import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormArray, FormBuilder } from '@angular/forms';
import { GenericTableBuilder } from '../../networking/common/classes/table-builder.class';
import { IGenericTableRow } from '../../ui-components/generic-table/ts/models/generic-table-row';
import { IGenericTableHeader } from '../../ui-components/generic-table/ts/models/generic-table-header';
import { IGenericUnsetSelectedRow } from '../../ui-components/generic-table/ts/models/generic-unset-selected-row.model';

@Injectable({
	providedIn: 'root',
})
export class AutomationProgramEditListTableService extends GenericTableBuilder {

	private readonly automationProgramEditList = 'automation-program-edit-list';

	private errorMessageState: BehaviorSubject<string> = new BehaviorSubject('');
	private unsetSelectedRowState = new BehaviorSubject({ unsetSelectedRow: false });

	constructor(
		private formBuilder: FormBuilder
	) {
		super();
	}

	buildTableHeaders(): IGenericTableHeader[] {
		return [
			{
				header: { headerName: 'AUTO.RULES.programName' },
				sort: { useSort: true, sortHeader: 'name', sortFeatureName: this.automationProgramEditList },
			},
			{
				header: { headerName: 'AUTO.RULES.created' },
				sort: {  useSort: true, sortHeader: 'createtime', sortFeatureName: this.automationProgramEditList },
			},
			{
				header: { headerName: '', isAction: true },
				sort: { useSort: false }
			}
		];
	}

	buildTableRows(): IGenericTableRow[] {
		return [
			{
				cell: { cellName: 'name', visibleCellCondition: true },
				edit: { editMode: false },
			},
			{
				cell: { cellName: 'createtime', visibleCellCondition: true },
				edit: { editMode: false },
			},
			{
				cell: { cellName: '', visibleCellCondition: true, isAction: true },
				edit: { editMode: false }
			}
		];
	}

	buildForm(): FormArray {
		return this.formBuilder.array([]);
	}

	setResponseErrorMessage(errorMessage: string = ''): void {
		this.errorMessageState.next(errorMessage);
	}

	getErrorMessageState$(): Observable<string> {
		return this.errorMessageState.asObservable();
	}

	setUnselectedRowState(rowState: boolean): void {
		this.unsetSelectedRowState.next({ unsetSelectedRow: rowState });
	}

	getUnsetSelectedRowState$(): Observable<IGenericUnsetSelectedRow> {
		return this.unsetSelectedRowState.asObservable();
	}
}
