import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { CustomInputComponent } from './../../../../../controls/custom-input/custom-input.component';
import { SideBarSearchNgrxActionService } from './../../../services/ngrx/side-bar-search-ngrx-action.service';
import { Component, Input, ViewChild, ChangeDetectionStrategy, OnInit, Output, EventEmitter } from '@angular/core';
import * as customOperators from '../../../../../../../custom-operators/custom-operators';

@UntilDestroy()
@Component({
	selector: 'app-side-bar-search-input',
	standalone: true,
	imports: [
		CommonModule,
		CustomInputComponent
	],
	templateUrl: './side-bar-search-input.component.html',
	styleUrls: ['./side-bar-search-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarSearchInputComponent implements OnInit {

	@ViewChild('customInputElement', { read: CustomInputComponent }) customInputElement: CustomInputComponent;

	@Input() isSearchVisible: boolean;

	@Output() contentAvailableEvent: EventEmitter<boolean> = new EventEmitter();

	public searchControl = new FormControl('', { nonNullable: true });

	constructor(
		private readonly sideBarSearchNgrxActionService: SideBarSearchNgrxActionService
	) {}

	public ngOnInit(): void {
		this.watchSearchControl();
	}

	public onResetSearch(): void {
		this.searchControl.patchValue('');
	}

	private watchSearchControl(): void {
		this.searchControl.valueChanges
			.pipe(
				untilDestroyed(this),
				customOperators.formDebounceDistinct$(250)
			)
			.subscribe(value => {
				this.emitContentAvailable(value !== '');
				this.sideBarSearchNgrxActionService.filterPagesBySearchTerm(value);
			});
	}

	private emitContentAvailable(contentAvailable: boolean): void {
		this.contentAvailableEvent.emit(contentAvailable);
	}
}
