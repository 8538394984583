import { Action, createReducer, on } from '@ngrx/store';
import { IAutomationDevice } from '../../ts/models/automation-device.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as automationActions from '../automation.action';

export const automationAllDevicesFeatureKey = 'automationAllDevices';

export type IAutomationAllDevices = EntityState<IAutomationDevice>;
export const adapter: EntityAdapter<IAutomationDevice> = createEntityAdapter<IAutomationDevice>();

export const initialAutomationAllDevicesState: IAutomationAllDevices = adapter.getInitialState();

const automationAllDevicesReducer = createReducer(
	initialAutomationAllDevicesState,
	on(automationActions.setAllDevices, (state, { payload }) => adapter.setAll(payload.allDevices, { ...state })),
	on(automationActions.removeAllDevices, (state) => adapter.removeAll(({ ...state })))
);

export function AutomationAllDevicesReducer(state: IAutomationAllDevices | undefined, action: Action) {
	return automationAllDevicesReducer(state, action);
}

export const { selectAll: selectAllAutomationDevices } = adapter.getSelectors();
