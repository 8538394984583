import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ISearchItem } from './../../../../../../../components/core/ts/models/search-item.model';
import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, inject } from '@angular/core';

@Component({
	selector: 'app-side-bar-search-content',
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule
	],
	templateUrl: './side-bar-search-content.component.html',
	styleUrls: ['./side-bar-search-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarSearchContentComponent {

	private readonly router: Router;

	@Input() results: ISearchItem[] = [];

	@Output() resetSearchEvent: EventEmitter<void> = new EventEmitter();

	constructor() {
		this.router = inject(Router);
	}

	public onGoToPage(result: ISearchItem): void {
		this.resetSearch();

		const { route, outlets, nestedOutlet } = result;
		const outletsToUse = outlets ? { [outlets]: this.getNestedOutlet(outlets, nestedOutlet) } : {};

		this.router.navigate([route, { outlets: outletsToUse }]);
	}

	public trackByIndex(index: number): number {
		return index;
	}

	private getNestedOutlet(
		outlets: string, nestedOutlet: string | undefined
	): string | Array<string | { outlets: { [key: string]: string } }> {
		if (!nestedOutlet) { return outlets; }

		return [outlets, { outlets: { [nestedOutlet]: nestedOutlet } }];
	}

	private resetSearch(): void {
		this.resetSearchEvent.emit();
	}
}
