import { Injectable } from '@angular/core';
import { RecentCallTableKey } from '../../ts/enums/recent-call-table-key.enum';
import { IGenericTableRow } from '../../../../ui-components/generic-table/ts/models/generic-table-row';
import { IGenericTableHeader } from '../../../../ui-components/generic-table/ts/models/generic-table-header';
import { GenericTableFilter } from '../../../../ui-components/generic-table/ts/enums/generic-table-filter.enum';
import { IGenericTableHeaderData } from '../../../../ui-components/generic-table/ts/models/generic-table-header-data.model';

@Injectable({
	providedIn: 'root'
})
export class RecentCallTableHeaderFilterService {

	private readonly userRow = {
		cell: { cellName: RecentCallTableKey.USER, visibleCellCondition: true },
		edit: { editMode: false }
	};

	private readonly userHeader = {
		header: { headerName: 'User' }, sort: { useSort: false },
		filter: {
			useFilter: true, filterComponent: GenericTableFilter.SELECT,
			select: { data: [], fieldWidth: 120, useTableData: true, tableDataKey: RecentCallTableKey.USER, formArrayIndex: 1 }
		}
	};

	constructor() { }

	/**
	 * Determines which function to handle header form values changes will be run.
	 * @param {string[]} values Contains values from table header
	 * @param {boolean} isAllMode If Recent call is in the All Users mode
	 */
	public updateHeaderFormValues(values: string[], isAllMode: boolean): IGenericTableHeaderData[] {
		if (isAllMode) {
			return this.handleAllUsersHeaderChanges(values);
		}

		return this.handleSingleUserHeaderChange(values);
	}

	/**
	 * Updates header form values (for all single user's mode), in order to react to user's changes in table header fields
	 * @param {string[]} values Contains values from table header
	 */
	public handleSingleUserHeaderChange(values: string[]): IGenericTableHeaderData[] {
		return values.map((value, index) => {
			switch (index) {
			case 0:
				return this.handleDatetimeFilter(value);
			case 1:
				return this.handleHeaderValues(RecentCallTableKey.CORRESPONDENT, value);
			case 2:
				return this.handleHeaderValues(RecentCallTableKey.DIRECTION_UI, this.getHeaderItemValue(value));
			case 3:
				return this.handleHeaderValues(RecentCallTableKey.RESULT_UI, this.getHeaderItemValue(value));
			default:
				return this.handleDatetimeFilter(value);
			}
		});
	}

	/**
	 * Updates header form values (for all Recent Calls user's mode), in order to react to user's changes in table header fields
	 * @param {string[]} values Contains values from table header
	 */
	public handleAllUsersHeaderChanges(values: string[]): IGenericTableHeaderData[] {
		return values.map((value, index) => {
			switch (index) {
			case 0:
				return this.handleDatetimeFilter(value);
			case 1:
				return this.handleHeaderValues(RecentCallTableKey.USER, this.getHeaderItemValue(value));
			case 2:
				return this.handleHeaderValues(RecentCallTableKey.CORRESPONDENT, value);
			case 3:
				return this.handleHeaderValues(RecentCallTableKey.DIRECTION_UI, this.getHeaderItemValue(value));
			case 4:
				return this.handleHeaderValues(RecentCallTableKey.RESULT_UI, this.getHeaderItemValue(value));
			default:
				return this.handleDatetimeFilter(value);
			}
		});
	}

	/**
	 * Gets user table header
	 */
	public get getUserHeader(): IGenericTableHeader {
		return { ...this.userHeader };
	}

	/**
	 * Gets user table row
	 */
	public get getUserRow(): IGenericTableRow {
		return { ...this.userRow };
	}

	/**
	 * Provides key and value for datetime header
	 * @param {string} value Header item filter value
	 */
	private handleDatetimeFilter(value: string): IGenericTableHeaderData {
		return { key: RecentCallTableKey.DATETIME_UI, value };
	}

	/**
	 * Provides key and value for header
	 * @param {string} key Header item filter key
	 * @param {string} value Header item filter value
	 */
	private handleHeaderValues(key: string, value: string): IGenericTableHeaderData {
		return { key, value };
	}

	/**
	 * Converts 'All' header value to empty string, so that it always matches, else it returns original value
	 * @param {string} value Header item filter value
	 */
	private getHeaderItemValue(value: string): string {
		return value === 'All' ? '' : value;
	}
}
