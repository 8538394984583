import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IAutomationMapUsedCamera } from '../../ts/models/automation-map-used-camera.model';
import * as automationActions from '../automation.action';

export const automationUsedCameraFeatureKey = 'automationUsedCamera';

export type IAutomationUsedCameraState = EntityState<IAutomationMapUsedCamera>;

export const adapter: EntityAdapter<IAutomationMapUsedCamera> = createEntityAdapter<IAutomationMapUsedCamera>();

export const initialUserCameraState: IAutomationUsedCameraState = adapter.getInitialState();

const automationCamerasUsedReducer = createReducer(
	initialUserCameraState,
	on(automationActions.setAllUsedCameras, (state, { payload }) => adapter.setAll(payload.usedCameras, { ...state })),
	on(automationActions.addUsedCamera, (state, { payload }) => adapter.upsertOne(payload.usedCamera, { ...state })),
	on(automationActions.removeUsedCamera, (state, { payload }) => adapter.removeOne(payload.usedCameraId, state)),
	on(automationActions.removeAllUsedCameras, (state) => adapter.removeAll(({ ...state })))
);

export function AutomationCamerasUsedReducer(state: IAutomationUsedCameraState | undefined, action: Action) {
	return automationCamerasUsedReducer(state, action);
}

export const { selectAll: selectAllUsedCameras } = adapter.getSelectors();
