import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, shareReplay } from 'rxjs';
import { ISelectData } from '../../../ts/models/select-data.model';
import { environment } from '../../../../environments/environment';
import { MainUtilService } from '../../../services/main-util.service';
import { IAutomationMapDevice } from '../ts/models/automation-map-device.model';
import { AutomationDeviceShortPipe } from '../../../pipes/automation-device-short.pipe';
import { AutomationLineDeviceType } from '../ts/enums/automation-line-device-type.enum';
import { IRioRvsIohubDevice } from '../../rio-rvs/ts/models/rio-rvs-iohub-device.model';
import { RioRvsIohubDeviceType } from '../../rio-rvs/ts/enums/rio-rvs-iohub-device-type.enum';
import { RioRvsWioIohubDeviceClass } from '../../rio-rvs/ts/enums/rio-rvs-wio-iohub-device-class.enum';
import { AutomationDeviceSessionHandlerService } from './device/automation-device-session-handler.service';
import { IRioRvsIohubDevicesResponse } from '../../rio-rvs/ts/models/rio-rvs-iohub-devices-response.model';
import { RioRvsWioIohubShortDeviceClass } from '../../rio-rvs/ts/enums/rio-rvs-wio-iohub-short-device-class.enum';
import { IAutomationProgramEditLineDevice } from '../../automation-program-edit/ts/models/automation-program-edit-line-device.model';
import * as customOperators from '../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class AutomationGenericService {

	private readonly getDevicesStatusUrl: string = environment.abilisUrl + '/sys/iohub/all/GetSesStatus.json';

	constructor(
		private http: HttpClient,
		private automationDeviceSessionHandlerService: AutomationDeviceSessionHandlerService
	) { }

	fetchAlertVideorecorderDevices$(noneValue = 'none'): Observable<ISelectData[]> {
		const defaultValue = { uiValue: 'TOOLS2.ALERTS.ALERT.NEW.DOUT.inputDigOutNone', value: noneValue };

		return this.http.get<IRioRvsIohubDevicesResponse>(this.getDevicesStatusUrl)
			.pipe(
				shareReplay(),
				map(response => {
					const { iohub } = response.Response;

					return iohub ? [defaultValue, ...this.getVideorecorerDevices(iohub)] : [defaultValue];
				}),
				customOperators.retryFromError$(1000)
			);
	}

	getVideorecorerDevices(iohub: IRioRvsIohubDevice[]): ISelectData[] {
		return this.automationDeviceSessionHandlerService.getAllDevices(iohub).reduce((accumulator, device) => {
			const { devices } = device;

			const updatedDevices = devices.reduce((portsAccumulator, item) => {
				const { port } = item;
				const matchingDin = port.toLowerCase().includes(AutomationLineDeviceType.DIN);
				const matchingDout = port.toLowerCase().includes(AutomationLineDeviceType.DOUT);

				if (matchingDin || matchingDout) {
					const fullDeviceName = (item.fullDeviceName as string).toLowerCase();
					const uiValue = new AutomationDeviceShortPipe().transform(fullDeviceName, item);

					return [...portsAccumulator, { value: uiValue, uiValue }];
				}

				return portsAccumulator;
			}, [] as ISelectData[]);

			return [...accumulator, ...updatedDevices];
		}, [] as ISelectData[]);
	}

	static getTranslatedShortClass(value: string): string {
		return value
			.replace(new RegExp(RioRvsWioIohubDeviceClass.DIN, 'i'), RioRvsWioIohubShortDeviceClass.DI.toUpperCase())
			.replace(new RegExp(RioRvsWioIohubDeviceClass.DOUT, 'i'), RioRvsWioIohubShortDeviceClass.DO.toUpperCase())
			.replace(new RegExp(RioRvsWioIohubDeviceClass.AIN, 'i'), RioRvsWioIohubShortDeviceClass.AI.toUpperCase())
			.replace(new RegExp(RioRvsWioIohubDeviceClass.AOUT, 'i'), RioRvsWioIohubShortDeviceClass.AO.toUpperCase())
			.replace(new RegExp(RioRvsIohubDeviceType.MFIO, 'i'), 'Mfio')
			.replace(new RegExp(RioRvsIohubDeviceType.GPIO, 'i'), 'Gpio')
			.replace(new RegExp(RioRvsIohubDeviceType.RIO, 'i'), 'Rio')
			.replace(new RegExp(RioRvsIohubDeviceType.RVS, 'i'), 'Rvs')
			.replace(new RegExp(RioRvsIohubDeviceType.WIO, 'i'), 'Wio');
	}

	static getPortNumberFromAlert(lineDevice: string): string {
		// D-OUT-10
		// 0  1  2
		return `${RioRvsWioIohubShortDeviceClass.DO}-${lineDevice.split('-')[2]}`;
	}

	static splitAutomationItemByComma(value: string): string[] {
		return value.split(',');
	}

	static splitAutomationItemByDash(value: string): string[] {
		return value.split('-');
	}

	static getWioPort(device: IAutomationMapDevice | IAutomationProgramEditLineDevice): string {
		const { port, wioline } = device;
		const [deviceClass] = port.split('-');
		const wioMasterSlave = AutomationGenericService.getWioMasterSlave(device);
		const newWioLine = `${wioMasterSlave}${deviceClass}-${wioline}`;

		return AutomationGenericService.getTranslatedShortClass(newWioLine);
	}

	static getWioMasterSlave(device: IAutomationMapDevice | IAutomationProgramEditLineDevice): string {
		const { wionode, deviceName } = device;

		return `${MainUtilService.capitalCaseString(deviceName as string)}.${wionode},`;
	}
}
