import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

@NgModule({
	imports: [
		ModalModule.forRoot(),
		TooltipModule.forRoot(),
		CollapseModule.forRoot(),
		AccordionModule.forRoot(),
		BsDropdownModule.forRoot(),
		TimepickerModule.forRoot(),
		ProgressbarModule.forRoot(),
		BsDatepickerModule.forRoot()
	],
	exports: [
		ModalModule,
		TooltipModule,
		CollapseModule,
		AccordionModule,
		BsDropdownModule,
		TimepickerModule,
		ProgressbarModule,
		BsDatepickerModule
	]
})
export class AngularBootstrapModule { }
