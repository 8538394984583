import { Injectable } from '@angular/core';
import { repeat, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { OpcPanelGroupNgrxService } from '../ngrx/opc-panel-group-ngrx.service';
import { IOpcPanelSessionResponse } from '../../ts/models/session/opc-panel-session-response.model';
import * as customOperators from '../../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class OpcPanelGroupHttpService {

	private readonly opcPanelStatusUrl: string = environment.abilisUrl + '/sys/opc/GetSesStatus.json';
	private readonly opcPanelSessionChangesUrl: string = environment.abilisUrl + '/sys/opc/GetSesChanges.json';

	private sessionId: string;
	private readonly opcPanelErrorMessage: BehaviorSubject<string> = new BehaviorSubject('');

	constructor(
		private readonly http: HttpClient,
		private readonly opcPanelGroupNgrxService: OpcPanelGroupNgrxService
	) { }

	public fetchInitialSessionData$(): Observable<IOpcPanelSessionResponse> {
		return this.http.get<IOpcPanelSessionResponse>(this.opcPanelStatusUrl)
			.pipe(
				tap(response => this.handleFetchInitialSessionData(response)),
				customOperators.retryFromError$(1000)
			);
	}

	public fetchSessionChanges$(): Observable<IOpcPanelSessionResponse> {
		return this.http.get<IOpcPanelSessionResponse>(this.opcPanelSessionChangesUrl + `?SesId=${this.sessionId}`)
			.pipe(
				repeat()
			);
	}

	public fetchOpcPanelSessionChanges$(): Observable<IOpcPanelSessionResponse> {
		return this.fetchSessionChanges$()
			.pipe(
				customOperators.handle404Error$(
					this.fetchInitialSessionData$().pipe(tap(() => this.opcPanelGroupNgrxService.fetchSessionChanges()))
				)
			);
	}

	public setOpcPanelMessage(message: string): void {
		this.opcPanelErrorMessage.next(message);
	}

	public getOpcPanelMessages$(): Observable<string> {
		return this.opcPanelErrorMessage.asObservable();
	}

	public setOpcSessionId(sessionId: string): void {
		this.sessionId = sessionId;
	}

	public get getOpcSessionId(): string {
		return this.sessionId;
	}

	public isOpcResponseValid(response: IOpcPanelSessionResponse): boolean {
		const { rsp, message } = response.Response;

		return !rsp && !message;
	}

	private handleFetchInitialSessionData(response: IOpcPanelSessionResponse): void {
		if (this.isOpcResponseValid(response)) {
			this.setOpcSessionId(response.Response.CtiMonitor.SesId);
			return;
		}

		this.setOpcPanelMessage(response.Response.message || '');
	}
}
