import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AutomationProgramDevicesService } from '../devices/automation-program-devices.service';
import { IAutomationLogGraph } from '../../../AUTO_FEATURES/automation-log/ts/models/automation-log-graph.model';
import { IAutomationLogResponse } from '../../../AUTO_FEATURES/automation-log/ts/models/automation-log-response.model';
import { IAutomationProgramEditLineDevice } from '../../ts/models/automation-program-edit-line-device.model';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramEditHttpTransitionService {

	private readonly historyStatisticsUrl = environment.abilisUrl + '/sys/io/iolog/history.json';

	constructor(
		private http: HttpClient,
		private automationPorgramDevicesService: AutomationProgramDevicesService
	) { }

	formatHistoricalHttpParamsToHttpRequest$(
		transitionHttpParams: string[], lineDevices: IAutomationProgramEditLineDevice[], isTransition: boolean
	): Observable<number | boolean>[] {
		return transitionHttpParams.map(transitionHttpParam => {
			const [trimmedCell, , , datetime] = transitionHttpParam.split('-');
			// const [from, to] = datetime.split('_');
			const lineDevice = AutomationProgramDevicesService.getReferencedLineDevice(trimmedCell, lineDevices);
			const isDigitalDevice = this.automationPorgramDevicesService.isDigitalLineDevice(lineDevice.port);

			return of(isTransition || !isDigitalDevice ? 0 : false);

			// return this.fetchHistoryStatistics$(from, to, lineDevice)
			// 	.pipe(
			// 		map(response => this.formatHistoricalValueFromResponse(response, isDigitalDevice, isTransition)),
			// 		customOperators.retryFromError$(1000)
			// 	);
		});
	}

	fetchHistoryStatistics$(
		from: string, to: string, selectedDevice: string
	): Observable<IAutomationLogResponse> {
		const fetchUrl = this.historyStatisticsUrl + `?period=${from}_${to}${selectedDevice}`;

		return this.http.get<IAutomationLogResponse>(fetchUrl);
	}

	formatHistoricalValueFromResponse(response: IAutomationLogResponse, isDigitalDevice: boolean, isTransition: boolean): number | boolean {
		const { desc, graph } = response.Response;

		if (desc === undefined) {
			const sample = graph[0].sample;

			if (!isTransition) {
				const value = sample[0].y0 as string | number;

				return isDigitalDevice ? value === 100 : value !== 'string' ? Number.parseFloat(value + '') : 0;
			}

			return this.getNumberOfTransitions(graph);
		}

		return this.getUnavailableValue(isDigitalDevice);
	}

	getNumberOfTransitions(graph: IAutomationLogGraph[]): number {
		return graph[0].sample.reduce((accumulator, item) => {
			const { y1 } = item;

			return typeof y1 !== 'string' && y1 ? accumulator + (y1 as number) : accumulator;
		}, 0);
	}

	getUnavailableValue(isDigitalDevice: boolean): number | boolean {
		return isDigitalDevice ? false : 0;
	}
}
