import { Injectable } from '@angular/core';
import { IRecentCall } from '../ts/models/recent-call-table.model';

@Injectable({
	providedIn: 'root'
})
export class RecentCallArrayService {

	constructor() { }

	/**
 * Gets first selected Recent Call item. If none is found, it returns null
 * @param {IRecentCall[]} recentCalls Recent Call items
 */
	public getFirstSelectedRecentCallItem(recentCalls: IRecentCall[]): IRecentCall | null {
		return recentCalls.find(item => item.checkboxState) || null;
	}

	/**
 * Determines if there is at least one selected Recent Call item
 * @param {IRecentCall[]} recentCalls Recent Call items
 */
	public isSomeRecentCallSelected(recentCalls: IRecentCall[]): boolean {
		return recentCalls.some(item => item.checkboxState);
	}

	/**
  * Determines if there are Recent Call items
  * @param {IRecentCall[]} recentCalls Recent Call items
  */
	public static areRecentCallsEmpty(recentCalls: IRecentCall[]): boolean {
		return !recentCalls.length;
	}
}
