import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/services/auth.service';
import { UserLevel } from '../../auth/ts/enums/user-level.enum';
import { ISelectData } from '../../../ts/models/select-data.model';
import { IPreferencesStore } from '../ts/models/preferences-store.model';
import { PreferencesInterfaceType } from '../ts/types/preferences-interface.type';
import { IPreferencesInterfaceType } from '../ts/enums/preferences-interface-type.enum';
import { PreferencesInterfaceUbrType } from '../ts/types/preferences-interface-ubr.type';
import { PreferencesInterfaceAction } from '../ts/enums/preferences-interface-action.enum';
import { IPreferencesVoUserInterface } from '../ts/models/preferences-vo-user-interface.model';
import { IPreferencesAbilisphoneFormData } from '../ts/models/preferences-abilisphone-form-data.model';

@Injectable({
	providedIn: 'root'
})
export class PreferencesUserService {

	private selectedUserInterface: IPreferencesInterfaceType | undefined;
	private readonly filterableInterfaces = [IPreferencesInterfaceType.IAX];

	constructor(
		private authService: AuthService,
		private translate: TranslateService
	) { }

	getConfiguredInterfaces(ctiPreferences: IPreferencesStore): ISelectData[] {
		const { haveError, interfaces } = ctiPreferences;

		if (haveError) { return []; }

		return interfaces
			.filter(interfaceData => !this.filterableInterfaces.includes(interfaceData.type))
			.reduce((accumulator: ISelectData[], interfaceData: PreferencesInterfaceType) => {
				const { configured, type } = interfaceData;
				const uiValue = type !== IPreferencesInterfaceType.VO ? type : IPreferencesInterfaceType.ABILISPHONE;

				return configured ? [...accumulator, { uiValue, value: type }] : accumulator;
			}, []);
	}

	getInterfaceData(ctiPreferences: IPreferencesStore, interfaceType: IPreferencesInterfaceType): PreferencesInterfaceUbrType {
		const { ubr, interfaces } = ctiPreferences;
		const abilisphoneData = this.getAbilisphoneFormValues(ctiPreferences);

		if (this.isUbrInterface(interfaceType)) { return { ...ubr, ...abilisphoneData }; }

		const interfaceData = this.getMatchingInterfaceData(interfaces, interfaceType) as PreferencesInterfaceType;

		return { ...interfaceData, ...abilisphoneData };
	}

	private getAbilisphoneFormValues(ctiPreferences: IPreferencesStore): IPreferencesAbilisphoneFormData {
		const voData = this.getMatchingInterfaceData(ctiPreferences.interfaces, IPreferencesInterfaceType.VO);

		if (!voData) { return { 'vo-ap-voip': false, 'vo-ap-cell': false, 'vo-contact-ap-voip': false }; }

		const {
			'vo-ap-voip': useVoIP, 'vo-ap-cell': useGsm, 'vo-contact-ap-voip': forIncomingCalls
		} = voData as IPreferencesVoUserInterface;

		return { 'vo-ap-voip': useVoIP, 'vo-ap-cell': useGsm, 'vo-contact-ap-voip': forIncomingCalls };
	}

	private getMatchingInterfaceData(
		interfaces: PreferencesInterfaceType[], interfaceType: IPreferencesInterfaceType
	): PreferencesInterfaceType | null {
		return interfaces.find(item => item.type === interfaceType) || null;
	}

	public isUbrInterface(interfaceType: IPreferencesInterfaceType): boolean {
		return interfaceType === IPreferencesInterfaceType.UBR;
	}

	getDefaultInterface(configuredInterfaces: ISelectData[]): IPreferencesInterfaceType {
		if (!configuredInterfaces.length) { return IPreferencesInterfaceType.CTIP; }

		const defaultInterface = this.getSelectedUserInterface();
		const matchingInterface = configuredInterfaces.some(configuredInterface => configuredInterface.value === defaultInterface);

		if (matchingInterface) {
			return defaultInterface as IPreferencesInterfaceType;
		}

		const [firstConfiguredInterface] = configuredInterfaces;

		return firstConfiguredInterface.value as IPreferencesInterfaceType;
	}

	setSelectedUserInterface(defaultUserInterface: IPreferencesInterfaceType | undefined): void {
		this.selectedUserInterface = defaultUserInterface;
	}

	getSelectedUserInterface(): IPreferencesInterfaceType | undefined {
		return this.selectedUserInterface;
	}

	isMatchingDndAdvanced(userConditionalRule: PreferencesInterfaceAction): boolean {
		return [
			PreferencesInterfaceAction.DND,
			PreferencesInterfaceAction.NONE,
			PreferencesInterfaceAction.ADVANCED
		]
			.includes(userConditionalRule);
	}

	showCallWaiting(interfaceType: IPreferencesInterfaceType): boolean {
		return [IPreferencesInterfaceType.VO, IPreferencesInterfaceType.CTIP, IPreferencesInterfaceType.UBR].includes(interfaceType);
	}

	showCallRetry(interfaceType: IPreferencesInterfaceType): boolean {
		return [IPreferencesInterfaceType.SIP, IPreferencesInterfaceType.CTIP, IPreferencesInterfaceType.UBR].includes(interfaceType);
	}

	public showAbilisphoneSettings(configuredInterfaces: ISelectData[]): boolean {
		return configuredInterfaces.some(item => item.value.toString().toUpperCase() === IPreferencesInterfaceType.VO);
	}

	setAdvancedSettingsVisibility(userLevel: UserLevel | null, type: IPreferencesInterfaceType): boolean {
		const showCallRetry = this.showCallRetry(type);
		const isVoInterface = type === IPreferencesInterfaceType.VO;

		return this.authService.haveAdminUserLevel(userLevel) && (isVoInterface || showCallRetry);
	}

	showVoiceRecorder(userLevel: UserLevel | null): boolean {
		return userLevel === UserLevel.ADMINISTRATOR;
	}

	showAbilisphoneTab(userLevel: UserLevel | null, interfaceType: IPreferencesInterfaceType): boolean {
		return userLevel === UserLevel.ADMINISTRATOR && interfaceType === IPreferencesInterfaceType.VO;
	}

	getNoRulesAdvancedTextImage(interfaceData: PreferencesInterfaceUbrType, getImage: boolean): string {
		if (!interfaceData['advanced-rules']) {
			return getImage ? 'dot.svg' : 'PHONE.PREF.radioNoRules';
		}

		return getImage ? 'phone-call.svg' : 'Advanced';
	}

	getConditionalRule(forwardOptions: boolean, initialConditionalRule: PreferencesInterfaceAction): PreferencesInterfaceAction {
		if (!forwardOptions) {
			return PreferencesInterfaceAction.ADVANCED;
		}

		const isMatchingDndAdvanced = this.isMatchingDndAdvanced(initialConditionalRule);

		return isMatchingDndAdvanced ? PreferencesInterfaceAction.CF_ALWAYS : initialConditionalRule;
	}

	getRecipientsBodyData$(): Observable<ISelectData[]> {
		return of([
			{ value: 'SYS', uiValue: this.translate.instant('PHONE.PREF.ADV.comboBodySysVal') },
			{ value: 'STANDARD', uiValue: this.translate.instant('PHONE.PREF.ADV.comboBodyExt') },
			{ value: 'SMS-LIKE', uiValue: this.translate.instant('PHONE.PREF.ADV.comboBodySms') }
		]);
	}
}
