import { props, createAction } from '@ngrx/store';
import { IAutomationMap } from '../ts/models/automation-map.model';
import { ICamera } from '../../videorecorder/ts/models/camera.model';
import { IAutomationDevice } from '../ts/models/automation-device.model';
import { IAutomationMapDevice } from '../ts/models/automation-map-device.model';
import { IAutomationMapCamera } from '../ts/models/automation-map-camera-device.model';
import { IAutomationMapUsedCamera } from '../ts/models/automation-map-used-camera.model';
import { IAutomationMapVideoContainer } from '../ts/models/automation-map-video-container.model';
import { AutomationVideoContainerUpdate } from '../ts/types/automation-video-container-update.type';
import { IAutomationMapTemporaryDescription } from '../ts/models/automation-map-temporary-description.model';

export const loadMapTableOverview = createAction(
	'[Automation] Start Map Loading Action',
	props<{ payload: { selectedMapNumber: number | null } }>()
);

export const fillAutomationMaps = createAction(
	'[Automation] Fill Automation Maps In Store',
	props<{ payload: { selectedMapNumber: number } }>()
);

export const finishMapLoadingAction = createAction(
	'[Automation] Finish Map Loading Action',
	props<{ payload: { automationMaps: IAutomationMap[] } }>()
);

export const cancelAutomationObservablesAction = createAction(
	'[Automation] Cancel HTTP Observables'
);

export const cancelMapsHttpObservables = createAction(
	'[Automation] Cancel Maps HTTP Observables'
);

export const setSelectedAutomationMapNumber = createAction(
	'[Automation] Set Selected Automation Map Number',
	props<{ payload: { automationMapNumber: number } }>()
);

export const deleteSelectedMap = createAction(
	'[Automation] Delete Selected Map',
	props<{ payload: { automationMapNumber: number } }>()
);

export const setAvailableMapNumbers = createAction(
	'[Automation] Set Available Map Numbers',
	props<{ payload: { availableMapNumbers: number[] } }>()
);

export const setSelectedAutomationMap = createAction(
	'[Automation] Set Selected Automation Map',
	props<{ payload: { automationMap: IAutomationMap } }>()
);

export const loadDevicesFromModal = createAction(
	'[Automation] Load Devices Used By Map From Modal',
	props<{ payload: { mapNumber: number; selectedDevices: IAutomationMapDevice[] } }>()
);

export const updateDevicesUsedByMap = createAction(
	'[Automation] Update Devices Used By Map',
	props<{ payload: { devicesUsedByMap: IAutomationMapDevice[] } }>()
);

export const updateDeviceUsedByMap = createAction(
	'[Automation] Update Device Used By Map',
	props<{ payload: { deviceUsedByMap: IAutomationMapDevice } }>()
);

export const setAllDevicesLoadingFlag = createAction(
	'[Automation] Set All Devices Loading Flag',
	props<{ payload: { isLoading: boolean } }>()
);

export const finishLoadingDevicesUsedByMap = createAction(
	'[Automation] Finish Loading Devices Used By Map',
	props<{ payload: { devicesUsedByMap: IAutomationMapDevice[] } }>()
);

export const setAllDevices = createAction(
	'[Automation] Set All Devices',
	props<{ payload: { allDevices: IAutomationDevice[] } }>()
);

export const removeAllDevices = createAction(
	'[Automation] Remove All Devices'
);

export const updateAvailableDevices = createAction(
	'[Automation] Update Available Devices',
	props<{ payload: { mapNumber: number; designMapMode: boolean } }>()
);

export const fetchAvailableDevicesAction = createAction(
	'[Automation] Fetch Available Devices',
	props<{ payload: { mapNumber: number; designMapMode: boolean } }>()
);

export const setSessionChangesAction = createAction(
	'[Automation] Set Session Changes',
	props<{ payload: { mapNumber: number; designMapMode: boolean } }>()
);

export const fetchDevicesSessionStatus = createAction(
	'[Automation] Fetch Session Changes',
	props<{ payload: { mapNumber: number; designMapMode: boolean } }>()
);

export const setSwitchControl = createAction(
	'[Automation] Set Switch Control',
	props<{ payload: { device: IAutomationMapDevice; selectedMapNumber: number; analogValue?: number } }>()
);

export const addUsedVideoContainers = createAction(
	'[Automation] Add Used Video Containers',
	props<{ payload: { videoContainers: IAutomationMapVideoContainer[] } }>()
);

export const removeAllVideoContainers = createAction(
	'[Automation] Remove All Video Containers'
);

export const updateVideoContainer = createAction(
	'[Automation] Update Video Container',
	props<{ payload: { videoContainer: AutomationVideoContainerUpdate } }>()
);

export const startFetchAllWorkingCameras = createAction(
	'[Automation] Start Fetch All Working Cameras'
);

export const setAllWorkingCameras = createAction(
	'[Automation] Set All Working Cameras',
	props<{ payload: { workingCameras: ICamera[] } }>()
);

export const setAllUsedCameras = createAction(
	'[Automation] Set All Used Cameras',
	props<{ payload: { usedCameras: IAutomationMapUsedCamera[] } }>()
);

export const addUsedCamera = createAction(
	'[Automation] Add Used Camera',
	props<{ payload: { usedCamera: IAutomationMapUsedCamera } }>()
);

export const removeUsedCamera = createAction(
	'[Automation] Remove Used Camera',
	props<{ payload: { usedCameraId: string } }>()
);

export const removeAllUsedCameras = createAction(
	'[Automation] Remove All Used Camera'
);

export const fetchLiveBackgroundOutputs = createAction(
	'[Automation] Fetch Live Background Output'
);

export const startLiveFetchCameraBackground = createAction(
	'[Automation] Start Live Fetch Camera Background',
	props<{ payload: { videoContainer: IAutomationMapVideoContainer; selectedCamera: IAutomationMapCamera } }>()
);

export const updateNewBackgroundImageId = createAction(
	'[Automation] Update Background Image Id'
);

export const setCameraToNormalMode = createAction(
	'[Automation] Set Camera To Normal Mode',
	props<{ payload: { cameraId: number; deviceId: number } }>()
);

export const cancelLiveBackgroundCameraOutput = createAction(
	'[Automation] Cancel Live Background Camera Output'
);

export const setAllCamerasUsedToNormalMode = createAction(
	'[Automation] Set All Cameras To Normal Mode'
);

export const destroyAutomationSessionId = createAction(
	'[Automation] Destroy Session Id'
);

export const addTemporaryLineDeviceDescription = createAction(
	'[Automation] Add Device Description Action',
	props<{ payload: { descriptionPayload: IAutomationMapTemporaryDescription } }>()
);

export const removeAllTemporaryDescriptions = createAction(
	'[Automation] Remove All Temporary Descriptions'
);

export const setNumberOfVideoContainers = createAction(
	'[Automation] Set Number Of Video Containers',
	props<{ payload: { numberOfVideoContainers: string } }>()
);

export const saveMapForAbilis = createAction(
	'[Automation] Save Map For Abilis POST',
	props<{ payload: { mapNumber: number; newMapSelected: boolean } }>()
);

export const setDeviceDescriptions = createAction(
	'[Automation] Set Devices Temporary Descriptions'
);

export const removeAllAutomationMaps = createAction(
	'[Automation] Remove All Automation Maps',
);

export const removeCurrentlyUsedMap = createAction(
	'[Automation] Remove Currently Used Map',
);

export const removeAllAvailableCameras = createAction(
	'[Automation] Remove All Available Cameras',
);

export const setSelectedLineDevices = createAction(
	'[Automation] Set Selected Checkbox Line Devices',
	props<{ payload: { selectedLineDevices: string[] } }>()
);
