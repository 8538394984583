import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EmptyObject } from '../../../../ts/types/empty-object.type';
import { IUserPreferenceItem } from '../../ts/models/user-preferences-item.model';
import { PrivilegePreferencesFormService } from './privilege-preferences-form.service';
import { IUserPreferencesVoForm } from '../../ts/models/user-preferences-vo-form.model';
import { UserPreferencesPhoneTab } from '../../ts/enums/user-preferences-phone-tab.enum';
import { IUserPreferencesOpcForm } from '../../ts/models/user-preferences-opc-form.model';
import { IUserPreferencesSipForm } from '../../ts/models/user-preferences-sip-form.model';
import { IUserPreferencesCtipForm } from '../../ts/models/user-preferences-ctip-form.model';
import { IUserPreferencesLdapForm } from '../../ts/models/user-preferences-ldap-form.model';
import { IUserPreferencesClusForm } from '../../ts/models/user-preferences-clus.form.model';
import { IUserPreferencesOpcFormPayload } from '../../ts/models/user-preferences-opc-form-payload.model';
import { UserPreferencesPhoneSavePayload } from '../../ts/types/user-preferences-phone-save-payload.type';
import { IUserPreferencesAddressBookForm } from '../../ts/models/user-preferences-address-book-form.model';

@Injectable({
	providedIn: 'root'
})
export class PrivilegePreferencesFormPayloadService {

	constructor(
		private privilegePreferencesFormService: PrivilegePreferencesFormService
	) { }

	getPhoneSettingsFormsPayload(
		voForm: IUserPreferencesVoForm, sipForm: IUserPreferencesSipForm,
		opcForm: IUserPreferencesOpcForm, ldapForm: IUserPreferencesLdapForm,
		addressBookForm: IUserPreferencesAddressBookForm, ctipForm: IUserPreferencesCtipForm,
		clusForm: IUserPreferencesClusForm, activeTab: UserPreferencesPhoneTab
	): UserPreferencesPhoneSavePayload | null {
		switch (activeTab) {
		case UserPreferencesPhoneTab.ADDRESS_BOOK:
			return this.getAddressBookFormPayload(addressBookForm) as unknown as UserPreferencesPhoneSavePayload;
		case UserPreferencesPhoneTab.OPC:
			return this.getOpcFormPayload(opcForm) as unknown as UserPreferencesPhoneSavePayload;
		case UserPreferencesPhoneTab.CTIP:
			return this.getCtipFormPayload(ctipForm) as unknown as UserPreferencesPhoneSavePayload;
		case UserPreferencesPhoneTab.CLUS:
			return this.getClusFormPayload(clusForm) as unknown as UserPreferencesPhoneSavePayload;
		case UserPreferencesPhoneTab.VO:
			return this.getVoFormPayload(voForm) as unknown as UserPreferencesPhoneSavePayload;
		case UserPreferencesPhoneTab.SIP:
			return this.getSipFormPayload(sipForm) as unknown as UserPreferencesPhoneSavePayload;
		case UserPreferencesPhoneTab.LDAP:
			return ldapForm as unknown as UserPreferencesPhoneSavePayload;
		case UserPreferencesPhoneTab.UBR:
			return null;
		}
	}

	getOpcFormPayload(opcForm: IUserPreferencesOpcForm): IUserPreferencesOpcFormPayload {
		const { checkboxesData, ...rest } = opcForm;

		const allSelected = checkboxesData.every(value => value);
		const noneSelected = checkboxesData.every(value => !value);
		const opcMonitor = allSelected ? 'ALL' : noneSelected ? 'NONE' : this.getOpcMonitoredInterfaces(checkboxesData);

		return { ...rest, 'OPC-MONITOR': opcMonitor };
	}

	getOpcMonitoredInterfaces(checkboxesData: boolean[]): string {
		const interfaces = this.privilegePreferencesFormService.getAvailableInterfaces();

		return checkboxesData
			.reduce((accumulator, value, index) => value ? [...accumulator, interfaces[index]] : accumulator, [] as string[])
			.join(',');
	}

	getSipFormPayload(sipForm: IUserPreferencesSipForm): IUserPreferencesSipForm | EmptyObject {
		if (!sipForm) { return {}; }

		const { 'SIP-DOMAIN': sipDomain, 'SIP-LOCPORT': sipLocport } = sipForm;

		return {
			...sipForm,
			'SIP-DOMAIN': this.translateSysValue(sipDomain),
			'SIP-LOCPORT': this.translateSysValue(sipLocport)
		};
	}

	getCtipFormPayload(ctipForm: IUserPreferencesCtipForm): IUserPreferencesCtipForm | EmptyObject {
		if (!ctipForm) { return {}; }

		const { CTIP, 'CTIP-NUM': ctipNum } = ctipForm;

		return { CTIP, ...(!!ctipNum && { 'CTIP-NUM': ctipNum }) };
	}

	getClusFormPayload(clusForm: IUserPreferencesClusForm): IUserPreferencesClusForm | EmptyObject {
		if (!clusForm) { return {}; }

		const { CLUS, 'CLUS-NUM': clusNum } = clusForm;

		return { CLUS, ...(!!clusNum && { 'CLUS-NUM': clusNum }) };
	}

	getVoFormPayload(voForm: IUserPreferencesVoForm): { VO: boolean } | EmptyObject {
		if (!voForm) { return {}; }

		return { VO: voForm.VO };
	}

	getAddressBookFormPayload(addressBookForm: IUserPreferencesAddressBookForm): IUserPreferencesAddressBookForm | EmptyObject {
		if (!addressBookForm) { return {}; }

		const {
			'ADDRBOOK-SYNC': addressBookSync, 'ADDRBOOK-OUTDIAL': addressBookOutdial,
			'ADDRBOOK-PRIV-MAX': addressBookPrivMax, 'ADDRBOOK-PUB-EDITABLE': addressBookPubEditable
		} = addressBookForm;

		return {
			...addressBookForm,
			'ADDRBOOK-SYNC': this.translateSysValue(addressBookSync),
			'ADDRBOOK-OUTDIAL': this.translateSysValue(addressBookOutdial),
			'ADDRBOOK-PRIV-MAX': this.translateSysValue(addressBookPrivMax),
			'ADDRBOOK-PUB-EDITABLE': this.translateSysValue(addressBookPubEditable)
		};
	}

	translateSysValue(value: string): string {
		return value && value.toString().toLowerCase().includes('sys') ? 'sys' : value;
	}

	setDisabledFormControl(isAddMode: boolean, userBasicParametersForm: FormGroup, selectedPrivilegeItem: IUserPreferenceItem): void {
		if (!(selectedPrivilegeItem && !isAddMode)) { return; }

		const {
			'REAL-NAME': realname, USER, 'HTTP-GUI-GROUP': groupLabel,
			PWD, 'HTTP-LEVEL': httpLevel, 'HTTP-HOME-URL': homeData
		} = selectedPrivilegeItem;

		[
			{ name: 'password', readonly: PWD.readonly },
			{ name: 'username', readonly: USER.readonly },
			{ name: 'group', readonly: groupLabel.readonly },
			{ name: 'homeUrl', readonly: homeData.readonly },
			{ name: 'realName', readonly: realname.readonly },
			{ name: 'levelGUI', readonly: httpLevel.readonly }
		].forEach(item => {
			if (!item.readonly) { return; }

			userBasicParametersForm.controls[item.name].disable();
		});
	}
}
