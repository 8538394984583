<div class="content">
	<div class="header">
		<a [attr.href]="item.location && !item.disabled ? item.location : null" class="header__item"
			[ngClass]="{'header__item--disabled': item.disabled, 'header__item--active': item.active}"
			(click)="onSelectTab($event, i)" *ngFor="let item of tabItems; let i = index"
			[tooltip]="item.tooltip | nestedTranslation:true:(languageChanged$ | async)">
			<ng-container *ngIf="item.labelComponent as labelComponent">
				<ng-container *ngTemplateOutlet="labelComponent.labelContent"></ng-container>
			</ng-container>
		</a>
	</div>
</div>
