import { Injectable } from '@angular/core';
import { IWioNodeModel } from '../../ts/models/wio-node.model';
import { WioSlavesState } from '../../ts/enums/wio-slaves-state.enum';
import { IWioModalDevice } from '../../ts/models/wio-modal-device.model';
import { WioSlavesTableService } from '../tables/wio-slaves-table.service';
import { IWioNodeResponse } from '../../ts/models/wio-nodes-response.model';
import { RioRvsIohubDeviceType } from '../../ts/enums/rio-rvs-iohub-device-type.enum';
import { IAutomationMapDevice } from '../../../automation/ts/models/automation-map-device.model';
import { AutomationGenericService } from '../../../automation/services/automation-generic.service';

@Injectable({
	providedIn: 'root'
})
export class WioHttpLogicService {

	constructor(
		private wioSlavesTableService: WioSlavesTableService
	) { }

	formatWioNodes(wioNodeResponse: IWioNodeResponse, hasErrorMessage: boolean): IWioNodeModel[] {
		const { message, WioNodes } = wioNodeResponse.Response;
		this.wioSlavesTableService.setResponseErrorMessage(message);

		if (!hasErrorMessage) {
			return WioNodes.map(wioNode => {
				const { res, nodeInd, State } = wioNode;
				const isUp = State === WioSlavesState.UP;
				const displayName = res + '.' + nodeInd;

				return { ...wioNode, displayName, isUp };
			});
		}

		return [];
	}

	getAvailableWioNodes(wioMainNumber: number, devices: IAutomationMapDevice[], wioSlaves: IWioNodeModel[]): IWioModalDevice[] {
		return devices
			.reduce((accumulator, wioPort) => {
				const { wionode } = wioPort;

				return wionode && accumulator.includes(wionode) ? accumulator : [...accumulator, wionode as number];
			}, [] as number[])
			.slice()
			.sort((a, b) => a - b)
			.map(slaveNumber => {
				const id = `${RioRvsIohubDeviceType.WIO}-${wioMainNumber}.${slaveNumber}`;
				const wioDevices = devices.reduce((wioDevicesAccumulator, wioDevice) => {
					const { wionode, wioline, port } = wioDevice;

					const [portType] = AutomationGenericService.splitAutomationItemByDash(port);
					const wioPort = `${AutomationGenericService.getTranslatedShortClass(portType)}-${wioline}`;

					if (wionode === slaveNumber) {
						return [...wioDevicesAccumulator, { ...wioDevice, wioPort }];
					}

					return wioDevicesAccumulator;
				}, [] as IAutomationMapDevice[]);

				const matchingNode = wioSlaves.find(wioSlaveItem => wioSlaveItem.displayName?.toUpperCase() === id.toUpperCase());

				return { id, wioDevices, description: matchingNode?.descr || '', isUp: matchingNode?.isUp || false };
			});
	}
}
