import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VirtualOfficeStoreKey } from '../ts/enums/virtual-office-store-key.enum';
import * as virtualOfficeReducer from './virtual-office.reducer';

export const selectVirtualOfficeState = createFeatureSelector<virtualOfficeReducer.IVirtualOfficeState>('virtualOffice');

export const selectFromVirtualOfficeStore = (property: VirtualOfficeStoreKey) => createSelector(
	selectVirtualOfficeState,
	virtualOfficeState => virtualOfficeState[property]
);
