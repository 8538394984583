import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { PhoneUtilService } from './../phone-util.service';
import { IAppState } from './../../../../root-reducers/index';
import { AuthService } from './../../../auth/services/auth.service';
import { PhoneMenuModes } from '../../ts/enums/phone-menu-modes.enum';
import { PhoneCallsLogService } from './../calls/phone-calls-log.service';
import { IPhoneCallRecord } from './../../ts/models/phone-call-record.model';
import { IPhoneCallRecordInfo } from '../../ts/models/phone-call-record-info.model';
import { OpcPanelCtiControl } from './../../../opc-panel/ts/enums/opc-panel-cti-control.enum';
import { IOpcPanelCallRecord } from '../../../opc-panel/ts/models/session/opc-panel-call-record.model';
import { IOpcPanelUserRecord } from './../../../opc-panel/ts/models/session/opc-panel-user-record.model';
import { PreferencesInterfaceAction } from './../../../preferences/ts/enums/preferences-interface-action.enum';
import * as phoneActions from '../../ngrx/phone-component.actions';

@Injectable({
	providedIn: 'root'
})
export class PhoneNgrxActionService {

	constructor(
		private readonly store: Store<IAppState>,
		private readonly authService: AuthService,
		private readonly phoneUtilService: PhoneUtilService,
		private readonly phoneCallsLogService: PhoneCallsLogService
	) { }

	public setClearNextInput(clearNextInput = true): void {
		const payload = { clearNextInput };

		this.store.dispatch(phoneActions.setClearNextInputState({ payload }));
	}

	public clearPhoneStore(): void {
		this.store.dispatch(phoneActions.clearPhoneStore());
	}

	public setStatus(status: PhoneMenuModes): void {
		const payload = { status };

		this.store.dispatch(phoneActions.setStatus({ payload }));
	}

	public setInputValue(value: string): void {
		const payload = { value };

		this.store.dispatch(phoneActions.setInputValue({ payload }));
	}

	public addInputValue(value: string): void {
		const payload = { value };

		this.store.dispatch(phoneActions.addInputValue({ payload }));
	}

	public clearInputValue(): void {
		this.store.dispatch(phoneActions.clearInputValue());
	}

	public removeLastInputValue(): void {
		this.store.dispatch(phoneActions.removeLastInputValue());
	}

	public fetchPhoneStatus(): void {
		this.store.dispatch(phoneActions.fetchPhoneStatus());
	}

	public fetchPhoneStatusSessionChanges(): void {
		this.store.dispatch(phoneActions.fetchPhoneStatusSessionChanges());
	}

	public cancelHTTPObservables(): void {
		this.store.dispatch(phoneActions.cancelPhoneHTTPObservables());
	}

	public clearCallRecordsAndCallInfo(): void {
		this.setCallRecords([]);
		this.setCallRecordInfo([], []);
	}

	public setCallRecords(callRecords: IPhoneCallRecord[]): void {
		const payload = { callRecords };

		this.store.dispatch(phoneActions.setCallRecords({ payload }));
	}

	public handleNewCallRecords(newCallRecords: IOpcPanelCallRecord[]): void {
		const payload = { newCallRecords };

		this.store.dispatch(phoneActions.handleNewCallRecords({ payload }));
	}

	public addUserRecord(userRecord: IOpcPanelUserRecord | null): void {
		const payload = { userRecord };

		this.store.dispatch(phoneActions.addUserRecord({ payload }));
	}

	public checkToUpdateCallRecords(): void {
		this.store.dispatch(phoneActions.checkToUpdateCallRecords());
	}

	public pollFetchRecentCalls(): void {
		this.store.dispatch(phoneActions.pollFetchRecentCalls());
	}

	public fetchRecentCalls(): void {
		this.store.dispatch(phoneActions.fetchRecentCalls());
	}

	public sendSmsToUser(phoneNumber: string, smsContent: string): void {
		const payload = { phoneNumber, smsContent };

		this.store.dispatch(phoneActions.sendSmsUserAction({ payload }));
	}

	public setUnconfiguredFooterState(unconfiguredFooter: boolean): void {
		const payload = { unconfiguredFooter };

		this.store.dispatch(phoneActions.setUnconfiguredFooterState({ payload }));
	}

	public fetchSmsAvailability(): void {
		this.store.dispatch(phoneActions.fetchSmsAvailability());
	}

	public fetchUserPreferences(): void {
		this.store.dispatch(phoneActions.fetchUserPreferences());
	}

	public setPhoneMode(action: PreferencesInterfaceAction): void {
		const user = this.authService.getUsername();
		const payload = { user, 'ctip-action': action };

		this.store.dispatch(phoneActions.setPhonePreferences({ payload }));
	}

	public setVoicemail(active: boolean): void {
		const user = this.authService.getUsername();
		const payload = { user, 'ctip-vm': !active };

		this.store.dispatch(phoneActions.setPhonePreferences({ payload }));
	}

	public setCallTry(command: OpcPanelCtiControl): void {
		const payload = this.phoneUtilService.getCtiActionPayload(command);

		this.store.dispatch(phoneActions.setCallTry({ payload }));
	}

	public setCallHold(command: OpcPanelCtiControl): void {
		const payload = this.phoneUtilService.getCtiActionPayload(command);

		this.store.dispatch(phoneActions.setCallHold({ payload }));
	}

	public setRecordCall(command: OpcPanelCtiControl): void {
		const payload = this.phoneUtilService.getCtiActionPayload(command);

		this.store.dispatch(phoneActions.setRecordCall({ payload }));
	}

	public handleSwitchTransferCall(command: OpcPanelCtiControl): void {
		const payload = this.phoneUtilService.getCtiActionPayload(command);

		this.store.dispatch(phoneActions.setCtiControl({ payload }));
	}

	public handleConferenceCall(): void {
		const payload = this.phoneUtilService.getCtiActionPayload(OpcPanelCtiControl.MIXCTIC);

		this.store.dispatch(phoneActions.handleConferenceCall({ payload }));
	}

	public setPriorityMode(priorityMode: boolean): void {
		const payload = { priorityMode };

		this.store.dispatch(phoneActions.setPriorityMode({ payload }));
	}

	public setCallRecordInfo(callRecords: IPhoneCallRecord[], existingCallRecordsInfo: IPhoneCallRecordInfo[]): void {
		const newCallRecordsInfo = this.phoneCallsLogService.formatCallRecordsToRecordsInfo(callRecords);
		const callRecordsInfo = this.phoneCallsLogService.mergeNewAndExistingCallRecordsInfo(newCallRecordsInfo, existingCallRecordsInfo);

		this.store.dispatch(phoneActions.setCallRecordsInfo({ payload: { callRecordsInfo } }));
	}

	public abortCall(): void {
		this.store.dispatch(phoneActions.abortCall());
	}

	public callUser(num: string, prefix: string, action: OpcPanelCtiControl): void {
		const payload = { num, prefix, action, intf: 'ctip' };

		this.store.dispatch(phoneActions.callUser({ payload }));
	}

	public setFetchError(active: boolean = true): void {
		const payload = { active };

		this.store.dispatch(phoneActions.setFetchError({ payload }));
	}
}
