import { Injectable } from '@angular/core';
import { AutomationCell } from '../../ts/enums/automation-cell.enum';
import { MainUtilService } from '../../../../services/main-util.service';
import { AutomationDeviceType } from '../../../automation/ts/enums/automation-device-type.enum';
import { AutomationLineDevice } from '../../../automation/ts/types/automation-line-device.type';
import { RioRvsIohubDeviceType } from '../../../rio-rvs/ts/enums/rio-rvs-iohub-device-type.enum';
import { AutomationGenericService } from '../../../automation/services/automation-generic.service';
import { IAutomationProgramEditLineDevice } from '../../ts/models/automation-program-edit-line-device.model';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramDevicesService {

	constructor() { }

	addInitialExpressionValueToOutputs(selectedDevices: IAutomationProgramEditLineDevice[]): IAutomationProgramEditLineDevice[] {
		return selectedDevices.map(selectedDevice => {
			const { type } = selectedDevice;

			return type === AutomationDeviceType.OUTPUT ? { ...selectedDevice, expressionValue: '' } : selectedDevice;
		});
	}

	setLineDevicesIndex(selectedDevices: IAutomationProgramEditLineDevice[]): IAutomationProgramEditLineDevice[] {
		return selectedDevices.map((selectedDevice, index) => ({ ...selectedDevice, index: index + 1 }));
	}

	static filterDevicesByType(
		selectedDevices: IAutomationProgramEditLineDevice[], deviceType: AutomationDeviceType
	): IAutomationProgramEditLineDevice[] {
		return selectedDevices.filter(selectedDevice => selectedDevice.type === deviceType);
	}

	mapLineDevicesWithIndex(selectedDevices: IAutomationProgramEditLineDevice[]): IAutomationProgramEditLineDevice[] {
		const inputDevices = AutomationProgramDevicesService.filterDevicesByType(selectedDevices, AutomationDeviceType.INPUT);
		const outputDevices = AutomationProgramDevicesService.filterDevicesByType(selectedDevices, AutomationDeviceType.OUTPUT);

		return [...this.setLineDevicesIndex(inputDevices), ...this.setLineDevicesIndex(outputDevices)];
	}

	formatProjectDevicesFromModal(selectedDevices: IAutomationProgramEditLineDevice[]): IAutomationProgramEditLineDevice[] {
		const lineDevices = this.mapLineDevicesWithIndex(this.formatSelectedDeviceToLuaTable(selectedDevices));

		return this.addInitialExpressionValueToOutputs(lineDevices);
	}

	formatSelectedDeviceToLuaTable(selectedDevices: IAutomationProgramEditLineDevice[]): IAutomationProgramEditLineDevice[] {
		return selectedDevices.map(lineDevice => {
			const { fullDeviceName, originalDeviceType } = lineDevice;

			const [deviceInfo, lineDeviceInfo] = AutomationGenericService.splitAutomationItemByComma(fullDeviceName as string);
			const [lineDeviceName, lineDeviceNumber] = AutomationGenericService.splitAutomationItemByDash(lineDeviceInfo);
			const data = AutomationGenericService.splitAutomationItemByComma(deviceInfo)[0];
			const [device, deviceNumber] = AutomationGenericService.splitAutomationItemByDash(data) as [RioRvsIohubDeviceType, string];
			const type = this.getDeviceType(originalDeviceType as AutomationLineDevice);

			return { ...lineDevice, device, deviceNumber: +deviceNumber, lineDeviceName, lineDeviceNumber: +lineDeviceNumber, type };
		});
	}

	getDeviceType(originalDeviceType: AutomationLineDevice): AutomationDeviceType {
		const inputDeviceType = AutomationDeviceType.INPUT;
		const lowercaseDeviceType = originalDeviceType.toLowerCase();

		return lowercaseDeviceType.includes(inputDeviceType) ? inputDeviceType : AutomationDeviceType.OUTPUT;
	}

	filterSpecifiedVout(
		selectedLineDevices: IAutomationProgramEditLineDevice[], fullDeviceName: string
	): IAutomationProgramEditLineDevice[] {
		return selectedLineDevices.filter(lineDevice => lineDevice.fullDeviceName !== fullDeviceName);
	}

	formatDragDropDevices(
		selectedLineDevices: IAutomationProgramEditLineDevice[], currentIndex: number, previousIndex: number
	): IAutomationProgramEditLineDevice[] {
		const inputDevices = AutomationProgramDevicesService.filterDevicesByType(selectedLineDevices, AutomationDeviceType.INPUT);
		const outputDevices = AutomationProgramDevicesService.filterDevicesByType(selectedLineDevices, AutomationDeviceType.OUTPUT);

		const deviceToBeMoved = this.getMatchingDeviceByIndex(outputDevices, previousIndex);
		const deviceToReplace = this.getMatchingDeviceByIndex(outputDevices, currentIndex);

		const updatedOutputDevices = MainUtilService.setItemAtIndex(outputDevices, deviceToReplace, previousIndex);
		const updatedMovedDevices = MainUtilService.setItemAtIndex(updatedOutputDevices, deviceToBeMoved, currentIndex);

		return [...inputDevices, ...updatedMovedDevices];
	}

	getMatchingDeviceByIndex(selectedLineDevices: IAutomationProgramEditLineDevice[], index: number): IAutomationProgramEditLineDevice {
		return selectedLineDevices.find((_, lineDeviceIndex) => lineDeviceIndex === index) as IAutomationProgramEditLineDevice;
	}

	isDigitalLineDevice(port: string): boolean {
		return port.toLowerCase().charAt(0) === 'd';
	}

	static getReferencedLineDevice(trimmedCell: string, lineDevices: IAutomationProgramEditLineDevice[]): IAutomationProgramEditLineDevice {
		const isInputTable = trimmedCell.toLowerCase().charAt(0) === AutomationCell.INPUT;
		const cellIndex = +trimmedCell.substring(1) - 1;

		if (isInputTable) {
			const inputDevices = AutomationProgramDevicesService.filterDevicesByType(lineDevices, AutomationDeviceType.INPUT);

			return inputDevices[cellIndex];
		}

		const outputDevices = AutomationProgramDevicesService.filterDevicesByType(lineDevices, AutomationDeviceType.OUTPUT);

		return outputDevices[cellIndex];
	}

	sortNonExpressionPorts(lineDevices: IAutomationProgramEditLineDevice[]): IAutomationProgramEditLineDevice[] {
		if (lineDevices.some(lineDeviceItem => lineDeviceItem.expression)) {
			return lineDevices.slice();
		}

		return lineDevices
			.slice()
			.sort((a, b) => a.lineDeviceNumber - b.lineDeviceNumber);
	}
}
