<ng-container *ngIf="sidebarFeature.action !== getSidebarFeatureAction.VIDEO; else videoSidebarTemplate">
	<a [href]="sidebarFeature.location" class="content" (click)="onFeatureOpen($event, sidebarFeature.action)" [ngClass]="{
		'content--active': sidebarFeatureName === sidebarFeature.action
	}" *ngIf="$any(sectionPermissions)?.[sidebarFeature.permission]">
		<img [src]="'assets/feather-icons/' + sidebarFeature.icon" alt="icon">
	</a>
</ng-container>

<ng-template #videoSidebarTemplate>
	<ng-container
		*ngIf="(((videorecorderPermission$ | async)?.tooltip) | nestedTranslation:true:(languageChanged$ | async)) as vsTooltip; else noTooltipVsTemplate">
		<div class="content content--inactive" [tooltip]="vsTooltip" placement="left"
			*ngIf="$any(sectionPermissions)?.[sidebarFeature.permission]">
			<img [src]="'assets/feather-icons/' + sidebarFeature.icon" alt="icon">
		</div>
	</ng-container>

	<ng-template #noTooltipVsTemplate>
		<a href="/#/videorecorder" class="content" (click)="onFeatureOpen($event, sidebarFeature.action)" [ngClass]="{
			'content--active': sidebarFeatureName === sidebarFeature.action
		}" *ngIf="$any(sectionPermissions)?.[sidebarFeature.permission]">
			<img [src]="'assets/feather-icons/' + sidebarFeature.icon" alt="icon">
		</a>
	</ng-template>
</ng-template>
