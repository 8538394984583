import { Action, createReducer, on } from '@ngrx/store';
import { INetworkingHttp } from '../ts/models/networking-http.model';
import * as httpActions from './http.actions';

export const httpKey = 'http';

export interface IHttpState {
	isLoading: boolean;
	httpConfiguration: INetworkingHttp | undefined;
}

export const initialHttpState: IHttpState = {
	isLoading: true,
	httpConfiguration: undefined
};

const httpReducer = createReducer(
	initialHttpState,
	on(httpActions.finishLoadingHttpConfiguration, (_, { payload }) => ({ ...payload, isLoading: false })),
	on(httpActions.setHttpConfigurationLoader, (state, { payload }) => ({ ...state, isLoading: payload.isLoading }))
);

export function HttpReducer(state: IHttpState | undefined, action: Action) {
	return httpReducer(state, action);
}
