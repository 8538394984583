import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AutomationService } from './automation.service';
import { environment } from '../../../../environments/environment';
import { IAutomationMapDevice } from '../ts/models/automation-map-device.model';
import { IAutomationMapBackground } from '../ts/models/automation-map-background.model';
import { IAutomationBackgroundImageSize } from '../ts/models/automation-background-image-size.model';

@Injectable({
	providedIn: 'root'
})
export class AutomationMapUiService {

	private loadBackgroundState: Subject<void> = new Subject();
	private backgroundImageSize = { top: '0', left: '0', width: '300', height: '300' };

	constructor(
		private automationService: AutomationService
	) { }

	addElementOffsetOnMapResize(
		devicesUsedByMap: IAutomationMapDevice[] | null, initialHeight: number,
		initialWidth: number, newHeight: number, newWidth: number
	): IAutomationMapDevice[] {
		if (!devicesUsedByMap || !devicesUsedByMap?.length) { return []; }

		const widthOffset = newWidth / initialWidth;
		const heightOffset = newHeight / initialHeight;

		return devicesUsedByMap.map(device => {
			const newTopPosition = Math.floor(heightOffset * Number.parseFloat(device.newTopPosition as string)).toString();
			const newLeftPosition = Math.floor(widthOffset * Number.parseFloat(device.newLeftPosition as string)).toString();

			return { ...device, newTopPosition, newLeftPosition };
		});
	}

	setBackgroundImageWidthHeight(width: string, height: string): void {
		this.backgroundImageSize = { ...this.backgroundImageSize, width, height };
	}

	setBackgroundImageTopLeftPosition(backgroundElement: HTMLDivElement | null, leftOffset?: string, topOffset?: string): void {
		if (backgroundElement) {
			const { left, top } = this.getElementLeftTopPosition(backgroundElement);

			this.backgroundImageSize = { ...this.backgroundImageSize, left, top };
			return;
		}

		this.backgroundImageSize = {
			...this.backgroundImageSize, left: leftOffset as string, top: topOffset as string
		};
	}

	getElementLeftTopPosition(resizableElement: HTMLDivElement): { left: string; top: string } {
		// Get new left and top device positions
		const deviceLeftPosition = Number.parseInt(resizableElement.style.left, 10) || 0;
		const deviceTopPosition = Number.parseInt(resizableElement.style.top, 10) || 0;
		const parenthesisRegExp = /\(([^)]+)\)/;
		// Get transform values from match group
		const dataBetweenParenthesis = resizableElement.style.transform.match(parenthesisRegExp) as RegExpMatchArray;
		const [leftOffset, topOffset] = dataBetweenParenthesis[1]
			.split(',')
			.map(translateValue => Number.parseInt(translateValue.trim(), 10));
		// Get new left and top positions
		return { left: (deviceLeftPosition + (leftOffset || 0)).toFixed(0), top: (deviceTopPosition + (topOffset || 0)).toFixed(0) };
	}

	setMapImageOnError(
		backgroundImagePath: string, selectedMapNumber: number,
		backgroundImageId: string, designMapMode: boolean
	): string {
		const mapPrefix = this.automationService.getMapPrefix(designMapMode);

		if (backgroundImagePath.includes('.png')) {
			return environment.abilisUrl + `/sys/io/${mapPrefix}/map${selectedMapNumber}/`
				+ `map${selectedMapNumber}-background` + '.jpg' + `?backgroundId=${backgroundImageId}`;
		} else if (backgroundImagePath.includes('.jpg')) {
			return environment.abilisUrl + `/sys/io/${mapPrefix}/map${selectedMapNumber}/`
				+ `map${selectedMapNumber}-background` + '.gif' + `?backgroundId=${backgroundImageId}`;
		} else if (backgroundImagePath.includes('.gif')) {
			return environment.abilisUrl + `/sys/io/${mapPrefix}/map${selectedMapNumber}/`
				+ `map${selectedMapNumber}-background` + '.bmp' + `?backgroundId=${backgroundImageId}`;
		}

		return '';
	}

	setMapLoadBackgroundEvent(): void {
		this.loadBackgroundState.next();
	}

	getMapLoadBackgroundEvent$(): Observable<void> {
		return this.loadBackgroundState.asObservable();
	}

	setInitialBackgroundInfo(mapBackground: IAutomationMapBackground): void {
		const { top, left, width, height } = mapBackground;

		this.setBackgroundImageWidthHeight(width.toString(), height.toString());
		this.setBackgroundImageTopLeftPosition(null, left, top);
	}

	getBackgroundPath(designMapMode: boolean, selectedMapNumber: number, backgroundImageId: string): string {
		const mapPrefix = this.automationService.getMapPrefix(designMapMode);

		return environment.abilisUrl + `/sys/io/${mapPrefix}/map${selectedMapNumber}/`
			+ `map${selectedMapNumber}-background` + '.png' + `?backgroundId=${backgroundImageId}`;
	}

	getBackgroundImageSize(): IAutomationBackgroundImageSize {
		return this.backgroundImageSize;
	}
}
