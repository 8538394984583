import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormArray, FormBuilder } from '@angular/forms';
import { GenericTableBuilder } from '../../../networking/common/classes/table-builder.class';
import { IGenericTableRow } from '../../../ui-components/generic-table/ts/models/generic-table-row';
import { IGenericTableHeader } from '../../../ui-components/generic-table/ts/models/generic-table-header';
import { IGenericUnsetSelectedRow } from '../../../ui-components/generic-table/ts/models/generic-unset-selected-row.model';

@Injectable({
	providedIn: 'root'
})
export class RioRvsTableService extends GenericTableBuilder {

	private errorMessageState: BehaviorSubject<string> = new BehaviorSubject('');
	private unsetSelectedRowState = new BehaviorSubject({ unsetSelectedRow: false });

	private readonly otherAbilis = 'owner---EQUAL---other';
	private readonly freeCondition = 'owner---EQUAL---none';
	private readonly configurationLimitTrueCondition = 'configurationLimitReached---EQUAL---true';

	private conditionalStyleValues = {
		conditionalFalseStyle: ['', '', ''],
		conditionalTrueStyle: ['font-weight: bold', 'color: #727272', 'color: red'],
		conditonalStyleCondition: [this.freeCondition, this.otherAbilis, this.configurationLimitTrueCondition]
	};

	constructor(
		private formBuilder: FormBuilder
	) {
		super();
	}

	buildTableHeaders(): IGenericTableHeader[] {
		return [
			{ header: { headerName: 'AUTO.PREVIEW.headMacAddr' }, sort: { useSort: false } },
			{ header: { headerName: 'AUTO.PREVIEW.headIpAddr' }, sort: { useSort: false } },
			{ header: { headerName: 'AUTO.PREVIEW.headResource' }, sort: { useSort: false } },
			{ header: { headerName: 'AUTO.PREVIEW.headDevDescription' }, sort: { useSort: false } },
			{ header: { headerName: 'AUTO.PREVIEW.headStatus' }, sort: { useSort: false } },
			{ header: { headerName: '', isAction: true }, sort: { useSort: false } }
		];
	}

	buildTableRows(): IGenericTableRow[] {
		return [
			{ cell: { cellName: 'mac', visibleCellCondition: true, ...this.conditionalStyleValues }, edit: { editMode: false } },
			{
				cell: { cellName: 'ipaddr', visibleCellCondition: true, ...this.conditionalStyleValues },
				edit: { editMode: false }
			},
			{
				cell: {
					cellName: 'resource', visibleCellCondition: 'owner---NOTEQUAL---none', ...this.conditionalStyleValues
				}, edit: { editMode: false }
			},
			{
				cell: { cellName: 'descr', visibleCellCondition: true, ...this.conditionalStyleValues, translateValue: true },
				edit: { editMode: false }
			},
			{
				cell: {
					cellName: 'translateOwnerField,configurationLimitReached', ...this.conditionalStyleValues, visibleCellCondition: true,
					iconCell: 'configurationLimitReached', upIconName: 'alert-triangle-red.svg', showIconUpState: true,
					showIconDownState: false, translateValue: true
				},
				edit: { editMode: false }
			},
			{
				cell: { cellName: '', visibleCellCondition: true, isAction: true },
				edit: { editMode: false }
			}
		];
	}

	buildForm(): FormArray {
		return this.formBuilder.array([]);
	}

	setResponseErrorMessage(errorMessage: string = ''): void {
		this.errorMessageState.next(errorMessage);
	}

	getErrorMessageState$(): Observable<string> {
		return this.errorMessageState.asObservable();
	}

	setUnselectedRowState(rowState: boolean): void {
		this.unsetSelectedRowState.next({ unsetSelectedRow: rowState });
	}

	getUnsetSelectedRowState$(): Observable<IGenericUnsetSelectedRow> {
		return this.unsetSelectedRowState;
	}
}
