import { of, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddressBookHttpService } from '../services/http/address-book-http.service';
import { AddressBookContactService } from '../services/http/address-book-contact.service';
import { AddressBookContactArrayService } from '../services/address-book-contact-array.service';
import { AddressBookContactUtilService } from './../services/address-book-contact-util.service';
import { AddressBookNgrxActionService } from '../services/ngrx/address-book-ngrx-action.service';
import { AddressBookNgrxSelectorService } from './../services/ngrx/address-book-ngrx-selector.service';
import { AddressBookEffectsHandlerService } from '../services/ngrx/address-book-effects-handler.service';
import { concatMap, map, switchMap, tap, withLatestFrom, takeUntil, filter, take } from 'rxjs/operators';
import { AddressBookCsvHandlerService } from '../services/import-export/address-book-csv-handler.service';
import { IAddressBookAddEditActionResponse } from '../ts/models/address-book-add-edit-action-response.model';
import * as addressBookActions from './address-book.action';

@Injectable()
export class AddressBookEffects {

	constructor(
		private readonly actions$: Actions,
		private readonly addressBookHttpService: AddressBookHttpService,
		private readonly addressBookContactService: AddressBookContactService,
		private readonly addressBookCsvHandlerService: AddressBookCsvHandlerService,
		private readonly addressBookNgrxActionService: AddressBookNgrxActionService,
		private readonly addressBookContactUtilService: AddressBookContactUtilService,
		private readonly addressBookNgrxSelectorService: AddressBookNgrxSelectorService,
		private readonly addressBookEffectsHandlerService: AddressBookEffectsHandlerService
	) { }

	fetchAddressBook$ = createEffect(() => this.actions$.pipe(
		ofType(addressBookActions.fetchAddressBook),
		switchMap(action => this.addressBookHttpService.fetchContacts$(action.payload.username)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(addressBookActions.cancelAddressBookHTTPObservables)))
			)),
		map(contacts => {
			const payload = { contacts };
			this.addressBookNgrxActionService.fetchAddressbookPermissionInfo();

			return addressBookActions.addressBoookFetchCompleted({ payload });
		})
	));

	fetchAddressbookPermissionInfo$ = createEffect(() => this.actions$.pipe(
		ofType(addressBookActions.fetchAddressbookPermissionInfo),
		switchMap(() => this.addressBookContactService.fetchContactPermissions$()
			.pipe(
				takeUntil(this.actions$.pipe(ofType(addressBookActions.cancelAddressBookHTTPObservables)))
			)
		),
		map(permissions => addressBookActions.finishLoadingAddressbookInfo({ payload: { permissions } }))
	));

	addContact$ = createEffect(() => this.actions$.pipe(
		ofType(addressBookActions.addContact),
		switchMap(action => this.addressBookEffectsHandlerService.formatAddContactPayloadData$(action.payload)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(addressBookActions.cancelAddressBookHTTPObservables)))
			)),
		concatMap(response => this.addressBookEffectsHandlerService.handleAddEditContact$(response)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(addressBookActions.cancelAddressBookHTTPObservables)))
			)),
		filter((response): response is IAddressBookAddEditActionResponse => response !== null),
		tap(response => this.addressBookEffectsHandlerService.handleAddContact(response))
	), { dispatch: false });

	editContact$ = createEffect(() => this.actions$.pipe(
		ofType(addressBookActions.editContact),
		tap(action => {
			const { selectedContact } = action.payload;

			this.addressBookNgrxActionService.setContactRowDisabledValue({ ...selectedContact, rowDisabled: true });
		}),
		switchMap(action => this.addressBookEffectsHandlerService.formatEditContactPayloadData$(action.payload)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(addressBookActions.cancelAddressBookHTTPObservables)))
			)
		),
		concatMap(response => this.addressBookEffectsHandlerService.handleAddEditContact$(response, true)
			.pipe(
				tap(() => {
					const { selectedContact } = response.payload;
					this.addressBookNgrxActionService.setContactRowDisabledValue({ ...selectedContact, rowDisabled: false });
				}),
				takeUntil(this.actions$.pipe(ofType(addressBookActions.cancelAddressBookHTTPObservables)))
			)),
		filter((response): response is IAddressBookAddEditActionResponse => response !== null),
		tap(response => {
			const { username, editAddContactResponse } = response;

			this.addressBookContactUtilService.handleAddEditContactResponse(editAddContactResponse, username);
		})
	), { dispatch: false });

	deleteContacts$ = createEffect(() => this.actions$.pipe(
		ofType(addressBookActions.deleteContacts),
		switchMap(action => forkJoin({
			username: of(action.payload.username),
			contacts: this.addressBookNgrxSelectorService.selectAddressBookContacts$().pipe(take(1))
		})),
		switchMap(response => {
			const { username, contacts } = response;
			const deleteResponse = this.addressBookEffectsHandlerService.handleDeleteContacts$(contacts, username);

			return forkJoin({ username: of(username), deleteResponse });
		}),
		map(response => {
			const { username } = response;

			return addressBookActions.fetchAddressBook({ payload: { username } });
		})
	));

	exportContacts$ = createEffect(() => this.actions$.pipe(
		ofType(addressBookActions.exportContacts),
		withLatestFrom(this.addressBookNgrxSelectorService.selectAddressBookContacts$()),
		tap(response => {
			const [action, contacts] = response;
			const { username } = action.payload;
			const selectedContacts = AddressBookContactArrayService.getSelectedContacts(contacts);

			this.addressBookCsvHandlerService.exportCsv(selectedContacts, username);
		})
	), { dispatch: false });

	setCheckedContactState$ = createEffect(() => this.actions$.pipe(
		ofType(addressBookActions.setCheckedContactsState),
		withLatestFrom(this.addressBookNgrxSelectorService.selectAddressBookContacts$()),
		map(response => {
			const [action, contacts] = response;
			const { isChecked } = action.payload;
			const updatedContacts = AddressBookContactArrayService.setAllContactsCheckedState(
				contacts.filter((contact) => contact.isInView
				), isChecked);

			return addressBookActions.updateContacts({ payload: { contacts: updatedContacts } });
		})
	));
}
