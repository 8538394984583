import { createAction, props } from '@ngrx/store';
import { IRecentCall } from '../ts/models/recent-call-table.model';

export const loadAllUsersRecentCalls = createAction(
	'[Recent Call] Load All Users Recent Calls'
);

export const loadSingleUsersRecentCalls = createAction(
	'[Recent Call] Load Single Users Recent Calls',
	props<{ payload: { username: string } }>()
);

export const setCallEnabledCtip = createAction(
	'[Recent Call] Set Call Enabled Ctip',
	props<{ payload: { callenabledCtip: boolean } }>()
);

export const finishLoadingRecentCalls = createAction(
	'[Recent Call] Finish Loading Recent Calls',
	props<{ payload: { recentCalls: IRecentCall[] } }>()
);

export const fetchRecentCallDebugInfo = createAction(
	'[Recent Call] Fetch Recent Call Debug Info',
	props<{ payload: { recentCallItem: IRecentCall } }>()
);

export const setRecentCallDebugInfo = createAction(
	'[Recent Call] Set Recent Call Debug Info',
	props<{ payload: { recentCallDebugInfo: string | null } }>()
);

export const pollFetchRecentCalls = createAction(
	'[Recent Call] Poll Fetch Recent Calls',
	props<{ payload: { username: string } }>()
);

export const cancelRecentCallHTTPObservables = createAction(
	'[Recent Call] Cancel Fetch Observables'
);

export const clearRecentCalls = createAction(
	'[Recent Call] Clear Recent Calls',
	props<{ payload: { username: string } }>()
);

export const exportRecentCalls = createAction(
	'[Recent Call] Export Recent Call',
	props<{ payload: { username: string } }>()
);

export const updateCheckedCallState = createAction(
	'[Recent Call] Update Checked Call State',
	props<{ payload: { contact: IRecentCall } }>()
);

export const uncheckAllRecentCalls = createAction(
	'[Recent Call] Uncheck All Recent Calls'
);

export const setUncheckAllRecentCalls = createAction(
	'[Recent Call] Set Uncheck All Recent Calls',
	props<{ payload: { recentCalls: IRecentCall[] } }>()
);

export const setIsLoading = createAction(
	'[Recent Call] Set Is Loading',
	props<{ payload: { isLoading: boolean } }>()
);
