<footer class="wrapper">
	<div class="content">
		<div>
			<p>
				{{ 'FOOTER.version' | translate}}:
				<span class="content__version" routerLink="release-update">{{version}}</span>
			</p>
			<p>&copy; {{currentDate | date:'yyyy'}} Abilis. {{ 'FOOTER.rights' | translate}} </p>
		</div>
	</div>
</footer>
