import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastrType } from '../../../ts/enums/toastr-type.enum';
import { MainUtilService } from '../../../services/main-util.service';
import { ResponseStatus } from '../../../ts/enums/response-status.enum';
import { VirtualOfficeSide } from '../ts/enums/virtual-office-side.enum';
import { IVirtualOfficeUser } from '../ts/models/virtual-office-user.model';
import { IVirtualOfficeNumber } from '../ts/models/virtual-office-number.model';
import { IVirtualOfficeTableItem } from '../ts/models/virtual-office-table-item.model';
import { IVirtualOfficeActionResponse } from '../ts/models/virtual-office-action-response.model';
import { IVirtualOfficeFormattedNumber } from '../ts/models/virtual-office-formatted-number.model';
import * as uuid from 'uuid';

@Injectable({
	providedIn: 'root'
})
export class VirtualOfficeService {

	private setFormInitialValuesEvent: Subject<void> = new Subject();

	constructor(
		private readonly mainUtilService: MainUtilService
	) { }

	public formatVirtualOfficeUserData(users: IVirtualOfficeUser[]): IVirtualOfficeTableItem[] {
		return users.map(user => this.formatFirstCurrentNumbers(user)) as IVirtualOfficeTableItem[];
	}

	public handleActionResponse(response: IVirtualOfficeActionResponse, setUuid: (uuid: string | undefined) => void): boolean {
		const { message, Uuid } = response.Response;

		setUuid(Uuid);

		const isOkAction = this.isActionOk(message);
		const toastrType = isOkAction ? ToastrType.SUCCESS : ToastrType.ERROR;

		this.mainUtilService.showToastrMessage(message, toastrType);

		return isOkAction;
	}

	public formatVirtualOfficeUserNumbers(users: IVirtualOfficeUser[]): IVirtualOfficeFormattedNumber[] {
		return users.reduce((accumulator, item) => {
			const numbersFormatted = item.numbers.map(numberItem => {
				const { user } = item;
				const { num, side } = numberItem;
				const current = this.getCurrentValue(numberItem.current);

				return { user, num, current, side };
			});

			return [...accumulator, ...numbersFormatted];
		}, [] as IVirtualOfficeFormattedNumber[]);
	}

	public emitSetFormInitialValuesEvent(): void {
		this.setFormInitialValuesEvent.next();
	}

	public watchSetFormInitialValuesEvent$(): Observable<void> {
		return this.setFormInitialValuesEvent.asObservable();
	}

	private formatFirstCurrentNumbers(item: IVirtualOfficeUser): IVirtualOfficeTableItem {
		const { numbers, user } = item;
		const firstNetNumber = this.getMatchingFirstNumber(numbers, VirtualOfficeSide.NET);
		const firstUserNumber = this.getMatchingFirstNumber(numbers, VirtualOfficeSide.USER);

		const id = uuid.v4();
		const netNum = this.getNumber(firstNetNumber);
		const userNum = this.getNumber(firstUserNumber);
		const netCur = this.getCurrentState(firstNetNumber);
		const userCur = this.getCurrentState(firstUserNumber);

		return { id, user, userNum, userCur, netNum, netCur };
	}

	private getMatchingFirstNumber(numbers: IVirtualOfficeNumber[], side: VirtualOfficeSide): IVirtualOfficeNumber | null {
		return numbers.find(numberItem => numberItem.side === side && numberItem.current) || null;
	}

	private getNumber(numberItem: IVirtualOfficeNumber | null): string {
		return numberItem ? numberItem.num : 'N/A';
	}

	private getCurrentState(numberItem: IVirtualOfficeNumber | null): boolean {
		return numberItem?.current || false;
	}

	private getCurrentValue(current: boolean): string {
		return current ? '{{ADMIN.VIRTUALOFFICE.tblhdrCur}}' : '';
	}

	private isActionOk(message: string): boolean {
		return message.toLowerCase() === ResponseStatus.OK;
	}
}
