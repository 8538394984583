import { Action, createReducer, on } from '@ngrx/store';
import { ISearchItem } from './../../../../../components/core/ts/models/search-item.model';
import * as sidebarSearchActions from './side-bar-search.actions';

export const sideBarSearchFeatureKey = 'sideBarSearch';

export interface ISideBarSearchState {
	EN: ISearchItem[];
	IT: ISearchItem[];
	filteredPages: ISearchItem[];
}

export const initialState: ISideBarSearchState = {
	EN: [],
	IT: [],
	filteredPages: []
};

export const sidebarSearchReducer = createReducer(
	initialState,
	on(sidebarSearchActions.finishLoadingPages, (state, { payload }) => ({ ...state, ...payload.pages })),
	on(sidebarSearchActions.setPagesFilteredBySearchTerm, (state, { payload }) => ({ ...state, filteredPages: payload.pages }))
);

export function SidebarSearchReducer(state: ISideBarSearchState | undefined, action: Action) {
	return sidebarSearchReducer(state, action);
}
