import { Injectable } from '@angular/core';
import { OpcPanelUtilService } from './opc-panel-util.service';
import { OpcPanelCardService } from './card/opc-panel-card.service';
import { OpcPanelInterface } from '../ts/enums/opc-panel-interface.enum';
import { IOpcPanelUserCard } from '../ts/models/opc-panel-user-card.model';
import { OpcPanelCardCallService } from './card/opc-panel-card-call.service';
import { OpcPanelCallDirection } from '../ts/enums/opc-panel-call-direction.enum';
import { IOpcPanelUserRecord } from '../ts/models/session/opc-panel-user-record.model';
import { OpcPanelUserRecordState } from '../ts/enums/opc-panel-user-record-state.enum';
import { IOpcPanelCallRecord } from '../ts/models/session/opc-panel-call-record.model';
import { OpcPanelCallRecordState } from '../ts/enums/opc-panel-call-record-state.enum';
import { OpcPanelCallRecordHandlerService } from './opc-panel-call-record-handler.service';
import { OpcPanelUserRecordPicture } from '../ts/enums/opc-panel-user-record-picture.enum';
import { OpcPanelUserRecordCallState } from '../ts/enums/opc-panel-user-record-call-state.enum';
import { OpcPanelCtipPotsDevice } from '../ts/enums/opc-panel-ctip-if-subtype-pots-device.enum';

@Injectable({
	providedIn: 'root'
})
export class OpcPanelUserRecordHandlerService {

	constructor(
		private opcPanelCardService: OpcPanelCardService,
		private opcPanelCardCallService: OpcPanelCardCallService,
		private opcPanelCallRecordHandlerService: OpcPanelCallRecordHandlerService
	) { }

	replaceUserRecordsWithSessionRecords(
		users: IOpcPanelUserCard[], matchingGroupsUserRecords: IOpcPanelUserRecord[]
	): IOpcPanelUserCard[] {
		this.opcPanelCardService.setLoggedUserRecords(matchingGroupsUserRecords);

		const loggedUserCallRecords = this.opcPanelCardService.getLoggedUserCallRecords(users);
		const isCallToCardAvailable = this.opcPanelCardCallService.isCallToCardAvailable(loggedUserCallRecords);

		return users.map(userItem => {
			const { id, callRecords } = userItem;

			const userRecords = matchingGroupsUserRecords.reduce((accumulator, userRecordItem) => {
				const { abilis, user, interface: userInterface, Picture } = userRecordItem;
				const userId = OpcPanelUtilService.createUserId(abilis, user);

				if (userId === id) {
					const isPictureChanged = Picture === OpcPanelUserRecordPicture.CHANGED;
					const pictureRefreshTimestamp = isPictureChanged ? new Date().getTime() + '' : '';
					const userRecordId = OpcPanelUtilService.createOpcItemId(abilis, user, userInterface);

					return [...accumulator, { ...userRecordItem, id: userRecordId, pictureRefreshTimestamp }];
				}

				return accumulator;
			}, [] as IOpcPanelUserRecord[]);

			if (userRecords.length) {
				const { userInterfacesData } = userItem;
				const updatedUserInterfaces = this.updateUserInterfacesWithUserRecords(userInterfacesData, userRecords, callRecords);
				const updatedUserItem = { ...userItem, userInterfacesData: updatedUserInterfaces };

				return this.checkCardCallFunctionality(updatedUserItem, isCallToCardAvailable);
			}

			return this.checkCardCallFunctionality(userItem, isCallToCardAvailable);
		});
	}

	checkCardCallFunctionality(userItem: IOpcPanelUserCard, isCallToCardAvailable: boolean): IOpcPanelUserCard {
		const { userInterfacesData } = userItem;
		const updatedUserInterfaceData = userInterfacesData.map(userInterfaceItem => {
			const isCardCallable = this.opcPanelCardService.isCardCallable(userInterfaceItem);
			const canCallCard = isCardCallable && isCallToCardAvailable;

			return { ...userInterfaceItem, canCallCard };
		});

		return { ...userItem, userInterfacesData: updatedUserInterfaceData };
	}

	updateUserInterfacesWithUserRecords(
		userInterfacesData: IOpcPanelUserRecord[], userRecords: IOpcPanelUserRecord[], callRecords: IOpcPanelCallRecord[]
	): IOpcPanelUserRecord[] {
		return userInterfacesData.map(userInterfaceItem => {
			const { id: userInterfaceId, enabled, main } = userInterfaceItem;
			const matchingUserRecord = userRecords.find(userRecordItem => userRecordItem.id === userInterfaceId);

			if (matchingUserRecord) {
				const updatedInterface = this.formatUserRecordByState(matchingUserRecord, userInterfacesData, callRecords);
				// added enabled/main manually in order to be kept
				return this.displayButtonByState({ ...updatedInterface, enabled, main }, callRecords);
			}

			return userInterfaceItem;
		});
	}

	displayButtonByState(updatedInterface: IOpcPanelUserRecord, callRecords: IOpcPanelCallRecord[]): IOpcPanelUserRecord {
		const hasNotNullCall = this.opcPanelCardCallService.hasNotNullCall(callRecords);

		const { role } = this.opcPanelCardService.getLoggedUserInfo();
		const transferActiveEnabled = this.opcPanelCardCallService.canTransferActive(updatedInterface, callRecords);
		const transferAlertingEnabled = this.opcPanelCardCallService.canTransferAlerting(updatedInterface, role, callRecords);

		if (hasNotNullCall && (transferAlertingEnabled || transferActiveEnabled)) {
			const showKeypadIcon = true;
			const isKeypadIconForTransfer = transferAlertingEnabled;
			const keypadIconText = OpcPanelUserRecordHandlerService.getKeypadIconText(isKeypadIconForTransfer);

			return { ...updatedInterface, showKeypadIcon, isKeypadIconForTransfer, keypadIconText };
		}

		return { ...updatedInterface, showKeypadIcon: false, showMoreCallsIcon: false };
	}

	formatUserRecordByState(
		matchingUserRecord: IOpcPanelUserRecord, userInterfaceData: IOpcPanelUserRecord[], callRecords: IOpcPanelCallRecord[]
	): IOpcPanelUserRecord {
		const { interface: userInterface, Call } = matchingUserRecord;

		if (userInterface === OpcPanelInterface.SIP && Call.State === OpcPanelUserRecordCallState.ACTIVE) {
			return this.opcPanelCallRecordHandlerService.getUpdatedInterfacesByFakeSipCall(
				matchingUserRecord, Call, userInterfaceData, true
			) as IOpcPanelUserRecord;
		}

		const { State } = matchingUserRecord;

		switch (State) {
		case OpcPanelUserRecordState.ONHOOK:
			return this.handleOnhookState(matchingUserRecord, callRecords);
		case OpcPanelUserRecordState.OFFHOOK:
			return this.handleOffhookState(matchingUserRecord, callRecords);
		case OpcPanelUserRecordState.READY:
			return this.handleReadyState(matchingUserRecord, userInterfaceData, callRecords);
		case OpcPanelUserRecordState.RINGING:
			return this.handleRingingState(matchingUserRecord, callRecords);
		case OpcPanelUserRecordState.NULL:
		case OpcPanelUserRecordState.FAIL:
		case OpcPanelUserRecordState.UNAVAIL:
		case OpcPanelUserRecordState.NOTREADY:
			return this.handleFailedStates(matchingUserRecord, callRecords);
		default:
			return this.handleFailedStates(matchingUserRecord, callRecords);
		}
	}

	handleRingingState(matchingUserRecord: IOpcPanelUserRecord, callRecords: IOpcPanelCallRecord[]): IOpcPanelUserRecord {
		const { role } = this.opcPanelCardService.getLoggedUserInfo();
		const updatedRecord = { ...matchingUserRecord, isRinging: true };

		const transferActiveEnabled = this.opcPanelCardCallService.canTransferActive(matchingUserRecord, callRecords);
		const transferAlertingEnabled = this.opcPanelCardCallService.canTransferAlerting(matchingUserRecord, role, callRecords);

		if (transferActiveEnabled || transferAlertingEnabled) {
			const showKeypadIcon = true;
			const isKeypadIconForTransfer = transferAlertingEnabled;
			const keypadIconText = OpcPanelUserRecordHandlerService.getKeypadIconText(isKeypadIconForTransfer);

			return { ...updatedRecord, showKeypadIcon, isKeypadIconForTransfer, keypadIconText, isDraggable: true };
		}

		return { ...updatedRecord, showKeypadIcon: false, isDraggable: false };
	}

	static getKeypadIconText(isKeypadIconForTransfer: boolean): string {
		return isKeypadIconForTransfer ? 'Transfer call to another number' : 'Call another number';
	}

	handleFailedStates(matchingUserRecord: IOpcPanelUserRecord, callRecords: IOpcPanelCallRecord[]): IOpcPanelUserRecord {
		const hasNoCalls = !this.opcPanelCardCallService.hasNotNullCall(callRecords);

		return !hasNoCalls ? this.clearUserRecord(matchingUserRecord) : matchingUserRecord;
	}

	handleReadyState(
		matchingUserRecord: IOpcPanelUserRecord, userInterfaceData: IOpcPanelUserRecord[], callRecords: IOpcPanelCallRecord[]
	): IOpcPanelUserRecord {
		const { interface: userInterface, Call } = matchingUserRecord;
		const { State } = Call;

		const isSipInterface = userInterface === OpcPanelInterface.SIP;
		const hasNoCalls = !this.opcPanelCardCallService.hasNotNullCall(callRecords);

		if (isSipInterface) {
			const isInactiveCallState = State === OpcPanelUserRecordCallState.INACTIVE;

			if (hasNoCalls) {
				if (isInactiveCallState) {
					return this.clearUserRecord(matchingUserRecord);
				}
			} else {
				const hasAlertingOutgoingCall = this.opcPanelCardCallService.hasCallRecordByStateAndDirection(
					callRecords, OpcPanelCallRecordState.ALERTING, OpcPanelCallDirection.OUT
				);

				const isRinging = !(isInactiveCallState && hasAlertingOutgoingCall);
				const firstNotNullCall = this.opcPanelCardCallService.getFirstNotNullCall(callRecords);

				if (firstNotNullCall) {
					const call = { ...firstNotNullCall, isRinging };

					this.opcPanelCallRecordHandlerService.formatCallRecordItemByState(
						matchingUserRecord, userInterfaceData, callRecords, call
					);
				}
			}
		}

		if (hasNoCalls) {
			return this.clearUserRecord(matchingUserRecord);
		}

		return matchingUserRecord;
	}

	handleOffhookState(matchingUserRecord: IOpcPanelUserRecord, callRecords: IOpcPanelCallRecord[]): IOpcPanelUserRecord {
		const hasNotNullCalls = this.opcPanelCardCallService.hasNotNullCall(callRecords);

		if (!hasNotNullCalls) {
			return {
				...matchingUserRecord, isRinging: false, contactNumber: '', showKeypadIcon: false,
				isDraggable: false, contactName: '', recordDirection: OpcPanelCallDirection.IN
			};
		}

		return { ...matchingUserRecord, isRinging: false, showKeypadIcon: false, isDraggable: false };
	}

	handleOnhookState(matchingUserRecord: IOpcPanelUserRecord, callRecords: IOpcPanelCallRecord[]): IOpcPanelUserRecord {
		const hasHeadset = this.opcPanelCardCallService.isMatchingPotsDevice(matchingUserRecord, OpcPanelCtipPotsDevice.HEADSET);
		const hasNotNullCalls = this.opcPanelCardCallService.hasNotNullCall(callRecords);

		if (hasHeadset || !hasNotNullCalls) {
			return this.clearUserRecord(matchingUserRecord);
		}

		return { ...matchingUserRecord, isRinging: false, showKeypadIcon: false, isDraggable: false };
	}

	getContactName(contactName: string, contactNumber: string): string {
		return contactName === '' ? contactNumber : contactName;
	}

	static updateUserInterface(updatedInterface: IOpcPanelUserRecord, userInterfacesData: IOpcPanelUserRecord[]): IOpcPanelUserRecord[] {
		return userInterfacesData.map(userInterfaceDataItem => {
			const { id } = userInterfaceDataItem;

			return id === updatedInterface.id ? updatedInterface : userInterfaceDataItem;
		});
	}

	clearUserRecord(matchingUserRecord: IOpcPanelUserRecord): IOpcPanelUserRecord {
		return {
			...matchingUserRecord, isRinging: false, showKeypadIcon: false,
			recordDirection: null, isDraggable: false, contactNumber: '', contactName: ''
		};
	}

	isUserRecordMatching(userRecords: IOpcPanelUserRecord[], loggedUsername: string): boolean {
		return userRecords.some(userRecord => userRecord.user === loggedUsername && (userRecord.interface != null));
	}
}
