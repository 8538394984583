import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from '../../../../../ts/enums/routes.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PermissionTabService } from '../../../services/permission-tab.service';
import { SidebarFeatureNames } from '../../../ts/enums/sidebar-feature-names.enum';
import { FeaturePermissionsName } from '../../../ts/enums/feature-permission-name.enum';
import { RouterUtilService } from './../../../../../services/utils/router-util.service';
import { AuthNgrxService } from '../../../../../components/auth/services/auth-ngrx.service';
import { IFeaturePermissionsItem } from '../../../ts/models/feature-permissions-item.model';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, inject } from '@angular/core';
import { NetworkingInfoNgrxService } from '../../../../networking-info/services/networking-info-ngrx.service';
import { SideBarService } from './../../../../../standalone/components/ui/side-bar/services/side-bar.service';

@UntilDestroy()
@Component({
	selector: 'app-network-preview',
	templateUrl: './network-preview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NetworkPreviewComponent implements OnInit, OnDestroy {

	public readonly router: Router;
	private readonly activatedRoute: ActivatedRoute;

	public networkSettingsPermission$: Observable<IFeaturePermissionsItem | null>;

	haveAdminUserLevel$: Observable<boolean>;

	constructor(
		private readonly sideBarService: SideBarService,
		private readonly authNgrxService: AuthNgrxService,
		private readonly routerUtilService: RouterUtilService,
		private readonly permissionTabService: PermissionTabService,
		private readonly networkingInfoNgrxService: NetworkingInfoNgrxService
	) {
		this.router = inject(Router);
		this.activatedRoute = inject(ActivatedRoute);

		this.sideBarService.setActiveSidebarItem(SidebarFeatureNames.NETWORK);
	}

	public ngOnInit(): void {
		this.haveAdminUserLevel$ = this.authNgrxService.haveAdminUserLevel$();

		this.setInitialActiveTab();
		this.watchMainRouteMatch();
		this.setNetworkSettingsPermission();
	}

	public ngOnDestroy(): void {
		this.networkingInfoNgrxService.cancelNetworkingObservables();
	}

	private setInitialActiveTab(): void {
		const matchesNetworkSettings = RouterUtilService.includesRouterUrl(this.router.url, RoutesEnum.NETWORK_SETTINGS);

		if (matchesNetworkSettings) { return; }

		this.onNetworkTabRouting(RoutesEnum.NETWORK_INFO);
	}

	private setNetworkSettingsPermission(): void {
		this.networkSettingsPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.NETWORK_SETTINGS);
	}

	private watchMainRouteMatch(): void {
		this.permissionTabService.watchMainRouteMatch$(RoutesEnum.NETWORK)
			.pipe(untilDestroyed(this))
			.subscribe(() => this.routerUtilService.navigateToRoute());
	}

	public onNetworkTabRouting(featureName: RoutesEnum): void {
		this.routerUtilService.navigateToRouterOutlet(featureName, this.activatedRoute);
	}

	public get getRoutesEnum(): typeof RoutesEnum {
		return RoutesEnum;
	}

	public onAdminTabRouting(featureName: RoutesEnum): void {
		this.routerUtilService.navigateToRouterOutlet(featureName, this.activatedRoute);
	}
}
