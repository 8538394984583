import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../../root-reducers';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/services/auth.service';
import { UserLevel } from '../../auth/ts/enums/user-level.enum';
import { EmptyObject } from '../../../ts/types/empty-object.type';
import { GlobalService } from '../../../services/others/global.service';
import { AuthNgrxService } from '../../auth/services/auth-ngrx.service';
import { IUserPreferenceItem } from '../ts/models/user-preferences-item.model';
import { IUserPreferencesVoForm } from '../ts/models/user-preferences-vo-form.model';
import { UserPreferencesPhoneTab } from '../ts/enums/user-preferences-phone-tab.enum';
import { UserPreferencesStoreKey } from '../ts/models/user-preferences-store-key.enum';
import { IUserPreferencesFtpForm } from '../ts/models/user-preferences-ftp-form.model';
import { IUserPreferencesOpcForm } from '../ts/models/user-preferences-opc-form.model';
import { IUserPreferencesSipForm } from '../ts/models/user-preferences-sip-form.model';
import { IUserPreferencesConflict } from '../ts/models/user-preferences-conflict.model';
import { IUserPreferencesLdapForm } from '../ts/models/user-preferences-ldap-form.model';
import { IUserPreferencesCtipForm } from '../ts/models/user-preferences-ctip-form.model';
import { IUserPreferencesClusForm } from '../ts/models/user-preferences-clus.form.model';
import { IUserPreferencesBasicForm } from '../ts/models/user-preferences-basic-form.model';
import { IUserPreferencesModalForm } from '../ts/models/user-preferences-modal-form.model';
import { ISaveConfigurationResponse } from '../../../ts/models/save-configuration-response.model';
import { PrivilegePreferencesService } from './privileges-preferences/privilege-preferences.service';
import { IUserPreferencesAddressBookForm } from '../ts/models/user-preferences-address-book-form.model';
import { IUserPreferencesPrivilegeResponse } from '../ts/models/user-preferences-privilege-response.model';
import { IUserPreferencesPrivilegeParameters } from '../ts/models/user-preferences-privilege-parameters.model';
import { PrivilegesPreferencesTableService } from './privileges-preferences/privileges-preferences-table.service';
import { PrivilegePreferencesFormPayloadService } from './privileges-preferences/privilege-preferences-form-payload.service';
import { IGenericTableImmediateControlData } from '../../ui-components/generic-table/ts/models/generic-table-immediate-control-data.model';
import * as basicUserPreferencesActions from '../ngrx/user-preferences.actions';
import * as preferencesActions from '../../preferences/ngrx/preferences.actions';
import * as basicUserPreferencesSelectors from '../ngrx/user-preferences.selectors';
import {
	UserPrivilegeUbrComponent
} from '../components/user-privileges-detail/user-privilege-detail-phone/user-privilege-ubr/user-privilege-ubr.component';
import { IAutomationMapInfo } from '../../automation/ts/models/automation-map-info.model';

@Injectable({
	providedIn: 'root'
})
export class UserPreferencesNgrxService {

	constructor(
		private store: Store<IAppState>,
		private authService: AuthService,
		private translate: TranslateService,
		private globalService: GlobalService,
		private authNgrxService: AuthNgrxService,
		private privilegesPreferencesService: PrivilegePreferencesService,
		private privilegesPreferencesTableService: PrivilegesPreferencesTableService,
		private privilegePreferencesFormPayloadService: PrivilegePreferencesFormPayloadService
	) { }

	loadPreferencesData(username: string): void {
		const payload = { username };

		this.loadBasicUserPreferences();
		this.store.dispatch(preferencesActions.fetchPreferencesAction({ payload }));
	}

	selectAllBasicUserPreferences$(): Observable<IUserPreferenceItem[]> {
		return this.store.pipe(select(basicUserPreferencesSelectors.selectAllBasicUserPreferences()));
	}

	selectFromBasicUserPreferencesStore$<T>(selector: UserPreferencesStoreKey): Observable<T> {
		return this.store.pipe(
			select(basicUserPreferencesSelectors.selectFromBasicUserPreferencesStore(selector))
		) as unknown as Observable<T>;
	}

	loadBasicUserPreferences(): void {
		this.store.dispatch(basicUserPreferencesActions.loadBasicUserPreferences());
	}

	loadCameraList(): void {
		this.store.dispatch(basicUserPreferencesActions.loadCameraList());
	}

	deleteSelectedUser(selectedPrivilegeItem: IUserPreferenceItem): void {
		const username = selectedPrivilegeItem.USER.VALUE;
		const message = this.translate.instant('ADMIN.delUserMsg') + `"${username}"` + '?';

		const okCallback = () => {
			this.setActionLoading();
			const payload = { username };

			this.privilegesPreferencesTableService.setUnselectedRowState(true);
			this.store.dispatch(basicUserPreferencesActions.deleteSelectedUser({ payload }));
		};

		this.globalService.confirm(message, okCallback);
	}

	duplicateNewUser(username: string, modalFormValues: IUserPreferencesModalForm): void {
		const { newUser, newUserPassword, newUserRealname } = modalFormValues;
		const payload = { username, newUser, newUserPassword, newUserRealname };

		this.store.dispatch(basicUserPreferencesActions.copyBasicUserToNewUser({ payload }));
		this.privilegesPreferencesTableService.setUnselectedRowState(true);
	}

	addNewUser(modalFormValues: IUserPreferencesModalForm): void {
		const { newUser, newUserPassword, newUserRealname } = modalFormValues;
		const payload = { newUser, newUserPassword, newUserRealname };

		this.store.dispatch(basicUserPreferencesActions.addNewUser({ payload }));
		this.privilegesPreferencesTableService.setUnselectedRowState(true);
	}

	savePermanentlyUserSettings(): void {
		this.store.dispatch(basicUserPreferencesActions.savePermanentlyUserSettings());
	}

	setSaveConfigurationResponse(response: ISaveConfigurationResponse | null): void {
		if (!response) { return; }

		const payload = { response };

		this.store.dispatch(basicUserPreferencesActions.setSaveConfigurationResponse({ payload }));
	}

	setLoadingUsersTable(isLoading: boolean): void {
		const payload = { isLoading };

		this.store.dispatch(basicUserPreferencesActions.setLoadingUsersTable({ payload }));
	}

	setActionLoading(): void {
		this.setLoadingUsersTable(true);
		this.privilegesPreferencesTableService.setUnselectedRowState(true);
	}

	cancelBasicUserPreferenceHTTPObservables(): void {
		this.store.dispatch(basicUserPreferencesActions.cancelUserPreferences());
	}

	handleUpdateLoggedUserLevel(username: string, levelGUI: UserLevel, isResponseSuccessful: boolean): void {
		const loggedUsername = this.authService.getUsername();

		if (isResponseSuccessful && username === loggedUsername) {
			this.authNgrxService.updateUserLevel(levelGUI);
		}
	}

	handleSaveUserPrivilege(response: IUserPreferencesPrivilegeResponse): void {
		const { payload, setPrivilegeResponse } = response;
		const { username, levelGUI } = payload;
		const isResponseSuccessful = this.privilegesPreferencesService.isActionSuccessful(setPrivilegeResponse);

		this.handleUpdateLoggedUserLevel(username, levelGUI, isResponseSuccessful);
		this.savePermanentlyUserSettings();
	}

	saveAutomationFormValues(maps: IAutomationMapInfo[], mapsData: boolean[], user: string, accessValue: boolean): void {
		// While before the method was sending the checkbox's position, not the maps[index].i index, now the method sends all
		// the maps[index].i indexes whose checkboxes have been checked.
		const mapsToSave = mapsData.reduce((totalNumbers, currentValue, currentIndex)=>{

			if(currentValue){
				if(maps.at(currentIndex)!==undefined){
					return [...totalNumbers,(maps.at(currentIndex) as IAutomationMapInfo).i];
				}else{
					return totalNumbers;
				}
			}
			return totalNumbers;
		}, [] as number[]).join(',');

		const savePayload = !mapsToSave ? '#' : mapsToSave;

		const payload = { savePayload: { 'IO-MAP': savePayload, 'HTTP-GUI-AUTO': accessValue }, user };
		this.store.dispatch(basicUserPreferencesActions.saveAutomationUserPrivileges({ payload }));
	}

	saveVideorecorderFormValues(cameraData: boolean[], availableCameras: string[], user: string, accessCheckbox: boolean): void {
		const camerasToSave = cameraData
			.reduce((accumulator, selected, index) => selected ? [...accumulator, availableCameras[index]] : accumulator, [] as string[])
			.join(',');

		const savePayload = !camerasToSave ? '#' : camerasToSave;
		const payload = { savePayload: { 'VS-CAM': savePayload, 'HTTP-GUI-VIDEO': accessCheckbox }, user };

		this.store.dispatch(basicUserPreferencesActions.saveVideorecorderUserPrivileges({ payload }));
	}

	savePhoneFormValues(
		voFormValues: IUserPreferencesVoForm, sipForm: IUserPreferencesSipForm,
		opcForm: IUserPreferencesOpcForm, ldapForm: IUserPreferencesLdapForm,
		addressBookForm: IUserPreferencesAddressBookForm, ctipForm: IUserPreferencesCtipForm,
		clusForm: IUserPreferencesClusForm, userPrivilegeUbrComponent: UserPrivilegeUbrComponent,
		user: string, activeTab: UserPreferencesPhoneTab
	): void {
		const savePayload = this.privilegePreferencesFormPayloadService.getPhoneSettingsFormsPayload(
			voFormValues, sipForm, opcForm, ldapForm, addressBookForm, ctipForm, clusForm, activeTab
		);

		if (!savePayload) { return; }

		const ubrPayload = this.getUbrSavePayload(userPrivilegeUbrComponent);
		const payload = { savePayload: { ...savePayload, ...ubrPayload }, user };

		this.store.dispatch(basicUserPreferencesActions.savePhoneUserPrivileges({ payload }));
	}

	private getUbrSavePayload(userPrivilegeUbrComponent: UserPrivilegeUbrComponent): EmptyObject | { NUMBER: string } {
		if (!userPrivilegeUbrComponent) { return {}; }

		const { ubrControl, formInvalid, initialUbrValue } = userPrivilegeUbrComponent;

		if (formInvalid || initialUbrValue === ubrControl.value) { return {}; }

		const { value, valid } = ubrControl;

		return !valid ? {} : { NUMBER: value };
	}

	saveBasicParameters(
		formValues: IUserPreferencesBasicForm, showAddNewUser: boolean, selectedPrivilegeItem: IUserPreferenceItem
	): void {
		this.setActionLoading();
		const payload = { formValues, showAddNewUser, selectedPrivilegeItem };

		this.store.dispatch(basicUserPreferencesActions.saveBasicUserPrivileges({ payload }));
	}

	saveNetworkingFormValues(ftpFormValues: IUserPreferencesFtpForm, user: string): void {
		const payload = { savePayload: ftpFormValues, user };

		this.store.dispatch(basicUserPreferencesActions.saveNetworkingUserPrivileges({ payload }));
	}

	saveAbilisphoneTracking(voFormValues: IUserPreferencesVoForm, user: string): void {
		const { abilisTracking } = voFormValues;
		const payload = { user, abilisTracking };

		this.store.dispatch(basicUserPreferencesActions.saveAbilisphoneTracking({ payload }));
	}

	saveMultipleForcePayload(conflicts: IUserPreferencesConflict[], user: string): void {
		const payload = { conflicts, user };

		this.store.dispatch(basicUserPreferencesActions.saveMultipleForcePayload({ payload }));
	}

	handlePrivilegeCheckboxChange(controlData: IGenericTableImmediateControlData): void {
		const { controlValue, tableDataItem, tableRowIndex } = controlData;
		const username = (tableDataItem as unknown as IUserPreferenceItem).USER.VALUE;
		const parameterKey = this.privilegesPreferencesService.getPrivilegeCheckboxChangeKey(tableRowIndex);
		const payload = { username, parameterKey, parameterValue: controlValue as boolean };

		this.store.dispatch(basicUserPreferencesActions.savePrivilegeCheckbox({ payload }));
	}

	handleAccessCheckboxChange(username: string, parameterKey: string, parameterValue: boolean): void {
		const payload = { username, parameterKey, parameterValue };

		this.store.dispatch(basicUserPreferencesActions.savePrivilegeCheckbox({ payload }));
	}

	setDisabledRow(userId: string): void {
		const payload = { userId };

		this.store.dispatch(basicUserPreferencesActions.setUserRowDisabled({ payload }));
	}

	fetchUserPrivilegeData(selectedUser: string): void {
		const payload = { selectedUser };

		this.store.dispatch(basicUserPreferencesActions.fetchUserPrivilegeData({ payload }));
	}

	setUserPrivilegeData(data: IUserPreferencesPrivilegeParameters | null): void {
		const payload = { data };

		this.store.dispatch(basicUserPreferencesActions.setUserPrivilegeData({ payload }));
	}
}
