import { Injectable } from '@angular/core';
import { RecentCallService } from '../recent-call.service';
import { RecentCallUrl } from '../../ts/enums/recent-call-url.enum';
import { IRecentCall } from '../../ts/models/recent-call-table.model';
import { environment } from '../../../../../../environments/environment';
import { RecentCallResult } from '../../ts/enums/recent-call-result.enum';
import { MainUtilService } from '../../../../../services/main-util.service';
import { RecentCallTableService } from '../table/recent-call-table.service';
import { GlobalService } from '../../../../../services/others/global.service';
import { ResponseStatus } from '../../../../../ts/enums/response-status.enum';
import { IRecentCallCompare } from '../../ts/models/recent-call-compare.model';
import { RecentCallDirection } from '../../ts/enums/recent-call-direction.enum';
import { IRecentCallResponse } from '../../ts/models/recent-call-response.model';
import { RecentCallNgrxActionService } from '../ngrx/recent-call-ngrx-action.service';
import { IUserListResponseItem } from '../../../../../ts/models/user-list-response-item.model';
import * as uuid from 'uuid';

@Injectable({
	providedIn: 'root'
})
export class RecentCallsHttpUtilService {

	private readonly recentCallsUrl: string = environment.abilisUrl + '/sys/user/RecentCallsGet.json';
	private readonly clearCallsUrl: string = environment.abilisUrl + '/sys/user/RecentCallsClear.json';

	private readonly recentCallsAdminUrl: string = environment.abilisUrl + '/sys/admin/users/RecentCallsGet.json';
	private readonly clearCallsAdminUrl: string = environment.abilisUrl + '/sys/admin/users/RecentCallsClear.json';

	constructor(
		private readonly recentCallService: RecentCallService,
		private readonly recentCallTableService: RecentCallTableService,
		private readonly recentCallNgrxActionService: RecentCallNgrxActionService
	) { }

	/**
	 * Adds user query param to URL
	 * @param {string} username Recent Call username
	 */
	public static getRecentCallQueryParams(username: string): string {
		return `?user=${username}`;
	}

	/**
	 * Returns appropriate URL for Recent Calls CRUD operations
	 * @param {RecentCallUrl} mode Which URL mode is request
	 */
	public getRequestUrl(mode: RecentCallUrl): string {
		const adminUrl = this.recentCallService.checkIfAdminRecentCalls();

		if (this.isFetchRequestType(mode)) {
			return adminUrl ? this.recentCallsAdminUrl : this.recentCallsUrl;
		}

		return adminUrl ? this.clearCallsAdminUrl : this.clearCallsUrl;
	}

	/**
	 * Handles HTTP response of Recent Calls
	 * @param {IRecentCallResponse} response Recent Calls HTTP response
	 * @param {boolean} emitTableError If in the case of error, error value should be written to the table
	 * @param {IRecentCall | null} selectedRecentCallItem If there is already selected Recent Call Item
	 */
	public handleRecentCallsResponse(
		response: IRecentCallResponse, emitTableError: boolean, selectedRecentCallItem?: IRecentCall | null
	): IRecentCall[] {
		const { RecentCalls, callenabled_ctip, rsp, user } = response.Response;

		this.recentCallNgrxActionService.setCallEnabledCtip(callenabled_ctip);

		if (emitTableError) {
			this.recentCallTableService.setResponseErrorMessage(rsp);
		}

		if (rsp) { return []; }

		return RecentCalls.map(recentCall => this.extendRecentCallItem(user, recentCall, selectedRecentCallItem));
	}

	/**
	 * Updates Recent Call item with new properties
	 * @param {string} user User to which Recent Calls belong to
	 * @param {IRecentCall} recentCall Recent Call item to format
	 * @param {IRecentCall | null} selectedRecentCallItem If there is already selected Recent Call Item
	 */
	public extendRecentCallItem(user: string, recentCall: IRecentCall, selectedRecentCallItem?: IRecentCall | null): IRecentCall {
		const { datetime, direction, duration, result } = recentCall;

		const recentCallId = uuid.v4();
		const datetimeUI = this.getDatetimeUI(datetime);
		const datetimeMs = this.getDatetimeInMs(datetime);
		const isInDirection = this.isInCallResult(direction);
		const directionUI = this.getDirectionUI(isInDirection);
		const resultUI = GlobalService.translateUIValue(result);
		const isAnswered = this.isAnsweredRecentCallItem(result);
		const resultIcon = this.getCallResultIcon(isInDirection);
		const durationUI = MainUtilService.formatSecondsToNiceFormat(duration);
		const correspondent = this.getCorrespondentValue(recentCall, isInDirection);
		const checkboxState = this.isRecentCallSelected(recentCall, selectedRecentCallItem);

		return {
			...recentCall, recentCallId, datetimeUI, isInDirection, correspondent, user, resultUI,
			durationUI, resultIcon, checkboxState, datetimeMs, directionUI, isAnswered
		};
	}

	/**
	 * Flattens multiple Recent Calls responses from different users, into single array of Recent Call items
	 * @param {IRecentCallResponse[]} response Multiple responses of Recent Calls from different users
	 */
	public formatMultipleCalls(response: IRecentCallResponse[]): IRecentCall[] {
		return response
			.map(responseItem => this.handleRecentCallsResponse(responseItem, false))
			.flat();
	}

	/**
	 * Returns true, if there is some error from clearing Recent Call Log
	 * @param {string | undefined} message Text from HTTP response, of clearing Recent Call Log
	 */
	public isErrorClearingRecentCallLog(message: string | undefined): boolean {
		return !!message && message.toLowerCase() !== ResponseStatus.OK;
	}

	/**
	 * Filter users belonging to the group of the already logged user
	 * @param {IUserListResponseItem[]} users Users list response
	 * @param {string} loggedUserGroup Logged user's group
	 */
	public filterUsersByGroup(users: IUserListResponseItem[], loggedUserGroup: string): IUserListResponseItem[] {
		return users.filter(user => user['http-gui-group'] === loggedUserGroup);
	}

	/**
	 * Formats Debug log info to string HTML format
	 * Every string will begin on the new row with <br> tag.
	 * @param {string[]} debugLog Debug Log information for selected unavailable Recent call
	 */
	public formatCallDebugInfo(debugLog: string[]): string {
		return debugLog
			.map(info => `${info} <br>`)
			.join('');
	}

	/**
	 * Gets datetime, to be rendered in the table
	 * @param {boolean} datetime Date and time value of Recent Call item event
	 */
	private getDatetimeUI(datetime: string): string {
		const luxonDatetime = this.getDatetime(datetime);
		const month = this.getMonthFromDatetime(luxonDatetime);
		const restOfDatetime = this.getRestOfDatetime(luxonDatetime);

		return GlobalService.translateUIValue(month + restOfDatetime);
	}

	/**
	 * Gets rest of datetime, excluding month
	 * @param {boolean} datetime Date and time value of Recent Call item event
	 * 'MMM dd, yyyy, HH:mm:ss a' -> dd, yyyy, HH:mm:ss a
	 */
	private getRestOfDatetime(datetime: string): string {
		return datetime.slice(3, datetime.length);
	}

	/**
	 * Gets month as translation string
	 * @param {boolean} datetime Date and time value of Recent Call item event
	 * 'MMM dd, yyyy, HH:mm:ss a' -> MMM
	 */
	private getMonthFromDatetime(datetime: string): string {
		return `{{COMMON.short${datetime.slice(0, 3)}}}`;
	}

	/**
	 * Gets datetime, based on the provided format
	 * @param {boolean} datetime Date and time value of Recent Call item event
	 */
	private getDatetime(datetime: string): string {
		return MainUtilService.getLuxonDatetime(datetime, 'MMM dd, yyyy, HH:mm:ss a');
	}

	/**
	 * Gets direction translated value, to be rendered in the table
	 * @param {boolean} isInDirection Returns true if call direction is IN
	 */
	private getDirectionUI(isInDirection: boolean): string {
		return GlobalService.translateUIValue(this.getDirectionValue(isInDirection));
	}

	/**
	 * Gets direction string
	 * @param {boolean} isInDirection Returns true if call direction is IN
	 */
	private getDirectionValue(isInDirection: boolean): string {
		return isInDirection ? 'PHONE.RECENTCALLS.inCalls' : 'PHONE.RECENTCALLS.outCalls';
	}

	/**
	 * Determines if Recent Call item is checked
	 * @param {IRecentCall} recentCall Recent Call item
	 * @param {IRecentCall | null} selectedRecentCallItem Selected Recent Call item
	 */
	private isRecentCallSelected(recentCall: IRecentCall, selectedRecentCallItem?: IRecentCall | null): boolean {
		if (!selectedRecentCallItem) { return false; }

		return this.checkEqualRecentCalls(recentCall, selectedRecentCallItem);
	}

	/**
	 * Determines if provided Recent Calls are the same
	 * @param {IRecentCall} firstRecentCall First Recent Call item to compare
	 * @param {IRecentCall} secondRecentCall Second Recent Call item to compare
	 */
	private checkEqualRecentCalls(firstRecentCall: IRecentCall, secondRecentCall: IRecentCall): boolean {
		const firstCall = this.formatRecentCallForCompare(firstRecentCall);
		const secondCall = this.formatRecentCallForCompare(secondRecentCall);

		return JSON.stringify(firstCall) === JSON.stringify(secondCall);
	}

	/**
	 * Formats Recent Call record for compare
	 * @param {IRecentCall} recentCall Recent Call item to compare
	 */
	private formatRecentCallForCompare(recentCall: IRecentCall): IRecentCallCompare {
		const { called, calling, result, direction, duration, name, outdial, datetime } = recentCall;

		return { called, calling, result, direction, duration, name, outdial, datetime };
	}

	/**
	 * Converts datetime to milliseconds
	 * @param {boolean} datetime Date and time value of Recent Call item event
	 */
	private getDatetimeInMs(datetime: string): number {
		return MainUtilService.getLuxonObject(datetime).toMillis();
	}

	/**
	 * Renders call result icon in the table
	 * @param {boolean} isInDirection Returns true if call direction is IN
	 */
	private getCallResultIcon(isInDirection: boolean): string {
		return !isInDirection ? 'phone-outgoing-white.svg' : 'phone-incoming-white.svg';
	}

	/**
	 * Returns correspondent value, to be rendered in the table
	 * @param {IRecentCall} recentCall Recent Call item
	 * @param {boolean} isInDirection Returns true if call direction is IN
	 */
	private getCorrespondentValue(recentCall: IRecentCall, isInDirection: boolean): string {
		const { name, calling, called } = recentCall;

		return name ? name : isInDirection ? calling : called;
	}

	/**
	 * Returns true if call direction as IN
	 * @param {RecentCallDirection} direction Call direction
	 */
	private isInCallResult(direction: RecentCallDirection): boolean {
		return direction === RecentCallDirection.IN;
	}

	/**
	 * Returns true if call outcome is Answered
	 * @param {RecentCallResult} result Result of call outcome
	 */
	private isAnsweredRecentCallItem(result: RecentCallResult): boolean {
		return result === RecentCallResult.ANSWERED;
	}

	/**
	 * Determines if fetch request type is requested
	 * @param {RecentCallUrl} mode Which URL mode is request
	 */
	private isFetchRequestType(mode: RecentCallUrl): boolean {
		return mode === RecentCallUrl.FETCH;
	}
}
