import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormArray, FormBuilder } from '@angular/forms';
import { GenericTableBuilder } from '../../../networking/common/classes/table-builder.class';
import { IGenericTableRow } from '../../../ui-components/generic-table/ts/models/generic-table-row';
import { GenericFormValue } from '../../../ui-components/generic-table/ts/types/generic-form-value.type';
import { IGenericTableHeader } from '../../../ui-components/generic-table/ts/models/generic-table-header';
import { GenericTableFilter } from '../../../ui-components/generic-table/ts/enums/generic-table-filter.enum';
import { IGenericTableHeaderData } from '../../../ui-components/generic-table/ts/models/generic-table-header-data.model';
import { IGenericUnsetSelectedRow } from '../../../ui-components/generic-table/ts/models/generic-unset-selected-row.model';

@Injectable({
	providedIn: 'root'
})
export class NetworkingInfoNewsTableService extends GenericTableBuilder {

	private errorMessageState: BehaviorSubject<string> = new BehaviorSubject('');
	private unsetSelectedRowState = new BehaviorSubject({ unsetSelectedRow: false });

	constructor(
		private formBuilder: FormBuilder
	) {
		super();
	}

	buildTableHeaders(): IGenericTableHeader[] {
		return [
			{ header: { headerName: 'NETWORK.COMMON.date' }, sort: { useSort: false } },
			{
				header: { headerName: 'NETWORK.COMMON.resource' }, sort: { useSort: false },
				filter: {
					useFilter: true, filterComponent: GenericTableFilter.INPUT,
					input: { inputRangeTitle: '', fieldWidth: 150, formArrayIndex: 0 }
				}
			},
			{ header: { headerName: 'NETWORK.COMMON.information' }, sort: { useSort: false } }
		];
	}

	buildHeaderForm(): FormArray {
		return this.formBuilder.array([this.formBuilder.control('')]);
	}

	buildTableRows(): IGenericTableRow[] {
		return [
			{ cell: { cellName: 'datetime', visibleCellCondition: true }, edit: { editMode: false } },
			{ cell: { cellName: 'resource', visibleCellCondition: true }, edit: { editMode: false } },
			{
				cell: { cellName: 'eventDescription', visibleCellCondition: true, translateValue: true },
				edit: { editMode: false }
			}
		];
	}

	buildForm(): FormArray {
		return this.formBuilder.array([]);
	}

	translateSelectedRowDataToForm(): GenericFormValue[] {
		return [];
	}

	setResponseErrorMessage(errorMessage: string = ''): void {
		this.errorMessageState.next(errorMessage);
	}

	getErrorMessageState$(): Observable<string> {
		return this.errorMessageState.asObservable();
	}

	setUnselectedRowState(rowState: boolean): void {
		this.unsetSelectedRowState.next({ unsetSelectedRow: rowState });
	}

	getUnsetSelectedRowState$(): Observable<IGenericUnsetSelectedRow> {
		return this.unsetSelectedRowState;
	}

	updateHeaderFormValues(headerFilterFormValues: string[]): IGenericTableHeaderData[] {
		return headerFilterFormValues.map(value => ({ key: 'resource', value }));
	}
}
