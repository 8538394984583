import { Injectable, Injector } from '@angular/core';
import { IRecentCallMode } from '../ts/enums/recent-call-mode.enum';
import { RecentCallModeClass } from '../classes/recent-call-mode.class';
import { RecentCallAllModeService } from './modes/recent-call-all-mode.service';
import { RecentCallSelfModeService } from './modes/recent-call-self-mode.service';
import { RecentCallOtherModeService } from './modes/recent-call-other-mode.service';

@Injectable({
	providedIn: 'root'
})
export class RecentCallActionFactoryService {

	private recentCallModeService: RecentCallModeClass;

	constructor(
		private readonly injector: Injector
	) { }

	/**
 * Sets appropriate Recent Call service, based on the mode
 * @param {IRecentCallMode} mode Recent Call mode
 */
	public setActiveService(mode: IRecentCallMode): void {
		this.recentCallModeService = this.getAppopriateServiceMode(mode);
	}

	/**
 * Returns appropriate Recent Call service
 */
	public get getActiveService(): RecentCallModeClass {
		return this.recentCallModeService;
	}

	/**
 * Returns appropriate Recent Call service, based on the mode
 * @param {IRecentCallMode} mode Recent Call mode
 */
	private getAppopriateServiceMode(mode: IRecentCallMode): RecentCallModeClass {
		switch (mode) {
		case IRecentCallMode.ALL:
			return this.injector.get(RecentCallAllModeService);
		case IRecentCallMode.OTHER:
			return this.injector.get(RecentCallOtherModeService);
		case IRecentCallMode.SELF:
			return this.injector.get(RecentCallSelfModeService);
		}
	}
}
