import { Store } from '@ngrx/store';
import { Injectable, inject } from '@angular/core';
import { IAppState } from '../../../../../root-reducers';
import { IRecentCall } from '../../ts/models/recent-call-table.model';
import * as recentCallActions from '../../ngrx/recent-call.action';

@Injectable({
	providedIn: 'root'
})
export class RecentCallNgrxActionService {

	private readonly store: Store<IAppState>;

	constructor() {
		this.store = inject(Store<IAppState>);
	}

	/**
	 * Handles fetching of additional info about why Recent Call is failed
	 * @param {IRecentCall} recentCallItem Selected Recent call item
	 */
	public fetchRecentCallDebugInfo(recentCallItem: IRecentCall): void {
		const payload = { recentCallItem };

		this.store.dispatch(recentCallActions.fetchRecentCallDebugInfo({ payload }));
	}

	/**
	 * Sets additional info about why Recent Call is failed
	 */
	public setRecentCallDebugInfo(recentCallDebugInfo: string | null): void {
		const payload = { recentCallDebugInfo };

		this.store.dispatch(recentCallActions.setRecentCallDebugInfo({ payload }));
	}

	/**
	 * Cancels Recent Call HTTP Observables, when onDestroy method is called
	 */
	public cancelRecentCallObservables(): void {
		this.store.dispatch(recentCallActions.cancelRecentCallHTTPObservables());
	}

	/**
	 * Handles fetching Recent Calls of single user
	 * @param {string} username Recent Calls user's username
	 */
	public loadSingleUsersRecentCalls(username: string): void {
		const payload = { username };

		this.store.dispatch(recentCallActions.loadSingleUsersRecentCalls({ payload }));
	}

	/**
	 * Sets if PC Phone for Recent Calls page should be visible
	 * @param {boolean} callenabledCtip If call on CTIP interface is visible
	 */
	public setCallEnabledCtip(callenabledCtip: boolean): void {
		const payload = { callenabledCtip };

		this.store.dispatch(recentCallActions.setCallEnabledCtip({ payload }));
	}

	/**
	 * Triggers loading of all users Recent Calls
	 */
	public loadAllUsersRecentCalls(): void {
		this.store.dispatch(recentCallActions.loadAllUsersRecentCalls());
	}

	/**
	 * Handles poll fetching of user's Recent Calls
	 * @param {string} username Recent Calls user's username
	 */
	public pollFetchRecentCalls(username: string): void {
		const payload = { username };

		this.store.dispatch(recentCallActions.pollFetchRecentCalls({ payload }));
	}

	/**
	 * Clears Recent Calls Log of user
	 * @param {string} username Recent Calls user's username
	 */
	public clearRecentCallsLog(username: string): void {
		const payload = { username };

		this.store.dispatch(recentCallActions.clearRecentCalls({ payload }));
	}

	/**
	 * Exports Recent Calls of user into CSV
	 * @param {string} username Recent Calls user's username
	 */
	public exportRecentCallsCsv(username: string): void {
		const payload = { username };

		this.store.dispatch(recentCallActions.exportRecentCalls({ payload }));
	}

	/**
	 * Handles fetching of user's Recent Calls Log
	 * @param {string} username Recent Calls user's username
	 */
	public fetchSingleUsersRecentCalls(username: string): void {
		this.loadSingleUsersRecentCalls(username);
		this.pollFetchRecentCalls(username);
	}

	/**
	 * Formats Recent Call item and update ngrx store's selected contact checkbox state
	 * @param {IRecentCall} recentCallItem Selected Recent Call item
	 * @param {boolean} checkboxState If Selected Recent Call item should be checked/unchecked
	 */
	public handleContactsCheckboxChange(recentCallItem: IRecentCall, checkboxState: boolean): void {
		const updatedRecentCallItem = { ...recentCallItem, checkboxState };

		this.uncheckAllRecentCalls();
		this.updateCheckedContact(updatedRecentCallItem);
	}

	/**
	 * Unchecks all Recent Calls in the table
	 */
	public uncheckAllRecentCalls(): void {
		this.store.dispatch(recentCallActions.uncheckAllRecentCalls());
	}

	/**
	 * Sets loading flag to Recent Calls table
	 * @param {boolean} isLoading If true, loading text will be presented in the Recent Calls table, if false, no loading indicator will be there
	 */
	public setIsLoading(isLoading: boolean): void {
		const payload = { isLoading };

		this.store.dispatch(recentCallActions.setIsLoading({ payload }));
	}

	/**
	 * Updates ngrx store's selected contact checkbox state
	 * @param {IRecentCall} contact Recent Call item to update
	 */
	private updateCheckedContact(contact: IRecentCall): void {
		const payload = { contact };

		this.store.dispatch(recentCallActions.updateCheckedCallState({ payload }));
	}
}
