import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SideBarService } from './../../services/side-bar.service';
import { LangChangeEvent, TranslateModule } from '@ngx-translate/core';
import { ISideBarFeature } from './../../ts/models/side-bar-feature.model';
import { GlobalService } from '../../../../../../services/others/global.service';
import { Component, Input, ChangeDetectionStrategy, OnInit, inject } from '@angular/core';
import { AuthNgrxService } from '../../../../../../components/auth/services/auth-ngrx.service';
import { NestedTranslationPipe } from '../../../../../../standalone/pipes/nested-translation.pipe';
import { ISectionPermission } from './../../../../../../components/auth/ts/models/section-permission.model';
import { SidebarFeatureNames } from './../../../../../../components/core/ts/enums/sidebar-feature-names.enum';
import { FeaturePermissionsName } from '../../../../../../components/core/ts/enums/feature-permission-name.enum';
import { IFeaturePermissionsItem } from './../../../../../../components/core/ts/models/feature-permissions-item.model';

@Component({
	selector: 'app-side-bar-item',
	standalone: true,
	imports: [
		CommonModule,
		TooltipModule,
		TranslateModule,
		NestedTranslationPipe
	],
	templateUrl: './side-bar-item.component.html',
	styleUrls: ['./side-bar-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarItemComponent implements OnInit {

	private readonly router: Router;

	@Input() sidebarFeature: ISideBarFeature;
	@Input() sectionPermissions: ISectionPermission;
	@Input() sidebarFeatureName: SidebarFeatureNames | null;

	public languageChanged$: Observable<LangChangeEvent>;
	public videorecorderPermission$: Observable<IFeaturePermissionsItem | null>;

	constructor(
		private readonly globalService: GlobalService,
		private readonly sideBarService: SideBarService,
		private readonly authNgrxService: AuthNgrxService
	) {
		this.router = inject(Router);
	}

	public ngOnInit(): void {
		this.languageChanged$ = this.globalService.getLanguageChangeState$();
		this.videorecorderPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.VIDEORECORDER);
	}

	public onFeatureOpen(event: Event, featureName: SidebarFeatureNames): void {
		event.preventDefault();

		if (this.sidebarFeatureName === featureName) { return; }

		this.sideBarService.setActiveSidebarItem(featureName);
		this.router.navigateByUrl(featureName);
	}

	public get getSidebarFeatureAction(): typeof SidebarFeatureNames {
		return SidebarFeatureNames;
	}
}
