import { createAction, props } from '@ngrx/store';
import { PhoneMenuModes } from '../ts/enums/phone-menu-modes.enum';
import { IPhoneCallRecord } from './../ts/models/phone-call-record.model';
import { IPhoneCallPayload } from '../ts/models/phone-call-payload.model';
import { IPhoneModePayload } from './../ts/models/phone-mode-payload.model';
import { IPhoneCallRecordInfo } from '../ts/models/phone-call-record-info.model';
import { IPreferencesStore } from './../../preferences/ts/models/preferences-store.model';
import { IRecentCall } from './../../PHONE_FEATURES/recent-calls/ts/models/recent-call-table.model';
import { IOpcPanelCallRecord } from '../../opc-panel/ts/models/session/opc-panel-call-record.model';
import { IOpcPanelUserRecord } from './../../opc-panel/ts/models/session/opc-panel-user-record.model';

export const fetchRecentCalls = createAction(
	'[Phone] Fetch Recent Calls'
);

export const pollFetchRecentCalls = createAction(
	'[Phone] Poll Fetch Recent Calls'
);

export const setStatus = createAction(
	'[Phone] Set Phone Component Status',
	props<{ payload: { status: PhoneMenuModes } }>()
);

export const setFetchError = createAction(
	'[Phone] Set Fetch Error',
	props<{ payload: { active: boolean } }>()
);

export const addInputValue = createAction(
	'[Phone] Add Input Value',
	props<{ payload: { value: string } }>()
);

export const setInputValue = createAction(
	'[Phone] Set Input Value',
	props<{ payload: { value: string } }>()
);

export const removeLastInputValue = createAction(
	'[Phone] Remove Last Input Value'
);

export const clearInputValue = createAction(
	'[Phone] Clear Input Value'
);

export const setCallRecords = createAction(
	'[Phone] Set Call Records',
	props<{ payload: { callRecords: IPhoneCallRecord[] } }>()
);

export const updateCallRecords = createAction(
	'[Phone] Update Call Records',
	props<{ payload: { callRecords: IPhoneCallRecord[] } }>()
);

export const handleNewCallRecords = createAction(
	'[Phone] Handle New Call Records',
	props<{ payload: { newCallRecords: IOpcPanelCallRecord[] } }>()
);

export const addUserRecord = createAction(
	'[Phone] Add User Record',
	props<{ payload: { userRecord: IOpcPanelUserRecord | null } }>()
);

export const clearPhoneStore = createAction(
	'[Phone] Clear Phone Store'
);

export const setClearNextInputState = createAction(
	'[Phone] Set Clear Next Input State',
	props<{ payload: { clearNextInput: boolean } }>()
);

export const fetchPhoneStatus = createAction(
	'[Phone] Fetch Phone Status'
);

export const checkToUpdateCallRecords = createAction(
	'[Phone] Check To Update Call Records'
);

export const fetchPhoneStatusSessionChanges = createAction(
	'[Phone] Fetch Phone Status Session Changes'
);

export const cancelPhoneHTTPObservables = createAction(
	'[Phone] Cancel Phone HTTP Observables'
);

export const setRecentCallsLog = createAction(
	'[Phone] Set Recent Calls Log',
	props<{ payload: { recentCalls: IRecentCall[] } }>()
);

export const sendSmsUserAction = createAction(
	'[Phone] Send Sms User',
	props<{ payload: { phoneNumber: string; smsContent: string } }>()
);

export const fetchSmsAvailability = createAction(
	'[Phone] Fetch Sms Availability'
);

export const fetchUserPreferences = createAction(
	'[Phone] Fetch User Preferences'
);

export const setUserPreferences = createAction(
	'[Phone] Set User Preferences',
	props<{ payload: { userPreferences: IPreferencesStore } }>()
);

export const setPhonePreferences = createAction(
	'[Phone] Set Phone Preferences',
	props<{ payload: IPhoneModePayload }>()
);

export const setUnconfiguredFooterState = createAction(
	'[Phone] Set Unconfigured Footer State',
	props<{ payload: { unconfiguredFooter: boolean } }>()
);

export const setCtiControl = createAction(
	'[Phone] Set Cti Control',
	props<{ payload: IPhoneModePayload }>()
);

export const handleConferenceCall = createAction(
	'[Phone] Handle Conference Call',
	props<{ payload: IPhoneModePayload }>()
);

export const setCallTry = createAction(
	'[Phone] Set Call Try',
	props<{ payload: IPhoneModePayload }>()
);

export const setCallHold = createAction(
	'[Phone] Set Call Hold',
	props<{ payload: IPhoneModePayload }>()
);

export const callUser = createAction(
	'[Phone] Call User',
	props<{ payload: IPhoneCallPayload }>()
);

export const abortCall = createAction(
	'[Phone] Abort Call'
);

export const setRecordCall = createAction(
	'[Phone] Set Record Call',
	props<{ payload: IPhoneModePayload }>()
);

export const setPriorityMode = createAction(
	'[Phone] Set Priority Mode',
	props<{ payload: { priorityMode: boolean } }>()
);

export const setCallRecordsInfo = createAction(
	'[Phone] Set Call Records Info',
	props<{ payload: { callRecordsInfo: IPhoneCallRecordInfo[] } }>()
);
