import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SideBarService } from './services/side-bar.service';
import { ISideBarFeature } from './ts/models/side-bar-feature.model';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { SideBarItemComponent } from './components/side-bar-item/side-bar-item.component';
import { AuthNgrxService } from './../../../../components/auth/services/auth-ngrx.service';
import { SideBarSearchComponent } from './components/side-bar-search/side-bar-search.component';
import { TranslateLanguageFullnamePipe } from '../../../pipes/translate-language-fullname.pipe';
import { SideBarFooterComponent } from './components/side-bar-footer/side-bar-footer.component';
import { SideBarSearchNgrxActionService } from './services/ngrx/side-bar-search-ngrx-action.service';
import { ISectionPermission } from './../../../../components/auth/ts/models/section-permission.model';
import { SidebarFeatureNames } from './../../../../components/core/ts/enums/sidebar-feature-names.enum';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		SideBarItemComponent,
		SideBarSearchComponent,
		SideBarFooterComponent,
		TranslateLanguageFullnamePipe
	],
	selector: 'app-side-bar',
	templateUrl: './side-bar.component.html',
	styleUrls: ['./side-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarComponent implements OnInit {

	@Input() isUserLoggedIn: boolean;

	public sidebarFeatures: ISideBarFeature[] = [];

	public isUserLoggedIn$: Observable<boolean>;
	public haveAdminUserLevel$: Observable<boolean>;
	public sectionPermissions$: Observable<ISectionPermission>;
	public selectedFeatureName$: Observable<SidebarFeatureNames>;

	constructor(
		private readonly sideBarService: SideBarService,
		private readonly authNgrxService: AuthNgrxService,
		public readonly translateService: TranslateService,
		private readonly sideBarSearchNgrxActionService: SideBarSearchNgrxActionService
	) { }

	public ngOnInit(): void {
		this.sideBarSearchNgrxActionService.loadSearchPages();

		this.isUserLoggedIn$ = this.authNgrxService.isUserLoggedIn$();

		this.setUserLevel();
		this.setSidebarFeatures();
		this.setSectionPermissions();
		this.setSelectedFeatureName();
	}

	private setSidebarFeatures(): void {
		this.sidebarFeatures = this.sideBarService.getSidebarFeatures;
	}

	private setUserLevel(): void {
		this.haveAdminUserLevel$ = this.authNgrxService.haveAdminUserLevel$();
	}

	private setSectionPermissions(): void {
		this.sectionPermissions$ = this.authNgrxService.selecteEnhancedPermissions$();
	}

	private setSelectedFeatureName(): void {
		this.selectedFeatureName$ = this.sideBarService.getActiveSidebarItem$;
	}
}
