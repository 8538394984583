import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from './../../../../../../root-reducers/index';
import { ISideBarSearchState } from './../../ngrx/side-bar-search.reducer';
import { ISearchItem } from './../../../../../../components/core/ts/models/search-item.model';
import * as sidebarSearchSelector from '../../ngrx/side-bar-search.selectors';

@Injectable({
	providedIn: 'root'
})
export class SideBarSearchNgrxSelectorService {

	constructor(
		private readonly store: Store<IAppState>
	) { }

	public selectAllPagesState$(): Observable<ISideBarSearchState> {
		return this.store.pipe(select(sidebarSearchSelector.selectAllPages));
	}

	public selectFilteredPages$(): Observable<ISearchItem[]> {
		return this.selectAllPagesState$()
			.pipe(
				map(pagesState => pagesState.filteredPages)
			);
	}
}
