import { FormlyFieldConfig } from '@ngx-formly/core';

export function minItemsValidationMessage(_: any, field: FormlyFieldConfig) {
	const { key, templateOptions } = field;

	return templateOptions ? `${key} should NOT have fewer than ${templateOptions.minItems} items` : '';
}

export function maxItemsValidationMessage(_: any, field: FormlyFieldConfig) {
	const { key, templateOptions } = field;

	return templateOptions ? `${key} should NOT have more than ${templateOptions.maxItems} items` : '';
}

export function minlengthValidationMessage(_: any, field: FormlyFieldConfig) {
	const { key, templateOptions } = field;

	return templateOptions ? `${key} should NOT be shorter than ${templateOptions.minLength} characters` : '';
}

export function maxlengthValidationMessage(_: any, field: FormlyFieldConfig) {
	const { key, templateOptions } = field;

	return templateOptions ? `${key} should NOT be longer than ${templateOptions.maxLength} characters` : '';
}

export function minValidationMessage(_: any, field: FormlyFieldConfig) {
	const { key, templateOptions } = field;

	return templateOptions ? `${key} should be >= ${templateOptions.min}` : '';
}

export function maxValidationMessage(_: any, field: FormlyFieldConfig) {
	const { key, templateOptions } = field;

	return templateOptions ? `${key} should be <= ${templateOptions.max}` : '';
}

export function multipleOfValidationMessage(_: any, field: FormlyFieldConfig) {
	const { key, templateOptions } = field;

	return templateOptions ? `${key} should be multiple of ${templateOptions.step}` : '';
}

export function exclusiveMinimumValidationMessage(_: any, field: FormlyFieldConfig) {
	const { key, templateOptions } = field;

	return templateOptions ? `${key} should be > ${templateOptions.step}` : '';
}

export function exclusiveMaximumValidationMessage(_: any, field: FormlyFieldConfig) {
	const { key, templateOptions } = field;

	return templateOptions ? `${key} should be < ${templateOptions.step}` : '';
}

export function constValidationMessage(_: any, field: FormlyFieldConfig) {
	const { key, templateOptions } = field;

	return templateOptions ? `${key} should be equal to constant "${templateOptions.const}"` : '';
}
