import { createAction, props } from '@ngrx/store';
import { IIpbanWhitelistItem } from '../ts/models/ipsh-whitelist-item.model';
import { IIpshConfigurationForm } from '../ts/models/configuration/ipsh-configuration-form.model';
import { IIpshConfigurationValue } from '../ts/models/configuration/ipsh-configuration-value.model';
import { INetworkingInfoResourceItem } from '../../networking-info/ts/models/resource/networking-info-resource-item.model';

export const cancelIpshHTTPObservables = createAction(
	'[IPSH] Cancel HTTP Observables',
);

export const cancelIpshConfigurationObservables = createAction(
	'[IPSH] Cancel HTTP IPSH Configuration Observables',
);

export const loadLanResources = createAction(
	'[IPSH] Load Lan Resources',
);

export const finishLoadingIpshConfiguration = createAction(
	'[IPSH] Finish Loading Ipsh Configuration',
	props<{ payload: { ipshConfigurationForm: IIpshConfigurationValue } }>()
);

export const loadWhitelistItems = createAction(
	'[IPSH] Load Whitelist Items',
);

export const finishLoadingWhitelistItems = createAction(
	'[IPSH] Finish Loading Whitelist Items',
	props<{ payload: { whitelistItems: IIpbanWhitelistItem[] } }>()
);

export const finishLoadingLanResources = createAction(
	'[IPSH] Finish Loading Lan Resources',
	props<{ payload: { lanResources: INetworkingInfoResourceItem[] } }>()
);

export const initializeIpshConfiguration = createAction(
	'[IPSH] Initialize Ipsh Configuration'
);

export const saveIpshConfiguration = createAction(
	'[IPSH] Save Ipsh Configuration'
);

export const setIpshConfiguration = createAction(
	'[IPSH] Set IPSH Configuration',
	props<{ payload: { ipshConfiguation: IIpshConfigurationForm } }>()
);

export const fetchIpshConfiguration = createAction(
	'[IPSH] Fetch Ipsh Configuration',
	props<{ payload: { setFormValues: boolean } }>()
);

export const setCheckedIpshWhitelistItems  = createAction(
	'[IPSH] Set Checked Ipsh Whitelist Items',
	props<{ payload: { isInternalWhitelist: boolean; isChecked: boolean } }>()
);

export const updateWhitelistItems = createAction(
	'[IPSH] Update Whitelist Items',
	props<{ payload: { whitelistItems: IIpbanWhitelistItem[] } }>()
);

export const updateSingleWhitelistItem = createAction(
	'[IPSH] Update Single Whitelist Item',
	props<{ payload: { whitelistItem: IIpbanWhitelistItem } }>()
);

export const addWhitelistItems = createAction(
	'[IPSH] Add Whitelist Items',
	props<{ payload: { internalWhitelist: boolean; items: string[] } }>()
);

export const removeItemsFromWhitelist = createAction(
	'[IPSH] Remove Whitelist Items',
	props<{ payload: { internalWhitelist: boolean } }>()
);

export const saveWhitelistPermanently = createAction(
	'[IPSH] Save Whitelist Permanently'
);
