import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { UserPreferencesSipType } from '../../ts/enums/user-preferences-sip-type.enum';

@Injectable({
	providedIn: 'root'
})
export class PrivilegePreferencesValidatorsService {

	constructor() { }

	getSipDomainValidators(sipTypeValue: string): ValidatorFn[] {
		if (sipTypeValue === UserPreferencesSipType.LOCAL__PEER || sipTypeValue === UserPreferencesSipType.PHONE) {
			return [Validators.maxLength(64), Validators.pattern(/^(SYS|SYS \(.*\)|([a-z0-9]+\.)*[a-z0-9]+\.[a-z]{1,64}|^$)$/i)];
		}

		return [Validators.maxLength(64), Validators.pattern(/^(([a-z0-9]+\.)*[a-z0-9]+\.[a-z]{1,64}|^$)$/i)];
	}

	getSipLocportValidators(sipTypeValue: string): ValidatorFn[] {
		if (sipTypeValue === UserPreferencesSipType.SERVER) {
			return [Validators.pattern(/^(?!(SYS|SYS \(.*\))$).*/i)];
		}

		return [Validators.pattern(/.*/)];
	}

	getSipHostValidators(sipTypeValue: string): ValidatorFn[] {
		if (sipTypeValue === UserPreferencesSipType.SERVER || sipTypeValue === UserPreferencesSipType.NNI) {
			return [Validators.pattern(/^([0-9a-z.-]{1,64}|^$)$/)];
		}

		return [Validators.pattern(/^([0-9a-z.-]{1,64}|^$|DYNAMIC)$/)];
	}

	getSipRemportValidators(sipTypeValue: string): ValidatorFn[] {
		if (sipTypeValue === UserPreferencesSipType.NNI) {
			return [Validators.pattern(/^([1-9]|[1-9]\d{1,3}|[1-5]\d{3,4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/)];
		}

		return [Validators.pattern(/^([1-9]|[1-9]\d{1,3}|[1-5]\d{3,4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5]|DYNAMIC)$/i)];
	}

	getSipClusNumberValidators(setHashtag: boolean): ValidatorFn[] {
		const hashtagPart = setHashtag ? '^#$|' : '';
		// eslint-disable-next-line max-len
		const sipClusPattern = new RegExp(`${hashtagPart}^[?^]{1,20}$|^(((([uinoshk])(?!.*\\4))?(([xedtlp])(?!.*\\6))?(\\d([*_]?)(\\^|\\?)*)){1,20}|(\\d{1,20})|[*_])$`, 'i');

		return [Validators.maxLength(20), Validators.pattern(sipClusPattern)];
	}

	getCtipNumberValidators(numberLength: number, noneVpotsPots: boolean): ValidatorFn[] {
		if (noneVpotsPots) {
			const otherThanPotsRegExp = /^#$|^[?]{1,20}$|^(((([uinoshk])(?!.*\4))?(([xedtlp])(?!.*\6))?(([armq])(?!.*\8))?(([yvwz])(?!.*\10))?(\d([*]?)(\?)*)){1,20}|(\d{1,20})|[*])$/i;

			return [Validators.maxLength(20), Validators.pattern(otherThanPotsRegExp)];
		}

		// eslint-disable-next-line max-len
		const potsRegExp = new RegExp(`^#$|^(((([uinoshk])(?!.*\\4))?(([xedtlp])(?!.*\\6))?(([armq])(?!.*\\8))?(([yvwz])(?!.*\\10))?(\\d${numberLength})){1,20}|(\\d{${numberLength}}))$`, 'i');

		return [Validators.maxLength(20), Validators.pattern(potsRegExp)];
	}
}
