import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule
	],
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent {

	constructor() { }

	public goBack(): void {
		window.history.back();
	}
}
