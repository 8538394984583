export enum OpcPanelUserRecordState {
	NULL = 'NULL',
	READY = 'READY',
	NOTREADY = 'NOTREADY',
	ONHOOK = 'ONHOOK',
	OFFHOOK = 'OFFHOOK',
	RINGING = 'RINGING',
	FAIL = 'FAIL',
	UNAVAIL = 'UNAVAIL'
}
