import { Injectable } from '@angular/core';
import { EmptyObject } from '../../../../ts/types/empty-object.type';
import { PreferencesUserService } from '../preferences-user.service';
import { PreferencesFormValidatorService } from './preferences-form-validator.service';
import { PreferencesTabsComponent } from '../../components/preferences-tabs.component';
import { IPreferencesSavePayload } from '../../ts/models/preferences-save-payload.model';
import { IPreferencesInterfaceType } from '../../ts/enums/preferences-interface-type.enum';
import { PreferencesVoiceMailRules } from '../../ts/enums/preferences-voicemail-rules.enum';
import { PreferencesInterfaceAction } from '../../ts/enums/preferences-interface-action.enum';
import { IPreferencesAdvancedFormPayload } from '../../ts/models/preferences-advanced-form-payload.model';

@Injectable({
	providedIn: 'root'
})
export class PreferencesFormPayloadService {

	constructor(
		private preferencesUserService: PreferencesUserService,
		private preferencesFormValidatorService: PreferencesFormValidatorService
	) { }

	getPreferenceSavePayload(
		user: string, preferencesTabsComponent: PreferencesTabsComponent,
		selectedInterface: IPreferencesInterfaceType, isUbrMode: boolean
	): IPreferencesSavePayload {
		const propertyPrefix = `${!isUbrMode ? selectedInterface.toLowerCase() : 'ubr'}-`;

		const {
			callManagementFormValid, otherOptionsFormValid, advancedSettingsFormValid
		} = this.preferencesFormValidatorService.getFormValidityState();

		const payload = [
			{
				isFormValid: advancedSettingsFormValid,
				payload: this.getAdvancedFormPayload(preferencesTabsComponent, advancedSettingsFormValid)
			},
			{
				isFormValid: callManagementFormValid,
				payload: this.getCallManagementFormPayload(preferencesTabsComponent, propertyPrefix, callManagementFormValid)
			},
			{
				isFormValid: otherOptionsFormValid,
				payload: this.getOtherOptionsFormPayload(preferencesTabsComponent, selectedInterface, propertyPrefix, otherOptionsFormValid)
			}
		]
			.reduce((accumulator, payloadItem) => payloadItem.isFormValid ? { ...accumulator, ...payloadItem.payload } : accumulator, {});

		return { CtiPreferences: [{ user, ...payload }] };
	}

	getCoreForwardPayload(
		propertyPrefix: string, selectedConditionalRule: string,
		forwardedNumber: string, callingNumberState: string
	): { [key: string]: string } {
		return {
			[`${propertyPrefix}action`]: selectedConditionalRule,
			[`${propertyPrefix}cf-cdo`]: forwardedNumber,
			[`${propertyPrefix}cf-cgo`]: callingNumberState
		};
	}

	getOtherOptionsFormPayload(
		preferencesTabsComponent: PreferencesTabsComponent, selectedInterface: IPreferencesInterfaceType,
		propertyPrefix: string, isFormValid: boolean = true
	): { [key: string]: string } | null {
		if (!isFormValid) { return null; }

		const { otherOptionsForm } = preferencesTabsComponent.otherOptionsComponent;
		const {
			callWaiting, voiceMail, voiceRecorder, recipients, voicemailRecipients,
			voiceMailAlways, voiceMailBusy, voiceMailNoAnswer, voiceMailOther, voiceMailTout,
			phoneStatistics
		} = otherOptionsForm.value;

		const showCallWaiting = this.preferencesUserService.showCallWaiting(selectedInterface);
		const callWaitingPayload = showCallWaiting ? { [`${propertyPrefix}call-waiting`]: callWaiting } : {};
		const vmRuleValue = this.getVoiceMailRules(voiceMailAlways, voiceMailBusy, voiceMailNoAnswer, voiceMailOther);
		const vmRulePayload = this.getVmRulePayload(vmRuleValue, propertyPrefix);
		const vmToutPayload = this.getVmToutPayload(voiceMailTout, propertyPrefix);

		return {
			...callWaitingPayload, ...vmRulePayload, ...vmToutPayload, 'on-demand-vr': voiceRecorder,
			'vm-rcpt': voicemailRecipients, 'on-demand-vr-rcpt': recipients, [`${propertyPrefix}vm`]: voiceMail, 'cti-trfa': phoneStatistics
		};
	}

	private getVmToutPayload(value: string, propertyPrefix: string): { [key: string]: string } {
		return {[this.getVmKey(propertyPrefix, 'vm-tout')]: value };
	}

	private getVmRulePayload(value: string, propertyPrefix: string): { [key: string]: string } | EmptyObject {
		return !value ? {} : {[`${propertyPrefix}vm-rule`]: value };
	}

	private getVmKey(propertyPrefix: string, key: string): string {
		return propertyPrefix === 'ubr-' ? key : `${propertyPrefix}${key}`;
	}

	getAdvancedFormPayload(
		preferencesTabsComponent: PreferencesTabsComponent, isFormValid: boolean = true
	): IPreferencesAdvancedFormPayload | null {
		if (!isFormValid) { return null; }

		const {
			interval, retries, useGsm, useVoIP, forIncomingCalls
		} = preferencesTabsComponent.advancedSettingsComponent.advancedSettingsForm.value;

		const coreAdvancedFormPayload = { 'calltry-tout': interval, 'calltry-nrty': retries };
		const voData = { 'vo-ap-voip': useVoIP, 'vo-ap-cell': useGsm, 'vo-contact-ap-voip': forIncomingCalls };

		return { ...coreAdvancedFormPayload, ...voData };
	}

	getCallManagementFormPayload(
		preferencesTabsComponent: PreferencesTabsComponent, propertyPrefix: string, isFormValid: boolean = true
	): { [key: string]: string } | null {
		if (!isFormValid) { return null; }

		const {
			selectedConditionalRule, forwardedNumber, callingNumberState,
			forkDelay, forkMode, busy, others, fork, noAnswer, noAnswerTimeout
		} = preferencesTabsComponent.conditionalRuleComponent.conditionalRulesForm.value;

		switch (selectedConditionalRule) {
		case PreferencesInterfaceAction.DND:
		case PreferencesInterfaceAction.ADVANCED:
			return { [`${propertyPrefix}action`]: selectedConditionalRule };
		case PreferencesInterfaceAction.CF_ALWAYS:
			return this.getCoreForwardPayload(propertyPrefix, selectedConditionalRule, forwardedNumber, callingNumberState);
		case PreferencesInterfaceAction.CF_FORK:
			return {
				...this.getCoreForwardPayload(propertyPrefix, selectedConditionalRule, forwardedNumber, callingNumberState),
				[`${propertyPrefix}cf-dly`]: forkDelay,
				[`${propertyPrefix}cf-fork-type`]: forkMode
			};
		case PreferencesInterfaceAction.CF:
			const cfRules = this.getCfRulePayload(busy, others, fork, noAnswer);

			return {
				...this.getCoreForwardPayload(propertyPrefix, selectedConditionalRule, forwardedNumber, callingNumberState),
				[`${propertyPrefix}cf-rule`]: cfRules,
				[`${propertyPrefix}cf-tout`]: noAnswerTimeout
			};
		default:
			return {};
		}
	}

	getCfRulePayload(busy: boolean, others: boolean, fork: boolean, noans: boolean): string {
		return [{ busy }, { others }, { fork }, { noans }]
			.reduce((accumulator, cfRuleItem) => {
				const [key, value] = Object.entries(cfRuleItem)[0];

				return value ? [...accumulator, key] : accumulator;
			}, [] as string[])
			.join(',');
	}

	getVoiceMailRules(voiceMailAlways: boolean, voiceMailBusy: boolean, voiceMailNoAnswer: boolean, voiceMailOther: boolean): string {
		return [
			voiceMailAlways ? PreferencesVoiceMailRules.ALWAYS : '', voiceMailBusy ? PreferencesVoiceMailRules.BUSY : '',
			voiceMailNoAnswer ? PreferencesVoiceMailRules.NOANS : '', voiceMailOther ? PreferencesVoiceMailRules.OTHERS : '']
			.filter(element => !!element)
			.join(',');
	}
}
