import { INetworkingInfoState } from './networking-info.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NetworkingInfoStoreKey } from '../ts/enums/networking-info-store-key.enum';
import { NetworkingInfoCategory } from '../ts/enums/networking-info-resource-category.enum';
import * as networkingInfoReducer from './networking-info.reducer';

export const selectNetworkingInfoState = createFeatureSelector<INetworkingInfoState>('networkingInfo');

export const selectAllNetworkingResources = () => createSelector(
	selectNetworkingInfoState,
	networkingInfoReducer.selectAllNeworkingResources
);

export const selectFromNetworkingInfoStore = (property: NetworkingInfoStoreKey) => createSelector(
	selectNetworkingInfoState,
	networkingInfoState => networkingInfoState[property]
);

export const selectNetworkingResources = (categories: NetworkingInfoCategory[]) => createSelector(
	selectAllNetworkingResources(),
	allNetworkingResources => allNetworkingResources.filter(networkingResource => categories.includes(networkingResource.cat))
);

export const showOutsideContainer = () => createSelector(
	selectFromNetworkingInfoStore(NetworkingInfoStoreKey.IS_LOADING),
	selectNetworkingResources([NetworkingInfoCategory.SDWAN]),
	selectNetworkingResources([NetworkingInfoCategory.WAN]),
	(isLoading, sdwanResources, wanResources) => isLoading === true || (!isLoading && !!(sdwanResources.length || wanResources.length))
);

export const showInsideContainer = () => createSelector(
	selectFromNetworkingInfoStore(NetworkingInfoStoreKey.IS_LOADING),
	selectNetworkingResources([NetworkingInfoCategory.VPN]),
	selectNetworkingResources([NetworkingInfoCategory.LAN]),
	(isLoading, vpnResources, lanResources) => isLoading === true || (!isLoading && !!(vpnResources.length || lanResources.length))
);
