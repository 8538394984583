import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RioRvsService } from '../rio-rvs.service';
import { WioNgrxService } from '../wio-ngrx.service';
import { TranslateService } from '@ngx-translate/core';
import { RioRvsHttpService } from './rio-rvs-http.service';
import { RioRvsNgrxService } from '../rio-rvs-ngrx.service';
import { RioRvsTab } from '../../ts/enums/rio-rvs-tab.enum';
import { WioHttpLogicService } from './wio-http-logic.service';
import { IWioNodeModel } from '../../ts/models/wio-node.model';
import { ToastrType } from '../../../../ts/enums/toastr-type.enum';
import { RioRvsHttpLogicService } from './rio-rvs-http-logic.service';
import { environment } from '../../../../../environments/environment';
import { MainUtilService } from '../../../../services/main-util.service';
import { catchError, exhaustMap, map, Observable, tap, timer } from 'rxjs';
import { ResponseStatus } from '../../../../ts/enums/response-status.enum';
import { IWioNodeResponse } from '../../ts/models/wio-nodes-response.model';
import { IRioRvsWioDevice } from '../../ts/models/rio-rvs-wio-device.model';
import { IActionResponse } from '../../../../ts/models/action-response.model';
import { RioRvsWioDeviceType } from '../../ts/enums/rio-rvs-device-type.enum';
import { AutomationGenericService } from '../../../automation/services/automation-generic.service';
import * as customOperators from '../../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class WioHttpService {

	private readonly setWioSlaveNodesUrl: string = environment.abilisUrl + '/sys/admin/io/wioset.json';
	private readonly getAllWioSlaveNodesUrl: string = environment.abilisUrl + '/sys/admin/io/wioget.json';
	private readonly setMultipleValuesUrl: string = environment.abilisUrl + '/sys/io/design/line/set.json';
	private readonly refreshWioNodesUrl: string = environment.abilisUrl + '/sys/admin/io/wiodiscover.json';
	private readonly removeWioSlaveDeviceUrl: string = environment.abilisUrl + '/sys/admin/io/wioremove.json';

	constructor(
		private http: HttpClient,
		private translate: TranslateService,
		private rioRvsService: RioRvsService,
		private wioNgrxService: WioNgrxService,
		private mainUtilService: MainUtilService,
		private rioRvsNgrxService: RioRvsNgrxService,
		private rioRvsHttpService: RioRvsHttpService,
		private wioHttpLogicService: WioHttpLogicService,
		private rioRvsHttpLogicService: RioRvsHttpLogicService
	) { }

	autoLoadWioSlaveNodes$(): Observable<IWioNodeModel[]> {
		return timer(0, 5 * 1000)
			.pipe(
				exhaustMap(() => this.fetchAvailableWioNodes$())
			);
	}

	fetchAvailableWioNodes$(): Observable<IWioNodeModel[]> {
		return this.http.get<IWioNodeResponse>(this.getAllWioSlaveNodesUrl)
			.pipe(
				map(response => {
					const { message } = response.Response;
					const hasErrorMessage = message !== undefined;

					return this.wioHttpLogicService.formatWioNodes(response, hasErrorMessage);
				}),
				customOperators.retryFromError$(1000)
			);
	}

	updateWioSlaveInformation$(selectedWioDevice: IWioNodeModel, newDescription: string): Observable<IActionResponse | null> {
		const { res, nodeType, nodeInd } = selectedWioDevice;

		const updatePayload = { res, nodeType, nodeInd: [nodeInd.toString()], descr: newDescription };

		return this.http.post<IActionResponse>(this.setWioSlaveNodesUrl, updatePayload)
			.pipe(
				catchError(() => {
					const translated = this.translate.instant('AUTO.PREVIEW.setChangesError');

					return this.rioRvsHttpService.handleOperationErrors$(translated);
				})
			);
	}

	removeWioSlave$(selectedWioItem: IWioNodeModel, selectedWioMaster: IRioRvsWioDevice): Observable<IActionResponse | null> {
		const { nodeInd: WioNode } = selectedWioItem;
		const { mac: Mac, resource } = selectedWioMaster;

		const Id = AutomationGenericService.splitAutomationItemByDash(resource as string)[1];
		const uuid = this.rioRvsHttpLogicService.getRioRvsUUID;

		const removePayload = { Uuid: uuid, Wioremove: { Mac, Id, WioNode, Type: RioRvsWioDeviceType.WIO } };

		return this.http.post<IActionResponse>(this.removeWioSlaveDeviceUrl, removePayload)
			.pipe(
				tap(response => {
					const { status, message } = response.Response;

					if (status === 177) {
						this.mainUtilService.showToastrMessage(message, ToastrType.SUCCESS, '');

						return;
					}

					this.mainUtilService.showToastrMessage(message, ToastrType.ERROR, '');
					this.wioNgrxService.setLoadingWioSlaveNodes(false);
				}),
				customOperators.retryFromError$(1000),
			);
	}

	refreshWioNodes$(selectedWioItem: IRioRvsWioDevice, wionode?: number): Observable<IActionResponse> {
		const { mac, id, resource } = selectedWioItem;

		const uuid = this.rioRvsHttpService.getUuid();
		const corePayload = { Uuid: uuid, Wiodiscover: { Mac: mac, Type: RioRvsWioDeviceType.WIO, Id: id } };
		const payload = wionode ? { ...corePayload, Wiodiscover: { ...corePayload.Wiodiscover, Wionode: wionode } } : corePayload;

		return this.http.post<IActionResponse>(this.refreshWioNodesUrl, payload)
			.pipe(
				tap(response => {
					const { status, message } = response.Response;

					if (status === 177) {
						this.rioRvsNgrxService.loadModalRioRvsWioLineDevices(resource as string);
						this.mainUtilService.showToastrMessage(message, ToastrType.SUCCESS, '');

						return;
					}

					this.mainUtilService.showToastrMessage(message, ToastrType.ERROR, '');
				}),
				customOperators.retryFromError$(1000)
			);
	}

	saveMultipleWioValuesAtOnce$(
		tab: RioRvsTab, device: IRioRvsWioDevice,
		parameter: string, value: string, lineDeviceNumbers: string[]
	): Observable<boolean> {
		const res = `${device.resource}`;
		const lineType = this.rioRvsService.getDeviceTypeOfTab(tab);
		const additionalPayload = this.rioRvsHttpLogicService.getAdditionalPayload(parameter);
		const payload = { res, lineType, lineInd: lineDeviceNumbers, [parameter]: value, ...additionalPayload };

		return this.http.post<IActionResponse>(this.setMultipleValuesUrl, payload)
			.pipe(
				map(response => {
					const { message } = response.Response;
					const isResponseOk = message.toLowerCase() === ResponseStatus.OK;

					if (isResponseOk) {
						this.mainUtilService.showToastrMessage(message, ToastrType.SUCCESS, '');

						return true;
					}

					this.mainUtilService.showToastrMessage(message, ToastrType.ERROR, '');

					return false;
				}),
				customOperators.retryFromError$(1000)
			);
	}
}
