import { Injectable } from '@angular/core';
import { AutomationProgramSpreadsheetCellService } from './automation-program-spreadsheet-cell.service';
import { AutomationProgramSpreadsheetUtilService } from '../automation-program-spreadsheet-util.service';
import { IAutomationProgramEditFunction } from '../../../ts/models/automation-program-edit-function.model';
import { IAutomationProgramEditLineDevice } from '../../../ts/models/automation-program-edit-line-device.model';
import { AutomationProgramEditFunctonList } from '../../../ts/enums/automation-program-edit-function-list.enum';
import { IAutomationProgramEditResultResponse } from '../../../ts/models/automation-program-edit-result-response.modal';
import { AutomationProgramSpreadsheetFunctionArgsService } from './automation-program-spreadsheet-function-args.service';
import * as availableFunctionsOperators from '../../../utils/available-functions-operators';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSpreadsheetFunctionValidatorService extends AutomationProgramSpreadsheetUtilService {

	constructor(
		private automationCellService: AutomationProgramSpreadsheetCellService,
		private automationFnArgsService: AutomationProgramSpreadsheetFunctionArgsService
	) {
		super();
	}

	getInputTokenCollections(
		inputValue: string, selectedDevices: IAutomationProgramEditLineDevice[], fieldIndex: number | null
	): IAutomationProgramEditResultResponse {
		const slicedInputValue = inputValue.substring(1);
		const expressionValidation = this.validateExpressionStart(inputValue);
		const parenthesisValidation = this.checkParenthesisValidation(slicedInputValue);
		const hasErrorData = [expressionValidation, parenthesisValidation].find(result => result?.haveError);

		if (hasErrorData) {
			return hasErrorData;
		}

		const tokenExpressions = super.setToTokenExpressions(slicedInputValue);
		const tokenExpressionsResult = this.validateTokenExpressionFunctionArguments(tokenExpressions);

		if (tokenExpressionsResult.haveError) {
			return tokenExpressionsResult;
		}

		return this.validateInputTokenCollections(tokenExpressions, fieldIndex, selectedDevices);
	}

	validateTokenExpressionFunctionArguments(tokenExpressions: string[]): IAutomationProgramEditResultResponse {
		const haveError = tokenExpressions.some((token, index) => {
			if (token.toLowerCase() !== AutomationProgramEditFunctonList.TRANS) {
				return false;
			}

			const transFunctionCell = tokenExpressions[index + 2];

			return transFunctionCell === 'true' || transFunctionCell === 'false';
		});

		const errorMessage = !haveError ? '' : '{{AUTO.RULES.EDIT.ERRORS.transReference}}';

		return { haveError, errorMessage, value: null };
	}

	validateInputTokenCollections(
		tokenExpressions: string[], fieldIndex: number | null, selectedDevices: IAutomationProgramEditLineDevice[]
	): IAutomationProgramEditResultResponse {
		const invalidComma = super.checkForInvalidComma(tokenExpressions);

		if (invalidComma) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.invalidCommaSign}}' };
		}

		const wrongOperator = super.isWrongOperatorUsedFromToken(tokenExpressions);

		if (wrongOperator) {
			return this.automationFnArgsService.getInvalidOperatorResponse(wrongOperator);
		}

		const { errorMessage, value } = this.automationCellService.translateCellValues(tokenExpressions, selectedDevices, fieldIndex);

		if (errorMessage) {
			return { haveError: true, errorMessage };
		}

		const nonOperatorsValidation = this.validateNoRepeatedOperators(value as string[]);

		if (nonOperatorsValidation?.haveError) {
			return nonOperatorsValidation;
		}

		return { haveError: false, value: nonOperatorsValidation.value };
	}

	validateExpressionStart(value: string): IAutomationProgramEditResultResponse {
		const delimiterToken = availableFunctionsOperators.delimiterToken;
		// Expression should not contain dollar sign
		// (because $ sign is used to distinguish between operators when using to set token expressions)
		if (value.includes(delimiterToken)) {
			const errorMessage = `{{AUTO.RULES.EDIT.ERRORS.expressionShouldntContain}} "${delimiterToken}" {{AUTO.RULES.EDIT.ERRORS.sign}}`;

			return { haveError: true, errorMessage };
		}

		return { haveError: false, value };
	}

	checkParenthesisValidation(expression: string): IAutomationProgramEditResultResponse {
		const numberOfOpeningParenthesis = expression.split('(').length;
		const numberOfClosingParenthesis = expression.split(')').length;

		if (numberOfOpeningParenthesis !== numberOfClosingParenthesis) {
			const errorMessage = '{{AUTO.RULES.EDIT.ERRORS.parenthesisErrors}}';

			return { haveError: true, errorMessage };
		}

		return { haveError: false, value: '' };
	}

	validateFunctionResult(
		functionName: string, parenthesisData: string, functionMatch: IAutomationProgramEditFunction
	): IAutomationProgramEditResultResponse {
		if (!functionMatch) {
			const errorMessage = `{{AUTO.RULES.EDIT.ERRORS.specifiedFnPrefix}} "${functionName}" {{AUTO.RULES.EDIT.ERRORS.fnWasntFound}}`;

			return { haveError: true, errorMessage };
		}

		const functionArgumentsValidation = this.automationFnArgsService.getFunctionArgumentsValidation(parenthesisData);
		const argumentsHasError = functionArgumentsValidation.find(argumentValidation => argumentValidation?.haveError);

		if (argumentsHasError) {
			return argumentsHasError;
		}

		const { minArguments, maxArguments } = functionMatch;
		const functionArgumentsValues = this.automationFnArgsService.getFunctionArgumentsValue(functionArgumentsValidation);

		if (!(functionArgumentsValues.length >= minArguments && functionArgumentsValues.length <= maxArguments)) {
			const numberOfFnArguments = '{{AUTO.RULES.EDIT.ERRORS.numberOfFnArguments}}';

			return {
				haveError: true, errorMessage: `${numberOfFnArguments} "${functionName}" {{AUTO.RULES.EDIT.ERRORS.isWrong}}.
        {{AUTO.RULES.EDIT.ERRORS.minArguments}}: ${minArguments} - {{AUTO.RULES.EDIT.ERRORS.maxArguments}}: ${maxArguments}`
			};
		}

		return this.automationFnArgsService.validateFunctionArguments(functionName, functionArgumentsValues, parenthesisData);
	}

	validateNoRepeatedOperators(tokenExpressions: string[]): IAutomationProgramEditResultResponse {
		const notAllowedRepeatingOperators = availableFunctionsOperators.nonAllowedRepeatingOperators;

		const errorToken = tokenExpressions.find((tokenExpression, currentIndex) => {
			const nextToken = tokenExpressions[currentIndex + 1];

			const isNextTokenNumber = /^\d+$/.test(nextToken);
			const isCurrentTokenNumber = /^\d+$/.test(tokenExpression);

			const missingOperator = isCurrentTokenNumber && isNextTokenNumber;
			const repeatedOperator = notAllowedRepeatingOperators.includes(tokenExpression) && tokenExpression === nextToken;

			return missingOperator || repeatedOperator;
		});

		if (errorToken) {
			const isRepeatingOperator = notAllowedRepeatingOperators.includes(errorToken);
			const errorMessage = this.getNoRepeatedOperatorErrorMessage(isRepeatingOperator, errorToken);

			return { haveError: true, errorMessage };
		}

		return { haveError: false, value: tokenExpressions };
	}

	getNoRepeatedOperatorErrorMessage(isRepeatingOperator: boolean, errorToken: string): string {
		if (isRepeatingOperator) {
			return `{{AUTO.RULES.EDIT.ERRORS.operator}} "${errorToken}" {{AUTO.RULES.EDIT.ERRORS.isNotAllowedToBeRepeated}}`;
		}

		return '{{AUTO.RULES.EDIT.ERRORS.validOperator}}';
	}
}
