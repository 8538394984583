import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { IStore } from '../ts/models/store.model';
import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserLevel } from '../ts/enums/user-level.enum';
import { AuthUserInfo } from '../ts/enums/user-auth.enum';
import { RoutesEnum } from '../../../ts/enums/routes.enum';
import { LoginMethod } from '../ts/enums/login-method.enum';
import { Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { ToastrType } from '../../../ts/enums/toastr-type.enum';
import { MainUtilService } from '../../../services/main-util.service';
import { RouterUtilService } from './../../../services/utils/router-util.service';
import { createTrimWhitespaceValidator } from '../../../utils/form-validators.validator';
import { LanguageService } from './../../OTHER_FEATURES/user-settings/services/language/language.service';

@Injectable({
	providedIn: 'root'
})

export class AuthService {

	private abilisLocalTime: string;
	private currentAbilisBuild: string;

	private username: BehaviorSubject<string> = new BehaviorSubject('');

	constructor(
		private readonly router: Router,
		private readonly formBuilder: FormBuilder,
		private readonly translate: TranslateService,
		private readonly mainUtilService: MainUtilService,
		private readonly languageService: LanguageService,
		private readonly routerUtilService: RouterUtilService,
		@Inject(DOCUMENT) private document: Document
	) { }

	// LOGIC

	setErrorAccessPermission$(username: string): Observable<boolean> {
		return forkJoin({
			unauthorizedInfo: this.translate.get('LOGIN.unauthorized') as Observable<string>,
			accessForbiddenInfo: this.translate.get('LOGIN.accessForbidden') as Observable<string>
		})
			.pipe(
				map(response => {
					const { unauthorizedInfo, accessForbiddenInfo } = response;
					const hasLoginCookie = MainUtilService.getCookieValue('login');

					if (username === AuthUserInfo.ANONYMOUS || !hasLoginCookie) {
						this.mainUtilService.showToastrMessage(unauthorizedInfo, ToastrType.ERROR);
						this.router.navigateByUrl(`/${RoutesEnum.LOGIN}`);

						return false;
					}

					this.mainUtilService.showToastrMessage(accessForbiddenInfo, ToastrType.ERROR);
					this.routerUtilService.navigateToRoute();

					return true;
				})
			);
	}

	setSessionData(sessionData: IStore): void {
		const { loctime, user, AbilisBuild } = sessionData;
		const { language, username } = user;

		this.abilisLocalTime = loctime;
		this.currentAbilisBuild = AbilisBuild;

		this.username.next(username);
		this.languageService.setLanguage(language);
	}

	areCookiesAvailable(): boolean {
		if (typeof navigator.cookieEnabled === 'undefined') {
			this.document.cookie = 'abilisTestCookie';

			return this.document.cookie.includes('abilisTestCookie');
		}

		return navigator.cookieEnabled;
	}

	initializeLoginForm(): FormGroup {
		return this.formBuilder.group({
			username: [null, [createTrimWhitespaceValidator()]],
			password: [null, [createTrimWhitespaceValidator()]],
			login_method: [LoginMethod.BROWSER_CLOSE, [createTrimWhitespaceValidator()]]
		});
	}

	getUsername(): string {
		return this.username.getValue();
	}

	isAnonymousUsername(): boolean {
		return this.getUsername() === AuthUserInfo.ANONYMOUS;
	}

	watchUsernameChanges$(): Observable<string> {
		return this.username.asObservable();
	}

	getAbilisTimezone(): string {
		return MainUtilService.getLuxonTimezone(this.abilisLocalTime);
	}

	getAbilisBuildVersion(): string {
		return this.currentAbilisBuild;
	}

	haveAdminUserLevel(userLevel: UserLevel | null): boolean {
		if (!userLevel) { return false; }

		return [UserLevel.MANAGER, UserLevel.ADMINISTRATOR, UserLevel.SUPER].includes(userLevel);
	}

	haveSpecificUserLevel(userLevelsToMatch: UserLevel[], userLevel: UserLevel): boolean {
		return userLevelsToMatch.includes(userLevel);
	}

	haveVsMapsHttpLevel(userLevel: UserLevel): boolean {
		return [UserLevel.ADMINISTRATOR, UserLevel.SUPER].includes(userLevel);
	}

	getRemoteAbilisUrl(remoteIp: string): string {
		return `https://${remoteIp}/gui/#/network;replaceUrl=true/(network-info:network-info)`;
	}
}
