import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppComponent } from '../app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from './app.routing.module';
import { reducers, metaReducers } from '../root-reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RootEffects } from '../root-effects/root.effects';
import { environment } from '../../environments/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from '../components/auth/utils/serializer';
import { HttpLoaderFactory } from '../utils/app-module-functions';
import { AuthEffects } from '../components/auth/ngrx/auth.effects';
import { CoreModule } from '../components/core/modules/core.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { RioRvsEffects } from '../components/rio-rvs/ngrx/rio-rvs.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { PreferencesEffects } from '../components/preferences/ngrx/preferences.effects';
import { HttpClientModule, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { SideBarComponent } from './../standalone/components/ui/side-bar/side-bar.component';
import { RecentCallEffects } from '../components/PHONE_FEATURES/recent-calls/ngrx/recent-call.effects';
import { ZonelessEventPluginService } from '../services/zoneless-events/zoneless-event-plugin.service';
import { AddressBookEffects } from '../components/PHONE_FEATURES/address-book/ngrx/address-book.effects';
import { SideBarSearchActionEffects } from './../standalone/components/ui/side-bar/ngrx/side-bar-search.effects';
import { AutomationProgramTableEffects } from '../components/automation-program-edit/ngrx/automation-program-edit-table.effects';
import { ChartsModule } from 'ng2-charts';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
	declarations: [AppComponent],
	imports: [
		CoreModule,
		SharedModule,
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		SideBarComponent,
		ReactiveFormsModule,
		HttpClientJsonpModule,
		BrowserAnimationsModule,
		PopoverModule.forRoot(),
		ToastrModule.forRoot({
			maxOpened: 1,
			preventDuplicates: true
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		StoreModule.forRoot(reducers, {
			metaReducers,
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true
			}
		}),
		!environment.production ? StoreDevtoolsModule.instrument() : [],
		EffectsModule.forRoot([
			RootEffects, AuthEffects, AutomationProgramTableEffects, SideBarSearchActionEffects,
			RecentCallEffects, AddressBookEffects, PreferencesEffects, RioRvsEffects
		]),
		StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer })
	],
	exports: [TranslateModule],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: EVENT_MANAGER_PLUGINS,
			useClass: ZonelessEventPluginService,
			multi: true
		}
	]
})

export class AppModule {
	constructor() { }
}
