import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SideBarFetchPageType } from './../ts/types/side-bar-fetch-page.type';
import { SideBarPagePermission } from './../ts/types/side-bar-page-permission.type';
import { ISearchItem } from './../../../../../components/core/ts/models/search-item.model';
import { AvailableLanguage } from './../../../../../components/core/ts/enums/available-language.enum';
import { ISectionPermission } from './../../../../../components/auth/ts/models/section-permission.model';
import { IFeaturePermissionsItem } from './../../../../../components/core/ts/models/feature-permissions-item.model';
import { ISearchNavigationResponse } from './../../../../../components/core/ts/models/search-navigation-response.model';

@Injectable({
	providedIn: 'root'
})
export class SideBarSearchService {

	constructor(
		private readonly translateService: TranslateService
	) { }

	public formatPagesResponse(response: SideBarFetchPageType): ISearchNavigationResponse {
		const [pages, adminUserLevel, featurePermissions, sectionPermissions] = response;
		const permissions = [featurePermissions, sectionPermissions] as SideBarPagePermission;

		const [enPages, itPages] = (Object
			.keys(pages) as Array<keyof ISearchNavigationResponse>)
			.map(key => this.filterPagesByPermissions(pages[key], adminUserLevel, permissions));

		return { EN: enPages, IT: itPages };
	}

	public getMatchingPages(searchValue: string, pages: ISearchNavigationResponse): ISearchItem[] {
		if (!searchValue) { return []; }

		const language = this.translateService.currentLang as AvailableLanguage;

		return pages[language.toUpperCase() as keyof ISearchNavigationResponse]
			.filter(page => page.display.toLowerCase().includes(searchValue.toLowerCase()));
	}

	public filterPagesByPermissions(pages: ISearchItem[], haveAdminRole: boolean, permissions: SideBarPagePermission): ISearchItem[] {
		const [featurePermissions, sectionPermissions] = permissions;
		const adminPages = this.filterPagesByRole(pages, haveAdminRole);
		const sectionPages = this.filterPagesBySectionPermissions(pages, sectionPermissions);

		return this.filterPagesByFeaturePermissions([...adminPages, ...sectionPages], featurePermissions);
	}

	private filterPagesByRole(pages: ISearchItem[], haveAdminRole: boolean): ISearchItem[] {
		return pages.filter(page => this.isAdminSectionPage(page.section) && haveAdminRole);
	}

	private filterPagesBySectionPermissions(pages: ISearchItem[], permissions: ISectionPermission): ISearchItem[] {
		return pages.filter(page => !this.isAdminSectionPage(page.section) && (permissions[page.section as keyof ISectionPermission]));
	}

	private filterPagesByFeaturePermissions(pages: ISearchItem[], permissions: IFeaturePermissionsItem[]): ISearchItem[] {
		return pages.filter(page => !this.getMatchingFeature(permissions, page)?.isFeatureDisabled);
	}

	private isAdminSectionPage(section: keyof ISectionPermission | 'admin'): boolean {
		return section === 'admin';
	}

	private getMatchingFeature(featurePermissions: IFeaturePermissionsItem[], page: ISearchItem): IFeaturePermissionsItem | null {
		return featurePermissions.find(permission => permission.name === page.permissionName) || null;
	}
}
