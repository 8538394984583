import { Injectable } from '@angular/core';
import { NETWORK_LOG_DOWN, NETWORK_LOG_UP } from '../../util/networking-info-util';
import { NetworkingInfoLogCode } from '../../ts/enums/networking-info-log-code.enum';
import { NetworkingInfoLogType } from '../../ts/enums/networking-info-log-type.enum';
import { INetworkingInfoLogItem } from '../../ts/models/networking-info-log-item.model';
import { NetworkingInfoNewsCodeUtilService } from './networking-info-news-code-util.service';
import { INetworkingInfoResourceItem } from '../../ts/models/resource/networking-info-resource-item.model';
import { INetworkingInfoCustomLogItem } from '../../ts/models/networking-info-custom-log-item.model';
import { NetworkingInfoService } from '../networking-info.service';

@Injectable({
	providedIn: 'root'
})
export class NetworkingInfoNewsCodeAipt2Service {

	constructor(
		private networkingCodeUtilService: NetworkingInfoNewsCodeUtilService
	) { }

	getAipt2LogAdditionalText(isUp: boolean, coreDescription: string, additionalDescription: string): string {
		return isUp ? coreDescription + additionalDescription : coreDescription;
	}

	formatAipt2MainEventDescription(
		code: NetworkingInfoLogCode, systemLogItem: INetworkingInfoLogItem, availableResources: INetworkingInfoResourceItem[]
	): string {
		const { text } = systemLogItem;
		const { isUp } = this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /i: ip-\d+/);

		const resourceStatus = this.networkingCodeUtilService.getResourceStatusFromText(text, isUp);
		const resourceTunnelInfo = this.getResourceTunnelInfo(text);
		const pathsInfo = this.getPathsDetailedInfo(text);
		const pingStatus = this.getPingStatus(text);

		switch (code) {
		case NetworkingInfoLogCode.AIPT2_MAIN_PING_TUNNEL_PATH:
			return this.getAipt2LogAdditionalText(isUp, resourceStatus, `${pingStatus}${resourceTunnelInfo}${pathsInfo}`);
		case NetworkingInfoLogCode.AIPT2_MAIN_TUNNEL_PATH:
			return this.getAipt2LogAdditionalText(isUp, resourceStatus, `${resourceTunnelInfo}${pathsInfo}`);
		case NetworkingInfoLogCode.AIPT2_MAIN_PING_TUNNEL:
			return this.getAipt2LogAdditionalText(isUp, resourceStatus, `${pingStatus}${resourceTunnelInfo}`);
		case NetworkingInfoLogCode.AIPT2_MAIN_TUNNEL:
			return this.getAipt2LogAdditionalText(isUp, resourceStatus, `${resourceTunnelInfo}`);
		default:
			return '';
		}
	}

	formatAipt2MainPingTunnelLog(
		code: NetworkingInfoLogCode, systemLogItem: INetworkingInfoLogItem,
		datetime: string, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const { id, resource, resourceDescription } = this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /i: ip-\d+/);

		const type = NetworkingInfoLogType.CRITICAL__ERROR;
		const eventDescription = this.formatAipt2MainEventDescription(code, systemLogItem, availableResources);
		const resourceUI = NetworkingInfoService.generateNetworkLogResource(resource, resourceDescription);

		return { id, type, datetime, resource: resourceUI, eventDescription };
	}

	getResourceTunnelInfo(logText: string): string {
		const tunnelInformationResult = logText.toLowerCase().match(/tunnel:.{2}/);

		if (tunnelInformationResult) {
			const tunnelStatus = this.networkingCodeUtilService.getValueFromParameter(tunnelInformationResult);

			switch (tunnelStatus) {
			case 'up':
				return `, {{NETWORK.NEWS.allTunnelsAre}} ${NETWORK_LOG_UP}`;
			case 'dn':
				return `, {{NETWORK.NEWS.allTunnelsAre}} ${NETWORK_LOG_DOWN}`;
			case 'su':
				return ', {{NETWORK.NEWS.someTunnelsPartiallyWorking}}';
			case 'bu':
				return ', {{NETWORK.NEWS.someBackupTunnelsActive}}';
			}
		}

		return '';
	}

	getPathsDetailedInfo(text: string): string {
		const pathStatusResult = text.toLowerCase().match(/paths:.+/);

		if (pathStatusResult) {
			const pathStatuses = this.networkingCodeUtilService.getValueFromParameter(pathStatusResult);

			const pathInformation = pathStatuses
				.split('')
				.map((pathStatus, pathIndex) => {
					const pathStatusTranslated = this.translatePathStatus(pathStatus.toLowerCase());

					return `{{NETWORK.NEWS.path}} ${pathIndex + 1}: ${pathStatusTranslated}`;
				})
				.join(' ');

			return ` ({{NETWORK.NEWS.contributors}}: ${pathInformation})`;
		}

		return '';
	}

	translatePathStatus(pathStatus: string): string {
		switch (pathStatus) {
		case 'd':
			return NETWORK_LOG_DOWN;
		case 'u':
			return NETWORK_LOG_UP;
		case '-':
			return '{{NETWORK.NEWS.statusDisabled}}';
		}

		return '{{NETWORK.NEWS.statusUnknown}}';
	}

	getPingStatus(text: string,): string {
		const pingStatusResult = text.toLowerCase().match(/ping:.{2}/);

		if (pingStatusResult) {
			const pingStatus = this.networkingCodeUtilService.getValueFromParameter(pingStatusResult);

			switch (pingStatus) {
			case 'dn':
				return `, {{NETWORK.NEWS.pingStatusIs}} ${NETWORK_LOG_DOWN}`;
			case 'up':
				return `, {{NETWORK.NEWS.pingStatusIs}} ${NETWORK_LOG_UP}`;
			}
		}

		return ', {{NETWORK.NEWS.pingStatusIs}} {{NETWORK.NEWS.unknown}}';
	}

	formatAipt2BackupLog(
		code: NetworkingInfoLogCode, systemLogItem: INetworkingInfoLogItem,
		datetime: string, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const { text } = systemLogItem;
		const resourceData = this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /i: ip-\d{1,3}/);
		const { id, resource, isUp, resourceDescription } = resourceData;
		const type = NetworkingInfoLogType.CRITICAL__ERROR;

		const pathInfo = this.getBackupTunnelStatus(text);
		const resourceTunnelInfo = this.getResourceTunnelInfo(text);
		const backupPathPrefix = this.getBackupPathPrefix(code, text);
		const resourceStatus = this.networkingCodeUtilService.getResourceStatusFromText(text, isUp);

		const additionalInfo =  isUp ? `${resourceTunnelInfo}${pathInfo}${backupPathPrefix}` : '';
		const eventDescription = `${resourceStatus}${additionalInfo}`;
		const resourceUI = NetworkingInfoService.generateNetworkLogResource(resource, resourceDescription);

		return { id, type, datetime, resource: resourceUI, eventDescription };
	}

	getBackupPathPrefix(code: NetworkingInfoLogCode, text: string): string {
		switch (code) {
		case NetworkingInfoLogCode.AIPT2_OUTSP:
			return this.formatAipt2OutspInfo(text);
		case NetworkingInfoLogCode.AIPT2_MISSING:
			return ', {{NETWORK.NEWS.pathWithDelays}}';
		default:
			return '';
		}
	}

	formatAipt2OutspInfo(text: string): string {
		const outspResult = text.toLowerCase().match(/preferred-outsp:\d+/);

		if (outspResult) {
			const outsp = this.networkingCodeUtilService.getValueFromParameter(outspResult);

			return `, {{NETWORK.NEWS.outspValue}}: ${outsp}`;
		}

		return '';
	}

	getBackupTunnelStatus(text: string): string {
		const backupStatusResult = text.toLowerCase().match(/backup-p[1-6]:.{2}/);

		if (backupStatusResult) {
			const backupStatus = this.networkingCodeUtilService.getValueFromParameter(backupStatusResult);
			const pathNumber = (backupStatusResult[0].toLowerCase().match(/\d/) as RegExpMatchArray)[0];

			switch (backupStatus) {
			case 'dn':
				return `, {{NETWORK.NEWS.path}} ${pathNumber} {{NETWORK.NEWS.is}} ${NETWORK_LOG_DOWN}`;
			case 'up':
				return `, {{NETWORK.NEWS.path}} ${pathNumber} {{NETWORK.NEWS.is}} ${NETWORK_LOG_UP}`;
			}
		}

		return '';
	}
}
