import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';
import { UiService } from '../services/others/ui.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IFormlySelectField } from '../ts/models/formly-select-field.model';
import { IGeneratedConfigurationParams } from '../components/networking/ts/models/generated-configuration-params.model';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, RendererFactory2, ViewChild } from '@angular/core';
import { IGeneratedConfigurationTemplateOptions } from '../components/networking/ts/models/generated-configuration-template-option.model';

@UntilDestroy()
@Component({
	selector: 'app-formly-field-select',
	styleUrls: ['../formly-styles/select-field-style.scss'],
	template: `
	<div class="form-group custom__select custom__inline mb-0" #container [ngStyle]="{ display: field.hide ? 'none' : null }">
		<label [attr.for]="field.id" *ngIf="label">
			{{label | translate | translateFix:label}}
		</label>

		<div class="select-wrapper">
			<select class="form-control" [attr.name]="label" [attr.id]="field.id" [formControl]="$any(formControl)"
			(selectionChange)="to.change && to.change(field, formControl)" [title]="tooltip | translate | translateFix:tooltip"
			[ngStyle]="{ 'max-width': '100%', 'width': '100%', height: '28px'}"
			[formlyAttributes]="field" #selectBox>
				<option [value]="item.value" *ngFor="let item of selectOptions$ | async">
					{{ item.label | translate | translateFix:item.label }}
				</option>
			</select>
		</div>
	</div>
 `,
})

export class FormlyFieldSelectComponent extends FieldType implements OnInit, AfterViewInit {

	@ViewChild('selectBox') selectBox: ElementRef;
	@ViewChild('container') container: ElementRef;

	label: string;
	tooltip: string;
	renderer2: Renderer2;
	initialSelectValue: string;
	selectOptions: IFormlySelectField[];

	selectOptions$: Observable<IFormlySelectField[]>;

	private selectValuesSet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private uiService: UiService,
		private cdRef: ChangeDetectorRef,
		private rendererFactory2: RendererFactory2
	) {
		super();

		this.renderer2 = this.rendererFactory2.createRenderer(null, null);
	}

	ngOnInit(): void {
		this.watchSelectboxValueChanges();
		this.initialSelectValue = this.field.formControl?.value;

		this.selectOptions$ = this.transformSelectValuesToObservables$()
			.pipe(
				map(selectValues => this.transformSelectValues(selectValues)),
				tap(() => this.selectValuesSet.next(true))
			);

		this.tooltip = (this.field as IGeneratedConfigurationParams).tooltip || '';
		this.label = (this.field.templateOptions as IGeneratedConfigurationTemplateOptions).label;
	}

	ngAfterViewInit(): void {
		this.watchSetInitialSelectValue();
		this.watchElementVisibilityChanges();
	}

	watchSetInitialSelectValue(): void {
		this.selectValuesSet.asObservable()
			.pipe(
				untilDestroyed(this),
				filter(selectValuesSet => selectValuesSet),
				delay(0)
			)
			.subscribe(() => this.setSelectboxWidth());
	}

	transformSelectValues(selectValues: IFormlySelectField[]): IFormlySelectField[] {
		this.selectOptions = selectValues.slice();
		const matchingInitialValue = selectValues.some(selectValueItem => selectValueItem.value === this.initialSelectValue);

		if (matchingInitialValue) {
			return selectValues.slice();
		}

		const newValue = { label: this.initialSelectValue, value: this.initialSelectValue };

		return [...selectValues, newValue];
	}

	transformSelectValuesToObservables$(): Observable<IFormlySelectField[]> {
		const areOptionsArray = Array.isArray(this.to.options);
		const selectValues = this.to.options as IFormlySelectField[];

		return areOptionsArray ? of(selectValues) : selectValues as unknown as Observable<IFormlySelectField[]>;
	}

	setSelectboxWidth(): void {
		this.uiService.setSelectboxWidth(this.cdRef, this.renderer2, this.selectBox, false, '');
	}

	watchElementVisibilityChanges(): void {
		const observer = new MutationObserver(() => this.setSelectboxWidth());

		observer.observe(this.container.nativeElement, { attributes: true, childList: true, characterData: true });
	}

	watchSelectboxValueChanges(): void {
		this.field.formControl?.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(() => this.setSelectboxWidth());
	}
}
