import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CoreService } from '../../../../components/core/services/core.service';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
	],
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class FooterComponent implements OnInit {

	public version: string;
	public currentDate: Date;

	constructor(
		private readonly coreService: CoreService
	) { }

	public ngOnInit(): void {
		this.currentDate = new Date();
		this.version = this.coreService.getCurrentVersion().version;
	}
}
