import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { AuthNgrxService } from '../../../auth/services/auth-ngrx.service';
import { ResponseStatus } from '../../../../ts/enums/response-status.enum';
import { UserPreferencesNgrxService } from '../user-preferences-ngrx.service';
import { IUserPreferenceItem } from '../../ts/models/user-preferences-item.model';
import { IUserPreferencesResponse } from '../../ts/models/user-preferences-response.model';
import { IUserPreferencesBasicSave } from '../../ts/models/user-preferences-basic-save.model';
import { IUserPreferencesVoPayload } from '../../ts/models/user-preferences-vo-payload.model';
import { UserPreferencesSaveSettings } from '../../ts/types/user-preferences-save-settings.type';
import { BasicUserPreferencesHttpLogicService } from './basic-user-preferences-http-logic.service';
import { IUserPreferencesSaveResponse } from '../../ts/models/user-preferences-save-response.model';
import { ISaveConfigurationResponse } from '../../../../ts/models/save-configuration-response.model';
import { IUserPreferencesActionResponse } from '../../ts/models/user-preferences-action-response.model';
import { IUserPreferencesSaveForcePayload } from '../../ts/models/user-preferences-save-force-payload.model';
import { PrivilegesPreferencesTableService } from '../privileges-preferences/privileges-preferences-table.service';
import * as customOperators from '../../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class BasicUserPreferencesHttpService {

	private static preferencesUUID: BehaviorSubject<string> = new BehaviorSubject<string>('');

	private readonly copyUserUrl: string = environment.abilisUrl + '/sys/admin/users/user_copy.json';
	private readonly addNewUserUrl: string = environment.abilisUrl + '/sys/admin/users/user_add.json';
	private readonly deleteUserUrl: string = environment.abilisUrl + '/sys/admin/users/user_delete.json';
	private readonly setSettingsUrl: string = environment.abilisUrl + '/sys/admin/users/settings_set.json';
	private readonly saveForceUrl: string = environment.abilisUrl + '/sys/admin/users/settings_force.json';
	private readonly basicUserPreferencesUrl: string = environment.abilisUrl + '/sys/admin/users/settings.json';
	private readonly setAdminPreferencesUrl: string = environment.abilisUrl + '/sys/admin/users/cti_pref_set.json';
	private readonly savePermanentlyUserSettingsUrl: string = environment.abilisUrl + '/sys/admin/users/save_conf.json';

	constructor(
		private http: HttpClient,
		private authNgrxService: AuthNgrxService,
		private userPreferencesNgrxService: UserPreferencesNgrxService,
		private privilegesPreferencesTableService: PrivilegesPreferencesTableService,
		private basicUserPreferencesLogicService: BasicUserPreferencesHttpLogicService
	) { }

	// HTTP

	fetchBasicUserPreferences$(): Observable<IUserPreferenceItem[]> {
		return this.http.get<IUserPreferencesResponse>(this.basicUserPreferencesUrl)
			.pipe(
				map(response => {
					const { 'users-settings': userSettings, message, Uuid, Saved } = response.Response;

					BasicUserPreferencesHttpService.setUuid(Uuid);
					this.privilegesPreferencesTableService.setResponseErrorMessage(message);

					if (message) { return []; }

					if (Saved) {
						this.userPreferencesNgrxService.setSaveConfigurationResponse({ showError: false, errorText: '' });
					}

					return this.basicUserPreferencesLogicService.formatUserSettings(userSettings);
				}),
				customOperators.retryFromError$(1000)
			);
	}

	copyUserToNewUser$(
		user: string, newuser: string, newUserPassword: string, newUserRealname: string
	): Observable<IUserPreferencesActionResponse | null> {
		const realnamePayload = this.basicUserPreferencesLogicService.getUsernameParameter(newUserRealname);
		const copyPayload = {
			uuid: BasicUserPreferencesHttpService.preferencesUUID.value, user,
			newuser, PWD: newUserPassword, ...realnamePayload
		};

		return this.http.post<IUserPreferencesActionResponse>(this.copyUserUrl, copyPayload)
			.pipe(
				tap(response => this.basicUserPreferencesLogicService.handleActionResponse(response)),
				catchError(() => this.basicUserPreferencesLogicService.handleActionError$())
			);
	}

	addNewUser$(
		newUser: string, newUserPassword: string, newUserRealname: string
	): Observable<IUserPreferencesActionResponse | null> {
		const realnamePayload = this.basicUserPreferencesLogicService.getUsernameParameter(newUserRealname);
		const savePayload = {
			uuid: BasicUserPreferencesHttpService.preferencesUUID.value,
			user: newUser, PWD: newUserPassword, ...realnamePayload
		};

		return this.http.post<IUserPreferencesActionResponse>(this.addNewUserUrl, savePayload)
			.pipe(
				tap(response => this.basicUserPreferencesLogicService.handleActionResponse(response)),
				catchError(() => this.basicUserPreferencesLogicService.handleActionError$())
			);
	}

	setBasicUserSetting$(payload: UserPreferencesSaveSettings): Observable<IUserPreferencesActionResponse | null> {
		const isVsCamera = Object.keys(payload).includes('VS-CAM');
		const setPayload = { uuid: BasicUserPreferencesHttpService.preferencesUUID.value, ...payload };

		return this.http.post<IUserPreferencesActionResponse>(this.setSettingsUrl, setPayload)
			.pipe(
				tap(response => {
					BasicUserPreferencesHttpService.setUuid(response.Response.Uuid);
					this.basicUserPreferencesLogicService.handleActionResponse(response, payload);

					if (!isVsCamera) { return; }

					this.authNgrxService.refreshUserPermissions();
				}),
				catchError(() => this.basicUserPreferencesLogicService.handleActionError$())
			);
	}

	setUserPrivileges$(savePayload: IUserPreferencesBasicSave, showAddNewUser: boolean): Observable<IUserPreferencesActionResponse | null> {
		const setPayload = { uuid: BasicUserPreferencesHttpService.preferencesUUID.value, ...savePayload };
		const actionUrl = !showAddNewUser ? this.setSettingsUrl : this.addNewUserUrl;

		return this.http.post<IUserPreferencesActionResponse>(actionUrl, setPayload)
			.pipe(
				tap(response => this.basicUserPreferencesLogicService.handleActionResponse(response)),
				catchError(() => this.basicUserPreferencesLogicService.handleActionError$())
			);
	}

	deleteSelectedUser$(user: string): Observable<IUserPreferencesActionResponse | null> {
		const savePayload = { uuid: BasicUserPreferencesHttpService.preferencesUUID.value, user };

		return this.http.post<IUserPreferencesActionResponse>(this.deleteUserUrl, savePayload)
			.pipe(
				tap(response => this.basicUserPreferencesLogicService.handleActionResponse(response)),
				catchError(() => this.basicUserPreferencesLogicService.handleActionError$())
			);
	}

	savePermanentlyUserSetting$(): Observable<ISaveConfigurationResponse | null> {
		return this.http.get<IUserPreferencesSaveResponse>(this.savePermanentlyUserSettingsUrl)
			.pipe(
				map(response => {
					const { message, text } = response.Response;

					if (message.toLowerCase() === ResponseStatus.OK && !text.includes('ERROR')) {
						return { showError: false, errorText: '' };
					}

					return { showError: true, errorText: text };
				}),
				catchError(() => this.basicUserPreferencesLogicService.handleActionError$())
			);
	}

	saveAbilisphoneValues$(voPayload: IUserPreferencesVoPayload): Observable<IUserPreferencesActionResponse | null> {
		const { user, abilisTracking } = voPayload;
		const payload = { CtiPreferences: [{ user, 'vo-tracking': abilisTracking }] };

		return this.http.post<IUserPreferencesActionResponse>(this.setAdminPreferencesUrl, payload)
			.pipe(
				tap(response => {
					BasicUserPreferencesHttpService.setUuid(response.Response.Uuid);
					this.basicUserPreferencesLogicService.handleActionResponse(response);
				}),
				catchError(() => this.basicUserPreferencesLogicService.handleActionError$())
			);
	}

	saveForceSettings$(payload: IUserPreferencesSaveForcePayload, showModal?: boolean): Observable<IUserPreferencesActionResponse | null> {
		return this.http.post<IUserPreferencesActionResponse>(this.saveForceUrl, payload)
			.pipe(
				tap(response => {
					BasicUserPreferencesHttpService.setUuid(response.Response.Uuid);
					this.basicUserPreferencesLogicService.handleActionResponse(response, undefined, showModal);
				}),
				catchError(() => this.basicUserPreferencesLogicService.handleActionError$())
			);
	}

	setPrivilegeCheckbox$(user: string, parameterKey: string, parameterValue: boolean): Observable<boolean | null> {
		const setPayload = { uuid: BasicUserPreferencesHttpService.preferencesUUID.value, user, [parameterKey]: parameterValue };

		return this.http.post<IUserPreferencesActionResponse>(this.setSettingsUrl, setPayload)
			.pipe(
				tap(response => this.basicUserPreferencesLogicService.handleActionResponse(response)),
				map(response => {
					const { rsp } = response.Response;

					return (rsp || '').toLowerCase().includes('command executed');
				}),
				catchError(() => this.basicUserPreferencesLogicService.handleActionError$())
			);
	}

	public static setUuid(Uuid: string | undefined): void {
		if (!Uuid) { return; }

		this.preferencesUUID.next(Uuid);
	}

	public static getPreferencesUUID$(): Observable<string> {
		return this.preferencesUUID.asObservable();
	}
}
