export enum OpcPanelCtiControl {
	CALL = 'CALL',
	CLOSE = 'CLOSE',
	TRANSFER = 'TRANSFER',
	CALLTRY = 'CALLTRY',
	CLEARCTIC = 'CLEARCTIC',
	'3PTRANSFER' = '3PTRANSFER',
	'3PTRANSFERQUEUE' = '3PTRANSFERQUEUE',
	ABORTCALLTRY = 'ABORTCALLTRY',
	ACCEPT = 'ACCEPT',
	SWITCHCALL = 'SWITCHCALL',
	HOLD = 'HOLD',
	UNHOLD = 'UNHOLD',
	CHANGEQUEUE = 'CHANGEQUEUE',
	RECORD = 'RECORD',
	RECORDSTOP = 'RECORDSTOP',
	DTMF = 'DTMF',
	PCPHONE = 'PCPHONE',
	MIXCTIC = 'MIXCTIC',
	GPSREFRESH = 'GPSREFRESH'
}
