import { Injectable } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { NetworkingInfoService } from '../networking-info.service';
import { NETWORK_LOG_DOWN, NETWORK_LOG_UP } from '../../util/networking-info-util';
import { NetworkingInfoLogType } from '../../ts/enums/networking-info-log-type.enum';
import { NetworkingInfoLogCode } from '../../ts/enums/networking-info-log-code.enum';
import { INetworkingInfoLogItem } from '../../ts/models/networking-info-log-item.model';
import { NetworkingInfoNewsCodeUtilService } from './networking-info-news-code-util.service';
import { NetworkingInfoNewsCodeAipt2Service } from './networking-info-news-code-aipt2.service';
import { INetworkingInfoCustomLogItem } from '../../ts/models/networking-info-custom-log-item.model';
import { NetworkingInfoNewsCodeResourceService } from './networking-info-news-code-resource.service';
import { INetworkingInfoResourceItem } from '../../ts/models/resource/networking-info-resource-item.model';

@Injectable({
	providedIn: 'root'
})
export class NetworkingInfoNewsCodeService {

	private readonly networkResourceCodes = [
		NetworkingInfoLogCode.LOG_CLEARED,
		NetworkingInfoLogCode.LAN, NetworkingInfoLogCode.PPP,
		NetworkingInfoLogCode.LAN_LAYER, NetworkingInfoLogCode.INFO,
		NetworkingInfoLogCode.DISK_WARNING, NetworkingInfoLogCode.DSL,
		NetworkingInfoLogCode.BUILD, NetworkingInfoLogCode.INFO_WITH_PING,
		NetworkingInfoLogCode.FAST_ETHERNET, NetworkingInfoLogCode.AIPT2_OUTSP,
		NetworkingInfoLogCode.AIPT2_MISSING, NetworkingInfoLogCode.AIPT2_BACKUP,
		NetworkingInfoLogCode.MODEM_SPEED_CHANGES, NetworkingInfoLogCode.MODEM_STATE_CHANGES,
		NetworkingInfoLogCode.AIPT2_MAIN_TUNNEL, NetworkingInfoLogCode.AIPT2_MAIN_PING_TUNNEL_PATH,
		NetworkingInfoLogCode.AIPT2_MAIN_TUNNEL_PATH, NetworkingInfoLogCode.AIPT2_MAIN_PING_TUNNEL,
		NetworkingInfoLogCode.VPN_IPSEC
	];

	constructor(
		private authService: AuthService,
		private networkingCodeUtilService: NetworkingInfoNewsCodeUtilService,
		private networkingCodeAipt2Service: NetworkingInfoNewsCodeAipt2Service,
		private networkingCodeResourceService: NetworkingInfoNewsCodeResourceService
	) { }

	formatLogItemBasedOnCode(
		systemLogItem: INetworkingInfoLogItem, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const { code, date, time } = systemLogItem;
		const datetime = `${date} ${time}`;

		switch (code) {
		case NetworkingInfoLogCode.BUILD:
			return this.formatBuildLogItem(systemLogItem, datetime);
		case NetworkingInfoLogCode.LAN:
			return this.networkingCodeResourceService.formatLanMainTunnelLog(systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.PPP:
			return this.networkingCodeResourceService.formatPPPLog(systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.DISK_WARNING:
			return this.formatDiskWarningInfo(systemLogItem, datetime);
		case NetworkingInfoLogCode.DSL:
			return this.networkingCodeResourceService.formatDslLog(systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.MODEM_SPEED_CHANGES:
			return this.formatModemSpeedChangesLog(systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.MODEM_STATE_CHANGES:
			return this.formatModemStateChangesLog(systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.LAN_LAYER:
			return this.networkingCodeResourceService.formatLanLayerLog(systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.INFO:
			return this.formatInfoLog(systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.INFO_WITH_PING:
			return this.formatInfoWithPingLog(systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.AIPT2_MAIN_TUNNEL:
		case NetworkingInfoLogCode.AIPT2_MAIN_TUNNEL_PATH:
		case NetworkingInfoLogCode.AIPT2_MAIN_PING_TUNNEL:
		case NetworkingInfoLogCode.AIPT2_MAIN_PING_TUNNEL_PATH:
			return this.networkingCodeAipt2Service.formatAipt2MainPingTunnelLog(code, systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.FAST_ETHERNET:
			return this.networkingCodeResourceService.formatFastEthernetLog(systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.AIPT2_OUTSP:
		case NetworkingInfoLogCode.AIPT2_BACKUP:
		case NetworkingInfoLogCode.AIPT2_MISSING:
			return this.networkingCodeAipt2Service.formatAipt2BackupLog(code, systemLogItem, datetime, availableResources);
		case NetworkingInfoLogCode.LOG_CLEARED:
			return this.formatClearedLogInfo(systemLogItem, datetime);
		case NetworkingInfoLogCode.VPN_IPSEC:
			return this.networkingCodeResourceService.formatVpnIpsecCode(systemLogItem, datetime);
		}
	}

	formatClearedLogInfo(systemLogItem: INetworkingInfoLogItem, datetime: string): INetworkingInfoCustomLogItem {
		const id = this.networkingCodeUtilService.createSystemLogId(systemLogItem);

		return {
			id, type: NetworkingInfoLogType.INFO, datetime,
			resource: '', eventDescription: 'Network News content was cleared'
		};
	}

	formatInfoWithPingLog(
		systemLogItem: INetworkingInfoLogItem, datetime: string, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const { text } = systemLogItem;

		const resourceData = this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /i: ip-\d+/);
		const { id, resource, isUp, resourceDescription } = resourceData;
		const type = NetworkingInfoLogType.CRITICAL__ERROR;

		const pingStatus = this.networkingCodeAipt2Service.getPingStatus(text);
		const resourceStatus = this.networkingCodeUtilService.getMainResourceState(text);
		const linkStatus = this.networkingCodeUtilService.getMainResourceLinkStatus(text, /link:.+\s/);
		const linkPingUI = isUp ? `${linkStatus}${pingStatus}` : '';

		const eventDescription = `${resourceStatus}${linkPingUI}`;
		const resourceUI = NetworkingInfoService.generateNetworkLogResource(resource, resourceDescription);

		return { id, type, datetime, resource: resourceUI, eventDescription };
	}

	formatInfoLog(
		systemLogItem: INetworkingInfoLogItem, datetime: string, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const { text } = systemLogItem;

		const resourceData = this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /i: ip-\d+/);
		const { id, resource, isUp, resourceDescription } = resourceData;
		const type = NetworkingInfoLogType.CRITICAL__ERROR;

		const resourceStatus = this.networkingCodeUtilService.getMainResourceState(text);
		const linkStatus = this.networkingCodeUtilService.getMainResourceLinkStatus(text, /link:.+/);
		const linkStatusUI = isUp ? linkStatus : '';

		const eventDescription = `${resourceStatus}${linkStatusUI}`;
		const resourceUI = NetworkingInfoService.generateNetworkLogResource(resource, resourceDescription);

		return { id, type, datetime, resource: resourceUI, eventDescription };
	}

	formatModemStateChangesLog(
		systemLogItem: INetworkingInfoLogItem, datetime: string, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const { text } = systemLogItem;
		const type = NetworkingInfoLogType.CRITICAL__ERROR;
		const { id, resource } = this.getModemResourceInfo(systemLogItem, availableResources);

		const modemState = this.getModemState(text);
		const modemLinkState = this.getModemLinkState(text);
		const eventDescription = `{{NETWORK.NEWS.modem}} ${modemState}${modemLinkState}`;

		return { id, type, datetime, resource, eventDescription };
	}

	getModemLinkState(text: string): string {
		const modemInfoResult = text.match(/L:.{2}/);

		if (modemInfoResult) {
			const modemLinkStatus = modemInfoResult[0].trim().split(':')[1].toLowerCase();

			switch (modemLinkStatus) {
			case 'up':
				return `, {{NETWORK.NEWS.lineIs}} ${NETWORK_LOG_UP}`;
			case 'dn':
				return `, {{NETWORK.NEWS.lineIs}} ${NETWORK_LOG_DOWN}`;
			default:
				return ', {{NETWORK.NEWS.lineIs}} {{NETWORK.NEWS.unknown}}';
			}
		}

		return '';
	}

	getModemState(text: string): string {
		const modemInfoResult = text.match(/M:.+\s/);

		if (modemInfoResult) {
			const modemStatus = modemInfoResult[0].trim().split(':')[1].toLowerCase();
			const statusText = modemStatus === 'up' ? NETWORK_LOG_UP : NETWORK_LOG_DOWN;

			return ` {{NETWORK.NEWS.is}} ${statusText}`;
		}

		return ' {{NETWORK.NEWS.is}} {{NETWORK.NEWS.unknown}}';
	}

	formatModemSpeedChangesLog(
		systemLogItem: INetworkingInfoLogItem, datetime: string, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const { text } = systemLogItem;
		const { id, resource } = this.getModemResourceInfo(systemLogItem, availableResources);

		const speedInfo = this.getDownloadUploadInfo(text);
		const type = NetworkingInfoLogType.CRITICAL__ERROR;

		const eventDescription = `{{NETWORK.NEWS.modemNegotiated}}: ${speedInfo}`;

		return { id, type, datetime, resource, eventDescription };
	}

	getModemResourceInfo(systemLogItem: INetworkingInfoLogItem, availableResources: INetworkingInfoResourceItem[]) {
		return this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /res:eth-\d+/);
	}

	getDownloadUploadInfo(text: string): string {
		const speedInfoResult = text.match(/RX\/TX:.+/);

		if (speedInfoResult) {
			const speedInfo = speedInfoResult[0].split(':')[1];
			const [speed, unit] = speedInfo.split(' ');
			const [downloadSpeed, uploadSpeed] = speed.split('/');

			return `${downloadSpeed}${unit} - ${uploadSpeed}${unit}`;
		}

		return '{{NETWORK.NEWS.unknownSpeed}}';
	}

	formatDiskWarningInfo(systemLogItem: INetworkingInfoLogItem, datetime: string): INetworkingInfoCustomLogItem {
		const id = this.networkingCodeUtilService.createSystemLogId(systemLogItem);

		const eventDescription = '{{NETWORK.NEWS.diskError}}';
		const type = NetworkingInfoLogType.CRITICAL__ERROR;

		return { id, type, datetime, resource: '', eventDescription };
	}

	formatBuildLogItem(systemLogItem: INetworkingInfoLogItem, datetime: string): INetworkingInfoCustomLogItem {
		const id = this.networkingCodeUtilService.createSystemLogId(systemLogItem);

		const type = NetworkingInfoLogType.CRITICAL__ERROR;
		const currentBuild = this.authService.getAbilisBuildVersion();
		const eventDescription = this.getSystemStartupMessage(currentBuild);

		return { id, type, datetime, resource: '', eventDescription };
	}

	getSystemStartupMessage(buildVersion: string): string {
		return `{{NETWORK.NEWS.systemStart}}: "${buildVersion}"`;
	}

	generateCodeQueryParams(): string {
		return this.networkResourceCodes.join(',');
	}
}
