import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, filter } from 'rxjs';
import { TabComponent } from '../components/tab/tab.component';

@Injectable({
	providedIn: 'root'
})
export class TabsService {

	private activeTabComponent: BehaviorSubject<TabComponent | null> = new BehaviorSubject<TabComponent | null>(null);

	constructor() { }

	public setActiveTabComponent(tabComponent: TabComponent): void {
		this.activeTabComponent.next(tabComponent);
	}

	public watchActiveTabComponent$(): Observable<TabComponent> {
		return this.activeTabComponent.asObservable()
			.pipe(
				filter((activeTabComponent): activeTabComponent is TabComponent => !!activeTabComponent)
			);
	}
}
