<app-antek-logo></app-antek-logo>

<app-tabs>
	<app-tab [active]="router.url.includes(getRoutesEnum.ADDRESSBOOK)"
		[disabled]="(addressbookPermission$ | async)?.isFeatureDisabled"
		(selectTabEvent)="onPhoneTabRouting(getRoutesEnum.ADDRESSBOOK)"
		[tooltip]="(addressbookPermission$ | async)?.tooltip || ''"
		[location]="'/#/phone;replaceUrl=true/(address-book:address-book/user/' + loggedUsername + ')'">
		<app-tab-label>
			<img src="assets/feather-icons/book.svg" alt="address-book">
			<span>{{'HOME.addressBook' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="address-book"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.RECENT_CALLS)"
		[disabled]="(recentCallsPermission$ | async)?.isFeatureDisabled"
		(selectTabEvent)="onPhoneTabRouting(getRoutesEnum.RECENT_CALLS)"
		[tooltip]="(recentCallsPermission$ | async)?.tooltip || ''"
		[location]="'/#/phone;replaceUrl=true/(recent-calls:recent-calls/user/' + loggedUsername + ')'">
		<app-tab-label>
			<img src="assets/feather-icons/clock.svg" alt="clock">
			<span>{{'HOME.recentCalls' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="recent-calls"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.PREFERENCES)"
		[disabled]="(preferencesPermission$ | async)?.isFeatureDisabled"
		(selectTabEvent)="onPhoneTabRouting(getRoutesEnum.PREFERENCES)"
		[tooltip]="(preferencesPermission$ | async)?.tooltip || ''"
		[location]="'/#/phone;replaceUrl=true/(preferences:preferences)'">
		<app-tab-label>
			<img src="assets/feather-icons/sliders.svg" alt="preferences">
			<span>{{'HOME.preferences' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="preferences"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.OPC_PANEL)"
		[disabled]="(opcPermission$ | async)?.isFeatureDisabled"
		(selectTabEvent)="onPhoneTabRouting(getRoutesEnum.OPC_PANEL)" [tooltip]="(opcPermission$ | async)?.tooltip || ''"
		[location]="'/#/phone;replaceUrl=true/(opc-panel:opc-panel)'">
		<app-tab-label>
			<img src="assets/feather-icons/grid.svg" alt="opc panel">
			<span>{{'HOME.opcPanel' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="opc-panel"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.EXTENSIONS)"
		(selectTabEvent)="onPhoneTabRouting(getRoutesEnum.EXTENSIONS)"
		[location]="'/#/phone;replaceUrl=true/(extensions:extensions)'" *ngIf="haveAdminPermission$ | async">
		<app-tab-label>
			<img src="assets/feather-icons/network.svg" alt="extensions">
			<span>{{'ADMIN.extensions' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="extensions"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.PHONE_TRAFFIC)"
		[disabled]="(phoneStatisticsPermission$ | async)?.isFeatureDisabled"
		(selectTabEvent)="onPhoneTabRouting(getRoutesEnum.PHONE_TRAFFIC)"
		[tooltip]="(phoneStatisticsPermission$ | async)?.tooltip || ''"
		[location]="'/#/phone;replaceUrl=true/(phone-traffic:phone-traffic)'">
		<app-tab-label>
			<img src="assets/feather-icons/graph.svg" alt="statistics">
			<span>{{'HOME.statistics' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="phone-traffic"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.ROUTINGS)"
		[disabled]="(routingsPermission$ | async)?.isFeatureDisabled"
		[tooltip]="(routingsPermission$ | async)?.tooltip || ''"
		(selectTabEvent)="onPhoneTabRouting(getRoutesEnum.ROUTINGS)"
		[location]="'/#/phone;replaceUrl=true/(routings:routings)'" *ngIf="haveAdminPermission$ | async">
		<app-tab-label>
			<img src="assets/feather-icons/git-pull-request.svg" alt="routings">
			<span>{{'HOME.ctiRoutings' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="routings"></router-outlet>
		</app-tab-body>
	</app-tab>
</app-tabs>
