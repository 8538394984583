import { Action, createReducer, on } from '@ngrx/store';
import { IOpcPanelClientSettingsState } from '../../ts/models/client-settings/opc-panel-client-settings-state.model';
import * as opcPanelClientActions from './opc-panel-client.actions';

export const opcPanelClientFeatureKey = 'opcPanelClient';

export const initialOpcPanelClientState: IOpcPanelClientSettingsState = {
	Response: null
};

export const opcPanelClientReducer = createReducer(
	initialOpcPanelClientState,
	on(opcPanelClientActions.finishLoadingOpcPanelClientSettings, (state, { payload }) =>
		({ ...state, Response: payload.settings.Response }))
);

export function OpcPanelClientReducer(state: IOpcPanelClientSettingsState | undefined, action: Action) {
	return opcPanelClientReducer(state, action);
}
