import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable, zip, filter } from 'rxjs';
import { IAppState } from '../../../../root-reducers';
import { TranslateService } from '@ngx-translate/core';
import { AutomationProgramState } from '../../ts/enums/automation-program-state.enum';
import { IAutomationProgramEdit } from '../../ts/models/automation-program-edit.model';
import { IProjectOptions } from '../../ts/models/automation-program-edit-project-options.model';
import { AutomationProgramExtensionType } from '../../ts/enums/automation-program-edit-extension-type.enum';
import { AutomationProgramProjectTimeout } from '../../ts/enums/automation-program-edit-project-timeout.enum';
import { IAutomationProgramEditSaveProject } from '../../ts/models/automation-program-edit-saved-project.model';
import { INewAutomationProgramEditInputValue } from '../../ts/models/automation-program-edit-new-input-value.model';
import * as automationProgramEditSelectors from '../../ngrx/automation-program-edit.selectors';
import * as automationProgramEditActions from '../../ngrx/automation-program-edit.actions';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramEditNgrxService {

	constructor(
		private store: Store<IAppState>,
		private translate: TranslateService
	) { }

	areAutomationProgramsLoading$(): Observable<boolean> {
		return this.store.pipe(select(automationProgramEditSelectors.selectAutomationProgramLoadingState()));
	}

	selectAllAutomationPrograms$(): Observable<IAutomationProgramEdit[]> {
		return this.store.pipe(select(automationProgramEditSelectors.selectAllJsonPrograms()));
	}

	selectScriptName$(): Observable<string> {
		return this.store.pipe(select(automationProgramEditSelectors.selectAutomationProgramName()));
	}

	getAllProgramNames$(): Observable<string[]> {
		return this.store.pipe(select(automationProgramEditSelectors.selectAllAutomationProgramNames()));
	}

	getAllScriptNamesAndSelectedScript$(): Observable<[string[], string]> {
		return zip(this.getAllProgramNames$(), this.selectScriptName$())
			.pipe(
				take(1)
			);
	}

	onRunStopLuaScript(selectedScriptName: string, scriptState: AutomationProgramState): void {
		const startProgram = scriptState === AutomationProgramState.STOPPED;
		const payload = { programName: selectedScriptName, startProgram };

		this.store.dispatch(automationProgramEditActions.startStopAutomationProgram({ payload }));
	}

	cancelAutomationProgramObservables(): void {
		this.store.dispatch(automationProgramEditActions.cancelAutomationProgramObservables());
	}

	clearAutomationProgramTableObservables(): void {
		this.store.dispatch(automationProgramEditActions.clearAutomationProgramTableObservables());
	}

	startLoadingAutomationPrograms(): void {
		this.store.dispatch(automationProgramEditActions.startLoadingAutomationPrograms());
	}

	fetchAutomationProgramOnce(): void {
		const payload = { programName: '' };

		this.store.dispatch(automationProgramEditActions.automationProgramsFetchOnce({ payload }));
	}

	onExportTable(filePath: string): void {
		const payload = { filePath };

		this.store.dispatch(automationProgramEditActions.exportJsonTable({ payload }));
	}

	loadAutomationProgramTable(automationProgramName: string): void {
		const payload = { automationProgramName };

		this.store.dispatch(automationProgramEditActions.loadAutomationProgramTable({ payload }));
	}

	setAutomationProgramOptions(ProjectTimeout: number, ProjectTimeoutUnit: AutomationProgramProjectTimeout): void {
		const payload = { ProjectTimeout, ProjectTimeoutUnit };

		this.store.dispatch(automationProgramEditActions.setAutomationProgramOptions({ payload }));
	}

	deleteProgram(automationProgram: IAutomationProgramEdit, areSheets = false): void {
		const { name, extension } = automationProgram;
		const translatedPrefix = this.translate.instant('AUTO.RULES.EDIT.deleteConfirmation');
		const confirmedDelete = confirm(`${translatedPrefix} "${name}"?`);

		if (confirmedDelete) {
			const deletePayload = { automationProgram: name + extension, areSheets };
			const setStatePayload = { automationProgram: { ...automationProgram, isDeleting: true } };

			this.store.dispatch(automationProgramEditActions.setProgramDeleteState({ payload: setStatePayload }));
			this.store.dispatch(automationProgramEditActions.deleteAutomationProgram({ payload: deletePayload }));
		}
	}

	importScript(dataToBeSaved: IAutomationProgramEditSaveProject): void {
		const payload = { ...dataToBeSaved };

		this.store.dispatch(automationProgramEditActions.importAutomationProgram({ payload }));
	}

	saveScriptContent(automationProgram: string, state: AutomationProgramState, programContent: string): void {
		const payload = { automationProgram, state, programContent };

		this.store.dispatch(automationProgramEditActions.saveAutomationProgramContent({ payload }));
	}

	toggleAutostartScript(tableDataItem: IAutomationProgramEdit, autostart: boolean): void {
		const { name: programName } = tableDataItem;
		const payload = { programName, autostart };

		this.store.dispatch(automationProgramEditActions.setAutomationProgramAutostart({ payload }));
	}

	clearAutomationProgramStoreAction(): void {
		this.store.dispatch(automationProgramEditActions.clearAutomationProgramStore());
	}

	isProjectNameTaken$(projectName: string, currentScriptName: string): Observable<boolean> {
		return this.selectAllAutomationPrograms$()
			.pipe(
				take(1),
				map(automationPrograms => automationPrograms
					.filter(item => item.name !== currentScriptName)
					.some(item => item.name === projectName))
			);
	}

	getAutomationProgram$(programName: string): Observable<IAutomationProgramEdit> {
		return this.selectAllAutomationPrograms$()
			.pipe(
				map(automationPrograms => automationPrograms.find(item => item.name === programName)),
				filter((automationProgram): automationProgram is IAutomationProgramEdit => !!automationProgram)
			);
	}

	saveProjectOptions(automationProgramName: string, projectOptions: IProjectOptions): void {
		const payload = { automationProgramName, projectOptions };

		this.store.dispatch(automationProgramEditActions.saveAutomationProgramProjectOptions({ payload }));
	}

	finishLoadingAutomationJsonPrograms(automationPrograms: IAutomationProgramEdit[]): void {
		const automationProgramsUpdated = this.getAutomationProgramsByType
		<IAutomationProgramEdit>(automationPrograms, AutomationProgramExtensionType.JSON);
		const payload = { automationPrograms: automationProgramsUpdated };

		this.store.dispatch(automationProgramEditActions.automationProgramsLoaded({ payload }));
	}

	getAutomationProgramsByType<T extends { extension: string }>(
		automationPrograms: T[], extension: AutomationProgramExtensionType
	): T[] {
		return automationPrograms.reduce((accumulator, automationProgram) => {
			const isLuaScript = automationProgram.extension === extension;

			return isLuaScript ? [...accumulator, { ...automationProgram, runStopActionInProgress: false }] : accumulator;
		}, [] as T[]);
	}

	setDevicesCheckboxState(selectedLineDevices: string[]): void {
		const payload = { selectedLineDevices };

		this.store.dispatch(automationProgramEditActions.setSelectedLineDevices({ payload }));
	}

	setPreselectedInputValues(customInputs: INewAutomationProgramEditInputValue[]): void {
		const payload = { customInputs };

		this.store.dispatch(automationProgramEditActions.setPreselectedInputValues({ payload }));
	}
}
