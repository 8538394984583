import { Pipe, PipeTransform } from '@angular/core';
import { IAutomationMapDevice } from '../components/automation/ts/models/automation-map-device.model';
import { RioRvsIohubDeviceType } from '../components/rio-rvs/ts/enums/rio-rvs-iohub-device-type.enum';
import { AutomationGenericService } from '../components/automation/services/automation-generic.service';
import {
	IAutomationProgramEditLineDevice
} from '../components/automation-program-edit/ts/models/automation-program-edit-line-device.model';

@Pipe({
	name: 'automationDeviceShort'
})
export class AutomationDeviceShortPipe implements PipeTransform {

	constructor() { }

	transform(value: string | undefined, device?: IAutomationMapDevice | IAutomationProgramEditLineDevice): string {
		if (device?.deviceName?.toLowerCase().startsWith(RioRvsIohubDeviceType.WIO)) {
			return AutomationGenericService.getWioPort(device);
		}

		return value ? AutomationGenericService.getTranslatedShortClass(value) : '';
	}
}
