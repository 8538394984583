import { Action, createReducer, on } from '@ngrx/store';
import { INetworkingDns } from '../ts/models/networking-dns.model';
import { INetworkingDnsCacheItem } from '../ts/models/dns-cache/networking-dns-cache-item.model';
import { INetworkingDnsPrivateTableDataItem } from '../ts/models/private-dns/networking-dns-private-table-data-item.model';
import { INetworkingDnsWhitelistBlacklistItem } from '../ts/models/whitelist-blacklist/networking-dns-whitelist-blacklist-item.model';
import * as dnsActions from './dns.actions';

export const dnsKey = 'dns';

export interface IDnsState {
	isLoading: boolean;
	isLoadingDnsCache: boolean;
	isLoadingResolvedData: boolean;
	isLoadingPrivateDnsTable: boolean;
	dnsCacheTableData: INetworkingDnsCacheItem[];
	dnsConfiguration: INetworkingDns | undefined;
	isLoadingWhitelistData: boolean;
	isLoadingBlacklistData: boolean;
	resolvedDnsData: INetworkingDnsWhitelistBlacklistItem[];
	dnsWhitelistData: INetworkingDnsWhitelistBlacklistItem[];
	dnsBlacklistData: INetworkingDnsWhitelistBlacklistItem[];
	dnsPrivateTableData: INetworkingDnsPrivateTableDataItem[];
	isLoadingDnsWhitelistApplied: boolean;
	dnsWhitelistApplied: INetworkingDnsWhitelistBlacklistItem[];
	isLoadingDnsBlacklistExemption: boolean;
	dnsBlacklistExemption: INetworkingDnsWhitelistBlacklistItem[];
}

export const initialDnsState: IDnsState = {
	isLoading: true,
	dnsCacheTableData: [],
	isLoadingResolvedData: true,
	isLoadingDnsCache: true,
	dnsPrivateTableData: [],
	dnsConfiguration: undefined,
	isLoadingWhitelistData: true,
	isLoadingBlacklistData: true,
	resolvedDnsData: [],
	dnsWhitelistData: [],
	dnsBlacklistData: [],
	isLoadingPrivateDnsTable: true,
	isLoadingDnsWhitelistApplied: true,
	dnsWhitelistApplied: [],
	isLoadingDnsBlacklistExemption: true,
	dnsBlacklistExemption: []
};

const dnsReducer = createReducer(
	initialDnsState,
	on(dnsActions.finishLoadingResolvedDnsData, (state, { payload }) =>
		({ ...state, resolvedDnsData: payload.resolvedData, isLoadingResolvedData: false })),
	on(dnsActions.finishLoadingDnsCacheData, (state, { payload }) =>
		({ ...state, dnsCacheTableData: payload.dnsCacheTableData, isLoadingDnsCache: false })),
	on(dnsActions.finishLoadingDnsConfiguration, (state, { payload }) =>
		({ ...state, dnsConfiguration: payload.dnsConfiguration, isLoading: false })),
	on(dnsActions.setLoadingDnsState, (state, { payload }) => ({ ...state, isLoading: payload.isLoading })),
	on(dnsActions.finishLoadingPrivateDnsTable, (state, { payload }) =>
		({ ...state, dnsPrivateTableData: payload.privateDnsTableData, isLoadingPrivateDnsTable: false })),
	on(dnsActions.finishLoadingDnsWhitelistBlacklistData, (state, { payload }) => {
		const { tableData, isWhitelist } = payload;
		const dataKey = isWhitelist ? 'dnsWhitelistData' : 'dnsBlacklistData';
		const loadingKey = isWhitelist ? 'isLoadingWhitelistData' : 'isLoadingBlacklistData';

		return { ...state, [dataKey]: tableData, [loadingKey]: false };
	}),
	on(dnsActions.finishLoadingDnsWhitelistBlacklistIpAddresses, (state, { payload }) => {
		const { tableData, isWhitelist } = payload;
		const dataKey = isWhitelist ? 'dnsWhitelistApplied' : 'dnsBlacklistExemption';
		const loadingKey = isWhitelist ? 'isLoadingDnsWhitelistApplied' : 'isLoadingDnsBlacklistExemption';

		return { ...state, [dataKey]: tableData, [loadingKey]: false };
	})
);

export function DnsReducer(state: IDnsState | undefined, action: Action) {
	return dnsReducer(state, action);
}
