import { IOpcPanelGroupState } from './opc-panel-group.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OpcPanelGroupStoreKey } from '../../ts/enums/opc-panel-group-store-key.enum';
import * as opcPanelGroupReducer from './opc-panel-group.reducer';

export const selectOpcPanelGroupState = createFeatureSelector<IOpcPanelGroupState>('opcPanelGroup');

export const selectAllOpcGroups = () => createSelector(
	selectOpcPanelGroupState,
	opcPanelGroupReducer.selectAllOpcGroups,
);

export const selectFromOpcGroupStore = (property: OpcPanelGroupStoreKey) => createSelector(
	selectOpcPanelGroupState,
	opcPanelGroupState => opcPanelGroupState[property]
);
