import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastrType } from '../../../../ts/enums/toastr-type.enum';
import { UserPreferencesService } from '../user-preferences.service';
import { EmptyObject } from '../../../../ts/types/empty-object.type';
import { MainUtilService } from '../../../../services/main-util.service';
import { IUserPreferenceItem } from '../../ts/models/user-preferences-item.model';
import { IForceModalPayload } from '../../ts/models/user-preferences-force-modal-payload.model';
import { UserPreferencesSaveSettings } from '../../ts/types/user-preferences-save-settings.type';
import { IUserPreferencesActionResponse } from '../../ts/models/user-preferences-action-response.model';
import { PrivilegesPreferencesTableService } from '../privileges-preferences/privileges-preferences-table.service';

@Injectable({
	providedIn: 'root'
})
export class BasicUserPreferencesHttpLogicService {

	constructor(
		private mainUtilService: MainUtilService,
		private userPreferencesService: UserPreferencesService,
		private privilegesPreferencesTablePrefrences: PrivilegesPreferencesTableService
	) { }

	formatUserSettings(userSettings: IUserPreferenceItem[]): IUserPreferenceItem[] {
		return userSettings.map(userSettingItem => {
			const {
				USER, 'HTTP-GUI-GROUP': groupLabel, 'HTTP-GUI-PHONE': guiPhone, 'HTTP-GUI-TOOLS': guiTools,
				'HTTP-GUI-NETWORK': guiNetwork, 'HTTP-GUI-AUTO': guiAuto, 'HTTP-GUI-VIDEO': guiVideo
			} = userSettingItem;

			const { VALUE: User } = USER;
			const { VALUE: Group } = groupLabel;

			const hiddenGuiAuto = this.isPropertyVisible(guiAuto.hasOwnProperty('hidden'), guiAuto.hidden);
			const hiddenGuiTool = this.isPropertyVisible(guiTools.hasOwnProperty('hidden'), guiTools.hidden);
			const hiddenGuiVideo = this.isPropertyVisible(guiVideo.hasOwnProperty('hidden'), guiVideo.hidden);
			const hiddenGuiPhone = this.isPropertyVisible(guiPhone.hasOwnProperty('hidden'), guiPhone.hidden);
			const hiddenGuiNetwork = this.isPropertyVisible(guiNetwork.hasOwnProperty('hidden'), guiNetwork.hidden);

			return {
				...userSettingItem, User, Group, hiddenGuiAuto, hiddenGuiTool,
				hiddenGuiVideo, hiddenGuiPhone, hiddenGuiNetwork, rowDisabled: false,
				networkImage: 'network-button.svg', phoneImage: 'phone-button.svg',
				automationImage: 'auto-button.svg', videoImage: 'video-button.svg', toolsImage: 'tool-button.svg'
			};
		});
	}

	isPropertyVisible(haveHiddenProperty: boolean, hiddenValue: boolean | undefined): boolean {
		return !haveHiddenProperty ? false : !!hiddenValue;
	}

	handleActionResponse(response: IUserPreferencesActionResponse, payload?: UserPreferencesSaveSettings, showModal?: boolean): void {
		const { rsp, Conflicts } = response.Response;

		this.privilegesPreferencesTablePrefrences.setUnselectedRowState(true);

		if (!!Conflicts && !showModal) {
			const initialState = { conflicts: Conflicts, payload } as IForceModalPayload;

			this.userPreferencesService.setToggleForceModal(initialState);
			return;
		}

		if (!!rsp && rsp.toLowerCase().includes('command executed')) {
			this.mainUtilService.showToastrMessage('Command executed.', ToastrType.SUCCESS);

			return;
		}

		this.mainUtilService.showToastrMessage(rsp || '', ToastrType.ERROR);
	}

	getUsernameParameter(newUserRealname: string): { 'REAL-NAME': string } | EmptyObject {
		return newUserRealname.trim() ? { 'REAL-NAME': newUserRealname } : {};
	}

	handleActionError$(): Observable<null> {
		this.mainUtilService.showToastrMessage('Error executing action', ToastrType.ERROR);

		return of(null);
	}
}
