import { Component, Input } from '@angular/core';
import { FieldType, FieldWrapper } from '@ngx-formly/core';

@Component({
	selector: 'app-formly-field-bubble',
	template: `
    <div style="display: flex; align-items: center;">

  <!-- <app-info-bubble *ngIf="field.templateOptions?.infoBox" [customTemplate]="field.templateOptions?.infoBoxContent"></app-info-bubble> -->
</div>

  `
})
export class FormlyFieldInfoBubbleComponent extends FieldWrapper { }
