import { Observable, first, tap } from 'rxjs';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take } from 'rxjs/operators';
import { PrivilegePreferencesFormService } from '../services/privileges-preferences/privilege-preferences-form.service';
import { PrivilegesPreferencesHttpService } from '../services/privileges-preferences/privileges-preferences-http.service';
import { BasicUserPreferencesTableHttpService } from '../services/basic-user-preferences/basic-user-preferences-table-http.service';

export class PreferencesAsyncValidators {

	static createUsernameValidator(
		staticUsernames: string[], usernameToSkip: string,
		privilegePreferencesFormService: PrivilegePreferencesFormService,
		basicUserPreferencesTableHttpService: BasicUserPreferencesTableHttpService
	) {
		return (control: AbstractControl): Observable<ValidationErrors | null> => control.valueChanges
			.pipe(
				startWith(control.value),
				debounceTime(250),
				take(1),
				distinctUntilChanged(),
				switchMap(() => basicUserPreferencesTableHttpService.fetchUniqueUsernameRealnameValidator$()),
				map(usernames => {
					const currentUsername = control.value.toLowerCase();
					const allUsernames = [...usernames, ...staticUsernames];

					const matchingUsername = allUsernames.some(username => {
						const usernameMatches = username.toLowerCase() === currentUsername;
						const notValidUsername = username.toLowerCase() !== usernameToSkip.toLowerCase();

						return usernameMatches && notValidUsername;
					});

					return matchingUsername ? { notUniqueUsername: true } : null;
				}),
				first(),
				tap(() => privilegePreferencesFormService.emitPrivilegePreferencesAsyncValidatorEvent())
			);
	}

	static createVoCdiPermitValidator(
		privilegesPreferencesHttpService: PrivilegesPreferencesHttpService,
		privilegePreferencesFormService: PrivilegePreferencesFormService
	) {
		return (control: AbstractControl): Observable<ValidationErrors | null> => control.valueChanges
			.pipe(
				startWith(control.value),
				debounceTime(250),
				take(1),
				distinctUntilChanged(),
				switchMap(() => privilegesPreferencesHttpService.fetchVoCdiPermitListData$() as Observable<string[]>),
				map(listItems => {
					const controlValue = control.value.toString().toLowerCase();
					const matchingListName = [...listItems, '*'].some(item => item.toLowerCase() === controlValue);

					return !matchingListName ? { notMatchingList: true } : null;
				}),
				first(),
				tap(() => privilegePreferencesFormService.emitPrivilegeVoAsyncValidatorEvent())
			);
	}
}
