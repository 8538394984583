import { Injectable } from '@angular/core';
import { AutomationCell } from '../../../ts/enums/automation-cell.enum';
import { AutomationDeviceShortPipe } from '../../../../../pipes/automation-device-short.pipe';
import { AutomationProgramDevicesService } from '../../devices/automation-program-devices.service';
import { AutomationDeviceType } from '../../../../automation/ts/enums/automation-device-type.enum';
import { IAutomationProgramEditArgument } from '../../../ts/models/automation-program-edit-argument.model';
import { IAutomationProgramEditCellMatch } from '../../../ts/models/automation-program-edit-cell-match.model';
import { IAutomationProgramEditLineDevice } from '../../../ts/models/automation-program-edit-line-device.model';
import { IAutomationProgramEditResultResponse } from '../../../ts/models/automation-program-edit-result-response.modal';
import { AutomationProgramEditLineDeviceValue } from '../../../ts/types/automation-program-edit-line-device-value.type';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSpreadsheetCellService {

	private inputAvailableCells: string[] = [];
	private outputAvailableCells: string[] = [];

	private readonly inputCell = AutomationCell.INPUT;
	private readonly outputCell = AutomationCell.OUTPUT;

	constructor(
		private automationDevicesService: AutomationProgramDevicesService
	) { }

	getAvailableCells(): string[] {
		return [...this.inputAvailableCells, ...this.outputAvailableCells].slice();
	}

	isCellColumnMatching(availableCells: string[], expressionPart: string): boolean {
		return availableCells.includes(expressionPart.toLowerCase());
	}

	getMatchingDeviceByIndex(
		lineDevices: IAutomationProgramEditLineDevice[], cellDigits: RegExpExecArray, cellIndex: number
	): IAutomationProgramEditLineDevice | undefined {
		return lineDevices.find(inputDevice => cellDigits && inputDevice.index === cellIndex);
	}

	setAvailableCells(devices: IAutomationProgramEditLineDevice[], cellType: AutomationDeviceType): void {
		if (cellType === AutomationDeviceType.INPUT) {
			this.inputAvailableCells = devices.map(inputDevice => `${this.inputCell.toUpperCase()}${inputDevice.index}`);
		} else {
			this.outputAvailableCells = devices.map(outputDevice => `${this.outputCell.toUpperCase()}${outputDevice.index}`);
		}
	}

	checkIfCellMatches(cell: string, expression: string): IAutomationProgramEditCellMatch {
		const indexOfMatchingCell = expression.toLowerCase().indexOf(cell.toLowerCase());

		let characterCounter = indexOfMatchingCell + 1;
		let matchingCell = expression[indexOfMatchingCell];

		while (!Number.isNaN(Number.parseInt(expression[characterCounter], 10))) {
			matchingCell += expression[characterCounter];
			characterCounter += 1;
		}

		if (matchingCell && cell.toLowerCase() === matchingCell.toLowerCase()) {
			return { match: true, cell: matchingCell };
		}

		return { match: false, cell: '' };
	}

	getCellLineDevice(matchingDevice: IAutomationProgramEditLineDevice): string {
		if (matchingDevice) {
			const { fullDeviceName } = matchingDevice;
			const translatedDeviceName = new AutomationDeviceShortPipe().transform(fullDeviceName, matchingDevice);

			return translatedDeviceName.toLowerCase();
		}

		return '';
	}

	translateCellValues(
		expression: string[], selectedLineDevices: IAutomationProgramEditLineDevice[], fieldIndex: number | null
	): IAutomationProgramEditResultResponse {
		const availableCells = this.getAvailableCells().map(cell => cell.toLowerCase());
		const cellValues = this.getCellValues(expression, availableCells, selectedLineDevices, fieldIndex);
		const invalidCellResult = cellValues.find(cellResult => cellResult?.haveError);

		if (invalidCellResult) {
			return { haveError: true, errorMessage: invalidCellResult.errorMessage };
		}

		const value = cellValues.reduce((accumulator, cellResult) =>
			cellResult?.value !== '' ? [...accumulator, cellResult.value as string] : accumulator, [] as string[]);

		return { haveError: false, value };
	}

	getCellValues(
		expression: string[], availableCells: string[],
		selectedLineDevices: IAutomationProgramEditLineDevice[], fieldIndex: number | null
	): IAutomationProgramEditResultResponse[] {
		return expression.map((expressionPart, expressionPartIndex) => {
			if (/^(([a-zA-Z]{1,})\d+)$/.test(expressionPart)) {
				const cellName = expressionPart.replace( /\d+/g, '').toLowerCase();

				if (cellName !== AutomationCell.INPUT && cellName !== AutomationCell.OUTPUT) {
					const errorMessage = `{{AUTO.RULES.EDIT.ERRORS.referenceError}} "${expressionPart}"`;

					return { haveError: true, errorMessage };
				}

				const cellColumn = expressionPart.charAt(0).toLowerCase();
				const isInputCell = cellColumn === AutomationCell.INPUT;
				const nonSpaceExpression = expression.filter(item => item !== '');

				const validateCellArguments = {
					availableCells, selectedLineDevices, expression: nonSpaceExpression,
					expressionPart, expressionPartIndex, isInputCell, fieldIndex
				};

				return this.validateCell(validateCellArguments);
			}

			return { haveError: false, value: expressionPart.toLowerCase() };
		});
	}

	validateCell(validateCellArguments: IAutomationProgramEditArgument): IAutomationProgramEditResultResponse {
		const {
			availableCells, selectedLineDevices, expression,
			expressionPart, expressionPartIndex, isInputCell, fieldIndex
		} = validateCellArguments;

		const matchingCellData = this.getMatchingCellData(expressionPart, selectedLineDevices, isInputCell);
		const recursiveValidation = this.checkForRecursiveOfflineCellReference(expressionPart, matchingCellData, fieldIndex);

		// Check for recursive function error
		if (recursiveValidation?.haveError) {
			return { ...recursiveValidation };
		}

		const cellFieldMatches = this.isCellColumnMatching(availableCells, expressionPart);

		// X stands for input field value, Y stands for output value
		if (cellFieldMatches && matchingCellData) {
			const isNextIndexBracket = expression[expressionPartIndex + 1] === '(';

			if (isNextIndexBracket) {
				return { haveError: false, value: expressionPart.toLowerCase() };
			}

			const cellValue = this.getCellValue(matchingCellData);

			return { haveError: false, value: cellValue };
		}

		const errorMessage = `{{AUTO.RULES.EDIT.ERRORS.referenceError}} "${expressionPart}"`;

		return { haveError: true, errorMessage };
	}

	getCellValue(matchingCellData: IAutomationProgramEditLineDevice): AutomationProgramEditLineDeviceValue {
		const { value, expressionValue, port, isOffline } = matchingCellData;
		const isDigitalDevice = this.automationDevicesService.isDigitalLineDevice(port);

		if (isOffline) {
			return this.getOfflineDeviceValue(isDigitalDevice);
		}

		if (expressionValue !== '') {
			return expressionValue || '0.0';
		}

		return this.translateCellValue(value, isDigitalDevice);
	}

	getOfflineDeviceValue(isDigitalDevice: boolean): number | boolean {
		return isDigitalDevice ? false : 0.0;
	}

	translateCellValue(value: AutomationProgramEditLineDeviceValue, isDigitalDevice: boolean): AutomationProgramEditLineDeviceValue {
		return isDigitalDevice ? value === 1 : value;
	}

	getMatchingCellData(
		expressionPart: string, selectedLineDevices: IAutomationProgramEditLineDevice[], isInputCell: boolean
	): IAutomationProgramEditLineDevice {
		const cellDigits = (/\d+/).exec(expressionPart) as RegExpExecArray;
		const cellIndex = Number.parseInt(cellDigits[0], 10);

		if (isInputCell) {
			const inputDevices = AutomationProgramDevicesService.filterDevicesByType(selectedLineDevices, AutomationDeviceType.INPUT);

			return this.getMatchingDeviceByIndex(inputDevices, cellDigits, cellIndex) as IAutomationProgramEditLineDevice;
		}

		const outputDevices = AutomationProgramDevicesService.filterDevicesByType(selectedLineDevices, AutomationDeviceType.OUTPUT);

		return this.getMatchingDeviceByIndex(outputDevices, cellDigits, cellIndex) as IAutomationProgramEditLineDevice;
	}

	checkForRecursiveOfflineCellReference(
		cell: string, matchingCellData: IAutomationProgramEditLineDevice, fieldIndex: number | null
	): IAutomationProgramEditResultResponse {
		const matchingCellIndex = cell.match(/\d+/g);

		if (matchingCellIndex && matchingCellData && matchingCellData.type === AutomationDeviceType.OUTPUT) {
			const { expression } = matchingCellData;
			const cellIndex = Number.parseInt((matchingCellIndex)[0], 10);
			const expressionInCellMatches = expression && expression.includes(cell);
			const cellFieldIndexMatches = fieldIndex !== null && cellIndex === fieldIndex;

			if (cellFieldIndexMatches || expressionInCellMatches) {
				const errorMessage = this.getReferenceCellTranslation(cell);

				return { haveError: true, errorMessage };
			}
		}

		return { haveError: false, value: null };
	}

	getReferenceCellTranslation(cell: string): string {
		return `{{AUTO.RULES.EDIT.ERRORS.referenceCellError}} "${cell}" {{AUTO.RULES.EDIT.ERRORS.referencingItself}}`;
	}

	updateFunctionExpressionResult(
		functionExpressionResult: IAutomationProgramEditResultResponse, fieldIndex: number
	): IAutomationProgramEditResultResponse {
		if (functionExpressionResult.haveError) {
			const { errorMessage } = functionExpressionResult;

			if (errorMessage && errorMessage.includes('referenceCellError')) {
				return { ...functionExpressionResult, errorMessage: this.getReferenceCellTranslation(`${this.outputCell}${fieldIndex}`) };
			}
		}

		return { ...functionExpressionResult };
	}
}
