import { lastValueFrom } from 'rxjs';
import { RouterModule } from '@angular/router';
import { coreRoutings } from '../utils/core.routing';
import { TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { AvailableLanguage } from '../../core/ts/enums/available-language.enum';
import { VersionModalComponent } from '../../version-modal/version-modal.component';
import { CheckVersionService } from '../../../services/others/check-version.service';
import { SharedPipeDirectiveModule } from '../../../shared/shared-pipe-directive.module';
import { NestedTranslationPipe } from '../../../standalone/pipes/nested-translation.pipe';
import { GlobalInterceptor } from '../../../interceptors/global-error.interceptor.service';
import { RequestInterceptorService } from '../../../interceptors/request-interceptor.service';
import { TabsComponent } from './../../../standalone/components/framework/tabs/tabs.component';
import { ManualPageComponent } from '../../../standalone/components/ui/manual-page/manual-page.component';
import { TabComponent } from '../../../standalone/components/framework/tabs/components/tab/tab.component';
import { ToolsPreviewComponent } from '../components/core-sidebar-items/tools-preview/tools-preview.component';
import { CustomInputComponent } from '../../../standalone/components/controls/custom-input/custom-input.component';
import { NetworkPreviewComponent } from '../components/core-sidebar-items/network-preview/network-preview.component';
import { TabBodyComponent } from '../../../standalone/components/framework/tabs/components/tab-body/tab-body.component';
import { PhoneTabPreviewComponent } from '../components/core-sidebar-items/phone-tab-preview/phone-tab-preview.component';
import { TabLabelComponent } from '../../../standalone/components/framework/tabs/components/tab-label/tab-label.component';
import { AutomationPreviewComponent } from '../components/core-sidebar-items/automation-preview/automation-preview.component';
import {
	AdminTabPreviewComponent
} from '../components/core-sidebar-items/admin-tab-preview/admin-tab-preview.component';

@NgModule({
	declarations: [
		ToolsPreviewComponent,
		VersionModalComponent,
		NetworkPreviewComponent,
		AdminTabPreviewComponent,
		PhoneTabPreviewComponent,
		AdminTabPreviewComponent,
		AutomationPreviewComponent
	],
	imports: [
		SharedModule,
		TabComponent,
		TabsComponent,
		TabBodyComponent,
		TabLabelComponent,
		ManualPageComponent,
		CustomInputComponent,
		NestedTranslationPipe,
		SharedPipeDirectiveModule,
		RouterModule.forChild(coreRoutings)
	],
	providers: [
		TranslateService,
		CheckVersionService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: GlobalInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptorService,
			multi: true
		},
		{
			// check if new file changes are presented, before app is bootstrapped
			provide: APP_INITIALIZER,
			useFactory: (checkVersionService: CheckVersionService, translateService: TranslateService) => () => {
				checkVersionService.initVersionCheck();

				return lastValueFrom(translateService.use(AvailableLanguage.EN));
			},
			deps: [CheckVersionService, TranslateService],
			multi: true
		},
	]
})

export class CoreModule {}
