import { Observable, switchMap, tap, catchError, of, forkJoin } from 'rxjs';
import { PhoneHttpUtilService } from '../services/http/phone-http-util.service';
import { PhoneNgrxSelectorService } from './../services/ngrx/phone-ngrx-selector.service';

export function setPhoneActivity$(phoneHttpUtilService: PhoneHttpUtilService, phoneNgrxSelectorService: PhoneNgrxSelectorService) {
	return (source: Observable<any>) => source.pipe(
		switchMap(sessionData => forkJoin({
			existingCallRecords: phoneNgrxSelectorService.selectCallRecordsSnapshot$(),
			callRecordsInfo: phoneNgrxSelectorService.selectCallRecordsInfoSnapshot$()
		})
			.pipe(
				tap(response => {
					const { existingCallRecords, callRecordsInfo } = response;

					phoneHttpUtilService.checkToSetPhoneActivity(sessionData, existingCallRecords, callRecordsInfo);
				})
			))
	);
}

export function handlePhoneActionResponse$(actionHandler: (response: any) => void, actionErrorHandler: () => void) {
	return (source: Observable<any>) => source.pipe(
		tap(response => actionHandler(response)),
		catchError(() => {
			actionErrorHandler();

			return of(null);
		})
	);
}
