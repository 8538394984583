import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OpcPanelUtilService } from '../opc-panel-util.service';
import { ID_SPLIT_CHARACTER } from '../../utils/opc-panel-util';
import { IOpcPanelGroup } from '../../ts/models/opc-panel-group.model';
import { OpcPanelUserRole } from '../../ts/enums/opc-panel-user-role.enum';
import { OpcPanelInterface } from '../../ts/enums/opc-panel-interface.enum';
import { IOpcPanelUserCard } from '../../ts/models/opc-panel-user-card.model';
import { OpcPanelSipIfSubtype } from '../../ts/enums/opc-panel-sip-if-subtype.enum';
import { OpcPanelUserRecordState } from '../../ts/enums/opc-panel-user-record-state.enum';
import { IOpcPanelCallRecord } from '../../ts/models/session/opc-panel-call-record.model';
import { IOpcPanelUserRecord } from '../../ts/models/session/opc-panel-user-record.model';
import { IOpcPanelLoggedUserInfo } from '../../ts/models/opc-panel-logged-user-info.model';
import { PreferencesCtipAllType } from '../../../preferences/ts/enums/preferences-ctip-all-type.enum';

@Injectable({
	providedIn: 'root'
})
export class OpcPanelCardService {

	private loggedUserInfo: IOpcPanelLoggedUserInfo;
	private loggedUserRecords: IOpcPanelUserRecord[] = [];

	private loggedUserId: BehaviorSubject<string> = new BehaviorSubject('');
	private activeCallModalId: BehaviorSubject<string> = new BehaviorSubject('');

	constructor() { }

	setActiveCallModalId(activeCallModalId: string): void {
		this.activeCallModalId.next(activeCallModalId);
	}

	watchGetActiveCallModalId$(): Observable<string> {
		return this.activeCallModalId.asObservable();
	}

	formatActiveCardGroup(groupData: IOpcPanelGroup[], groupId: number, userId: string): IOpcPanelGroup {
		const matchingGroup = groupData.find(groupItem => groupItem.id === groupId) as IOpcPanelGroup;
		const updatedUsers = this.setUsersActiveCard(matchingGroup.users, userId);

		return { ...matchingGroup, users: updatedUsers };
	}

	formatMinimizeGroup(groupData: IOpcPanelGroup[], groupId: number, minimized: boolean): IOpcPanelGroup {
		const matchingGroup = groupData.find(groupItem => groupItem.id === groupId) as IOpcPanelGroup;

		return { ...matchingGroup, minimized };
	}

	getVoInterfaceData(userInterfacesData: IOpcPanelUserRecord[]): IOpcPanelUserRecord | undefined {
		return userInterfacesData.find(userInterfaceItem => userInterfaceItem.interface === OpcPanelInterface.VO);
	}

	getCardUserRecord(userCardData: IOpcPanelUserCard): IOpcPanelUserRecord {
		const matchingMainCard = userCardData.userInterfacesData.find(interfaceCard => interfaceCard.main);

		return matchingMainCard ? matchingMainCard : userCardData.userInterfacesData[0];
	}

	setUsersActiveCard(users: IOpcPanelUserCard[], userId: string): IOpcPanelUserCard[] {
		const [abilis, user, selectedInterface] = userId.split(ID_SPLIT_CHARACTER);

		return users.map(userItem => {
			if (userItem.id !== OpcPanelUtilService.createUserId(abilis, user)) {
				return userItem;
			}

			const { userInterfacesData } = userItem;

			const updatedUserInterfaceData = userInterfacesData.map(userInterfaceItem => {
				const { interface: userInterface } = userInterfaceItem;
				const main = userInterface === selectedInterface;

				return { ...userInterfaceItem, main };
			});

			return { ...userItem, userInterfacesData: updatedUserInterfaceData };
		});
	}

	setLoggedUserInfo(abilisName: string, username: string, role: OpcPanelUserRole): void {
		this.loggedUserInfo = { user: username, abilisName, role };

		this.loggedUserId.next(`${abilisName}${ID_SPLIT_CHARACTER}${username}`);
	}

	setLoggedUserRecords(userRecords: IOpcPanelUserRecord[]): void {
		const { abilisName, user: loggedUsername } = this.loggedUserInfo;
		const matchingRecords = userRecords.filter(userRecord => userRecord.abilis === abilisName && userRecord.user === loggedUsername);

		if (matchingRecords.length) {
			this.loggedUserRecords = matchingRecords.slice();
		}
	}

	getLoggedUserId$(): Observable<string> {
		return this.loggedUserId.asObservable();
	}

	getLoggedUserInfo(): IOpcPanelLoggedUserInfo {
		return this.loggedUserInfo;
	}

	getloggedUserAvailableInterfaces(): OpcPanelInterface[] {
		return this.loggedUserRecords.map(userRecord => userRecord.interface);
	}

	getCtipPotsRecord(userRecords: IOpcPanelUserRecord[]): IOpcPanelUserRecord | undefined {
		return userRecords.find(userRecord => {
			const { interface: userInterface, IfSubtype } = userRecord;

			return userInterface === OpcPanelInterface.CTIP && IfSubtype === PreferencesCtipAllType.POTS;
		});
	}

	getSipPhoneRecord(userRecords: IOpcPanelUserRecord[]): IOpcPanelUserRecord | undefined {
		return userRecords.find(userRecord => {
			const { interface: userInterface, IfSubtypeSip } = userRecord;

			return userInterface === OpcPanelInterface.SIP && IfSubtypeSip === OpcPanelSipIfSubtype.PHONE;
		});
	}

	getLoggedUserActiveInterface(): IOpcPanelUserRecord | null {
		const mainInterface = this.getMainInterface();
		const loggedUserRecords = this.getLoggedUserRecords();
		const ctipPotsRecord = this.getCtipPotsRecord(loggedUserRecords);
		const sipPhoneRecord = this.getSipPhoneRecord(loggedUserRecords);

		if (ctipPotsRecord && sipPhoneRecord) {
			if (!mainInterface) {
				return ctipPotsRecord;
			}

			const { interface: userInterface } = mainInterface;

			if (userInterface === ctipPotsRecord.interface) {
				return ctipPotsRecord;
			}

			if (userInterface === sipPhoneRecord.interface) {
				return sipPhoneRecord;
			}
		}

		if (ctipPotsRecord && mainInterface?.interface === ctipPotsRecord.interface) {
			return ctipPotsRecord;
		}

		if (sipPhoneRecord && mainInterface?.interface === sipPhoneRecord.interface) {
			return sipPhoneRecord;
		}

		return null;
	}

	getMainInterface(): IOpcPanelUserRecord | undefined {
		return this.getLoggedUserRecords().find(userRecord => userRecord.main);
	}

	getLoggedUserRecords(): IOpcPanelUserRecord[] {
		return this.loggedUserRecords.slice();
	}

	isUserOperator(loggedUserRole: OpcPanelUserRole): boolean {
		return loggedUserRole === OpcPanelUserRole.OPERATOR;
	}

	isCardCallable(userRecord: IOpcPanelUserRecord, compareUserRecord = true): boolean {
		const activeUserRecord = this.getLoggedUserActiveInterface();

		if (activeUserRecord) {
			const { Num, State, abilis, user } = userRecord;
			const userRecordId = OpcPanelUtilService.createUserId(abilis, user);

			const { abilis: activeUserAbilis, user: activeUsername } = activeUserRecord;
			const activeRecordId = OpcPanelUtilService.createUserId(activeUserAbilis, activeUsername);

			return Num !== '*' && Num !== '' && State !== OpcPanelUserRecordState.UNAVAIL
				&& State !== OpcPanelUserRecordState.FAIL && (!compareUserRecord || userRecordId !== activeRecordId);
		}

		return false;
	}

	getLoggedUserCallRecords(users: IOpcPanelUserCard[]): IOpcPanelCallRecord[] {
		const { abilisName, user: loggedUsername } = this.loggedUserInfo;
		const loggedUserData = users.find(user => user.id === OpcPanelUtilService.createUserId(abilisName, loggedUsername));

		return loggedUserData ? loggedUserData.callRecords : [];
	}
}
