import { Injectable } from '@angular/core';
import { PhoneUtilService } from '../phone-util.service';
import { PhoneCallsService } from '../calls/phone-calls.service';
import { PhoneFooterUtilService } from './phone-footer-util.service';
import { IPhoneCallRecord } from '../../ts/models/phone-call-record.model';
import { OpcPanelCallRecordState } from '../../../opc-panel/ts/enums/opc-panel-call-record-state.enum';
import { IOpcPanelUserRecord } from '../../../opc-panel/ts/models/session/opc-panel-user-record.model';
import { OpcPanelUserRecordCallState } from '../../../opc-panel/ts/enums/opc-panel-user-record-call-state.enum';
import { OpcPanelCallRecordVoicerecorder } from '../../../opc-panel/ts/enums/opc-panel-call-record-voicerecorder.enum';

@Injectable({
	providedIn: 'root'
})
export class PhoneFooterService {

	constructor(
		private readonly phoneFooterUtilService: PhoneFooterUtilService
	) { }

	public isPriorityActive(response: [IPhoneCallRecord[], IOpcPanelUserRecord | null]): boolean {
		const [callRecords, userRecord] = response;
		const numberOfCalls = callRecords.length;

		if (numberOfCalls === 0) { return true; }

		const [firstCall, secondCall] = callRecords;
		const { State: firstCallState } = firstCall;

		if (numberOfCalls === 1) {
			return PhoneFooterUtilService.canSetPriorityOnSingleCall(firstCallState, userRecord);
		}

		const { State: secondCallState } = secondCall;

		return this.phoneFooterUtilService.canSetPriorityOnMultipleCalls(firstCallState, secondCallState);
	}

	public isCallTryActive(userRecord: IOpcPanelUserRecord | null): boolean | null {
		if (!userRecord) { return null; }

		return userRecord.CallTry.State === OpcPanelUserRecordCallState.ACTIVE;
	}

	public isSwitchCallActive(callRecords: IPhoneCallRecord[]): boolean | null {
		const [firstCall, secondCall] = callRecords;

		if (!PhoneFooterUtilService.areBothCallsAvailable(firstCall, secondCall)) { return null; }

		const { State: firstCallState } = firstCall;
		const { State: secondCallState } = secondCall;

		const isSecondCallHold = PhoneFooterUtilService.isCallStateMatching(secondCallState, OpcPanelCallRecordState.HOLD);
		const isFirstCallActive = PhoneFooterUtilService.isCallStateMatching(firstCallState, OpcPanelCallRecordState.ACTIVE);

		const isFirstCallHold = PhoneFooterUtilService.isCallStateMatching(firstCallState, OpcPanelCallRecordState.HOLD);
		const isSecondCallActive = PhoneFooterUtilService.isCallStateMatching(secondCallState, OpcPanelCallRecordState.ACTIVE);

		const isSecondCallOutgoing = !PhoneUtilService.isInDirection(secondCall.Direction);
		const isSecondCallAlerting = PhoneFooterUtilService.isCallStateMatching(secondCallState, OpcPanelCallRecordState.ALERTING);

		const isFirstActiveSecondHold = isFirstCallActive && isSecondCallHold;
		const isFirstHoldSecondActive = isFirstCallHold && isSecondCallActive;
		const isFirstActiveHoldSecondAlertingOut = (isFirstCallActive || isFirstCallHold) && (isSecondCallAlerting || isSecondCallOutgoing);
		const canSwitchCalls = isFirstActiveSecondHold || isFirstHoldSecondActive || isFirstActiveHoldSecondAlertingOut;

		return PhoneFooterUtilService.isCommandActive(canSwitchCalls);
	}

	public isTransferCallActive(callRecords: IPhoneCallRecord[]): boolean | null {
		const [firstCall, secondCall] = callRecords;

		if (!PhoneFooterUtilService.areBothCallsAvailable(firstCall, secondCall)) { return null; }

		const { State: firstCallState, Direction: firstCallDirection } = firstCall;
		const { State: secondCallState, Direction: secondCallDirection } = secondCall;

		const isFirstHold = PhoneFooterUtilService.isCallStateMatching(firstCallState, OpcPanelCallRecordState.HOLD);
		const isSecondHold = PhoneFooterUtilService.isCallStateMatching(secondCallState, OpcPanelCallRecordState.HOLD);
		const isFirstActive = PhoneFooterUtilService.isCallStateMatching(firstCallState, OpcPanelCallRecordState.ACTIVE);
		const isSecondActive = PhoneFooterUtilService.isCallStateMatching(firstCallState, OpcPanelCallRecordState.ACTIVE);

		const areBothHold = isFirstHold && isSecondHold;
		const isFirstActiveSecondHold = isFirstActive && isSecondHold;
		const isFirstHoldSecondActive = isFirstHold && isSecondActive;
		const isSecondOut = !PhoneUtilService.isInDirection(secondCallDirection);

		if ((isFirstActiveSecondHold || isFirstHoldSecondActive || areBothHold) && isSecondOut) { return null; }

		if (isFirstHold) {
			const canTransferSecond = this.phoneFooterUtilService.canTransferNormalCall(secondCallState, secondCallDirection);

			return PhoneFooterUtilService.isCommandActive(canTransferSecond);
		}

		if (isSecondHold) {
			const canTransferFirst = this.phoneFooterUtilService.canTransferNormalCall(firstCallState, firstCallDirection);

			return PhoneFooterUtilService.isCommandActive(canTransferFirst);
		}

		return null;
	}

	public isConferenceActive(callRecords: IPhoneCallRecord[]): boolean | null {
		const [firstCall, secondCall] = callRecords;

		if (!PhoneFooterUtilService.areBothCallsAvailable(firstCall, secondCall)) { return null; }

		const { State: firstCallState } = firstCall;
		const { State: secondCallState } = secondCall;

		const isFirstHold = PhoneFooterUtilService.isCallStateMatching(firstCallState, OpcPanelCallRecordState.HOLD);
		const isSecondHold = PhoneFooterUtilService.isCallStateMatching(secondCallState, OpcPanelCallRecordState.HOLD);
		const isFirstActive = PhoneFooterUtilService.isCallStateMatching(firstCallState, OpcPanelCallRecordState.ACTIVE);
		const isSecondActive = PhoneFooterUtilService.isCallStateMatching(secondCallState, OpcPanelCallRecordState.ACTIVE);

		const areBothHold = isFirstHold && isSecondHold;
		const isFirstHoldSecondActive = isFirstHold && isSecondActive;
		const isFirstActiveSecondHold = isFirstActive && isSecondHold;

		return PhoneFooterUtilService.isCommandActive(isFirstHoldSecondActive || isFirstActiveSecondHold || areBothHold);
	}

	public isHoldCallActive(callRecords: IPhoneCallRecord[], userRecord: IOpcPanelUserRecord | null): boolean {
		return PhoneCallsService.hasOnlyHoldAndActiveCalls(callRecords) && PhoneFooterUtilService.isUserRecordNotRinging(userRecord);
	}

	public isUnholdCallActive(callRecords: IPhoneCallRecord[], userRecord: IOpcPanelUserRecord | null): boolean {
		if (!callRecords.length) { return false; }

		const [firstCallRecord] = callRecords;
		const firstCallState = firstCallRecord.State;
		const isUserRecordNotRinging = PhoneFooterUtilService.isUserRecordNotRinging(userRecord);
		const isFirstHold = PhoneFooterUtilService.isCallStateMatching(firstCallState, OpcPanelCallRecordState.HOLD);

		if (callRecords.length === 1) { return isFirstHold && isUserRecordNotRinging; }

		const [_, secondCallRecord] = callRecords;
		const secondCallState = secondCallRecord.State;
		const isSecondHold = PhoneFooterUtilService.isCallStateMatching(secondCallState, OpcPanelCallRecordState.HOLD);
		const isSecondDisconnecting = PhoneFooterUtilService.isCallStateMatching(secondCallState, OpcPanelCallRecordState.DISCONNECTING);

		const isFirstDisconnecting = PhoneFooterUtilService.isCallStateMatching(firstCallState, OpcPanelCallRecordState.DISCONNECTING);

		const firstSecondHold = isFirstHold && isSecondHold;
		const firstDiscSecondHold = isFirstDisconnecting && isSecondHold;
		const firstHoldSecondDisc = isFirstHold && isSecondDisconnecting;

		return (firstSecondHold || firstDiscSecondHold || firstHoldSecondDisc) && isUserRecordNotRinging;
	}

	public isCalltryActive(callRecords: IPhoneCallRecord[], userRecord: IOpcPanelUserRecord | null, inputValue: string): boolean | null {
		const isInputEmpty = PhoneUtilService.isInputEmpty(inputValue);
		const numberName = this.phoneFooterUtilService.getCalltryNumberName(userRecord);

		if (isInputEmpty && !numberName && PhoneFooterUtilService.isCalltryActiveByCallRecords(callRecords, userRecord)) { return null; }

		return this.isCallTryActive(userRecord);
	}

	public isRecordingActive(callRecords: IPhoneCallRecord[]): boolean | null {
		const activeCallRecord = PhoneCallsService.getCallRecordByState(callRecords, OpcPanelCallRecordState.ACTIVE);

		if (!activeCallRecord) { return null; }

		const { VoiceRecorder } = activeCallRecord;

		if (VoiceRecorder === OpcPanelCallRecordVoicerecorder.DISALLOWED) { return null; }

		return callRecords.some(callRecord => callRecord.VoiceRecorder === OpcPanelCallRecordVoicerecorder.RECORDING);
	}
}
