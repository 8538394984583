<app-antek-logo></app-antek-logo>

<app-tabs>
	<app-tab [active]="router.url.includes(getRoutesEnum.BASIC_LIST)"
		[disabled]="(listPermission$ | async)?.isFeatureDisabled" [tooltip]="(listPermission$ | async)?.tooltip || ''"
		(selectTabEvent)="onToolsRouting(getRoutesEnum.BASIC_LIST)"
		[location]="'/#/tools;replaceUrl=true/(basic-list:basic-list)'">
		<app-tab-label>
			<img src="assets/feather-icons/list.svg" alt="list">
			<span>{{'TOOLS2.LISTS.tab' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="basic-list"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.AUDIO)" [tooltip]="(audioPermission$ | async)?.tooltip || ''"
		[disabled]="(audioPermission$ | async)?.isFeatureDisabled" (selectTabEvent)="onToolsRouting(getRoutesEnum.AUDIO)"
		[location]="'/#/tools;replaceUrl=true/(audio:audio)'">
		<app-tab-label>
			<img src="assets/feather-icons/voicemail.svg" alt="audio messages">
			<span>{{'TOOLS2.tabMessages' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="audio"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.ALERTS)" [tooltip]="(alertPermission$ | async)?.tooltip || ''"
		[disabled]="(alertPermission$ | async)?.isFeatureDisabled" (selectTabEvent)="onToolsRouting(getRoutesEnum.ALERTS)"
		[location]="'/#/tools;replaceUrl=true/(alerts:alerts)'">
		<app-tab-label>
			<img src="assets/feather-icons/alert-octagon.svg" alt="alert">
			<span>{{'TOOLS2.tabAlerts' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="alerts"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.SMS)" [disabled]="(smsPermission$ | async)?.isFeatureDisabled"
		[tooltip]="(smsPermission$ | async)?.tooltip || ''" (selectTabEvent)="onToolsRouting(getRoutesEnum.SMS)"
		[location]="'/#/tools;replaceUrl=true/(sms:sms)'">
		<app-tab-label>
			<img src="assets/feather-icons/mail.svg" alt="sms">
			<span>{{'TOOLS2.tabSms' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="sms"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.CP)" [disabled]="(cpPermission$ | async)?.isFeatureDisabled"
		(selectTabEvent)="onToolsRouting(getRoutesEnum.CP)" [tooltip]="(cpPermission$ | async)?.tooltip || ''"
		[location]="'/#/tools;replaceUrl=true/(cp:cp)'">
		<app-tab-label>
			<img src="assets/feather-icons/hash.svg" alt="cp">
			<span>{{'TOOLS2.tabCp' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="cp"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.DISA)" [disabled]="(disaPermission$ | async)?.isFeatureDisabled"
		(selectTabEvent)="onToolsRouting(getRoutesEnum.DISA)" [tooltip]="(disaPermission$ | async)?.tooltip || ''"
		[location]="'/#/tools;replaceUrl=true/(disa:disa)'">
		<app-tab-label>
			<img src="assets/feather-icons/phone-conditional.svg" alt="disa">
			<span>{{'TOOLS2.DISA.tab' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="disa"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.DISK)" (selectTabEvent)="onToolsRouting(getRoutesEnum.DISK)"
		[location]="'/#/tools;replaceUrl=true/(disk-analyzer:disk-analyzer)'" *ngIf="haveAdminPermission$ | async">
		<app-tab-label>
			<img src="assets/feather-icons/disc.svg" alt="disk">
			<span>{{'TOOLS2.DISK.tab' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="disk-analyzer"></router-outlet>
		</app-tab-body>
	</app-tab>
</app-tabs>
