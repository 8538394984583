import { Injectable } from '@angular/core';
import { OpcPanelCardService } from './card/opc-panel-card.service';
import { OpcPanelInterface } from '../ts/enums/opc-panel-interface.enum';
import { OpcPanelCardCallService } from './card/opc-panel-card-call.service';
import { OpcPanelCallDirection } from '../ts/enums/opc-panel-call-direction.enum';
import { IOpcPanelCallRecord } from '../ts/models/session/opc-panel-call-record.model';
import { OpcPanelCallRecordState } from '../ts/enums/opc-panel-call-record-state.enum';
import { IOpcPanelUserRecord } from '../ts/models/session/opc-panel-user-record.model';
import { OpcPanelUserRecordState } from '../ts/enums/opc-panel-user-record-state.enum';
import { OpcPanelUserRecordHandlerService } from './opc-panel-user-record-handler.service';
import { OpcPanelUserRecordCallState } from '../ts/enums/opc-panel-user-record-call-state.enum';
import { IOpcPanelUserRecordCall } from '../ts/models/session/opc-panel-user-record-call.model';

@Injectable({
	providedIn: 'root'
})
export class OpcPanelCallRecordHandlerService {

	constructor(
		private opcPanelCardService: OpcPanelCardService,
		private opcPanelCardCallService: OpcPanelCardCallService
	) { }

	formatCallRecordItemByState(
		matchingInterface: IOpcPanelUserRecord, userInterfaceData: IOpcPanelUserRecord[],
		callRecords: IOpcPanelCallRecord[], callRecord: IOpcPanelCallRecord
	): IOpcPanelUserRecord[] {
		const updatedCallRecords = this.getUpdatedCallRecordsByState(matchingInterface, userInterfaceData, callRecords, callRecord);

		return this.setKeypadIconAndMoreCallsVisiblity(updatedCallRecords, callRecords);
	}

	getUpdatedCallRecordsByState(
		matchingInterface: IOpcPanelUserRecord, userInterfaceData: IOpcPanelUserRecord[],
		callRecords: IOpcPanelCallRecord[], callRecord: IOpcPanelCallRecord
	): IOpcPanelUserRecord[] {
		const { interface: userInterface, Call } = matchingInterface;

		if (userInterface === OpcPanelInterface.SIP && Call.State === OpcPanelUserRecordCallState.ACTIVE) {
			return this.getUpdatedInterfacesByFakeSipCall(matchingInterface, Call, userInterfaceData, false) as IOpcPanelUserRecord[];
		}

		switch (callRecord.State) {
		case OpcPanelCallRecordState.NULL:
			return this.handleNullCallRecord(matchingInterface, userInterfaceData, callRecords);
		case OpcPanelCallRecordState.ALERTING:
			return this.handleAlertingCallRecord(matchingInterface, userInterfaceData, callRecords, callRecord);
		default:
			return this.handleOtherCallRecordStates(matchingInterface, userInterfaceData, callRecords, callRecord);
		}
	}

	setKeypadIconAndMoreCallsVisiblity(userRecords: IOpcPanelUserRecord[], callRecords: IOpcPanelCallRecord[]): IOpcPanelUserRecord[] {
		const showMoreCallsIcon = this.opcPanelCardCallService.hasMoreCalls(callRecords);

		return userRecords.map(userRecord => {
			const { interface: userInterface } = userRecord;
			const { role } = this.opcPanelCardService.getLoggedUserInfo();
			const canTransferActive = this.opcPanelCardCallService.canTransferActive(userRecord, callRecords);
			const canTransferAlerting = this.opcPanelCardCallService.canTransferAlerting(userRecord, role, callRecords);

			const isKeypadIconForTransfer = canTransferAlerting;
			const keypadIconText = OpcPanelUserRecordHandlerService.getKeypadIconText(canTransferAlerting);

			if (canTransferAlerting) {
				return {
					...userRecord, showKeypadIcon: true, showMoreCallsIcon,
					isKeypadIconForTransfer, keypadIconText, isDraggable: true
				};
			}

			if (canTransferActive) {
				const loggedUserActiveInterface = this.opcPanelCardService.getLoggedUserActiveInterface();
				const showKeypadIcon = userInterface === loggedUserActiveInterface?.interface;

				return {
					...userRecord, showKeypadIcon, showMoreCallsIcon,
					isKeypadIconForTransfer, keypadIconText, isDraggable: true
				};
			}

			return { ...userRecord, showKeypadIcon: false, showMoreCallsIcon, isDraggable: false };
		});
	}

	handleOtherCallRecordStates(
		matchingInterface: IOpcPanelUserRecord, userInterfaceData: IOpcPanelUserRecord[],
		callRecords: IOpcPanelCallRecord[], callRecord: IOpcPanelCallRecord
	): IOpcPanelUserRecord[] {
		const callRecordToUse = this.opcPanelCardCallService.getLastActiveCall(callRecords) || callRecord;

		if (!callRecordToUse) { return userInterfaceData; }

		const { Direction, Called, CalledName, Calling, CallingName } = callRecord;
		const initialInterface = { ...matchingInterface, recordDirection: Direction };

		if (Direction === OpcPanelCallDirection.OUT) {
			return OpcPanelUserRecordHandlerService.updateUserInterface({
				...initialInterface, contactName: CallingName as string, contactNumber: Calling
			}, userInterfaceData);
		}

		if (Direction === OpcPanelCallDirection.IN) {
			return OpcPanelUserRecordHandlerService.updateUserInterface({
				...initialInterface, contactName: CalledName as string, contactNumber: Called
			}, userInterfaceData);
		}

		return userInterfaceData;
	}

	handleAlertingCallRecord(
		matchingInterface: IOpcPanelUserRecord, userInterfaceData: IOpcPanelUserRecord[],
		callRecords: IOpcPanelCallRecord[], callRecord: IOpcPanelCallRecord
	): IOpcPanelUserRecord[] {
		const { IfSubtype } = matchingInterface;
		const isPotsInterface = this.opcPanelCardCallService.isPots(IfSubtype);
		const notNullCall = this.getNotNullCallBasedOnPots(isPotsInterface, callRecords);
		const matchingAlertCondition = notNullCall && notNullCall.CallID === callRecord.CallID;

		if (!matchingAlertCondition) { return userInterfaceData; }

		const { Direction, Called, CalledName, Calling, CallingName } = callRecord;
		const initialInterface = { ...matchingInterface, recordDirection: Direction };

		if (Direction === OpcPanelCallDirection.OUT) {
			return OpcPanelUserRecordHandlerService.updateUserInterface({
				...initialInterface, contactName: CallingName as string, contactNumber: Calling, isRinging: true
			}, userInterfaceData);
		}

		if (Direction === OpcPanelCallDirection.IN) {
			const haveOutAlertingCall = this.opcPanelCardCallService.hasCallRecordByStateAndDirection(
				callRecords, OpcPanelCallRecordState.ALERTING, OpcPanelCallDirection.OUT
			);

			const isRinging = !initialInterface.isRinging && !haveOutAlertingCall ? false : initialInterface.isRinging;

			return OpcPanelUserRecordHandlerService.updateUserInterface({
				...initialInterface, contactName: CalledName as string, contactNumber: Called, isRinging
			}, userInterfaceData);
		}

		return userInterfaceData;
	}

	getNotNullCallBasedOnPots(isPotsInterface: boolean, callRecords: IOpcPanelCallRecord[]): IOpcPanelCallRecord | undefined {
		if (isPotsInterface) {
			return this.opcPanelCardCallService.getLastNotNullCall(callRecords);
		}

		return this.opcPanelCardCallService.getFirstNotNullCall(callRecords);
	}

	handleNullCallRecord(
		matchingInterface: IOpcPanelUserRecord, userInterfaceData: IOpcPanelUserRecord[], callRecords: IOpcPanelCallRecord[]
	): IOpcPanelUserRecord[] {
		const { State, interface: userInterface, Call } = matchingInterface;
		const hasNotNullCall = this.opcPanelCardCallService.hasNotNullCall(callRecords);

		if (!hasNotNullCall) {
			const initialInterface = {
				...matchingInterface, isDraggable: false, showKeypadIcon: false, contactName: '', contactNumber: ''
			};

			if (State === OpcPanelUserRecordState.OFFHOOK) {
				return OpcPanelUserRecordHandlerService.updateUserInterface(initialInterface, userInterfaceData);
			}

			if (userInterface === OpcPanelInterface.SIP && Call.State === OpcPanelUserRecordCallState.ACTIVE) {
				const { CdoName: contactName, CdoNum: contactNumber } = Call as { CdoName: string; CdoNum: string };

				return OpcPanelUserRecordHandlerService.updateUserInterface({
					...initialInterface, contactName, contactNumber, recordDirection: OpcPanelCallDirection.IN
				}, userInterfaceData);
			}

			return OpcPanelUserRecordHandlerService.updateUserInterface({ ...initialInterface, isRinging: false }, userInterfaceData);
		}

		const firstNotNullCall = this.opcPanelCardCallService.getFirstNotNullCall(callRecords);

		if (firstNotNullCall) {
			return this.formatCallRecordItemByState(matchingInterface, userInterfaceData, callRecords, firstNotNullCall);
		}

		return userInterfaceData;
	}

	getUpdatedInterfacesByFakeSipCall(
		matchingInterface: IOpcPanelUserRecord, call: IOpcPanelUserRecordCall,
		userInterfacesData: IOpcPanelUserRecord[], getUpdatedInterfaceOnly: boolean
	): IOpcPanelUserRecord[] | IOpcPanelUserRecord {
		const { CdoNum, CdoName } = call as { CdoNum: string; CdoName: string };

		const updatedInterface = {
			...matchingInterface, isRinging: true, showKeypadIcon: false, isDraggable: false,
			recordDirection: OpcPanelCallDirection.IN, contactName: CdoName, contactNumber: CdoNum
		};

		if (getUpdatedInterfaceOnly) {
			return updatedInterface;
		}

		return OpcPanelUserRecordHandlerService.updateUserInterface(updatedInterface, userInterfacesData);
	}
}
