import { Action, createReducer, on } from '@ngrx/store';
import { IAutomationMap } from '../../ts/models/automation-map.model';
import * as automationActions from '../automation.action';

export const automationSelectedMapFeatureKey = 'automationSelectedMap';

export interface IAutomationCurrentMapState {
	currenltyUsedMap: IAutomationMap | null;
}

export const initialSelectedMapState: IAutomationCurrentMapState = {
	currenltyUsedMap: null
};

const automationCurrentMapReducer = createReducer(
	initialSelectedMapState,
	on(automationActions.removeCurrentlyUsedMap, () => ({ currenltyUsedMap: null })),
	on(automationActions.setSelectedAutomationMap, (_, { payload }) => ({ currenltyUsedMap: payload.automationMap }))
);

export function AutomationCurrentMapReducer(state: IAutomationCurrentMapState | undefined, action: Action) {
	return automationCurrentMapReducer(state, action);
}
