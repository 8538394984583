import { createAction, props } from '@ngrx/store';
import { INetworkingDns } from '../ts/models/networking-dns.model';
import { INetworkingDnsForm } from '../ts/models/networking-dns-form.model';
import { INetworkingDnsAddForm } from '../ts/models/private-dns/networking-dns-add-form.model';
import { INetworkingDnsCacheItem } from '../ts/models/dns-cache/networking-dns-cache-item.model';
import { INetworkingDnsPrivateTableDataItem } from '../ts/models/private-dns/networking-dns-private-table-data-item.model';
import { INetworkingDnsWhitelistBlacklistItem } from '../ts/models/whitelist-blacklist/networking-dns-whitelist-blacklist-item.model';

export const startLoadingDnsConfiguration = createAction(
	'[DNS] Start Loading DNS Configuration'
);

export const loadDnsCacheTableData = createAction(
	'[DNS] Load DNS Cache Table Data'
);

export const setDnsFilter = createAction(
	'[DNS] Set DNS Filter',
	props<{ payload: { ipAddress: string } }>()
);

export const loadDnsCacheTableDataOnce = createAction(
	'[DNS] Load DNS Cache Table Data Once'
);

export const finishLoadingDnsCacheData = createAction(
	'[DNS] Finish Loading DNS Cache Table Data',
	props<{ payload: { dnsCacheTableData: INetworkingDnsCacheItem[] } }>()
);

export const loadDnsWhitelistBlacklistData = createAction(
	'[DNS] Load Blacklist/Whitelist DNS Data',
	props<{ payload: { isWhitelistTable: boolean } }>()
);

export const loadDnsWhitelistBlacklistDataOnce = createAction(
	'[DNS] Load Blacklist/Whitelist DNS Data Once',
	props<{ payload: { isWhitelistTable: boolean } }>()
);

export const finishLoadingDnsWhitelistBlacklistData = createAction(
	'[DNS] Finish Loading DNS Whitelist/Blacklist Data',
	props<{ payload: { tableData: INetworkingDnsWhitelistBlacklistItem[]; isWhitelist: boolean } }>()
);

export const loadDnsWhitelistBlacklistIpAddresses = createAction(
	'[DNS] Load DNS Whitelist/Blacklist Ip Addresses',
	props<{ payload: { isWhitelistTable: boolean } }>()
);

export const loadDnsWhitelistBlacklistIpAddressesOnce = createAction(
	'[DNS] Load Whitelist/Blacklist DNS Ip Addresses Once',
	props<{ payload: { isWhitelistTable: boolean } }>()
);

export const finishLoadingDnsWhitelistBlacklistIpAddresses = createAction(
	'[DNS] Finish Loading DNS Whitelist/Blacklist Ip Addresses',
	props<{ payload: { tableData: INetworkingDnsWhitelistBlacklistItem[]; isWhitelist: boolean } }>()
);

export const finishLoadingDnsConfiguration = createAction(
	'[DNS] Finish Loading DNS Configuration',
	props<{ payload: { dnsConfiguration: INetworkingDns } }>()
);

export const setDnsSettings = createAction(
	'[DNS] Set DNS Settings',
	props<{ payload: { formData: INetworkingDnsForm } }>()
);

export const applyDnsSettings = createAction(
	'[DNS] Apply DNS Settings'
);

export const setLoadingDnsState = createAction(
	'[DNS] Set Loading DNS State',
	props<{ payload: { isLoading: boolean } }>()
);

export const clearAllDnsCache = createAction(
	'[DNS] Clear All Cache'
);

export const clearAllResolvedDomains = createAction(
	'[DNS] Clear All Resolved Domains'
);

export const loadPrivateDnsTable = createAction(
	'[DNS] Load Private DNS Table'
);

export const loadPrivateDnsTableOnce = createAction(
	'[DNS] Load Private DNS Table Once'
);

export const finishLoadingPrivateDnsTable = createAction(
	'[DNS] Finish Loading Private DNS Table',
	props<{ payload: { privateDnsTableData: INetworkingDnsPrivateTableDataItem[] } }>()
);

export const loadDnsResolvedData = createAction(
	'[DNS] Load DNS Resolved Data'
);

export const loadDnsResolvedDataOnce = createAction(
	'[DNS] Load DNS Resolved Data Once'
);

export const finishLoadingResolvedDnsData = createAction(
	'[DNS] Finish Loading Resolved DNS Data',
	props<{ payload: { resolvedData: INetworkingDnsWhitelistBlacklistItem[] } }>()
);

export const deletePrivateDnsItem = createAction(
	'[DNS] Delete Private DNS Item',
	props<{ payload: { selectedDnsItem: INetworkingDnsPrivateTableDataItem } }>()
);

export const deleteAddWhitelistBlacklistItem = createAction(
	'[DNS] Delete/Add Whitelist/Blacklist Item',
	props<{ payload: { blacklistWhitelistItem: string; addItem: boolean; isWhitelistTable: boolean } }>()
);

export const deleteAddWhitelistBlacklistIpAddress = createAction(
	'[DNS] Delete/Add Whitelist/Blacklist Ip Address',
	props<{ payload: { ip: string; addItem: boolean; isWhitelistTable: boolean } }>()
);

export const addDnsTableItem = createAction(
	'[DNS] Add Dns Table Item',
	props<{ payload: { dnsAddForm: INetworkingDnsAddForm } }>()
);

export const cancelDnsObservables = createAction(
	'[DNS] Cancel Dns Observables'
);
