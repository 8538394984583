export enum FeaturePermissionNaStatus {
	OK = 0,
	SERVICE_NA = 1,
	SERVICE_INACTIVE = 121,
	SERVICE_LICENSE_PROBLEM = 122,
	SERVICE_NOT_RUNNING = 188,
	SERVICE_DISABLED_UBR = 221,
	SERVICE_MISSING = 228,
	SERVICE_VALUE_NO = 229
}
