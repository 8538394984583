import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { DateTime, Interval } from 'luxon';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ElementRef, Injectable, Type } from '@angular/core';
import { ToastrType } from '../ts/enums/toastr-type.enum';
import { ISelectData } from '../ts/models/select-data.model';
import { AvailableLanguage } from '../components/core/ts/enums/available-language.enum';
import { errorToastrDuration, infoToastrDuration, successToastrDuration } from '../utils/global-variables';
import { GenericFormValue } from '../components/ui-components/generic-table/ts/types/generic-form-value.type';
import * as dayjsEnglish from 'dayjs/locale/en';
import * as dayjsItalian from 'dayjs/locale/it';
import * as ip from 'ip';

@Injectable({
	providedIn: 'root'
})

export class MainUtilService {
	static mergeArrays = (arr1: any[], arr2: any[], predicate = (a: any, b: any) => a === b) => {
		const result = [...arr1];
		arr2.forEach((bItem: any) => (result.some((cItem) => predicate(bItem, cItem)) ? null : result.push(bItem)));
		return result;
	};

	constructor(
		private toastrService: ToastrService
	) { }

	static setCookie(cookieName: string, cookieValue: string, daysForExpiry: number, path: string): void {
		const date: Date = new Date();
		date.setDate(date.getDate() + daysForExpiry);

		document.cookie = `${cookieName}=${cookieValue}; expires=${date.toUTCString()}; path=${path}`;
	}


	static enumToArray(value: any): any[] {
		return Object.keys(value).filter((key) => isNaN(Number(key))).map((key) => ({
			value: key,
		 	text: value[key]
		}));
	};

	// static getKeyByValue<T extends string | number>(enumType: Type<T>,  value: string) {
	// 	const indexOfS = Object.values(enumType)
	// 		.indexOf(value as unknown as T); const key = Object.keys(enumType)[indexOfS]; return key;
	// }

	static getCookieValue(cookieName: string): string {
		const name = cookieName + '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const cookies = decodedCookie.split(';');
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i];

			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1);
			}

			if (cookie.indexOf(name) === 0) {
				return cookie.substring(name.length, cookie.length);
			}
		}

		return '';
	}

	static getLocalStorageValue<T>(localStorageKey: string, fallingValue: any): T {
		try {
			return JSON.parse(localStorage.getItem(localStorageKey) || '') || fallingValue;
		} catch {
			return fallingValue;
		}
	}

	static setLocalStorageItem(localStorageKey: string, localStorageValue: string): void {
		localStorage.setItem(localStorageKey, localStorageValue);
	}

	static getTitleCaseString(value: string): string {
		return value.charAt(0).toUpperCase() + value.slice(1, value.length);
	}

	static getLuxonDatetime(datetime: string | null, format: string): string {
		if (!datetime) { return ''; }

		return DateTime.fromISO(datetime, { setZone: true }).toFormat(format);
	}

	static getLuxonObjectFromJs(date: Date): DateTime {
		return DateTime.fromJSDate(date);
	}

	static getLuxonObject(datetime: string, setZone = true): DateTime {
		return DateTime.fromISO(datetime, { setZone });
	}

	static getLuxonLocalDatetime(): DateTime {
		return DateTime.local();
	}

	static clearCanvas(canvasElement: ElementRef): void {
		const { width, height } = canvasElement.nativeElement;
		const context = canvasElement.nativeElement.getContext('2d') as CanvasRenderingContext2D;

		context.clearRect(0, 0, width, height);
	}

	static getLuxonWeekday(timezone: string): number {
		return DateTime.local().setZone(timezone).weekday;
	}

	static getCurrentLuxonDatetime(timezone: string, format: string): string {
		return DateTime.local().setZone(timezone).toFormat(format);
	}

	static getCurrentLuxonDatetimeObject(timezone: string): DateTime {
		return DateTime.local().setZone(timezone);
	}

	static getLuxonTimezone(timespan: string): string {
		return DateTime.fromISO(timespan, { setZone: true }).zoneName || '';
	}

	static formatDatePickerToValidDateString(dateFromForm: string): string {
		return dateFromForm.slice(3, 5) + '/' + dateFromForm.slice(0, 2) + dateFromForm.slice(5, 16);
	}

	static getArrayFromTotalLength(numberOfItems: number): number[] {
		return Array.from({ length: numberOfItems }, (_, k) => k);
	}

	static sortByHeader<T>(data: Array<T>, numberKeys: string[], headerKey: string, descendingOrder: boolean): Array<T> {
		let first: string | number;
		let next: string | number;

		return data.sort((a, b) => {
			const nextValue = (b as any)?.[headerKey]?.toString();
			const firstValue = (a as any)?.[headerKey]?.toString();

			if (firstValue === '' || firstValue === null || firstValue === undefined) { return 1; }
			if (nextValue === '' || nextValue === null || nextValue === undefined) { return -1; }

			if (!numberKeys.includes(headerKey)) {
				first = firstValue.toLowerCase();
				next = nextValue.toLowerCase();

				if (descendingOrder) {
					return first > next ? -1 : first < next ? 1 : 0;
				}

				return first < next ? -1 : first > next ? 1 : 0;
			}

			const firstNumberValue = firstValue.match(/\d+/);
			const lastNumberValue = nextValue.match(/\d+/);

			if (firstNumberValue && lastNumberValue) {
				first = Number.parseInt(firstNumberValue[0], 10);
				next = Number.parseInt(lastNumberValue[0], 10);

				return !descendingOrder ? first - next : next - first;
			}

			return 1;
		});
	}

	static capitalCaseString(value: string): string {
		return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
	}

	static getBlobImageUrl(blob: Blob | null): string {
		try {
			return (URL || webkitURL).createObjectURL(blob as Blob);
		} catch {
			return '';
		}
	}

	static getTextFromHTMLString(htmlString: string): string {
		return htmlString.replace(/<[^>]+>/g, '');
	}

	static sameComparedValues(firstValue: unknown, secondValue: unknown): boolean {
		return JSON.stringify(firstValue) === JSON.stringify(secondValue);
	}

	static differentComparedValues(firstValue: unknown, secondValue: unknown): boolean {
		return JSON.stringify(firstValue) !== JSON.stringify(secondValue);
	}

	static bpsToMbs(originalValue: number | string): string {
		return typeof originalValue === 'number' ? (originalValue / 1_000_000).toFixed(2) + ' Mb/s' : 'N/A';
	}

	static kibToMB(originalValue: number): number {
		return parseFloat((originalValue / Math.pow(1024, 1)).toFixed(2));
	}

	static unitSeparator(tickValue: string | number, multiplier: 1000 | 1024, customUnits?: string[]): string {
		const tickString = tickValue.toString();
		const isNegativeTick = tickString.charAt(0) === '-';
		const tickValueNumber = Number.parseFloat(!isNegativeTick ? tickString : tickString.slice(1, tickString.length));

		if (tickValueNumber >= 10) {
			const startingValue = !isNegativeTick ? '' : '-';
			const unitsToUse = !customUnits ? ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'] : customUnits;
			const i = Math.floor(Math.log(tickValueNumber) / Math.log(multiplier));

			return `${startingValue}${Number.parseFloat((tickValueNumber / Math.pow(multiplier, i)).toFixed(2))} ${unitsToUse[i] || ''}`;
		}

		return tickValue.toString();
	}

	static hasJsonStructure(fileContent: string): boolean {
		if (typeof fileContent !== 'string') {
			return false;
		}

		try {
			const type = Object.prototype.toString.call(JSON.parse(fileContent));

			return type === '[object Object]' || type === '[object Array]';
		} catch {
			return false;
		}
	}

	static prependZero(unit: number, limit: number): string | number {
		return unit < limit ? `0${unit}` : unit;
	}

	static formatDatetimeRange(startDatetime: DateTime, endDatetime: DateTime): Interval {
		return Interval.fromDateTimes(startDatetime, endDatetime);
	}

	static formatDatetimeFromHTTP(date: string, abilisTimezone: string): DateTime {
		return DateTime
			.fromHTTP(date)
			.setZone(abilisTimezone);
	}

	static getLuxonRangeFromInterval() {
		// eslint-disable-next-line space-before-function-paren
		return function* (interval: Interval) {
			const { start, end } = interval;

			if (!start || !end) { return; }

			let startDay = start.startOf('day');

			while (startDay < end) {
				yield startDay;
				startDay = startDay.plus({ days: 1 });
			}
		};
	}

	static setItemAtIndex<T>(items: T[], itemToAdd: T, index: number): T[] {
		return [...items.slice(0, index), { ...itemToAdd }, ...items.slice(index + 1)];
	}

	static replaceToRgbaColor(rgbColor: string, opacityLevel: number): string {
		return rgbColor
			.replace('rgb', 'rgba')
			.replace(')', `, ${opacityLevel})`);
	}

	static roundNumberToDecimalPoint(value: number, numberOfDecimalPoints: number): number {
		return Number.parseFloat(value.toFixed(numberOfDecimalPoints));
	}

	static convertToBlob(csvContent: string | ArrayBuffer, type: string): Blob {
		return new Blob([csvContent], { type });
	}

	static saveContent(blob: Blob | string, name: string): void {
		saveAs(blob, name);
	}

	public showToastrMessage(text: string, toastrType: ToastrType, subtext: string = '', positionClass: string = ''): void {
		switch (toastrType) {
		case ToastrType.SUCCESS:
			this.toastrService.success(text, subtext, this.getToastrAdditionalPayload(successToastrDuration, positionClass));
			break;
		case ToastrType.INFO:
			this.toastrService.info(text, subtext, this.getToastrAdditionalPayload(infoToastrDuration, positionClass));
			break;
		case ToastrType.ERROR:
			this.toastrService.error(text, subtext,  this.getToastrAdditionalPayload(errorToastrDuration, positionClass));
			break;
		}
	}

	private getToastrAdditionalPayload(timeOut: number, positionClass: string): { timeOut: number; positionClass?: string } {
		return !positionClass ? { timeOut } : { timeOut, positionClass };
	}

	static formatFormForSave(formValues: Record<string, any>, skipValueTransformKey?: string[]): Record<string, any> {
		return Object
			.entries(formValues)
			.sort()
			.slice()
			.reduce((accumulator, keyValuePair) => {
				const [key, value] = keyValuePair;

				if (!skipValueTransformKey || !skipValueTransformKey.includes(key)) {
					const valueToUse = value === null ? null : value.toString().toLowerCase();

					return { ...accumulator, [key]: valueToUse };
				}

				if (skipValueTransformKey.includes(key)) {
					const valueToUse = value === null ? null : value.toString();

					return { ...accumulator, [key]: valueToUse };
				}

				return accumulator;
			}, {});
	}

	static areFormsChanged(
		initialFormValues: Record<string, any>, currentFormValues: Record<string, any>, skipValueTransformKey?: string[]
	): boolean {
		const initialFormValuesToCompare = MainUtilService.formatFormForSave(initialFormValues, skipValueTransformKey);
		const currentFormValuesToCompare = MainUtilService.formatFormForSave(currentFormValues, skipValueTransformKey);

		return JSON.stringify(initialFormValuesToCompare) !== JSON.stringify(currentFormValuesToCompare);
	}

	static filterObjectProperties(propertiesToFilter: string[], filterableObject: Record<string, any>): Record<string, any> {
		if (propertiesToFilter.length) {
			return Object
				.keys(filterableObject)
				.reduce((accumulator, objectKey) => !propertiesToFilter.includes(objectKey) ?
					{ ...accumulator, [objectKey]: filterableObject[objectKey] } : accumulator, {});
		}

		return { ...filterableObject };
	}

	static formatSecondsToNiceFormat(seconds: string, showSeconds = true): string {
		let parsedSeconds = Number.parseInt(seconds, 10);
		// calculate days
		const days = Math.floor(parsedSeconds / 86400);
		parsedSeconds -= days * 86400;
		// calculate hours
		const hours = Math.floor(parsedSeconds / 3600) % 24;
		parsedSeconds -= hours * 3600;
		// calculate minutes
		const minutes = Math.floor(parsedSeconds / 60) % 60;
		const daysPrefix = MainUtilService.getDaysTranslationPrefix(days);

		return [
			{ value: days, prefix: daysPrefix },
			{ value: hours, prefix: '{{NETWORK.COMMON.hour}}' },
			{ value: minutes, prefix: '{{NETWORK.COMMON.minute}}' },
			{ value: parsedSeconds % 60, prefix: '{{NETWORK.COMMON.second}}' }
		]
			.filter((timeUnit, timeIndex) => timeIndex === 3 && !showSeconds ? false : timeUnit.value)
			.map(timeUnit => `${timeUnit.value}${timeUnit.prefix} `)
			.join('');
	}

	static getDaysTranslationPrefix(days: number): string {
		return days > 1 ? '{{NETWORK.COMMON.days}}' : '{{NETWORK.COMMON.day}}';
	}

	static convertObjectToArray(objectToConvert: Record<string, unknown>): Record<string, unknown>[] {
		return Object
			.keys(objectToConvert)
			.map(objectKey => ({ [objectKey]: objectToConvert[objectKey] }));
	}

	static isIpMatchingRange(ipAddress: string, ipHostIpAddress: string): boolean {
		ipAddress = ipAddress.replace(/0+(\d)/g, '$1');
		ipHostIpAddress = ipHostIpAddress.replace(/0+(\d)/g, '$1');
		return ip.cidrSubnet(ipAddress).contains(ipHostIpAddress);
	}

	// static isIpMatchingRange(ipAddress: string, ipHostIpAddress: string): boolean {
	// 	const subnet = ip.cidrSubnet(ipAddress);
	// 	const result = subnet.contains(ipHostIpAddress);
	// 	return result;
	// }

	static getValidRange(ipAddressWithMask: string): string {
		const { firstAddress, lastAddress } = ip.cidrSubnet(ipAddressWithMask);

		return `${firstAddress} - ${lastAddress}`;
	}

	static pluckValueFromObject(keyPath: string, object: any): GenericFormValue {
		return keyPath
			.split('.')
			.reduce((accumulator, cellPath) => accumulator[cellPath], object);
	}

	static splitAtFirstOccurence(value: string, delimeter: string): string[] {
		const index = value.indexOf(delimeter);

		return [value.slice(0, index), value.slice(index + 1)];
	}

	static removeValidators(form: FormGroup) {
		// eslint-disable-next-line guard-for-in
		for (const key in form.controls) {
			form.get(key)?.clearValidators();
			form.get(key)?.updateValueAndValidity({ emitEvent: false });
		}
	}

	static changeDayjsLanguage(language: AvailableLanguage | string): void {
		dayjs.locale(language === AvailableLanguage.EN ? dayjsEnglish : dayjsItalian);
	}

	static getRegExpStringFromSelectData(selectData: ISelectData[], separator = '', stringSeparator = '|'): string {
		return selectData
			.map(item => item.value)
			.map(value => `${separator}${value}${separator}`.trim())
			.join(stringSeparator);
	}
}
