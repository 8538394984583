import { startWith } from 'rxjs/operators';
import { FieldType } from '@ngx-formly/core';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IGeneratedConfigurationParams } from '../components/networking/ts/models/generated-configuration-params.model';

@UntilDestroy()
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'container-for-parameters',
	styleUrls: ['../formly-styles/parameters-container-style.scss'],
	template: `
	<div [formlyAttributes]="$any(formField)" [title]="tooltip | translate | translateFix:tooltip" [style]="containerStyle">
		<span class="section" (click)="onToggleDropdown($event)" *ngIf="formField.containerHeaderText else containerHeaderElementTemplate">
			<span class="span__icon" *ngIf="!disableContainerExpandIcon">
				<img src="assets/feather-icons/chevron-right-gray.svg" alt="chevron-up" class="span__icon--img" *ngIf="!dropdownExpanded">
				<img src="assets/feather-icons/chevron-down-gray.svg" alt="chevron-down" class="span__icon--img" *ngIf="dropdownExpanded">

				<input type="checkbox" name="formControlName">
			</span>

			<span class="main__heading"
			[ngClass]="{
				'main__heading--indent': !disableContainerExpandIcon,
				'main__heading--invalid': fieldGroups | formlyValidContainer:formChangedCounter
			}">
				{{label | translate | translateFix:label}}
			</span>
		</span>

		<ng-template #containerHeaderElementTemplate>
				<span *ngIf="label" [ngClass]="{
				'main__heading--invalid': fieldGroups | formlyValidContainer:formChangedCounter
			}">{{label | translate | translateFix:label}}</span>
				<formly-field [field]="$any(headerContainerElement)" class="w-auto"></formly-field>
		</ng-template>

		<div class="form" *ngIf="dropdownExpanded && showFormContent" #formElement>
			<div class="form__border"></div>

			<div class="form__content">
				<ng-container *ngFor="let field of fieldGroups">
					<formly-field [field]="$any(field)" *ngIf="!field.hide && !(field.className || '').includes('d-none')"></formly-field>
				</ng-container>
			</div>
		</div>
	</div>
  `
})

export class ParametersContainerComponent extends FieldType implements OnInit {

	label: string;
	tooltip: string;
	containerStyle: string;
	formChangedCounter = 0;
	showFormContent: boolean;
	dropdownExpanded: boolean;
	disableContainerExpandIcon: boolean | undefined;

	formField: IGeneratedConfigurationParams;
	fieldGroups: IGeneratedConfigurationParams[] = [];
	headerContainerElement: IGeneratedConfigurationParams | undefined;

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.formField = this.field as IGeneratedConfigurationParams;

		this.tooltip = this.formField.tooltip || '';
		const { label, disableContainerExpandIcon, containerStyle } = this.formField.templateOptions;

		this.label = label;
		this.containerStyle = containerStyle;
		this.disableContainerExpandIcon = disableContainerExpandIcon;

		this.setFieldGroups();
		this.setInitialExpandState();
		this.watchFormValueChanges();
		this.watchContainerElementFormChanges();
	}

	setInitialExpandState(): void {
		if (this.disableContainerExpandIcon) {
			this.dropdownExpanded = true;
		}
	}

	watchFormValueChanges(): void {
		this.field.form?.valueChanges
			.pipe(
				startWith(this.field.form?.value),
				untilDestroyed(this)
			)
			.subscribe(() => {
				this.showFormContent = !this.fieldGroups.every(filedGroupItem => {
					const { hide, className } = filedGroupItem;

					return hide || (className || '')?.includes('d-none');
				});

				this.formChangedCounter = this.formChangedCounter + 1;
			});
	}

	watchContainerElementFormChanges(): void {
		this.headerContainerElement?.formControl?.valueChanges
			.pipe(
				startWith(this.headerContainerElement?.formControl?.value),
				untilDestroyed(this)
			)
			.subscribe(dropdownExpanded => this.dropdownExpanded = !!dropdownExpanded);
	}

	setFieldGroups(): void {
		const { containerHeaderText, fieldGroup, containerElementHeaderName } = this.formField;
		const fieldGroupCasted = (fieldGroup as IGeneratedConfigurationParams[]).slice();

		if (containerHeaderText) {
			this.fieldGroups = fieldGroupCasted;

			return;
		}

		this.fieldGroups = fieldGroupCasted.filter(fieldGroupItem => fieldGroupItem.key !== containerElementHeaderName);
		this.headerContainerElement = fieldGroupCasted.find(fieldGroupItem => fieldGroupItem.key === containerElementHeaderName);
	}

	onToggleDropdown(event: Event): void {
		event.stopPropagation();

		this.dropdownExpanded = !this.dropdownExpanded;
	}
}
