import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { IGenericUnsetSelectedRow } from '../../../../ui-components/generic-table/ts/models/generic-unset-selected-row.model';

@Injectable({
	providedIn: 'root'
})
export class AddressBookTableUtilService {

	private static errorMessageState: BehaviorSubject<string> = new BehaviorSubject('');
	private static unsetSelectedRowState = new BehaviorSubject({ unsetSelectedRow: false });

	constructor() { }

	/**
	 * Sets response error from fetch HTTP response of Address Book contacts
	 * @param {string} errorMessage Error message to be displayed in the Address Book table
	 */
	public static setResponseErrorMessage(errorMessage: string = ''): void {
		this.errorMessageState.next(errorMessage);
	}

	/**
	 * Gets response error from fetch HTTP response of Address Book contacts
	 */
	public static getErrorMessageState$(): Observable<string> {
		return this.errorMessageState.asObservable();
	}

	/**
	 * Sets unsetSelectedRow value for the table. If true, any selected row in the table will be unselected
	 * @param {boolean} unsetSelectedRow If selected row in the table should be unselected
	 */
	public static setUnselectedRowState(unsetSelectedRow: boolean): void {
		this.unsetSelectedRowState.next({ unsetSelectedRow });
	}

	/**
	 * Gets Observable, if selected row should be kept or not
	 */
	public static getUnsetSelectedRowState$(): Observable<IGenericUnsetSelectedRow> {
		return this.unsetSelectedRowState.asObservable();
	}
}
