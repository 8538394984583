import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-tab-body',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './tab-body.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class TabBodyComponent {
	@ViewChild(TemplateRef) bodyContent: TemplateRef<unknown>;
}
