import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class NetworkingAutomationUtilService {

	private formlyButtonClickState: Subject<string | boolean> = new Subject();

	constructor() { }

	emitClickButtonState(parameter: string | boolean): void {
		this.formlyButtonClickState.next(parameter);
	}

	getClickButtonState$(): Observable<string | boolean> {
		return this.formlyButtonClickState.asObservable();
	}
}
