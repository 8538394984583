import { Injectable } from '@angular/core';
import { IFormlySelect } from '../ts/models/formly/formly-select.model';

@Injectable({
	providedIn: 'root'
})
export class NetworkingAutomationSelectService {

	constructor() { }

	getSelectOptions(selectValues: string, selectValuesUI: string): IFormlySelect[] {
		const selectAbilisValues = this.getSelectValues(selectValues);
		const selectUserValues = this.getSelectValues(selectValuesUI || '');

		if (!selectUserValues.length) { return selectAbilisValues; }

		return selectAbilisValues.map((selectItem, selectItemIndex) => {
			if (selectUserValues.length > selectItemIndex) {
				return { ...selectItem, label: selectUserValues[selectItemIndex].label };
			}

			return selectItem;
		});
	}

	getSelectValues(selectValues: string): IFormlySelect[] {
		if (!selectValues) { return []; }

		return selectValues
			.split(/(?!"),(?!")/)
			.map(selectValue => {
			// check to generate dynamic range
				if (/^RANGE\(.*-?\[\d{1,9}-\d{1,9}\]\)$/.test(selectValue)) {
					return this.generateDynamicSelectRange(selectValue);
				}
				// check to translate quote values
				if (/","/.test(selectValue)) {
					const translatedQuotes = selectValue.replace(/","/, ',');
					return { value: translatedQuotes, label: translatedQuotes };
				}
				// check to translate empty string value
				if (/'empty'/.test(selectValue)) {
					return { value: '', label: '' };
				}

				return { value: selectValue, label: selectValue };
			})
			.flat(1);
	}

	generateDynamicSelectRange(selectValue: string): IFormlySelect[] {
		const prefixName = selectValue.slice(6, selectValue.indexOf('['));
		const uiPrefixName = prefixName.includes('empty') ? '' : prefixName;
		const [minRange, maxRange] = selectValue
			.slice(selectValue.indexOf('[') + 1, selectValue.lastIndexOf(']'))
			.split('-')
			.map(rangeItem => Number.parseInt(rangeItem, 10));

		const rangeLength = (maxRange - minRange) + 1;
		let rangeCounter = minRange - 1;

		return Array
			.from({ length: rangeLength }, (_, k) => k)
			.map(() => {
				rangeCounter += 1;

				return { value: uiPrefixName + rangeCounter.toString(), label: uiPrefixName + rangeCounter.toString() };
			});
	}

	formatAsyncSelectDropdownValues(selectValues: string[]): IFormlySelect[] {
		return selectValues.map(selectValue => ({ value: selectValue, label: selectValue }));
	}

	getAsyncSelectDataFormat(asyncData: string[], responseSeparator: string | undefined): string[] {
		const updatedResponseSeparator = responseSeparator || '';

		return asyncData.map(asyncDataItem => `${updatedResponseSeparator}${asyncDataItem}${updatedResponseSeparator}`.toLowerCase());
	}
}
