import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { IAppState } from './../../../../../root-reducers/index';
import * as languagePickerActions from '../../components/language-picker/ngrx/language-picker.actions';

@Injectable({
	providedIn: 'root'
})
export class LanguageNgrxService {

	constructor(
		private store: Store<IAppState>
	) { }

	setDefaultLanguage(language: string): void {
		const payload = { language };

		this.store.dispatch(languagePickerActions.changeLanguage({ payload }));
	}
}
