import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, isObservable, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, repeat, retry, takeUntil } from 'rxjs/operators';

export function formDebounceDistinct$(debounceTimeUnit: number) {
	return (source: Observable<any>) => source.pipe(
		debounceTime(debounceTimeUnit),
		distinctUntilChanged((first, next) => JSON.stringify(first) === JSON.stringify(next))
	);
}

export function handle404Error$(errorValueToReturn: any, sessionCallback: () => void = () => { }, errorCallback?: () => void) {
	return (source: Observable<any>) => source.pipe(
		catchError((error: HttpErrorResponse) => {
			if (errorCallback) { errorCallback(); }

			if (error.status === 404) {
				if (sessionCallback) { sessionCallback(); }

				return !isObservable(errorValueToReturn) ? of(errorValueToReturn) : errorValueToReturn;
			}

			return throwError(() => 'Network error');
		}),
		retryFromError$(1000)
	);
}

export function retryFromError$<T>(delayTime: number, callback?: (error: HttpErrorResponse) => void) {
	return (source: Observable<T>) => source.pipe(retry({
		delay: (error: HttpErrorResponse) => {
			if (callback) { callback(error); }

			return timer(delayTime);
		}
	}));
}

export function handleUniqueValueOnError$<T>(valueToReturn: T) {
	return (source: Observable<T | null>) => source.pipe(
		catchError(error => error.status === 404 ? of(null) : of(valueToReturn))
	);
}

export function optionalTakeUntil$<T>(setTakeUntil: boolean, takeUntilSource$: Observable<void>) {
	if (setTakeUntil) {
		return (source: Observable<T | null>) => source.pipe(
			takeUntil(takeUntilSource$)
		);
	}

	return (source: Observable<T | null>) => source;
}

export function conditionalRepeat$<T>(useRepeatOperator: boolean) {
	if (!useRepeatOperator) {
		return (source: Observable<T | null>) => source;
	}

	return (source: Observable<T | null>) => source.pipe(repeat());
}
