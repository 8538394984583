import { Injectable } from '@angular/core';
import { RioRvsDeviceValue } from '../ts/enums/rio-rvs-device-value.enum';
import { RioRvsDeviceStatus } from '../ts/enums/rio-rvs-device-status.enum';

@Injectable({
	providedIn: 'root'
})
export class RioRvsImageService {

	private readonly customSensorImages = [
		'assets/feather-icons/thermometer.svg',
		'assets/feather-icons/lumens.svg',
		'assets/feather-icons/humidity.svg',
		'assets/feather-icons/pressure.svg',
		'assets/feather-icons/wind-measure.svg',
		'assets/feather-icons/doepler.svg',
		'assets/feather-icons/accoustic.svg',
		'assets/feather-icons/battery.svg',
		'assets/feather-icons/battery-charging.svg',
		'assets/feather-icons/volt-ampere.svg'
	];

	constructor() { }

	getAllAvailableImagePaths(lineDeviceType: string): string[] {
		const deviceStatus = RioRvsDeviceStatus.OFF;

		switch (lineDeviceType) {
		case 'DigitalInputs':
		case 'DigitalInput':
		case 'DigitalInputBulbs':
		case 'DigitalInputBulb':
			return [
				'assets/rio-rvs/led-' + deviceStatus + '.svg',
				'assets/rio-rvs/bulb-' + deviceStatus + '.svg'
			];
		case 'DigitalOutputBulbs':
		case 'DigitalOutputBulb':
		case 'DigitalOutputs':
		case 'DigitalOutput':
			return [
				'assets/rio-rvs/pulse-' + deviceStatus + '.svg',
				'assets/rio-rvs/toggle-' + deviceStatus + '.svg',
				'assets/rio-rvs/switch-' + deviceStatus + '.svg'
			];
		case 'AnalogInputs':
		case 'AnalogInputsAlarms':
		case 'AnalogInputsAlarm':
		case 'AnalogInputAlarm':
			return ['assets/rio-rvs/alarm-normal.svg', ...this.customSensorImages];
		case 'AnalogOutputs':
		case 'AnalogOutputsAlarm':
		case 'AnalogOutputsAlarms':
			return [];
		}

		return [];
	}

	getDeviceImagePath(lineDeviceType: string, deviceStatus: RioRvsDeviceStatus = RioRvsDeviceStatus.OFF): string {
		switch (lineDeviceType) {
		case 'DigitalInputs':
		case 'DigitalInput':
			return 'assets/rio-rvs/led-' + deviceStatus + '.svg';
		case 'DigitalInputBulbs':
		case 'DigitalInputBulb':
		case 'DigitalOutputBulbs':
		case 'DigitalOutputBulb':
			return 'assets/rio-rvs/bulb-' + deviceStatus + '.svg';
		case 'DigitalOutputPulses':
		case 'DigitalOutputPulse':
		case 'DigitalOutputs':
		case 'DigitalOutput':
			return 'assets/rio-rvs/pulse-' + deviceStatus + '.svg';
		case 'DigitalOutputToggles':
		case 'DigitalOutputToggle':
			return 'assets/rio-rvs/toggle-' + deviceStatus + '.svg';
		case 'DigitalOutputSwitches':
		case 'DigitalOutputSwitch':
			return 'assets/rio-rvs/switch-' + deviceStatus + '.svg';
		case 'AnalogInputsAlarms':
		case 'AnalogInputsAlarm':
		case 'AnalogInputAlarm':
		case 'AnalogOutputsAlarms':
		case 'AnalogOutputsAlarm':
			return 'assets/rio-rvs/alarm-normal.svg';
		}

		return '';
	}

	convertValueToStatus(deviceValue: RioRvsDeviceValue): RioRvsDeviceStatus {
		switch (deviceValue) {
		case RioRvsDeviceValue.OFF:
			return RioRvsDeviceStatus.OFF;
		case RioRvsDeviceValue.ON:
			return RioRvsDeviceStatus.ON;
		case RioRvsDeviceValue.UNAVAILABLE:
			return RioRvsDeviceStatus.UNAVAILABLE;
		default:
			return RioRvsDeviceStatus.OFF;
		}
	}
}
