import { Injectable } from '@angular/core';
import { IActuator } from '../../ts/models/automation-program-edit-actuator.model';
import { AutomationProgramDevicesService } from './automation-program-devices.service';
import { IAutomationDevice } from '../../../automation/ts/models/automation-device.model';
import { AutomationDeviceType } from '../../../automation/ts/enums/automation-device-type.enum';
import { AutomationLineDevice } from '../../../automation/ts/types/automation-line-device.type';
import { RioRvsIohubDeviceType } from '../../../rio-rvs/ts/enums/rio-rvs-iohub-device-type.enum';
import { AutomationGenericService } from '../../../automation/services/automation-generic.service';
import { RioRvsWioIohubDeviceClass } from '../../../rio-rvs/ts/enums/rio-rvs-wio-iohub-device-class.enum';
import { IAutomationProgramEditLineDevice } from '../../ts/models/automation-program-edit-line-device.model';
import { IAutomationProgramVoutDescription } from '../../ts/models/automation-program-vout-description.model';
import { AutomationProgramEditDeviceStatusData } from '../../ts/types/automation-program-edit-status-data.type';
import { INewAutomationProgramEditInputValue } from '../../ts/models/automation-program-edit-new-input-value.model';
import { AutomationProgramEditLineDeviceValue } from '../../ts/types/automation-program-edit-line-device-value.type';
import { RioRvsWioIohubShortDeviceClass } from '../../../rio-rvs/ts/enums/rio-rvs-wio-iohub-short-device-class.enum';
import { AutomationDeviceSessionHandlerService } from '../../../automation/services/device/automation-device-session-handler.service';
import { IAutomationProgramEditStoredFunctionExpression } from '../../ts/models/automation-program-edit-stored-function-expression.model';
import {
	AutomationProgramSpreadsheetFunctionService
} from '../spreadsheet/input-validation/automation-program-spreadsheet-function.service';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSessionDevicesHandlerService {

	constructor(
		private automationDevicesService: AutomationProgramDevicesService,
		private automationSessionHandlerService: AutomationDeviceSessionHandlerService,
		private automationSpreadsheetFnService: AutomationProgramSpreadsheetFunctionService
	) { }

	formatProjectLineDevices(response: AutomationProgramEditDeviceStatusData): IAutomationProgramEditLineDevice[] {
		const [ioSessionData] = response;
		const automationDevices = this.automationSessionHandlerService.getAllDevices(ioSessionData);
		const availableLineDevices = this.formatAllAvailableDevices(automationDevices);

		return this.updateSpreadsheetLineDevices(response, availableLineDevices);
	}

	updateSpreadsheetLineDevices(
		response: AutomationProgramEditDeviceStatusData, availableLineDevices: IAutomationProgramEditLineDevice[]
	): IAutomationProgramEditLineDevice[] {
		const [, actuators, customInputValues, spreadsheetLineDevices, voutDescriptions] = response;

		const updatedSpreadsheetLineDevices = spreadsheetLineDevices.map(lineDevice => {
			const fullDeviceName = lineDevice.fullDeviceName as string;

			const customInputValueData = customInputValues.find(inputItem => inputItem.id === fullDeviceName);
			const matchingSessionLineDevice = this.getMatchingSessionLineDevice(availableLineDevices, fullDeviceName);
			const isOffline = this.isDeviceValueOffline(lineDevice, matchingSessionLineDevice);
			const value = this.getValueOfLineDevice(lineDevice, customInputValueData, matchingSessionLineDevice);
			const functionExpressionResult = this.checkForStoredFunctionExpression(actuators, fullDeviceName, spreadsheetLineDevices);
			const lineDeviceToSpread = matchingSessionLineDevice ? { ...matchingSessionLineDevice } : { ...lineDevice };
			const description = this.getLineDeviceDescription(voutDescriptions, lineDeviceToSpread);

			return { ...lineDeviceToSpread, ...functionExpressionResult, value, isOffline, description };
		}, []) as IAutomationProgramEditLineDevice[];

		return this.automationDevicesService.mapLineDevicesWithIndex(updatedSpreadsheetLineDevices);
	}

	formatAllAvailableDevices(allDevices: IAutomationDevice[]): IAutomationProgramEditLineDevice[] {
		return allDevices.reduce((accumulator, deviceItem) => {
			const { devices, num: deviceNumber, type: device } = deviceItem;

			const updatedLineDevices = devices.map(lineDevice => {
				const { fullDeviceName, originalDeviceType } = lineDevice;
				const type = this.automationDevicesService.getDeviceType(originalDeviceType as AutomationLineDevice);
				// rio-1, din-2
				const port = AutomationGenericService.splitAutomationItemByComma(fullDeviceName as string)[1];
				const [lineDeviceName, lineDeviceNumber] = AutomationGenericService.splitAutomationItemByDash(port);

				return { ...lineDevice, device, deviceNumber, lineDeviceName, lineDeviceNumber: +lineDeviceNumber, type };
			}) as unknown as IAutomationProgramEditLineDevice[];

			return [...accumulator, ...updatedLineDevices];
		}, [] as IAutomationProgramEditLineDevice[]);
	}

	getValueOfLineDevice(
		lineDevice: IAutomationProgramEditLineDevice,
		customInputValueData: INewAutomationProgramEditInputValue | undefined,
		matchingSessionLineDevice: IAutomationProgramEditLineDevice | undefined
	): AutomationProgramEditLineDeviceValue {
		if (customInputValueData) {
			return customInputValueData.newInputValue;
		}

		const { lineDeviceName, type } = lineDevice;
		const isDigitalDevice = lineDeviceName.toLowerCase().charAt(0) === 'd';

		if (matchingSessionLineDevice && type.toLowerCase() === AutomationDeviceType.INPUT) {
			const haveDefaultState = matchingSessionLineDevice.hasOwnProperty('dft');

			if (haveDefaultState) {
				const { dft } = matchingSessionLineDevice;

				return isDigitalDevice ? dft.toString().toLowerCase() === 'on' : dft;
			}
		}

		if (lineDevice.device === RioRvsIohubDeviceType.VOUT) {
			return '';
		}

		return isDigitalDevice ? false : 0;
	}

	isDeviceValueOffline(
		lineDevice: IAutomationProgramEditLineDevice, matchingSessionLineDevice: IAutomationProgramEditLineDevice | undefined
	): boolean {
		if (lineDevice.device === RioRvsIohubDeviceType.VOUT) {
			return false;
		}

		if (matchingSessionLineDevice) {
			const { value } = matchingSessionLineDevice;

			return value === 255 || value === 'N/A';
		}

		return true;
	}

	checkForStoredFunctionExpression(
		actuators: IActuator[], fullDeviceName: string, spreadsheetLineDevices: IAutomationProgramEditLineDevice[]
	): IAutomationProgramEditStoredFunctionExpression {
		const matchingActuator = actuators.find(availableActuator => availableActuator.id === fullDeviceName);

		if (matchingActuator && matchingActuator.functionExpression) {
			const { functionExpression: expression, id } = matchingActuator;

			const selectedOutputDevice = spreadsheetLineDevices.find(lineDevice => lineDevice.fullDeviceName === matchingActuator.id);
			const parsedInputResult = this.automationSpreadsheetFnService.parseInputToValue(
				expression, spreadsheetLineDevices, null, selectedOutputDevice
			);

			const { haveError, value, errorMessage } = parsedInputResult;

			if (haveError) {
				return { expressionValue: errorMessage as string, expression };
			}

			const expressionValue = this.translateDigitalOutputValues(id, (value as AutomationProgramEditLineDeviceValue).toString());

			return { expressionValue, expression };
		}

		return { expressionValue: '', expression: '' };
	}

	translateDigitalOutputValues(actuatorId: string, expressionValue: string): string {
		const parsedValue = Number.parseFloat(expressionValue);
		const doutMatching = actuatorId.includes(RioRvsWioIohubDeviceClass.DOUT) || actuatorId.includes(RioRvsWioIohubShortDeviceClass.DO);

		if (doutMatching && expressionValue && !Number.isNaN(parsedValue)) {
			return parsedValue === 0 ? 'false' : 'true';
		}

		return expressionValue;
	}

	getMatchingSessionLineDevice(
		availableLineDevices: IAutomationProgramEditLineDevice[], fullDeviceName: string
	): IAutomationProgramEditLineDevice | undefined {
		return availableLineDevices.find(availableLineDevice => availableLineDevice.fullDeviceName === fullDeviceName);
	}

	getLineDeviceDescription(voutDescriptions: IAutomationProgramVoutDescription[], lineDevice: IAutomationProgramEditLineDevice): string {
		const { fullDeviceName, description } = lineDevice;

		if (voutDescriptions.length) {
			const matchingDescription = voutDescriptions.find(savedDescription => savedDescription.id === fullDeviceName);

			if (matchingDescription) {
				return matchingDescription.description;
			}
		}

		return description || '';
	}
}
