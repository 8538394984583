import { Action, createReducer, on } from '@ngrx/store';
import { ICamera } from '../../videorecorder/ts/models/camera.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IUserPreferenceItem } from '../ts/models/user-preferences-item.model';
import { IUserPreferencesPrivilegeParameters } from './../ts/models/user-preferences-privilege-parameters.model';
import * as userPreferenceActions from './user-preferences.actions';

export const UserPreferencesFeatureKey = 'userPreferences';

export interface IUserPreferencesState extends EntityState<IUserPreferenceItem> {
	isLoading: boolean;
	showSaveLog: boolean;
	cameraList: ICamera[];
	saveErrorText: string | null;
	userPrivilegeDetails: null | IUserPreferencesPrivilegeParameters;
}

export const adapter: EntityAdapter<IUserPreferenceItem> = createEntityAdapter<IUserPreferenceItem>({
	selectId: (userPreferenceItem: IUserPreferenceItem) => userPreferenceItem.ID.VALUE
});

export const initialUserPreferenceState: IUserPreferencesState = adapter.getInitialState({
	cameraList: [],
	isLoading: true,
	showSaveLog: false,
	saveErrorText: null,
	userPrivilegeDetails: null
});

const userPreferenceReducer = createReducer(
	initialUserPreferenceState,
	on(userPreferenceActions.finishLoadingUserPreferences, (state, { payload }) =>
		adapter.setAll(payload.userPreferences, { ...state, isLoading: false })),
	on(userPreferenceActions.setSaveConfigurationResponse, (state, { payload }) => {
		const { errorText, showError } = payload.response;

		return { ...state, saveErrorText: errorText, showSaveLog: showError };
	}),
	on(userPreferenceActions.setUserRowDisabled, (state, { payload }) =>
		adapter.updateOne({ id: payload.userId, changes: { rowDisabled: true } }, { ...state })),
	on(userPreferenceActions.cancelUserPreferences, (state) =>
		adapter.setAll([], { ...state, isLoading: true, showSaveLog: false, saveErrorText: '', cameraList: [] })),
	on(userPreferenceActions.setLoadingUsersTable, (state, { payload }) => ({ ...state, isLoading: payload.isLoading })),
	on(userPreferenceActions.finishLoadingCameraList, (state, { payload }) => ({ ...state, cameraList: payload.cameraList })),
	on(userPreferenceActions.setUserPrivilegeData, (state, { payload }) => ({ ...state, userPrivilegeDetails: payload.data }))
);

export function UserPreferenceReducer(state: IUserPreferencesState | undefined, action: Action) {
	return userPreferenceReducer(state, action);
}

export const { selectAll: selectAllUserPreferenceItems } = adapter.getSelectors();
