import { createAction, props } from '@ngrx/store';
import { IOpcPanelGroup } from '../../ts/models/opc-panel-group.model';
import { IOpcPanelUserRecord } from '../../ts/models/session/opc-panel-user-record.model';
import { IOpcPanelCtiControlPayload } from '../../ts/models/opc-panel-cti-control-payload.model';

export const loadOpcPanelGroups = createAction(
	'[Opc Panel Group] Load Opc Panel Groups'
);

export const loadOpcSettings = createAction(
	'[Opc Panel Group] Load Opc Settings'
);

export const setUserRowDisabled = createAction(
	'[Opc Panel Group] Set User Row Disabled',
	props<{ payload: { userId: string } }>()
);

export const setShowPhone = createAction(
	'[Opc Panel Group] Set Show Phone',
	props<{ payload: { showPhone: boolean } }>()
);

export const finishLoadingOpcPanelGroups = createAction(
	'[Opc Panel Group] Finish Loading Opc Panel Groups',
	props<{ payload: { opcPanelGroups: IOpcPanelGroup[] } }>()
);

export const fetchSessionChanges = createAction(
	'[Opc Panel Group] Fetch Session Changes'
);

export const cancelOpcPanelHTTPObservables = createAction(
	'[Opc Panel Group] Cancel HTTP Observables'
);

export const setCardActiveTab = createAction(
	'[Opc Panel Group] Set Card Active Tab',
	props<{ payload: { userId: string; groupId: number } }>()
);

export const updateOpcPanelGroup = createAction(
	'[Opc Panel Group] Update OPC Panel Group',
	props<{ payload: { id: number; groupData: IOpcPanelGroup } }>()
);

export const setGroupMinimizeAction = createAction(
	'[Opc Panel Group] Set Group Minimize Action',
	props<{ payload: { groupId: number; minimize: boolean } }>()
);

export const refreshGpsPosition = createAction(
	'[Opc Panel Group] Refresh GPS Position',
	props<{ payload: { userRecord: IOpcPanelUserRecord; ctiPayload: IOpcPanelCtiControlPayload; showToastr: boolean } }>()
);

export const transferCallOpcCallRecord = createAction(
	'[Opc Panel Group] Transfer/Call OPC Call Record',
	props<{ payload: { ctiPayload: IOpcPanelCtiControlPayload; showToastr: boolean } }>()
);

export const recordOpcCall = createAction(
	'[Opc Panel Group] Record OPC Call',
	props<{ payload: { ctiPayload: IOpcPanelCtiControlPayload; showToastr: boolean } }>()
);

export const closeActiveCall = createAction(
	'[Opc Panel Group] Close Active Call',
	props<{ payload: { ctiPayload: IOpcPanelCtiControlPayload; showToastr: boolean } }>()
);

export const changeQueuePosition = createAction(
	'[Opc Panel Group] Change Queue Position',
	props<{ payload: { ctiPayload: IOpcPanelCtiControlPayload; showToastr: boolean } }>()
);

export const setDragCallQueueLayout = createAction(
	'[Opc Panel Group] Set Drag Call Queue Layout',
	props<{ payload: { showQueueDragNotification: boolean } }>()
);

export const setGpsRefreshErrorState = createAction(
	'[Opc Panel Group] Set GPS Refresh Error State',
	props<{ payload: { gpsRefreshValidation: string } }>()
);

