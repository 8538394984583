import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrType } from '../../../../../ts/enums/toastr-type.enum';
import { MainUtilService } from '../../../../../services/main-util.service';
import { IAddressBookContact } from '../../ts/models/address-book-contact.model';
import { AddressBookFieldKey } from '../../ts/enums/address-book-field-key.enum';
import { AddressBookContactArrayService } from './../address-book-contact-array.service';
import { IAddressBookContactCsv } from './../../ts/models/address-book-contact-csv.model';

@Injectable({
	providedIn: 'root'
})
export class AddressBookCsvHandlerService {

	private readonly translateService: TranslateService;

	constructor(
		private readonly mainUtilService: MainUtilService,
		private readonly addressBookContactArrayService: AddressBookContactArrayService
	) {
		this.translateService = inject(TranslateService);
	 }

	/**
	* Handles export of Address Book contacts in CSV format
	* @param {IAddressBookContact[]} contacts Address Book contacts
	* @param {string} username Passed username, in order to form filename which is going to be exported
	*/
	public exportCsv(contacts: IAddressBookContact[], username: string): void {
		const privateContacts = this.addressBookContactArrayService.formatContactsForCsv(contacts);

		if (!privateContacts.length) {
			this.showNoPrivateContactsError();
			return;
		}

		const headers = this.getHeaders(privateContacts);
		const csvData = this.addressBookContactArrayService.formatCsvData(privateContacts, headers);
		const updatedHeader = this.addressBookContactArrayService.formatCsvHeader(headers);
		const csvArray = this.getFinalCsv(updatedHeader, csvData);
		const blobData = MainUtilService.convertToBlob(csvArray, 'text/csv');

		MainUtilService.saveContent(blobData, this.getCsvFilename(username));
	}

	/**
	* Formats CSV filename
	* @param {string} username Passed username, in order to form filename which is going to be exported
	*/
	private getCsvFilename(username: string): string {
		return username + '_addrbook.csv';
	}

	/**
	* Shows toastr with error notification, when no private contacts are to export
	* UI will be notified
	*/
	private showNoPrivateContactsError(): void {
		this.mainUtilService.showToastrMessage(this.translateService.instant('ADDRESS-BOOK.CSV.errorExport'), ToastrType.ERROR);
	}

	/**
	* Gets first contact's object keys
	* @param {IAddressBookContactCsv[]} privateContacts Address Book contacts
	*/
	private getHeaders(privateContacts: IAddressBookContactCsv[]): AddressBookFieldKey[] {
		const [firstContact] = privateContacts;

		return Object.keys(firstContact) as AddressBookFieldKey[];
	}

	/**
	* Formats final CSV
	* @param {string[]} headers File headers
	* @param {string[]} csvData Selected private Address Book contacts in CSV format
	*/
	private getFinalCsv(headers: string[], csvData: string[]): string {
		return [headers.join(','), ...csvData].join('\r\n');
	}
}
