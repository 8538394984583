<div dropdown>
	<button class="content__item" dropdownToggle type="button">
		<img src="assets/flags/{{translateService.currentLang}}.svg" alt="{{translateService.currentLang}}"
			*ngIf="translateService.currentLang">
	</button>

	<div class="dropdown">
		<div class="dropdown__item" (click)="onSetLanguage(language)"
			*ngFor="let language of translateService.getLangs()">
			<img src="assets/flags/{{language}}.svg" alt="{{language}}" *ngIf="language">
			<span>{{language | translateLanguageFullname | translate}}</span>
		</div>
	</div>
</div>
