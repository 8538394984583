import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from '../../../../../ts/enums/routes.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthNgrxService } from '../../../../auth/services/auth-ngrx.service';
import { PermissionTabService } from '../../../services/permission-tab.service';
import { SidebarFeatureNames } from '../../../ts/enums/sidebar-feature-names.enum';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { FeaturePermissionsName } from '../../../ts/enums/feature-permission-name.enum';
import { RouterUtilService } from './../../../../../services/utils/router-util.service';
import { IFeaturePermissionsItem } from '../../../ts/models/feature-permissions-item.model';
import { SideBarService } from './../../../../../standalone/components/ui/side-bar/services/side-bar.service';

@UntilDestroy()
@Component({
	selector: 'app-admin-tab-preview',
	templateUrl: './admin-tab-preview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminTabPreviewComponent implements OnInit {

	public readonly router: Router;
	private readonly activatedRoute: ActivatedRoute;

	public voPermission$: Observable<IFeaturePermissionsItem | null>;
	public userRightsPermission$: Observable<IFeaturePermissionsItem | null>;

	constructor(
		private readonly sideBarService: SideBarService,
		private readonly authNgrxService: AuthNgrxService,
		private readonly routerUtilService: RouterUtilService,
		private readonly permissionTabService: PermissionTabService
	) {
		this.router = inject(Router);
		this.activatedRoute = inject(ActivatedRoute);

		this.sideBarService.setActiveSidebarItem(SidebarFeatureNames.ADMINISTRATION);
	 }

	public ngOnInit(): void {
		this.setInitialActiveTab();
		this.watchMainRouteMatch();

		this.voPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.VO);
		this.userRightsPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.ADMIN_USER_RIGHTS);
	}

	private setInitialActiveTab(): void {
		const matchesAdministration = RouterUtilService.matchesRouterUrl(this.router.url, RoutesEnum.ADMINISTRATION);

		if (!matchesAdministration) { return; }

		this.onAdminTabRouting(RoutesEnum.USERS_MANAGEMENT);
	}

	private watchMainRouteMatch(): void {
		this.permissionTabService.watchMainRouteMatch$(RoutesEnum.ADMINISTRATION)
			.pipe(untilDestroyed(this))
			.subscribe(() => this.routerUtilService.navigateToRoute());
	}

	public onAdminTabRouting(featureName: RoutesEnum): void {
		this.routerUtilService.navigateToRouterOutlet(featureName, this.activatedRoute);
	}

	public get getRoutesEnum(): typeof RoutesEnum {
		return RoutesEnum;
	}
}
