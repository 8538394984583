import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map, catchError, switchMap, concatMap } from 'rxjs/operators';
import { FormlyUtilOperators } from '../../ts/enums/formly/formly-util-operators.enum';
import { NetworkingAutomationHttpLogicService } from './networking-automation-http-logic.service';
import { INetworkingAutomationSaveConfiguration } from '../../ts/models/networking-automation-save-config.model';
import * as customOperators from '../../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root',
})
export class NetworkingAutomationHttpService {

	private autoformJsonUrl = 'assets/autoforms/';

	constructor(
		private http: HttpClient,
		private networkingAutomationHttpLogicService: NetworkingAutomationHttpLogicService
	) { }

	// HTTP

	fetchAsyncConfigurationItemValues$(fetchUrl: string, asyncValidatorPath: string): Observable<string[]> {
		const asyncItems$ = fetchUrl
			.split(FormlyUtilOperators.SEPARATOR)
			.map(url => this.http.get(environment.abilisUrl + url, { responseType: 'text' }));

		const pathItems = asyncValidatorPath.split(FormlyUtilOperators.ASYNC_PATH_SEPARATOR);

		return zip(...asyncItems$)
			.pipe(
				map(response => response
					.map((responseItem, index) => {
						const asyncPath = pathItems[index];

						return this.networkingAutomationHttpLogicService.formatAsyncConfigurationItems(responseItem, asyncPath);
					})
					.flat(1)
				),
				customOperators.formDebounceDistinct$(100)
			);
	}

	fetchConfigurationData$(configurationName: string, restructureReferences: boolean): Observable<INetworkingAutomationSaveConfiguration> {
		const translatedConfiguration = configurationName.includes('eth') ? 'eth' : configurationName;
		const fetchUrl = this.autoformJsonUrl + `${translatedConfiguration}.json?t=${new Date().getTime()}`;

		return this.http.get<INetworkingAutomationSaveConfiguration>(fetchUrl)
			.pipe(
				switchMap(response => {
					const asyncFilesToFetch = this.networkingAutomationHttpLogicService.getReferencedConfigurationNames(response);
					// if there are no referenced-configurations present or set with restructureReference flag, then return initially fetched configuration
					if (!asyncFilesToFetch.length || !restructureReferences) {
						return of(response);
					}

					const asyncFilesToFetch$ = this.getReferencedConfigurationNames$(asyncFilesToFetch);

					return zip(of(response), of(asyncFilesToFetch), ...asyncFilesToFetch$);
				}),
				concatMap(response => {
					// Array structure means there are referenced data
					if (Array.isArray(response)) {
						const nestedResponse = response as [INetworkingAutomationSaveConfiguration, string[]];

						return of(this.networkingAutomationHttpLogicService.translateReferencedConfigurations(nestedResponse));
					}

					return of(response as INetworkingAutomationSaveConfiguration);
				})
			);
	}

	getReferencedConfigurationNames$(asyncFilesToFetch: string[]): Observable<INetworkingAutomationSaveConfiguration>[] {
		return asyncFilesToFetch.map(asyncFileName => {
			const fetchUrl = this.autoformJsonUrl + `${asyncFileName}.json?t=${new Date().getTime()}`;

			return this.http.get<INetworkingAutomationSaveConfiguration>(fetchUrl)
				.pipe(
					catchError(() => of({ configuration: [] }))
				);
		});
	}
}
