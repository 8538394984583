import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[appAutofocusInputField]',
	standalone: true
})
export class AutofocusInputFieldDirective implements AfterViewInit {

	@Input() autofocus = true;

	constructor(
		private elementRef: ElementRef
	) { }

	ngAfterViewInit(): void {
		if (!this.autofocus) { return; }

		setTimeout(() => this.elementRef.nativeElement.focus(), 0);
	}
}
