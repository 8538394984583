import { Injectable } from '@angular/core';
import { IRioRvsSaveDevice } from '../ts/models/rio-rvs-save-device.model';
import { RioRvsDoutControlState } from '../ts/enums/rio-rvs-dout-control-state.enum';
import { RioRvsModalLineDeviceType } from '../ts/types/rio-rvs-modal-line-device.type';
import { RioRvsWioIohubDeviceClass } from '../ts/enums/rio-rvs-wio-iohub-device-class.enum';
import { GenericFormValue } from '../../ui-components/generic-table/ts/types/generic-form-value.type';

@Injectable({
	providedIn: 'root'
})
export class RioRvsModalService {

	constructor() { }

	formatChangedDevice(
		deviceType: RioRvsWioIohubDeviceClass, resource: string,
		lineDevice: RioRvsModalLineDeviceType, formValues: GenericFormValue[],
		isGainValueOnly?: boolean
	): IRioRvsSaveDevice {
		const formValuesCasted = formValues as string[];
		const coreParamsToSave = { res: resource, lineType: deviceType, lineInd: [`${lineDevice.num}`] };

		switch (deviceType) {
		case RioRvsWioIohubDeviceClass.DIN:
			const [_, dinDescription, dinDft, eventTreshold, dinAlert, dinAlertSt] = formValuesCasted;

			return { ...coreParamsToSave, descr: dinDescription, dft: dinDft, 'event-thr': eventTreshold, alert: dinAlert, alertSt: dinAlertSt };
		case RioRvsWioIohubDeviceClass.DOUT:
			const [, doutDescription, mode, pulseT, pulseGap, start, doutDft, ctrlDi, ctrlDiSt, doutAlert, doutAlertSt] = formValuesCasted;

			return {
				...coreParamsToSave, descr: doutDescription, mode, 'pulse-t': pulseT,
				'pulse-gap': pulseGap, start, dft: doutDft, ctrlDi, ctrlDiSt: ctrlDiSt as RioRvsDoutControlState,
				alert: doutAlert, alertSt: doutAlertSt
			};
		case RioRvsWioIohubDeviceClass.AIN:
			if (isGainValueOnly) {
				return this.filterAinGainValues({ ...coreParamsToSave, gain: formValuesCasted[0] }, lineDevice);
			}

			const [
				, ainDescription, ainUnit, ainMin, ainMax, ainHist,
				ainUpd, ainPoll, dft, almLo, almHi, alertAlmLo, alertAlmHi
			] = formValuesCasted;

			return this.filterAinGainValues({
				...coreParamsToSave, descr: ainDescription, unit: ainUnit, almLo, almHi,
				min: ainMin, max: ainMax, hist: ainHist, upd: ainUpd, poll: ainPoll, dft,
				alertAlmLo, alertAlmHi
			}, lineDevice);
		case RioRvsWioIohubDeviceClass.AOUT:
			const [
				, aoutDescription, aoutUnit, aoutMin, aoutMax, aoutUpd, aoutPoll,
				cutLo, cutHi, aoutStart, aoutDft, aoutAlmLo, aoutAlmHi, aoutHist
			] = formValuesCasted;

			return this.filterUnavailableCutValues({
				...coreParamsToSave, descr: aoutDescription, almLo: aoutAlmLo, hist: aoutHist,
				unit: aoutUnit, min: aoutMin, max: aoutMax, upd: aoutUpd, 'cut-lo': cutLo,
				'cut-hi': cutHi, poll: aoutPoll, start: aoutStart, dft: aoutDft, almHi: aoutAlmHi
			});
		}
	}

	filterAinGainValues(ainPayload: IRioRvsSaveDevice, lineDevice: RioRvsModalLineDeviceType): IRioRvsSaveDevice {
		return Object
			.entries(ainPayload)
			.reduce((accumulator, keyValuePair) => {
				const [key, value] = keyValuePair;

				if ((key === 'gain' && !value) || lineDevice[`${key}-ronly` as keyof RioRvsModalLineDeviceType]) {
					return accumulator;
				}

				return { ...accumulator, [key]: value };
			}, {} as IRioRvsSaveDevice);
	}

	filterUnavailableCutValues(aoutPayload: IRioRvsSaveDevice): IRioRvsSaveDevice {
		return Object
			.entries(aoutPayload)
			.reduce((accumulator, keyValuePair) => {
				const [key, value] = keyValuePair;

				if (key === 'cut-lo' && (value === '9999' || value === '-9999')) {
					return accumulator;
				}

				if (key === 'cut-hi' && (value === '9999' || value === '-9999')) {
					return accumulator;
				}

				return { ...accumulator, [key]: value };
			}, {} as IRioRvsSaveDevice);
	}
}
