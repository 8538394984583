import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from './../../../ts/enums/routes.enum';
import { UserPreferencesNgrxService } from './user-preferences-ngrx.service';
import { RouterUtilService } from './../../../services/utils/router-util.service';
import { IUserPreferencesConflict } from '../ts/models/user-preferences-conflict.model';
import { IForceModalPayload } from '../ts/models/user-preferences-force-modal-payload.model';
import { IUserPreferencesActionResponse } from '../ts/models/user-preferences-action-response.model';
import { IUserPreferenceResponseConflict } from '../ts/models/user-preferences-response-conflict.model';

@Injectable({
	providedIn: 'root'
})
export class UserPreferencesService {

	private readonly router: Router;
	private readonly activatedRoute: ActivatedRoute;

	private toggleForceModal = new BehaviorSubject<IForceModalPayload | null>(null);

	constructor(
		private formBuilder: FormBuilder,
		private routerUtilService: RouterUtilService,
		private userPreferencesNgrxService: UserPreferencesNgrxService
	) {
		this.router = inject(Router);
		this.activatedRoute = inject(ActivatedRoute);
	}

	handleSavePrivilegesResult(response: IUserPreferencesActionResponse | null): boolean {
		if (!response) { return false; }

		const { rsp } = response.Response;

		if (!!rsp && rsp.toLowerCase().includes('command executed')) {
			this.userPreferencesNgrxService.savePermanentlyUserSettings();
		}

		this.userPreferencesNgrxService.loadBasicUserPreferences();
		return true;
	}

	setToggleForceModal(forceModalData: IForceModalPayload | null): void {
		this.toggleForceModal.next(forceModalData);
	}

	getToggleForceModal$(): Observable<IForceModalPayload | null> {
		return this.toggleForceModal.asObservable();
	}

	generateConflictsArray(conflictResponse: IUserPreferenceResponseConflict[]): IUserPreferencesConflict[] {
		return conflictResponse.reduce((accumulator, conflict) => {
			const { rsp } = conflict;
			const [, message] = rsp.split(',');
			const matchingParameter = rsp.match(/\'([^']+)\'/);

			if (!matchingParameter) { return accumulator; }

			const [result] = matchingParameter;
			const conflictingParameter = result.slice(1, result.length - 1);

			return [...accumulator, { conflictingParameter, message }];
		}, [] as IUserPreferencesConflict[]);
	}

	buildConflictsFormArray(): FormArray {
		return this.formBuilder.array([]);
	}

	getConflictsToForce(conflicts: IUserPreferencesConflict[], selectedConflicts: string[]): IUserPreferencesConflict[] {
		return conflicts.filter(conflict => selectedConflicts.includes(conflict.conflictingParameter));
	}

	getConflictsToSave(conflicts: IUserPreferencesConflict[], selectedConflicts: string[]): IUserPreferencesConflict[] {
		const isSingleConflict = conflicts.length === 1;

		if (isSingleConflict) { return conflicts.slice(); }

		return this.getConflictsToForce(conflicts, selectedConflicts);
	}

	checkToClearAddressBook(): void {
		const urlPortion = 'admin-preferences:admin-preferences;replaceUrl=true/(address-book:address-book/';
		const clearUrl = this.router.url.includes(urlPortion);

		if (!clearUrl) { return; }

		this.routerUtilService.navigateToRouterOutlet(RoutesEnum.USERS_MANAGEMENT, this.activatedRoute);
	}
}
