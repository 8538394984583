import { IRioRvsState } from './rio-rvs.reducer';
import { RioRvsStoreKey } from '../ts/enums/rio-rvs-store-key.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RioRvsUpdatedOwner } from '../ts/enums/rio-rvs-updated-owner.enum';
import { RioRvsWioIohubDeviceClass } from '../ts/enums/rio-rvs-wio-iohub-device-class.enum';
import { RioRvsWioIohubShortDeviceClass } from '../ts/enums/rio-rvs-wio-iohub-short-device-class.enum';
import * as rioRvsReducer from './rio-rvs.reducer';

export const selectRioRvsState = createFeatureSelector<IRioRvsState>('rioRvs');

export const selectAllRioRvsDevices = () => createSelector(
	selectRioRvsState,
	rioRvsReducer.selectAllRioRvsDevices,
);

export const selectAllRioRvsLineDevices = () => createSelector(
	selectRioRvsState,
	rioRvsState => rioRvsState.rioRvsLineDevices
);

export const selectAllWioSlaveNodes = () => createSelector(
	selectRioRvsState,
	rioRvsState => rioRvsState.wioSlaveNodes
);

export const selectWioSlaveNodes = (wioResourceName: string | undefined) => createSelector(
	selectAllWioSlaveNodes(),
	wioSlaveNodes => wioSlaveNodes.filter(wioSlaveNode => wioSlaveNode.res.toUpperCase() === wioResourceName?.toUpperCase())
);

export const selectSpecificWioLineDevices = (lineDeviceType: RioRvsWioIohubShortDeviceClass, wionode: number) => createSelector(
	selectAllRioRvsLineDevices(),
	rioRvsWioLineDevices => rioRvsWioLineDevices.filter(item => {
		const { wioLineName, wionode: wioLineDeviceNode } = item;

		return (wioLineName || '').includes(lineDeviceType) && wioLineDeviceNode === wionode;
	})
);

export const selectSpecificWioLineDevicesByNode = (wionode: number) => createSelector(
	selectAllRioRvsLineDevices(),
	rioRvsWioLineDevices => rioRvsWioLineDevices.filter(item => item.wionode === wionode)
);

export const selectSpecificRioRvsLineDevices = (lineDeviceType: RioRvsWioIohubDeviceClass) => createSelector(
	selectAllRioRvsLineDevices(),
	rioRvsLineDevices => rioRvsLineDevices.filter(rioRvsLineDevice => rioRvsLineDevice.lineDeviceType.includes(lineDeviceType))
);

export const selectAllAvailableBindableDevices = (bindableDevices: string[]) => createSelector(
	selectAllRioRvsDevices(),
	rioRvsDevices => bindableDevices.filter(bindableDevice => rioRvsDevices.some(rioRvsItem => {
		const { updatedOwner, configured, resource } = rioRvsItem;

		const isMatchingByResource = resource?.toUpperCase() === bindableDevice.toUpperCase();
		const isNotConfiguredAndOwned = !(updatedOwner === RioRvsUpdatedOwner.OWN && configured);

		return (isNotConfiguredAndOwned && isMatchingByResource) || !isMatchingByResource;
	}))
);

export const selectFromRioRvsStore = (property: RioRvsStoreKey) => createSelector(
	selectRioRvsState,
	rioRvsState => rioRvsState[property]
);
