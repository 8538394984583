
import { Injectable } from '@angular/core';
import { EmptyObject } from '../../../../ts/types/empty-object.type';
import { OpcPanelCardService } from '../card/opc-panel-card.service';
import { OpcPanelUserRole } from '../../ts/enums/opc-panel-user-role.enum';
import { OpcPanelInterface } from '../../ts/enums/opc-panel-interface.enum';
import { OpcPanelCardCallService } from '../card/opc-panel-card-call.service';
import { OpcPanelCtiControl } from '../../ts/enums/opc-panel-cti-control.enum';
import { OpcPanelCallDirection } from '../../ts/enums/opc-panel-call-direction.enum';
import { OpcPanelCallRecordState } from '../../ts/enums/opc-panel-call-record-state.enum';
import { IOpcPanelCallRecord } from '../../ts/models/session/opc-panel-call-record.model';
import { IOpcPanelUserRecord } from '../../ts/models/session/opc-panel-user-record.model';
import { PhoneCallsLogService } from './../../../phone/services/calls/phone-calls-log.service';
import { OpcPanelCallRecordVoicerecorder } from '../../ts/enums/opc-panel-call-record-voicerecorder.enum';

@Injectable({
	providedIn: 'root'
})
export class OpcPanelModalService {

	constructor(
		private readonly opcPanelCardService: OpcPanelCardService,
		private readonly phoneCallsLogService: PhoneCallsLogService,
		private readonly opcPanelCardCallService: OpcPanelCardCallService
	) { }

	formatCallRecordsToModal(callRecords: IOpcPanelCallRecord[]): IOpcPanelCallRecord[] {
		if (!callRecords || !callRecords.length) {
			return [];
		}

		const { role: loggedUserRole } = this.opcPanelCardService.getLoggedUserInfo();
		const transferCallRecords = this.opcPanelCardCallService.hasCallRecordByStateAndDirection(
			callRecords, OpcPanelCallRecordState.ALERTING, OpcPanelCallDirection.OUT
		);

		return callRecords.map(callRecordItem => {
			const { CallingName, CalledName, Calling, Called, State, Direction, VoiceRecorder } = callRecordItem;

			const updatedDirection = Direction === OpcPanelCallDirection.IN ? OpcPanelCallDirection.OUT : OpcPanelCallDirection.IN;
			const isInDirection = updatedDirection === OpcPanelCallDirection.IN;

			const isRecording = this.isRecording(VoiceRecorder);
			const calledName = this.formatCallingCalledName(CalledName, Called);
			const callingName = this.formatCallingCalledName(CallingName, Calling);
			const showTransferIcon = this.showTransferIcon(callRecordItem, loggedUserRole, transferCallRecords);
			const showRecordIcon = State === OpcPanelCallRecordState.ACTIVE && this.isRecordingAllowed(callRecordItem);

			const calledNameToShow = isInDirection ? callingName : calledName;
			const callingNameToShow = isInDirection ? calledName : callingName;

			return {
				...callRecordItem, Direction: updatedDirection, Calling: callingNameToShow,
				Called: calledNameToShow, showTransferIcon, showRecordIcon, isRecording
			};
		});
	}

	formatCallingCalledName(name: string | undefined, callingCalled: string): string {
		return name ? callingCalled + ' / ' + name : callingCalled;
	}

	showTransferIcon(callRecordItem: IOpcPanelCallRecord, loggedUserRole: OpcPanelUserRole, transferCallRecords: boolean): boolean {
		const { Direction, State } = callRecordItem;
		const directionStateValid = Direction === OpcPanelCallDirection.OUT && State === OpcPanelCallRecordState.ALERTING;

		return directionStateValid && this.canTransferAlerting(callRecordItem, loggedUserRole, transferCallRecords);
	}

	canTransferAlerting(callRecordItem: IOpcPanelCallRecord, loggedUserRole: OpcPanelUserRole, transferCallRecords: boolean): boolean {
		const isOperator = this.opcPanelCardService.isUserOperator(loggedUserRole);
		const isMatchingByLoggedUser = this.isMatchingLoggedUserData(callRecordItem);

		return (isOperator || isMatchingByLoggedUser) && transferCallRecords;
	}

	isMatchingLoggedUserData(callRecordItem: IOpcPanelCallRecord): boolean {
		const { abilis, user, interface: callInterface } = callRecordItem;
		const { abilisName: loggedUserAbilis, user: loggedUserUsername } = this.opcPanelCardService.getLoggedUserInfo();

		const loggedUserInterfaces = this.opcPanelCardService.getloggedUserAvailableInterfaces();

		const matchingByAbilis = loggedUserAbilis === abilis;
		const matchingByUsername = matchingByAbilis && user === loggedUserUsername;

		return (loggedUserInterfaces.includes(callInterface) && matchingByAbilis) || (matchingByAbilis && matchingByUsername);
	}

	formatCallRecordsDuration(callRecords: IOpcPanelCallRecord[]): IOpcPanelCallRecord[] {
		return callRecords.map(callRecord => {
			const { callStartTime } = callRecord;

			if (callStartTime === undefined) {
				return {...callRecord, callDuration: '0' };
			}

			const seconds = this.phoneCallsLogService.calculateCallDuration(callStartTime, callRecord);
			const callDuration = this.phoneCallsLogService.formatSecondsToHMS(seconds, true);

			return {...callRecord, callDuration };
		});
	}

	isRecordingAllowed(callRecordItem: IOpcPanelCallRecord): boolean {
		const { VoiceRecorder } = callRecordItem;
		const voiceRecorderNotDisallowed = VoiceRecorder && VoiceRecorder !== OpcPanelCallRecordVoicerecorder.DISALLOWED;

		return voiceRecorderNotDisallowed && (this.isRecording(VoiceRecorder) || this.isRecordingStopped(VoiceRecorder));
	}

	hasCallReadyForRecording(callRecords: IOpcPanelCallRecord[]): boolean {
		const activeState = OpcPanelCallRecordState.ACTIVE;

		return callRecords.some(callRecord => callRecord.State === activeState && this.isRecordingAllowed(callRecord));
	}

	isRecordingStopped(voiceRecorder: OpcPanelCallRecordVoicerecorder): boolean {
		return this.isMatchingRecordingState(voiceRecorder, OpcPanelCallRecordVoicerecorder.STOPPED);
	}

	isRecording(voiceRecorder: OpcPanelCallRecordVoicerecorder): boolean {
		return this.isMatchingRecordingState(voiceRecorder, OpcPanelCallRecordVoicerecorder.RECORDING);
	}

	isMatchingRecordingState(voiceRecorder: OpcPanelCallRecordVoicerecorder, state: OpcPanelCallRecordVoicerecorder): boolean {
		return voiceRecorder === state;
	}

	showRecordIcon(activeUserRecord: IOpcPanelUserRecord | undefined, callRecords: IOpcPanelCallRecord[]): boolean {
		if (activeUserRecord) {
			const { user, abilis, IfSubtype, interface: userInterface } = activeUserRecord;

			const isLoggedUser = this.isLoggedUser(user, abilis);
			const isSipInterface = userInterface === OpcPanelInterface.SIP;
			const isIaxInterface = userInterface === OpcPanelInterface.IAX;
			const isPotsInterface = this.opcPanelCardCallService.isPots(IfSubtype);

			if (isLoggedUser && (isPotsInterface || isSipInterface || isIaxInterface)) {
				return this.hasCallReadyForRecording(callRecords);
			}
		}

		return false;
	}

	isLoggedUser(user: string, abilis: string): boolean {
		const { abilisName: loggedUserAbilis, user: loggedUsername } = this.opcPanelCardService.getLoggedUserInfo();

		return user === loggedUsername && abilis === loggedUserAbilis;
	}

	getRecordCommand(callRecord: IOpcPanelCallRecord): OpcPanelCtiControl | null {
		const { VoiceRecorder, State } = callRecord;

		const isActiveCall = State === OpcPanelCallRecordState.ACTIVE;
		const isRecordingAllowed = this.isRecordingAllowed(callRecord);
		const isRecordingStopped = this.isRecordingStopped(VoiceRecorder);

		if (isActiveCall && isRecordingAllowed && isRecordingStopped) {
			return OpcPanelCtiControl.RECORD;
		}

		if (!isRecordingStopped) {
			return OpcPanelCtiControl.RECORDSTOP;
		}

		return null;
	}

	getTransferCallPriorityPayload(isPriorityCall: boolean): { OVERTAKE: 'YES' } | EmptyObject {
		return isPriorityCall ? { OVERTAKE: 'YES' } : {};
	}

	getTransferCallId(callRecord: IOpcPanelCallRecord | undefined, callRecords: IOpcPanelCallRecord[]): number | undefined {
		if (callRecord) {
			return callRecord.CallID;
		}

		const lastAlertingCall = this.opcPanelCardCallService.getLastAlertingCall(callRecords);

		if (lastAlertingCall) {
			return lastAlertingCall.CallID;
		}

		return undefined;
	}
}
