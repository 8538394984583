export enum SidebarFeatureNames {
	HOME = '',
	USER_SETTINGS = 'user-settings',
	ADMINISTRATION = 'administration',
	AUTOMATION = 'automation',
	NETWORK = 'network',
	PHONE = 'phone',
	TOOLS = 'tools',
	VIDEO = 'videorecorder',
	MANUAL = 'manual',
	MESSAGE = 'message'
}
