import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IAutomationProgramVoutDescription } from '../../ts/models/automation-program-vout-description.model';
import * as automationProgramEditActions from '../automation-program-edit.actions';

export const automationProgramEditDescriptionFeatureKey = 'automationProgramEditDescription';

export type IAutomationProgramDescriptionState = EntityState<IAutomationProgramVoutDescription>;

export const adapter: EntityAdapter<IAutomationProgramVoutDescription> = createEntityAdapter<IAutomationProgramVoutDescription>();

export const initialDescriptionState: IAutomationProgramDescriptionState = adapter.getInitialState();

const automationProgramDescriptionReducer = createReducer(
	initialDescriptionState,
	on(automationProgramEditActions.updateDeviceDescription, (state, { payload }) => adapter.upsertOne(payload.description, { ...state })),
	on(automationProgramEditActions.removeAllDescriptions, (state) => adapter.removeAll(state)),
);

export function AutomationProgramDescriptionReducer(state: IAutomationProgramDescriptionState | undefined, action: Action) {
	return automationProgramDescriptionReducer(state, action);
}

export const { selectAll: selectAllDescriptions } = adapter.getSelectors();
