import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../components/auth/services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import * as urlParse from 'url-parse';

@Injectable({
	providedIn: 'root'
})

export class GlobalInterceptor implements HttpInterceptor {

	forbiddenUrls = ['/login.json'];

	constructor(
		private authService: AuthService
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		return next.handle(req)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					const errorUrl = urlParse(error.url || '').pathname;
					// skip login forbidden URL
					if (this.forbiddenUrls.includes(errorUrl)) {
						return throwError(() => '');
					}
					// intercept forbidden status - handle action appropriate to user permission level
					if (error.status === 403) {
						return this.authService.setErrorAccessPermission$(this.authService.getUsername())
							.pipe(
								switchMap(() => throwError(() => error))
							);
					}

					return throwError(() => error);
				})
			);
	}
}
