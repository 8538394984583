import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { AbstractControl, FormGroup } from '@angular/forms';
import { first, map, shareReplay, take } from 'rxjs/operators';
import { IFormlyModel } from '../../ts/models/formly/formly-model.model';
import { MainUtilService } from '../../../../services/main-util.service';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { FormlyFunction } from '../../ts/enums/formly/formly-function.enum';
import { NetworkingAutomationService } from '../networking-automation.service';
import { FormlyUtilOperators } from '../../ts/enums/formly/formly-util-operators.enum';
import { IGeneratedFormContainer } from '../../ts/models/generated-form-container.model';
import { NetworkingAutomationSelectService } from '../networking-automation-select.service';
import { NetworkingAutomationHttpService } from '../http/networking-automation-http.service';
import { IGeneratedConfigurationValidator } from '../../ts/models/generated-configuration-validator.model';
import { IGeneratedConfigurationAsyncValidator } from '../../ts/models/generated-configuration-async-validator';

@Injectable({
	providedIn: 'root'
})
export class NetworkingAutomationFormlyService {

	private nonValidatedFieldRegExp = /custom-checkbox|custom-select|container-for-parameters/;

	constructor(
		private networkingAutomationService: NetworkingAutomationService,
		private networkingSelectService: NetworkingAutomationSelectService,
		private networkingAutomationHttpService: NetworkingAutomationHttpService
	) { }

	getParameterValidators(configurationItem: IGeneratedFormContainer): IGeneratedConfigurationValidator {
		const { name, type, regExp, errorMessage, asyncValidatorUrl, dependentFieldCondition } = configurationItem;

		if (regExp && !asyncValidatorUrl && !this.nonValidatedFieldRegExp.test(type)) {
			return {
				[name]: {
					message: () => errorMessage,
					expression: (control: AbstractControl, contorolFormReference: { form: FormGroup }) => {
						try {
							const isRegExpMatching = this.isRegExpMatching(regExp, control.value);

							if (dependentFieldCondition) {
								const form = contorolFormReference.form.root as FormGroup;

								return isRegExpMatching && this.isDependentFieldRegExpMatching(dependentFieldCondition, form);
							}

							return isRegExpMatching;
						} catch {
							return false;
						}
					}
				}
			};
		}

		return {};
	}

	getParameterAsyncValidators(configurationItem: IGeneratedFormContainer): IGeneratedConfigurationAsyncValidator {
		const { name, type, errorMessage, asyncValidatorUrl } = configurationItem;

		if (asyncValidatorUrl && !this.nonValidatedFieldRegExp.test(type)) {
			return {
				[name]: {
					message: errorMessage,
					expression: (control: AbstractControl) => this.fetchAsyncValidatorData$(control, configurationItem)
				}
			};
		}

		return {};
	}

	fetchAsyncValidatorData$(control: AbstractControl, configurationItem: IGeneratedFormContainer): Promise<boolean> {
		const { regExp, asyncValidatorUrl, asyncValidatorPath, responseSeparator } = configurationItem;

		return lastValueFrom(this.formatAsyncConfigItemValues$(asyncValidatorUrl as string, asyncValidatorPath as string)
			.pipe(
				take(1),
				first(),
				shareReplay(),
				map(asyncData => {
					const controlValue = control.value.toLowerCase();
					const updatedAsyncData = this.networkingSelectService.getAsyncSelectDataFormat(asyncData, responseSeparator);

					if (!regExp) {
						return !!updatedAsyncData.includes(controlValue);
					}

					const flags = NetworkingAutomationFormlyService.getFlagsFromRegExp(regExp);
					const updatedRegExp = NetworkingAutomationFormlyService.removeFlagsFromRegExp(regExp);
					const matchingAsyncData = !updatedAsyncData.includes(controlValue.toLowerCase());

					return !(matchingAsyncData && !new RegExp(updatedRegExp, flags).test(controlValue));
				})
			));
	}

	formatAsyncConfigItemValues$(asyncValidatorUrl: string, asyncValidatorPath: string): Observable<string[]> {
		return this.networkingAutomationHttpService.fetchAsyncConfigurationItemValues$(asyncValidatorUrl, asyncValidatorPath);
	}

	getParameterOnHooksSelectLogic(configurationItem: IGeneratedFormContainer): { onInit: (field: FormlyFieldConfig) => void } {
		const { asyncValidatorUrl, asyncValidatorPath, selectValues, selectValuesUI } = configurationItem;

		if (asyncValidatorUrl) {
			return {
				onInit: (field: FormlyFieldConfig) => {
					const dropdownData$ = this.networkingAutomationHttpService.fetchAsyncConfigurationItemValues$(
						asyncValidatorUrl, asyncValidatorPath as string
					).pipe(
						map(asyncValues => {
							const syncDropdownValues = this.networkingSelectService.getSelectOptions(selectValues, selectValuesUI);

							return [
								...syncDropdownValues,
								...this.networkingSelectService.formatAsyncSelectDropdownValues(asyncValues)
							];
						})
					);

					(field.templateOptions as FormlyTemplateOptions).options = dropdownData$;
				}
			};
		}

		return { onInit: () => { } };
	}

	getParameterHideExpressionOption<T>(advanced: boolean, hideOn: string): (modal: IFormlyModel, formState: IFormlyModel) => boolean {
		// advanced checkbox can be selected + hide on condition can be specified
		return (_, formState: IFormlyModel) => {
			const advancedParameterCondition = advanced ? !formState.mainModel.ADVANCED : false;
			// if hide on condition is not set, return advanced checkbox selected logic
			if (hideOn !== '') {
				// if hide condtion is set check to combine that logic with advanced checkbox logic
				const stateModalParameterValue = this.networkingAutomationService.getFormStateModalParameter(formState.mainModel, hideOn);
				const hideConditionResult = this.networkingAutomationService.getFormStateModalCondition(stateModalParameterValue, hideOn);

				return !advancedParameterCondition ? hideConditionResult : (advancedParameterCondition || hideConditionResult);
			}

			return advancedParameterCondition;
		};
	}

	isDependentFieldRegExpMatching(dependentFieldCondition: string, form: FormGroup): boolean {
		try {
			const [ownFieldName, functionName, dependentFieldName] = dependentFieldCondition.split(FormlyUtilOperators.SEPARATOR);
			const parameterValue = MainUtilService.pluckValueFromObject(ownFieldName, form.getRawValue());
			const dependentFieldValue = MainUtilService.pluckValueFromObject(dependentFieldName, form.getRawValue());

			return this.networkingAutomationService.isFormlyConditionMatching(
				functionName.toLowerCase() as FormlyFunction, dependentFieldValue?.toString(), parameterValue, []
			);
		} catch {
			return false;
		}
	}

	isRegExpMatching(regExp: string, valueToCheck: string): boolean {
		const flags = NetworkingAutomationFormlyService.getFlagsFromRegExp(regExp);
		const updatedRegExp = NetworkingAutomationFormlyService.removeFlagsFromRegExp(regExp);

		return new RegExp(updatedRegExp, flags).test(valueToCheck);
	}

	static getFlagsFromRegExp(regExp: string): string {
		const flagsIndex = regExp.lastIndexOf('$') + 1;

		return regExp.slice(flagsIndex, regExp.length);
	}

	static removeFlagsFromRegExp(regExp: string): string {
		const flags = this.getFlagsFromRegExp(regExp);

		if (!flags) { return regExp; }

		const flagsIndex = regExp.lastIndexOf('$') + 1;

		return regExp.slice(0, flagsIndex);
	}

	static getParameterClassName(className: string | undefined): string {
		return className ? className : '';
	}
}
