import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageNgrxService } from './language-ngrx.service';
import { AvailableLanguage } from './../../../../core/ts/enums/available-language.enum';
import moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class LanguageService {

	constructor(
		private readonly translateService: TranslateService,
		private readonly languageNgrxService: LanguageNgrxService
	) { }

	public setLanguage(language: string = AvailableLanguage.EN): void {
		// I set this here so that everywhere in the code it will conform with the current language
		moment.locale(language);
		this.translateService.use(language.toLowerCase());
	}

	public static get getAvailableLanguages(): AvailableLanguage[] {
		return [AvailableLanguage.EN, AvailableLanguage.IT];
	}

	public onSetLanguage(language: string): void {
		this.setLanguage(language);
		this.languageNgrxService.setDefaultLanguage(language);
	}
}
