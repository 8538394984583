import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RoutesEnum } from '../../../../ts/enums/routes.enum';
import { FooterComponent } from './../footer/footer.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SideBarService } from '../side-bar/services/side-bar.service';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '../../../../components/auth/services/auth.service';
import { AuthUserInfo } from '../../../../components/auth/ts/enums/user-auth.enum';
import { AuthNgrxService } from '../../../../components/auth/services/auth-ngrx.service';
import { IAbilisInformation } from '../../../../components/auth/ts/models/abilis-information.model';
import { SidebarFeatureNames } from '../../../../components/core/ts/enums/sidebar-feature-names.enum';

@UntilDestroy()
@Component({
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		FooterComponent
	],
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {

	public abilisInformation$: Observable<IAbilisInformation>;

	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly sideBarService: SideBarService,
		private readonly authNgrxService: AuthNgrxService
	) { }

	public ngOnInit(): void {
		this.checkToNavigateToLoginPage();

		this.sideBarService.setActiveSidebarItem(SidebarFeatureNames.HOME);
		this.abilisInformation$ = this.authNgrxService.selectFromAuthStore<IAbilisInformation>('selectAbilisInformation');
	}

	private checkToNavigateToLoginPage(): void {
		this.authService.watchUsernameChanges$()
			.pipe(
				filter(username => username === AuthUserInfo.ANONYMOUS),
				untilDestroyed(this)
			)
			.subscribe(() => this.router.navigateByUrl(`/${RoutesEnum.LOGIN}`));
	}
}
