import { Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { RoutesEnum } from '../../../../ts/enums/routes.enum';
import { IRecentCall } from '../ts/models/recent-call-table.model';
import { IRecentCallMode } from '../ts/enums/recent-call-mode.enum';
import { IAddressBookContact } from '../../../PHONE_FEATURES/address-book/ts/models/address-book-contact.model';
import { AddressBookContactType } from '../../../PHONE_FEATURES/address-book/ts/enums/address-book-contact-type.enum';
import { IAddressBookContactForm } from '../../../PHONE_FEATURES/address-book/ts/models/form/address-book-contact-form.model';
import { AddressBookContactUtilService } from '../../../PHONE_FEATURES/address-book/services/address-book-contact-util.service';

@Injectable({
	providedIn: 'root',
})
export class RecentCallService {

	private readonly router: Router;

	private adminRecentCalls: boolean;

	constructor(
		private readonly addressBookContactUtilService: AddressBookContactUtilService
	) {
		this.router = inject(Router);
	}

	/**
	 * Returns admin Recent Calls value
	 */
	public checkIfAdminRecentCalls(): boolean {
		return this.adminRecentCalls;
	}

	/**
	 * Sets if Recent Calls have admin role
	 * @param {boolean} haveAdminUserLevel
	 */
	public setAdminRecentCallsRole(haveAdminUserLevel: boolean): void {
		this.adminRecentCalls = haveAdminUserLevel;
	}

	/**
	 * Converts Address Book contact to Recent Calls item
	 * @param {IAddressBookContactForm} formValues Passed form value object
	 */
	public transformFormToContact(formValues: IAddressBookContactForm): IAddressBookContact {
		const { name, num, od, email, tag, type } = formValues;
		const updatedType = this.addressBookContactUtilService.translateContactTypeToOriginal(type);

		return { type: updatedType, name, num, od, email, tag } as IAddressBookContact;
	}

	/**
	 * Converts Recent Calls item to Address Book contact
	 * @param {IRecentCall | null} recentCallItem Passed Recent Call item
	 */
	public generateAddressBookContact(recentCallItem: IRecentCall | null): IAddressBookContact {
		const user = recentCallItem?.user || '';
		const correspondent = recentCallItem?.correspondent || '';
		const type = AddressBookContactType.PRIVATE;

		return { type, num: correspondent, name: '', od: '', tag: '', email: '', user } as IAddressBookContact;
	}

	/**
	 * Returns if Recent Calls component mode
	 */
	public getRecentCallsMode(): IRecentCallMode {
		if (this.checkIfAllRecentCallUrlMatches()) { return IRecentCallMode.ALL; }

		return this.checkIfOtherRecentCallUrlMatches() ? IRecentCallMode.OTHER : IRecentCallMode.SELF;
	}

	/**
	 * Checks if All Recent Calls Admin Url is matching
	 */
	private checkIfAllRecentCallUrlMatches(): boolean {
		return this.router.url.startsWith(`/${RoutesEnum.ADMINISTRATION};replaceUrl=true/(${RoutesEnum.ALL_RECENT_CALLS}`);
	}

	/**
	 * Checks if Other Recent Calls Admin Url is matching
	 */
	private checkIfOtherRecentCallUrlMatches(): boolean {
		const usersManagement = RoutesEnum.USERS_MANAGEMENT;
		const url = `/${RoutesEnum.ADMINISTRATION};replaceUrl=true/(${usersManagement}:${usersManagement};`;

		return this.router.url.startsWith(url);
	}
}
