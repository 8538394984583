export enum RecentCallTableKey {
	USER = 'user',
	CHECKBOX_STATE = 'checkboxState',
	DATETIME_UI = 'datetimeUI',
	CORRESPONDENT = 'correspondent',
	RESULT_ICON = 'resultIcon',
	RESULT_UI = 'resultUI',
	DURATION_UI = 'durationUI',
	DIRECTION_UI = 'directionUI'
}
