import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MainUtilService } from '../../../services/main-util.service';
import { NetworkingInfoQualityItem } from '../ts/types/networking-info-quality-item.type';
import { INetworkingInfoLegendLabel } from '../ts/models/networking-info-legend-label.model';
import { NetworkingInfoSdwanBasicLegend } from '../ts/enums/networking-info-sdwan-basic-legend.enum';
import { INetworkingInfoQualityData } from '../ts/models/quality/networking-info-quality-data.model';
import { INetworkingInfoQualityInfo } from '../ts/models/quality/networking-info-quality-info.model';
import { INetworkingInfoQualityParam } from '../ts/models/quality/networking-info-quality-param.model';
import { INetworkingInfoResourceItem } from '../ts/models/resource/networking-info-resource-item.model';
import { INetworkingInfoQualitySample } from '../ts/models/quality/networking-info-quality-sample.model';
import { NetworkingInfoSdwanAdvancedLegend } from '../ts/enums/networking-info-sdwan-advanced-legend.enum';
import { INetworkingInfoQualityResponse } from '../ts/models/quality/networking-info-quality-response.model';
import { NetworkingInfoQuality, NUMBER_OF_NETWORKING_UNITS, SDWAN_UNIT_WIDTH } from '../util/networking-info-util';
import { INetworkingInfoQualityAdvancedData } from '../ts/models/quality/networking-info-quality-advanced-data.model';

@Injectable({
	providedIn: 'root'
})
export class NetworkingInfoSdwanService {

	private sdwanControlUnchanged = new Subject<void>();

	constructor() { }

	emitSdwanControlUnchanged(): void {
		this.sdwanControlUnchanged.next();
	}

	watchSdwanControlUnchanged$(): Observable<void> {
		return this.sdwanControlUnchanged.asObservable();
	}

	getAdvancedZoomDataFormat(overallData: INetworkingInfoQualityAdvancedData[], downloadStripe: boolean): INetworkingInfoQualityData[] {
		if (downloadStripe) {
			return overallData.map(item => {
				const { timestamp, inFbw: fbw, inJit: jit, inMiss: miss, inQualityLevel: quality, inTt: tt } = item;

				return { timestamp, fbw, jit, miss, quality, tt };
			});
		}

		return overallData.map(item => {
			const { timestamp, outFbw: fbw, outJit: jit, outMiss: miss, outQualityLevel: quality, outTt: tt } = item;

			return { timestamp, fbw, jit, miss, quality, tt };
		});
	}

	getLatestQualityValue(overallData: INetworkingInfoQualityData[]): INetworkingInfoQualityData {
		return overallData[overallData.length - 1];
	}

	getLastSampleFromResponse(response: INetworkingInfoQualityResponse): INetworkingInfoQualitySample | null {
		const haveError = response.hasOwnProperty('Error') || response.hasOwnProperty('error');

		if (!haveError) {
			const { Samples } = response;

			if (Samples.length) {
				return Samples[Samples.length - 1];
			}
		}

		return null;
	}

	getLatestUnitValues(overallData: INetworkingInfoQualityData[]): number[] {
		const { miss, fbw, jit, tt } = this.getLatestQualityValue(overallData);

		return [miss, fbw, jit, tt];
	}

	getSdwanInfoFromPath(
		pathNumber: number, resource: INetworkingInfoResourceItem,
		availableNetworkingResources: INetworkingInfoResourceItem[] | null
	): string {
		const description = this.getMatchingTunnelDescription(pathNumber, resource, availableNetworkingResources);

		const id = resource.id;

		if (availableNetworkingResources) {
			// console.log('bbbbb', availableNetworkingResources);
		}

		return !description ? `PATH ${pathNumber}` : description;
	}

	getSdwanInfoFromPathForChart(
		resource: INetworkingInfoResourceItem,
		availableNetworkingResources: INetworkingInfoResourceItem[] | null,
		text: string
	): INetworkingInfoResourceItem {

		if (availableNetworkingResources) {
			for (const item of availableNetworkingResources) {
				if (`${item.id} ${item.des}` === text) {
					return item;
				}
			}
		}
		return resource;
	}

	getMatchingTunnelDescription(
		pathNumber: number,
		resource: INetworkingInfoResourceItem,
		availableNetworkingResources: INetworkingInfoResourceItem[] | null
	): string {

		if (!resource || typeof resource.id !== 'string') {
			console.error('Invalid resource or resource.id');
			return `PATH ${pathNumber}`; // Ritorna "PATH X" come fallback
		}

		const resourceId = resource.id.toLowerCase();
		const matchingResource = availableNetworkingResources?.find(
			availableResource => availableResource.id.toLowerCase() === resourceId
		);

		if (!matchingResource || !matchingResource.paths) {
			return `PATH ${pathNumber}`; // Ritorna "PATH X" come fallback
		}

		const path = matchingResource.paths[pathNumber - 1];
		if (!path || !path.out) {
			return `PATH ${pathNumber}`; // Ritorna "PATH X" come fallback
		}

		if (path.out.descr) {
			return `${path.out.ipres} ${path.out.descr}`;
		}


		return path.out.descr || `PATH ${pathNumber}`; // Restituisce la descrizione del percorso o "PATH X" come fallback
	}



	getZoomedLeftOffset(index: number, overallDataLength: number): number {
		const leftOffset = (NUMBER_OF_NETWORKING_UNITS - overallDataLength) + index * SDWAN_UNIT_WIDTH;

		return leftOffset < 49 ? 49 : leftOffset > 930 ? 930 : leftOffset;
	}

	getZoomedData(
		zoomIndex: number, overallData: INetworkingInfoQualityAdvancedData[]
	): { zoomData: INetworkingInfoQualityAdvancedData[]; zoomStartIndex: number } {
		const zoomStartIndex = NUMBER_OF_NETWORKING_UNITS - overallData.length;

		if (zoomIndex !== undefined && overallData) {
			const endIndex = zoomIndex + 30;
			const startIndex = zoomIndex - 10;

			if (zoomIndex < 20) {
				return { zoomData: overallData.slice(0, 40), zoomStartIndex };
			}

			if (zoomIndex > NUMBER_OF_NETWORKING_UNITS - 20) {
				const startEndingIndex = NUMBER_OF_NETWORKING_UNITS - 40;
				const zoomStartEndIndex = zoomStartIndex + startEndingIndex;

				return { zoomData: overallData.slice(startEndingIndex, NUMBER_OF_NETWORKING_UNITS), zoomStartIndex: zoomStartEndIndex };
			}

			return { zoomData: overallData.slice(startIndex, endIndex), zoomStartIndex: zoomStartIndex + startIndex - 1 };
		}

		return { zoomData: [], zoomStartIndex };
	}

	getLatestDatetime(qualityReportData: INetworkingInfoQualityResponse): string {
		const lastSample = this.getLastSampleFromResponse(qualityReportData);

		if (!lastSample) { return ''; }

		const { TimeStamp } = lastSample;
		const month = MainUtilService.getLuxonDatetime(TimeStamp, 'MMM');
		const restOfDatetime = MainUtilService.getLuxonDatetime(TimeStamp, 'dd/yyyy HH:mm:ss');

		return `{{COMMON.short${month}}} ${restOfDatetime}`;
	}

	getLatestSdwanScore(qualityReportData: INetworkingInfoQualityResponse, path: number | undefined, isOverall: boolean): number {
		const lastSample = this.getLastSampleFromResponse(qualityReportData);

		if (!isOverall) {
			if (lastSample && path && lastSample.Path[path - 1]) {
				const pathDataset = lastSample.Path[path - 1];

				return NetworkingInfoSdwanService.getTunnelPathValues(pathDataset).QualityLevel;
			}

			return NetworkingInfoQuality.DOWN;
		}

		if (lastSample) {
			return NetworkingInfoSdwanService.getTunnelPathValues(lastSample.Tunnel).QualityLevel;
		}

		return NetworkingInfoQuality.DOWN;
	}

	getLegendItemsVisibility(legendItems: INetworkingInfoLegendLabel[]): boolean[] {
		return legendItems.map(legendItem => !legendItem.isDisabled);
	}

	getUpdatedLegendItems(
		label: NetworkingInfoSdwanBasicLegend | NetworkingInfoSdwanAdvancedLegend, itemsData: INetworkingInfoLegendLabel[]
	): INetworkingInfoLegendLabel[] {
		return itemsData.map(item => item.label === label ? { ...item, isDisabled: !item.isDisabled } : item);
	}

	static getTunnelPathValues(qualityInfo: INetworkingInfoQualityInfo | null): INetworkingInfoQualityParam {
		if (!qualityInfo || !Object.keys(qualityInfo).length) {
			return NetworkingInfoSdwanService.getBasicNaDataset();
		}

		const { IN, OUT } = qualityInfo;
		const { QualityLevel: qualityLevelIn, MISS: missIn, FBW: fbwIn, JIT: jitterIn, TT: ttIn } = Object();
		const { QualityLevel: qualityLevelOut, MISS: missOut, FBW: fbwOut, JIT: jitterOut, TT: ttOut } = Object(OUT);

		const fbw = NetworkingInfoSdwanService.getWorstSampleValue(fbwIn, fbwOut);
		const miss = NetworkingInfoSdwanService.getWorstSampleValue(missIn, missOut);
		const tripTime = NetworkingInfoSdwanService.getWorstSampleValue(ttIn, ttOut);
		const jitter = NetworkingInfoSdwanService.getWorstSampleValue(jitterIn, jitterOut);
		const qualityLevel = NetworkingInfoSdwanService.getWorstSampleValue(qualityLevelIn, qualityLevelOut);

		return { QualityLevel: qualityLevel, MISS: miss, FBW: fbw, JIT: jitter, TT: tripTime };
	}

	public static getAdvancedNaDataset(timestamp: string): NetworkingInfoQualityItem {
		const naValue = NetworkingInfoQuality.DOWN;

		return {
			inQualityLevel: naValue, inFbw: naValue, inJit: naValue, inMiss: naValue, inTt: naValue,
			outQualityLevel: naValue, outFbw: naValue, outJit: naValue, outMiss: naValue, outTt: naValue, timestamp
		};
	}

	public static getBasicNaDataset(): INetworkingInfoQualityParam {
		const naValue = NetworkingInfoQuality.DOWN;

		return { QualityLevel: naValue, MISS: naValue, FBW: naValue, JIT: naValue, TT: naValue };
	}

	static getWorstSampleValue(firstValue: number = 0, secondValue: number = 0): number {
		if (firstValue === secondValue) { return firstValue; }

		const areBothGoodSamples = firstValue >= NetworkingInfoQuality.GOOD && secondValue >= NetworkingInfoQuality.GOOD;

		return areBothGoodSamples ? Math.max(firstValue, secondValue) : Math.min(firstValue, secondValue);
	}

	getInitialBasicLegendItems(): INetworkingInfoLegendLabel[] {
		return [
			{
				className: 'legend__good', isDisabled: false,
				label: NetworkingInfoSdwanBasicLegend.GOOD, translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkGood'
			},
			{
				className: 'legend__fair', isDisabled: false,
				label: NetworkingInfoSdwanBasicLegend.FAIR, translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkFair'
			},
			{
				className: 'legend__bad', isDisabled: false,
				label: NetworkingInfoSdwanBasicLegend.POOR, translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkPoor'
			},
			{
				className: 'legend__inactive', isDisabled: false,
				label: NetworkingInfoSdwanBasicLegend.INACTIVE, translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkInactive'
			},
			{
				className: 'legend__noservice', isDisabled: false,
				label: NetworkingInfoSdwanBasicLegend.OUT_OF_SERVICE, translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkLocalFault'
			},
			{
				className: 'legend__error', isDisabled: false,
				label: NetworkingInfoSdwanBasicLegend.ERROR, translationLabel: 'NETWORK.NETWORK-INFO.networkError'
			}
		];
	}

	getInitialAdvancedLegendItems(): INetworkingInfoLegendLabel[] {
		return [
			{
				className: 'legend__miss', isDisabled: false, label: NetworkingInfoSdwanAdvancedLegend.LOST_PACKETS,
				translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkSdwanAdvLostPack'
			},
			{
				className: 'legend__congestion', isDisabled: false, label: NetworkingInfoSdwanAdvancedLegend.LINE_CONGESTION,
				translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkSdwanAdvLineCong'
			},
			{
				className: 'legend__jitter', isDisabled: false, label: NetworkingInfoSdwanAdvancedLegend.JITTER,
				translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkSdwanAdvJitter'
			},
			{
				className: 'legend__tt', isDisabled: false, label: NetworkingInfoSdwanAdvancedLegend.TRIP_TIME_DELAYED,
				translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkSdwanAdvTTDel'
			},
			{
				className: 'legend__inactive', isDisabled: false, label: NetworkingInfoSdwanAdvancedLegend.INACTIVE,
				translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkInactive'
			},
			{
				className: 'legend__noservice', isDisabled: false, label: NetworkingInfoSdwanAdvancedLegend.OUT_OF_SERVICE,
				translationLabel: 'NETWORK.NETWORK-INFO.outsideNetworkLocalFault'
			},
			{
				className: 'legend__error', isDisabled: false,
				label: NetworkingInfoSdwanBasicLegend.ERROR, translationLabel: 'NETWORK.NETWORK-INFO.networkError'
			}
		];
	}
}
