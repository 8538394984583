import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TabComponent } from '../tab/tab.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LangChangeEvent } from '@ngx-translate/core';
import { GlobalService } from '../../../../../../services/others/global.service';
import { NestedTranslationPipe } from './../../../../../pipes/nested-translation.pipe';
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	selector: 'app-tab-header-render',
	standalone: true,
	imports: [
		CommonModule,
		TooltipModule,
		NestedTranslationPipe
	],
	templateUrl: './tab-header-render.component.html',
	styleUrls: ['./tab-header-render.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class TabHeaderRenderComponent implements OnInit {

	@Input() tabItems: TabComponent[] | null;

	@Output() selectedTabIndexEvent = new EventEmitter();

	public languageChanged$: Observable<LangChangeEvent>;

	constructor(
		private readonly globalService: GlobalService
	) {}

	public ngOnInit(): void {
		this.languageChanged$ = this.globalService.getLanguageChangeState$();
	}

	public onSelectTab(event: Event, tabIndex: number): void {
		event.preventDefault();

		this.selectedTabIndexEvent.emit(tabIndex);
	}
}
