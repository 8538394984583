import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormArray, FormBuilder } from '@angular/forms';
import { IUserPreferenceItem } from '../../ts/models/user-preferences-item.model';
import { PrivilegeSection } from '../../ts/enums/user-preferences-privilege-section.enum';
import { GenericTableBuilder } from '../../../../components/networking/common/classes/table-builder.class';
import { IGenericTableRow } from '../../../../components/ui-components/generic-table/ts/models/generic-table-row';
import { GenericTableEdit } from '../../../../components/ui-components/generic-table/ts/enums/generic-table-edit.enum';
import { GenericFormValue } from '../../../../components/ui-components/generic-table/ts/types/generic-form-value.type';
import { IGenericTableHeader } from '../../../../components/ui-components/generic-table/ts/models/generic-table-header';
import { GenericTableFilter } from '../../../../components/ui-components/generic-table/ts/enums/generic-table-filter.enum';
import { IGenericTableHeaderData } from '../../../../components/ui-components/generic-table/ts/models/generic-table-header-data.model';
import { IGenericUnsetSelectedRow } from '../../../../components/ui-components/generic-table/ts/models/generic-unset-selected-row.model';

@Injectable({
	providedIn: 'root',
})
export class PrivilegesPreferencesTableService extends GenericTableBuilder {

	private errorMessageState: BehaviorSubject<string> = new BehaviorSubject('');
	private unsetSelectedRowState = new BehaviorSubject({ unsetSelectedRow: false });
	private selectedItem: BehaviorSubject<IUserPreferenceItem | null> = new BehaviorSubject<IUserPreferenceItem | null>(null);
	private selectedPrivilegeSection: BehaviorSubject<PrivilegeSection | null> = new BehaviorSubject<PrivilegeSection | null>(null);

	constructor(
		private formBuilder: FormBuilder
	) {
		super();
	}

	private readonly checkboxStyle = 'justify-content: center';
	private readonly headerContainerStyle = 'flex-direction: column';
	private readonly headerIconCustomStyle = 'width: 16px; height: 16px;';
	private readonly headerCellStyle = 'display: flex; justify-content: center; padding-left: 8px !important;';

	buildTableHeaders(): IGenericTableHeader[] {
		return [
			{ header: { headerName: 'ADMIN.PRIV.tblhdrActive' }, sort: { useSort: false } },
			{
				header: { headerName: 'ADMIN.PRIV.tblhdrUserName' },
				sort: { useSort: false },
				filter: {
					useFilter: true, filterComponent: GenericTableFilter.INPUT,
					input: { inputRangeTitle: '', fieldWidth: 150, formArrayIndex: 0 }
				}
			},
			{
				header: { headerName: 'ADMIN.PRIV.tblhdrName', headerStyle: 'min-width: 150px' },
				sort: { useSort: false },
				filter: {
					useFilter: true, filterComponent: GenericTableFilter.INPUT,
					input: { inputRangeTitle: '', fieldWidth: 150, formArrayIndex: 1 }
				}
			},
			{
				header: {
					headerName: 'ADMIN.PRIV.tblhdrNetwork', headerStyle: this.headerCellStyle,
					headerIconStyle: this.headerIconCustomStyle, headerContainerStyle: this.headerContainerStyle,
					headerIcon: true, headerIconPath: 'network.svg'
				},
				sort: { useSort: false }
			},
			{
				header: {
					headerName: 'ADMIN.PRIV.tblhdrPhone', headerStyle: this.headerCellStyle,
					headerIconStyle: this.headerIconCustomStyle, headerContainerStyle: this.headerContainerStyle,
					headerIcon: true, headerIconPath: 'phone.svg'
				},
				sort: { useSort: false }
			},
			{
				header: {
					headerName: 'ADMIN.PRIV.tblhdrAuto', headerStyle: this.headerCellStyle,
					headerIconStyle: this.headerIconCustomStyle, headerContainerStyle: this.headerContainerStyle,
					headerIcon: true, headerIconPath: 'automation.svg'
				},
				sort: { useSort: false }
			},
			{
				header: {
					headerName: 'ADMIN.PRIV.tblhdrVideo', headerStyle: this.headerCellStyle,
					headerIconStyle: this.headerIconCustomStyle, headerContainerStyle: this.headerContainerStyle,
					headerIcon: true, headerIconPath: 'recorder.svg'
				},
				sort: { useSort: false }
			},
			{
				header: {
					headerName: 'ADMIN.PRIV.tblhdrTools', headerStyle: this.headerCellStyle,
					headerIconStyle: this.headerIconCustomStyle, headerContainerStyle: this.headerContainerStyle,
					headerIcon: true, headerIconPath: 'tool.svg'
				},
				sort: { useSort: false }
			},
			{
				header: { headerName: '', isAction: true },
				sort: { useSort: false }
			}
		];
	}

	buildTableRows(): IGenericTableRow[] {
		return [
			{
				cell: { cellName: 'ACT.VALUE', visibleCellCondition: true },
				edit: {
					disableCondition: 'ACT.readonly---EQUAL---true',
					editComponent: GenericTableEdit.CHECKBOX, editMode: false,
					editVisibleCondition: true, immediatelyVisible: true, immediatelyVisibleValueKey: 'ACT.VALUE',
				},
			},
			{
				cell: { cellName: 'USER.VALUE', visibleCellCondition: true },
				edit: { editMode: false },
			},
			{
				cell: { cellName: 'REAL-NAME.VALUE', visibleCellCondition: true },
				edit: { editMode: false },
			},
			{
				cell: {
					checkboxStyle: this.checkboxStyle, cellName: 'networkImage',
					visibleCellCondition: 'hiddenGuiNetwork---EQUAL---false', isImage: true,
					imageVisiblePerRow: true, allowPropagation: () => true, disableImage: 'HTTP-GUI-NETWORK.VALUE---EQUAL---false',
					cellClick: (selectedItem: IUserPreferenceItem) => this.handleImageClick(selectedItem, PrivilegeSection.NETWORK)
				},
				edit: { editMode: false }
			},
			{
				cell: {
					checkboxStyle: this.checkboxStyle, cellName: 'phoneImage',
					visibleCellCondition: 'hiddenGuiPhone---EQUAL---false', isImage: true,
					imageVisiblePerRow: true, allowPropagation: () => true, disableImage: 'HTTP-GUI-PHONE.VALUE---EQUAL---false',
					cellClick: (selectedItem: IUserPreferenceItem) => this.handleImageClick(selectedItem, PrivilegeSection.PHONE)
				},
				edit: { editMode: false }
			},
			{
				cell: {
					checkboxStyle: this.checkboxStyle, cellName: 'automationImage',
					visibleCellCondition: 'hiddenGuiAuto---EQUAL---false', isImage: true,
					imageVisiblePerRow: true, allowPropagation: () => true, disableImage: 'HTTP-GUI-AUTO.VALUE---EQUAL---false',
					cellClick: (selectedItem: IUserPreferenceItem) => this.handleImageClick(selectedItem, PrivilegeSection.AUTO)
				},
				edit: { editMode: false }
			},
			{
				cell: {
					checkboxStyle: this.checkboxStyle, cellName: 'videoImage',
					visibleCellCondition: 'hiddenGuiVideo---EQUAL---false', isImage: true,
					imageVisiblePerRow: true, allowPropagation: () => true, disableImage: 'HTTP-GUI-VIDEO.VALUE---EQUAL---false',
					cellClick: (selectedItem: IUserPreferenceItem) => this.handleImageClick(selectedItem, PrivilegeSection.VIDEO)
				},
				edit: { editMode: false }
			},
			{
				cell: {
					checkboxStyle: this.checkboxStyle, cellName: 'toolsImage',
					visibleCellCondition: 'hiddenGuiTool---EQUAL---false', isImage: true,
					imageVisiblePerRow: true, allowPropagation: () => true, disableImage: 'HTTP-GUI-TOOLS.VALUE---EQUAL---false',
					cellClick: (selectedItem: IUserPreferenceItem) => this.handleImageClick(selectedItem, PrivilegeSection.TOOL)
				},
				edit: { editMode: false }
			},
			{
				cell: { cellName: '', visibleCellCondition: true, isAction: true },
				edit: { editMode: false }
			}
		];
	}

	handleImageClick(selectedItem: IUserPreferenceItem, selectedPrivilegeSection: PrivilegeSection): void {
		this.setSelectedItem(selectedItem);
		this.setSelectedPrivilegeSection(selectedPrivilegeSection);
	}

	buildForm(): FormArray {
		return this.formBuilder.array([]);
	}

	buildHeaderForm(): FormArray {
		return this.formBuilder.array([
			this.formBuilder.control(''),
			this.formBuilder.control('')
		]);
	}

	updateHeaderFormValues(headerFilterFormValues: GenericFormValue[]): IGenericTableHeaderData[] {
		return headerFilterFormValues.map((headerItem, index) => {
			const key = index === 0 ? 'USER.VALUE' : 'REAL-NAME.VALUE';

			return { key, value: headerItem };
		});
	}

	setUnselectedRowState(rowState: boolean): void {
		this.unsetSelectedRowState.next({ unsetSelectedRow: rowState });
	}

	getUnsetSelectedRowState$(): Observable<IGenericUnsetSelectedRow> {
		return this.unsetSelectedRowState;
	}

	setResponseErrorMessage(errorMessage: string = ''): void {
		this.errorMessageState.next(errorMessage);
	}

	getErrorMessageState$(): Observable<string> {
		return this.errorMessageState.asObservable();
	}

	setSelectedPrivilegeSection(privilegeSection: PrivilegeSection | null): void {
		this.selectedPrivilegeSection.next(privilegeSection);
	}

	getSelectedPrivilegeSection$(): Observable<PrivilegeSection | null> {
		return this.selectedPrivilegeSection.asObservable();
	}

	setSelectedItem(privilegeSection: IUserPreferenceItem | null): void {
		this.selectedItem.next(privilegeSection);
	}

	getSelectedItem$(): Observable<IUserPreferenceItem | null> {
		return this.selectedItem.asObservable();
	}
}
