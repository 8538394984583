import { Injectable } from '@angular/core';
import { AutomationLineDevice } from '../../ts/types/automation-line-device.type';
import { AutomationAnalogInputs } from '../../ts/enums/automation-analog-inputs.enum';
import { AutomationAnalogAlarms } from '../../ts/enums/automation-analog-alarms.enum';
import { AutomationAnalogOutputs } from '../../ts/enums/automation-analog-outputs.enum';
import { AutomationDigitalInputs } from '../../ts/enums/automation-digital-inputs.enum';
import { AutomationDigitalOutputs } from '../../ts/enums/automation-digital-outputs.enum';
import { AutomationLineDeviceType } from '../../ts/enums/automation-line-device-type.enum';
import { AutomationDeviceCustomClass } from '../../ts/enums/automation-device-custom-class.enum';
import { AutomationAnalogInputAlarms } from '../../ts/enums/automation-analog-input-alarms.enum';
import { AutomationAnalogOutputAlarms } from '../../ts/enums/automation-analog-output-alarms.enum';

@Injectable({
	providedIn: 'root'
})
export class AutomationDeviceClassService {

	constructor() { }

	translateDeviceClassToCustomType(deviceSubtype: AutomationLineDevice): AutomationDeviceCustomClass {
		switch (deviceSubtype) {
		case AutomationDigitalInputs.DIGITAL_INPUTS:
		case AutomationDigitalInputs.DIGITAL_INPUT:
		case AutomationDigitalInputs.DIGITAL_INPUT_BULBS:
		case AutomationDigitalInputs.DIGITAL_INPUT_BULB:
			return AutomationDeviceCustomClass.DIGITAL_INPUT;
		case AutomationDigitalOutputs.DIGITAL_OUTPUTS:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_BULBS:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_BULB:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_SWITCHES:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_SWITCH:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_TOGGLES:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_TOGGLE:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_PULSES:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_PULSE:
			return AutomationDeviceCustomClass.DIGITAL_OUTPUT;
		case AutomationAnalogInputs.ANALOG_INPUTS:
		case AutomationAnalogInputs.ANALOG_INPUT:
			return AutomationDeviceCustomClass.ANALOG_INPUT;
		case AutomationAnalogAlarms.ANALOG_ALARMS:
		case AutomationAnalogAlarms.ANALOG_ALARM:
			return AutomationDeviceCustomClass.ANALOG_ALARM;
		case AutomationAnalogInputAlarms.ANALOG_INPUTS_ALARMS:
		case AutomationAnalogInputAlarms.ANALOG_INPUTS_ALARM:
		case AutomationAnalogInputAlarms.ANALOG_INPUT_ALARM:
			return AutomationDeviceCustomClass.ANALOG_INPUT_ALARM;
		case AutomationAnalogOutputAlarms.ANALOG_OUTPUTS_ALARMS:
		case AutomationAnalogOutputAlarms.ANALOG_OUTPUTS_ALARM:
		case AutomationAnalogOutputAlarms.ANALOG_OUTPUT_ALARM:
			return AutomationDeviceCustomClass.ANALOG_OUTPUT_ALARM;
		case AutomationAnalogOutputs.ANALOG_OUTPUTS:
		case AutomationAnalogOutputs.ANALOG_OUTPUT:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_SLIDERS:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_SLIDER:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_SPINNERS:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_SPINNER:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_MONITORS:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_MONITOR:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_MONITOR_CONTROLS:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_MONITOR_CONTROL:
			return AutomationDeviceCustomClass.ANALOG_OUTPUT;
		}
	}

	getLineDeviceName(lineDeviceType: AutomationLineDevice): AutomationLineDeviceType {
		switch (lineDeviceType) {
		case AutomationDigitalInputs.DIGITAL_INPUTS:
		case AutomationDigitalInputs.DIGITAL_INPUT:
		case AutomationDigitalInputs.DIGITAL_INPUT_BULBS:
		case AutomationDigitalInputs.DIGITAL_INPUT_BULB:
			return AutomationLineDeviceType.DIN;
		case AutomationDigitalOutputs.DIGITAL_OUTPUTS:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_BULBS:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_BULB:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_SWITCHES:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_SWITCH:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_TOGGLES:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_TOGGLE:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_PULSES:
		case AutomationDigitalOutputs.DIGITAL_OUTPUT_PULSE:
			return AutomationLineDeviceType.DOUT;
		case AutomationAnalogInputs.ANALOG_INPUTS:
		case AutomationAnalogInputs.ANALOG_INPUT:
			return AutomationLineDeviceType.AIN;
		case AutomationAnalogAlarms.ANALOG_ALARMS:
		case AutomationAnalogAlarms.ANALOG_ALARM:
			return AutomationLineDeviceType.ALARM;
		case AutomationAnalogInputAlarms.ANALOG_INPUTS_ALARMS:
		case AutomationAnalogInputAlarms.ANALOG_INPUTS_ALARM:
		case AutomationAnalogInputAlarms.ANALOG_INPUT_ALARM:
			return AutomationLineDeviceType.ALMIN;
		case AutomationAnalogOutputAlarms.ANALOG_OUTPUTS_ALARMS:
		case AutomationAnalogOutputAlarms.ANALOG_OUTPUTS_ALARM:
		case AutomationAnalogOutputAlarms.ANALOG_OUTPUT_ALARM:
			return AutomationLineDeviceType.ALMOUT;
		case AutomationAnalogOutputs.ANALOG_OUTPUTS:
		case AutomationAnalogOutputs.ANALOG_OUTPUT:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_SLIDERS:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_SLIDER:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_SPINNERS:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_SPINNER:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_MONITORS:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_MONITOR:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_MONITOR_CONTROLS:
		case AutomationAnalogOutputs.ANALOG_OUTPUT_MONITOR_CONTROL:
			return AutomationLineDeviceType.AOUT;
		}
	}
}
