import { Injectable } from '@angular/core';
import { RoutesEnum } from './../../ts/enums/routes.enum';
import { CanLoad, CanActivate, Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class UserSettingsGuardService implements CanLoad, CanActivate {

	constructor(
		private readonly router: Router
	) { }

	public canLoad(): boolean {
		return this.isLoginPage();
	}

	public canActivate(): boolean {
		return this.isLoginPage();
	}

	private isLoginPage(): boolean {
		return this.router.url !== `/${RoutesEnum.LOGIN}`;
	}
}
