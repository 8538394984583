import { Observable, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PhoneUtilService } from './../phone-util.service';
import { PhoneHttpUtilService } from './phone-http-util.service';
import { PhoneCallsService } from './../calls/phone-calls.service';
import { environment } from './../../../../../environments/environment';
import { IPhoneCallRecord } from './../../ts/models/phone-call-record.model';
import { IPhoneModePayload } from './../../ts/models/phone-mode-payload.model';
import { PhoneNgrxSelectorService } from './../ngrx/phone-ngrx-selector.service';
import { switchMap, filter, map, take, takeWhile, exhaustMap } from 'rxjs/operators';
import { IRecentCall } from '../../../PHONE_FEATURES/recent-calls/ts/models/recent-call-table.model';
import { RecentCallHttpService } from '../../../PHONE_FEATURES/recent-calls/services/http/recent-call-http.service';
import { IUserPreferencesActionResponse } from '../../../user-preferences/ts/models/user-preferences-action-response.model';
import * as phoneOperators from '../../utils/phone-operators';

@Injectable({
	providedIn: 'root'
})
export class PhoneHttpService {

	private readonly opcPanelCtiControlUrl: string = environment.abilisUrl + '/sys/opc/CtiControl.json';
	private readonly setUserPreferencesUrl: string = environment.abilisUrl + '/sys/user/cti_pref_set.json';

	constructor(
		private readonly http: HttpClient,
		private readonly phoneCallsService: PhoneCallsService,
		private readonly phoneHttpUtilService: PhoneHttpUtilService,
		private readonly recentCallHttpService: RecentCallHttpService,
		private readonly phoneNgrxSelectorService: PhoneNgrxSelectorService
	) { }

	public setCtiControl$(CtiControl: IPhoneModePayload): Observable<IUserPreferencesActionResponse | null> {
		const errorHandler = this.phoneHttpUtilService.handleActionErrors.bind(this.phoneHttpUtilService);
		const actionHandler = this.phoneHttpUtilService.handleSetActionResponse.bind(this.phoneHttpUtilService);

		return this.http.post<IUserPreferencesActionResponse>(this.opcPanelCtiControlUrl, { CtiControl })
			.pipe(
				phoneOperators.handlePhoneActionResponse$(actionHandler, errorHandler)
			);
	}

	public setUserPreferences$(payload: IPhoneModePayload): Observable<IUserPreferencesActionResponse | null> {
		const errorHandler = this.phoneHttpUtilService.handleActionErrors.bind(this.phoneHttpUtilService);
		const actionHandler = this.phoneHttpUtilService.handleSetActionResponse.bind(this.phoneHttpUtilService);

		return this.http.post<IUserPreferencesActionResponse>(this.setUserPreferencesUrl, { CtiPreferences: [payload] })
			.pipe(
				phoneOperators.handlePhoneActionResponse$(actionHandler, errorHandler)
			);
	}

	public pollFetchCallRecords$(): Observable<IPhoneCallRecord[]> {
		return timer(0, 1_000)
			.pipe(
				switchMap(() => this.phoneNgrxSelectorService.selectFromPhoneStore$('fetchError') as Observable<boolean>),
				filter(fetchError => !fetchError),
				switchMap(() => this.phoneNgrxSelectorService.selectCallRecordsSnapshot$()),
				filter(callRecords => !!callRecords.length),
				map(callRecords => this.phoneCallsService.updateCallRecords(callRecords))
			);
	}

	public pollFetchRecentCalls$(previousRecentCalls: IRecentCall[], username: string): Observable<IRecentCall[]> {
		return timer(0, 1_000)
			.pipe(
				take(35),
				exhaustMap(() => this.recentCallHttpService.fetchRecentCalls$(username)),
				takeWhile(callRecords => PhoneUtilService.areRecentCallsTheSame(previousRecentCalls, callRecords), true)
			);
	}
}
