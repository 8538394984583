import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TranslateLanguageFullnamePipe } from './../../../pipes/translate-language-fullname.pipe';
import { LanguageService } from './../../../../components/OTHER_FEATURES/user-settings/services/language/language.service';

@Component({
	selector: 'app-language-picker-small',
	standalone: true,
	imports: [
		CommonModule,
		TooltipModule,
		TranslateModule,
		BsDropdownModule,
		TranslateLanguageFullnamePipe
	],
	templateUrl: './language-picker-small.component.html',
	styleUrls: ['./language-picker-small.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePickerSmallComponent {

	constructor(
		private readonly languageService: LanguageService,
		public readonly translateService: TranslateService
	) {}

	public onSetLanguage(language: string): void {
		this.languageService.onSetLanguage(language);
	}
}
