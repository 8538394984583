import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'app-manual-page',
	templateUrl: './manual-page.component.html',
	styleUrls: ['./manual-page.component.scss']
})
export class ManualPageComponent {

	@Input() title: string;
	@Input() manualUrl: string;
	@Input() bottomMargin = true;

	constructor() { }

	onOpenManual(): void {
		if (!this.manualUrl) { return; }

		window.open(this.manualUrl, '_blank');
	}
}
