import { Injectable } from '@angular/core';
import { IActuator } from '../../ts/models/automation-program-edit-actuator.model';
import { AutomationDeviceType } from '../../../automation/ts/enums/automation-device-type.enum';
import { RioRvsIohubDeviceType } from '../../../rio-rvs/ts/enums/rio-rvs-iohub-device-type.enum';
import { IAutomationProgramEditLineDevice } from '../../ts/models/automation-program-edit-line-device.model';
import { AutomationProgramSpreadsheetNgrxService } from '../ngrx/automation-program-spreadsheet-ngrx.service';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSpreadsheetVoutService {

	constructor(
		private automationSpreadsheetNgrxService: AutomationProgramSpreadsheetNgrxService
	) { }

	generateUniqueVoutId(vouts: IAutomationProgramEditLineDevice[]): number {
		if (!vouts.length) {
			return 1;
		}

		const voutIds = vouts.map(vout => vout.lineDeviceNumber);
		let indexCounter = 1;

		while (voutIds.includes(indexCounter)) {
			indexCounter += 1;
		}

		return indexCounter;
	}

	formatNewVout(vouts: IAutomationProgramEditLineDevice[]): IAutomationProgramEditLineDevice {
		const uniqueIndex = this.generateUniqueVoutId(vouts);
		const vout = RioRvsIohubDeviceType.VOUT;

		return {
			lineDeviceNumber: uniqueIndex,
			description: '',
			value: '',
			isOffline: false,
			deviceNumber: uniqueIndex,
			device: RioRvsIohubDeviceType.VOUT,
			fullDeviceName: `${vout}-${uniqueIndex}`,
			lineDeviceName: vout,
			index: -1,
			expression: '',
			expressionValue: '',
			port: vout,
			dft: '',
			type: AutomationDeviceType.OUTPUT
		};
	}

	handleSetActuators(actuator: IActuator, actuators: IActuator[]): void {
		const actuatorsToSet = this.getActuatorsToSet(actuator, actuators);

		this.automationSpreadsheetNgrxService.setActuators(actuatorsToSet);
	}

	getActuatorsToSet(actuator: IActuator, actuators: IActuator[]): IActuator[] {
		if (actuators.length) {
			const { id, functionExpression } = actuator;
			const isActuatorExist = actuators.find(existingActuator => existingActuator.id === id);

			if (isActuatorExist) {
				return this.updateExistingActuators(id, functionExpression, actuators);
			}

			return [...actuators, actuator];
		}

		return [actuator];
	}

	updateExistingActuators(id: string, functionExpression: string, actuators: IActuator[]): IActuator[] {
		return actuators.map(existingActuator => {
			const { id: existingActuatorId } = existingActuator;

			return existingActuatorId === id ? { ...existingActuator, functionExpression } : existingActuator;
		});
	}
}
