import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPreferencesFormValidity } from '../../ts/models/preferences-form-valid.model';
import { PREFERENCES_INITIAL_FORM_STATE } from '../../utils/preferences-global-variables';

@Injectable({
	providedIn: 'root'
})
export class PreferencesFormValidatorService {

	private formsValidityState: BehaviorSubject<IPreferencesFormValidity> = new BehaviorSubject
	<IPreferencesFormValidity>(PREFERENCES_INITIAL_FORM_STATE);

	constructor() { }

	formatFormValuesForCompare(formValues: Record<string, any>): Record<string, any> {
		return Object
			.entries(formValues)
			.reduce((accumulator, formItemKeyValuePair) => {
				const [key, value] = formItemKeyValuePair;

				if (Array.isArray(value)) {
					return { ...accumulator, [key]: value.sort() };
				}

				if (value) {
					return { ...accumulator, [key]: value.toString().toLowerCase() };
				}

				return { ...accumulator, [key]: value };
			}, {} as Record<string, any>);
	}

	areFormsValid$(): Observable<boolean> {
		return this.formsValidityState.asObservable()
			.pipe(
				map(formValidityState => Object.values(formValidityState).some(formValidityValue => formValidityValue))
			);
	}

	resetFormValidityState(): void {
		this.formsValidityState.next(PREFERENCES_INITIAL_FORM_STATE);
	}

	setCallManagementFormValidity(callManagementFormValid: boolean): void {
		this.formsValidityState.next({ ...this.formsValidityState.getValue(), callManagementFormValid });
	}

	setOtherOptionsFormValidity(otherOptionsFormValid: boolean): void {
		this.formsValidityState.next({ ...this.formsValidityState.getValue(), otherOptionsFormValid });
	}

	setAdvancedSettingsFormValidity(advancedSettingsFormValid: boolean): void {
		this.formsValidityState.next({ ...this.formsValidityState.getValue(), advancedSettingsFormValid });
	}

	getFormValidityState(): IPreferencesFormValidity {
		return this.formsValidityState.getValue();
	}

	arePreferencesFormChanged(formValues: Record<string, any>, initialFormValues: Record<string, any>): boolean {
		const currentValuesToCompare = this.formatFormValuesForCompare(formValues);
		const initialCurrentValuesToCompare = this.formatFormValuesForCompare(initialFormValues);

		return JSON.stringify(currentValuesToCompare) !== JSON.stringify(initialCurrentValuesToCompare);
	}
}
