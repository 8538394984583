import { Injectable } from '@angular/core';
import { RioRvsService } from '../rio-rvs.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RioRvsAinTableService } from './rio-rvs-ain-table.service';
import { RioRvsDoutTableService } from './rio-rvs-dout-table.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutomationDeviceShortPipe } from '../../../../pipes/automation-device-short.pipe';
import { createTrimWhitespaceValidator } from '../../../../utils/form-validators.validator';
import { GenericTableBuilder } from '../../../networking/common/classes/table-builder.class';
import { IGenericTableRow } from '../../../ui-components/generic-table/ts/models/generic-table-row';
import { IGenericTableHeader } from '../../../ui-components/generic-table/ts/models/generic-table-header';
import { AutomationAnalogInputAlarms } from '../../../automation/ts/enums/automation-analog-input-alarms.enum';
import { AutomationAnalogOutputAlarms } from '../../../automation/ts/enums/automation-analog-output-alarms.enum';
import { IRioRvsModalAnalogLineDevice } from '../../ts/models/modal-line-devices/rio-rvs-modal-analog-line-device.model';
import { IGenericUnsetSelectedRow } from '../../../ui-components/generic-table/ts/models/generic-unset-selected-row.model';

@Injectable({
	providedIn: 'root'
})
export class RioRvsAoutTableService extends GenericTableBuilder {

	private unsetSelectedRowState = new BehaviorSubject({ unsetSelectedRow: false });

	private readonly aoutIconStyle = 'margin-right: 6px; width: 16px; height: 16px; cursor: default';

	constructor(
		private formBuilder: FormBuilder,
		private rioRvsAinTableService: RioRvsAinTableService,
		private rioRvsDoutTableService: RioRvsDoutTableService
	) {
		super();
	}

	buildTableHeaders(): IGenericTableHeader[] {
		return [
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrPort' }, sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrDesc' }, sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrUnit' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrMinVal' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrMaxVal' },
				sort: { useSort: false }
			},
			{
				header: {
					headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrLow',
					headerIcon: true, headerIconPath: 'bell.svg', headerContainerStyle: 'flex-direction: row !important;',
					headerIconStyle: this.aoutIconStyle
				},
				sort: { useSort: false }
			},
			{
				header: {
					headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrHigh',
					headerIcon: true, headerIconPath: 'bell.svg', headerContainerStyle: 'flex-direction: row !important;',
					headerIconStyle: this.aoutIconStyle
				},
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrSens' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrPoll' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrLowestVal' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrHighestVal' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrStartingState' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrDefaultState' },
				sort: { useSort: false }
			},
			{
				header: { headerName: '', isAction: true },
				sort: { useSort: false }
			}
		];
	}

	buildTableRows(): IGenericTableRow[] {
		return [
			{
				cell: { cellName: 'lineDeviceType', visibleCellCondition: true, pipe: new AutomationDeviceShortPipe() },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'description', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'unit', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'minUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'maxUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'almLoUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'almHiUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'updUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'poll', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'cutLoUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'cutHiUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'startTranslated', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'dftUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: '', visibleCellCondition: true, isAction: true },
				edit: { editMode: false }
			}
		];
	}

	buildForm(): FormArray {
		return this.formBuilder.array([]);
	}

	setUnselectedRowState(rowState: boolean): void {
		this.unsetSelectedRowState.next({ unsetSelectedRow: rowState });
	}

	getUnsetSelectedRowState$(): Observable<IGenericUnsetSelectedRow> {
		return this.unsetSelectedRowState;
	}

	buildAoutForm(selectedDevice: IRioRvsModalAnalogLineDevice): FormGroup {
		const hystUpdRegExp = this.rioRvsAinTableService.getHystValidators();
		const poolingPeriodRegExp = this.rioRvsAinTableService.getPoolingPeriod();

		const {
			'unit-ronly': unitReadonly, 'min-ronly': minReadonly, 'max-ronly': maxReadonly,
			'upd-ronly': sensitivityReadonly, 'poll-ronly': poolingReadonly, min, max,
			'hist-ronly': hystReadonly, almLo, almHi, 'cut-lo': lowestValue, 'cut-hi': highestValue
		} = selectedDevice;

		const minMaxValidators = this.rioRvsAinTableService.getMinMaxValidators();
		const unavailableAlarmLow = this.rioRvsAinTableService.isMatchingUnavailableValue(almLo);
		const unavailableAlarmHigh = this.rioRvsAinTableService.isMatchingUnavailableValue(almHi);
		const unavailableLowestValue = this.rioRvsAinTableService.isMatchingUnavailableValue(lowestValue);
		const unavailableHighestValue = this.rioRvsAinTableService.isMatchingUnavailableValue(highestValue);
		const defaultValidators = this.rioRvsAinTableService.getAlmLoHiValidators(min, max, false, true);

		return this.formBuilder.group({
			start: [''],
			imagePath: [''],
			almHi: ['', [...this.rioRvsAinTableService.getAlmLoHiValidators(min, max, true, unavailableAlarmHigh)]],
			almLo: ['', [...this.rioRvsAinTableService.getAlmLoHiValidators(min, max, true, unavailableAlarmLow)]],
			description: ['', [Validators.maxLength(14)]],
			lowest: ['', [...this.rioRvsAinTableService.getAlmLoHiValidators(min, max, true, unavailableLowestValue)]],
			highest: ['', [...this.rioRvsAinTableService.getAlmLoHiValidators(min, max, true, unavailableHighestValue)]],
			dft: ['', [createTrimWhitespaceValidator(), ...defaultValidators]],
			minValue: [{ value: '', disabled: minReadonly }, minMaxValidators],
			maxValue: [{ value: '', disabled: maxReadonly }, minMaxValidators],
			unit: [{ value: '', disabled: unitReadonly }, [Validators.maxLength(8)]],
			sensitivity: [{ value: '', disabled: sensitivityReadonly }, hystUpdRegExp],
			poolingPeriod: [{ value: '', disabled: poolingReadonly }, poolingPeriodRegExp],
			hysteresis: [{ value: '', disabled: hystReadonly }, this.rioRvsAinTableService.getHystValidators()]
		});
	}

	updateAinAoutDevices(devices: IRioRvsModalAnalogLineDevice[], isAout: boolean = false): IRioRvsModalAnalogLineDevice[] {
		return devices.reduce((accumulator, ainAoutDevice) => {
			const { originalDeviceType } = ainAoutDevice;

			if (this.getAinAoutAlarmCondition(originalDeviceType, isAout)) {
				const matchingAlarm = devices.find(device => device.lineDeviceType === ainAoutDevice.lineDeviceType);
				const { min, max, hist, upd, dft, start } = ainAoutDevice;
				const almHi = matchingAlarm?.almHi || '';
				const almLo = matchingAlarm?.almLo || '';

				const minUI = RioRvsService.translateUnavailableValues(min);
				const maxUI = RioRvsService.translateUnavailableValues(max);
				const updUI = RioRvsService.translateUnavailableValues(upd);
				const dftUI = RioRvsService.translateUnavailableValues(dft);
				const histUI = RioRvsService.translateUnavailableValues(hist);
				const almLoUI = RioRvsService.translateUnavailableValues(almLo);
				const almHiUI = RioRvsService.translateUnavailableValues(almHi);

				const newItem = { ...ainAoutDevice, minUI, maxUI, almLoUI, almHiUI, histUI, updUI, dftUI, almLo, almHi };

				if (isAout) {
					const { 'cut-lo': cutLo, 'cut-hi': cutHi } = ainAoutDevice;
					const cutLoUI = RioRvsService.translateUnavailableValues(cutLo);
					const cutHiUI = RioRvsService.translateUnavailableValues(cutHi);
					const startTranslated = this.rioRvsDoutTableService.translateStartValue(start);

					return [...accumulator, { ...newItem, startTranslated, cutLoUI, cutHiUI }];
				}

				return [...accumulator, newItem];
			}

			return accumulator;
		}, [] as IRioRvsModalAnalogLineDevice[]);
	}

	getAinAoutAlarmCondition(originalDeviceType: string, isAout: boolean): boolean {
		if (!isAout) {
			return originalDeviceType !== AutomationAnalogInputAlarms.ANALOG_INPUTS_ALARMS;
		}

		return originalDeviceType !== AutomationAnalogOutputAlarms.ANALOG_OUTPUTS_ALARMS;
	}

}
