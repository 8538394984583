import { Injectable } from '@angular/core';
import { nonAllowedOperators } from '../../../utils/available-functions-operators';
import { AutomationProgramSpreadsheetUtilService } from '../automation-program-spreadsheet-util.service';
import { AutomationProgramEditFunctonList } from '../../../ts/enums/automation-program-edit-function-list.enum';
import { AutomationProgramEditLineDeviceValue } from '../../../ts/types/automation-program-edit-line-device-value.type';
import { IAutomationProgramEditResultResponse } from '../../../ts/models/automation-program-edit-result-response.modal';
import * as expressionEval from 'expression-eval';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSpreadsheetFunctionArgsService extends AutomationProgramSpreadsheetUtilService {

	private readonly ifComparasionOperators: string[] = ['>', '>=', '<', '<='];

	constructor() {
		super();
	}

	validateFunctionArguments(
		expressionName: string, functionArguments: AutomationProgramEditLineDeviceValue[], parenthesisData: string
	): IAutomationProgramEditResultResponse {
		switch (expressionName) {
		case AutomationProgramEditFunctonList.ALERT:
			return this.checkIfAlertIsValid(functionArguments);
		case AutomationProgramEditFunctonList.INV:
			return this.checkIfInvIsValid(functionArguments);
		case AutomationProgramEditFunctonList.HMSM:
		case AutomationProgramEditFunctonList.TIME:
			return this.checkIfDatePassedIsValid(functionArguments, expressionName);
		case AutomationProgramEditFunctonList.BETWEEN:
			return this.checkIfDateBetweenIsValid(functionArguments);
		case AutomationProgramEditFunctonList.RANDBETWEEN:
			return this.checkIfRandomFunctionIsValid(expressionName, functionArguments);
		case AutomationProgramEditFunctonList.MIN:
		case AutomationProgramEditFunctonList.MAX:
		case AutomationProgramEditFunctonList.ABS:
		case AutomationProgramEditFunctonList.ODD:
		case AutomationProgramEditFunctonList.STDEV:
		case AutomationProgramEditFunctonList.INT:
		case AutomationProgramEditFunctonList.MOD:
		case AutomationProgramEditFunctonList.POW:
		case AutomationProgramEditFunctonList.EVEN:
		case AutomationProgramEditFunctonList.SQRT:
		case AutomationProgramEditFunctonList.LOG_10:
		case AutomationProgramEditFunctonList.AVERAGE:
			return this.checkNumericalFunctionsArguments(expressionName, functionArguments);
		case AutomationProgramEditFunctonList.OR:
		case AutomationProgramEditFunctonList.NOT:
		case AutomationProgramEditFunctonList.AND:
		case AutomationProgramEditFunctonList.XOR:
			return this.checkLogicalFunctionsArguments(expressionName, functionArguments);
		case AutomationProgramEditFunctonList.IF:
		case AutomationProgramEditFunctonList.IFNA:
			return this.checkIfFunctionArguments(functionArguments, parenthesisData);
		case AutomationProgramEditFunctonList.TRANS:
			return this.checkTransFunctionArguments(functionArguments);
		case AutomationProgramEditFunctonList.ROUND:
			return this.checkRoundFunctionArguments(functionArguments);
		}

		if (new RegExp(AutomationProgramEditFunctonList.HISTORY).test(expressionName)) {
			const numericalValuesValidation = this.checkNumericalFunctionsArguments(expressionName, functionArguments);

			return numericalValuesValidation.haveError ? numericalValuesValidation : this.checkHistoryFunctionArguments(functionArguments);
		}

		return { haveError: false, value: 0 };
	}

	checkIfAlertIsValid(functionArguments: AutomationProgramEditLineDeviceValue[]): IAutomationProgramEditResultResponse {
		const [channelId] = functionArguments;

		if (typeof channelId !== 'number' || channelId < 1 || channelId > 255) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.alertInvalid}}' };
		}

		return { haveError: false, value: '' };
	}

	checkIfInvIsValid(functionArguments: AutomationProgramEditLineDeviceValue[]): IAutomationProgramEditResultResponse {
		const [condition, interval] = functionArguments;

		if (typeof condition !== 'boolean' || interval < 1) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.invInvalid}}' };
		}

		return { haveError: false, value: 0 };
	}

	checkIfDatePassedIsValid(
		functionArguments: AutomationProgramEditLineDeviceValue[], expression: AutomationProgramEditFunctonList
	): IAutomationProgramEditResultResponse {
		const isTimeInvalid = this.isHourMinuteInvalid(functionArguments, expression);

		if (isTimeInvalid) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.invalidDatetime}}' };
		}

		return { haveError: false, value: 0 };
	}

	checkIfDateBetweenIsValid(functionArguments: AutomationProgramEditLineDeviceValue[]): IAutomationProgramEditResultResponse {
		const endingTime = functionArguments.slice(2, 4);
		const startingTime = functionArguments.slice(0, 2);

		const positiveIntegers = functionArguments.every(timeUnit => timeUnit >= 0);

		if (!positiveIntegers) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.minusInteger}}' };
		}

		const argumentsInvalid = [startingTime, endingTime]
			.map(fnArgument => this.isHourMinuteInvalid(fnArgument, AutomationProgramEditFunctonList.BETWEEN))
			.some(result => result);

		if (argumentsInvalid) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.invalidDatetime}}' };
		}

		const [endingHour, endingMinute] = endingTime as number[];
		const [startingHour, startingMinute] = startingTime as number[];

		if ((startingHour * 60 + startingMinute) >= (endingHour * 60 + endingMinute)) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.invalidHourBetween}}' };
		}

		return { haveError: false, value: 0 };
	}

	isHourMinuteInvalid(functionArguments: AutomationProgramEditLineDeviceValue[], expression: AutomationProgramEditFunctonList): boolean {
		const stringDateFormat = super.areValidHoursMinutesSeconds(functionArguments, expression);
		const validDateResult = new Date(Date.parse(stringDateFormat));

		return validDateResult.toString() === 'Invalid Date';
	}

	checkHistoryFunctionArguments(functionArguments: AutomationProgramEditLineDeviceValue[]): IAutomationProgramEditResultResponse {
		const [seconds] = functionArguments;

		if (seconds <= 0) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.timespanLess}}' };
		}

		return { haveError: false, value: 0 };
	}

	checkRoundFunctionArguments(functionArguments: AutomationProgramEditLineDeviceValue[]): IAutomationProgramEditResultResponse {
		const [cellValue, roundNumber] = functionArguments;

		if (typeof cellValue !== 'number') {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.roundFnNumber}}' };
		}

		if (roundNumber < 0 || roundNumber > 3) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.roundFnDecimalPlaces}}' };
		}

		return { haveError: false, value: 0 };
	}

	checkTransFunctionArguments(functionArguments: AutomationProgramEditLineDeviceValue[]): IAutomationProgramEditResultResponse {
		const [cellValue, seconds] = functionArguments;

		if (typeof cellValue !== 'boolean') {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.referencedCellDigitalDevice}}' };
		}

		if (typeof seconds !== 'number') {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.transTimespan}}' };
		}

		if (seconds <= 0) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.timespanLess}}' };
		}

		return { haveError: false, value: 0 };
	}

	checkIfFunctionArguments(
		functionArguments: AutomationProgramEditLineDeviceValue[], parenthesisData: string
	): IAutomationProgramEditResultResponse {
		const [conditionPart] = parenthesisData.split(',');
		const [conditionValue, firstArgument] = functionArguments;
		const condtionalOperatorBoolean = this.ifComparasionOperators.some(operator => conditionPart.includes(operator));

		if ((conditionPart.includes('true') || conditionPart.includes('false')) && condtionalOperatorBoolean) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.conditionBooleanValues}}' };
		}

		const firstTypeArgument = typeof firstArgument;

		const fnArgumentsSame = functionArguments
			.slice(1, functionArguments.length - 1)
			.map(item => typeof item)
			.every(fnArgType => fnArgType === firstTypeArgument);

		if (!fnArgumentsSame) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.argumentTypes}}' };
		}

		if (typeof conditionValue !== 'boolean') {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.nonIfBooleanCondition}}' };
		}

		return { haveError: false, value: 0 };
	}

	checkIfRandomFunctionIsValid(
		expressionName: string, functionArguments: AutomationProgramEditLineDeviceValue[]
	): IAutomationProgramEditResultResponse {
		const fnArgumentsBooleanValidation = this.checkNumericalFunctionsArguments(expressionName, functionArguments);

		if (fnArgumentsBooleanValidation.haveError) {
			return fnArgumentsBooleanValidation;
		}

		const [minimumArgumentValue, maximumArgumentValue] = functionArguments;

		if (minimumArgumentValue > maximumArgumentValue) {
			const errorMessage = '{{AUTO.RULES.EDIT.ERRORS.randomFnRange}}';

			return { haveError: true, errorMessage };
		}

		return { haveError: false, value: 0 };
	}

	checkLogicalFunctionsArguments(
		expressionName: string, functionArguments: AutomationProgramEditLineDeviceValue[]
	): IAutomationProgramEditResultResponse {
		const someArgumentNotBoolean = functionArguments.some(argument => typeof argument !== 'boolean');
		const errorMessage = `{{AUTO.RULES.EDIT.ERRORS.fnArguments}} "${expressionName}" {{AUTO.RULES.EDIT.ERRORS.fnMustBeBoolean}}`;

		return { haveError: someArgumentNotBoolean, errorMessage, value: 0 };
	}

	checkNumericalFunctionsArguments(
		expressionName: string, functionArguments: AutomationProgramEditLineDeviceValue[]
	): IAutomationProgramEditResultResponse {
		const someArgumentBoolean = functionArguments.some(argument => typeof argument === 'boolean');
		const errorMessage = `{{AUTO.RULES.EDIT.ERRORS.fnArguments}} "${expressionName}" {{AUTO.RULES.EDIT.ERRORS.fnCantBeBoolean}}`;

		return { haveError: someArgumentBoolean, errorMessage, value: 0 };
	}

	getFunctionArgumentsValidation(parenthesisData: string): IAutomationProgramEditResultResponse[] {
		if (parenthesisData !== '()') {
			return parenthesisData
				.slice(1, parenthesisData.length - 1)
				.split(',')
				.map(argument => this.evaluateExpressionResult(argument));
		}

		return [];
	}

	getFunctionArgumentsValue(fnArgsValidation: IAutomationProgramEditResultResponse[]): AutomationProgramEditLineDeviceValue[] {
		return fnArgsValidation.map(argumentValidationResult => argumentValidationResult.value as AutomationProgramEditLineDeviceValue);
	}

	evaluateExpressionResult(expression: string): IAutomationProgramEditResultResponse {
		const formattedExpression = super.formatExpressionForEvaluation(expression);

		try {
			const parsedResult = expressionEval.parse(formattedExpression);
			const value = expressionEval.eval(parsedResult, {});
			const { operator } = parsedResult as unknown as { operator: string };

			if (this.expressionResultInvalidOperators().includes(operator)) {
				return this.getInvalidOperatorResponse(operator);
			}

			return this.validateFunctionResult(value, formattedExpression);
		} catch {
			const errorMessage = this.getFunctionErrorMessage(true, formattedExpression);

			return { haveError: true, errorMessage };
		}
	}

	expressionResultInvalidOperators(): string[] {
		return nonAllowedOperators.filter(operator => !['==', '!=='].includes(operator));
	}

	validateFunctionResult(value: AutomationProgramEditLineDeviceValue, expression?: string | null): IAutomationProgramEditResultResponse {
		const haveError = value === undefined || value.toString() === 'NaN';
		const errorValueMessage = this.getFunctionErrorMessage(haveError, expression);

		return { haveError, errorMessage: errorValueMessage, value };
	}

	getInvalidOperatorResponse(wrongOperator: string): IAutomationProgramEditResultResponse {
		const wrongPrefix = '{{AUTO.RULES.EDIT.ERRORS.wrongOperatorPrefix}}';
		const wrongSuffix = '{{AUTO.RULES.EDIT.ERRORS.wrongOperatorSuffix}}';
		const errorMessage = `${wrongPrefix} "${wrongOperator}" ${wrongSuffix}`;

		return { haveError: true, errorMessage };
	}

	getFunctionErrorMessage(haveError: boolean, expression?: string | null): string {
		if (!haveError) {
			return '';
		}

		if (expression && expression.toString() === 'NaN') {
			return '{{AUTO.RULES.EDIT.ERRORS.expressionResultNotValid}}';
		}

		return '{{AUTO.RULES.EDIT.ERRORS.expressionNotValid}}';
	}
}
