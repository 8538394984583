import { Observable } from 'rxjs';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ISelectData } from '../ts/models/select-data.model';
import { filter, map, startWith, take } from 'rxjs/operators';
import { GeneratedFormComponentType } from '../components/networking/ts/enums/generated-form-component-type.enum';
import { NetworkingAutomationUtilService } from '../components/networking/services/networking-automation-util.service';
import { IGeneratedConfigurationParams } from '../components/networking/ts/models/generated-configuration-params.model';
import { NetworkingAutomationSelectService } from '../components/networking/services/networking-automation-select.service';
import { NetworkingAutomationFormlyService } from '../components/networking/services/formly/networking-automation-formly.service';
import { IGeneratedConfigurationTemplateOptions } from '../components/networking/ts/models/generated-configuration-template-option.model';
import * as customOperators from '../custom-operators/custom-operators';
import { InfoBubbleComponent } from '../components/ui-components/custom-ui-components/info-bubble.component';

@Component({
	selector: 'app-formly-field-input-string',
	styleUrls: ['../formly-styles/input-string-field-style.scss'],
	template: `
	<div class="form-group custom__input custom__inline" [ngStyle]="{'flex-wrap': 'wrap'}">
		<label [title]="tooltip | translate | translateFix:tooltip" class="input__label">
			{{label | translate | translateFix:label}}
		</label>

		<div class="input">
			<input [type]="fieldType" class="form-control"
			[formControl]="$any(formControl)" [formlyAttributes]="field"
			[ngClass]="{'is-invalid': showError && formControl.errors !== null}"
			[ngStyle]="{'max-width': fieldWidth, width: 'auto'}" (focus)="onFocus()" (blur)="onBlur()">

			<ng-container *ngIf="selectData$ | async as selectData">
				<ng-container *ngIf="selectData && isFocused && (selectData | selectFilter:formControl.value) as filterData">
					<div class="input__select" *ngIf="filterData.length">
						<ng-container *ngTemplateOutlet="dropdownListTemplate, context: { dropdownItems: filterData, fullList: false }">
							</ng-container>

							<ng-container *ngIf="formControl.value">
								<ng-container *ngTemplateOutlet="dropdownListTemplate, context: { dropdownItems: selectData, fullList: true }">
									</ng-container>
								</ng-container>
							</div>
						</ng-container>
					</ng-container>
				</div>


				<ng-container *ngIf="infoBubbleTranslation">
						<ng-container *ngTemplateOutlet="translationBlock"></ng-container>
						<ng-template #translationBlock>
								<app-info-bubble [customTemplate]="translationTemplate"></app-info-bubble>
							<ng-template #translationTemplate>
								{{infoBubbleTranslation | translate }}
							</ng-template>
						</ng-template>
				</ng-container>

				<ng-container *ngIf="infoBubbleContent">
						<ng-container *ngTemplateOutlet="infoBubbleTemplate"></ng-container>
						<ng-template #infoBubbleTemplate>
							<app-info-bubble [customTemplate]="infoBubbleTemplate"></app-info-bubble>
							<ng-template #infoBubbleTemplate>
								<div [innerHTML]="infoBubbleContent"></div>
							</ng-template>
						</ng-template>
				</ng-container>



		<label *ngIf="afterLabelText" class="input__afterlabel">{{afterLabelText | translate | translateFix:afterLabelText}}</label>

		<span *ngIf="referenceIcon && referenceIconRegExpMatching">
			<img src="assets/feather-icons/external-link-2.svg" alt="link" (click)="onLinkClick()">
		</span>
	</div>

	<ng-template #dropdownListTemplate let-dropdownItems="dropdownItems" let-fullList="fullList">
		<ng-container *ngIf="fullList">
			<hr>
		</ng-container>

		<span [ngStyle]="{'max-width': fieldWidth}" (click)="onSelectItem(selectItem)"
			(touchend)="onSelectItem(selectItem)" *ngFor="let selectItem of dropdownItems">
			{{selectItem.uiValue}}
		</span>
	</ng-template>

	<div class="custom__input--invalid" *ngIf="showError && formControl.errors !== null && isFocused">
		{{errorMessage | translate | translateFix:errorMessage}}
	</div>
 `,
})

export class FormlyFieldCustomInputComponent extends FieldType implements OnInit {

	infoBubbleContent: string | TemplateRef<any>;
	infoBubbleTranslation: string;
	infoBubble: any;
	label: string;
	tooltip: string;
	fieldWidth: string;
	errorMessage: string;
	afterLabelText: string;
	referenceIcon: boolean;
	referenceIconRegExp: string;
	referenceIconRegExpMatching = true;
	fieldType: GeneratedFormComponentType;

	useFocus = true;
	isFocused: boolean;
	selectData$: Observable<ISelectData[]>;

	constructor(
		private networkingSelectService: NetworkingAutomationSelectService,
		private networkingAutomationUtilService: NetworkingAutomationUtilService,
		private networkingAutomationFormlyService: NetworkingAutomationFormlyService
	) {
		super();
	}

	ngOnInit(): void {
		const {
			label, afterLabelText, errorMessage, type, referenceIcon, referenceIconRegExp, fieldWidth,
		} = this.field.templateOptions as IGeneratedConfigurationTemplateOptions;
		const { tooltip, infoBubble, } = this.field as IGeneratedConfigurationParams;

		this.tooltip = tooltip || '';
		this.infoBubbleTranslation = infoBubble?.translation || '';
		this.infoBubbleContent = infoBubble?.content || '';
		this.label = label;
		this.fieldType = type;
		this.errorMessage = errorMessage;
		this.referenceIcon = referenceIcon;
		this.fieldWidth = fieldWidth || '200px';
		this.referenceIconRegExp = referenceIconRegExp;
		this.afterLabelText = afterLabelText as string;

		this.fetchInputDropdownData();
		this.watchFieldValueChanges();
	}

	isTemplate(val: string | TemplateRef<any>): boolean { return val instanceof TemplateRef; }


	fetchInputDropdownData(): void {
		const {
			asyncValidatorUrl, asyncValidatorPath, responseSeparator
		} = this.field.templateOptions as IGeneratedConfigurationTemplateOptions;

		if (asyncValidatorUrl && asyncValidatorPath) {
			this.selectData$ = this.networkingAutomationFormlyService.formatAsyncConfigItemValues$(asyncValidatorUrl, asyncValidatorPath)
				.pipe(
					take(1),
					map(response => this.networkingSelectService.getAsyncSelectDataFormat(response, responseSeparator)),
					map(response => response.map(value => ({ value, uiValue: value }))),
					customOperators.retryFromError$(1000)
				);
		}
	}

	onLinkClick(): void {
		this.networkingAutomationUtilService.emitClickButtonState(this.field?.formControl?.value);
	}

	watchFieldValueChanges(): void {
		this.field.formControl?.valueChanges
			.pipe(
				startWith(this.field.formControl?.value),
				filter(() => !!this.referenceIconRegExp)
			)
			.subscribe(fieldValue => this.handleValueChanges(fieldValue));
	}

	handleValueChanges(fieldValue: string): void {
		this.referenceIconRegExpMatching = new RegExp(this.referenceIconRegExp).test(fieldValue);
	}

	onFocus(): void {
		this.isFocused = true;
	}

	onBlur(): void {
		if (this.useFocus) {
			this.isFocused = false;
		}
	}

	onSelectItem(onSelectItem: ISelectData): void {
		this.useFocus = false;
		(this.field.formControl as FormControl).setValue(onSelectItem.value);

		this.useFocus = true;
		this.isFocused = false;
	}
}
