import { Injectable } from '@angular/core';
import { TypedAction } from '@ngrx/store/src/models';
import { PhoneCallsService } from './../calls/phone-calls.service';
import { PhoneNgrxActionService } from './phone-ngrx-action.service';
import { IPhoneCallRecord } from './../../ts/models/phone-call-record.model';
import { PhoneFooterUtilService } from './../footer/phone-footer-util.service';
import { IPhoneModePayload } from './../../ts/models/phone-mode-payload.model';
import { PhoneRecentCallsService } from './../calls/phone-recent-calls.service';
import { IRecentCall } from '../../../PHONE_FEATURES/recent-calls/ts/models/recent-call-table.model';
import { OpcPanelCallRecordState } from '../../../opc-panel/ts/enums/opc-panel-call-record-state.enum';
import * as phoneActions from '../../ngrx/phone-component.actions';

@Injectable({
	providedIn: 'root'
})
export class PhoneNgrxEffectHandlerService {

	constructor(
		private readonly phoneNgrxActionService: PhoneNgrxActionService,
		private readonly phoneRecentCallsService: PhoneRecentCallsService
	) { }

	public handleSetFilterOnCallHoldRecord(callRecord: IPhoneCallRecord | null, isCallHold: boolean): boolean {
		if (isCallHold) { return true; }

		const callRecordAvailable = !!callRecord;

		if (!callRecordAvailable) { return false; }

		this.phoneNgrxActionService.setUnconfiguredFooterState(false);

		return true;
	}

	public getParameterPayloadForCallHoldRecord(callRecord: IPhoneCallRecord, isCallHold: boolean): IPhoneModePayload {
		if (!isCallHold) {
			return { CallId: callRecord.CallID };
		}

		return { NUM: PhoneCallsService.getCallingNumber(callRecord) };
	}

	public getParameterPayloadForConferenceCall(callRecords: IPhoneCallRecord[]): IPhoneModePayload | null {
		const [firstCall, secondCall] = callRecords;
		const areCallsOfDifferentOrigin = firstCall?.abilis !== secondCall?.abilis;
		const areBothCallsNA = !PhoneFooterUtilService.areBothCallsAvailable(firstCall, secondCall);

		if (areBothCallsNA || areCallsOfDifferentOrigin) { return null; }

		return { callId1: firstCall.CallID, callId2: secondCall.CallID };
	}

	public setRecentCallsLog(recentCalls: IRecentCall[]): TypedAction<'[Phone] Set Recent Calls Log'> {
		const updatedRecentCalls = this.phoneRecentCallsService.formatRecentCallsForPhone(recentCalls);

		return phoneActions.setRecentCallsLog({ payload: { recentCalls: updatedRecentCalls } });
	}

	public getHoldRecordCallState(isHoldAction: boolean, command: OpcPanelCallRecordState): OpcPanelCallRecordState {
		return !isHoldAction || command === OpcPanelCallRecordState.HOLD ? OpcPanelCallRecordState.ACTIVE : OpcPanelCallRecordState.HOLD;
	}
}
