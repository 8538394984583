<div class="wrapper">
	<div class="content" *ngIf="sectionPermissions$ | async as sectionPermissions">
		<div>
			<app-side-bar-search [haveAdminUserLevel]="haveAdminUserLevel$ | async" *ngIf="isUserLoggedIn$ | async">
			</app-side-bar-search>

			<app-side-bar-item [sidebarFeature]="sidebarFeature" [sectionPermissions]="sectionPermissions"
				[sidebarFeatureName]="selectedFeatureName$ | async" *ngFor="let sidebarFeature of sidebarFeatures">
			</app-side-bar-item>
		</div>

		<app-side-bar-footer [isUserLoggedIn]="isUserLoggedIn$ | async" [sidebarFeatureName]="selectedFeatureName$ | async">
		</app-side-bar-footer>
	</div>
</div>
