import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { IAppState } from '../../../../root-reducers';
import { ICamera } from '../../../videorecorder/ts/models/camera.model';
import { IAutomationMapCamera } from '../../ts/models/automation-map-camera-device.model';
import { IAutomationMapUsedCamera } from '../../ts/models/automation-map-used-camera.model';
import { IAutomationMapVideoContainer } from '../../ts/models/automation-map-video-container.model';
import { AutomationVideoContainerUpdate } from '../../ts/types/automation-video-container-update.type';
import { IAutomationMapVideoContainerModal } from '../../ts/models/automation-map-video-container-modal.model';
import * as automationSelectors from '../../ngrx/automation-selector';
import * as automationActions from '../../ngrx/automation.action';

@Injectable({
	providedIn: 'root'
})
export class AutomationVideoNgrxService {

	constructor(
		private store: Store<IAppState>
	) { }

	selectUsedCameras$(): Observable<IAutomationMapUsedCamera[]> {
		return this.store.pipe(select(automationSelectors.selectUsedCameras()));
	}

	selectAllVideoContainers$(): Observable<IAutomationMapVideoContainer[]> {
		return this.store.pipe(select(automationSelectors.selectVideoContainers()));
	}

	selectAllVideoContainersWithCameras$(): Observable<IAutomationMapVideoContainer[]> {
		return this.store.pipe(select(automationSelectors.selectAllVideoContainersWithCameras()));
	}

	selectAllAvailableCameras$(): Observable<ICamera[]> {
		return this.store.pipe(select(automationSelectors.selectAvailableCameras()));
	}

	selectAllWorkingCameras$(): Observable<IAutomationMapUsedCamera[]> {
		return this.store.pipe(select(automationSelectors.selectAllWorkingCameras()));
	}

	selectAvailableVideoContainersModal$(): Observable<IAutomationMapVideoContainerModal[]> {
		return combineLatest([
			this.selectAllAvailableCameras$(),
			this.selectAllVideoContainers$()
		])
			.pipe(
				map(response => {
					const [cameraList, videoContainers] = response;

					return videoContainers.map((videoContainerItem, videoContainerIndex) => {
						const id = videoContainerIndex + 1;
						const { cameraId, deviceId } = videoContainerItem;
						const selectedCamera = cameraList.find(item => item.CamId === cameraId && item.DevId === deviceId)?.id || '-1';
						const availableCameras = cameraList as unknown as IAutomationMapCamera[];

						return { selectedCamera, availableCameras, videoContainers, id };
					});
				})
			);
	}

	setVideoContainersLength(numberOfVideoContainers: string): void {
		const payload = { numberOfVideoContainers };

		this.store.dispatch(automationActions.setNumberOfVideoContainers({ payload }));
	}

	startLiveFetchCameraBackground(videoContainer: IAutomationMapVideoContainer, selectedCamera: IAutomationMapCamera): void {
		const payload = { videoContainer, selectedCamera };

		this.store.dispatch(automationActions.startLiveFetchCameraBackground({ payload }));
	}

	removeUsedVideoContainers(): void {
		this.store.dispatch(automationActions.removeAllVideoContainers());
	}

	updateVideoContainerInfo(videoContainer: AutomationVideoContainerUpdate): void {
		const payload = { videoContainer };

		this.store.dispatch(automationActions.updateVideoContainer({ payload }));
	}

	fetchAllWorkingCameras(): void {
		this.store.dispatch(automationActions.startFetchAllWorkingCameras());
	}

	setVideoContainersFromMap(videoContainers: IAutomationMapVideoContainer[], usedCameras: IAutomationMapUsedCamera[]): void {
		this.addUsedCameras(usedCameras);
		this.addUsedVideoContainers(videoContainers);
	}

	addUsedCameras(usedCameras: IAutomationMapUsedCamera[]): void {
		const usedCamerasPayload = { usedCameras };

		this.store.dispatch(automationActions.setAllUsedCameras({ payload: usedCamerasPayload }));
	}

	addUsedVideoContainers(videoContainers: IAutomationMapVideoContainer[]): void {
		const videoContainersPayload = { videoContainers };

		this.store.dispatch(automationActions.addUsedVideoContainers({ payload: videoContainersPayload }));
	}

	addUsedCamera(usedCamera: IAutomationMapUsedCamera): void {
		const payload = { usedCamera };

		this.store.dispatch(automationActions.addUsedCamera({ payload }));
	}

	removeUsedCamera(usedCameraId: string): void {
		const payload = { usedCameraId };

		this.store.dispatch(automationActions.removeUsedCamera({ payload }));
	}

	setCameraToNormalMode(cameraId: number, deviceId: number): void {
		const payload = { cameraId, deviceId };

		this.store.dispatch(automationActions.setCameraToNormalMode({ payload }));
	}

	cancelLiveBackgroundCameraOutput(): void {
		this.store.dispatch(automationActions.cancelLiveBackgroundCameraOutput());
	}

	setAllUsedCamerasToNormalMode(): void {
		this.store.dispatch(automationActions.setAllCamerasUsedToNormalMode());
	}

	removeAllUsedCameras(): void {
		this.store.dispatch(automationActions.removeAllUsedCameras());
	}

	removeAllAvailableCameras(): void {
		this.store.dispatch(automationActions.removeAllAvailableCameras());
	}
}
