import { Injectable } from '@angular/core';
import { ISelectData } from '../../../../../ts/models/select-data.model';
import { IOpcPanelUser } from '../../../ts/models/opc-panel-user.model';
import { IOpcPanelGroup } from '../../../ts/models/opc-panel-group.model';
import { OpcPanelInterface } from '../../../ts/enums/opc-panel-interface.enum';
import { IOpcPanelUserRecord } from '../../../ts/models/session/opc-panel-user-record.model';

@Injectable({
	providedIn: 'root'
})
export class OpcPanelSettingsUsersService {

	constructor() { }

	formatUsersTableData(opcGroupData: IOpcPanelGroup[]): IOpcPanelUser[] {
		const availableGroups = this.getAvailableGroups(opcGroupData);

		return opcGroupData.reduce((accumulator, groupData) => {
			const { users, groupName } = groupData;

			if (users.length) {
				const usersFormatted = users.map(userItem => {
					const { id, rowDisabled, userInterfacesData } = userItem;
					const { abilis, Alias } = userInterfacesData[0];
					const availableInterfaces = userInterfacesData.map(item => item.interface.toLowerCase());

					const voVisible = availableInterfaces.includes(OpcPanelInterface.VO.toLowerCase());
					const sipVisible = availableInterfaces.includes(OpcPanelInterface.SIP.toLowerCase());
					const ctipVisible = availableInterfaces.includes(OpcPanelInterface.CTIP.toLowerCase());
					const clusVisible = availableInterfaces.includes(OpcPanelInterface.CLUS.toLowerCase());

					const [voEnabled, sipEnabled, ctipEnabled, clusEnabled] = this.getInterfacesEnabledValues(userInterfacesData);

					const voImage = this.getPreviewImage(voVisible && voEnabled);
					const sipImage = this.getPreviewImage(sipVisible && sipEnabled);
					const ctipImage = this.getPreviewImage(ctipVisible && ctipEnabled);
					const clusImage = this.getPreviewImage(clusVisible && clusEnabled);

					return {
						id, abilis, alias: Alias, groupName, voEnabled, sipEnabled,
						voVisible, sipVisible, ctipEnabled, clusEnabled, voImage, sipImage,
						ctipImage, clusImage, availableGroups, ctipVisible, clusVisible, rowDisabled
					};
				});

				return [...accumulator, ...usersFormatted];
			}

			return accumulator;
		}, [] as IOpcPanelUser[]);
	}

	getAvailableGroups(opcGroupData: IOpcPanelGroup[]): ISelectData[] {
		return opcGroupData.map(opcGroupItem => ({ value: opcGroupItem.groupName, uiValue: opcGroupItem.groupName }));
	}

	getInterfacesEnabledValues(userInterfacesData: IOpcPanelUserRecord[]): boolean[] {
		const voEnabled = this.checkIfInterfaceIsEnabled(userInterfacesData, OpcPanelInterface.VO.toLowerCase());
		const sipEnabled = this.checkIfInterfaceIsEnabled(userInterfacesData, OpcPanelInterface.SIP.toLowerCase());
		const ctipEnabled = this.checkIfInterfaceIsEnabled(userInterfacesData, OpcPanelInterface.CTIP.toLowerCase());
		const clusEnabled = this.checkIfInterfaceIsEnabled(userInterfacesData, OpcPanelInterface.CLUS.toLowerCase());

		return [voEnabled, sipEnabled, ctipEnabled, clusEnabled];
	}

	checkIfInterfaceIsEnabled(userInterfacesData: IOpcPanelUserRecord[], interfaceName: string): boolean {
		return !!userInterfacesData.find(interfaceItem => interfaceItem.interface.toLowerCase() === interfaceName)?.enabled;
	}

	getPreviewImage(interfaceEnabled: boolean): string {
		return !interfaceEnabled ? '' : 'check-square-blue.svg';
	}
}
