import { Injectable } from '@angular/core';
import { ID_SPLIT_CHARACTER } from '../utils/opc-panel-util';
import { OpcPanelModal } from '../ts/enums/opc-panel-modal.enum';
import { OpcPanelInterface } from '../ts/enums/opc-panel-interface.enum';
import { OpcPanelUserRecordState } from '../ts/enums/opc-panel-user-record-state.enum';

@Injectable({
	providedIn: 'root'
})
export class OpcPanelUtilService {

	constructor() { }

	static updatePhoneNumber(telephoneNumber: string): string {
		const formattedNumber = telephoneNumber.trim().replace(/([\s\(\)\-])/g, '').replace(/^[+]+/, '+');
		const digitIndex = formattedNumber.search(/[^+\d]/);

		if (digitIndex > -1) {
			return formattedNumber.substring(0, formattedNumber.search(/[^+\d]/)) + '#';
		}

		return formattedNumber + '#';
	}

	static areNumberAndPrefixCorrect(phoneNumber: string, phonePrefix: string): boolean {
		const updatedPrefix = typeof phonePrefix === 'string' ? phonePrefix : '';
		const updatedPhoneNumber = typeof phoneNumber === 'string' ? phoneNumber : '';

		if (updatedPhoneNumber) {
			const correctPrefixRegExp = new RegExp('^\\d{0,8}$');
			const correctNumRegExp = new RegExp('^\\+{0,1}\\d{1,20}#$');

			if (correctNumRegExp.test(updatedPhoneNumber) && correctPrefixRegExp.test(updatedPrefix)) {
				const numericPart = updatedPhoneNumber.replace(new RegExp('[\\+#]*', 'g'), '');
				// the length of prefix + numeric part of number must be <= 20 characters
				return numericPart.length > 0 && (numericPart + updatedPrefix).length <= 20;
			}
		}

		return false;
	}

	static showAdditionalCardControl(userRecordState: OpcPanelUserRecordState): boolean {
		return userRecordState === OpcPanelUserRecordState.ONHOOK || userRecordState === OpcPanelUserRecordState.READY;
	}

	static showTransferCallModal(modalType: OpcPanelModal): boolean {
		return modalType === OpcPanelModal.TRANSFER
		|| modalType === OpcPanelModal.CALL_FROM_CARD
		|| modalType === OpcPanelModal.TRANSFER_FROM_CARD
		|| modalType === OpcPanelModal.TRANSFER_FROM_QUEUE;
	}

	static getTransferCallModalTitle(modalType: OpcPanelModal): string {
		return modalType === OpcPanelModal.CALL_FROM_CARD ? 'Call to' : 'Transfer call';
	}

	static getTransferCallInputLabel(modalType: OpcPanelModal): string {
		if (modalType === OpcPanelModal.CALL_FROM_CARD) {
			return 'Call to number not listed in the table below:';
		}

		return 'Transfer call to number not listed in the table below:';
	}

	static getTransferCallButtonLabel(modalType: OpcPanelModal): string {
		return modalType === OpcPanelModal.CALL_FROM_CARD ? 'Call' : 'Transfer';
	}

	static createOpcItemId(abilis: string, user: string, userInterface: OpcPanelInterface): string {
		return `${abilis}${ID_SPLIT_CHARACTER}${user}${ID_SPLIT_CHARACTER}${userInterface}`;
	}

	static createUserId(abilis: string, user: string | undefined): string {
		return `${abilis}${ID_SPLIT_CHARACTER}${user}`;
	}
}
