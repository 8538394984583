import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PhoneUtilService } from './../phone-util.service';
import { ToastrType } from '../../../../ts/enums/toastr-type.enum';
import { PhoneCallsService } from './../calls/phone-calls.service';
import { PhoneNgrxActionService } from '../ngrx/phone-ngrx-action.service';
import { MainUtilService } from './../../../../services/main-util.service';
import { IPhoneCallRecord } from '../../ts/models/phone-call-record.model';
import { IPhoneCallRecordInfo } from '../../ts/models/phone-call-record-info.model';
import { IOpcPanelCallRecord } from '../../../opc-panel/ts/models/session/opc-panel-call-record.model';
import { OpcPanelCallRecordState } from '../../../opc-panel/ts/enums/opc-panel-call-record-state.enum';
import { OpcPanelGroupHttpService } from '../../../opc-panel/services/group/opc-panel-group-http.service';
import { IOpcPanelSessionResponse } from '../../../opc-panel/ts/models/session/opc-panel-session-response.model';
import { IUserPreferencesActionResponse } from '../../../user-preferences/ts/models/user-preferences-action-response.model';
import { IOpcPanelSessionResponseUserInfo } from '../../../opc-panel/ts/models/session/opc-panel-session-response-user-info.model';

@Injectable({
	providedIn: 'root'
})
export class PhoneHttpUtilService {

	private static userInfo: IOpcPanelSessionResponseUserInfo;

	constructor(
		private readonly translate: TranslateService,
		private readonly mainUtilService: MainUtilService,
		private readonly phoneUtilService: PhoneUtilService,
		private readonly phoneCallsService: PhoneCallsService,
		private readonly phoneNgrxActionService: PhoneNgrxActionService,
		private readonly opcPanelGroupHttpService: OpcPanelGroupHttpService
	) { }

	public handleSetActionResponse(response: IUserPreferencesActionResponse): void {
		const { errcode, rsp } = response.Response;
		const responseText = errcode || rsp || '';
		const isCommandExecuted = PhoneUtilService.isCommandExecuted(responseText.toLowerCase());

		if (!isCommandExecuted && responseText) {
			this.mainUtilService.showToastrMessage(responseText, ToastrType.ERROR);
		}
	}

	public handleActionErrors(): void {
		const translatedInfo = this.translate.instant('PHONE-COMP.HTTP.errorAction');

		this.mainUtilService.showToastrMessage(translatedInfo, ToastrType.ERROR);
	}

	public checkToSetPhoneActivity(
		response: IOpcPanelSessionResponse, existingCallRecords: IPhoneCallRecord[], callRecordsInfo: IPhoneCallRecordInfo[]
	): void {
		const opcResponseValid = this.opcPanelGroupHttpService.isOpcResponseValid(response);

		if (!opcResponseValid) { return; }

		const userRecord = this.phoneCallsService.getLoggedUserCtipRecord(response);
		const newCallRecords = this.phoneCallsService.getLoggedUserCallRecords(response);

		if (userRecord) {
			this.phoneNgrxActionService.addUserRecord(userRecord);
		}

		PhoneHttpUtilService.setUserInfo(response.Response.CtiMonitor.UserInfo);

		if (!newCallRecords.length) { return; }

		this.phoneNgrxActionService.handleNewCallRecords(newCallRecords);
		this.handleMatchingCallRecords(existingCallRecords, callRecordsInfo, newCallRecords);
	}

	public static get getUserInfo(): IOpcPanelSessionResponseUserInfo {
		return PhoneHttpUtilService.userInfo;
	}

	private static setUserInfo(userInfo: IOpcPanelSessionResponseUserInfo): void {
		PhoneHttpUtilService.userInfo = userInfo;
	}

	private handleMatchingCallRecords(
		existingCallRecords: IPhoneCallRecord[], callRecordsInfo: IPhoneCallRecordInfo[], newCallRecords: IOpcPanelCallRecord[]
	): void {
		newCallRecords.forEach(newCallRecord => this.handleMatchingCallRecord(existingCallRecords, callRecordsInfo, newCallRecord));

		this.handleMultipleNullNewRecords(newCallRecords);
	}

	private handleMultipleNullNewRecords(newCallRecords: IOpcPanelCallRecord[]): void {
		const twoOrMoreRecords = newCallRecords.length >= 2;
		const allNullRecords = newCallRecords.every(callRecord => callRecord.State === OpcPanelCallRecordState.NULL);

		if (!twoOrMoreRecords || !allNullRecords) { return; }

		this.phoneNgrxActionService.pollFetchRecentCalls();
	}

	private handleMatchingCallRecord(
		existingCallRecords: IPhoneCallRecord[], callRecordsInfo: IPhoneCallRecordInfo[], newCallRecord: IOpcPanelCallRecord
	): void {
		const { State, CallID } = newCallRecord;

		switch (State) {
		case OpcPanelCallRecordState.HOLD:
			this.phoneNgrxActionService.setPriorityMode(false);
			break;
		case OpcPanelCallRecordState.ACTIVE:
		case OpcPanelCallRecordState.CALLING:
		case OpcPanelCallRecordState.ALERTING:
			this.phoneNgrxActionService.setInputValue(PhoneCallsService.getCallingNumber(newCallRecord));
			this.phoneNgrxActionService.setPriorityMode(false);
			break;
		case OpcPanelCallRecordState.NULL:
			this.handleNullState(existingCallRecords, callRecordsInfo, CallID);
			break;
		case OpcPanelCallRecordState.DISCONNECTING:
			this.phoneNgrxActionService.pollFetchRecentCalls();
			break;
		}
	}

	private handleNullState(existingCallRecords: IPhoneCallRecord[], callRecordsInfo: IPhoneCallRecordInfo[], CallID: number): void {
		const emptyOrSingleRecords = this.isNoOrSingleCallRecord(existingCallRecords);
		const matchingCallRecordInfo = PhoneCallsService.getMatchingCallRecordInfo(callRecordsInfo, CallID);

		if (emptyOrSingleRecords) {
			this.handleSingleCallEnd(matchingCallRecordInfo);
			this.phoneNgrxActionService.pollFetchRecentCalls();
			return;
		}

		this.handleCallEnd(matchingCallRecordInfo);
	}

	private isNoOrSingleCallRecord(callRecords: IPhoneCallRecord[]): boolean {
		return callRecords.length <= 1;
	}

	private wasCallUnsuccessfulOnEnd(callRecordInfo: IPhoneCallRecordInfo | null): boolean {
		return !!callRecordInfo && !callRecordInfo.states.includes(OpcPanelCallRecordState.ACTIVE);
	}

	private handleCallEnd(callRecordInfo: IPhoneCallRecordInfo | null): void {
		if (this.wasCallUnsuccessfulOnEnd(callRecordInfo)) { return; }

		this.phoneUtilService.handleUncheckTableRows();
	}

	private handleSingleCallEnd(callRecordInfo: IPhoneCallRecordInfo | null): void {
		if (this.wasCallUnsuccessfulOnEnd(callRecordInfo)) {
			this.phoneNgrxActionService.setClearNextInput();
			this.phoneNgrxActionService.clearCallRecordsAndCallInfo();
			return;
		}

		this.phoneNgrxActionService.clearPhoneStore();
		this.phoneUtilService.handleUncheckTableRows();
	}
}
