<app-antek-logo></app-antek-logo>

<app-tabs>
	<app-tab [active]="router.url.includes(getRoutesEnum.USERS_MANAGEMENT)"
		(selectTabEvent)="onAdminTabRouting(getRoutesEnum.USERS_MANAGEMENT)"
		[location]="'/#/administration;replaceUrl=true/(admin-preferences:admin-preferences)'">
		<app-tab-label>
			<img src="assets/feather-icons/user.svg" alt="admin-preferences">
			<span>{{'ADMIN.mainTab' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="admin-preferences"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.USERS_RIGHTS)"
		(selectTabEvent)="onAdminTabRouting(getRoutesEnum.USERS_RIGHTS)"
		[tooltip]="(userRightsPermission$ | async)?.tooltip || ''"
		[disabled]="(userRightsPermission$ | async)?.isFeatureDisabled"
		[location]="'/#/administration;replaceUrl=true/(users-rights:users-rights)'">
		<app-tab-label>
			<img src="assets/feather-icons/check-all.svg" alt="check-all">
			<span>{{'ADMIN.userRights' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="users-rights"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.VIRTUAL_OFFICE)"
		(selectTabEvent)="onAdminTabRouting(getRoutesEnum.VIRTUAL_OFFICE)"
		[disabled]="(voPermission$ | async)?.isFeatureDisabled" [tooltip]="(voPermission$ | async)?.tooltip || ''"
		[location]="'/#/administration;replaceUrl=true/(virtual-office:virtual-office)'">
		<app-tab-label>
			<img src="assets/feather-icons/package.svg" alt="check-all">
			<span>{{'ADMIN.virtualOffice' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="virtual-office"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.ALL_RECENT_CALLS)"
		(selectTabEvent)="onAdminTabRouting(getRoutesEnum.ALL_RECENT_CALLS)"
		[location]="'/#/administration;replaceUrl=true/(all-recent-calls:all-recent-calls)'">
		<app-tab-label>
			<img src="assets/feather-icons/clock.svg" alt="all-recent-calls">
			<span>{{'ADMIN.recentCalls' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="all-recent-calls"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.SYSTEM_PARAMETERS)"
		(selectTabEvent)="onAdminTabRouting(getRoutesEnum.SYSTEM_PARAMETERS)"
		[location]="'/#/administration;replaceUrl=true/(sys-parameters:sys-parameters)'">
		<app-tab-label>
			<img src="assets/feather-icons/sliders.svg" alt="parameters">
			<span>{{'ADMIN.SYSPARAMS.title' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="sys-parameters"></router-outlet>
		</app-tab-body>
	</app-tab>

	<!--
	<app-tab [active]="router.url.includes(getRoutesEnum.BLACKLIST)"
	(selectTabEvent)="onAdminTabRouting(getRoutesEnum.BLACKLIST)"
	[location]="'/#/administration;replaceUrl=true/(dns-blacklist: dns-blacklist)'"
	>
		<app-tab-label>
			BLACK LIST
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="dns-blacklist"></router-outlet>
		</app-tab-body>
	</app-tab>-->

	<app-tab [active]="router.url.includes(getRoutesEnum.NETWORK_ADMIN_FEATURES)"
	(selectTabEvent)="onAdminTabRouting(getRoutesEnum.NETWORK_ADMIN_FEATURES)"
	[location]="'/#/administration;replaceUrl=true/(network-admin-features:network-admin-features)'">
		<app-tab-label>
			<img src="assets/feather-icons/filter.svg" alt="filter">
			<span>
				<!-- TODO -->
				<!-- Create translation -->
				{{'NETWORK-SETTINGS.TABS.adminSettings' | translate }}
			</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="network-admin-features"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.SYSTEM_MANAGEMENT)"
	(selectTabEvent)="onAdminTabRouting(getRoutesEnum.SYSTEM_MANAGEMENT)"
	[location]="'/#/administration;replaceUrl=true/(system-management:system-management)'">
		<app-tab-label>
			<img src="assets/feather-icons/settings.svg" alt="settings">
			<span>{{'ADMIN.systemManagement' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="system-mangement"></router-outlet>
		</app-tab-body>
	</app-tab>
</app-tabs>
