export enum GeneratedFormComponentType {
	CONTAINER = 'container-for-parameters',
	SELECT = 'select',
	CUSTOM_SELECT = 'custom-select',
	BUTTON = 'button',
	STRING = 'string',
	NUMBER = 'number',
	CHECKBOX = 'checkbox',
	CUSTOM_CHECKBOX = 'custom-checkbox',
	REFERENCED = 'referenced-configuration'
}
