import { catchError } from 'rxjs/operators';
import { EMPTY, Observable, tap } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AddressBookService } from './../address-book.service';
import { ToastrType } from '../../../../../ts/enums/toastr-type.enum';
import { environment } from '../../../../../../environments/environment';
import { MainUtilService } from '../../../../../services/main-util.service';
import { IAddressBookContact } from '../../ts/models/address-book-contact.model';
import { AddressBookOperation } from '../../ts/types/address-book-operation.type';
import { IAddressBookSmsPayload } from '../../ts/models/address-book-sms-payload.model';
import { IAddressBookCoreContact } from '../../ts/models/address-book-core-contact.model';
import { IAddressBookOperationResponse } from '../../ts/models/address-book-operation-response.model';
import { IAddressBookAddEditContactInfo } from '../../ts/models/address-book-add-edit-contact-info.model';

@Injectable({
	providedIn: 'root',
})
export class AddressBookContactHttpService {

	private readonly http: HttpClient;
	private readonly translate: TranslateService;

	private readonly addContactsUrl: string = environment.abilisUrl + '/sys/user/addrbook_add.json';
	private readonly editContactsUrl: string = environment.abilisUrl + '/sys/user/addrbook_modify.json';
	private readonly deleteContactsUrl: string = environment.abilisUrl + '/sys/user/addrbook_delete.json';
	private readonly addressBookActionUrl: string = environment.abilisUrl + '/sys/user/addrbook_command.json';

	private readonly addAdminContactsUrl: string = environment.abilisUrl + '/sys/admin/users/addrbook_add.json';
	private readonly editAdminContactsUrl: string = environment.abilisUrl + '/sys/admin/users/addrbook_modify.json';
	private readonly deleteAdminContactsUrl: string = environment.abilisUrl + '/sys/admin/users/addrbook_delete.json';

	constructor(
		private readonly mainUtilService: MainUtilService,
		private readonly addressBookService: AddressBookService
	) {
		this.http = inject(HttpClient);
		this.translate = inject(TranslateService);
	}

	/**
	* Adds new contact
	* @param {string} username Owner of Address Book
	* @param {IAddressBookContact} formContact Address Book contact to add
	* @param {boolean} overwrite If Address Book contact should be overwritten
	*/
	public addContact$(username: string, formContact: IAddressBookCoreContact, overwrite: boolean): Observable<AddressBookOperation> {
		const { type, name, od, num, email, tag } = formContact;

		const encodedUrlBody = new HttpParams()
			.append('type', type)
			.append('user', username)
			.append('contact_name', name)
			.append('contact_prefix', od)
			.append('contact_num', num)
			.append('contact_email', email)
			.append('contact_tag', tag)
			.append('overwrite', this.getOverwriteState(overwrite));

		return this.http.post<IAddressBookOperationResponse>(`${this.getAddContactUrl}?user=${username}`, encodedUrlBody)
			.pipe(
				catchError(this.handleHTTPErrors$.bind(this, this.translate.instant('ADDRESS-BOOK.MAIN.errorAddingContact')))
			);
	}

	/**
	* Handles editing of existing contact
	* @param {IAddressBookAddEditContactInfo} payload Data which will contain:
	* - username - Owner of Address Book
	* - selectedContact - Already existing contact
	* @param {IAddressBookContact} formContact Address Book contact to add
	* @param {boolean} overwrite If Address Book contact should be overwritten
	*/
	public editContact$(
		payload: IAddressBookAddEditContactInfo, formContact: IAddressBookCoreContact, overwrite: boolean
	): Observable<AddressBookOperation> {
		const { username, selectedContact } = payload;

		if (!selectedContact) { return EMPTY; }

		const { type, name, od, num, email, tag } = formContact;
		const { name: selectedContactName, type: selectedContactType } = selectedContact;

		const encodedUrlBody = new HttpParams()
			.append('name', selectedContactName)
			.append('type', selectedContactType)
			.append('user', username)
			.append('contact_name', name)
			.append('contact_type', type)
			.append('contact_prefix', od)
			.append('contact_num', num)
			.append('contact_email', email)
			.append('contact_tag', tag)
			.append('overwrite', this.getOverwriteState(overwrite));

		return this.http.post<IAddressBookOperationResponse>(this.getEditContactUrl, encodedUrlBody)
			.pipe(
				catchError(this.handleHTTPErrors$.bind(this, this.translate.instant('ADDRESS-BOOK.MAIN.errorEditingContact')))
			);
	}

	/**
	* Handles deletion of existing contact
	* @param {IAddressBookContact} selectedContact Already existing contact
	* @param {string} username - Owner of Address Book
	*/
	public deleteContact$(selectedContact: IAddressBookContact, username: string): Observable<AddressBookOperation> {
		const { name, type } = selectedContact;

		const encodedUrlBody = new HttpParams()
			.append('name', name)
			.append('type', type)
			.append('user', username);

		return this.http.post<IAddressBookOperationResponse>(this.getDeleteContactUrl, encodedUrlBody)
			.pipe(
				catchError(this.handleHTTPErrors$.bind(this, this.translate.instant('ADDRESS-BOOK.MAIN.errorDeletingContact')))
			);
	}

	/**
	* Sends sms
	* @param {IAddressBookSmsPayload} payload Contains:
	* - num - number to send sms
	* - smstext - sms text
	*/
	public sendSms$(payload: IAddressBookSmsPayload): Observable<IAddressBookOperationResponse | void> {
		return this.http.post<IAddressBookOperationResponse>(this.addressBookActionUrl, { ...payload, action: 'sms' })
			.pipe(
				tap(response => this.handleSendSmsResponse(response)),
				catchError(this.handleHTTPErrors$.bind(this, this.translate.instant('ADDRESS-BOOK.MAIN.errorExecutingAction')))
			);
	}

	/**
	* Handles HTTP response of sending SMS
	* @param {IAddressBookOperationResponse} response HTTP response of send SMS action
	*/
	private handleSendSmsResponse(response: IAddressBookOperationResponse): void {
		const { code, rsp, message } = response.Response;

		if (!code) { return; }

		this.mainUtilService.showToastrMessage(message || rsp, ToastrType.ERROR);
	}

	/**
	* Handles HTTP error response
	* @param {string} errorText HTTP response error text
	*/
	private handleHTTPErrors$(errorText: string): Observable<void> {
		this.mainUtilService.showToastrMessage(errorText, ToastrType.ERROR);

		return EMPTY;
	}

	/**
	* Convert boolean overwrite state to yes|no type
	* @param {boolean} overwrite Overwrite state
	*/
	private getOverwriteState(overwrite: boolean): 'yes' | 'no' {
		return overwrite ? 'yes' : 'no';
	}

	/**
	* Returns appropriate delete URL
	*/
	private get getDeleteContactUrl(): string {
		const isAdminBook = this.addressBookService.checkIfAdminAddressBook();

		return !isAdminBook ? this.deleteContactsUrl : this.deleteAdminContactsUrl;
	}

	/**
	* Returns appropriate edit URL
	*/
	private get getEditContactUrl(): string {
		const isAdminBook = this.addressBookService.checkIfAdminAddressBook();

		return !isAdminBook ? this.editContactsUrl : this.editAdminContactsUrl;
	}

	/**
	* Returns appropriate add URL
	*/
	private get getAddContactUrl(): string {
		const isAdminBook = this.addressBookService.checkIfAdminAddressBook();

		return !isAdminBook ? this.addContactsUrl : this.addAdminContactsUrl;
	}
}
