import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ISelectData } from '../../../../ts/models/select-data.model';
import { UserPreferencesTabs } from '../../ts/enums/user-preferences-tabs.enum';
import { UserLevel } from '../../../../components/auth/ts/enums/user-level.enum';
import { IUserPreferencesActionResponse } from '../../ts/models/user-preferences-action-response.model';
import { IUserPreferencesPrivilegeSectioData } from '../../ts/models/user-preferences-privilege-section.model';
import { IUserPreferencesPrivilegeParameters } from '../../ts/models/user-preferences-privilege-parameters.model';

@Injectable({
	providedIn: 'root'
})
export class PrivilegePreferencesService {

	private selectedPrivilegeItemUsername: BehaviorSubject<string> = new BehaviorSubject<string>('');
	private privilegeSectionData: BehaviorSubject<IUserPreferencesPrivilegeSectioData | null> = new BehaviorSubject
	<IUserPreferencesPrivilegeSectioData | null>(null);
	private selectedPrivilegePhoneTab: BehaviorSubject<UserPreferencesTabs | null> = new BehaviorSubject<UserPreferencesTabs | null>(null);

	constructor() { }

	setSelectedPrivilegePhoneTab(phoneSectionTab: UserPreferencesTabs | null): void {
		this.selectedPrivilegePhoneTab.next(phoneSectionTab);
	}

	getSelectedPrivilegePhoneTab$(): Observable<UserPreferencesTabs | null> {
		return this.selectedPrivilegePhoneTab.asObservable();
	}

	setPrivilegeSectionData(privilegeSectionData: IUserPreferencesPrivilegeSectioData | null): void {
		this.privilegeSectionData.next(privilegeSectionData);
	}

	getPrivilegeSectionData$(): Observable<IUserPreferencesPrivilegeSectioData | null> {
		return this.privilegeSectionData.asObservable();
	}

	setSelectedPrivilegeItemUsername(username: string): void {
		this.selectedPrivilegeItemUsername.next(username);
	}

	getSelectedPrivilegeItemUsername$(): Observable<string> {
		return this.selectedPrivilegeItemUsername.asObservable();
	}

	getAddressBookSyncData$(value: string): Observable<ISelectData[]> {
		const sysUiValue = this.translateSysDropdown(value);

		return of([
			{ value: sysUiValue, uiValue: sysUiValue },
			{ value: 'NO', uiValue: 'NO' },
			{ value: 'LDAP', uiValue: 'LDAP' },
			{ value: 'ABILIS', uiValue: 'ABILIS' },
			{ value: 'ALL', uiValue: 'ALL' }
		]);
	}

	getSipTypes$(userPrivilegeData: IUserPreferencesPrivilegeParameters): Observable<ISelectData[]> {
		const username = userPrivilegeData.USER.VALUE;

		if (username !== 'guest') {
			return of([
				{ value: 'PHONE', uiValue: 'PHONE' },
				{ value: 'LOCAL-PEER', uiValue: 'LOCAL-PEER' },
				{ value: 'REMOTE-PEER', uiValue: 'REMOTE-PEER' },
				{ value: 'SERVER', uiValue: 'SERVER' },
				{ value: 'NNI', uiValue: 'NNI' }
			]);
		}

		return of([{ value: 'PHONE', uiValue: 'PHONE' }]);
	}

	getSipLocalPortValue(userPrivilegeData: IUserPreferencesPrivilegeParameters): number {
		const { 'locport-base': locportBase, 'locport-range': locportRange, locport } = userPrivilegeData.CtiSip;

		let newLocport = locportBase;
		const upperLocportBorder = locportBase + locportRange;

		while (newLocport === locport && newLocport <= upperLocportBorder) {
			newLocport += 1;
		}

		return newLocport;
	}

	translateSysDropdown(value: string): string {
		return value?.toString().toLowerCase().includes('sys') ? value : 'SYS';
	}

	getAddressBookEditable$(value: string): Observable<ISelectData[]> {
		const sysUiValue = this.translateSysDropdown(value);

		return of([
			{ value: sysUiValue, uiValue: sysUiValue },
			{ value: 'NO', uiValue: 'COMMON.no' },
			{ value: 'YES', uiValue: 'COMMON.yes' }
		]);
	}

	get getVoChannels$(): Observable<ISelectData[]> {
		return of([
			{ value: 1, uiValue: 1 },
			{ value: 2, uiValue: 2 },
			{ value: 3, uiValue: 3 },
			{ value: 4, uiValue: 4 },
			{ value: 5, uiValue: 5 },
			{ value: 6, uiValue: 6 },
			{ value: 7, uiValue: 7 },
			{ value: 8, uiValue: 8 },
			{ value: 9, uiValue: 9 },
			{ value: 10, uiValue: 10 }
		]);
	}

	get getOpcRoles$(): Observable<ISelectData[]> {
		return of([
			{ value: 'USER', uiValue: 'USER' },
			{ value: 'OPERATOR', uiValue: 'OPERATOR' }
		]);
	}

	get getAddressBookNumbers$(): Observable<ISelectData[]> {
		return of([
			{ value: 'NONE', uiValue: 'NONE' },
			{ value: 'AUTO', uiValue: 'AUTO' },
			{ value: 'CTIP', uiValue: 'CTIP' },
			{ value: 'CLUS', uiValue: 'CLUS' },
			{ value: 'CTISIP', uiValue: 'CTISIP' },
			{ value: 'CTIIAX', uiValue: 'CTIIAX' },
			{ value: 'CTIVO', uiValue: 'CTIVO' }
		]);
	}

	showPrivilegesActions(user: string, userLevel: UserLevel): boolean {
		return userLevel === UserLevel.BASIC ? false : !['super', 'admin'].includes(user.toLowerCase());
	}

	showDetails(userLevel: UserLevel): boolean {
		return userLevel !== UserLevel.BASIC;
	}

	isActionSuccessful(response: IUserPreferencesActionResponse | null): boolean {
		const rsp = response?.Response.rsp;

		if (response && rsp) {
			return rsp.toLowerCase().includes('command executed');
		}

		return false;
	}

	getPrivilegeCheckboxChangeKey(tableRowIndex: number): string {
		switch (tableRowIndex) {
		case 0:
			return 'ACT';
		case 3:
			return 'HTTP-GUI-NETWORK';
		case 4:
			return 'HTTP-GUI-PHONE';
		case 5:
			return 'HTTP-GUI-AUTO';
		case 6:
			return 'HTTP-GUI-VIDEO';
		case 7:
			return 'HTTP-GUI-TOOLS';
		default:
			return 'ACT';
		}
	}
}
