import { Store } from '@ngrx/store';
import { Injectable, inject } from '@angular/core';
import { IAppState } from '../../../../../root-reducers';
import { IAddressBookContact } from '../../ts/models/address-book-contact.model';
import { IAddressBookCoreContact } from '../../ts/models/address-book-core-contact.model';
import { IAddressBookEditContactPayload } from '../../ts/models/address-book-edit-contact-payload.model';
import {
	IGenericTableImmediateControlData,
	IGenericTableImmediateControlDataUnkow
} from '../../../../ui-components/generic-table/ts/models/generic-table-immediate-control-data.model';
import * as addressBookActions from '../../ngrx/address-book.action';

@Injectable({
	providedIn: 'root',
})
export class AddressBookNgrxActionService {

	private readonly store: Store<IAppState>;

	constructor() {
		this.store = inject(Store<IAppState>);
	}

	/**
	 * Handles fetching of Address Book Permission Info
	 * This info is object: { smsenabled, pubprotected, callenabled_ctip, callenabled_sip };
	 */
	public fetchAddressbookPermissionInfo(): void {
		this.store.dispatch(addressBookActions.fetchAddressbookPermissionInfo());
	}

	/**
	 * Cancels Address Book HTTP Observables, when onDestroy method is called
	 */
	public cancelAddressBookObservables(): void {
		this.store.dispatch(addressBookActions.cancelAddressBookHTTPObservables());
	}

	/**
	 * Loads Address Book table contacts
	 * @param {string} username Passed username for which Address Book contacts will load
	 */
	public fetchAddressBook(username: string): void {
		this.store.dispatch(addressBookActions.fetchAddressBook({ payload: { username } }));
	}

	/**
	 * Handles editing of Address Book contact
	 * @param {IAddressBookEditContactPayload} payload Passed payload which contains info for contact edit
	 */
	public editAddressBookContact(payload: IAddressBookEditContactPayload): void {
		this.store.dispatch(addressBookActions.editContact({ payload }));
	}

	/**
	 * Adds new Address Book contact
	 * @param {IAddressBookContact} formContact New contact form values
	 * @param {string} username New contact's username
	 */
	public addNewContact(formContact: IAddressBookCoreContact, username: string): void {
		const payload = { formContact, username };

		this.store.dispatch(addressBookActions.addContact({ payload }));
	}

	/**
	 * Deletes selected Address Book contacts
	 * @param {string} username Passed username for which selected contacts will be deleted
	 */
	public deleteContacts(username: string): void {
		const payload = { username };

		this.store.dispatch(addressBookActions.deleteContacts({ payload }));
	}

	/**
	 * Exports selected contacts into CSV
	 * @param {string} username Username is required to be included in the exported filename
	 */
	public exportCsv(username: string): void {
		const payload = { username };

		this.store.dispatch(addressBookActions.exportContacts({ payload }));
	}

	/**
	 * Handles formatting of control data into Address Book contact and update ngrx store's selected contact checkbox state
	 * @param {IGenericTableImmediateControlData} controlData Contains info about:
	 * 1. tableDataItem = JSON object of selected row
	 * 2. controlValue = If selected checkbox is checked/unchecked
	 */
	public handleContactCheckboxChange(controlData: IGenericTableImmediateControlDataUnkow): void {
		const { controlValue: checkboxState, tableDataItem } = controlData;
		const updatedAddressbookItem = { ...tableDataItem, checkboxState } as IAddressBookContact;

		this.updateCheckedContact(updatedAddressbookItem);
	}

	/**
	 * Updates ngrx store's selected contact checkbox state
	 * @param {IAddressBookContact} contact Address Book contact to update
	 */
	public updateCheckedContact(contact: IAddressBookContact): void {
		const payload = { contact };

		this.store.dispatch(addressBookActions.updateSingleCheckedContact({ payload }));
	}

	/**
	 * Updates ngrx store's rowDisabled property of contact
	 * @param {IAddressBookContact} contact Address Book contact to update
	 */
	public setContactRowDisabledValue(contact: IAddressBookContact): void {
		const payload = { contact };

		this.store.dispatch(addressBookActions.markContactAsUnselectable({ payload }));
	}

	/**
	 * Sets loading flag to Address Book table
	 * @param {boolean} isLoading If true, loading text will be presented in the Address Book table, if false, no loading indicator will be there
	 */
	public setIsLoading(isLoading: boolean): void {
		const payload = { isLoading };

		this.store.dispatch(addressBookActions.setIsLoadingValue({ payload }));
	}

	/**
	 * Either checks/unchecks all Address Book contacts
	 * @param {boolean} isChecked If true, all Address Book contacts would be checked, else Address Book contacts are unchecked
	 */
	public setCheckedContactsState(isChecked: boolean): void {
		const payload = { isChecked };

		this.store.dispatch(addressBookActions.setCheckedContactsState({ payload }));
	}

	public changedCurretlyVisibleContacts(visibilityList: IAddressBookContact[]) {
		this.store.dispatch(addressBookActions.changedCurretlyVisibleContacts({ payload: { visibleContacts: visibilityList } }));
	}
}
