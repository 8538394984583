export const SDWAN_UNIT_WIDTH = 1;
export const NUMBER_OF_NETWORKING_UNITS = 1079;

export const NETWORK_LOG_UP = '<img src="assets/feather-icons/status-ok.svg" alt="online" title="Online">';
export const NETWORK_LOG_DOWN = '<img src="assets/feather-icons/status-ko.svg" alt="offline" title="Offline">';

export enum NetworkingInfoQuality {
	CELLKEY_NETWORK_ERROR1 = -4,
	DOWN = -3,
	INACTIVE = -2,
	CELLKEY_NETWORK_ERROR2 = -1,
	GOOD = 0,
	FAIR = 1,
	POOR = 2,
	BAD = 3
}

export const badNetworkQualityScores = [
	NetworkingInfoQuality.CELLKEY_NETWORK_ERROR1, NetworkingInfoQuality.DOWN,
	NetworkingInfoQuality.INACTIVE, NetworkingInfoQuality.CELLKEY_NETWORK_ERROR2
];

export const okNetworkQualityScores = [
	NetworkingInfoQuality.GOOD, NetworkingInfoQuality.FAIR,
	NetworkingInfoQuality.POOR, NetworkingInfoQuality.BAD
];
