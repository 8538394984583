import { createSelector } from '@ngrx/store';
import { IAppState } from '../../../root-reducers';
import { PreferencesStoreKey } from '../ts/enums/preferences-store-key.enum';

export const selectPreferencesState = (select: IAppState) => select.preferences;

export const selectPreferences = () => createSelector(
	selectPreferencesState,
	preferencesState => preferencesState
);

export const selectFromPreferencesStore = (property: PreferencesStoreKey) => createSelector(
	selectPreferences(),
	preferencesState => preferencesState?.[property]
);
