import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RecentCallUrl } from '../../ts/enums/recent-call-url.enum';
import { ToastrType } from '../../../../../ts/enums/toastr-type.enum';
import { IRecentCall } from '../../ts/models/recent-call-table.model';
import { Observable, interval, of, take, forkJoin, EMPTY } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { MainUtilService } from '../../../../../services/main-util.service';
import { RecentCallsHttpUtilService } from './recent-calls-http-util.service';
import { IRecentCallResponse } from '../../ts/models/recent-call-response.model';
import { IUserListResponse } from '../../../../../ts/models/user-list-response.model';
import { IRecentCallActionResponse } from '../../ts/models/recent-call-action-response';
import { RecentCallNgrxSelectorService } from '../ngrx/recent-call-ngrx-selector.service';
import { IRecentCallDebugResponse } from '../../ts/models/recent-call-debug-response.model';
import { IUserListResponseItem } from '../../../../../ts/models/user-list-response-item.model';
import * as customOperators from '../../../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class RecentCallHttpService {

	private readonly http: HttpClient;
	private readonly translateService: TranslateService;

	private readonly unavailableCallUrl: string = environment.abilisUrl + '/sys/admin/debuglog.json';
	private readonly getConfiguredUsernamesUrl: string = environment.abilisUrl + '/sys/admin/getusernames.json';

	constructor(
		private readonly mainUtilService: MainUtilService,
		private readonly recentCallsHttpUtilService: RecentCallsHttpUtilService,
		private readonly recentCallNgrxSelectorService: RecentCallNgrxSelectorService
	) {
		this.http = inject(HttpClient);
		this.translateService = inject(TranslateService);
	}

	/**
	 * Handles fetching of Recent Calls for all provided users
	 * @param {string} loggedUserGroup Logged user's group
	 * @param {boolean} haveManagerRole Value to know if logged user have manager role
	 */
	public fetchAllUsersCalls$(loggedUserGroup: string, haveManagerRole: boolean): Observable<IRecentCall[]> {
		return this.fetchAdminRecentCallUsers$(loggedUserGroup, haveManagerRole)
			.pipe(
				switchMap(contacts => this.fetchSelectedUsersCalls$(contacts.map(contact => contact.user)))
			);
	}

	/**
	 * Handles fetching of additional info about why Recent Call is failed.
	 * This info is presented plain (without formatting) from the BE.
	 * @param {IRecentCall} recentCallItem Recent Call item
	 */
	public fetchDebugInfoAboutNACall$(recentCallItem: IRecentCall): Observable<string> {
		const { id, datetime } = recentCallItem;

		return this.http.get<IRecentCallDebugResponse>(this.unavailableCallUrl + `?id=${id}&datetime=${encodeURIComponent(datetime)}`)
			.pipe(
				map(response => {
					const { message, DebugLog } = response.Response;

					return message ? '' : this.recentCallsHttpUtilService.formatCallDebugInfo(DebugLog as string[]);
				}),
				customOperators.retryFromError$(1000)
			);
	}

	/**
	 * Handles fetching of Recent Calls users
	 * @param {string} loggedUserGroup Logged user's group
	 * @param {boolean} haveManagerRole Value to know if logged user have manager role
	 */
	public fetchAdminRecentCallUsers$(loggedUserGroup: string, haveManagerRole: boolean): Observable<IUserListResponseItem[]> {
		return this.http.get<IUserListResponse>(this.getConfiguredUsernamesUrl)
			.pipe(
				map(response => {
					const { Users, rsp } = response.Response;

					return rsp ? [] : this.formatUsersBasedOnRole(Users, loggedUserGroup, haveManagerRole);
				}),
				customOperators.retryFromError$(1000)
			);
	}

	/**
	 * Handles fetching of Recent Calls Log once
	 * @param {string} username Recent Call owner
	 * @param {IRecentCall | null} selectedRecentCallItem Selected Recent Call item
	 */
	public fetchRecentCalls$(username: string, selectedRecentCallItem?: IRecentCall | null): Observable<IRecentCall[]> {
		const urlQueryParameters = RecentCallsHttpUtilService.getRecentCallQueryParams(username);
		const fetchUrl = this.recentCallsHttpUtilService.getRequestUrl(RecentCallUrl.FETCH) + urlQueryParameters;

		return this.http.get<IRecentCallResponse>(fetchUrl)
			.pipe(
				map(response => this.recentCallsHttpUtilService.handleRecentCallsResponse(response, true, selectedRecentCallItem)),
				customOperators.retryFromError$(1000)
			);
	}

	/**
	 * Periodically (every minute) fetch Recent Calls Log
	 * @param {string} username Recent Call owner
	 */
	public pollFetchRecentCalls$(username: string): Observable<IRecentCall[]> {
		return interval(60 * 1000)
			.pipe(
				switchMap(() => this.recentCallNgrxSelectorService.getFirstSelectedRecentCallItem$().pipe(take(1))),
				switchMap(selectedRecentCallItem => this.fetchRecentCalls$(username, selectedRecentCallItem))
			);
	}

	/**
	 * Clears Recent Calls log
	 * @param {string} username Recent Call owner
	 */
	public clearRecentCallsLog$(username: string): Observable<IRecentCallActionResponse | void> {
		const usernameQueryParam = RecentCallsHttpUtilService.getRecentCallQueryParams(username);
		const fetchUrl = this.recentCallsHttpUtilService.getRequestUrl(RecentCallUrl.CLEAR) + usernameQueryParam;

		return this.http.get<IRecentCallActionResponse>(fetchUrl)
			.pipe(
				tap(response => {
					const message = response.Response.rsp;

					if (!this.recentCallsHttpUtilService.isErrorClearingRecentCallLog(message)) { return; }

					this.mainUtilService.showToastrMessage(message as string, ToastrType.ERROR);
				}),
				catchError(this.handleClearLogErrors$.bind(this))
			);
	}

	/**
	 * Returns Recent Calls for all provided usernames
	 * @param {string[]} usernames Recent Calls usernames
	 */
	private fetchSelectedUsersCalls$(usernames: string[]): Observable<IRecentCall[]> {
		if (!usernames.length) { return of([]); }

		return forkJoin(this.getAllUserCallLogRequests$(usernames))
			.pipe(
				map(response => this.recentCallsHttpUtilService.formatMultipleCalls(response)),
				customOperators.retryFromError$(1000)
			);
	}

	/**
	 * Makes HTTP request for Recent Calls of provided usernames
	 * @param {string[]} usernames Recent Calls usernames
	 */
	private getAllUserCallLogRequests$(usernames: string[]): Observable<IRecentCallResponse>[] {
		return usernames.map(username => {
			const urlQueryParameters = RecentCallsHttpUtilService.getRecentCallQueryParams(username);
			const urlToFetch = this.recentCallsHttpUtilService.getRequestUrl(RecentCallUrl.FETCH) + urlQueryParameters;

			return this.http.get<IRecentCallResponse>(urlToFetch);
		});
	}

	/**
	* Handles HTTP error of clearing Recent Call log
	*/
	private handleClearLogErrors$(): Observable<void> {
		const errorText = this.translateService.instant('ADDRESS-BOOK.MAIN.errorExecutingAction');

		this.mainUtilService.showToastrMessage(errorText, ToastrType.ERROR);

		return EMPTY;
	}

	/**
	 * Handles fetching of Recent Calls Log once
	 * @param {IUserListResponseItem[]} users Users list response
	 * @param {string} loggedUserGroup Logged user's group
	 * @param {boolean} haveManagerRole Value to know if logged user have manager role
	 */
	private formatUsersBasedOnRole(
		users: IUserListResponseItem[], loggedUserGroup: string, haveManagerRole: boolean
	): IUserListResponseItem[] {
		if (!haveManagerRole) { return users.slice(); }

		return this.recentCallsHttpUtilService.filterUsersByGroup(users, loggedUserGroup);
	}
}
