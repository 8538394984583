import { Action, createReducer, on } from '@ngrx/store';
import { IWioNodeModel } from '../ts/models/wio-node.model';
import { IRioRvsWioDevice } from '../ts/models/rio-rvs-wio-device.model';
import { EntityState, createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { RioRvsModalLineDeviceType } from '../ts/types/rio-rvs-modal-line-device.type';
import * as rioRvsActions from './rio-rvs.action';

export const rioRvsFeatureKey = 'rioRvs';

export interface IRioRvsState extends EntityState<IRioRvsWioDevice> {
	isLoading: boolean;
	wioSlaveNodes: IWioNodeModel[];
	isLoadingWioSlaveDevices: boolean;
	isLoadingRioRvsLineDevices: boolean;
	rioRvsLineDevices: RioRvsModalLineDeviceType[];
	selectedRioRvsLineDevice: IRioRvsWioDevice | null;
}

export const adapter: EntityAdapter<IRioRvsWioDevice> = createEntityAdapter<IRioRvsWioDevice>({
	selectId: (rioRvsDevice: IRioRvsWioDevice) => rioRvsDevice.customId
});

export const initialRioRvsState: IRioRvsState = adapter.getInitialState({
	isLoading: true,
	wioSlaveNodes: [],
	rioRvsLineDevices: [],
	selectedRioRvsLineDevice: null,
	isLoadingWioSlaveDevices: true,
	isLoadingRioRvsLineDevices: true
});

const rioRvsReducer = createReducer(
	initialRioRvsState,
	on(rioRvsActions.finishLoadingRioRvsDevices, (state, { payload }) =>
		adapter.setAll(payload.devices, { ...state, isLoading: false })),
	on(rioRvsActions.finishLoadingRioRvsWioLineDevices, (state, { payload }) =>
		({ ...state, isLoadingRioRvsLineDevices: false, rioRvsLineDevices: payload.rioRvsWioLineDevices })),
	on(rioRvsActions.finishLoadingWioSlaveNodes, (state, { payload }) =>
		({ ...state, isLoadingWioSlaveDevices: false, wioSlaveNodes: payload.wioSlaveNodes })),
	on(rioRvsActions.setLoadingStateAction, (state, { payload }) => ({ ...state, isLoading: payload.isLoading })),
	on(
		rioRvsActions.rioRvsWioLoadDevices,
		rioRvsActions.rioRvsBindDeviceAction,
		rioRvsActions.rioRvsUnbindDeviceAction,
		rioRvsActions.rioRvsReleaseDeviceAction,
		rioRvsActions.updateDeviceInformationAction,
		(state) => ({ ...state, isLoading: true })
	),
	on(rioRvsActions.saveLineDeviceChange, (state) => ({ ...state, isLoadingRioRvsLineDevices: true })),
	on(rioRvsActions.setSelectedDevice, (state, { payload }) => ({ ...state, selectedRioRvsLineDevice: payload.selectedDevice })),
	on(rioRvsActions.setLoadingFlagRioRvsLineDevices, (state, { payload }) =>
		({ ...state, isLoadingRioRvsLineDevices: payload.isLoading })),
	on(rioRvsActions.updateWioSlaveInformation, (state) => ({ ...state, isLoadingWioSlaveDevices: true })),
	on(rioRvsActions.setLoadingWioSlaveNodes, (state, { payload }) => ({ ...state, isLoadingWioSlaveDevices: payload.isLoading }))
);

export function RioRvsReducer(state: IRioRvsState | undefined, action: Action) {
	return rioRvsReducer(state, action);
}

export const { selectAll: selectAllRioRvsDevices } = adapter.getSelectors();
