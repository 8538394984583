import { CommonModule } from '@angular/common';
import { TabsService } from './services/tabs.service';
import { startWith, map, delay, take, filter } from 'rxjs';
import { TabComponent } from './components/tab/tab.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TabBodyComponent } from './components/tab-body/tab-body.component';
import { TabLabelComponent } from './components/tab-label/tab-label.component';
import { TabHeaderRenderComponent } from './components/tab-header-render/tab-header-render.component';
import { ChangeDetectionStrategy, Component, ContentChildren, QueryList, AfterContentInit, ChangeDetectorRef, inject } from '@angular/core';

@UntilDestroy()
@Component({
	selector: 'app-tabs',
	standalone: true,
	imports: [
		CommonModule,
		TabComponent,
		TabBodyComponent,
		TabLabelComponent,
		TabHeaderRenderComponent
	],
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class TabsComponent implements AfterContentInit {

	@ContentChildren(TabComponent, { descendants: true }) tabs: QueryList<TabComponent>;

	public tabComponents: TabComponent[] = [];
	public activeTabComponent: TabComponent | null;

	private readonly cdRef: ChangeDetectorRef;

	constructor(
		private readonly tabsService: TabsService
	) {
		this.cdRef = inject(ChangeDetectorRef);
	}

	public ngAfterContentInit(): void {
		this.setTabsContent();
		this.watchSetActiveTabComponent();
	}

	public onSelectTab(tabIndex: number) {
		if (this.isTabUnavailable(tabIndex)) { return; }

		this.handleSelectTab(tabIndex);
	}

	private handleSelectTab(tabIndex: number): void {
		this.setTabComponentState(false, tabIndex);
		this.markActiveTab(tabIndex);
	}

	private setTabsContent(): void {
		this.tabs.changes
			.pipe(
				untilDestroyed(this),
				startWith(null),
				map(() => this.tabs.toArray()),
				delay(0),
				take(1)
			)
			.subscribe(tabs => {
				this.tabComponents = tabs.slice();
				this.setTabComponentState(true, this.getFirstActiveTabIndex(this.tabComponents));

				if (this.isEveryTabInactive(tabs)) {
					this.emitActiveComponentEvent();
				}

				this.cdRef.markForCheck();
			});
	}

	private markActiveTab(tabIndex: number): void {
		this.setTabComponentState(true, tabIndex);
		this.emitActiveComponentEvent();
	}

	private setTabComponentState(state: boolean, tabIndex: number): void {
		this.setInactiveAllTabs();
		this.setTabComponentsActiveState(state, tabIndex);
		this.setActiveTabComponent();
	}

	private setTabComponentsActiveState(state: boolean, tabIndex: number): void {
		this.tabComponents = this.tabComponents.map((item, index) => {
			const active = tabIndex !== index ? item.active : state;

			return { ...item, active };
		}) as TabComponent[];
	}

	private setActiveTabComponent(): void {
		this.activeTabComponent = this.tabComponents.find(item => item.active) || null;
	}

	private setInactiveAllTabs(): void {
		this.tabComponents = this.tabComponents.map(item => ({ ...item, active: false })) as TabComponent[];
	}

	private emitActiveComponentEvent(): void {
		if (this.isActiveTabComponentNA()) { return; }

		this.emitActiveTabEvent();
	}

	private isActiveTabComponentNA(): boolean {
		return !this.activeTabComponent || !!this.activeTabComponent.disabled;
	}

	private isTabUnavailable(tabIndex: number): boolean {
		const matchingComponent = this.tabComponents[tabIndex];

		return this.activeTabComponent === matchingComponent || !!matchingComponent?.disabled;
	}

	private getFirstActiveTabIndex(tabs: TabComponent[]): number {
		const activeTabIndex = this.getActiveTabIndex(tabs);

		if (activeTabIndex !== -1) { return activeTabIndex; }

		const nonDisabledTabIndex = this.getNonDisabledTabIndex(tabs);

		if (nonDisabledTabIndex !== -1) { return nonDisabledTabIndex; }

		return 0;
	}

	private getActiveTabIndex(tabs: TabComponent[]): number {
		return tabs.findIndex(tab => tab.active && !tab.disabled);
	}

	private isEveryTabInactive(tabs: TabComponent[]): boolean {
		return tabs.every(tab => !tab.active);
	}

	private getNonDisabledTabIndex(tabs: TabComponent[]): number {
		return tabs.findIndex(tab => !tab.disabled);
	}

	private watchSetActiveTabComponent(): void {
		this.tabsService.watchActiveTabComponent$()
			.pipe(
				untilDestroyed(this),
				delay(0),
				filter(tabComponent => !!tabComponent.location),
				map(tabComponent => this.tabComponents.findIndex(component => component.location === tabComponent.location)),
				filter(tabIndex => tabIndex !== -1)
			)
			.subscribe(tabIndex => {
				this.handleSelectTab(tabIndex);
				this.emitActiveTabEvent();
			});
	}

	private emitActiveTabEvent(): void {
		(this.activeTabComponent as TabComponent).selectTabEvent.emit();
	}
}
