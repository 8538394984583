import { createAction, props } from '@ngrx/store';
import { ISelectData } from '../../../ts/models/select-data.model';
import { IVirtualOfficeTableItem } from '../ts/models/virtual-office-table-item.model';
import { IVirtualOfficeSystemForm } from '../ts/models/virtual-office-system-form.model';
import { IVirtualOfficeFormattedNumber } from '../ts/models/virtual-office-formatted-number.model';

export const loadSystemForm = createAction(
	'[Virtual Office] Load Vritual Office System Form'
);

export const cancelVOHttpObservables = createAction(
	'[Virtual Office] Cancel Virtual Office HTTP Observables'
);

export const finishLoadingSystemForm = createAction(
	'[Virtual Office] Load Virtual Office System Form Success',
	props<{ payload: { systemForm: IVirtualOfficeSystemForm } }>()
);

export const saveSystemFormValues = createAction(
	'[Virtual Office] Save Virtual Office System Form',
	props<{ payload: { systemForm: IVirtualOfficeSystemForm } }>()
);

export const loadUsers = createAction(
	'[Virtual Office] Load Vritual Office Users'
);

export const finishLoadingUsers = createAction(
	'[Virtual Office] Load Virtual Office Users Success',
	props<{ payload: { users: IVirtualOfficeTableItem[] } }>()
);

export const finishLoadingUserNumbers = createAction(
	'[Virtual Office] Load Virtual Office User Numbers Success',
	props<{ payload: { userNumbers: IVirtualOfficeFormattedNumber[] } }>()
);

export const finishLoadingAvailableNumbers = createAction(
	'[Virtual Office] Finish loading available numbers',
	props<{ payload: { availableNumbers: ISelectData[] } }>()
);
