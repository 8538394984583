import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutesEnum } from './../../../../ts/enums/routes.enum';
import { AuthService } from './../../../auth/services/auth.service';
import { AddressBookRouter } from './../ts/enums/address-book-router.enum';

@Injectable({
	providedIn: 'root',
})
export class AddressBookService {

	private readonly router: Router;
	private readonly translate: TranslateService;
	private readonly activatedRoute: ActivatedRoute;

	private adminAddressBook: boolean;

	constructor(
		private readonly authService: AuthService
	) {
		this.router = inject(Router);
		this.translate = inject(TranslateService);
		this.activatedRoute = inject(ActivatedRoute);
	}

	/**
	 * Sets if Address Book have admin role
	 * @param {boolean} adminAddressBook
	 */
	public setAdminAddressBookRole(adminAddressBook: boolean): void {
		this.adminAddressBook = adminAddressBook;
	}

	/**
	 * Returns admin AddressBook value
	 */
	public checkIfAdminAddressBook(): boolean {
		return this.adminAddressBook;
	}

	/**
	 * Returns if Address Book is in the other (not logged user) mode
	 */
	public isOtherMode(): boolean {
		return this.router.url.startsWith(`/${RoutesEnum.ADMINISTRATION}`);
	}

	/**
	 * Generates text for contacts import/export modal
	 * @param {string} username
	 */
	public generateHeaderTitleString(username: string): string {
		return this.translate.instant('PHONE.ADDBOOK.dlgImpExpTitle') + `<span class="active__selection"><b>${username}` +
			this.translate.instant('PHONE.ADDBOOK.dlgImpExpTitle2') + '</b></span>' +
			this.translate.instant('PHONE.ADDBOOK.dlgImpExpTitle3');
	}

	/**
	 * Navigates to Address Book
	 */
	public navigateToAddressBook(): void {
		const featureName = RoutesEnum.ADDRESSBOOK;
		const username = this.authService.getUsername();
		const outlets = { [featureName]: `${featureName}/${AddressBookRouter.USER}/${username}` };

		this.router.navigate([{ replaceUrl: true }, { outlets }], { relativeTo: this.activatedRoute });
	}
}
