export enum FeaturePermissionsName {
	// PHONE
	OPC_PANEL = 'opc',
	ROUTINGS = 'routings',
	STATISTICS = 'statistics',
	ADDRESSBOOK = 'addressbook',
	PREFERENCES = 'preferences',
	RECENT_CALLS = 'recentcalls',
	// TOOLS
	VM = 'vm',
	MOH = 'moh',
	SMS = 'sms',
	MIX = 'mix',
	DISA = 'disa',
	ALERT = 'alert',
	LISTS = 'lists',
	CONTROL_PORT = 'cp',
	DISK = 'disk-analyzer',
	// Added by FE
	AUDIO = 'audio',
	// AUTO
	LOGS = 'logs',
	MAPS = 'maps',
	SHEETS = 'sheets',
	IOHUB = 'iohub',
	PROGRAMS = 'programs',
	DISCOVERY = 'discovery',
	PORTS_CONFIGURATION = 'ports-configuration',
	// VIDEO
	VIDEORECORDER = 'vs',
	// NETWORKING
	WAN_PREFERENCES = 'wanpreferences',
	IP_SHAPING = 'ipshaping',
	HTTP = 'http',
	FTP = 'ftp',
	DNS = 'dns',
	DHCP = 'dhcp',
	ACL = 'acl',
	NAT = 'nat',
	NETWORK_ROUTINGS = 'network-routings',
	PORTS = 'ports',
	NEWS = 'news',
	LAN_MONITOR = 'lanmonitor',
	IPBAN = 'ipban',
	NETWORK_STATISTICS = 'network-statistics',
	// Added by FE
	NETWORK_SETTINGS = 'network-settings',
	// ADMIN
	ADMIN_HTTP = 'admin-http',
	ADMIN_FTP = 'admin-ftp',
	LDAP = 'ldap',
	VO = 'vo',
	CTI = 'cti',
	SIP = 'sip',
	IAX = 'iax',

	// Added by FE
	ADMIN_USER_RIGHTS = 'admin-user-rights',

	DNS_BLACKLIST = 'dns-blacklist',
	DNS_WHITELIST = 'dns-whitelist',
	ADMIN_NETWORKING = 'admin-networking'
}
