import { createAction, props } from '@ngrx/store';
import { IStore } from '../ts/models/store.model';
import { UserLevel } from '../ts/enums/user-level.enum';
import { ILoginVerification } from '../ts/models/login-verification-data.model';

export const loginAction = createAction(
	'[Auth] Login Action'
);

export const refreshUserPermissions = createAction(
	'[Auth] Refresh User Permissions'
);

export const refreshUserPermissionsFinished = createAction(
	'[Auth] Refresh User Permissions Finished',
	props<{ payload: { sessionData: IStore } }>()
);

export const set2FaVerificationData = createAction(
	'[Auth] Set 2Fa Verification Data',
	props<{ payload: { verification: ILoginVerification | null } }>()
);

export const reset2FaVerificationCode = createAction(
	'[Auth] Reset 2Fa Verification Code'
);

export const loginWith2FaVerificationCode = createAction(
	'[Auth] Login with 2Fa Verification Code',
	props<{ payload: { verificationCode: string } }>()
);

export const anonymousLoggedUser = createAction(
	'[Auth] Anonymous Logged User',
);

export const loginActionCompleted = createAction(
	'[Auth] Login Action Completed',
	props<{ payload: { sessionData: IStore } }>()
);

export const logoutAction = createAction(
	'[Auth] Logout Action'
);

export const logoutActionCompleted = createAction(
	'[Auth] Logout Action Completed',
	props<{ payload: { sessionData: IStore } }>()
);

export const clearLoginStore = createAction(
	'[Auth] Clear Login Store'
);

export const logoutNonAnonymousAction = createAction(
	'[Auth] Logout Non Anonymous Action'
);

export const checkForAnonymousSessionAction = createAction(
	'[Auth] Session Check Action For Anonymous User',
	props<{ payload: { sessionData: IStore } }>()
);

export const checkForNonAnonymousSessionAction = createAction(
	'[Auth] Session Check Action For Non Anonymous User',
	props<{ payload: { sessionData: IStore } }>()
);

export const updateUserLevel = createAction(
	'[Auth] Update User Level',
	props<{ payload: { userLevel: UserLevel } }>()
);
