import { filter, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { RoutesEnum } from './../../ts/enums/routes.enum';
import { NavigationStart, Router, ActivatedRoute, ActivationStart } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class RouterUtilService {

	private readonly router: Router;
	private readonly location: Location;

	constructor() {
		this.router = inject(Router);
		this.location = inject(Location);
	}

	public static matchesRouterUrl(currentUrl: string, urlToMatch: RoutesEnum): boolean {
		return currentUrl === `/${urlToMatch}`;
	}

	public static includesRouterUrl(currentUrl: string, urlToInclude: RoutesEnum): boolean {
		return currentUrl.includes(urlToInclude);
	}

	public static isNavigationStart(event: unknown): event is NavigationStart {
		return event instanceof NavigationStart;
	}

	public static isPopStateEvent(event: NavigationStart): boolean {
		return event.navigationTrigger === 'popstate';
	}

	public goBack(): void {
		this.location.back();
	}

	public navigateToRoute(route: string = ''): void {
		this.router.navigateByUrl(route);
	}

	public navigateToRouterOutlet(featureName: string, activatedRoute: ActivatedRoute): void {
		const outlets = { [featureName]: featureName };

		this.router.navigate([{ replaceUrl: true }, { outlets }], { relativeTo: activatedRoute });
	}

	public watchRouteOutletChange$(outlet: string): Observable<unknown> {
		return this.router.events
			.pipe(
				filter(event => event instanceof ActivationStart && event.snapshot.outlet === outlet)
			);
	}
}
