import { createAction, props } from '@ngrx/store';
import { INetworkingHttpForm } from '../ts/models/networking-http-form.model';
import { INetworkingHttp } from '../ts/models/networking-http.model';

export const startLoadingHttpConfiguration = createAction(
	'[HTTP] Start Loading HTTP Configuration'
);

export const finishLoadingHttpConfiguration = createAction(
	'[HTTP] Finish Loading HTTP Configuration',
	props<{ payload: { httpConfiguration: INetworkingHttp } }>()
);

export const setHttpConfigurationLoader = createAction(
	'[HTTP] Set HTTP Configuration Loader',
	props<{ payload: { isLoading: boolean } }>()
);

export const setHttpSettings = createAction(
	'[HTTP] Set HTTP Settings',
	props<{ payload: { formData: INetworkingHttpForm } }>()
);

export const applyHttpSettings = createAction(
	'[HTTP] Apply HTTP Settings'
);

export const cancelHttpObservables = createAction(
	'[HTTP] Cancel HTTP Observables'
);
