import { Injectable } from '@angular/core';
import { RioRvsService } from '../rio-rvs.service';
import { RioRvsNgrxService } from '../rio-rvs-ngrx.service';
import { RioRvsImageService } from '../rio-rvs-image.service';
import { RioRvsOwner } from '../../ts/enums/rio-rvs-owner.enum';
import { ToastrType } from '../../../../ts/enums/toastr-type.enum';
import { RioRvsTableService } from '../tables/rio-rvs-table.service';
import { EmptyObject } from '../../../../ts/types/empty-object.type';
import { IRioRvsResponse } from '../../ts/models/rio-rvs-response.model';
import { MainUtilService } from '../../../../services/main-util.service';
import { RioRvsCookieKey } from '../../ts/enums/rio-rvs-cookie-key.enum';
import { IRioRvsIconList } from '../../ts/models/rio-rvs-icon-list.model';
import { IRioRvsWioDevice } from '../../ts/models/rio-rvs-wio-device.model';
import { RioRvsWioDeviceType } from '../../ts/enums/rio-rvs-device-type.enum';
import { IActionResponse } from '../../../../ts/models/action-response.model';
import { IRioRvsPreferences } from '../../ts/models/rio-rvs-preferences.model';
import { RioRvsUpdatedOwner } from '../../ts/enums/rio-rvs-updated-owner.enum';
import { IRioRvsIohubDevice } from '../../ts/models/rio-rvs-iohub-device.model';
import { RioRvsLineDeviceTypes } from '../../ts/types/rio-rvs-line-devices.type';
import { RioRvsDiscoveryType } from '../../ts/enums/rio-rvs-discovery-type.enum';
import { RioRvsIohubDeviceType } from '../../ts/enums/rio-rvs-iohub-device-type.enum';
import { RioRvsModalLineDeviceType } from '../../ts/types/rio-rvs-modal-line-device.type';
import { AutomationGenericService } from '../../../automation/services/automation-generic.service';
import { IPortsConfigurationDeviceConf } from '../../ts/models/ports-configuration-device-conf.model';
import * as uuid from 'uuid';
import * as ip from 'ip';

@Injectable({
	providedIn: 'root'
})
export class RioRvsHttpLogicService {

	private readonly nonLineDeviceKeys = ['type', 'num', 'status', 'statusdesc', 'description'];

	private uuid: string;
	private rioRvsWioPreferences: IRioRvsPreferences;

	constructor(
		private rioRvsService: RioRvsService,
		private mainUtilService: MainUtilService,
		private rioRvsNgrxService: RioRvsNgrxService,
		private rioRvsTableService: RioRvsTableService,
		private rioRvsImageService: RioRvsImageService
	) { }

	formatDevicesInformation(discoveredDevices: IRioRvsWioDevice[]): IRioRvsWioDevice[] {
		return discoveredDevices.map(rioRvsDevice => {
			const { descr, type, id, owner, 'ipres-mask': ipresMask, 'default-gw': defaultGateway } = rioRvsDevice;


			const updatedOwner = this.formatOwnerField(owner);
			const resource = this.formatResourceField(type, id);
			const isResource = this.rioRvsService.isItemResource(rioRvsDevice);
			const translateOwnerField = this.formatOwnerTranslateField(updatedOwner, rioRvsDevice['owner-ipaddr']);
			const configurationLimitReached = this.isConfigurationLimitReached(resource);
			const ipAddressRange = this.formatIpAddressTooltipField(defaultGateway, ipresMask);
			const updatedDescription = this.getResourceDescription(descr, resource, configurationLimitReached);
			const showEditIcon = updatedOwner.includes(RioRvsOwner.NA) || updatedOwner.includes(RioRvsOwner.OTHER);

			return {
				...rioRvsDevice, customId: uuid.v4(), resource, descr: updatedDescription,
				ipAddressRange, updatedOwner, configurationLimitReached, translateOwnerField, showEditIcon, isResource
			};
		});
	}

	getAdditionalPayload(parameter: string): { mode: 'pulse' } | EmptyObject {
		return parameter === 'pulse-t' || parameter === 'pulse-gap' ? { mode: 'pulse' } : {};
	}

	getResourceDescription(currentDescription: string, resource: string, configurationLimitReached: boolean): string {
		const errLimitReached2Translation = '{{AUTO.PREVIEW.errLimitReached2}}';

		if (!configurationLimitReached) {
			return currentDescription;
		}

		const isLicenseLimit = this.checkIfLicenseLimit(resource);

		if (isLicenseLimit) {
			const licenseLimit = this.getMaxConfiguredDeviceLimit(resource, true);
			const errLicenseLimitReachedTranslation = '{{AUTO.PREVIEW.errLicenseLimitReached}}';

			return `${errLicenseLimitReachedTranslation}${licenseLimit}${errLimitReached2Translation}`;
		}

		const configurationLimit = this.getMaxConfiguredDeviceLimit(resource, false);
		const errLimitReachedTranslation = '{{AUTO.PREVIEW.errLimitReached}}';

		return `${errLimitReachedTranslation}${configurationLimit}${errLimitReached2Translation}`;
	}

	checkIfLicenseLimit(resource: string): boolean {
		const deviceNumber = +AutomationGenericService.splitAutomationItemByDash(resource)[1];
		const licenseLimit = this.getMaxConfiguredDeviceLimit(resource, true);

		return !licenseLimit || deviceNumber > licenseLimit;
	}

	isConfigurationLimitReached(resource: string): boolean {
		const deviceNumber = +AutomationGenericService.splitAutomationItemByDash(resource)[1];
		const licenseLimit = this.getMaxConfiguredDeviceLimit(resource, true);
		const configurationLimit = this.getMaxConfiguredDeviceLimit(resource, false);

		if (!licenseLimit || !configurationLimit) {
			return true;
		}

		return deviceNumber > licenseLimit || deviceNumber > configurationLimit;
	}

	getMaxConfiguredDeviceLimit(resource: string, isLicense: boolean): number {
		const [deviceName] = AutomationGenericService.splitAutomationItemByDash(resource.toLowerCase());

		if (deviceName.startsWith(RioRvsWioDeviceType.RIO.toLowerCase())) {
			const rioKey = isLicense ? 'licensedMaxRio' : 'maxRio';

			return this.rioRvsWioPreferences[rioKey];
		}

		if (deviceName.startsWith(RioRvsWioDeviceType.RVS.toLowerCase())) {
			const rvsKey = isLicense ? 'licensedMaxRvs' : 'maxRvs';

			return this.rioRvsWioPreferences[rvsKey];
		}

		const wioKey = isLicense ? 'licensedMaxWio' : 'maxWio';

		return this.rioRvsWioPreferences[wioKey];
	}

	formatOwnerField(ownerParameter: RioRvsOwner): RioRvsUpdatedOwner {
		switch (ownerParameter) {
		case RioRvsOwner.NONE:
			return RioRvsUpdatedOwner.FREE;
		case RioRvsOwner.OTHER:
			return RioRvsUpdatedOwner.OTHER_ABILIS;
		case RioRvsOwner.THIS:
			return RioRvsUpdatedOwner.OWN;
		default:
			return RioRvsUpdatedOwner.NA;
		}
	}

	formatOwnerTranslateField(ownerField: RioRvsUpdatedOwner, otherIp?: string): string {
		switch (ownerField) {
		case RioRvsUpdatedOwner.FREE:
			return '{{AUTO.PREVIEW.free}}';
		case RioRvsUpdatedOwner.NA:
			return '{{AUTO.PREVIEW.notAvailable}}';
		case RioRvsUpdatedOwner.OTHER_ABILIS:
			return '({{AUTO.PREVIEW.otherAbilis}} (' + (otherIp ?? '') + '))';
		case RioRvsUpdatedOwner.OWN:
			return '{{AUTO.PREVIEW.own}}';
		}
	}

	formatResourceField(deviceType: RioRvsWioDeviceType, deviceId: number): string {
		return MainUtilService.capitalCaseString((deviceId === 0 ? deviceType : `${deviceType}-${deviceId}`));
	}

	formatIpAddressTooltipField(defaultGateway: string, deviceIpAddresMask: string): string {
		try {
			const { firstAddress, lastAddress } = ip.subnet(defaultGateway, deviceIpAddresMask);

			return `[${firstAddress}-${lastAddress}]---${defaultGateway}`;
		}
		catch {
			return '';
		}
	}

	formatDeviceConfiguration(deviceConfiguration: IPortsConfigurationDeviceConf): IRioRvsIohubDevice {
		const { status, id: num, descr: description, type } = deviceConfiguration;

		return { ...deviceConfiguration, status, description, type, num, statusdesc: '' };
	}

	formatAllLineDevices(
		type: RioRvsWioDeviceType, resourceId: number, ioDevices: IRioRvsIohubDevice[], iconListResponse: IRioRvsIconList
	): RioRvsModalLineDeviceType[] {
		const selectedDevice = ioDevices.find(lineDevice => {
			const { type: lineDeviceType, num } = lineDevice;

			return lineDeviceType.toLowerCase() === type.toLowerCase() && num === resourceId;
		});

		if (!selectedDevice) { return []; }

		const { iconList } = iconListResponse.response;

		return Object
			.entries(selectedDevice)
			.filter(deviceKeyValuePair => !this.nonLineDeviceKeys.includes(deviceKeyValuePair[0]))
			.reduce((accumulator: RioRvsModalLineDeviceType[], lineDeviceKeyValuePair: [string, RioRvsLineDeviceTypes]) => {
				const [originalDeviceType, lineData] = lineDeviceKeyValuePair;
				const deviceName = this.rioRvsService.getDeviceMainClass(originalDeviceType);
				const wioLineName = this.rioRvsService.getWioLineType(deviceName);

				if (!deviceName) { return accumulator; }

				const lineDevices = lineData.map(lineDevice => {
					const { num, wioline } = lineDevice;

					const id = uuid.v4();
					const lineDeviceType = wioline !== undefined ? `${wioline}` : `${deviceName}-${num}`;
					const icon = this.rioRvsService.getSavedIcon(lineDeviceType, originalDeviceType, iconList);
					const availablePaths = this.rioRvsImageService.getAllAvailableImagePaths(originalDeviceType);
					const imagePaths = this.getImagePaths(icon, availablePaths);

					return { ...lineDevice, id, lineDeviceType, originalDeviceType, imagePath: icon, imagePaths, wioLineName };
				}) as RioRvsModalLineDeviceType[];

				return [...accumulator, ...lineDevices];
			}, []);
	}

	getImagePaths(imagePath: string, availablePaths: string[]): string[] {
		const isEndingWithOffOn = imagePath.endsWith('-off.svg') || imagePath.endsWith('-on.svg');

		if (isEndingWithOffOn) {
			const imageWithoutState = imagePath.replace('-off.svg', '').replace('-on.svg', '');

			return availablePaths.map(availableItem => availableItem.startsWith(imageWithoutState) ? imagePath : availableItem);
		}

		return availablePaths;
	}

	showResponseNotifications(requestResponse: IActionResponse | null): void {
		if (requestResponse) {
			const { message, status } = requestResponse.Response;

			if (status !== 0) {
				this.mainUtilService.showToastrMessage(message, ToastrType.ERROR);
			}

			this.rioRvsTableService.setUnselectedRowState(true);
		}
	}

	formatAvailableDevices(rioRvsResponse: IRioRvsResponse, hasErrorMessage: boolean): IRioRvsWioDevice[] {
		const { message } = rioRvsResponse.Response;

		this.setRioRvsWioPreferences(rioRvsResponse);
		this.rioRvsTableService.setResponseErrorMessage(message);

		if (!hasErrorMessage) {
			const { uuid: responseUUID, devices } = rioRvsResponse.Response;

			this.uuid = responseUUID;

			MainUtilService.setLocalStorageItem(RioRvsCookieKey.RIO_RVS_UUID, this.uuid);

			return this.formatDevicesInformation(devices);
		}

		return [];
	}

	setRioRvsWioPreferences(rioRvsResponse: IRioRvsResponse): void {
		const {
			'max-rio': maxRio, 'max-rvs': maxRvs, 'cfg-max-rio': configuredMaxRio,
			'cfg-max-wio': configuredMaxWio, 'lic-max-wio': licensedMaxWio, 'cfg-max-rvs': configuredMaxRvs,
			'lic-max-rio': licensedMaxRio, 'lic-max-rvs': licensedMaxRvs, 'max-wio': maxWio
		} = rioRvsResponse.Response;

		this.rioRvsWioPreferences = {
			maxRio, maxRvs, configuredMaxRio, configuredMaxRvs, maxWio,
			licensedMaxRio, licensedMaxRvs, licensedMaxWio, configuredMaxWio
		};
	}

	getRioRvsWioPreferences(): IRioRvsPreferences {
		return this.rioRvsWioPreferences;
	}

	get getRioRvsUUID(): string {
		return this.uuid;
	}
}
