import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { Component, OnInit } from '@angular/core';
import { IGeneratedConfigurationParams } from '../components/networking/ts/models/generated-configuration-params.model';
import { IGeneratedConfigurationTemplateOptions } from '../components/networking/ts/models/generated-configuration-template-option.model';

@Component({
	selector: 'app-formly-field-checkbox',
	styleUrls: ['../formly-styles/checkbox-field-style.scss'],
	template: `
		<label class="form-check-label checkbox__field">
			<input type="checkbox" class="form-check-input" [attr.name]="field.id"
			[formControl]="$any(formControl)" [formlyAttributes]="field">
			<span></span>
			<span class="hidden__checkbox" [title]="tooltip | translate">{{label | translate | translateFix:label}}</span>
		</label>
		<span> {{ infoBubble }}</span>
 `,
})

export class FormlyFieldCheckboxComponent extends FieldType implements OnInit {

	label: string;
	tooltip: string;
	infoBubble: any;

	ngOnInit(): void {

		this.tooltip = (this.field as IGeneratedConfigurationParams).tooltip || '';
		this.label = (this.field.templateOptions as IGeneratedConfigurationTemplateOptions).label;
	}
}
