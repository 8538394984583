import { forkJoin, of } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AuthUserInfo } from '../ts/enums/user-auth.enum';
import { RoutesEnum } from '../../../ts/enums/routes.enum';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthNgrxService } from '../services/auth-ngrx.service';
import { AuthHttpService } from '../services/auth-http.service';
import { IAppState, getRouterUrlState } from '../../../root-reducers';
import { switchMap, tap, map, take, concatMap, filter } from 'rxjs/operators';
import { ILoginVerification } from '../ts/models/login-verification-data.model';
import { RouterUtilService } from './../../../services/utils/router-util.service';
import * as authActions from './auth.actions';

@Injectable()
export class AuthEffects {

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly store: Store<IAppState>,
		private readonly authHttpService: AuthHttpService,
		private readonly authNgrxService: AuthNgrxService,
		private readonly routerUtilService: RouterUtilService
	) { }

	refreshUserPermissions$ = createEffect(() => this.actions$.pipe(
		ofType(authActions.refreshUserPermissions),
		switchMap(() => this.authHttpService.fetchUserSessionData$()),
		filter(response => response.user.username !== AuthUserInfo.ANONYMOUS),
		map(sessionData => authActions.refreshUserPermissionsFinished({ payload: { sessionData } }))
	));

	authLoginStarted$ = createEffect(() => this.actions$.pipe(
		ofType(authActions.loginAction),
		switchMap(() => this.authHttpService.fetchUserSessionData$()),
		tap(sessionData => {
			if (sessionData.user.username !== AuthUserInfo.ANONYMOUS) {
				this.store.dispatch(authActions.loginActionCompleted({ payload: { sessionData } }));
				this.routerUtilService.navigateToRoute();
				return;
			}

			this.store.dispatch(authActions.anonymousLoggedUser());
		})
	), { dispatch: false });

	authAnonymousLogin$ = createEffect(() => this.actions$.pipe(
		ofType(authActions.anonymousLoggedUser),
		tap(() => window.location.href = `https://${window.location.hostname}`)
	), { dispatch: false });

	authLogout$ = createEffect(() => this.actions$.pipe(
		ofType(authActions.logoutAction),
		switchMap(() => this.authHttpService.logout$()),
		concatMap(() => this.authHttpService.fetchUserSessionData$()),
		tap(sessionData => this.store.dispatch(authActions.logoutActionCompleted({ payload: { sessionData } }))),
		concatMap(() => this.store.pipe(select(getRouterUrlState), take(1))),
		filter(routerUrlState => routerUrlState !== '/'),
		tap(() => this.router.navigateByUrl(`/${RoutesEnum.LOGIN}`))
	), { dispatch: false });

	logoutNonAnonymousAction$ = createEffect(() => this.actions$.pipe(
		ofType(authActions.logoutNonAnonymousAction),
		switchMap(() => this.authHttpService.fetchUserSessionData$()),
		map(sessionData => authActions.logoutActionCompleted({ payload: { sessionData } }))
	));

	reset2FaVerificationCode$ = createEffect(() => this.actions$.pipe(
		ofType(authActions.reset2FaVerificationCode),
		switchMap(() => this.authNgrxService.selectAllVerificationData$().pipe(take(1))),
		filter((data): data is ILoginVerification => !!data),
		switchMap(data => this.authHttpService.login$({ ...data, login_phase: 'retry' }))
	), { dispatch: false });

	loginWith2FaVerificationCode$ = createEffect(() => this.actions$.pipe(
		ofType(authActions.loginWith2FaVerificationCode),
		switchMap(action => forkJoin({
			vcode: of(action.payload.verificationCode),
			data: this.authNgrxService.selectAllVerificationData$().pipe(take(1))
		})),
		filter(response => !!response.data),
		switchMap(response => {
			const { vcode, data } = response;

			return this.authHttpService.login$({ ...data, vcode, login_phase: 'vcode' }, true);
		})
	), { dispatch: false });
}
