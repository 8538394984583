<div class="content" [ngClass]="{
		'content--minimized': !isSearchVisible,
		'content--fullwidth': isSearchVisible,
		'content--fullheight': isSearchVisible && isInputFilled
	}" (click)="onToggleSearchVisibility($event)">
	<app-side-bar-search-input [isSearchVisible]="isSearchVisible"
		(contentAvailableEvent)="onSetInputFilled($event)"></app-side-bar-search-input>

	<ng-container *ngIf="isSearchVisible && isInputFilled && (filteredResults$ | async) as filteredResults">
		<app-side-bar-search-content [results]="filteredResults" (resetSearchEvent)="onResetSearch()">
		</app-side-bar-search-content>
	</ng-container>
</div>
