import { Injectable } from '@angular/core';
import { AutomationDeviceService } from './automation-device.service';
import { IAutomationDevice } from '../../ts/models/automation-device.model';
import { AutomationDeviceClassService } from './automation-device-class.service';
import { AutomationLineDevice } from '../../ts/types/automation-line-device.type';
import { IAutomationMapDevice } from '../../ts/models/automation-map-device.model';
import { AutomationMapDeviceTypes } from '../../ts/types/automation-map-device-types';
import { AutomationMapDeviceValue } from '../../ts/enums/automation-map-device-value.enum';
import { RioRvsImageService } from '../../../../components/rio-rvs/services/rio-rvs-image.service';
import { IRioRvsIconList } from '../../../../components/rio-rvs/ts/models/rio-rvs-icon-list.model';
import { IRioRvsSavedIcon } from '../../../../components/rio-rvs/ts/models/rio-rvs-saved-icon.model';
import { RioRvsDeviceValue } from '../../../../components/rio-rvs/ts/enums/rio-rvs-device-value.enum';
import { IAutomationMapDeviceContainer } from '../../ts/models/automation-map-device-container.model';
import { IRioRvsIohubDevice } from '../../../../components/rio-rvs/ts/models/rio-rvs-iohub-device.model';
import { RioRvsIohubDeviceType } from '../../../../components/rio-rvs/ts/enums/rio-rvs-iohub-device-type.enum';
import { IAutomationMapAvailableIconDevices } from '../../ts/models/automation-map-available-icon-devices.model';
import { IAutomationMap } from '../../ts/models/automation-map.model';
import * as uuid from 'uuid';

@Injectable({
	providedIn: 'root'
})
export class AutomationDeviceFormatterService {

	constructor(
		private rioRvsImageService: RioRvsImageService,
		private automationDeviceService: AutomationDeviceService,
		private automationDeviceClassService: AutomationDeviceClassService
	) { }

	updateMapDevicesWithIcons(devicesUsedByMap: IAutomationMapDevice[], allIcons: IRioRvsSavedIcon[]): IAutomationMapDevice[] {
		if (devicesUsedByMap.length) {
			return devicesUsedByMap.reduce((accumulator, mapDevice) => {
				const { originalDeviceType, device, deviceNumber, num, value } = mapDevice;
				const originalDeviceTypeCasted = originalDeviceType as AutomationLineDevice;

				const deviceType = this.automationDeviceClassService.getLineDeviceName(originalDeviceTypeCasted);
				const lineDeviceIconId = `${device}-${deviceNumber}-${deviceType.toUpperCase()}-${num}-${originalDeviceType}`;

				const matchingIcon = allIcons.find(iconItem => iconItem.id === lineDeviceIconId);
				const clickEnabled = this.automationDeviceService.isClickEnabled(originalDeviceTypeCasted, value);

				if (matchingIcon) {
					const imagePath = this.automationDeviceService.setImageBasedOnValue(value, matchingIcon.imagePath);

					return [...accumulator, { ...mapDevice, imagePath, clickEnabled }];
				}

				return [...accumulator, { ...mapDevice, clickEnabled }];
			}, [] as IAutomationMapDevice[]);
		}

		return [];
	}

	getAllMapDevices(
		allDevices: IAutomationDevice[], iconsAndMapDevices: IAutomationMapAvailableIconDevices
	): IAutomationMapDevice[] {
		if (allDevices.length) {
			const { allIcons, mapDevices } = iconsAndMapDevices;

			const updatedMapDevices = mapDevices.reduce((accumulator, mapDevice) => {
				const { num, type, devices: mapLineDevices } = mapDevice;
				const matchingIoDevice = allDevices.find(device => device.num === num && device.type === type);

				if (matchingIoDevice) {
					const devices = this.getUpdatedMapDevices(mapDevice, matchingIoDevice);

					return [...accumulator, ...devices];
				}

				return accumulator;
			}, [] as IAutomationMapDevice[]);

			return this.updateMapDevicesWithIcons(updatedMapDevices, allIcons);
		}

		return [];
	}

	getUpdatedMapDevices(mapDevice: IAutomationDevice, matchingIoDevice: IAutomationDevice): IAutomationMapDevice[] {
		return mapDevice.devices.map(lineDevice => {
			const { num, deviceType, } = lineDevice;
			const { devices } = matchingIoDevice;

			const matchingLineDevice = devices.find(ioDevice => ioDevice.num === num && ioDevice.deviceType === deviceType);
			const updatedLineDevice = this.getUpdatedLineDevices(matchingLineDevice, lineDevice);

			const { type: device, num: deviceNumber } = mapDevice;
			const { deviceType: lineDeviceType, num: lineDeviceNum, originalDeviceType } = updatedLineDevice;

			const resourceIdentifier = this.automationDeviceService.getResourceIdentifier(
				lineDeviceType as AutomationLineDevice, lineDeviceNum, device, deviceNumber
			);
			const tooltipDescription = this.automationDeviceService.formatTooltipDescription(updatedLineDevice, resourceIdentifier);
			const deviceValue = updatedLineDevice.hasOwnProperty('value') ? updatedLineDevice.value : AutomationMapDeviceValue.UNAVAILABLE;
			const status = this.rioRvsImageService.convertValueToStatus(deviceValue.toString() as RioRvsDeviceValue);
			const imagePath = this.rioRvsImageService.getDeviceImagePath(originalDeviceType as AutomationLineDevice, status);

			const newTopPosition = lineDevice.hasOwnProperty('newTopPosition') ? lineDevice.newTopPosition : lineDevice.top;
			const newLeftPosition = lineDevice.hasOwnProperty('newLeftPosition') ? lineDevice.newLeftPosition : lineDevice.left;

			return {
				...updatedLineDevice, imagePath, resourceIdentifier, status, device,
				tooltipDescription: tooltipDescription || resourceIdentifier, deviceNumber,
				newLeftPosition, newTopPosition, id: uuid.v4()
			};
		});
	}

	getUpdatedLineDevices(matchingLineDevice: IAutomationMapDevice | undefined, lineDevice: IAutomationMapDevice): IAutomationMapDevice {
		if (matchingLineDevice) {
			const { value } = matchingLineDevice;

			return { ...matchingLineDevice, ...lineDevice, value };
		}

		return lineDevice;
	}

	formatMapDevices(presetDevices: IAutomationMapDeviceContainer): IAutomationDevice[] {
		return Object
			.entries(presetDevices)
			.reduce((accumulator, mapDevice) => {
				const [key, devices] = mapDevice;
				const devicesPayload = Array.isArray(devices) ? devices.slice() : [devices];

				const updatedDevices = devicesPayload.map((device: IRioRvsIohubDevice) => {
					const { num } = device;
					const mapDevices = this.formatDeviceToMapFormat(device);

					return {
						id: '', num, type: key as RioRvsIohubDeviceType,
						devices: mapDevices, statusdesc: '', description: '', isUp: false
					};
				});

				return [...accumulator, ...updatedDevices];
			}, [] as IAutomationDevice[]);
	}

	formatDeviceToMapFormat(sessionItem: IRioRvsIohubDevice): IAutomationMapDevice[] {
		return Object
			.entries(sessionItem)
			.reduce((accumulator, keyValuePair) => {
				const [key, value] = keyValuePair;

				if (!Array.isArray(value)) { return accumulator; }

				const updateDevices = value.map(device => {
					const originalDeviceType = key as AutomationLineDevice;
					const deviceType = this.automationDeviceClassService.translateDeviceClassToCustomType(originalDeviceType);

					return { ...device, originalDeviceType, deviceType };
				}, []);

				return [...accumulator, ...updateDevices];
			}, [] as IAutomationMapDevice[]);
	}

	getAllAvailableIcons(allIconsResponse: { id: string; iconListResponse: IRioRvsIconList }[]): IRioRvsSavedIcon[] {
		return allIconsResponse.reduce((accumulator, iconResponse) => {
			const { id, iconListResponse } = iconResponse;
			const iconList = iconListResponse.response.iconList.map(iconItem => ({ ...iconItem, id: `${id}-${iconItem.id}` }));

			return [...accumulator, ...iconList];
		}, [] as IRioRvsSavedIcon[]);
	}

	extractMapDevices(automationMap: IAutomationMap): IAutomationMapDeviceContainer {
		const deviceTypes = this.getDeviceTypes(automationMap);

		return deviceTypes.reduce((accumulator, deviceType) => {
			const mapDevices = automationMap[deviceType as AutomationMapDeviceTypes];

			return { ...accumulator, [deviceType]: mapDevices };
		}, {});
	}

	getDeviceTypes(automationMap: IAutomationMap): RioRvsIohubDeviceType[] {
		return [
			RioRvsIohubDeviceType.RIO, RioRvsIohubDeviceType.RVS,
			RioRvsIohubDeviceType.GPIO, RioRvsIohubDeviceType.MFIO,
			RioRvsIohubDeviceType.WIO
		]
			.filter(deviceType => automationMap.hasOwnProperty(deviceType));
	}

	getMapDevicesFromMap(mapNumber: number, automationMaps: IAutomationMap[]): IAutomationDevice[] {
		const automationMap = automationMaps.find(availableMap => availableMap.mapNumber === mapNumber);

		return automationMap ? this.formatMapDevices(this.extractMapDevices(automationMap)) : [];
	}

	formatModalMapDevices(mapDevices: IAutomationMapDevice[], mapWidth: string): IAutomationMapDevice[] {
		const existingDevices = mapDevices.filter(mapDevice => mapDevice.newLeftPosition !== null && mapDevice.newTopPosition !== null);

		const newDevices = mapDevices
			.filter(mapDevice => mapDevice.newLeftPosition === null && mapDevice.newTopPosition === null)
			.map((mapDevice, mapDeviceIndex) => {
				const { newLeftPosition, newTopPosition } = this.getNewTopLeftDevicePositions(mapWidth, mapDeviceIndex);

				return { ...mapDevice, newLeftPosition, newTopPosition };
			});

		return [...existingDevices, ...newDevices];
	}

	getNewTopLeftDevicePositions(mapWidth: string, lineDeviceIndex: number): { newTopPosition: string; newLeftPosition: string } {
		const parsedWidth = +mapWidth;
		const leftPosition = (parsedWidth / 10) * lineDeviceIndex;
		const widthRatio = Number.parseInt((leftPosition / parsedWidth).toString(), 10);

		const newTopPosition = (widthRatio * 70).toString();
		const newLeftPosition = (leftPosition % parsedWidth).toString();

		return { newTopPosition, newLeftPosition };
	}

	updateDevicesCheckboxState(existingDevices: IAutomationDevice[], selectedLineDevices: string[]): IAutomationDevice[] {
		return existingDevices.map(device => {
			const { devices } = device;
			const updatedDevices = this.updateLineDevicesCheckboxState(devices, selectedLineDevices);

			return { ...device, devices: updatedDevices };
		});
	}

	updateLineDevicesCheckboxState(devices: IAutomationMapDevice[], selectedLineDevices: string[]): IAutomationMapDevice[] {
		return devices.map(lineDevice => {
			const { fullDeviceName, originalDeviceType } = lineDevice;

			const lineDeviceIdentifier = `${fullDeviceName}${originalDeviceType}`;
			const checkboxState = selectedLineDevices.includes(lineDeviceIdentifier);

			return { ...lineDevice, checkboxState };
		});
	}
}
