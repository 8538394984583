import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { RoutesEnum } from '../../../../../ts/enums/routes.enum';
import { RecentCallRouter } from '../../ts/enums/recent-call-router.enum';
import { RecentCallModeClass } from '../../classes/recent-call-mode.class';

@Injectable({
	providedIn: 'root'
})
export class RecentCallOtherModeService extends RecentCallModeClass {

	constructor(
		private readonly router: Router
	) {
		super();
	}

	/**
	 * Routes to add mode of logged user, in OTHER mode
	 * @param {string} username Owner of Recent Calls
	 */
	public openAddForm(username: string): void {
		this.router.navigateByUrl(this.constructOtherModeUrl(username));
	}

	/**
	 * Constructs URL to route to other mode
	 * @param {string} username Owner of Recent Calls
	 */
	private constructOtherModeUrl(username: string): string {
		const recentCalls = RoutesEnum.RECENT_CALLS;
		const administration = RoutesEnum.ADMINISTRATION;
		const usersManagement = RoutesEnum.USERS_MANAGEMENT;
		const usersRecentCalls = RoutesEnum.USERS_RECENT_CALLS;

		const suffix = `(${recentCalls}:${usersRecentCalls}/${RecentCallRouter.ADD}/${username}))`;
		const rootUrl = `/${administration};replaceUrl=true/(${usersManagement}:${usersManagement};replaceUrl=true/`;

		return rootUrl + suffix;
	}
}
