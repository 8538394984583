import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { GlobalService } from '../../services/others/global.service';
import { GenericFormValue } from '../../components/ui-components/generic-table/ts/types/generic-form-value.type';

@Pipe({
	standalone: true,
	name: 'nestedTranslation'
})
export class NestedTranslationPipe implements PipeTransform {

	constructor(
		private globalService: GlobalService
	) {}

	transform(
		resolvedValue: GenericFormValue | null | undefined, translateValue: boolean | undefined, _: LangChangeEvent | null
	): string {
		if (resolvedValue !== undefined && resolvedValue !== null) {
			return this.globalService.translateCurlyBracesTranslation(resolvedValue, translateValue);
		}

		return '';
	}
}
