import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutesEnum } from '../../../../../ts/enums/routes.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from './../../../../auth/services/auth.service';
import { AuthNgrxService } from '../../../../auth/services/auth-ngrx.service';
import { PermissionTabService } from './../../../services/permission-tab.service';
import { SidebarFeatureNames } from '../../../ts/enums/sidebar-feature-names.enum';
import { Component, OnInit, ChangeDetectionStrategy, inject } from '@angular/core';
import { RouterUtilService } from './../../../../../services/utils/router-util.service';
import { FeaturePermissionsName } from '../../../ts/enums/feature-permission-name.enum';
import { FeaturePermissionsService } from '../../../services/feature-permissions.service';
import { IFeaturePermissionsItem } from '../../../ts/models/feature-permissions-item.model';
import { SideBarService } from './../../../../../standalone/components/ui/side-bar/services/side-bar.service';
import { AddressBookRouter } from '../../../../PHONE_FEATURES/address-book/ts/enums/address-book-router.enum';

@UntilDestroy()
@Component({
	selector: 'app-phone-tab-preview',
	templateUrl: './phone-tab-preview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneTabPreviewComponent implements OnInit {

	public readonly router: Router;
	private readonly activatedRoute: ActivatedRoute;

	private readonly paramUrls = [RoutesEnum.ADDRESSBOOK, RoutesEnum.RECENT_CALLS];

	public loggedUsername: string;
	public haveAdminPermission$: Observable<boolean>;

	public opcPermission$: Observable<IFeaturePermissionsItem | null>;
	public routingsPermission$: Observable<IFeaturePermissionsItem | null>;
	public addressbookPermission$: Observable<IFeaturePermissionsItem | null>;
	public preferencesPermission$: Observable<IFeaturePermissionsItem | null>;
	public recentCallsPermission$: Observable<IFeaturePermissionsItem | null>;
	public phoneStatisticsPermission$: Observable<IFeaturePermissionsItem | null>;

	constructor(
		private readonly authService: AuthService,
		private readonly sideBarService: SideBarService,
		private readonly authNgrxService: AuthNgrxService,
		private readonly routerUtilService: RouterUtilService,
		private readonly permissionTabService: PermissionTabService,
		private readonly featurePermissionsService: FeaturePermissionsService
	) {
		this.router = inject(Router);
		this.activatedRoute = inject(ActivatedRoute);

		this.sideBarService.setActiveSidebarItem(SidebarFeatureNames.PHONE);
	}

	public ngOnInit(): void {
		this.loggedUsername = this.authService.getUsername();
		this.haveAdminPermission$ = this.authNgrxService.haveAdminUserLevel$();

		this.opcPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.OPC_PANEL);
		this.routingsPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.ROUTINGS);
		this.addressbookPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.ADDRESSBOOK);
		this.preferencesPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.PREFERENCES);
		this.recentCallsPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.RECENT_CALLS);
		this.phoneStatisticsPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.STATISTICS);

		this.watchMainRouteMatch();
		this.setFirstAvailableTab();
	}

	private watchMainRouteMatch(): void {
		this.permissionTabService.watchMainRouteMatch$(RoutesEnum.PHONE)
			.pipe(untilDestroyed(this))
			.subscribe(() => this.routerUtilService.navigateToRoute());
	}

	private setFirstAvailableTab(): void {
		const permissions$ = [
			this.addressbookPermission$, this.recentCallsPermission$, this.preferencesPermission$,
			this.opcPermission$, this.phoneStatisticsPermission$, this.routingsPermission$
		];

		const formatPermissionCallback = this.featurePermissionsService.formatPhonePermissionToRoutes;

		this.permissionTabService.getFirstAvailableTab$(RoutesEnum.PHONE, permissions$, formatPermissionCallback)
			.pipe(untilDestroyed(this))
			.subscribe(featureName => this.onPhoneTabRouting(featureName));
	}

	public onPhoneTabRouting(featureName: RoutesEnum): void {
		if (!this.paramUrls.includes(featureName)) {
			this.routerUtilService.navigateToRouterOutlet(featureName, this.activatedRoute);
			return;
		}

		this.handleParamsRoute(featureName);
	}

	private handleParamsRoute(featureName: RoutesEnum): void {
		const username = this.authService.getUsername();
		const outlets = { [featureName]: `${featureName}/${AddressBookRouter.USER}/${username}` };

		this.router.navigate([{ replaceUrl: true }, { outlets }], { relativeTo: this.activatedRoute });
	}

	public get getRoutesEnum(): typeof RoutesEnum {
		return RoutesEnum;
	}
}
