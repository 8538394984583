import { Injectable, ElementRef } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DomUtilService {

	constructor() { }

	public static isDomElement(target: HTMLElement, elementName: string): boolean {
		return target.nodeName.toLowerCase() === elementName;
	}

	public static isOutOfViewport(element: ElementRef): boolean {
		const bounding = element.nativeElement.getBoundingClientRect();

		const top = bounding.top < 0;
		const left = bounding.left < 0;
		const right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
		const bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);

		return top || left || bottom || right;
	};

	public static haveCertainClass(element: ElementRef, className: string): boolean {
		return Array
			.from(element.nativeElement.classList)
			.includes(className);
	}
}
