import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IAutomationMapVideoContainer } from '../../ts/models/automation-map-video-container.model';
import * as automationActions from '../automation.action';

export const automationVideoContainerFeatureKey = 'automationVideoContainer';

export type IAutomationVideoContainerState = EntityState<IAutomationMapVideoContainer>;

export const adapter: EntityAdapter<IAutomationMapVideoContainer> = createEntityAdapter<IAutomationMapVideoContainer>();

export const initialVideoContainerState: IAutomationVideoContainerState = adapter.getInitialState();

const automationVideoContainerReducer = createReducer(
	initialVideoContainerState,
	on(automationActions.removeAllVideoContainers, (state) => adapter.removeAll(({ ...state }))),
	on(automationActions.addUsedVideoContainers, (state, { payload }) => adapter.setAll(payload.videoContainers, { ...state })),
	on(automationActions.updateVideoContainer, (state, { payload }) =>
		adapter.updateOne({ id: payload.videoContainer.id, changes: payload.videoContainer }, state))
);

export function AutomationVideoContainerReducer(state: IAutomationVideoContainerState | undefined, action: Action) {
	return automationVideoContainerReducer(state, action);
}

export const { selectAll: selectAllVideoContainers } = adapter.getSelectors();
