import { FieldType } from '@ngx-formly/core';
import { Component, OnInit } from '@angular/core';
import { NetworkingAutomationUtilService } from '../components/networking/services/networking-automation-util.service';
import { IGeneratedConfigurationTemplateOptions } from '../components/networking/ts/models/generated-configuration-template-option.model';

@Component({
	selector: 'app-formly-field-custom-button',
	styleUrls: ['../formly-styles/button-field-style.scss'],
	template: `
		<div>
			<span (click)="onButtonClick()" [attr.class]="field.className">
			<button class="btn btn-sm btn-primary">
				<img src="assets/feather-icons/chevrons-left.svg" class="button__icon" alt="open">
				{{label | translate | translateFix:label}}
			</button>
			</span>
		</div>
 `,
})

export class FormlyFieldCustomButtonComponent extends FieldType implements OnInit {

	label: string;
	defaultValue: string;

	constructor(
		private networkingAutomationUtilService: NetworkingAutomationUtilService
	) {
		super();
	}

	ngOnInit(): void {
		this.defaultValue = this.field.defaultValue;
		const { label } = this.field.templateOptions as IGeneratedConfigurationTemplateOptions;

		this.label = label;
	}

	onButtonClick(): void {
		this.networkingAutomationUtilService.emitClickButtonState(this.defaultValue);
	}
}
