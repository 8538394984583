import { IAddressBookState } from './address-book.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AddressBookStoreKey } from '../ts/enums/address-book-store-key.enum';
import * as addressBookReducer from './address-book.reducer';

export const selectAddressBookState = createFeatureSelector<IAddressBookState>('addressBook');

/**
	* Selects all Address Book contacts from ngrx store
	*/
export const selectAllContacts = () => createSelector(
	selectAddressBookState,
	addressBookReducer.selectAllAddressBookContacts
);

/**
	* Selects value from ngrx store
	* @param {AddressBookStoreKey} key Key to access ngrx store value
	*/
export const selectFromAddressBookStore = (key: AddressBookStoreKey) => createSelector(
	selectAddressBookState,
	addressBookState => addressBookState[key]
);
