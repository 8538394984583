import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../../root-reducers';
import { VirtualOfficeStoreKey } from '../ts/enums/virtual-office-store-key.enum';
import { IVirtualOfficeSystemForm } from '../ts/models/virtual-office-system-form.model';
import { IVirtualOfficeFormattedNumber } from '../ts/models/virtual-office-formatted-number.model';
import * as virtualOfficeSelectors from '../ngrx/virtual-office.selectors';
import * as virtualOfficeActions from '../ngrx/virtual-office.actions';

@Injectable({
	providedIn: 'root'
})
export class VirtualOfficeNgrxService {

	constructor(
		private store: Store<IAppState>
	) { }

	loadUsers(): void {
		this.store.dispatch(virtualOfficeActions.loadUsers());
	}

	loadSystemForm(): void {
		this.store.dispatch(virtualOfficeActions.loadSystemForm());
	}

	finishLoadingUserNumbers(userNumbers: IVirtualOfficeFormattedNumber[]): void{
		const payload = { userNumbers };

		this.store.dispatch(virtualOfficeActions.finishLoadingUserNumbers({ payload }));
	}

	saveSystemFormValues(systemForm: IVirtualOfficeSystemForm): void {
		const payload = { systemForm };

		this.store.dispatch(virtualOfficeActions.saveSystemFormValues({ payload }));
	}

	cancelVOHttpObservables(): void {
		this.store.dispatch(virtualOfficeActions.cancelVOHttpObservables());
	}

	selectFromVirtualOfficeStore$<T>(selector: VirtualOfficeStoreKey): Observable<T> {
		return this.store.pipe(select(virtualOfficeSelectors.selectFromVirtualOfficeStore(selector))) as unknown as Observable<T>;
	}
}
