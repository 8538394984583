import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserLevel } from '../../../auth/ts/enums/user-level.enum';
import { AuthUserInfo } from '../../../auth/ts/enums/user-auth.enum';
import { UserPreferences2fa } from '../../ts/enums/user-preferences-2fa.enum';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { IUserPreferenceItem } from '../../ts/models/user-preferences-item.model';
import { createTrimWhitespaceValidator } from '../../../../utils/form-validators.validator';
import { PreferencesAsyncValidators } from '../../utils/preferences-custom-async.validators';
import { UserPreferences2faPrivateIp } from '../../ts/enums/user-preferences-2fa-private-ip.enum';
import { PrivilegePreferencesValidatorsService } from './privilege-preferences-validators.service';
import { IUserPreferencesNewBasicForm } from '../../ts/models/user-preferences-new-basic-form.model';
import { IUserPreferencesEditBasicForm } from '../../ts/models/user-preferences-edit-basic-form.model';
import { IUserPreferencesPrivilegeParameters } from '../../ts/models/user-preferences-privilege-parameters.model';
import { BasicUserPreferencesTableHttpService } from '../basic-user-preferences/basic-user-preferences-table-http.service';
import { UserPreferences2faHttp } from '../../ts/enums/user-preferences-2fa-http.enum';

@Injectable({
	providedIn: 'root'
})
export class PrivilegePreferencesFormService {

	private privilegeVoAsyncValidator: Subject<void> = new Subject<void>();
	private privilegePreferencesAsyncValidator: Subject<void> = new Subject<void>();

	private readonly availableInterfaces = ['CTIP', 'IAX', 'SIP', 'CLUS', 'VO'];
	private readonly forbiddenUsernames = ['A', 'ALL', AuthUserInfo.ANONYMOUS, 'super', 'any-authenticated'];

	constructor(
		private formBuilder: FormBuilder,
		private preferencesTableHttpService: BasicUserPreferencesTableHttpService,
		private privilegePreferencesValidatorsService: PrivilegePreferencesValidatorsService
	) { }

	public get getUbrValidators(): ValidatorFn[] {
		const numberPattern = /^((([uinoshk])(?!.*\3))?(([xedtlp])(?!.*\5))?\d{1,20})$|^(\d{1,20})$/;

		return [Validators.pattern(numberPattern), Validators.maxLength(20)];
	}

	buildBasicPreferencesForm(isAddMode: boolean, selectedPrivilegeItem: IUserPreferenceItem): FormGroup {
		const asyncUserValidator = PreferencesAsyncValidators.createUsernameValidator([], '', this, this.preferencesTableHttpService);

		const {
			levelGuiValue, groupValue, homeValue, realnameValue, passwordValue,
			usernameValue, twoFactorAuthValue, twoFactorMailValue, twoFactorSmsValue,
			twoFactorPrivateIpValue, twoFactorHttpValue
		} = this.getBasicPreferencesFormValue(isAddMode, selectedPrivilegeItem);

		return this.formBuilder.group({
			levelGUI: [levelGuiValue],
			group: [groupValue, [Validators.pattern(/^.{0,16}$/)]],
			homeUrl: [homeValue, [Validators.pattern(/^.{0,128}$/)]],
			realName: [realnameValue, [createTrimWhitespaceValidator(), Validators.pattern(/^.{0,32}$/)]],
			password: [passwordValue, [createTrimWhitespaceValidator(), Validators.minLength(6), Validators.maxLength(32)]],
			username: [usernameValue, [createTrimWhitespaceValidator(), Validators.pattern(/^\S{0,32}$/)], asyncUserValidator],
			'2FA-MAIL-TO': [twoFactorMailValue, [Validators.email, Validators.maxLength(128)]],
			'2FA-AUTH': [twoFactorAuthValue],
			'2FA-HTTP-PROT': [twoFactorHttpValue],
			'2FA-PRIVATE-IP': [twoFactorPrivateIpValue],
			'2FA-SMS-TO': [twoFactorSmsValue, [Validators.pattern(/^((([uinoshk])(?!.*\3))?(([xedtlp])(?!.*\5))?\d{1,20})$|^(\d{1,20})$/), Validators.maxLength(20)]]
		});
	}

	private getNewBasicPreferencesFormValue(): IUserPreferencesNewBasicForm {
		return {
			groupValue: '', passwordValue: '', usernameValue: '',
			realnameValue: '', homeValue: '', levelGuiValue: UserLevel.BASIC,
			twoFactorAuthValue: UserPreferences2fa.NO, twoFactorSmsValue: '', twoFactorMailValue: '',
			twoFactorPrivateIpValue: UserPreferences2faPrivateIp.NO, twoFactorHttpValue: UserPreferences2faHttp.SSL
		};
	}

	private getEditBasicPreferencesFormValue(selectedPrivilegeItem: IUserPreferenceItem): IUserPreferencesEditBasicForm {
		const {
			'HTTP-HOME-URL': homeUrlData, 'REAL-NAME': realName, 'HTTP-LEVEL': httpLevel,
			Group, PWD, USER, '2FA-AUTH': twoFactorAuth, '2FA-SMS-TO': twoFactorSms, '2FA-MAIL-TO': twoFactorEmail,
			'2FA-PRIVATE-IP': twoPrivateIp, '2FA-HTTP-PROT': twoFactorHttpProtocol
		} = selectedPrivilegeItem;

		return {
			groupValue: { value: Group, disabled: false },
			passwordValue: this.getValueReadonlyData(PWD),
			usernameValue: this.getValueReadonlyData(USER),
			realnameValue: this.getValueReadonlyData(realName),
			homeValue: this.getValueReadonlyData(homeUrlData),
			levelGuiValue: this.getValueReadonlyData(httpLevel),
			twoFactorHttpValue: this.getValueReadonlyData(twoFactorHttpProtocol) as { value: string; disabled: boolean },
			twoFactorPrivateIpValue: this.getValueReadonlyData(twoPrivateIp) as { value: string; disabled: boolean },
			twoFactorAuthValue: this.getValueReadonlyData(twoFactorAuth) as { value: string; disabled: boolean },
			twoFactorSmsValue: this.getValueReadonlyData(twoFactorSms),
			twoFactorMailValue: this.getValueReadonlyData(twoFactorEmail)
		};
	}

	private getValueReadonlyData(data: { VALUE: string | boolean; readonly: boolean }): { value: string | boolean; disabled: boolean } {
		return { value: data.VALUE, disabled: data.readonly };
	}

	private getBasicPreferencesFormValue(
		isAddMode: boolean, selectedPrivilegeItem: IUserPreferenceItem
	): IUserPreferencesNewBasicForm | IUserPreferencesEditBasicForm {
		return isAddMode ? this.getNewBasicPreferencesFormValue() : this.getEditBasicPreferencesFormValue(selectedPrivilegeItem);
	}

	buildAddDuplicateUserForm(): FormGroup {
		const usernameValidator = PreferencesAsyncValidators.createUsernameValidator(
			this.forbiddenUsernames, '', this, this.preferencesTableHttpService
		);

		return this.formBuilder.group({
			newUserPasswordRepeated: [''],
			newUserRealname: ['', [Validators.maxLength(32)], usernameValidator],
			newUserPassword: ['', [createTrimWhitespaceValidator(), Validators.minLength(6), Validators.maxLength(32)]],
			newUser: ['', [createTrimWhitespaceValidator(), Validators.pattern(/^([^*#\\/:?"<>|,&\s+]{1,32})$/i)], usernameValidator]
		});
	}

	isFormChanged<T>(initialFormValues: T, actualFormValues: T): boolean {
		const initialFormValuesForCompare = JSON.stringify(initialFormValues);
		const actualFormValuesForCompare = JSON.stringify(actualFormValues);

		return initialFormValuesForCompare !== actualFormValuesForCompare;
	}

	buildUserPrivilegeAddressBook(userPrivilegeData: IUserPreferencesPrivilegeParameters): FormGroup {
		const {
			'ADDRBOOK-SYNC': addressBookSync, 'ADDRBOOK-NUMBER': addressBookNumber,
			'ADDRBOOK-OUTDIAL': addressBookOutdial, 'ADDRBOOK-PRIV-MAX': addressBookPrivateMax,
			'ADDRBOOK-PUB-EDITABLE': addressBookPublicEditable
		} = userPrivilegeData;

		const addressBookOutdialValidator = [createTrimWhitespaceValidator(), Validators.pattern(/^(NONE|SYS|SYS \(\d+\)|[0-9]{1,8})$/i)];
		const addressBookPrivateMaxValidator = [createTrimWhitespaceValidator(), Validators.pattern(/^(([0-9]|[1-9]\d{1,3}|10000)|SYS|SYS \(\d+\))$/i)];

		return this.formBuilder.group({
			'ADDRBOOK-SYNC': [{ value: addressBookSync.VALUE, disabled: addressBookSync.readonly }],
			'ADDRBOOK-NUMBER': [{ value: addressBookNumber.VALUE, disabled: addressBookNumber.readonly }],
			'ADDRBOOK-OUTDIAL': [{ value: addressBookOutdial.VALUE, disabled: addressBookOutdial.readonly }, addressBookOutdialValidator],
			'ADDRBOOK-PRIV-MAX': [
				{ value: addressBookPrivateMax.VALUE, disabled: addressBookPrivateMax.readonly },
				addressBookPrivateMaxValidator
			],
			'ADDRBOOK-PUB-EDITABLE': [{ value: addressBookPublicEditable.VALUE, disabled: addressBookPublicEditable.readonly }]
		});
	}

	buildUserPrivilegeOpc(userPrivilegeData: IUserPreferencesPrivilegeParameters): FormGroup {
		const {
			OPC, 'OPC-ROLE': opcRole, 'OPC-VIEW': opcView, 'OPC-HIDE-NUMBERS': opcHideNumbers, 'OPC-PRIVACY': opcPrivacy
		} = userPrivilegeData;

		return this.formBuilder.group({
			checkboxesData: this.formBuilder.array([]),
			OPC: [{ value: OPC.VALUE, disabled: OPC.readonly }],
			'OPC-ROLE': [{ value: opcRole.VALUE, disabled: opcRole.readonly }],
			'OPC-VIEW': [{ value: opcView.VALUE, disabled: opcView.readonly }],
			'OPC-PRIVACY': [{ value: opcPrivacy.VALUE, disabled: opcPrivacy.readonly }],
			'OPC-HIDE-NUMBERS': [{ value: opcHideNumbers.VALUE, disabled: opcHideNumbers.readonly }]
		});
	}

	buildLdapForm(userPrivilegeData: IUserPreferencesPrivilegeParameters): FormGroup {
		const { LDAP, 'LDAP-OWN-ADDRBOOK': ldapOwnAddressbook } = userPrivilegeData;

		return this.formBuilder.group({
			LDAP: [{ value: LDAP.VALUE, disabled: LDAP.readonly }],
			'LDAP-OWN-ADDRBOOK': [{ value: ldapOwnAddressbook.VALUE, disabled: ldapOwnAddressbook.readonly }]
		});
	}

	buildCtipForm(userPrivilegeData: IUserPreferencesPrivilegeParameters): FormGroup {
		const { CTIP, 'CTIP-NUM': ctipNumber } = userPrivilegeData;

		return this.formBuilder.group({
			CTIP: [{ value: CTIP.VALUE, disabled: CTIP.readonly }, [createTrimWhitespaceValidator(), Validators.pattern(/^(#|\d|[1-9]\d|[1-5]\d{2}|600)$/)]],
			'CTIP-NUM': [{ value: ctipNumber.VALUE, disabled: ctipNumber.readonly }]
		});
	}

	getCtipFormValues(userPrivilegeData: IUserPreferencesPrivilegeParameters): FormGroup {
		const { CTIP, 'CTIP-NUM': ctipNumber } = userPrivilegeData;

		return this.formBuilder.group({
			CTIP: CTIP.VALUE,
			'CTIP-NUM': ctipNumber.VALUE
		});
	}

	buildClusForm(userPrivilegeData: IUserPreferencesPrivilegeParameters): FormGroup {
		const { CLUS, 'CLUS-NUM': clusNumber } = userPrivilegeData;
		const clusNumberValidators = this.privilegePreferencesValidatorsService.getSipClusNumberValidators(true);

		return this.formBuilder.group({
			CLUS: [{ value: CLUS.VALUE, disabled: CLUS.readonly }, [createTrimWhitespaceValidator(), Validators.pattern(/^([0-9A-Za-z_:#]){1,8}$/)]],
			'CLUS-NUM': [{ value: clusNumber.VALUE, disabled: clusNumber.readonly }, clusNumberValidators]
		});
	}

	buildFtpForm(userPrivilegeData: IUserPreferencesPrivilegeParameters): FormGroup {
		const { FTP, 'FTP-HOMEDIR': ftpHomedir, 'HTTP-GUI-NETWORK': guiNetwork } = userPrivilegeData;

		return this.formBuilder.group({
			FTP: [{ value: FTP.VALUE, disabled: FTP.readonly }],
			'HTTP-GUI-NETWORK': [{ value: guiNetwork.VALUE, disabled: guiNetwork.readonly }],
			'FTP-HOMEDIR': [{ value: ftpHomedir.VALUE, disabled: ftpHomedir.readonly }, [Validators.maxLength(128)]]
		});
	}

	buildVoForm(userPrivilegeData: IUserPreferencesPrivilegeParameters): FormGroup {
		const { VO } = userPrivilegeData;
		const value = VO.VALUE.toLowerCase() !== 'no';
		const numberPattern = /^((([uinoshk])(?!.*\3))?(([xedtlp])(?!.*\5))?\d{1,20})$|^(\d{1,20})$/i;

		return this.formBuilder.group({
			abilisTracking: [false],
			VO: [{ value, disabled: VO.readonly }],
			extensionNumber: ['', [createTrimWhitespaceValidator(), Validators.pattern(numberPattern), Validators.maxLength(20)]]
		});
	}

	translateVoNumberNone(value: string): string {
		return value === '#' ? '' : value;
	}

	buildSipForm(userPrivilegeData: IUserPreferencesPrivilegeParameters): FormGroup {
		const {
			SIP, 'SIP-TYPE': sipType, 'SIP-DOMAIN': sipDomain, 'SIP-HOST': sipHost,
			'SIP-NUMBER': sipNumber, 'SIP-REMPORT': sipRemport, 'SIP-LOCPORT': sipLocport
		} = userPrivilegeData;

		const sipNumberValidators = this.privilegePreferencesValidatorsService.getSipClusNumberValidators(false);

		return this.formBuilder.group({
			SIP: [{ value: SIP.VALUE, disabled: SIP.readonly }],
			'SIP-HOST': [{ value: sipHost.VALUE, disabled: sipHost.readonly }],
			'SIP-TYPE': [{ value: sipType.VALUE, disabled: sipType.readonly }],
			'SIP-DOMAIN': [{ value: sipDomain.VALUE, disabled: sipDomain.readonly }],
			'SIP-NUMBER': [{ value: sipNumber.VALUE, disabled: sipNumber.readonly }, sipNumberValidators],
			'SIP-REMPORT': [{ value: sipRemport.VALUE, disabled: sipRemport.readonly }],
			'SIP-LOCPORT': [{ value: sipLocport.VALUE, disabled: sipLocport.readonly }]
		});
	}

	buildAutomationForm(): FormGroup {
		return this.formBuilder.group({
			checkboxesData: this.formBuilder.array([])
		});
	}

	emitPrivilegePreferencesAsyncValidatorEvent(): void {
		this.privilegePreferencesAsyncValidator.next();
	}

	watchPrivilegePreferencesAsyncValidatorEvent$(): Observable<void> {
		return this.privilegePreferencesAsyncValidator.asObservable();
	}

	emitPrivilegeVoAsyncValidatorEvent(): void {
		this.privilegeVoAsyncValidator.next();
	}

	watchPrivilegeVoAsyncValidatorEvent$(): Observable<void> {
		return this.privilegeVoAsyncValidator.asObservable();
	}

	getAvailableInterfaces(): string[] {
		return this.availableInterfaces.slice();
	}
}
