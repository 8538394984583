import { Component } from '@angular/core';

@Component({
	selector: 'app-antek-logo',
	templateUrl: './antek-logo.component.html',
	styleUrls: ['./antek-logo.component.scss']
})
export class AntekLogoComponent {

	constructor() { }
}
