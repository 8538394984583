export enum RecentCallCvsHeaderKey {
	NAME = 'name',
	RESULT = 'result',
	DATETIME = 'datetime',
	DIRECTION = 'direction',
	DURATION = 'duration',
	CALLED = 'called',
	CALLING = 'calling',
	CALLED_NAME = 'called Name'
}
