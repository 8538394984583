<div class="form-group custom__input custom__inline" [ngStyle]="{'margin-bottom': bottomSpace ? '8px' : '0'}"
	[ngClass]="{'custom__afterlabel': !!afterInputLabel}">

	<label class="input__label" [ngClass]="{'custom__afterlabel': !!afterInputLabel}"
		*ngIf="inputLabel && !noWrap">{{inputLabel}}</label>

		<label class="input__label text--no-wrap" [ngClass]="{'custom__afterlabel': !!afterInputLabel}" *ngIf="inputLabel && noWrap">{{inputLabel}}</label>

	<input [type]="inputType" class="form-control" [attr.placeholder]="inputPlaceholder"
		[formControl]="$any(passedFormControl)" appPasswordText [translateToPasswordType]="translateToPasswordType"
		[attr.min]="min" [attr.max]="max" [attr.step]="step" [passwordTextState]="passwordTextState"
		[ngStyle]="{'max-width': maxControlWidth, 'height': inputIcon && !rightIcon ? '38px' : '28px', 'padding-left': inputIcon && !rightIcon ? '42px' : 'auto'}"
		autocomplete="off" [ngClass]="{'input--padding-right': rightIcon, 'input--white': whiteText}" (focus)="onFocus()"
		(blur)="onBlur()" #inputElement appAutofocusInputField [autofocus]="autofocus">

	<img [src]="'assets/feather-icons/' + inputIcon" [attr.alt]="inputIcon" class="input__icon"
		[ngClass]="{'input__icon--right': rightIcon}" (click)="onClickInputIcon()" *ngIf="inputIcon">

	<ng-container *ngIf="selectData && isFocused && (selectData | selectFilter:passedFormControl.value) as filterData">
		<div class="input__select" *ngIf="filterData.length">
			<ng-container *ngTemplateOutlet="dropdownListTemplate, context: { dropdownItems: filterData, fullList: false }">
			</ng-container>

			<ng-container *ngIf="passedFormControl.value">
				<ng-container *ngTemplateOutlet="dropdownListTemplate, context: { dropdownItems: selectData, fullList: true }">
				</ng-container>
			</ng-container>
		</div>
	</ng-container>

	<span *ngIf="afterInputLabel">{{afterInputLabel}}</span>
</div>

<ng-template #dropdownListTemplate let-dropdownItems="dropdownItems" let-fullList="fullList">
	<ng-container *ngIf="fullList">
		<hr>
	</ng-container>

	<span [ngStyle]="{'max-width': maxControlWidth}" (mousedown)="onSelectItem(selectItem)"
		(touchend)="onSelectItem(selectItem)" *ngFor="let selectItem of dropdownItems">
		{{selectItem.uiValue.toString() | translate | translateFix:selectItem.uiValue.toString() |
		nestedTranslation:true:(languageChanged$ | async)}}
	</span>
</ng-template>
