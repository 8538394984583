import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../../../root-reducers';
import { OpcPanelCardService } from '../card/opc-panel-card.service';
import { IOpcPanelUser } from '../../ts/models/opc-panel-user.model';
import { OpcPanelPayloadService } from '../opc-panel-payload.service';
import { IOpcPanelGroup } from '../../ts/models/opc-panel-group.model';
import { OpcPanelInterface } from '../../ts/enums/opc-panel-interface.enum';
import { IOpcPanelUserCard } from '../../ts/models/opc-panel-user-card.model';
import { OpcPanelCtiControl } from '../../ts/enums/opc-panel-cti-control.enum';
import { OpcPanelGroupStoreKey } from '../../ts/enums/opc-panel-group-store-key.enum';
import { IOpcPanelUserRecord } from '../../ts/models/session/opc-panel-user-record.model';
import { IOpcPanelCallRecord } from '../../ts/models/session/opc-panel-call-record.model';
import { OpcPanelUserRecordHandlerService } from '../opc-panel-user-record-handler.service';
import { OpcPanelCallQueuePosition } from '../../ts/enums/opc-panel-call-queue-position.enum';
import { OpcPanelSettingsUsersService } from '../settings/users/opc-panel-settings-users.service';
import { IOpcPanelCallQueueRecord } from '../../ts/models/session/opc-panel-call-queue-record.model';
import { IAddressBookContact } from '../../../PHONE_FEATURES/address-book/ts/models/address-book-contact.model';
import * as opcPanelGroupSelectors from '../../ngrx/opc-panel-group/opc-panel-group.selectors';
import * as opcPanelGroupActions from '../../ngrx/opc-panel-group/opc-panel-group.actions';

@Injectable({
	providedIn: 'root'
})
export class OpcPanelGroupNgrxService {

	constructor(
		private store: Store<IAppState>,
		private opcPanelCardService: OpcPanelCardService,
		private opcPanelPayloadService: OpcPanelPayloadService,
		private opcPanelSettingsUsersService: OpcPanelSettingsUsersService,
		private readonly opcPanelUserRecordHandlerService: OpcPanelUserRecordHandlerService
	) { }

	loadOpcSettings(): void {
		this.store.dispatch(opcPanelGroupActions.loadOpcSettings());
	}

	loadOpcPanelGroups(): void {
		this.store.dispatch(opcPanelGroupActions.loadOpcPanelGroups());
	}

	updateOpcGroup(id: number, groupData: IOpcPanelGroup): void {
		const payload = { id, groupData };

		this.store.dispatch(opcPanelGroupActions.updateOpcPanelGroup({ payload }));
	}

	cancelOpcPanelHttpObservables(): void {
		this.store.dispatch(opcPanelGroupActions.cancelOpcPanelHTTPObservables());
	}

	getOpcGroupsData$(): Observable<IOpcPanelGroup[]> {
		return this.store.pipe(select(opcPanelGroupSelectors.selectAllOpcGroups()));
	}

	getOpcUsersTableData$(): Observable<IOpcPanelUser[]> {
		return this.getOpcGroupsData$()
			.pipe(
				map(opcGroupData => this.opcPanelSettingsUsersService.formatUsersTableData(opcGroupData))
			);
	}

	selectFromOpcGroupStore$<T>(key: OpcPanelGroupStoreKey): Observable<T> {
		return this.store.pipe(select(opcPanelGroupSelectors.selectFromOpcGroupStore(key))) as unknown as Observable<T>;
	}

	setActiveCardTab(groupId: number, userRecord: IOpcPanelUserRecord): void {
		const { id } = userRecord;
		const payload = { userId: id, groupId };

		this.store.dispatch(opcPanelGroupActions.setCardActiveTab({ payload }));
	}

	setGroupMinimizeState(groupId: number, minimize: boolean): void {
		const payload = { groupId, minimize };

		this.store.dispatch(opcPanelGroupActions.setGroupMinimizeAction({ payload }));
	}

	setShowPhone(userRecords: IOpcPanelUserRecord[], loggedUsername: string): void {
		const showPhone = this.opcPanelUserRecordHandlerService.isUserRecordMatching(userRecords, loggedUsername);
		const payload = { showPhone };

		this.store.dispatch(opcPanelGroupActions.setShowPhone({ payload }));
	}

	setDisabledRow(userId: string): void {
		const payload = { userId };

		this.store.dispatch(opcPanelGroupActions.setUserRowDisabled({ payload }));
	}

	fetchSessionChanges(): void {
		this.store.dispatch(opcPanelGroupActions.fetchSessionChanges());
	}

	refreshUserPosition(userCardData: IOpcPanelUserCard, showToastr = false): void {
		const { userInterfacesData } = userCardData;
		const userRecord = this.opcPanelCardService.getVoInterfaceData(userInterfacesData);

		if (!userRecord) { return; }

		const { abilis, user, interface: userInterface } = userRecord;
		const ctiPayload = { CtiControl: { A: abilis, U: user, I: userInterface, Cmd: OpcPanelCtiControl.GPSREFRESH } };
		const payload = { userRecord, ctiPayload, showToastr };

		this.store.dispatch(opcPanelGroupActions.refreshGpsPosition({ payload }));
	}

	transferOpcCallRecord(
		addressBookUser: IAddressBookContact, callRecords: IOpcPanelCallRecord[],
		callRecord: IOpcPanelCallRecord, activeUserRecord: IOpcPanelUserRecord
	): void {
		const ctiPayload = this.opcPanelPayloadService.getTransferCallPayload(addressBookUser, callRecords, callRecord, activeUserRecord);

		this.store.dispatch(opcPanelGroupActions.transferCallOpcCallRecord({ payload: { ctiPayload, showToastr: true } }));
	}

	transferCustomNumber(transferNumber: string, callRecords: IOpcPanelCallRecord[], activeUserRecord: IOpcPanelUserRecord): void {
		const ctiPayload = this.opcPanelPayloadService.getTransferCustomNumberPayload(transferNumber, callRecords, activeUserRecord);

		if (ctiPayload) {
			this.store.dispatch(opcPanelGroupActions.transferCallOpcCallRecord({ payload: { ctiPayload, showToastr: true } }));
		}
	}

	callNumberFromModal(
		addressBookUser: IAddressBookContact | null, activeUserRecord: IOpcPanelUserRecord, customInputValue?: string
	): void {
		const ctiPayload = this.opcPanelPayloadService.getCallNumberFromModalPayload(addressBookUser, activeUserRecord, customInputValue);

		if (ctiPayload) {
			this.store.dispatch(opcPanelGroupActions.transferCallOpcCallRecord({ payload: { ctiPayload, showToastr: true } }));
		}
	}

	recordOpcPanelCall(callRecord: IOpcPanelCallRecord, activeUserRecord: IOpcPanelUserRecord): void {
		const ctiPayload = this.opcPanelPayloadService.getRecordCallPayload(callRecord, activeUserRecord);

		if (ctiPayload) {
			this.store.dispatch(opcPanelGroupActions.recordOpcCall({ payload: { ctiPayload, showToastr: true } }));
		}
	}

	closeActiveCall(callRecord: IOpcPanelCallRecord): void {
		const ctiPayload = this.opcPanelPayloadService.getCloseCallPayload(callRecord);

		if (ctiPayload) {
			this.store.dispatch(opcPanelGroupActions.closeActiveCall({ payload: { ctiPayload, showToastr: false } }));
		}
	}

	changeQueuePosition(
		callQueue: IOpcPanelCallQueueRecord, position: OpcPanelCallQueuePosition,
		isPriorityCall: boolean, phoneNumber: string, phonePrefix: string
	): void {
		const ctiPayload = this.opcPanelPayloadService.getChangeQueuePositionPayload(
			callQueue, position, isPriorityCall, phoneNumber, phonePrefix
		);

		if (ctiPayload) {
			this.store.dispatch(opcPanelGroupActions.changeQueuePosition({ payload: { ctiPayload, showToastr: false } }));
		}
	}

	transferQueueCall(callQueue: IOpcPanelCallQueueRecord, phoneNumber: string, outdial: string): void {
		const loggedUserRecords = this.opcPanelCardService.getLoggedUserRecords();
		const ctipInterfaceData = loggedUserRecords.find(userRecord => userRecord.interface === OpcPanelInterface.CTIP);

		const isPriorityCall = ctipInterfaceData ? ctipInterfaceData.Num === phoneNumber : false;
		const position = isPriorityCall ? OpcPanelCallQueuePosition.FIRST : OpcPanelCallQueuePosition.LAST;

		this.changeQueuePosition(callQueue, position, isPriorityCall, phoneNumber, outdial);
	}

	setDragCallQueueLayout(showQueueDragNotification: boolean): void {
		const payload = { showQueueDragNotification };

		this.store.dispatch(opcPanelGroupActions.setDragCallQueueLayout({ payload }));
	}
}
