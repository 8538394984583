import { IPhoneComponentState } from './phone-component.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as phoneReducer from './phone-component.reducer';

export const selectPhoneComponentState = createFeatureSelector<IPhoneComponentState>('phoneComponent');

export const selectFromPhoneStore = (selector: keyof IPhoneComponentState) => createSelector(
	selectPhoneComponentState,
	phoneComponentState => phoneComponentState[selector]
);

export const selectAllCallRecords = () => createSelector(
	selectPhoneComponentState,
	phoneReducer.selectAllCallRecords
);
