import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { MainUtilService } from '../../../services/main-util.service';
import { AutomationNgrxService } from './ngrx/automation-ngrx.service';
import { AutomationMapMode } from '../ts/enums/automation-map-mode.enum';
import { IAutomationTabDevicesLengthInfo } from '../ts/models/automation-tab-devices-length-info.model';
import { IAutomationTabDevicesCheckedInfo } from '../ts/models/automation-tab-devices-checked-info.model';

@Injectable({
	providedIn: 'root'
})
export class AutomationService {

	private isDesignMode: boolean;

	constructor(
		private router: Router,
		private authService: AuthService,
		private automationNgrxService: AutomationNgrxService
	) { }

	handleRouteNewMap(automationMapNumbers: number[], maxAutomationMapNumber: number): void {
		const newMapNumber = this.getNewMapNumber(automationMapNumbers, maxAutomationMapNumber);

		this.setDesignModeState(true);
		const path = `automation-maps/new/${newMapNumber}`;
		this.router.navigate([{ replaceUrl: true }, { outlets: { 'automation-maps': path } }]);

		this.automationNgrxService.setSelectedAutomationMapNumber(newMapNumber);
	}

	handleRouteEditMap(selectedMapNumber: number): void {
		this.setDesignModeState(true);
		const path = `automation-maps/edit/${selectedMapNumber}`;
		this.router.navigate([{ replaceUrl: true }, { outlets: { 'automation-maps': path } }]);

		this.automationNgrxService.setSelectedAutomationMapNumber(selectedMapNumber);
	}

	handleRoutePreviewMap(selectedMapNumber: number): void {
		this.setDesignModeState(false);
		this.automationNgrxService.setSelectedAutomationMapNumber(selectedMapNumber);

		const pathOutlet = { 'automation-maps': `automation-maps/view/${selectedMapNumber}` };

		this.router.navigate([{ replaceUrl: true }, { outlets: pathOutlet }]);
	}

	handleDeleteMap(selectedMapNumber: number): void {
		this.automationNgrxService.deleteSelectedAutomationMap(selectedMapNumber);
	}

	goToAutomationTable(): void {
		this.router.navigate([{ replaceUrl: true }, { outlets: { 'automation-maps': 'automation-maps' } }]);
	}

	checkToSetDesignMode(): void {
		this.setDesignModeState(this.router.url.includes('new') || this.router.url.includes('edit'));
	}

	setDesignModeState(isDesignMode: boolean): void {
		this.isDesignMode = isDesignMode;
	}

	getDesignMode(): boolean {
		return this.isDesignMode;
	}

	getMapPrefix(designMapMode: boolean): AutomationMapMode {
		return designMapMode ? AutomationMapMode.DESIGN : AutomationMapMode.CONTROL;
	}

	isNewMapSelected(): boolean {
		return this.router.url.includes('automation-maps/new/');
	}

	getNewMapNumber(automationMapNumbers: number[], maxAutomationMapNumber: number): number {
		for (let i = 1; i < maxAutomationMapNumber; i++) {
			if (!automationMapNumbers.includes(i)) {
				return i;
			}
		}

		return maxAutomationMapNumber;
	}

	getAutomationCreateDatetime(): string {
		const abilisTimezone = this.authService.getAbilisTimezone();

		return MainUtilService.getCurrentLuxonDatetime(abilisTimezone, 'dd/MM/yyyy HH:mm');
	}

	getInitialTabDevicesLengthInfo(): IAutomationTabDevicesLengthInfo {
		return { DIN: 0, DOUT: 0, AIN: 0, AOUT: 0 };
	}

	getInitialTabDevicesCheckedInfo(): IAutomationTabDevicesCheckedInfo {
		return { DIN: false, DOUT: false, AIN: false, AOUT: false };
	}
}
