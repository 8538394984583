import { forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthNgrxService } from '../../auth/services/auth-ngrx.service';
import { PreferencesHttpService } from '../services/preferences-http.service';
import { PreferencesNgrxService } from '../services/preferences-ngrx.service';
import { VirtualOfficeSide } from '../../virtual-office/ts/enums/virtual-office-side.enum';
import { map, switchMap, takeUntil, mergeMap, tap, filter, withLatestFrom } from 'rxjs/operators';
import { VirtualOfficeHttpService } from '../../virtual-office/services/virtual-office-http.service';
import * as preferencesActions from './preferences.actions';

@Injectable()
export class PreferencesEffects {

	constructor(
		private actions$: Actions,
		private authNgrxService: AuthNgrxService,
		private preferencesHttpService: PreferencesHttpService,
		private readonly preferencesNgrxService: PreferencesNgrxService,
		private readonly virtualOfficeHttpService: VirtualOfficeHttpService
	) { }

	fetchPreferencesAction$ = createEffect(() => this.actions$.pipe(
		ofType(preferencesActions.fetchPreferencesAction),
		withLatestFrom(this.authNgrxService.haveAdminUserLevel$()),
		switchMap(response => {
			const [action, hasAdminPrivilege] = response;
			const { username } = action.payload;

			return this.preferencesHttpService.fetchUserPreferences$(username, hasAdminPrivilege)
				.pipe(
					takeUntil(this.actions$.pipe(ofType(preferencesActions.cancelPreferencesHTTPObservablesAction)))
				);
		}),
		map(preferences => {
			const payload = { preferences };

			return preferencesActions.fetchingPreferencesCompletedAction({ payload });
		})
	));

	setUserPreferences$ = createEffect(() => this.actions$.pipe(
		ofType(preferencesActions.setUserPreferencesAction),
		withLatestFrom(this.authNgrxService.haveAdminUserLevel$()),
		mergeMap(response => {
			const [action, hasAdminPrivilege] = response;
			const { savePayload, fetchPreferences } = action.payload;
			const { user } = savePayload.CtiPreferences[0];

			return forkJoin({
				username: of(user), fetchPreferences: of(fetchPreferences),
				saveResponse: this.preferencesHttpService.setUserPreferences$(hasAdminPrivilege, savePayload)
			})
				.pipe(
					takeUntil(this.actions$.pipe(ofType(preferencesActions.cancelPreferencesHTTPObservablesAction)))
				);
		}),
		filter(response => response.fetchPreferences),
		map(response => preferencesActions.fetchPreferencesAction({ payload: { username: response.username } }))
	));

	clearPreferencesStore$ = createEffect(() => this.actions$.pipe(
		ofType(preferencesActions.clearPreferencesStoreAction),
		map(() => preferencesActions.cancelPreferencesHTTPObservablesAction())
	));

	handleNetNumber$ = createEffect(() => this.actions$.pipe(
		ofType(preferencesActions.saveNewNetUserNumber),
		switchMap(action => {
			const { user, phoneNumber, side } = action.payload;
			const key = side === VirtualOfficeSide.NET ? 'vo-cur-net-num' : 'vo-cur-user-num';

			return this.virtualOfficeHttpService.addVirtualOfficeNumber$(user, phoneNumber, side)
				.pipe(
					filter(isActionOk => isActionOk),
					tap(() => this.preferencesNgrxService.saveExistingVoNumber(user, phoneNumber, key))
				);
		})
	), { dispatch: false });
}
