import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserPreferencesStoreKey } from '../ts/models/user-preferences-store-key.enum';
import * as basicUserPreferencesReducer from './user-preferences.reducer';

export const selectBasicUserPreferencesState = createFeatureSelector<basicUserPreferencesReducer.IUserPreferencesState>('userPreferences');

export const selectAllBasicUserPreferences = () => createSelector(
	selectBasicUserPreferencesState,
	basicUserPreferencesReducer.selectAllUserPreferenceItems
);

export const selectFromBasicUserPreferencesStore = (property: UserPreferencesStoreKey) => createSelector(
	selectBasicUserPreferencesState,
	basicUserPreferencesState => basicUserPreferencesState[property]
);
