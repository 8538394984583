import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrType } from '../../../../ts/enums/toastr-type.enum';
import { MainUtilService } from '../../../../services/main-util.service';
import { AutomationProgramDevicesService } from '../devices/automation-program-devices.service';
import { AutomationLineDeviceType } from '../../../automation/ts/enums/automation-line-device-type.enum';
import { IAutomationProgramEditLineDevice } from '../../ts/models/automation-program-edit-line-device.model';
import { AutomationProgramEditLineDeviceValue } from '../../ts/types/automation-program-edit-line-device-value.type';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSpreadsheetTableUIService {

	constructor(
		private translate: TranslateService,
		private mainUtilService: MainUtilService,
		private automationProgramDevicesService: AutomationProgramDevicesService
	) { }

	getNextValidInputDevice(
		inputLineDevices: IAutomationProgramEditLineDevice[], fieldId: string | null
	): IAutomationProgramEditLineDevice | null {
		let whileLoopOver = false;
		let nextFieldIndex = inputLineDevices.findIndex(input => input.fullDeviceName === fieldId) + 1;

		if (nextFieldIndex === inputLineDevices.length) {
			return null;
		}

		while (!whileLoopOver && nextFieldIndex < inputLineDevices.length) {
			const { device } = inputLineDevices[nextFieldIndex];

			if (device) {
				whileLoopOver = true;
			} else {
				nextFieldIndex += 1;
			}
		}

		return inputLineDevices[nextFieldIndex];
	}

	validateInputFieldValue(newValue: string, inputDevice: IAutomationProgramEditLineDevice): boolean {
		if (newValue.trim() === '') {
			return false;
		}

		if (newValue.includes(',')) {
			this.mainUtilService.showToastrMessage(this.translate.instant('AUTO.RULES.EDIT.inputValueComma'), ToastrType.ERROR);

			return false;
		}

		const isDigitalDevice = this.automationProgramDevicesService.isDigitalLineDevice(inputDevice.lineDeviceName);

		if (!isDigitalDevice && !/^[+-]?\d+(.\d+)?$/.test(newValue)) {
			this.mainUtilService.showToastrMessage(this.translate.instant('AUTO.RULES.EDIT.analogValueNumber'), ToastrType.ERROR);

			return false;
		}

		return true;
	}

	translateInputTableCustomValue(cellValue: string): number {
		switch (cellValue) {
		case 'false':
			return 0;
		case 'true':
			return 1;
		default:
			return Number.parseFloat(cellValue);
		}
	}

	formatInputTableValue(inputDevice: IAutomationProgramEditLineDevice): AutomationProgramEditLineDeviceValue {
		const { value, port, isOffline, lineDeviceName } = inputDevice;
		const isAnalog = !this.automationProgramDevicesService.isDigitalLineDevice(port);

		if (isOffline) {
			return this.getPipeUnavailableDeviceValue(isAnalog);
		}

		return lineDeviceName === AutomationLineDeviceType.AIN ? value : this.getDigitalDeviceValue(value);
	}

	formatOutputTableValue(outputDevice: IAutomationProgramEditLineDevice): AutomationProgramEditLineDeviceValue {
		const { value, port, isOffline } = outputDevice;
		const isAnalog = !this.automationProgramDevicesService.isDigitalLineDevice(port);

		if (isOffline) {
			return this.getPipeUnavailableDeviceValue(isAnalog);
		}

		const expressionValue = outputDevice.expressionValue as string;
		return expressionValue !== '' ? expressionValue : !isAnalog ? this.getDigitalDeviceValue(value) : value;
	}

	formatTableValue(device: IAutomationProgramEditLineDevice, outputTable: boolean): AutomationProgramEditLineDeviceValue {
		return outputTable ? this.formatOutputTableValue(device) : this.formatInputTableValue(device);
	}

	getDigitalDeviceValue(value: AutomationProgramEditLineDeviceValue): string {
		const parsedDinValue = Number.parseFloat(value.toString());

		return parsedDinValue === 0 || parsedDinValue === 255 || Number.isNaN(parsedDinValue) ? 'false' : 'true';
	}

	getPipeUnavailableDeviceValue(isAnalog: boolean): number | boolean {
		return isAnalog ? 0.0 : false;
	}
}
