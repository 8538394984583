import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { TranslateFixPipe } from '../standalone/pipes/translate-fix.pipe';
import { SelectFilterPipe } from '../standalone/pipes/select-filter.pipe';
import { NullTypeComponent } from '../formly-components/null.type';
import { ArrayTypeComponent } from '../formly-components/array.type';
import { ObjectTypeComponent } from '../formly-components/object.type';
import { SharedPipeDirectiveModule } from './shared-pipe-directive.module';
import { FormlyValidContainerPipe } from '../pipes/formly-valid-container.pipe';
import { MultiSchemaTypeComponent } from '../formly-components/multischema.type';
import { FormlyFieldSelectComponent } from '../formly-components/select-field.type';
import { FormlyFieldCustomInputComponent } from '../formly-components/input-field.type';
import { FormlyFieldCheckboxComponent } from '../formly-components/checkbox-field.type';
import { FormlyFieldCustomButtonComponent } from '../formly-components/button-field.type';
import { ParametersContainerComponent } from '../formly-components/parameters-container.type';
import { GeneratedFormComponentType } from '../components/networking/ts/enums/generated-form-component-type.enum';
import {
	minlengthValidationMessage, maxlengthValidationMessage, minValidationMessage,
	maxValidationMessage, multipleOfValidationMessage, exclusiveMinimumValidationMessage,
	exclusiveMaximumValidationMessage, minItemsValidationMessage, maxItemsValidationMessage,
	constValidationMessage
} from '../formly-components/validation-types';
import { FormlyFieldInfoBubbleComponent } from '../formly-components/formly-info-bubble.type';
import { InfoBubbleComponent } from '../components/ui-components/custom-ui-components/info-bubble.component';
import { PopoverModule } from 'ngx-bootstrap/popover';


@NgModule({
	declarations: [
		InfoBubbleComponent,
		NullTypeComponent,
		ArrayTypeComponent,
		ObjectTypeComponent,
		FormlyValidContainerPipe,
		MultiSchemaTypeComponent,
		FormlyFieldSelectComponent,
		FormlyFieldCheckboxComponent,
		ParametersContainerComponent,
		FormlyFieldCustomInputComponent,
		FormlyFieldCustomButtonComponent,
		FormlyFieldInfoBubbleComponent,
	],
	imports: [
		CommonModule,
		TooltipModule,
		PopoverModule,
		TranslateFixPipe,
		SelectFilterPipe,
		ReactiveFormsModule,
		SharedPipeDirectiveModule,
		TranslateModule.forChild(),
		FormlyModule.forRoot(
			{
				extras: { checkExpressionOn: 'modelChange' },
				validationMessages: [
					{ name: 'required', message: 'This field is required' },
					{ name: 'null', message: 'Should be null' },
					{ name: 'minlength', message: minlengthValidationMessage },
					{ name: 'maxlength', message: maxlengthValidationMessage },
					{ name: 'min', message: minValidationMessage },
					{ name: 'max', message: maxValidationMessage },
					{ name: 'multipleOf', message: multipleOfValidationMessage },
					{ name: 'exclusiveMinimum', message: exclusiveMinimumValidationMessage },
					{ name: 'exclusiveMaximum', message: exclusiveMaximumValidationMessage },
					{ name: 'minItems', message: minItemsValidationMessage },
					{ name: 'maxItems', message: maxItemsValidationMessage },
					{ name: 'uniqueItems', message: 'Should NOT have duplicate items' },
					{ name: 'const', message: constValidationMessage },
				],
				types: [
					{ name: GeneratedFormComponentType.CONTAINER, component: ParametersContainerComponent },
					{ name: GeneratedFormComponentType.STRING, component: FormlyFieldCustomInputComponent },
					{ name: GeneratedFormComponentType.NUMBER, component: FormlyFieldCustomInputComponent },
					{ name: GeneratedFormComponentType.CUSTOM_SELECT, component: FormlyFieldSelectComponent },
					{ name: GeneratedFormComponentType.BUTTON, component: FormlyFieldCustomButtonComponent },
					{ name: GeneratedFormComponentType.CUSTOM_CHECKBOX, component: FormlyFieldCheckboxComponent }
				],
				wrappers: [
					{name: 'info-bubble', component: FormlyFieldInfoBubbleComponent}
				]
			}
		),
		FormlyBootstrapModule
	],
	exports: [
		NullTypeComponent,
		ArrayTypeComponent,
		ObjectTypeComponent,
		FormlyValidContainerPipe,
		MultiSchemaTypeComponent,
		ParametersContainerComponent,
		FormlyFieldInfoBubbleComponent,
		InfoBubbleComponent,
	]
})

export class FormlyFormModule { }
