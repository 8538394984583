import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { IAppState } from '../../../../../../root-reducers';
import * as sidebarSearchActions from '../../ngrx/side-bar-search.actions';

@Injectable({
	providedIn: 'root'
})
export class SideBarSearchNgrxActionService {

	constructor(
		private readonly store: Store<IAppState>
	) { }

	public loadSearchPages(): void {
		this.store.dispatch(sidebarSearchActions.loadSearchBarPages());
	}

	public filterPagesBySearchTerm(value: string): void {
		const payload = { value };

		this.store.dispatch(sidebarSearchActions.filterPagesBySearchTerm({ payload }));
	}
}
