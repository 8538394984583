import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IAutomationMapDevice } from '../../ts/models/automation-map-device.model';
import * as automationActions from '../automation.action';

export const automationMapDevicesFeatureKey = 'automationMapDevices';

export interface IAutomationMapDevicesState extends EntityState<IAutomationMapDevice> {
	isLoading: boolean;
}

export const adapter: EntityAdapter<IAutomationMapDevice> = createEntityAdapter<IAutomationMapDevice>();

export const initialAutomationMapDevicesState: IAutomationMapDevicesState = adapter.getInitialState({
	isLoading: true
});

const automationMapDevicesReducer = createReducer(
	initialAutomationMapDevicesState,
	on(automationActions.finishLoadingDevicesUsedByMap, (state, { payload }) =>
		adapter.setAll(payload.devicesUsedByMap, { ...state, isLoading: false })),
	on(automationActions.updateDevicesUsedByMap, (state, { payload }) =>
		adapter.updateMany(payload.devicesUsedByMap.map(device =>
			Object.assign({}, { id: device.id as string, changes: device })), { ...state })),
	on(automationActions.updateDeviceUsedByMap, (state, { payload }) =>
		adapter.updateOne({ id: payload.deviceUsedByMap.id as string, changes: payload.deviceUsedByMap }, state)),
	on(automationActions.setAllDevicesLoadingFlag, (state, { payload }) => ({ ...state, isLoading: payload.isLoading }))
);

export function AutomationMapDevicesReducer(state: IAutomationMapDevicesState | undefined, action: Action) {
	return automationMapDevicesReducer(state, action);
}

export const { selectAll: selectAllDevicesUsedByMap } = adapter.getSelectors();
