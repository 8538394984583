export enum AutomationProgramEditFunctonList {
	WEEKDAY = 'weekday',
	POW = 'pow',
	SQRT = 'sqrt',
	RANDBETWEEN = 'randbetween',
	ABS = 'abs',
	AVERAGE = 'average',
	HOUR = 'hour',
	HMSM = 'hmsm',
	INV = 'inv',
	TIME = 'time',
	ALERT = 'alert',
	MINUTE = 'minute',
	SECOND = 'second',
	BETWEEN = 'between',
	MILLISECOND = 'millisecond',
	DATETIME = 'datetime',
	NOW = 'now',
	STDEV = 'stdev',
	TODAY = 'today',
	MAX = 'max',
	MIN = 'min',
	MOD = 'mod',
	RAND = 'rand',
	EVEN = 'even',
	INT = 'int',
	ROUND = 'round',
	LOG_10 = 'log10',
	ODD = 'odd',
	XOR = 'xor',
	NOT = 'not',
	IF = 'if',
	IFNA = 'ifna',
	AND = 'and',
	OR = 'or',
	TRANS = 'trans',
	HISTORY = '^[a-yA-Y]\\d+$'
}
