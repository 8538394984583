<app-antek-logo></app-antek-logo>

<app-tabs>
	<app-tab [active]="router.url.includes(getRoutesEnum.AUTOMATION_MAPS)"
		[disabled]="(mapsPermission$ | async)?.isFeatureDisabled" [tooltip]="(mapsPermission$ | async)?.tooltip || ''"
		(selectTabEvent)="onAutomationTabRouting(getRoutesEnum.AUTOMATION_MAPS)"
		[location]="'/#/automation;replaceUrl=true/(automation-maps:automation-maps)'">
		<app-tab-label>
			<img src="assets/feather-icons/map.svg" alt="maps">
			<span>{{'AUTO.MAINTABS.maps' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="automation-maps"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.AUTOMATION_PROGRAMS_EDIT)"
		[disabled]="(sheetsPermission$ | async)?.isFeatureDisabled" [tooltip]="(sheetsPermission$ | async)?.tooltip || ''"
		(selectTabEvent)="onAutomationTabRouting(getRoutesEnum.AUTOMATION_PROGRAMS_EDIT)"
		[location]="'/#/automation;replaceUrl=true/(table-programs-edit:table-programs-edit)'">
		<app-tab-label>
			<img src="assets/feather-icons/table.svg" alt="automation">
			<span>{{'AUTO.MAINTABS.sheets' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="table-programs-edit"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.AUTOMATION_PROGRAM)"
		[disabled]="(programsPermission$ | async)?.isFeatureDisabled"
		[tooltip]="(programsPermission$ | async)?.tooltip || ''"
		(selectTabEvent)="onAutomationTabRouting(getRoutesEnum.AUTOMATION_PROGRAM)"
		[location]="'/#/automation;replaceUrl=true/(automation-program:automation-program)'">
		<app-tab-label>
			<img src="assets/feather-icons/automation-play.svg" alt="automation">
			<span>{{'AUTO.MAINTABS.programs' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="automation-program"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.AUTOMATION_LOG)"
		[disabled]="(logPermission$ | async)?.isFeatureDisabled" [tooltip]="(logPermission$ | async)?.tooltip || ''"
		(selectTabEvent)="onAutomationTabRouting(getRoutesEnum.AUTOMATION_LOG)"
		[location]="'/#/automation;replaceUrl=true/(automation-log:automation-log)'">
		<app-tab-label>
			<img src="assets/feather-icons/paper.svg" alt="log">
			<span>{{'AUTO.MAINTABS.logs' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="automation-log"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.RIO_RVS)"
		[disabled]="(discoveryPermission$ | async)?.isFeatureDisabled"
		[tooltip]="(discoveryPermission$ | async)?.tooltip || ''"
		(selectTabEvent)="onAutomationTabRouting(getRoutesEnum.RIO_RVS)"
		[location]="'/#/automation;replaceUrl=true/(rio-rvs:rio-rvs)'">
		<app-tab-label>
			<img src="assets/feather-icons/device.svg" alt="device">
			<span>{{'AUTO.MAINTABS.discConf' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="rio-rvs"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab [active]="router.url.includes(getRoutesEnum.PORTS_CONFIGURATION)"
		[disabled]="(discoveryPermission$ | async)?.isFeatureDisabled"
		[tooltip]="(discoveryPermission$ | async)?.tooltip || ''"
		(selectTabEvent)="onAutomationTabRouting(getRoutesEnum.PORTS_CONFIGURATION)"
		[location]="'/#/automation;replaceUrl=true/(ports-configuration:ports-configuration)'">
		<app-tab-label>
			<img src="assets/feather-icons/settings.svg" alt="settings">
			<span>
				{{'AUTO.MAINTABS.configuration' | translate}}
			</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="ports-configuration"></router-outlet>
		</app-tab-body>
	</app-tab>
</app-tabs>
