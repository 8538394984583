import { Injectable } from '@angular/core';
import { PhoneCallsLogService } from './phone-calls-log.service';
import { IRecentCall } from './../../../PHONE_FEATURES/recent-calls/ts/models/recent-call-table.model';

@Injectable({
	providedIn: 'root'
})
export class PhoneRecentCallsService {

	constructor(
		private readonly phoneCallsLogService: PhoneCallsLogService
	) { }

	public formatRecentCallsForPhone(recentCalls: IRecentCall[]): IRecentCall[] {
		return recentCalls.map(item => {
			const { duration } = item;
			const updatedDuration = this.phoneCallsLogService.formatSecondsToHMS(+duration);

			return { ...item, duration: updatedDuration };
		});
	}
}
