export enum AutomationAnalogOutputs {
	ANALOG_OUTPUTS = 'AnalogOutputs',
	ANALOG_OUTPUT = 'AnalogOutput',
	ANALOG_OUTPUT_SLIDERS = 'AnalogOutputSliders',
	ANALOG_OUTPUT_SLIDER = 'AnalogOutputSlider',
	ANALOG_OUTPUT_SPINNERS = 'AnalogOutputSpinners',
	ANALOG_OUTPUT_SPINNER = 'AnalogOutputSpinner',
	ANALOG_OUTPUT_MONITORS = 'AnalogOutputMonitors',
	ANALOG_OUTPUT_MONITOR = 'AnalogOutputMonitor',
	ANALOG_OUTPUT_MONITOR_CONTROLS = 'AnalogOutputMonitorControls',
	ANALOG_OUTPUT_MONITOR_CONTROL = 'AnalogOutputMonitorControl'
}
