import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { INewAutomationProgramEditInputValue } from '../../ts/models/automation-program-edit-new-input-value.model';
import * as automationProgramEditActions from '../automation-program-edit.actions';

export const automationProgramInputValueFeatureKey = 'automationProgramInputValue';

export type IAutomationProgramInputValueState = EntityState<INewAutomationProgramEditInputValue>;

export const adapter: EntityAdapter<INewAutomationProgramEditInputValue> = createEntityAdapter<INewAutomationProgramEditInputValue>();

export const initialAutomationProgramInputValueState: IAutomationProgramInputValueState = adapter.getInitialState();

const automationProgramInputValueReducer = createReducer(
	initialAutomationProgramInputValueState,
	on(automationProgramEditActions.addNewInputValue, (state, { payload }) => adapter.addOne(payload.newInput, { ...state })),
	on(automationProgramEditActions.updateNewInputValue, (state, { payload }) => adapter.upsertOne(payload.newInput, { ...state })),
	on(automationProgramEditActions.setPreselectedInputValues, (state, { payload }) => adapter.setAll(payload.customInputs, { ...state }))
);

export function AutomationProgramInputValueReducer(state: IAutomationProgramInputValueState | undefined, action: Action) {
	return automationProgramInputValueReducer(state, action);
}

export const { selectAll: selectAllAutomationProgramInputValues } = adapter.getSelectors();
