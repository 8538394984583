import { createAction, props } from '@ngrx/store';
import { IAddressBookContact } from '../ts/models/address-book-contact.model';
import { IAddressBookAddContactPayload } from '../ts/models/address-book-add-contact-payload.model';
import { IAddressBookEditContactPayload } from '../ts/models/address-book-edit-contact-payload.model';
import { IAddressBookPermissionInfo } from './../ts/models/permissions/address-book-permission-info.model';

export const fetchAddressBook = createAction(
	'[Address Book] Fetch Address Book',
	props<{ payload: { username: string } }>()
);

export const fetchAddressbookPermissionInfo = createAction(
	'[Address Book] Fetch Addressbook Permission Info'
);

export const addressBoookFetchCompleted = createAction(
	'[Address Book] Fetch Completed',
	props<{ payload: { contacts: IAddressBookContact[] } }>()
);

export const finishLoadingAddressbookInfo = createAction(
	'[Address Book] Finish Loading Addressbook Info',
	props<{ payload: { permissions: IAddressBookPermissionInfo } }>()
);

export const addContact = createAction(
	'[Address Book] Add Contact',
	props<{ payload: IAddressBookAddContactPayload }>()
);

export const editContact = createAction(
	'[Address Book] Edit Contact',
	props<{ payload: IAddressBookEditContactPayload }>()
);

export const deleteContacts = createAction(
	'[Address Book] Delete Contacts',
	props<{ payload: { username: string } }>()
);

export const exportContacts = createAction(
	'[Address Book] Export Contacts',
	props<{ payload: { username: string } }>()
);

export const cancelAddressBookHTTPObservables = createAction(
	'[Address Book] Cancel HTTP Observables'
);

export const setIsLoadingValue = createAction(
	'[Address Book] isLoading True',
	props<{ payload: { isLoading: boolean } }>()
);

export const updateSingleCheckedContact = createAction(
	'[Address Book] Updated Single Row Item Checkbox',
	props<{ payload: { contact: IAddressBookContact } }>()
);

export const markContactAsUnselectable = createAction(
	'[Address Book] Mark Contact As Unselectable',
	props<{ payload: { contact: IAddressBookContact } }>()
);

export const updateContacts = createAction(
	'[Address Book] Update Contacts',
	props<{ payload: { contacts: IAddressBookContact[] } }>()
);

export const setCheckedContactsState = createAction(
	'[Address Book] Set Checked Contacts State',
	props<{ payload: { isChecked: boolean } }>()
);

export const changedCurretlyVisibleContacts = createAction(
	'[Address Book] Set contact visibility',
	props<{ payload: { visibleContacts: IAddressBookContact[] } }>()
);
