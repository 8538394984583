import { Injectable } from '@angular/core';
import { PhoneUtilService } from './../phone-util.service';
import { PhoneCallsLogService } from './phone-calls-log.service';
import { PhoneMenuModes } from '../../ts/enums/phone-menu-modes.enum';
import { PhoneCallRecord } from '../../ts/types/phone-call-record.type';
import { IPhoneCallRecord } from './../../ts/models/phone-call-record.model';
import { IPhoneCallRecordInfo } from '../../ts/models/phone-call-record-info.model';
import { IOpcPanelUserRecord } from './../../../opc-panel/ts/models/session/opc-panel-user-record.model';
import { OpcPanelCallRecordState } from './../../../opc-panel/ts/enums/opc-panel-call-record-state.enum';
import { IOpcPanelCallRecord } from './../../../opc-panel/ts/models/session/opc-panel-call-record.model';
import { IOpcPanelSessionResponse } from './../../../opc-panel/ts/models/session/opc-panel-session-response.model';

@Injectable({
	providedIn: 'root'
})
export class PhoneCallsService {

	constructor(
		private readonly phoneCallsLogService: PhoneCallsLogService
	) { }

	public handleNewCallRecords(existingCallRecords: IPhoneCallRecord[], newCallRecords: IOpcPanelCallRecord[]): IPhoneCallRecord[] {
		return newCallRecords.reduce((accumulator, callRecord) =>
			this.handleSingleNewCallRecord(accumulator, callRecord), existingCallRecords);
	}

	public handleSingleNewCallRecord(callRecords: IPhoneCallRecord[], newCallRecord: IOpcPanelCallRecord): IPhoneCallRecord[] {
		const updatedNewCallRecord = this.phoneCallsLogService.addCallRecordProperties(newCallRecord);

		if (!callRecords.length) { return PhoneCallsService.handleEmptyNewCallRecord(updatedNewCallRecord); }

		const { State, CallID } = updatedNewCallRecord;
		const isNullRecord = PhoneUtilService.isNullCallRecord(State);
		const newRecordToSet = PhoneCallsService.handleAddNewNullRecord(isNullRecord, updatedNewCallRecord);
		const isNewRecordPresent = PhoneUtilService.isSomeCallIdMatching(callRecords, CallID);

		if (!isNewRecordPresent) { return PhoneCallsService.filterNullCallRecords([...callRecords, newRecordToSet]); }

		const updatedRecords = callRecords.map(callRecord => callRecord.CallID !== CallID ? callRecord : newRecordToSet);

		return PhoneCallsService.filterNullCallRecords(updatedRecords);
	}

	public updateCallRecords(callRecords: IPhoneCallRecord[]): IPhoneCallRecord[] {
		return callRecords.map(callRecord => this.phoneCallsLogService.addCallRecordProperties(callRecord));
	}

	public getLoggedUserCallRecords(response: IOpcPanelSessionResponse): IOpcPanelCallRecord[] {
		const { UserInfo, CallRecords } = response.Response.CtiMonitor;

		return CallRecords.filter(callRecord => PhoneCallsService.isMatchingCallRecord(callRecord, UserInfo.U));
	}

	public getLoggedUserCtipRecord(response: IOpcPanelSessionResponse): IOpcPanelUserRecord | null {
		const { UserInfo, UserRecords } = response.Response.CtiMonitor;

		return UserRecords.find(userRecord => PhoneCallsService.isMatchingCallRecord(userRecord, UserInfo.U)) || null;
	}

	public static getCallingNumber(matchingCallRecord: PhoneCallRecord | null): string {
		if (!matchingCallRecord) { return ''; }

		const { Direction, Called, Calling } = matchingCallRecord;

		return PhoneUtilService.isInDirection(Direction) ? Called : Calling;
	}

	public static someCallStateMatches(callRecords: IPhoneCallRecord[], states: OpcPanelCallRecordState[]): boolean {
		return callRecords.some(callRecord => states.includes(callRecord.State));
	}

	public static getPhoneMenuModeToSet(isDialpadUnavailable: boolean, callRecords: IPhoneCallRecord[]): PhoneMenuModes {
		return !callRecords.length || isDialpadUnavailable ? PhoneMenuModes.CORE : PhoneMenuModes.CALL;
	}

	public static getCallRecordByState(callRecords: IPhoneCallRecord[], state: OpcPanelCallRecordState): IPhoneCallRecord | null {
		return callRecords.find(callRecord => callRecord.State === state) || null;
	}

	public static everyCallStateMatches(callRecords: IPhoneCallRecord[], state: OpcPanelCallRecordState): boolean {
		return !!callRecords.length && callRecords.every(callRecord => callRecord.State === state);
	}

	public static getCallRecordCount(callRecords: IPhoneCallRecord[], states: OpcPanelCallRecordState[]): number {
		return callRecords.filter(callRecord => states.includes(callRecord.State)).length;
	}

	public static hasOnlyHoldAndActiveCalls(callRecords: IPhoneCallRecord[]): boolean {
		return !!callRecords.length && callRecords.every(callRecord => {
			const { State } = callRecord;

			return State === OpcPanelCallRecordState.HOLD || State === OpcPanelCallRecordState.ACTIVE;
		});
	}

	public static getMatchingCallRecordInfo(callRecordsInfo: IPhoneCallRecordInfo[], callRecordId: number): IPhoneCallRecordInfo | null {
		return callRecordsInfo.find(item => item.callId === callRecordId) || null;
	}

	private static filterNullCallRecords(callRecords: Array<IPhoneCallRecord | null>): IPhoneCallRecord[] {
		return callRecords.filter(calllRecord => calllRecord !== null) as IPhoneCallRecord[];
	}

	private static handleAddNewNullRecord(isNullRecord: boolean, newCallRecord: IPhoneCallRecord): IPhoneCallRecord | null {
		return !isNullRecord ? newCallRecord : null;
	}

	private static handleEmptyNewCallRecord(updatedNewCallRecord: IPhoneCallRecord): IPhoneCallRecord[] {
		return updatedNewCallRecord.State !== OpcPanelCallRecordState.NULL ? [updatedNewCallRecord] : [];
	}

	private static isMatchingCallRecord(callRecord: PhoneCallRecord | IOpcPanelUserRecord, username: string): boolean {
		const { user, interface: phoneInterface } = callRecord;

		return user === username && PhoneUtilService.isCtipInterface(phoneInterface);
	}
}
