import { Directive, ElementRef, Renderer2, Input, OnChanges } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[appPasswordText]'
})
export class PasswordTextDirective implements OnChanges {

	@Input() translateToPasswordType: boolean;
	@Input() passwordTextState: boolean | null;

	constructor(
		private renderer2: Renderer2,
		private elementRef: ElementRef
	) { }

	ngOnChanges(): void {
		if (!this.translateToPasswordType) { return; }

		const fieldType: 'text' | 'password' = this.passwordTextState ? 'text' : 'password';
		this.renderer2.setProperty(this.elementRef.nativeElement, 'type', fieldType);
	}
}
