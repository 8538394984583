import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../../root-reducers';
import { WioNgrxService } from './wio-ngrx.service';
import { ISelectData } from '../../../ts/models/select-data.model';
import { RioRvsStoreKey } from '../ts/enums/rio-rvs-store-key.enum';
import { RioRvsDeviceTab } from '../ts/enums/rio-rvs-device-tab.enum';
import { IRioRvsWioDevice } from '../ts/models/rio-rvs-wio-device.model';
import { RioRvsWioDeviceType } from '../ts/enums/rio-rvs-device-type.enum';
import { RioRvsDoutTableService } from './tables/rio-rvs-dout-table.service';
import { RioRvsAoutTableService } from './tables/rio-rvs-aout-table.service';
import { RioRvsDiscoveryType } from '../ts/enums/rio-rvs-discovery-type.enum';
import { RioRvsModalLineDeviceType } from '../ts/types/rio-rvs-modal-line-device.type';
import { RioRvsFormComponent } from '../components/rio-rvs-form/rio-rvs-form.component';
import { RioRvsWioIohubDeviceClass } from '../ts/enums/rio-rvs-wio-iohub-device-class.enum';
import { AutomationGenericService } from '../../automation/services/automation-generic.service';
import { GenericFormValue } from '../../ui-components/generic-table/ts/types/generic-form-value.type';
import { RioRvsWioIohubShortDeviceClass } from '../ts/enums/rio-rvs-wio-iohub-short-device-class.enum';
import { IRioRvsModalAnalogLineDevice } from '../ts/models/modal-line-devices/rio-rvs-modal-analog-line-device.model';
import { IRioRvsModalDigitalLineDevice } from '../ts/models/modal-line-devices/rio-rvs-modal-digital-line-device.model';
import * as rioRvsSelectors from '../ngrx/rio-rvs.selector';
import * as rioRvsActions from '../ngrx/rio-rvs.action';

@Injectable({
	providedIn: 'root'
})
export class RioRvsNgrxService {

	constructor(
		private store: Store<IAppState>,
		private wioNgrxService: WioNgrxService,
		private rioRvsDoutTableService: RioRvsDoutTableService,
		private rioRvsAoutTableService: RioRvsAoutTableService
	) { }

	loadRioRvsWioDevices(discovery: RioRvsDiscoveryType): void {
		const payload = { discovery };

		this.store.dispatch(rioRvsActions.rioRvsWioLoadDevices({ payload }));
	}

	loadDeviceConfiguration(deviceName: string): void {
		const payload = { deviceName };

		this.store.dispatch(rioRvsActions.loadDeviceConfiguration({ payload }));
	}

	autoLoadWioSlaveNodes(): void {
		this.store.dispatch(rioRvsActions.autoLoadWioSlaveNodes());
	}

	fillDevices(macIp: string): void {
		const payload = { macIp };

		this.store.dispatch(rioRvsActions.fillRioRvsWioLineDevices({ payload }));
	}

	cancelRioRvsHTTPObservables(): void {
		this.store.dispatch(rioRvsActions.cancelRioRvsHTTPObservables());
	}

	removeRioRvsAction(selectedDevice: IRioRvsWioDevice): void {
		const payload = { selectedDevice };

		this.store.dispatch(rioRvsActions.rioRvsReleaseDeviceAction({ payload }));
	}

	unbindRioRvsAction(selectedDevice: IRioRvsWioDevice): void {
		const payload = { selectedDevice };

		this.store.dispatch(rioRvsActions.rioRvsUnbindDeviceAction({ payload }));
	}

	setSelectedDevice(selectedDevice: IRioRvsWioDevice): void {
		const payload = { selectedDevice };

		this.store.dispatch(rioRvsActions.setSelectedDevice({ payload }));
	}

	handleBindRioRvsDevice(selectedDevice: IRioRvsWioDevice, rioRvsFormComponent: RioRvsFormComponent, callback: () => void): void {
		const { ipAddress, resourceToBind, deviceDescription } = rioRvsFormComponent.rioRvsForm.value;
		const bindDeviceId = AutomationGenericService.splitAutomationItemByDash(resourceToBind)[1];

		this.bindRioRvsDevice(selectedDevice, ipAddress, deviceDescription, bindDeviceId, callback);
	}

	bindRioRvsDevice(
		selectedDevice: IRioRvsWioDevice, newDeviceIpAddress: string,
		newDeviceDescription: string, bindDeviceId: string, callback: () => void
	): void {
		const payload = { selectedDevice, newDeviceIpAddress, newDeviceDescription, bindDeviceId, callback };

		this.store.dispatch(rioRvsActions.rioRvsBindDeviceAction({ payload }));
	}

	updateDeviceInformation(updateDevice: IRioRvsWioDevice, formValues: string[], callback: () => void): void {
		const [newIpAddress, newDescription] = formValues;
		const payload = { updateDevice, newIpAddress, newDescription, callback };

		this.store.dispatch(rioRvsActions.updateDeviceInformationAction({ payload }));
	}

	loadModalRioRvsWioLineDevices(resource: string, checkIfDevicesAreLoading = false): void {
		const [deviceName, resourceId] = AutomationGenericService.splitAutomationItemByDash(resource);

		if (!resourceId) { return; }

		const payload = { type: deviceName as RioRvsWioDeviceType, resourceId, checkIfDevicesAreLoading };

		this.store.dispatch(rioRvsActions.startLoadingRioRvsWioLineDevices({ payload }));
	}

	saveRioRvsLineDeviceChanges(
		selectedRioRvsDevice: RioRvsModalLineDeviceType, resource: string,
		formValues: GenericFormValue[], iohubClass: RioRvsWioIohubDeviceClass
	): void {
		const { lineDeviceType, originalDeviceType } = selectedRioRvsDevice;
		const [imagePath] = formValues as string[];

		const newIcon = { id: `${lineDeviceType}-${originalDeviceType}`, imagePath };
		const payload = { newIcon, selectedRioRvsDevice, resource, formValues, iohubClass };

		this.store.dispatch(rioRvsActions.saveLineDeviceChange({ payload }));
	}

	setLoadingRioRvsLineDevices(isLoading: boolean): void {
		const payload = { isLoading };

		this.store.dispatch(rioRvsActions.setLoadingFlagRioRvsLineDevices({ payload }));
	}

	selectDigitalRioRvsLineDevices$(deviceType: RioRvsWioIohubDeviceClass): Observable<IRioRvsModalDigitalLineDevice[]> {
		return this.store.pipe(select(rioRvsSelectors.selectSpecificRioRvsLineDevices(deviceType))
		) as Observable<IRioRvsModalDigitalLineDevice[]>;
	}

	selectAnalogRioRvsLineDevices$(deviceType: RioRvsWioIohubDeviceClass): Observable<IRioRvsModalAnalogLineDevice[]> {
		return this.store.pipe(
			select(rioRvsSelectors.selectSpecificRioRvsLineDevices(deviceType))
		) as Observable<IRioRvsModalAnalogLineDevice[]>;
	}

	selectBindeableDevices$(bindableDevices: string[]): Observable<ISelectData[]> {
		return this.store.pipe(select(rioRvsSelectors.selectAllAvailableBindableDevices(bindableDevices)))
			.pipe(
				map(response => response.map(responseItem => ({ value: responseItem, uiValue: responseItem })))
			);
	}

	selectAllRioRvsDevices$(): Observable<IRioRvsWioDevice[]> {
		return this.store.pipe(select(rioRvsSelectors.selectAllRioRvsDevices()));
	}

	selectFromRioRvsStore$<T>(selector: RioRvsStoreKey): Observable<T> {
		return this.store.pipe(select(rioRvsSelectors.selectFromRioRvsStore(selector))) as unknown as Observable<T>;
	}

	eraseIconConfiguration(resource: string): void {
		const payload = { resource };

		this.store.dispatch(rioRvsActions.eraseIconConfiguration({ payload }));
	}

	setIsLoading(isLoading: boolean): void {
		const payload = { isLoading };

		this.store.dispatch(rioRvsActions.setLoadingStateAction({ payload }));
	}

	selectLineDeviceByType$(
		deviceType: RioRvsDeviceTab, wionode: string
	): Observable<Array<IRioRvsModalDigitalLineDevice | IRioRvsModalAnalogLineDevice>> {
		const isWioSlaveNode = wionode !== '';

		switch (deviceType) {
		case RioRvsDeviceTab.DIGITAL_INPUT:
			return this.getDinLineDevices$(isWioSlaveNode, wionode);
		case RioRvsDeviceTab.DIGITAL_OUTPUT:
			return this.getDoutLineDevices$(isWioSlaveNode, wionode);
		case RioRvsDeviceTab.ANALOG_INPUT:
			return this.getAinLineDevices$(isWioSlaveNode, wionode);
		case RioRvsDeviceTab.ANALOG_OUTPUT:
			return this.getAoutLineDevices$(isWioSlaveNode, wionode);
		default:
			return of([]);
		}
	}

	getDinLineDevices$(isWioSlaveNode: boolean, wionode: string): Observable<IRioRvsModalDigitalLineDevice[]> {
		if (isWioSlaveNode && wionode) {
			return this.wioNgrxService.selectWioNodeLineDevices$<IRioRvsModalDigitalLineDevice[]>
			(RioRvsWioIohubShortDeviceClass.DI, +wionode);
		}

		return this.selectDigitalRioRvsLineDevices$(RioRvsWioIohubDeviceClass.DIN);
	}

	getAinLineDevices$(isWioSlaveNode: boolean, wionode: string): Observable<IRioRvsModalAnalogLineDevice[]> {
		if (isWioSlaveNode && wionode) {
			return this.wioNgrxService.selectWioNodeLineDevices$<IRioRvsModalAnalogLineDevice[]>
			(RioRvsWioIohubShortDeviceClass.AI, +wionode)
				.pipe(
					map(ainDevices => this.rioRvsAoutTableService.updateAinAoutDevices(ainDevices))
				);
		}

		return this.selectAnalogRioRvsLineDevices$(RioRvsWioIohubDeviceClass.AIN)
			.pipe(
				map(ainDevices => this.rioRvsAoutTableService.updateAinAoutDevices(ainDevices))
			);
	}

	getDoutLineDevices$(isWioSlaveNode: boolean, wionode: string): Observable<IRioRvsModalDigitalLineDevice[]> {
		if (isWioSlaveNode && wionode) {
			return this.wioNgrxService.selectWioNodeLineDevices$<IRioRvsModalDigitalLineDevice[]>
			(RioRvsWioIohubShortDeviceClass.DO, +wionode)
				.pipe(
					map(doutDevices => this.rioRvsDoutTableService.formatDoutDevices(doutDevices))
				);
		}

		return this.selectDigitalRioRvsLineDevices$(RioRvsWioIohubDeviceClass.DOUT)
			.pipe(
				map(doutDevices => this.rioRvsDoutTableService.formatDoutDevices(doutDevices))
			);
	}

	getAoutLineDevices$(isWioSlaveNode: boolean, wionode: string): Observable<IRioRvsModalAnalogLineDevice[]> {
		if (isWioSlaveNode && wionode) {
			return this.wioNgrxService.selectWioNodeLineDevices$<IRioRvsModalAnalogLineDevice[]>
			(RioRvsWioIohubShortDeviceClass.AO, +wionode)
				.pipe(
					map(aoutDevices => this.rioRvsAoutTableService.updateAinAoutDevices(aoutDevices, true))
				);
		}

		return this.selectAnalogRioRvsLineDevices$(RioRvsWioIohubDeviceClass.AOUT)
			.pipe(
				map(aoutDevices => this.rioRvsAoutTableService.updateAinAoutDevices(aoutDevices, true))
			);
	}
}
