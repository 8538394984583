import { createAction, props } from '@ngrx/store';
import { RioRvsTab } from '../ts/enums/rio-rvs-tab.enum';
import { IWioNodeModel } from '../ts/models/wio-node.model';
import { IRioRvsWioDevice } from '../ts/models/rio-rvs-wio-device.model';
import { IRioRvsSavedIcon } from '../ts/models/rio-rvs-saved-icon.model';
import { RioRvsWioDeviceType } from '../ts/enums/rio-rvs-device-type.enum';
import { RioRvsDiscoveryType } from '../ts/enums/rio-rvs-discovery-type.enum';
import { RioRvsModalLineDeviceType } from '../ts/types/rio-rvs-modal-line-device.type';
import { RioRvsWioIohubDeviceClass } from '../ts/enums/rio-rvs-wio-iohub-device-class.enum';
import { GenericFormValue } from '../../ui-components/generic-table/ts/types/generic-form-value.type';

export const rioRvsWioLoadDevices = createAction(
	'[Rio Rvs] Load Devices',
	props<{ payload: { discovery: RioRvsDiscoveryType } }>()
);

export const autoLoadWioSlaveNodes = createAction(
	'[Rio Rvs] Auto Load All Wio Slave Nodes'
);

export const fillRioRvsWioLineDevices = createAction(
	'[Rio Rvs] Fill Rio/Rvs/Wio Devices In Store',
	props<{ payload: { macIp: string } }>()
);

export const loadRioRvsDevicesTableOverview = createAction(
	'[Rio Rvs] Start Rio Rvs Device Loading',
	props<{ payload: { macIp: string } }>()
);

export const setSelectedDevice = createAction(
	'[Rio Rvs] Set Selected Rio Rvs Device',
	props<{ payload: { selectedDevice: IRioRvsWioDevice } }>()
);

export const finishLoadingRioRvsDevices = createAction(
	'[Rio Rvs] Device Fetch Action Finished',
	props<{ payload: { devices: IRioRvsWioDevice[] } }>()
);

export const rioRvsReleaseDeviceAction = createAction(
	'[Rio Rvs] Release Device',
	props<{ payload: { selectedDevice: IRioRvsWioDevice } }>()
);

export const rioRvsUnbindDeviceAction = createAction(
	'[Rio Rvs] Unbind Device',
	props<{ payload: { selectedDevice: IRioRvsWioDevice } }>()
);

export const rioRvsBindDeviceAction = createAction(
	'[Rio Rvs] Bind Device',
	props<{
		payload: {
			selectedDevice: IRioRvsWioDevice; newDeviceIpAddress: string;
			newDeviceDescription: string; bindDeviceId: string; callback: () => void;
		};
	}>()
);

export const updateDeviceInformationAction = createAction(
	'[Rio Rvs] Update Device Information',
	props<{ payload: {
		updateDevice: IRioRvsWioDevice; newIpAddress: string;
		newDescription: string; callback: () => void;
	}; }>()
);

export const startLoadingRioRvsWioLineDevices = createAction(
	'[Rio Rvs] Line Device Start Loading Rio/Rvs/Wio',
	props<{ payload: { type: RioRvsWioDeviceType; resourceId: string; checkIfDevicesAreLoading: boolean } }>()
);

export const setMultipleWioValuesAtOnce = createAction(
	'[Rio Rvs] Set Multiple Values At Once',
	props<{ payload: { tab: RioRvsTab; device: IRioRvsWioDevice; parameter: string; value: string; selectedWioNodeItem: IWioNodeModel } }>()
);

export const refreshWioNodes = createAction(
	'[Rio Rvs] Refresh WIO Nodes',
	props<{ payload: { selectedWioDevice: IRioRvsWioDevice; selectedWioNode?: number } }>()
);

export const finishLoadingRioRvsWioLineDevices = createAction(
	'[Rio Rvs] Line Device Finished Loading',
	props<{ payload: { rioRvsWioLineDevices: RioRvsModalLineDeviceType[] } }>()
);

export const setLoadingStateAction = createAction(
	'[Rio Rvs] Set Loading State',
	props<{ payload: { isLoading: boolean } }>()
);

export const eraseIconConfiguration = createAction(
	'[Rio Rvs] Erase Icon Configuration',
	props<{ payload: { resource: string } }>()
);

export const saveLineDeviceChange = createAction(
	'[Rio Rvs] Save DIN Line Device Changes',
	props<{
		payload: {
			newIcon: IRioRvsSavedIcon; resource: string; iohubClass: RioRvsWioIohubDeviceClass;
			formValues: GenericFormValue[]; selectedRioRvsDevice: RioRvsModalLineDeviceType;
		};
	}>()
);

export const cancelRioRvsHTTPObservables = createAction(
	'[Rio Rvs] Cancel Rio Rvs HTTP Observables'
);

export const setLoadingFlagRioRvsLineDevices = createAction(
	'[Rio Rvs] Set Loading Rio Rvs Line Devices',
	props<{ payload: { isLoading: boolean } }>()
);

export const fetchAllWioSlaveNodes = createAction(
	'[Rio Rvs] Fetch all Wio Slave Nodes'
);

export const finishLoadingWioSlaveNodes = createAction(
	'[Rio Rvs] Finish Loading WIO slave nodes',
	props<{ payload: { wioSlaveNodes: IWioNodeModel[] } }>()
);

export const updateWioSlaveInformation = createAction(
	'[Rio Rvs] Update WIO Slave Information',
	props<{ payload: { wioSlaveNode: IWioNodeModel; newDescription: string } }>()
);

export const setLoadingWioSlaveNodes = createAction(
	'[Rio Rvs] Set Loading Wio Slave Nodes',
	props<{ payload: { isLoading: boolean } }>()
);

export const removeWioSlaveNode = createAction(
	'[Rio Rvs] Remove Wio Slave Node',
	props<{ payload: { selectedWioSlaveNode: IWioNodeModel; selectedWioMaster: IRioRvsWioDevice } }>()
);

export const loadDeviceConfiguration = createAction(
	'[Rio Rvs] Load Device Configuration',
	props<{ payload: { deviceName: string } }>()
);
