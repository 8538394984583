<div class="content">
	<h2>404</h2>
	<h5>{{'ERROR.cantFind' | translate}}</h5>
	<h6>{{'ERROR.exist' | translate}}</h6>

	<button type="button" class="btn btn-primary" (click)="goBack()">
		<img src="assets/feather-icons/arrow-left.svg" class="content__icon" alt="left arrow">
		<span>{{'ERROR.goBack' | translate}}</span>
	</button>
</div>
