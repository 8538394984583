import { forkJoin, of, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap, switchMap, takeUntil, take, withLatestFrom } from 'rxjs/operators';
import { AutomationProgramEditModalService } from '../services/automation-program-edit-modal.service';
import { AutomationProgramDevicesService } from '../services/devices/automation-program-devices.service';
import { AutomationProgramSpreadsheetHttpService } from '../services/http/automation-program-spreadsheet-http.service';
import { AutomationProgramSpreadsheetNgrxService } from '../services/ngrx/automation-program-spreadsheet-ngrx.service';
import { AutomationProgramSpreadsheetVoutService } from '../services/spreadsheet/automation-program-spreadsheet-vout.service';
import * as automationProgramActions from './automation-program-edit.actions';

@Injectable()
export class AutomationProgramTableEffects {

	constructor(
		private actions$: Actions,
		private automationProgramModalService: AutomationProgramEditModalService,
		private automationProgramDevicesService: AutomationProgramDevicesService,
		private automationProgramSpreadsheetHttpService: AutomationProgramSpreadsheetHttpService,
		private automationProgramSpreadsheetVoutService: AutomationProgramSpreadsheetVoutService,
		private automationProgramSpreadsheetNgrxService: AutomationProgramSpreadsheetNgrxService
	) { }

	automationProgramDeviceFetchRequest$ = createEffect(() => this.actions$.pipe(
		ofType(automationProgramActions.loadLineDevices),
		switchMap(() => this.automationProgramSpreadsheetHttpService.pollFetchDevicesStatus$()
			.pipe(
				takeUntil(this.actions$.pipe(ofType(automationProgramActions.cancelAutomationProgramObservables)))
			)),
		map(lineDevices => automationProgramActions.updateProjectLineDevices({ payload: { lineDevices } }))
	));

	fetchModalLineDevices$ = createEffect(() => this.actions$.pipe(
		ofType(automationProgramActions.fetchModalLineDevices),
		switchMap(action => forkJoin({
			okAction: of(action.payload.successCallback),
			modalDevices: this.automationProgramSpreadsheetHttpService.fetchModalDevices$()
		})
			.pipe(
				takeUntil(this.actions$.pipe(ofType(automationProgramActions.cancelAutomationProgramObservables)))
			)),
		tap(response => {
			const { okAction, modalDevices } = response;

			this.automationProgramSpreadsheetNgrxService.finishLoadingModalDevices(modalDevices);

			if (okAction) {
				okAction();
			}
		})
	), { dispatch: false });

	addActuators$ = createEffect(() => this.actions$.pipe(
		ofType(automationProgramActions.addActuator),
		withLatestFrom(this.automationProgramSpreadsheetNgrxService.selectActuators$()),
		tap(response => {
			const [action, actuators] = response;
			const { actuator } = action.payload;

			this.automationProgramSpreadsheetVoutService.handleSetActuators(actuator, actuators);
		})
	), { dispatch: false });

	addNewInputValue$ = createEffect(() => this.actions$.pipe(
		ofType(automationProgramActions.addNewInputValue),
		map(action => {
			const { newInputValue, id: fullDeviceName } = action.payload.newInput;
			const newInput = { newInputValue, id: fullDeviceName };

			return automationProgramActions.updateNewInputValue({ payload: { newInput } });
		})
	));

	addNewVout$ = createEffect(() => this.actions$.pipe(
		ofType(automationProgramActions.addNewVout),
		switchMap(() => zip(
			this.automationProgramSpreadsheetNgrxService.selectAllVouts$(),
			this.automationProgramSpreadsheetNgrxService.selectAllSpreadsheetLineDevices$()
		).pipe(
			take(1)
		)),
		tap(response => {
			const [vouts, selectedLineDevices] = response;
			const newVout = this.automationProgramSpreadsheetVoutService.formatNewVout(vouts);

			this.automationProgramModalService.setSpreadsheetDevices([...selectedLineDevices, { ...newVout }]);
		})
	), { dispatch: false });

	deleteVout$ = createEffect(() => this.actions$.pipe(
		ofType(automationProgramActions.deleteVout),
		withLatestFrom(this.automationProgramSpreadsheetNgrxService.selectAllSpreadsheetLineDevices$()),
		tap(response => {
			const [action, selectedLineDevices] = response;
			const { vout } = action.payload;
			const fullDeviceName = vout.fullDeviceName as string;
			const filteredVoutDevices = this.automationProgramDevicesService.filterSpecifiedVout(selectedLineDevices, fullDeviceName);

			this.automationProgramModalService.setSpreadsheetDevices(filteredVoutDevices);
		})
	), { dispatch: false });

	updateDevicePositionOnDragDrop$ = createEffect(() => this.actions$.pipe(
		ofType(automationProgramActions.updateDevicePositionOnDragDrop),
		withLatestFrom(this.automationProgramSpreadsheetNgrxService.selectAllSpreadsheetLineDevices$()),
		tap(response => {
			const [action, selectedLineDevices] = response;
			const { currentIndex, previousIndex } = action.payload;
			const newSpreadsheetDevices = this.automationProgramDevicesService.formatDragDropDevices(
				selectedLineDevices, currentIndex, previousIndex
			);

			this.automationProgramModalService.setSpreadsheetDevices(newSpreadsheetDevices);
		})
	), { dispatch: false });
}
