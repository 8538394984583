import { CommonModule } from '@angular/common';
import { Component, ViewChild, TemplateRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'app-tab-label',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './tab-label.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class TabLabelComponent {
	@ViewChild(TemplateRef) labelContent: TemplateRef<unknown>;
}
