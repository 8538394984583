import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'translateFix'
})
export class TranslateFixPipe implements PipeTransform {

	transform(translatedValue: string | Record<string, string>, valueToTranslate: string): string {
		return typeof translatedValue === 'string' ? translatedValue : valueToTranslate;
	}
}
