import { Injectable } from '@angular/core';
import { ToastrType } from './../../../../ts/enums/toastr-type.enum';
import { MainUtilService } from './../../../../services/main-util.service';
import { IAddressBookContact } from '../ts/models/address-book-contact.model';
import { AddressBookCustomType } from './../ts/enums/address-book-custom-type.enum';
import { AddressBookContactType } from './../ts/enums/address-book-contact-type.enum';
import { AddressBookNgrxActionService } from './ngrx/address-book-ngrx-action.service';
import { IAddressBookOperationResponse } from './../ts/models/address-book-operation-response.model';

@Injectable({
	providedIn: 'root'
})
export class AddressBookContactUtilService {

	private readonly customContactTypes = {
		[AddressBookContactType.PUBLIC]: AddressBookCustomType.SHARED,
		[AddressBookContactType.PRIVATE]: AddressBookCustomType.PERSONAL
	};

	private readonly originalContactTypes = {
		[AddressBookCustomType.SHARED]: AddressBookContactType.PUBLIC,
		[AddressBookCustomType.PERSONAL]: AddressBookContactType.PRIVATE
	};

	constructor(
		private readonly mainUtilService: MainUtilService,
		private readonly addressBookNgrxActionService: AddressBookNgrxActionService
	) { }

	/**
	 * Converts original contact type (PUBLIC, PRIVATE) to custom contact type (SHARED, PERSONAL)
	 * @param {AddressBookContactType} type Value of Address Book Contact Type
	 */
	public translateContactTypeToCustom(type: AddressBookContactType): AddressBookCustomType {
		return this.customContactTypes[type.toLowerCase() as AddressBookContactType];
	}

	/**
	 * Converts custom contact type (SHARED, PERSONAL) to original (PUBLIC, PRIVATE)
	 * @param {AddressBookCustomType} type Value of Address Book Custom Type
	 */
	public translateContactTypeToOriginal(type: AddressBookCustomType): AddressBookContactType {
		return this.originalContactTypes[type.toLowerCase() as AddressBookCustomType];
	}

	/**
	 * Handles either adding/editing HTTP response by presenting appropriate toastr and fetching Address Book table
	 * @param {IAddressBookOperationResponse} result HTTP action response
	 * @param {boolean} username Passed username, so that it's known for which user Address Book table should refresh
	 */
	public handleAddEditContactResponse(result: IAddressBookOperationResponse, username: string): void {
		const { code, rsp } = result.Response;

		if (!AddressBookContactUtilService.isActionOk(code)) {
			this.mainUtilService.showToastrMessage(rsp, ToastrType.ERROR);
			return;
		}

		this.addressBookNgrxActionService.fetchAddressBook(username);
		this.mainUtilService.showToastrMessage(rsp, ToastrType.SUCCESS);
	}

	/**
	 * Checks if user can edit existing contact info
	 * @param {boolean} publicContactsProtected If from HTTP Response, there is restriction to edit public contacts
	 * @param {boolean} isPrivateContact If selected contact is private
	 */
	public static canModifyContact(publicContactsProtected: boolean, isPrivateContact: boolean): boolean {
		return isPrivateContact || !publicContactsProtected;
	}

	/**
	 * Checks if executed Address Book action is successful
	 * @param {number} code Passed response's code number. Successful code value is 10
	 */
	public static isActionOk(code: number): boolean {
		return code === 10;
	}

	/**
	 * Checks if provided contact type is 'public' or 'private'
	 * @param {AddressBookContactType} type Passed Sddress book contact type (either 'private' or 'public')
	 */
	public static isPublicContact(type: AddressBookContactType): boolean {
		return type === AddressBookContactType.PUBLIC;
	}

	/**
	 * Provides contact type icon for the main table view, in order to distinguish between private and public contact type
	 * @param {AddressBookContactType} type Passed Address Book contact type (either 'private' or 'public')
	 */
	public static getContactTypeIcon(type: AddressBookContactType): string {
		return type === AddressBookContactType.PRIVATE ? 'user.svg' : 'building.svg';
	}

	/**
	 * Creates new empty IAddressBookContact object
	 */
	public static getNewAddresbookItem(): IAddressBookContact {
		return {
			type: AddressBookContactType.PRIVATE, name: '', num: '', od: '',
			tag: '', email: '', checkboxState: false, contactTypeIcon: '',
			canModifyContact: false, updatedType: AddressBookCustomType.SHARED,
			rowDisabled: false,
			isInView: false
		};
	}
}
