<div class="modal-header">
	<h5 class="modal-title pull-left">{{ 'COMMON.confirmation' | translate }}</h5>
</div>

<div class="modal-body">
	<p>{{message}}</p>

	<div class="title__name">
		<button type="button" class="btn btn-sm btn-primary" (click)="onConfirm()">
			{{ 'COMMON.yes' | translate }}
		</button>

		<button type="button" class="btn btn-sm btn-primary" (click)="onCancel()">
			{{ 'COMMON.no' | translate }}
		</button>
	</div>
</div>
