import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RioRvsTab } from '../ts/enums/rio-rvs-tab.enum';
import { RioRvsNgrxService } from './rio-rvs-ngrx.service';
import { RoutesEnum } from '../../../ts/enums/routes.enum';
import { IWioNodeModel } from '../ts/models/wio-node.model';
import { RioRvsImageService } from './rio-rvs-image.service';
import { RioRvsOwner } from '../ts/enums/rio-rvs-owner.enum';
import { WioSlavesState } from '../ts/enums/wio-slaves-state.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainUtilService } from '../../../services/main-util.service';
import { RioRvsDeviceTab } from '../ts/enums/rio-rvs-device-tab.enum';
import { IRioRvsSavedIcon } from '../ts/models/rio-rvs-saved-icon.model';
import { IRioRvsWioDevice } from '../ts/models/rio-rvs-wio-device.model';
import { RioRvsWioDeviceType } from '../ts/enums/rio-rvs-device-type.enum';
import { RioRvsIohubDeviceType } from '../ts/enums/rio-rvs-iohub-device-type.enum';
import { RioRvsModalLineDeviceType } from '../ts/types/rio-rvs-modal-line-device.type';
import { RioRvsWioIohubDeviceClass } from '../ts/enums/rio-rvs-wio-iohub-device-class.enum';
import { RioRvsWioIohubShortDeviceClass } from '../ts/enums/rio-rvs-wio-iohub-short-device-class.enum';
import { IRioRvsModalDigitalLineDevice } from '../ts/models/modal-line-devices/rio-rvs-modal-digital-line-device.model';

@Injectable({
	providedIn: 'root'
})
export class RioRvsService {

	private isPortsConfiguration = false;
	private editDeviceActiveTab: RioRvsTab;

	private refreshInProgress = new BehaviorSubject(false);

	private readonly unavailableMac = '00-00-00-00-00-00';

	private readonly ainTypes = ['AnalogInputs', 'AnalogInput'];
	private readonly dinTypes = ['DigitalInputs', 'DigitalInput', 'DigitalInputBulbs', 'DigitalInputBulb'];
	private readonly doutTypes = [
		'DigitalOutputs', 'DigitalOutput', 'DigitalOutputBulbs', 'DigitalOutputBulb', 'DigitalOutputSwitches',
		'DigitalOutputSwitch', 'DigitalOutputToggles', 'DigitalOutputToggle', 'DigitalOutputPulses', 'DigitalOutputPulse'
	];
	private readonly aoutTypes = [
		'AnalogOutputs', 'AnalogOutput', 'AnalogOutputSliders', 'AnalogOutputSlider', 'AnalogOutputSpinners',
		'AnalogOutputSpinner', 'AnalogOutputMonitors', 'AnalogOutputMonitor', 'AnalogOutputMonitorControls',
		'AnalogOutputMonitorControl'
	];

	private readonly deviceMainClassTypes = [
		{ deviceTypes: this.dinTypes, type: RioRvsWioIohubDeviceClass.DIN },
		{ deviceTypes: this.doutTypes, type: RioRvsWioIohubDeviceClass.DOUT },
		{ deviceTypes: this.ainTypes, type: RioRvsWioIohubDeviceClass.AIN },
		{ deviceTypes: this.aoutTypes, type: RioRvsWioIohubDeviceClass.AOUT }
	];

	private readonly deviceMainClassAlarmTypes = [
		{ deviceTypes: this.dinTypes, type: RioRvsWioIohubDeviceClass.DIN },
		{ deviceTypes: this.doutTypes, type: RioRvsWioIohubDeviceClass.DOUT },
		{ deviceTypes: [...this.ainTypes, 'AnalogInputsAlarms'], type: RioRvsWioIohubDeviceClass.AIN },
		{ deviceTypes: [...this.aoutTypes, 'AnalogOutputsAlarms'], type: RioRvsWioIohubDeviceClass.AOUT }
	];

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private translate: TranslateService,
		private rioRvsNgrxService: RioRvsNgrxService,
		private rioRvsImageService: RioRvsImageService
	) { }

	formatConfirmationTextRelease(resource: string, deviceOwner: RioRvsOwner, deviceType: RioRvsWioDeviceType): string {
		if (deviceOwner.toLowerCase() === 'own') {
			return this.translate.instant('AUTO.PREVIEW.removalConfGnrc');
		}

		if (deviceType.toLowerCase() === RioRvsIohubDeviceType.RVS) {
			return this.translate.instant('AUTO.PREVIEW.removalConfPart1') + `${resource}` +
				this.translate.instant('AUTO.PREVIEW.removalConfRvsPart2');
		}

		return this.translate.instant('AUTO.PREVIEW.removalConfPart1') + `${resource}` +
			this.translate.instant('AUTO.PREVIEW.removalConfPart2');
	}

	getDeviceMainClass(lineDeviceType: string, checkForAnalogAlarms: boolean = true): RioRvsWioIohubDeviceClass | null {
		const matchingGroup = (!checkForAnalogAlarms ? this.deviceMainClassTypes : this.deviceMainClassAlarmTypes)
			.find(item => item.deviceTypes.includes(lineDeviceType));

		return matchingGroup?.type || null;
	}

	getWioLineType(deviceType: RioRvsWioIohubDeviceClass | null): RioRvsWioIohubShortDeviceClass | string {
		switch (deviceType) {
		case RioRvsWioIohubDeviceClass.DIN:
			return RioRvsWioIohubShortDeviceClass.DI;
		case RioRvsWioIohubDeviceClass.DOUT:
			return RioRvsWioIohubShortDeviceClass.DO;
		case RioRvsWioIohubDeviceClass.AIN:
			return RioRvsWioIohubShortDeviceClass.AI;
		case RioRvsWioIohubDeviceClass.AOUT:
			return RioRvsWioIohubShortDeviceClass.AO;
		default:
			return '';
		}
	}

	getSavedIcon(lineDeviceType: string, originalDeviceType: string, iconList: IRioRvsSavedIcon[]): string {
		const matchingIcon = iconList.find(iconItem => iconItem.id === lineDeviceType + '-' + originalDeviceType);

		return matchingIcon ? matchingIcon.imagePath : this.rioRvsImageService.getDeviceImagePath(originalDeviceType);
	}

	formatBindDataForModal(
		maxDevices: number, deviceType: RioRvsIohubDeviceType, bindableDevices: string[]
	): string[] {
		const bindableDevicesByType = bindableDevices.filter(device => device.toLowerCase().startsWith(deviceType)).map(dev => dev.toUpperCase());

		return MainUtilService.getArrayFromTotalLength(maxDevices).reduce((accumulator, iterableNumber) => {
			const deviceNumber = iterableNumber + 1;

			if (!bindableDevicesByType.includes(`${deviceType.toUpperCase()}-${deviceNumber}`)) {
				const device = `${MainUtilService.capitalCaseString(deviceType)}-${deviceNumber}`;

				return [...accumulator, device];
			}

			return accumulator;
		}, [] as string[]);
	}

	updateDinDoutDevices(dinDoutDevices: IRioRvsModalDigitalLineDevice[]): IRioRvsModalDigitalLineDevice[] {
		return dinDoutDevices.map(dinDoutDevice => {
			const { dft } = dinDoutDevice;
			const dftUI = RioRvsService.translateUnavailableValues(dft);

			return { ...dinDoutDevice, dftUI };
		});
	}

	static translateUnavailableValues(value: string | number): string | number {
		if (typeof value === 'string') {
			return value === '9999' || value === '-9999' ? '' : value;
		}

		return value === 9999 || value === -9999 || value === undefined ? '' : value;
	}

	getRemoveDeviceTranslation(resource: string): string {
		const proceedTranslate = this.translate.instant('AUTO.PREVIEW.proceed');
		const translatedProcees = this.translate.instant('AUTO.PREVIEW.unbindDevice');

		return `${translatedProcees} ${resource}. ${proceedTranslate}`;
	}

	buildRioRvsForm(isWioSlave: boolean): FormGroup {
		return this.formBuilder.group({
			ipAddress: [''],
			resourceToBind: [''],
			deviceDescription: ['', isWioSlave ? [Validators.maxLength(14)] : [Validators.maxLength(79)]]
		});
	}

	getDeviceTypeOfTab(tab: RioRvsTab | RioRvsDeviceTab): string {
		switch (tab) {
		case RioRvsTab.ANALOG_INPUT:
			return RioRvsWioIohubDeviceClass.AIN;
		case RioRvsTab.ANALOG_OUTPUT:
			return RioRvsWioIohubDeviceClass.AOUT;
		case RioRvsTab.DIGITAL_INPUT:
			return RioRvsWioIohubDeviceClass.DIN;
		case RioRvsTab.DIGITAL_OUTPUT:
			return RioRvsWioIohubDeviceClass.DOUT;
		default:
			return '';
		}
	}

	showIpAddressInForm(selectedRioRvsItem: IRioRvsWioDevice): boolean {
		const { 'ipres-mask': mask, 'ipres-ip': gatway } = selectedRioRvsItem;

		return mask !== '255.255.255.255' && !this.isZeroedIP(gatway);
	}

	isZeroedIP(ipAddress: string): boolean {
		return /([0]{1,3}\.){3}[0]{1,3}$/.test(ipAddress);
	}

	getRefreshInProgress$(): Observable<boolean> {
		return this.refreshInProgress.asObservable();
	}

	setRefreshInProgress(value: boolean): void {
		this.refreshInProgress.next(value);
	}

	getIsPortsConfiguration(): boolean {
		return this.isPortsConfiguration;
	}

	setIsPortsConfiguration(value: boolean): void {
		this.isPortsConfiguration = value;
	}

	getWioNodeIndexes(wioNodes: IWioNodeModel[]): number[] {
		return wioNodes.reduce((accumulator: number[], wioNode: IWioNodeModel) =>
			wioNode.State === WioSlavesState.DN ? [...accumulator, wioNode.nodeInd] : accumulator, []);
	}

	isItemResource(item: IRioRvsWioDevice): boolean {
		const { mac, state, configured } = item;

		const boundResource = mac !== this.unavailableMac && state.toLowerCase() === 'down' && configured;
		const notBoundResource = mac === this.unavailableMac && state.toLowerCase() === 'down' && configured;

		return notBoundResource || boundResource;
	}

	getUnavailableMac(): string {
		return this.unavailableMac;
	}

	handleSetSelectedDevice(devices: IRioRvsWioDevice[], macIp: string): void {
		const selectedDevice = devices.find(device => device.mac + device.ipaddr === macIp);

		if (!selectedDevice) {
			this.goToDiscoveryTable();
			return;
		}

		this.rioRvsNgrxService.setSelectedDevice(selectedDevice);
	}

	goToDiscoveryTable(): void {
		const route = this.getRoutePath();

		this.router.navigate([{ replaceUrl: true }, { outlets: { [route]: route } }]);
	}

	generateMacIp(selectedItem: IRioRvsWioDevice): string {
		const { mac, ipaddr } = selectedItem;

		return mac + ipaddr;
	}

	goToDeviceDetails(macIp: string, id: string): void {
		const route = this.getRoutePath();
		const outlets = { [route]: `${route}/edit/${macIp}/${id || ''}` };

		this.router.navigate([{ replaceUrl: true }, { outlets }]);
	}

	goToWioSlaveDetails(macIp: string, id: number, nodeIndex: number, nodeType: RioRvsWioIohubDeviceClass): void {
		const route = this.getRoutePath();
		const outlets = { [route]: `${route}/edit-wio-slave/${macIp}/${id}/${nodeIndex}/${nodeType}` };

		this.router.navigate([{ replaceUrl: true }, { outlets }]);
	}

	goToDevicePort(macIp: string, portId: string, wionode: string, resource: string, deviceType: RioRvsDeviceTab): void {
		const route = this.getRoutePath();
		const outlets = { [route]: `${route}/edit-port/${macIp}/${portId}/${resource}/${deviceType}/${wionode || ''}` };

		this.router.navigate([{ replaceUrl: true }, { outlets }]);
	}

	getMatchingLineDevice(id: string, availablePorts: RioRvsModalLineDeviceType[]): RioRvsModalLineDeviceType | null {
		const isWioSlaveNode = RioRvsService.isWioSlaveNode(id) ? 'wioline' : 'lineDeviceType';
		const matchingPort = availablePorts.find(item => item[isWioSlaveNode]?.toString() === id);

		return matchingPort || null;
	}

	setEditDeviceActiveTab(activeTab: RioRvsTab): void {
		this.editDeviceActiveTab = activeTab;
	}

	getEditDeviceActiveTab(): RioRvsTab {
		return this.editDeviceActiveTab || RioRvsTab.DEVICE_SETTINGS;
	}

	updateWioSlaveNodePortsInfo(response: IWioNodeModel[]): IWioNodeModel[] {
		return response.map(wioNode => {
			const [port1, port2, port3, port4] = wioNode.Lines;

			return { ...wioNode, port1, port2, port3, port4 };
		});
	}

	setRioRvsMode(): void {
		const isPortsConfiguration = this.router.url.includes(RoutesEnum.PORTS_CONFIGURATION);

		this.setIsPortsConfiguration(isPortsConfiguration);
	}

	getRoutePath(): string {
		return this.isPortsConfiguration ? RoutesEnum.PORTS_CONFIGURATION : RoutesEnum.RIO_RVS;
	}

	static isWioSlaveNode(id: string): boolean {
		return /^\d+$/.test(id);
	}
}
