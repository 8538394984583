import { AbstractControl } from '@angular/forms';

export function minValueValidator(value: string | number, acceptEmptyValue = false, includeEqual = true) {
	const minValue = Number.parseFloat(value.toString());

	return (control: AbstractControl) => {
		if (acceptEmptyValue && control.value.toString().trim() === '') {
			return null;
		}

		try {
			const controlValue = Number.parseFloat(control.value.toString());

			if (includeEqual) {
				return controlValue >= minValue ? null : { min: true };
			}

			return controlValue > minValue ? null : { min: true };
		} catch {
			return { min: true };
		}
	};
}

export function maxValueValidator(value: string | number, acceptEmptyValue = false, includeEqual = true) {
	const maxValue = Number.parseFloat(value.toString());

	return (control: AbstractControl) => {
		if (acceptEmptyValue && control.value.toString().trim() === '') {
			return null;
		}

		try {
			const controlValue = Number.parseFloat(control.value.toString());

			if (includeEqual) {
				return controlValue <= maxValue ? null : { max: true };
			}

			return controlValue < maxValue ? null : { max: true };
		} catch {
			return { max: true };
		}
	};
}
