import { createSelector } from '@ngrx/store';
import { IAppState } from '../../../root-reducers';
import { UserLevel } from '../ts/enums/user-level.enum';
import { FeaturePermissionsName } from '../../core/ts/enums/feature-permission-name.enum';

export const selectAuthState = (select: IAppState) => select.auth;

export const selectLoggedInState = createSelector(
	selectAuthState,
	auth => auth.isLoggedIn
);

export const selectUsername = createSelector(
	selectAuthState,
	auth => auth?.user?.username || ''
);

export const selectAllowedAutomationMap = createSelector(
	selectAuthState,
	auth => auth?.user?.['io-map'] || '#'
);

export const selectAllowedVsCamera = createSelector(
	selectAuthState,
	auth => auth?.user?.['vs-cam'] || '#'
);

export const selectPermissions = createSelector(
	selectAuthState,
	auth => auth?.permissions || []
);

export const selectVerificationData = createSelector(
	selectAuthState,
	auth => auth?.verification || null
);

export const selectSectionPermissions = createSelector(
	selectAuthState,
	auth => {
		if (!auth?.user) { return {};  };

		return {
			'http-gui-auto': auth.user['http-gui-auto'],
			'http-gui-video': auth.user['http-gui-video'],
			'http-gui-tools': auth.user['http-gui-tools'],
			'http-gui-phone': auth.user['http-gui-phone'],
			'http-gui-network': auth.user['http-gui-network']
		};
	}
);

export const selectUserLevel = createSelector(
	selectAuthState,
	auth => auth?.user?.level || UserLevel.BASIC
);

export const selectLoggedUserGroup = createSelector(
	selectAuthState,
	auth => auth?.user?.['http-gui-group']
);

export const selectMaxAutomationMapsNumber = createSelector(
	selectAuthState,
	auth => auth['auto-max-maps'] || 0
);

export const selectUbrMode = createSelector(
	selectAuthState,
	auth => auth['ctisys-ubr']
);

export const selectAbilisInformation = createSelector(
	selectAuthState,
	auth => {
		const { name, serialNumber, firmware } = auth;

		return { name, serialNumber, firmware };
	}
);

export const selectFromPermissions = (permissionName: FeaturePermissionsName) => createSelector(
	selectPermissions,
	permissions => permissions.find(item => item.name === permissionName) || null
);
