import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormArray } from '@angular/forms';
import { AutomationDeviceShortPipe } from '../../../../pipes/automation-device-short.pipe';
import { GenericTableBuilder } from '../../../networking/common/classes/table-builder.class';
import { IGenericTableRow } from '../../../ui-components/generic-table/ts/models/generic-table-row';
import { IGenericTableHeader } from '../../../ui-components/generic-table/ts/models/generic-table-header';
import { IGenericUnsetSelectedRow } from '../../../ui-components/generic-table/ts/models/generic-unset-selected-row.model';

@Injectable({
	providedIn: 'root'
})
export class WioSlavesTableService extends GenericTableBuilder {

	private readonly portWidthStyle = 'width: 100px; min-width: 100px';

	private errorMessageState: BehaviorSubject<string> = new BehaviorSubject('');
	private unsetSelectedRowState = new BehaviorSubject({ unsetSelectedRow: false });

	constructor(
		private formBuilder: FormBuilder
	) {
		super();
	}

	buildTableHeaders(): IGenericTableHeader[] {
		return [
			{ header: { headerName: 'AUTO.PREVIEW.slave' }, sort: { useSort: false } },
			{ header: { headerName: 'AUTO.PREVIEW.headDevDescription' }, sort: { useSort: false } },
			{ header: { headerName: 'AUTO.PREVIEW.macAddress' }, sort: { useSort: false } },
			{ header: { headerName: 'AUTO.PREVIEW.headState' }, sort: { useSort: false } },
			{ header: { headerName: 'AUTO.PREVIEW.headRssi' }, sort: { useSort: false } },
			{ header: { headerName: 'AUTO.PREVIEW.nodeType' }, sort: { useSort: false } },
			{ header: { headerName: '1' }, sort: { useSort: false } },
			{ header: { headerName: '2' }, sort: { useSort: false } },
			{ header: { headerName: '3' }, sort: { useSort: false } },
			{ header: { headerName: '4' }, sort: { useSort: false } },
			{ header: { headerName: '', isAction: true }, sort: { useSort: false } }
		];
	}

	buildTableRows(): IGenericTableRow[] {
		return [
			{ cell: { cellName: 'displayName', visibleCellCondition: true }, edit: { editMode: false } },
			{ cell: { cellName: 'descr', visibleCellCondition: true }, edit: { editMode: false } },
			{ cell: { cellName: 'hwaddr', visibleCellCondition: true }, edit: { editMode: false } },
			{
				cell: {
					cellName: 'isUp', iconCell: 'isUp', visibleCellCondition: true, upIconTooltip: 'Up', downIconTooltip: 'Down',
					showIconUpState: true, showIconDownState: true, upIconName: 'status-ok.svg', downIconName: 'status-ko.svg',
					centeredImage: true
				}, edit: { editMode: false }
			},
			{ cell: { cellName: 'Rssi', visibleCellCondition: true }, edit: { editMode: false } },
			{
				cell: {
					cellName: 'nodeType', visibleCellCondition: true, pipe: new AutomationDeviceShortPipe()
				}, edit: { editMode: false }
			},
			{
				cell: { cellName: 'port1', visibleCellCondition: true, sentenceCase: true, cellStyle: this.portWidthStyle },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'port2', visibleCellCondition: true, sentenceCase: true, cellStyle: this.portWidthStyle },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'port3', visibleCellCondition: true, sentenceCase: true, cellStyle: this.portWidthStyle },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'port4', visibleCellCondition: true, sentenceCase: true, cellStyle: this.portWidthStyle },
				edit: { editMode: false }
			},
			{
				cell: { cellName: '', visibleCellCondition: true, isAction: true },
				edit: { editMode: false }
			}
		];
	}

	buildForm(): FormArray {
		return this.formBuilder.array([]);
	}

	setUnselectedRowState(rowState: boolean): void {
		this.unsetSelectedRowState.next({ unsetSelectedRow: rowState });
	}

	setResponseErrorMessage(errorMessage: string = ''): void {
		this.errorMessageState.next(errorMessage);
	}

	getErrorMessageState$(): Observable<string> {
		return this.errorMessageState.asObservable();
	}

	getUnsetSelectedRowState$(): Observable<IGenericUnsetSelectedRow> {
		return this.unsetSelectedRowState;
	}
}
