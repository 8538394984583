import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from '../../../../../ts/enums/routes.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthNgrxService } from '../../../../auth/services/auth-ngrx.service';
import { PermissionTabService } from '../../../services/permission-tab.service';
import { SidebarFeatureNames } from '../../../ts/enums/sidebar-feature-names.enum';
import { Component, OnInit, ChangeDetectionStrategy, inject } from '@angular/core';
import { FeaturePermissionsName } from '../../../ts/enums/feature-permission-name.enum';
import { RouterUtilService } from './../../../../../services/utils/router-util.service';
import { FeaturePermissionsService } from '../../../services/feature-permissions.service';
import { IFeaturePermissionsItem } from '../../../ts/models/feature-permissions-item.model';
import { SideBarService } from './../../../../../standalone/components/ui/side-bar/services/side-bar.service';

@UntilDestroy()
@Component({
	selector: 'app-automation-preview',
	templateUrl: './automation-preview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutomationPreviewComponent implements OnInit {

	public readonly router: Router;
	private readonly activatedRoute: ActivatedRoute;

	public logPermission$: Observable<IFeaturePermissionsItem | null>;
	public mapsPermission$: Observable<IFeaturePermissionsItem | null>;
	public sheetsPermission$: Observable<IFeaturePermissionsItem | null>;
	public programsPermission$: Observable<IFeaturePermissionsItem | null>;
	public discoveryPermission$: Observable<IFeaturePermissionsItem | null>;
	public configurationPermission$: Observable<IFeaturePermissionsItem | null>;

	constructor(
		private readonly sideBarService: SideBarService,
		private readonly authNgrxService: AuthNgrxService,
		private readonly routerUtilService: RouterUtilService,
		private readonly permissionTabService: PermissionTabService,
		private readonly featurePermissionsService: FeaturePermissionsService
	) {
		this.router = inject(Router);
		this.activatedRoute = inject(ActivatedRoute);

		this.sideBarService.setActiveSidebarItem(SidebarFeatureNames.AUTOMATION);
	}

	ngOnInit(): void {
		this.logPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.LOGS);
		this.mapsPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.MAPS);
		this.sheetsPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.SHEETS);
		this.programsPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.PROGRAMS);
		this.discoveryPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.DISCOVERY);
		this.configurationPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.PORTS_CONFIGURATION);

		this.watchMainRouteMatch();
		this.setFirstAvailableTab();
	}

	private watchMainRouteMatch(): void {
		this.permissionTabService.watchMainRouteMatch$(RoutesEnum.AUTOMATION)
			.pipe(untilDestroyed(this))
			.subscribe(() => this.routerUtilService.navigateToRoute());
	}

	private setFirstAvailableTab(): void {
		const permissions$ = [
			this.mapsPermission$, this.sheetsPermission$, this.programsPermission$,
			this.logPermission$, this.discoveryPermission$, this.configurationPermission$
		];

		const formatPermissionCallback = this.featurePermissionsService.formatAutoPermissionToRoutes;

		this.permissionTabService.getFirstAvailableTab$(RoutesEnum.AUTOMATION, permissions$, formatPermissionCallback)
			.pipe(untilDestroyed(this))
			.subscribe(featureName => this.onAutomationTabRouting(featureName));
	}

	public onAutomationTabRouting(featureName: RoutesEnum): void {
		if (!this.router.url.includes(featureName)) {
			this.routerUtilService.navigateToRouterOutlet(featureName, this.activatedRoute);
		}
	}

	public get getRoutesEnum(): typeof RoutesEnum {
		return RoutesEnum;
	}
}
