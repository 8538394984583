import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IActuator } from '../../ts/models/automation-program-edit-actuator.model';
import * as automationProgramEditActions from '../automation-program-edit.actions';

export const automationProgramActuatorFeatureKey = 'automationProgramActuator';

export type IActuatorsState = EntityState<IActuator>;

export const adapter: EntityAdapter<IActuator> = createEntityAdapter<IActuator>();

export const initialActuatorsState: IActuatorsState = adapter.getInitialState();

const actuatorsReducer = createReducer(
	initialActuatorsState,
	on(automationProgramEditActions.finishActuatorsLoading, (state, { payload }) => adapter.setAll(payload.actuators, { ...state })),
	on(automationProgramEditActions.removeActuator, (state, { payload }) =>
		adapter.removeOne(payload.actuator.fullDeviceName as string, state))
);

export function ActuatorReducer(state: IActuatorsState | undefined, action: Action) {
	return actuatorsReducer(state, action);
}

export const { selectAll: selectAllActuators } = adapter.getSelectors();


