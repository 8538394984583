import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class AddressBookCsvTranslationService {

	private readonly translateService: TranslateService;

	constructor() {
		this.translateService = inject(TranslateService);
	}

	/**
	* Gets import/export text translation, based on the HTTP response code
	* @param {number} code HTTP response code
	* @param {string} text HTTP response text
	*/
	public getImportExportTranslations(code: number, text: string): string {
		// Missing 10 to 13 code responses because of possible mixtures of text
		switch (code) {
		case 0:
		case 19:
			return this.translateService.instant('ADDRESS-BOOK.CSV.importOk');
		case 1:
			return this.translateService.instant('ADDRESS-BOOK.CSV.licenseRestriction');
		case 2:
			return this.translateService.instant('ADDRESS-BOOK.CSV.serviceNotRunning');
		case 3:
			const userAccessUsername = this.extractParameterFromResponse(text);

			return this.translateService.instant('ADDRESS-BOOK.CSV.userAccess', { username: userAccessUsername });
		case 5:
			return this.translateService.instant('ADDRESS-BOOK.CSV.missingUserParameter');
		case 6:
			const notPresentUsername = this.extractParameterFromResponse(text);

			return this.translateService.instant('ADDRESS-BOOK.CSV.userNotPresent', { username: notPresentUsername });
		case 7:
			return this.translateService.instant('ADDRESS-BOOK.CSV.notFound');
		case 8:
			return this.translateService.instant('ADDRESS-BOOK.CSV.invalidActionParameter');
		case 9:
			return this.translateService.instant('ADDRESS-BOOK.CSV.invalidParamNameParameter');
		case 14:
			return this.translateService.instant('ADDRESS-BOOK.CSV.serviceBusy');
		case 15:
			const notAllowedUsername = this.extractParameterFromResponse(text);

			return this.translateService.instant('ADDRESS-BOOK.CSV.userNotAllowed', { username: notAllowedUsername });
		case 16:
			return this.translateService.instant('ADDRESS-BOOK.CSV.sameName');
		case 17:
			return this.translateService.instant('ADDRESS-BOOK.CSV.contactWithSameNameOverwrite');
		case 18:
			return this.translateService.instant('ADDRESS-BOOK.CSV.contactWithSameNumber');
		case 20:
			const parameter = this.extractParameterFromResponse(text);

			return this.translateService.instant('ADDRESS-BOOK.CSV.missingParameter', { parameter });
		case 21:
			const privateEntries = this.extractNumberOfEntries(text);

			return this.translateService.instant('ADDRESS-BOOK.CSV.privateExceeded', { entries: privateEntries });
		case 22:
			const publicEntries = this.extractNumberOfEntries(text);

			return this.translateService.instant('ADDRESS-BOOK.CSV.publicExceeded', { entries: publicEntries });
		default:
			return text;
		}
	}

	/**
	* Gets all the text from the response
	* @param {string} text HTTP response text
	*/
	private extractParameterFromResponse(text: string): string {
		const regExpResult = text.match(/'.+'/);

		if (!regExpResult) { return ''; }

		const [result] = regExpResult;

		return result;
	}

	/**
	* Gets text from starting parenthesis and first ending space
	* Example: text123(32323223 2332 -> (32323223
	* @param {string} text HTTP response text
	*/
	private extractNumberOfEntries(text: string): string {
		const regExpResult = text.match(/\(.+\s/);

		if (!regExpResult) { return ''; }

		const [result] = regExpResult;

		return result.slice(1);
	}
}
