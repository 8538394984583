import { FormArray } from '@angular/forms';
import { IGenericTableRow } from '../../../ui-components/generic-table/ts/models/generic-table-row';
import { IGenericTableHeader } from '../../../ui-components/generic-table/ts/models/generic-table-header';
import { GenericFormValue } from '../../../../components/ui-components/generic-table/ts/types/generic-form-value.type';

export abstract class GenericTableBuilder {

	constructor() { }

	public abstract buildForm(args?: unknown): FormArray;

	public abstract buildTableRows(args?: unknown): IGenericTableRow[];

	public abstract buildTableHeaders(args?: unknown): IGenericTableHeader[];

	public translateSelectedRowDataToForm?(...args: unknown[]): Array<GenericFormValue | null>;

	protected setErrorMessage(errorMessage: string | undefined, callback: (errorText: string) => void): void {
		callback(errorMessage || '');
	}
}
