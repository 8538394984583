/* eslint-disable max-len */
export const availableFunctionDescriptions = [
	{
		title: 'AUTO.RULES.EDIT.MANUAL.mathematicalOperators',
		values: [
			'AUTO.RULES.EDIT.MANUAL.addition',
			'AUTO.RULES.EDIT.MANUAL.subtraction',
			'AUTO.RULES.EDIT.MANUAL.multiplication',
			'AUTO.RULES.EDIT.MANUAL.division',
			'AUTO.RULES.EDIT.MANUAL.brackets'
		]
	},
	{
		title: 'AUTO.RULES.EDIT.MANUAL.mathematicalFunctions',
		values: [
			'AUTO.RULES.EDIT.MANUAL.absExplanation',
			'AUTO.RULES.EDIT.MANUAL.evenExplanation',
			'AUTO.RULES.EDIT.MANUAL.oddExplanation',
			'AUTO.RULES.EDIT.MANUAL.intExplanation',
			'AUTO.RULES.EDIT.MANUAL.roundExplanation',
			'AUTO.RULES.EDIT.MANUAL.log10Explanation',
			'AUTO.RULES.EDIT.MANUAL.modExplanation',
			'AUTO.RULES.EDIT.MANUAL.randExplanation',
			'AUTO.RULES.EDIT.MANUAL.randbetweenExplanation',
			'AUTO.RULES.EDIT.MANUAL.powExplanation',
			'AUTO.RULES.EDIT.MANUAL.sqrtExplanation'
		]
	},
	{
		title: 'AUTO.RULES.EDIT.MANUAL.logicalFunctions',
		values: [
			'AUTO.RULES.EDIT.MANUAL.notExplanation',
			'AUTO.RULES.EDIT.MANUAL.andExplanation',
			'AUTO.RULES.EDIT.MANUAL.orExplanation',
			'AUTO.RULES.EDIT.MANUAL.xorExplanation'
		]
	},
	{
		title: 'AUTO.RULES.EDIT.MANUAL.conditionalStatements',
		values: [
			'AUTO.RULES.EDIT.MANUAL.ifExplanation',
			'AUTO.RULES.EDIT.MANUAL.ifnaExplanation'
		]
	},
	{
		title: 'AUTO.RULES.EDIT.MANUAL.statisticalFunctions',
		values: [
			'AUTO.RULES.EDIT.MANUAL.averageExplanation',
			'AUTO.RULES.EDIT.MANUAL.maxExplanation',
			'AUTO.RULES.EDIT.MANUAL.minExplanation',
			'AUTO.RULES.EDIT.MANUAL.stdevExplanation'
		]
	},
	{
		title: 'AUTO.RULES.EDIT.MANUAL.dateAndTimeFunctions',
		values: [
			'AUTO.RULES.EDIT.MANUAL.todayExplanation',
			'AUTO.RULES.EDIT.MANUAL.weekdayExplanation',
			'AUTO.RULES.EDIT.MANUAL.timeExplanation',
			'AUTO.RULES.EDIT.MANUAL.hourExplanation',
			'AUTO.RULES.EDIT.MANUAL.minuteExplanation',
			'AUTO.RULES.EDIT.MANUAL.betweenExplanation',
			'AUTO.RULES.EDIT.MANUAL.secondExplanation',
			'AUTO.RULES.EDIT.MANUAL.millisecondExplanation',
			'AUTO.RULES.EDIT.MANUAL.hmsmExplanation',
			'AUTO.RULES.EDIT.MANUAL.nowExplanation',
			'AUTO.RULES.EDIT.MANUAL.datetimeExplanation',
			'AUTO.RULES.EDIT.MANUAL.transExplanation',
			'AUTO.RULES.EDIT.MANUAL.invExplanation',
			'AUTO.RULES.EDIT.MANUAL.alertExplanation',
		]
	}
];

// not allowed operators to be specified by user
export const nonAllowedOperators = [
	'&', '&&', '!', ';', ':', '...', '!==', '&', '=>', '\\',
	'$', '>>', '>>>', '<<<', '<<', '===', '==', '|', '||', '"', '%'
];

// operators used to construct token expression
export const tokenExpressionCharacters = ['+', '-', '*', '/', '(', ')', '<', '<=', '>', '>=', ',', '!=', '='];

// operators to check if expression part is operator
export const availableOperators = ['+', '-', '*', '/', '>', '<', '=', ',', '(', ')', '!=', '>=', '<='];

// operator used to divide previous/next position of operator when setting to token expression
export const delimiterToken = '$';

// operators which are not allowed to be repeated
export const nonAllowedRepeatingOperators = ['*', '+', '-', '/', '<', '>', ',', '=', '==', '!=', '>=', '<=', '|', '&', '"'];

// special value which doesn't check type
export const specialAlertFnValue = 'ALERT-RELEASED';
