import { Action, createReducer, on } from '@ngrx/store';
import * as PreferencesActions from './preferences.actions';

export const preferencesReducerFeatureKey = 'preferences';

export interface IPreferencesState {
	isLoading: boolean;
	rsp?: string;
	haveError?: boolean;
}

export const initialPreferencesState: IPreferencesState = {
	isLoading: true
};

const preferencesReducer = createReducer(
	initialPreferencesState,
	on(PreferencesActions.fetchingPreferencesCompletedAction, (_, { payload }) => ({ ...payload.preferences, isLoading: false })),
	on(PreferencesActions.setLoadingPreferencesStateAction, (state, { payload }) =>
		({ ...state, isLoading: payload.isLoadingPreferences })),
	on(PreferencesActions.clearPreferencesStoreAction, () => ({ isLoading: true }))
);

export function PreferencesReducer(state: IPreferencesState | undefined, action: Action) {
	return preferencesReducer(state, action);
}
