<div class="wrapper">
	<div class="content" *ngIf="abilisInformation$ | async as abilisInformation">
		<div>
			<h2 class="content__abilis">Abilis</h2>
			<h5 class="content__abilis__name">{{abilisInformation?.name | slice:0:100}}</h5>
		</div>

		<div class="footer">
			<h6 class="footer__serial"><b>{{'HOME.serialNumber' | translate}}</b> {{abilisInformation?.serialNumber}}</h6>
			<h6 class="footer__firmware"><b>{{'HOME.firmware' | translate}}</b> {{abilisInformation?.firmware}}</h6>
		</div>
	</div>
</div>

<app-footer></app-footer>
