import { Injectable } from '@angular/core';
import { MainUtilService } from '../../../../services/main-util.service';
import { IActuator } from '../../ts/models/automation-program-edit-actuator.model';
import { tokenExpressionCharacters } from '../../utils/available-functions-operators';
import { IAutomationProgramEditLineDevice } from '../../ts/models/automation-program-edit-line-device.model';
import { AutomationProgramEditFunctonList } from '../../ts/enums/automation-program-edit-function-list.enum';
import { AutomationProgramEditLineDeviceValue } from '../../ts/types/automation-program-edit-line-device-value.type';
import * as availableFunctionsOperators from '../../utils/available-functions-operators';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSpreadsheetUtilService {

	constructor() { }

	protected checkForInvalidComma(tokenExpressions: string[]): boolean {
		return tokenExpressions
			.filter(token => token !== '')
			.some((token: string, index: number, originalArray: string[]) => token === ',' && originalArray[index + 1] === ')');
	}

	protected formatExpressionForEvaluation(expression: string): string {
		return expression
			.replaceAll('=', '==')
			.replaceAll('!==', '!=')
			.replaceAll('>==', '>=')
			.replaceAll('<==', '<=')
			.replaceAll('""', '');
	}

	protected setToTokenExpressions(expression: string): string[] {
		const doubleSignCharacters = ['!=', '>=', '<='];
		const delimiterToken = availableFunctionsOperators.delimiterToken;

		const translatedDoubleChars = doubleSignCharacters
			.reduce((accumulator, character) => accumulator.replaceAll(character, `$${character}$`), expression)
			.split('');

		return translatedDoubleChars
			.reduce((accumulator: string[], character: string, currentIndex: number) => {
				if (character !== '') {
					const nextChar = translatedDoubleChars[currentIndex + 1];
					const previousChar = translatedDoubleChars[currentIndex - 1];

					if (tokenExpressionCharacters.includes(character) && nextChar !== delimiterToken && previousChar !== delimiterToken) {
						return [...accumulator, ' ', character, ' '];
					}

					return [...accumulator, character];
				}

				return accumulator;
			}, [])
			.join('')
			.replaceAll(delimiterToken, ' ')
			.split(' ');
	}

	protected areValidHoursMinutesSeconds(
		functionArguments: AutomationProgramEditLineDeviceValue[], expression: AutomationProgramEditFunctonList
	): string {
		const isSomeUndefined = functionArguments.some(functionArgument => functionArgument === undefined);

		if (!isSomeUndefined) {
			const isTimeFunction = [AutomationProgramEditFunctonList.TIME, AutomationProgramEditFunctonList.BETWEEN].includes(expression);
			const parsedFunctionArguments = functionArguments.map(functionArgument => +functionArgument.toString());
			const [hours, minutes, seconds, milliseconds] = parsedFunctionArguments;
			const millisecondsValid = !isTimeFunction ? milliseconds >= 0 && milliseconds <= 1000 : true;

			if (millisecondsValid) {
				const formattedHours = MainUtilService.prependZero(hours, 10);
				const formattedMinutes = MainUtilService.prependZero(minutes, 10);
				const formattedSeconds = MainUtilService.prependZero(seconds || 0, 10);

				return `1/1/2021 ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
			}
		}

		return '';
	}

	protected isWrongOperatorUsedFromToken(tokenExpressions: string[]): string | undefined {
		return tokenExpressions.find(tokenPart => availableFunctionsOperators.nonAllowedOperators.includes(tokenPart));
	}

	protected isNotNumber(token: string): boolean {
		if (!token) {
			return false;
		}

		try {
			return token
				.split('')
				.map((tokenPart: string) => Number.parseFloat(tokenPart).toString() !== `${NaN}`)
				.some(tokenValid => !tokenValid);
		} catch {
			return false;
		}
	}

	protected isOperator(token: string): boolean {
		return availableFunctionsOperators.availableOperators.includes(token);
	}

	protected getFirstCloseParenthesisIndex(expression: string[], lastOpenParenthesis: number): number {
		let firstCloseParenthesisIndex = lastOpenParenthesis;

		while (expression[firstCloseParenthesisIndex] !== ')' && firstCloseParenthesisIndex <= expression.length) {
			firstCloseParenthesisIndex += 1;
		}

		return firstCloseParenthesisIndex + 1;
	}

	protected getLastOpenParenthesisIndex(expression: string[]): number {
		return expression.lastIndexOf('(');
	}

	protected isCharacterFunction(preParenthesisChar: string): boolean {
		const isNotOperator = !this.isOperator(preParenthesisChar);
		const isNotNumber = this.isNotNumber(preParenthesisChar);

		return isNotOperator && isNotNumber;
	}

	protected getDataBetweenParentheis(expression: string[], lastOpenParenthesis: number, firstCloseParenthesis: number): string {
		return expression
			.slice(lastOpenParenthesis, firstCloseParenthesis)
			.join('');
	}

	protected getUsedActuators(actuators: IActuator[], lineDevices: IAutomationProgramEditLineDevice[]): IActuator[] {
		return actuators.filter(actuator => lineDevices.some(lineDevice => lineDevice.fullDeviceName === actuator.id));
	}
}
