import { Injectable } from '@angular/core';
import { IRecentCall } from '../../ts/models/recent-call-table.model';
import { RecentCallArrayService } from '../recent-call-array.service';
import { MainUtilService } from '../../../../../services/main-util.service';
import { RecentCallDirection } from '../../ts/enums/recent-call-direction.enum';
import { IRecentCallCsvItem } from '../../ts/models/recent-call-csv-item.model';
import { RecentCallCvsItemKey } from '../../ts/enums/recent-call-cvs-item-key.enum';
import { RecentCallCvsHeaderKey } from '../../ts/enums/recent-call-cvs-header-key.enum';

@Injectable({
	providedIn: 'root'
})
export class RecentCallsCsvService {

	constructor() { }

	/**
	* Handles export of Recent Call items in CSV format
	* @param {IRecentCall[]} recentCalls Recent Call items
	* @param {string} username Passed username, in order to form filename which is going to be exported
	*/
	public exportCSV(recentCalls: IRecentCall[], username: string): void {
		if (RecentCallArrayService.areRecentCallsEmpty(recentCalls)) { return; }

		const csvRecentCalls = this.formatRecentCallsForCsv(recentCalls);
		// Get header keys from first item
		const headers = this.getCsvHeaders(csvRecentCalls);
		const csv = this.getCsvData(csvRecentCalls, headers);
		const csvArray = [this.getTitleCaseHeaders(headers).join(','), ...csv].join('\r\n');

		MainUtilService.saveContent(MainUtilService.convertToBlob(csvArray, 'text/csv'), this.getCsvFilename(username));
	}

	/**
	* Formats CSV headers
	* @param {RecentCallCvsHeaderKey[]} headers File headers
		@param {IRecentCallCsvItem} recentCallItem Call direction, whether it's IN or OUT
	*/
	private formatCsvHeaders(headers: RecentCallCvsHeaderKey[], recentCallItem: IRecentCallCsvItem): string[] {
		const { datetime, name, direction } = recentCallItem;

		const emptyValueReplacer = (_: any, value: any) => (value === null ? '' : value);

		return headers.map(header => {
			if (header === RecentCallCvsHeaderKey.DATETIME) {
				const formatedDateTime = MainUtilService.getLuxonDatetime(datetime, 'dd-MM-yyyy hh:mm:ss');

				return formatedDateTime.toString();
			}

			const haveNameDirectionOut = this.haveNameDirectionIsOut(name, direction);

			if (haveNameDirectionOut && header === RecentCallCvsHeaderKey.NAME) {
				return '';
			}

			if (haveNameDirectionOut && header === RecentCallCvsHeaderKey.CALLED_NAME) {
				return name.toString();
			}

			return JSON.stringify(recentCallItem[header as unknown as RecentCallCvsItemKey], emptyValueReplacer);
		});
	}

	/**
	* Determines if Recent Call have name value and direction is OUT
	* @param {string} name Recent Call name
		@param {string} direction Call direction, whether it's IN or OUT
	*/
	private haveNameDirectionIsOut(name: string, direction: string): boolean {
		return !!name && direction === RecentCallDirection.OUT;
	}

	/**
	* Formats CSV filename
	* @param {string} username Passed username, in order to form filename which is going to be exported
	*/
	private getCsvFilename(username: string): string {
		return `${username}-recent-calls.csv`;;
	}

	/**
	 * Formats CSV headers to title case CSV headers
	 * @param {RecentCallCvsHeaderKey[]} headers File headers
	 */
	private getTitleCaseHeaders(headers: RecentCallCvsHeaderKey[]): string[] {
		return headers.map(fieldName => fieldName.charAt(0).toUpperCase() + fieldName.slice(1));
	}

	/**
	 * Gets CSV data from Recent Calls and headers
	 * @param {IRecentCallCsvItem[]} recentCalls Recent Call CVS items
	 * @param {RecentCallCvsHeaderKey[]} headers File headers
	 */
	private getCsvData(recentCalls: IRecentCallCsvItem[], headers: RecentCallCvsHeaderKey[]): string[] {
		return recentCalls.map(recentCallItem => this.formatCsvHeaders(headers, recentCallItem).join(','));
	}

	/**
	 * Gets first Recent Call item object keys
	 * @param {IRecentCallCsvItem[]} recentCalls Recent Call CVS items
	 */
	private getCsvHeaders(recentCalls: IRecentCallCsvItem[]): RecentCallCvsHeaderKey[] {
		const [firstRecentCall] = recentCalls;

		return [...Object.keys(firstRecentCall), RecentCallCvsHeaderKey.CALLED_NAME] as RecentCallCvsHeaderKey[];
	}

	/**
	 * Formats Recent Calls for CSV
	 * @param {IRecentCall[]} recentCalls Recent Call items
	 */
	private formatRecentCallsForCsv(recentCalls: IRecentCall[]): IRecentCallCsvItem[] {
		return recentCalls.map(recentCallItem => {
			const { datetime, direction, duration, result, name, calling, called } = recentCallItem;

			return { datetime, direction, duration, result, name, calling, called };
		});
	}
}
