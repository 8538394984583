import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { VERSION } from '../../../../../version';
import { TranslateService } from '@ngx-translate/core';
import { IVersionModal } from '../../../ts/models/version.model';
import { VersionSemver } from '../../../ts/models/version-semver.model';
import { AvailableLanguage } from '../ts/enums/available-language.enum';

@Injectable({
	providedIn: 'root'
})
export class CoreService {

	private newVersion: string;

	version: {
		raw: string;
		hash: string;
		tag: string;
		suffix: string;
		dirty: boolean;
		version: string;
		distance: number;
		semverString: string;
		semver: VersionSemver;
	};

	constructor(
		private translateService: TranslateService
	) { }

	// HTTP

	getSelectedLanguage$(): Observable<AvailableLanguage> {

		return this.translateService.onLangChange
			.pipe(
				map(value => value.lang)
			) as Observable<AvailableLanguage>;
	}

	// LOGIC

	getCurrentVersion(): IVersionModal {
		this.version = { ...VERSION };

		return this.version;
	}

	getNewVersion(): string {
		return this.newVersion;
	}

	setNewVersion(newVersion: string): void {
		this.newVersion = newVersion;
	}
}
