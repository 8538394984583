import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { PhoneUtilService } from './../phone-util.service';
import { PhoneCallsService } from './../calls/phone-calls.service';
import { PhoneFooterService } from '../footer/phone-footer.service';
import { PhoneNgrxSelectorService } from './phone-ngrx-selector.service';
import { PhoneFooterUtilService } from '../footer/phone-footer-util.service';
import { OpcPanelCallRecordState } from './../../../opc-panel/ts/enums/opc-panel-call-record-state.enum';
import { PreferencesInterfaceAction } from './../../../preferences/ts/enums/preferences-interface-action.enum';

@Injectable({
	providedIn: 'root'
})
export class PhoneNgrxFooterSelectorService {

	constructor(
		private readonly phoneFooterService: PhoneFooterService,
		private readonly phoneFooterUtilService: PhoneFooterUtilService,
		private readonly phoneNgrxSelectorService: PhoneNgrxSelectorService
	) { }

	public isPriorityActive$(): Observable<boolean | null> {
		const userRecord$ = this.phoneNgrxSelectorService.selectUserRecord$();
		const callRecords$ = this.phoneNgrxSelectorService.selectCallRecords$();

		return combineLatest([callRecords$, userRecord$])
			.pipe(
				map(response => !this.phoneFooterService.isPriorityActive(response) ? null : false)
			);
	}

	public isCalltryActive$(): Observable<boolean | null> {
		const callRecords$ = this.phoneNgrxSelectorService.selectCallRecords$();
		const userRecord$ = this.phoneNgrxSelectorService.selectUserRecord$();
		const inputValue$ = this.phoneNgrxSelectorService.selectInputValue$();

		return combineLatest([callRecords$, userRecord$, inputValue$])
			.pipe(
				map(response => {
					const [callRecords, userRecord, inputValue] = response;

					return this.phoneFooterService.isCalltryActive(callRecords, userRecord, inputValue);
				})
			);
	}

	public isRecordingActive$(): Observable<boolean | null> {
		return this.phoneNgrxSelectorService.selectCallRecords$()
			.pipe(
				map(callRecords => this.phoneFooterService.isRecordingActive(callRecords))
			);
	}

	public isSwitchCallActive$(): Observable<boolean | null> {
		return this.phoneNgrxSelectorService.selectCallRecords$()
			.pipe(
				map(callRecords => this.phoneFooterService.isSwitchCallActive(callRecords))
			);
	}

	public isTransferCallActive$(): Observable<boolean | null> {
		return this.phoneNgrxSelectorService.selectCallRecords$()
			.pipe(
				map(callRecords => this.phoneFooterService.isTransferCallActive(callRecords))
			);
	}

	public isConferenceActive$(): Observable<boolean | null> {
		return this.phoneNgrxSelectorService.selectCallRecords$()
			.pipe(
				map(callRecords => this.phoneFooterService.isConferenceActive(callRecords))
			);
	}

	public isPhonePreferenceActive$(action: PreferencesInterfaceAction): Observable<boolean | null> {
		return this.phoneNgrxSelectorService.selectCtipInterface$()
			.pipe(
				map(ctipInterfaceData => PhoneUtilService.isMatchingAction(ctipInterfaceData?.action, action))
			);
	}

	public isHoldActive$(): Observable<boolean | null> {
		const userRecord$ = this.phoneNgrxSelectorService.selectUserRecord$();
		const callRecords$ = this.phoneNgrxSelectorService.selectCallRecords$();

		return combineLatest([callRecords$, userRecord$])
			.pipe(
				map(response => {
					const [callRecords, userRecord] = response;
					const isHoldActive = this.phoneFooterService.isHoldCallActive(callRecords, userRecord);
					const isUnholdActive = this.phoneFooterService.isUnholdCallActive(callRecords, userRecord);

					if (!(isHoldActive || isUnholdActive) || !callRecords.length) { return null; }

					 return PhoneCallsService.everyCallStateMatches(callRecords, OpcPanelCallRecordState.HOLD);
				})
			);
	}

	public selectPriorityMode$(): Observable<boolean> {
		return this.phoneNgrxSelectorService.selectFromPhoneStore$('priorityMode') as Observable<boolean>;
	}

	public isVoicemailActive$(): Observable<boolean | null> {
		return this.phoneNgrxSelectorService.selectCtipInterface$()
			.pipe(
				map(ctipInterfaceData => ctipInterfaceData === null ? null : ctipInterfaceData.vm)
			);
	}

	public getCalltryNumberName$(): Observable<string> {
		return this.phoneNgrxSelectorService.selectUserRecord$()
			.pipe(
				map(userRecord => this.phoneFooterUtilService.getCalltryNumberName(userRecord))
			);
	}

	public isSmsEnabled$(): Observable<boolean> {
		return this.phoneNgrxSelectorService.selectUserRecord$()
			.pipe(
				map(userRecord => userRecord?.SmsEnabled?.smsenabled || false)
			);
	}
}
