import { switchMap } from 'rxjs/operators';
import { lastValueFrom, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { versionFrequency } from '../../utils/global-variables';
import { IVersionRelease } from '../../ts/models/version-release.model';
import { CoreService } from '../../components/core/services/core.service';
import { VersionModalComponent } from '../../components/version-modal/version-modal.component';

@Injectable()
export class CheckVersionService {

	coreService = this.injector.get(CoreService);

	constructor(
		private injector: Injector
	) { }

	initVersionCheck(): Promise<void> {
		if (environment.production) {
			const url = environment.versionCheckURL;
			// Show prompt for new version when window is active
			timer(versionFrequency, versionFrequency)
				.pipe(
					switchMap(() => this.checkVersion(url, false))
				)
				.subscribe();
			// If user entered url, or choosen bookmark, reload without asking
			return this.checkVersion(url, true);
		}

		return new Promise(() => { });
	}


	checkVersion(url: string, reloadNow: boolean): Promise<void> {
		// Timestamp these requests to invalidate caches
		const http = this.injector.get(HttpClient);

		return lastValueFrom(http.get<IVersionRelease>(url + '?t=' + new Date().getTime()))
			.then(response => {
				this.coreService.setNewVersion(response.version);

				// Check if version is changed
				if (!this.wasVersionChanged()) { return; }

				// Check if needed to reload immediately
				if (reloadNow) {
					window.location.reload();
					return;
				}
				// Open modal prompt and set versions to service
				const modalService = this.injector.get(BsModalService);
				this.closeAllActiveModals(modalService);

				modalService.show(VersionModalComponent);
			})
			.catch(error => console.error(error, 'Could not get version'));
	}

	// Checks if version has changed.
	wasVersionChanged(): boolean {
		const currentVersion = this.coreService.getCurrentVersion().version;

		return currentVersion !== this.coreService.getNewVersion();
	}

	closeAllActiveModals(modalService: BsModalService) {
		// @ts-ignore: Accessing private variable as workaround for missing feature
		modalService.loaders.forEach(loader => { loader.instance.hide(); });
	}
}
