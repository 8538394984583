import { Injectable } from '@angular/core';
import { NETWORK_LOG_DOWN, NETWORK_LOG_UP } from '../../util/networking-info-util';
import { INetworkingInfoLogItem } from '../../ts/models/networking-info-log-item.model';
import { INetworkingInfoResourceItem } from '../../ts/models/resource/networking-info-resource-item.model';
import { INetworkingInfoCommonLogData } from '../../ts/models/networking-info-common-log-data.model';

@Injectable({
	providedIn: 'root'
})
export class NetworkingInfoNewsCodeUtilService {

	constructor() { }

	createSystemLogId(systemLogItem: INetworkingInfoLogItem): string {
		const { code, date, time, text } = systemLogItem;

		return `${code}-${date}-${time}-${text}`;
	}

	getInfoFromText(logText: string, resourceRegExp: RegExp): string {
		const matchingResourceResult = logText.toLowerCase().match(resourceRegExp);

		return matchingResourceResult ? matchingResourceResult[0].trim().split(':')[1].trim() : '';
	}

	getValueFromParameter(regExpResult: RegExpMatchArray): string {
		return regExpResult[0]
			.trim()
			.split(':')[1].toLowerCase();
	}

	getSystemLogResourceDescription(resourceId: string, availableResources: INetworkingInfoResourceItem[]): string {
		const matchingResource = availableResources.find(resourceItem => resourceItem.id.toLowerCase() === resourceId);

		return matchingResource ? matchingResource.des : '';
	}

	getCommonResourceInfo(
		systemLogItem: INetworkingInfoLogItem, availableResources: INetworkingInfoResourceItem[], resourceRegExp: RegExp
	): INetworkingInfoCommonLogData {
		const { text } = systemLogItem;
		const statusInformationResult = text.toLowerCase().match(/st:.{2}/);

		const id = this.createSystemLogId(systemLogItem);
		const isUp = this.isResourceUp(statusInformationResult);
		const resource = this.getInfoFromText(text, resourceRegExp);
		const resourceStatus = this.getResourceStatusFromText(text, isUp);
		const resourceDescription = this.getSystemLogResourceDescription(resource.toLowerCase(), availableResources);
		const resourceToPresent = resource.startsWith('(') ? resource.toUpperCase() : `(${resource.toUpperCase()})`;

		return { id, isUp, resource: resourceToPresent, resourceStatus, resourceDescription };
	}

	isResourceUp(statusInformationResult: RegExpMatchArray | null): boolean {
		return statusInformationResult ? !(this.getValueFromParameter(statusInformationResult) === 'dn') : false;
	}

	getResourceStatusFromText(logText: string, isResourceUp: boolean): string {
		const statusInformationResult = logText.toLowerCase().match(/st:.{2}/);

		if (statusInformationResult) {
			return isResourceUp ? `{{NETWORK.NEWS.is}} ${NETWORK_LOG_UP}` : `{{NETWORK.NEWS.is}} ${NETWORK_LOG_DOWN}`;
		}

		return '';
	}

	static getDownloadUploadInfo(text: string): string {
		const speedInfoResult = text.match(/RX\/TX:.+/);

		return speedInfoResult ? speedInfoResult[0].split(':')[1] + 'bps' : 'N/A';
	}

	getMainResourceLinkStatus(text: string, regExp: RegExp): string {
		const mainResourceLinkStatusResult = text.toLowerCase().match(regExp);

		if (mainResourceLinkStatusResult) {
			const mainResourceLinkStatus = this.getValueFromParameter(mainResourceLinkStatusResult);

			switch (mainResourceLinkStatus) {
			case 'ld':
				return `, {{NETWORK.NEWS.linkIs}} ${NETWORK_LOG_DOWN}`;
			case 'lr':
				return ', {{NETWORK.NEWS.linkIsReady}}';
			case 'ln':
				return ', {{NETWORK.NEWS.linkNotConfigured}}';
			case 'ht':
				return ', {{NETWORK.NEWS.linkStopped}}';
			case 'fl':
				return ', {{NETWORK.NEWS.linkNotWorking}}';
			}
		}

		return '';
	}

	getMainResourceState(text: string): string {
		const mainResourceStateResult = text.toLowerCase().match(/st:.+\s/);

		if (mainResourceStateResult) {
			const mainResourceStatus = this.getValueFromParameter(mainResourceStateResult);

			switch (mainResourceStatus) {
			case 'dn':
				return `{{NETWORK.NEWS.is}} ${NETWORK_LOG_DOWN}`;
			case 'up':
				return `{{NETWORK.NEWS.is}} ${NETWORK_LOG_UP}`;
			case 'gu':
				return '{{NETWORK.NEWS.isGoingUp}}';
			case 'gd':
				return '{{NETWORK.NEWS.isGoingDown}}';
			}
		}

		return '';
	}
}
