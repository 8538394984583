export enum AutomationDigitalOutputs {
	DIGITAL_OUTPUTS = 'DigitalOutputs',
	DIGITAL_OUTPUT = 'DigitalOutput',
	DIGITAL_OUTPUT_BULBS = 'DigitalOutputBulbs',
	DIGITAL_OUTPUT_BULB = 'DigitalOutputBulb',
	DIGITAL_OUTPUT_SWITCHES = 'DigitalOutputSwitches',
	DIGITAL_OUTPUT_SWITCH = 'DigitalOutputSwitch',
	DIGITAL_OUTPUT_TOGGLES = 'DigitalOutputToggles',
	DIGITAL_OUTPUT_TOGGLE = 'DigitalOutputToggle',
	DIGITAL_OUTPUT_PULSES = 'DigitalOutputPulses',
	DIGITAL_OUTPUT_PULSE = 'DigitalOutputPulse'
}
