import { Pipe, PipeTransform } from '@angular/core';
import { ISelectData } from '../../ts/models/select-data.model';
import { GlobalService } from '../../services/others/global.service';

@Pipe({
	standalone: true,
	name: 'selectFilter'
})
export class SelectFilterPipe implements PipeTransform {

	constructor() { }

	transform(selectData: ISelectData[] | null, searchTerm: string): ISelectData[] {
		if (!selectData) {
			return [];
		}

		return selectData.filter(item => {
			const translatedValue = GlobalService.translateUIValue(item.uiValue.toString() || '').toString();

			return translatedValue.toLowerCase().includes(searchTerm.toString().toLowerCase());
		});
	}
}
