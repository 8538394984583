import { filter } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, ChangeDetectionStrategy, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';

@UntilDestroy()
@Component({
	selector: 'app-slider',
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		ReactiveFormsModule
	],
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements OnInit, OnChanges {

	@Input() value: boolean;
	@Input() disabled: boolean;
	@Input() leftLabel: string;
	@Input() rightLabel: string;
	@Input() leftLabelIcon: string;
	@Input() rightLabelIcon: string;

	@Output() checkboxValueEvent = new EventEmitter();

	public checkboxFormControl = new FormControl(false);

	constructor() { }

	public ngOnInit(): void {
		this.watchCheckboxFormControl();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('value')) {
			this.handleSetCheckboxValue();
			return;
		 }


		if (changes.hasOwnProperty('disabled')) {
			this.handleDisableCheckbox();
		}
	}

	private handleSetCheckboxValue(): void {
		this.checkboxFormControl.setValue(this.value, { emitEvent: false });
	}

	private handleDisableCheckbox(): void {
		if (this.disabled) {
			this.checkboxFormControl.disable();
			return;
		}

		this.checkboxFormControl.enable();
	}

	public onSetValue(value: boolean): void {
		if (this.disabled || value === this.checkboxFormControl.value) { return; }

		this.checkboxFormControl.setValue(value);
	}

	private watchCheckboxFormControl(): void {
		this.checkboxFormControl.valueChanges
			.pipe(
				untilDestroyed(this),
				filter(() => !this.disabled)
			)
			.subscribe(isChecked => this.checkboxValueEvent.emit(isChecked));
	}
}
