export enum RioRvsIohubDeviceType {
	MFIO = 'mfio',
	GPIO = 'gpio',
	RIO = 'rio',
	RVS = 'rvs',
	WIO = 'wio',

	VOUT = 'vout',
	NONE = 'none'
}
