import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISearchNavigationResponse } from './../../../../../components/core/ts/models/search-navigation-response.model';

@Injectable({
	providedIn: 'root'
})
export class SideBarSearchHttpService {

	constructor(
		private readonly http: HttpClient
	) { }

	public fetchSearchNavigationPages$(): Observable<ISearchNavigationResponse> {
		return this.http.get<ISearchNavigationResponse>(`assets/pages/pages.json?t=${new Date().getTime()}`);
	}
}
