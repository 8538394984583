import { Observable, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../../../root-reducers';
import { IActuator } from '../../ts/models/automation-program-edit-actuator.model';
import { IAutomationDevice } from '../../../automation/ts/models/automation-device.model';
import { IProjectOptions } from '../../ts/models/automation-program-edit-project-options.model';
import { AutomationDeviceType } from '../../../automation/ts/enums/automation-device-type.enum';
import { IAutomationProgramEditLineDevice } from '../../ts/models/automation-program-edit-line-device.model';
import { IAutomationProgramVoutDescription } from '../../ts/models/automation-program-vout-description.model';
import { IAutomationProgramEditProjectJsonData } from '../../ts/models/automation-program-edit-json-data.model';
import { INewAutomationProgramEditInputValue } from '../../ts/models/automation-program-edit-new-input-value.model';
import { AutomationProgramSpreadsheetTableUIService } from '../spreadsheet/automation-program-spreadsheet-table-ui.service';
import * as automationProgramSelectors from '../../ngrx/automation-program-edit.selectors';
import * as automationProgramActions from '../../ngrx/automation-program-edit.actions';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSpreadsheetNgrxService {

	constructor(
		private store: Store<IAppState>,
		private automationSpreadsheetTableUIService: AutomationProgramSpreadsheetTableUIService
	) { }

	isSpreadsheetLoading$(): Observable<boolean> {
		return this.store.pipe(select(automationProgramSelectors.selectSpreadsheetLoadingState()));
	}

	selectLineDevicesByType$(deviceType: AutomationDeviceType): Observable<IAutomationProgramEditLineDevice[]> {
		return this.store.pipe(select(automationProgramSelectors.selectLineDevicesByType(deviceType)));
	}

	selectActuators$(): Observable<IActuator[]> {
		return this.store.pipe(select(automationProgramSelectors.selectActuators()));
	}

	selectAllSpreadsheetLineDevices$(): Observable<IAutomationProgramEditLineDevice[]> {
		return this.store.pipe(select(automationProgramSelectors.selectAllSpreadsheetLineDevices()));
	}

	selectProjectOptions$(): Observable<IProjectOptions> {
		return this.store.pipe(select(automationProgramSelectors.selectProjectOptions()));
	}

	getProjectData$(): Observable<IAutomationProgramEditProjectJsonData> {
		return zip(
			this.selectActuators$(),
			this.selectProjectOptions$(),
			this.selectCustomInputValues$(),
			this.selectAllSpreadsheetLineDevices$(),
			this.selectVoutTemporaryDescriptions$()
		)
			.pipe(
				take(1),
				map(response => {
					const [actuators, projectOptions, customInputValues, lineDevices, voutDescriptions] = response;

					return { actuators, projectOptions, customInputValues, lineDevices, voutDescriptions };
				})
			);
	}

	selectCustomInputValues$(): Observable<INewAutomationProgramEditInputValue[]> {
		return this.store.pipe(select(automationProgramSelectors.selectCustomInputValues()));
	}

	selectModalDevices$(): Observable<IAutomationDevice[]> {
		return this.store.pipe(select(automationProgramSelectors.selectModalAutomationProgramDevices()));
	}

	areModalDevicesLoading$(): Observable<boolean> {
		return this.store.pipe(select(automationProgramSelectors.areModalAutomationProgramDevicesLoading()));
	}

	selectAllVouts$(): Observable<IAutomationProgramEditLineDevice[]> {
		return this.store.pipe(select(automationProgramSelectors.selectAllVouts()));
	}

	setProjectLineDevices(lineDevices: IAutomationProgramEditLineDevice[], scriptName?: string): void {
		const payload = { lineDevices, scriptName };

		this.store.dispatch(automationProgramActions.setProjectLineDevices({ payload }));
	}

	setActuators(actuators: IActuator[]): void {
		const payload = { actuators };

		this.store.dispatch(automationProgramActions.finishActuatorsLoading({ payload }));
	}

	loadLineDevices(): void {
		this.store.dispatch(automationProgramActions.loadLineDevices());
	}

	saveProject(initialProgramName: string, newProgramName: string): void {
		const payload = { initialProgramName, newProgramName };

		this.store.dispatch(automationProgramActions.saveProject({ payload }));
	}

	fetchModalLineDevices(successCallback?: () => void): void {
		const payload = { successCallback };

		this.store.dispatch(automationProgramActions.fetchModalLineDevices({ payload }));
	}

	finishLoadingModalDevices(modalDevices: IAutomationDevice[]): void {
		const payload = { modalDevices };

		this.store.dispatch(automationProgramActions.finishLoadingModalDevices({ payload }));
	}

	addNewInputValue(inputDevice: IAutomationProgramEditLineDevice, value: string): void {
		const newInputValue = this.automationSpreadsheetTableUIService.translateInputTableCustomValue(value);
		const id = (inputDevice.fullDeviceName as string);
		const payload = { newInput: { id, newInputValue } };

		this.store.dispatch(automationProgramActions.addNewInputValue({ payload }));
	}

	addNewVout(): void {
		this.store.dispatch(automationProgramActions.addNewVout());
	}

	deleteVout(vout: IAutomationProgramEditLineDevice): void {
		const payload = { vout };

		this.store.dispatch(automationProgramActions.deleteVout({ payload }));
	}

	removeActuator(actuator: IAutomationProgramEditLineDevice): void {
		const payload = { actuator };

		this.store.dispatch(automationProgramActions.removeActuator({ payload }));
	}

	addActuator(id: string, functionExpression: string): void {
		const payload = { actuator: { id, functionExpression } };

		this.store.dispatch(automationProgramActions.addActuator({ payload }));
	}

	updateDevicePositionOnDragDrop(currentIndex: number, previousIndex: number): void {
		const payload = { currentIndex, previousIndex };

		this.store.dispatch(automationProgramActions.updateDevicePositionOnDragDrop({ payload }));
	}

	setDeviceDescription(device: IAutomationProgramEditLineDevice, description: string): void {
		const payload = { device, description };

		this.store.dispatch(automationProgramActions.setDeviceData({ payload }));
	}

	removeAllVoutDescriptions(): void {
		this.store.dispatch(automationProgramActions.removeAllDescriptions());
	}

	fillAutomationProgram(programName: string): void {
		const payload = { programName };

		this.store.dispatch(automationProgramActions.fillAutomationProgram({ payload }));
	}

	selectVoutTemporaryDescriptions$(): Observable<IAutomationProgramVoutDescription[]> {
		return this.store.pipe(select(automationProgramSelectors.selectVoutTemporaryDescriptions()));
	}
}
