import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IAutomationProgramEditLineDevice } from '../../ts/models/automation-program-edit-line-device.model';
import * as automationProgramEditActions from '../automation-program-edit.actions';

export const automationProgramSpreadsheetFeatureKey = 'automationProgramSpreadsheet';

export interface IAutomationProgramProjectState extends EntityState<IAutomationProgramEditLineDevice> {
	isLoading: boolean;
	programName: string;
}

export const adapter: EntityAdapter<IAutomationProgramEditLineDevice> = createEntityAdapter<IAutomationProgramEditLineDevice>({
	selectId: (automationProgramLineDevice: IAutomationProgramEditLineDevice) => automationProgramLineDevice.fullDeviceName as string
});

export const initialAutomationProgramProjectState: IAutomationProgramProjectState = adapter.getInitialState({
	isLoading: true,
	programName: ''
});

const automationProgramSpreadsheetReducer = createReducer(
	initialAutomationProgramProjectState,
	on(automationProgramEditActions.setProjectLineDevices, (state, { payload }) => {
		const { lineDevices, scriptName } = payload;
		const programName = scriptName === '' ? scriptName : scriptName || state.programName;

		return adapter.setAll(lineDevices, { ...state, programName, isLoading: false });
	}),
	on(automationProgramEditActions.updateProjectLineDevices, (state, { payload }) => adapter.updateMany(
		payload.lineDevices.map(lineDevice => Object.assign({ id: lineDevice.fullDeviceName, changes: lineDevice })),
		{ ...state }
	))
);

export function AutomationProgramProjectReducer(state: IAutomationProgramProjectState | undefined, action: Action) {
	return automationProgramSpreadsheetReducer(state, action);
}

export const { selectAll: selectAllSpreadsheetLineDevices } = adapter.getSelectors();
