import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrType } from './../../../../../ts/enums/toastr-type.enum';
import { MainUtilService } from './../../../../../services/main-util.service';
import { AddressBookNgrxActionService } from '../ngrx/address-book-ngrx-action.service';
import { AddressBookCsvTranslationService } from './address-book-csv-translation.service';
import { IAddressBookOperationResponse } from './../../ts/models/address-book-operation-response.model';

@Injectable({
	providedIn: 'root'
})
export class AddressBookCsvUploadService {

	private file: Event | null;
	private filePath: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	constructor(
		private readonly mainUtilService: MainUtilService,
		private readonly addressBookNgrxService: AddressBookNgrxActionService,
		private readonly addressBookCsvTranslationService: AddressBookCsvTranslationService
	) { }

	/**
	 * Triggers when HTTP response of uploading CSV is done
	 * 1. Set empty file name to UI (example: instead of daniel_addrbook.csv, filename would be "")
	 * 2. Pass arguments to function "handleUploadAddressBook":
	 * - code - HTTP response code number
	 * - translatedText - Text to be shown in the toastr
	 * - username - Username in order to fetch Address Book table
	 * @param {IAddressBookOperationResponse} response Upload action HTTP response
	 * @param {string} username Passed username, so that on success, Address Book table would be loaded
	 */
	public handleUploadCsvAction(response: IAddressBookOperationResponse, username: string): void {
		this.setFilePath('');

		const { code, rsp } = response.Response;
		const translatedText = this.addressBookCsvTranslationService.getImportExportTranslations(code, rsp);

		this.handleUploadAddressBook(code, translatedText, username);
	}

	/**
	 * Handles upload CSV of Address Book
	 * Function arguments are explained in the function "handleUploadCsvAction" above
	 * 1. If code is different than 19 (successfull code), present error toastr message
	 * 2. Else everything was fine and Address book table is loaded + successfull toastr will be presented
	 * @param {number} code
	 * @param {string} textToShow
	 * @param {string} username
	 */
	private handleUploadAddressBook(code: number, textToShow: string, username: string): void {
		if (code !== 19) {
			this.mainUtilService.showToastrMessage(textToShow, ToastrType.ERROR);
			return;
		}

		this.addressBookNgrxService.fetchAddressBook(username);
		this.mainUtilService.showToastrMessage(textToShow, ToastrType.SUCCESS);
	}

	/**
	 * Sets file and file path on file change event, or manually to null
	 * @param {Event | null} file
	 */
	public setCsvPath(file: Event | null): void {
		this.file = file;

		this.setFilePath(this.formatFilePath(file));
	}

	/**
	 * Retrieves the file
	 */
	public getFile(): Event | null {
		return this.file;
	}

	/**
	 * Retrieves the file path
	 */
	public getFilePath(): string | null {
		return this.filePath.getValue();
	}

	/**
	 * Retrieves the file path as Observable
	 */
	public getfilePath$(): Observable<string | null> {
		return this.filePath.asObservable();
	}

	/**
	 * Gets file path, based on file
	 * @param {Event | null} file
	 */
	private formatFilePath(file: Event | null): string | null {
		return file ? (file.target as HTMLInputElement).value.split('\\').pop() as string : null;
	}

	/**
	 * Sets the file path
	 * @param {string | null} filePath
	 */
	private setFilePath(filePath: string | null): void {
		this.filePath.next(filePath);
	}
}
