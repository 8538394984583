import { Action, createReducer, on } from '@ngrx/store';
import { INetworkingFtp } from '../ts/models/networking-ftp.model';
import * as ftpActions from './ftp.actions';

export const ftpKey = 'ftp';

export interface IFtpState {
	isLoading: boolean;
	ftpConfiguration: INetworkingFtp | undefined;
}

export const initialFtpState: IFtpState = {
	isLoading: true,
	ftpConfiguration: undefined
};

const ftpReducer = createReducer(
	initialFtpState,
	on(ftpActions.setFtpLoaderState, (state, { payload }) => ({ ...state, isLoading: payload.isLoading })),
	on(ftpActions.finishLoadingFtpConfiguration, (_, { payload }) => ({ ...payload, isLoading: false }))
);

export function FtpReducer(state: IFtpState | undefined, action: Action) {
	return ftpReducer(state, action);
}
