import { IRecentCallState } from './recent-call.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RecentCallStoreKey } from '../ts/enums/recent-call-store-key.enum';
import * as RecentCallState from './recent-call.reducer';

export const selectRecentCallState = createFeatureSelector<IRecentCallState>('recentCall');

/**
	* Selects all Recent Call items from ngrx store
	*/
export const selectAllRecentCalls = () => createSelector(
	selectRecentCallState,
	RecentCallState.selectAllRecentCalls
);

/**
	* Gets value from ngrx store
	* @param {AddressBookStoreKey} key Key to access ngrx store value
	*/
export const selectFromRecentCallsStore = (key: RecentCallStoreKey) => createSelector(
	selectRecentCallState,
	recentCallsState => recentCallsState[key]
);
