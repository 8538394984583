import { Action, createReducer, on } from '@ngrx/store';
import { RECENT_CALLS_ID } from '../utils/recent-calls.util';
import { IRecentCall } from '../ts/models/recent-call-table.model';
import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import * as recentCallActions from './recent-call.action';

export const recentCallFeatureKey = 'recentCall';

export interface IRecentCallState extends EntityState<IRecentCall> {
	isLoading: boolean;
	callenabledCtip: boolean;
	recentCallDebugInfo: string | null;
}

export const adapter: EntityAdapter<IRecentCall> = createEntityAdapter<IRecentCall>({
	selectId: recentCallItem => recentCallItem[RECENT_CALLS_ID]
});

export const initalRecentCallState: IRecentCallState = adapter.getInitialState({
	isLoading: true,
	callenabledCtip: false,
	recentCallDebugInfo: null
});

const recentCallReducer = createReducer(
	initalRecentCallState,
	on(recentCallActions.setIsLoading, (state, { payload }) => ({ ...state, isLoading: payload.isLoading })),
	on(recentCallActions.setCallEnabledCtip, (state, { payload }) => ({ ...state, callenabledCtip: payload.callenabledCtip })),
	on(recentCallActions.setUncheckAllRecentCalls, (state, { payload }) => adapter.updateMany(payload.recentCalls.map(item =>
		Object.assign({}, { id: item[RECENT_CALLS_ID] as string, changes: { ...item, checkboxState: false } })), { ...state })),
	on(recentCallActions.finishLoadingRecentCalls, (state, { payload }) =>
		adapter.setAll(payload.recentCalls, { ...state, isLoading: false })),
	on(recentCallActions.updateCheckedCallState, (state, { payload }) =>
		adapter.updateOne({ id: payload.contact[RECENT_CALLS_ID] as string, changes: payload.contact }, state)),
	on(recentCallActions.setRecentCallDebugInfo, (state, { payload }) => ({ ...state, recentCallDebugInfo: payload.recentCallDebugInfo }))
);

export function RecentCallReducer(state: IRecentCallState | undefined, action: Action) {
	return recentCallReducer(state, action);
}

export const { selectAll: selectAllRecentCalls } = adapter.getSelectors();
