import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from '../../../../../ts/enums/routes.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthNgrxService } from '../../../../auth/services/auth-ngrx.service';
import { PermissionTabService } from '../../../services/permission-tab.service';
import { SidebarFeatureNames } from '../../../ts/enums/sidebar-feature-names.enum';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterUtilService } from './../../../../../services/utils/router-util.service';
import { FeaturePermissionsName } from '../../../ts/enums/feature-permission-name.enum';
import { FeaturePermissionsService } from '../../../services/feature-permissions.service';
import { IFeaturePermissionsItem } from '../../../ts/models/feature-permissions-item.model';
import { SideBarService } from './../../../../../standalone/components/ui/side-bar/services/side-bar.service';

@UntilDestroy()
@Component({
	selector: 'app-tools-preview',
	templateUrl: './tools-preview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolsPreviewComponent implements OnInit {

	public readonly router: Router;
	private readonly activatedRoute: ActivatedRoute;

	public haveAdminPermission$: Observable<boolean>;

	public cpPermission$: Observable<IFeaturePermissionsItem | null>;
	public smsPermission$: Observable<IFeaturePermissionsItem | null>;
	public disaPermission$: Observable<IFeaturePermissionsItem | null>;
	public listPermission$: Observable<IFeaturePermissionsItem | null>;
	public audioPermission$: Observable<IFeaturePermissionsItem | null>;
	public alertPermission$: Observable<IFeaturePermissionsItem | null>;

	constructor(
		private readonly sideBarService: SideBarService,
		private readonly authNgrxService: AuthNgrxService,
		private readonly routerUtilService: RouterUtilService,
		private readonly permissionTabService: PermissionTabService,
		private readonly featurePermissionsService: FeaturePermissionsService
	) {
		this.router = inject(Router);
		this.activatedRoute = inject(ActivatedRoute);

		this.sideBarService.setActiveSidebarItem(SidebarFeatureNames.TOOLS);
	}

	ngOnInit(): void {
		this.haveAdminPermission$ = this.authNgrxService.haveAdminUserLevel$();

		this.smsPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.SMS);
		this.disaPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.DISA);
		this.audioPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.AUDIO);
		this.listPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.LISTS);
		this.alertPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.ALERT);
		this.cpPermission$ = this.authNgrxService.selectUserPermission$(FeaturePermissionsName.CONTROL_PORT);

		this.watchMainRouteMatch();
		this.setFirstAvailableTab();
	}

	private setFirstAvailableTab(): void {
		const formatPermissionCallback = this.featurePermissionsService.formatToolsPermissionToRoutes;
		const permissions$ = [this.listPermission$, this.audioPermission$, this.alertPermission$, this.smsPermission$, this.cpPermission$];

		this.permissionTabService.getFirstAvailableTab$(RoutesEnum.TOOLS, permissions$, formatPermissionCallback)
			.pipe(untilDestroyed(this))
			.subscribe(featureName => this.onToolsRouting(featureName));
	}

	private watchMainRouteMatch(): void {
		this.permissionTabService.watchMainRouteMatch$(RoutesEnum.TOOLS)
			.pipe(untilDestroyed(this))
			.subscribe(() => this.routerUtilService.navigateToRoute());
	}

	public onToolsRouting(featureName: RoutesEnum): void {
		this.routerUtilService.navigateToRouterOutlet(featureName, this.activatedRoute);
	}

	public get getRoutesEnum(): typeof RoutesEnum {
		return RoutesEnum;
	}
}
