import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { IRecentCall } from '../../ts/models/recent-call-table.model';
import { EmptyObject } from '../../../../../ts/types/empty-object.type';
import { RecentCallResult } from '../../ts/enums/recent-call-result.enum';
import { RecentCallTableKey } from '../../ts/enums/recent-call-table-key.enum';
import { RecentCallTableHeaderFilterService } from './recent-call-table-header-filter.service';
import { GenericTableBuilder } from '../../../../networking/common/classes/table-builder.class';
import { IGenericTableRow } from '../../../../ui-components/generic-table/ts/models/generic-table-row';
import { GenericTableEdit } from '../../../../ui-components/generic-table/ts/enums/generic-table-edit.enum';
import { IGenericTableHeader } from '../../../../ui-components/generic-table/ts/models/generic-table-header';
import { GenericTableFilter } from '../../../../ui-components/generic-table/ts/enums/generic-table-filter.enum';
import { IGenericUnsetSelectedRow } from '../../../../ui-components/generic-table/ts/models/generic-unset-selected-row.model';

@Injectable({
	providedIn: 'root',
})
export class RecentCallTableService extends GenericTableBuilder {

	private readonly formBuilder: FormBuilder;

	private readonly debugLogInfoEvent: Subject<IRecentCall> = new Subject();
	private readonly errorMessageState: BehaviorSubject<string> = new BehaviorSubject('');
	private readonly unsetSelectedRowState = new BehaviorSubject({ unsetSelectedRow: false });

	constructor(
		private recentCallTableHeaderFilterService: RecentCallTableHeaderFilterService
	) {
		super();

		this.formBuilder = inject(FormBuilder);
	}

	/**
	 * Build table headers, with appropriate filters, sort functionalities etc...
	 * @param {boolean} isAllMode If Recent call is in the All Users mode
	 */
	public buildTableHeaders(isAllMode: boolean): IGenericTableHeader[] {
		const nameControlIndex = this.calculateHeaderFormArrayIndex(isAllMode, 1);
		const resultControlIndex = this.calculateHeaderFormArrayIndex(isAllMode, 3);
		const directionControlIndex = this.calculateHeaderFormArrayIndex(isAllMode, 2);
		const userHeader = isAllMode ? this.recentCallTableHeaderFilterService.getUserHeader : null;

		return [
			{ header: { headerName: '' }, sort: { useSort: false } },
			{
				header: { headerName: 'PHONE.RECENTCALLS.tblhdrDate' },
				sort: { useSort: true, sortHeader: 'datetimeMs' },
				filter: {
					useFilter: true, filterComponent: GenericTableFilter.INPUT,
					input: { inputRangeTitle: '', fieldWidth: 160, formArrayIndex: 0 }
				}
			},
			userHeader,
			{
				header: { headerName: 'PHONE.RECENTCALLS.tblhdrCorr' },
				sort: { useSort: false, sortHeader: 'correspondent' },
				filter: {
					useFilter: true, filterComponent: GenericTableFilter.INPUT,
					input: { inputRangeTitle: '', fieldWidth: 160, formArrayIndex: nameControlIndex }
				}
			},
			{
				header: { headerName: 'PHONE.RECENTCALLS.tblhdrCDir' },
				sort: { useSort: false, sortHeader: 'directionUI' },
				filter: {
					useFilter: true, filterComponent: GenericTableFilter.SELECT,
					select: {
						data: [], fieldWidth: 65, useTableData: true,
						tableDataKey: 'directionUI', formArrayIndex: directionControlIndex
					}
				}
			},
			{
				header: { headerName: 'PHONE.RECENTCALLS.tblhdrCRes' },
				sort: { useSort: true, sortHeader: 'resultUI' },
				filter: {
					useFilter: true, filterComponent: GenericTableFilter.SELECT,
					select: {
						data: [], fieldWidth: 120, useTableData: true,
						tableDataKey: 'resultUI', formArrayIndex: resultControlIndex
					}
				}
			},
			{
				header: { headerName: 'PHONE.RECENTCALLS.tblhdrDur' },
				sort: { useSort: true, sortHeader: 'duration', numberedSortColumn: true }
			},
		].filter(tableHeader => tableHeader !== null) as IGenericTableHeader[];
	}

	/**
 * Build table rows, with appropriate keys from JSON object
 * @param {object} data Object which contains:
 * 1. isAllMode: If Recent call is in the All Users mode
 * 2. haveAdminUserLevel If logged user have Admin HTTP-LEVEL
 */
	public buildTableRows(data: { isAllMode: boolean; haveAdminUserLevel: boolean }): IGenericTableRow[] {
		const { isAllMode, haveAdminUserLevel } = data;
		const checkboxState = RecentCallTableKey.CHECKBOX_STATE;
		const userRow = isAllMode ? this.recentCallTableHeaderFilterService.getUserRow : null;

		return [
			{
				cell: { cellName: checkboxState, visibleCellCondition: true },
				edit: {
					editComponent: GenericTableEdit.CHECKBOX, editMode: false,
					editVisibleCondition: true, immediatelyVisible: true, immediatelyVisibleValueKey: checkboxState
				},
			},
			{
				cell: { cellName: RecentCallTableKey.DATETIME_UI, visibleCellCondition: true, translateValue: true },
				edit: { editMode: false },
			},
			userRow,
			{
				cell: { cellName: RecentCallTableKey.CORRESPONDENT, visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: RecentCallTableKey.RESULT_ICON, visibleCellCondition: true, isImage: true, customImageHeight: '16px' },
				edit: { editMode: false }
			},
			{
				cell: {
					cellName: RecentCallTableKey.RESULT_UI, visibleCellCondition: true,
					conditonalStyleCondition: ['result---EQUAL---Unreachable'],
					conditionalTrueStyle: ['text-decoration: underline'],
					conditionalFalseStyle: ['text-decoration: none'],
					...this.getClickProperties(haveAdminUserLevel)

				},
				edit: { editMode: false }
			},
			{
				cell: { cellName: RecentCallTableKey.DURATION_UI, visibleCellCondition: true, translateValue: true },
				edit: { editMode: false }
			}
		].filter(tableRow => tableRow !== null) as IGenericTableRow[];
	}

	private getClickProperties(haveAdminPermission: boolean): EmptyObject | {
		cellClick: (selectedRowItem: IRecentCall) => void;
		allowPropagation: (selectedRowItem: IRecentCall) => boolean;
	} {
		if (!haveAdminPermission) { return {}; }

		return {
			cellClick: (recentCallItem: IRecentCall) => this.handleUnreachableResultClick(recentCallItem),
			allowPropagation: (recentCallItem: IRecentCall) => recentCallItem.result === RecentCallResult.UNEREACHABLE
		};
	}

	/**
	 * Build generic form array, so that when user clicks on table row, form with fields for editing appears
	 */
	public buildForm(): FormArray {
		return this.formBuilder.array([]);
	}

	/**
	 * Sets response error from fetch HTTP response of Recent Calls
	 * @param {string} errorMessage Error message to be displayed in the Recent Calls table
	 */
	public setResponseErrorMessage(errorMessage: string = ''): void {
		this.errorMessageState.next(errorMessage);
	}

	/**
	 * Gets response error from fetch HTTP response of Recent Calls
	 */
	public getErrorMessageState$(): Observable<string> {
		return this.errorMessageState.asObservable();
	}

	/**
	 * Builds form for table header filters
	 * @param {boolean} isAllUserCalls If Recent call is in the All Users mode
	 */
	public buildHeaderForm(isAllUserCalls: boolean): FormArray {
		return this.formBuilder.array(this.getHeaderFields(isAllUserCalls));
	}

	/**
	 * Sets unsetSelectedRow value for the table. If true, any selected row in the table will be unselected
	 * @param {boolean} unsetSelectedRow If selected row in the table should be unselected
	 */
	public setUnselectedRowState(unsetSelectedRow: boolean): void {
		this.unsetSelectedRowState.next({ unsetSelectedRow });
	}

	/**
	 * Gets Observable, if selected row should be kept or not
	 */
	public getUnsetSelectedRowState$(): Observable<IGenericUnsetSelectedRow> {
		return this.unsetSelectedRowState.asObservable();
	}

	/**
	 * Gets debug log info event as observable
	 */
	public watchDebugLogInfoEvent$(): Observable<IRecentCall> {
		return this.debugLogInfoEvent.asObservable();
	}

	/**
	 * Emits event to open modal to show Recent Call debug info
	 */
	private handleUnreachableResultClick(recentCallItem: IRecentCall): void {
		this.debugLogInfoEvent.next(recentCallItem);
	}

	/**
	 * Gets appropriate table header field controls
	 * @param {boolean} isAllMode If Recent call is in the All Users mode
	 * @param {number} index Form Control Index
	 */
	private calculateHeaderFormArrayIndex(isAllMode: boolean, index: number): number {
		return isAllMode ? index + 1 : index;
	}

	/**
	 * Gets appropriate table header field controls
	 * @param {boolean} isAllUserCalls Returns different subset of controls, if Recent call is in the All Users mode
	 */
	private getHeaderFields(isAllUserCalls: boolean): FormControl[] {
		if (!isAllUserCalls) {
			// 0 - Datetime // 1 - Correspondent
			// 2 - Direction // 3 - Call result

			return [
				this.formBuilder.control(''), this.formBuilder.control(''),
				this.formBuilder.control('All'), this.formBuilder.control('All')
			];
		}

		// 0 - Datetime // 1 - User
		// 2 - Correspondent // 3 - Direction
		// 4 - Call result

		return [
			this.formBuilder.control(''), this.formBuilder.control('All'),
			this.formBuilder.control(''), this.formBuilder.control('All'),
			this.formBuilder.control('All')
		];
	}
}
