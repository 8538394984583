import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericTableComponent } from '../generic-table.component';
import { GenericTablePipePipe } from '../pipes/generic-table-pipe.pipe';
import { GenericIconStatePipe } from '../pipes/generic-icon-state.pipe';
import { GenericTableSortPipe } from '../pipes/generic-table-sort.pipe';
import { FormlyFormModule } from '../../../../shared/formly-form.module';
import { GenericTableSlicePipe } from '../pipes/generic-table-slice.pipe';
import { GenericTableHeightPipe } from '../pipes/generic-table-height.pipe';
import { GenericTableFilterPipe } from '../pipes/generic-table-filter.pipe';
import { VisibleCellDirective } from '../directives/visible-cell.directive';
import { NestedTranslationPipe } from '../../../../standalone/pipes/nested-translation.pipe';
import { InputEditComponent } from '../components/input-edit/input-edit.component';
import { GenericRowKeyResolverPipe } from '../pipes/generic-row-key-resolver.pipe';
import { UiComponentsFeatureModule } from '../../../../shared/ui-components.module';
import { EditVisibleCellDirective } from '../directives/edit-visible-cell.directive';
import { AngularBootstrapModule } from '../../../../shared/angular-bootstrap.module';
import { SelectEditComponent } from '../components/select-edit/select-edit.component';
import { GenericTableSentenceCasePipe } from '../pipes/generic-table-sentence-case.pipe';
import { GenericTableImageTooltipPipe } from '../pipes/generic-table-image-tooltip.pipe';
import { InputFilterComponent } from '../components/input-filter/input-filter.component';
import { GenericDirectiveConditionPipe } from '../pipes/generic-directive-condition.pipe';
import { SharedPipeDirectiveModule } from '../../../../shared/shared-pipe-directive.module';
import { SelectFilterComponent } from '../components/select-filter/select-filter.component';
import { ConditionalRowStyleDirective } from '../directives/conditional-row-style.directive';
import { ConditionalCellStyleDirective } from '../directives/conditional-cell-style.directive';
import { GenericSortDescendingResolverPipe } from '../pipes/generic-sort-descending-resolver.pipe';
import { GenericEditComponentVisibilityPipe } from '../pipes/generic-edit-component-visibility.pipe';
import { GenericTableFilterComponent } from '../components/generic-table-filter/generic-table-filter.component';
import { GenericTableRowInputComponent } from '../components/generic-table-row-input/generic-table-row-input.component';

@NgModule({
	declarations: [
		InputEditComponent,
		SelectEditComponent,
		GenericTablePipePipe,
		VisibleCellDirective,
		InputFilterComponent,
		SelectFilterComponent,
		GenericTableSortPipe,
		GenericIconStatePipe,
		GenericTableSlicePipe,
		GenericTableComponent,
		GenericTableHeightPipe,
		GenericTableFilterPipe,
		EditVisibleCellDirective,
		GenericRowKeyResolverPipe,
		GenericTableFilterComponent,
		ConditionalRowStyleDirective,
		GenericTableImageTooltipPipe,
		GenericTableSentenceCasePipe,
		GenericTableRowInputComponent,
		GenericDirectiveConditionPipe,
		ConditionalCellStyleDirective,
		GenericEditComponentVisibilityPipe,
		GenericSortDescendingResolverPipe,
	],
	imports: [
		A11yModule,
		FormsModule,
		ChartsModule,
		CommonModule,
		DragDropModule,
		FormlyFormModule,
		ReactiveFormsModule,
		VirtualScrollerModule,
		FormlyBootstrapModule,
		NestedTranslationPipe,
		AngularBootstrapModule,
		FormlyModule.forChild(),
		TranslateModule.forChild(),
		UiComponentsFeatureModule,
		SharedPipeDirectiveModule
	],
	exports: [
		InputEditComponent,
		SelectEditComponent,
		InputFilterComponent,
		GenericTablePipePipe,
		SelectFilterComponent,
		VisibleCellDirective,
		GenericIconStatePipe,
		GenericTableSortPipe,
		GenericTableComponent,
		GenericTableHeightPipe,
		GenericTableFilterPipe,
		GenericRowKeyResolverPipe,
		GenericTableSentenceCasePipe,
		GenericSortDescendingResolverPipe,
		GenericEditComponentVisibilityPipe
	],
})
export class GenericTableFeatureModule {}
