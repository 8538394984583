import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../../root-reducers';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';
import { UserLevel } from '../../auth/ts/enums/user-level.enum';
import { NetworkingInfoService } from './networking-info.service';
import { AuthNgrxService } from '../../auth/services/auth-ngrx.service';
import { ITrfaResource } from '../../trfa/ts/models/utils/trfa-resource.model';
import { NetworkingInfoStoreKey } from '../ts/enums/networking-info-store-key.enum';
import { NetworkingInfoCategory } from '../ts/enums/networking-info-resource-category.enum';
import { NetworkingInfoMobilePinState } from '../ts/enums/networking-info-mobile-pin-state.enum';
import { INetworkingInfoResourceItem } from '../ts/models/resource/networking-info-resource-item.model';
import { NetworkingInfoResourceTunnelState } from '../ts/enums/networking-info-resource-tunnel-state.enum';
import { INetworkingInfoMobileTrfaSavePayload } from '../ts/models/mobile/networking-info-mobile-trfa-save-payload.model';
import * as networkingInfoSelectors from '../ngrx/networking-info.selectors';
import * as networkingInfoActions from '../ngrx/networking-info.actions';

@Injectable({
	providedIn: 'root'
})
export class NetworkingInfoNgrxService {

	constructor(
		private store: Store<IAppState>,
		private authService: AuthService,
		private authNgrxService: AuthNgrxService,
		private networkingInfoService: NetworkingInfoService
	) { }

	setTrfaWanConfiguration(resource: INetworkingInfoResourceItem, trfaForm: INetworkingInfoMobileTrfaSavePayload): void {
		const payload = { resource, trfaForm };

		this.store.dispatch(networkingInfoActions.setTrfaWanConfiguration({ payload }));
	}

	setSdwanControl(resource: INetworkingInfoResourceItem, vpnValue: string): void {
		const payload = { resource, vpnValue };

		this.store.dispatch(networkingInfoActions.setSdwanControl({ payload }));
	}

	setPinCardState(id: number, pin: string, state: NetworkingInfoMobilePinState): void {
		const payload = { id, pin, state };

		this.store.dispatch(networkingInfoActions.setPinCardState({ payload }));
	}

	selectFromNetworkingInfoStore$<T>(selector: NetworkingInfoStoreKey): Observable<T> {
		return this.store.pipe(select(networkingInfoSelectors.selectFromNetworkingInfoStore(selector))) as unknown as Observable<T>;
	}

	selectAllNetworkingResources$(): Observable<INetworkingInfoResourceItem[]> {
		return this.store.pipe(select(networkingInfoSelectors.selectAllNetworkingResources()));
	}

	selectAllNetworkingResourcesSnapshot$(): Observable<INetworkingInfoResourceItem[]> {
		return this.selectAllNetworkingResources$()
			.pipe(take(1));
	}

	selectTrfaModalResource$(selectedResource: INetworkingInfoResourceItem): Observable<ITrfaResource[]> {
		return this.selectAllNetworkingResources$()
			.pipe(
				map(resources => this.networkingInfoService.getTrfaModalResource(resources, selectedResource))
			);
	}

	selectNetworkingResources$(categories: NetworkingInfoCategory[]): Observable<INetworkingInfoResourceItem[]> {
		return this.store.pipe(select(networkingInfoSelectors.selectNetworkingResources(categories)));
	}

	showAdvancedNetworkingInfoPart$(): Observable<boolean> {
		return combineLatest([
			this.authNgrxService.getUserLevel$(),
			this.selectNetworkingResources$([NetworkingInfoCategory.SDWAN]),
		])
			.pipe(
				switchMap(response => {
					const [userLevel, sdwanResources] = response;

					return forkJoin({
						haveSdwan: of(!!sdwanResources.length),
						haveAccess: of(!this.authService.haveSpecificUserLevel([UserLevel.NO, UserLevel.BASIC], userLevel))
					});
				}),
				map(response => {
					const { haveAccess, haveSdwan } = response;

					return haveAccess && haveSdwan;
				})
			);
	}

	selectInsideVpnResources$(): Observable<INetworkingInfoResourceItem[]> {
		return this.selectNetworkingResources$([NetworkingInfoCategory.VPN])
			.pipe(
				map(resources => resources
					.reduce((accumulator, resource) => {
						const [existingNA, existingAllDown, existingSomeUp, existingBackupUp, existingAllUp] = accumulator;

						switch (resource.tst) {
						case NetworkingInfoResourceTunnelState.N_A:
							return [[...existingNA, resource], existingAllDown, existingSomeUp, existingBackupUp, existingAllUp];
						case NetworkingInfoResourceTunnelState.ALL_DOWN:
							return [existingNA, [...existingAllDown, resource], existingSomeUp, existingBackupUp, existingAllUp];
						case NetworkingInfoResourceTunnelState.SOME_UP:
							return [existingNA, existingAllDown, [...existingSomeUp, resource], existingBackupUp, existingAllUp];
						case NetworkingInfoResourceTunnelState.BACKUP_UP:
							return [existingNA, existingAllDown, existingSomeUp, [...existingBackupUp, resource], existingAllUp];
						case NetworkingInfoResourceTunnelState.ALL_UP:
							return [existingNA, existingAllDown, existingSomeUp, existingBackupUp, [...existingAllUp, resource]];
						default:
							return accumulator;
						}
					}, [[], [], [], [], []] as INetworkingInfoResourceItem[][])
					.flat()
				));
	}

	showOutsideContainer$(): Observable<boolean> {
		return this.store.pipe(select(networkingInfoSelectors.showOutsideContainer()));
	}

	showInsideContainer$(): Observable<boolean> {
		return this.store.pipe(select(networkingInfoSelectors.showInsideContainer()));
	}

	loadNetworkingResources(): void {
		this.store.dispatch(networkingInfoActions.loadNetworkingResources());
	}

	loadNetworkLogs(): void {
		this.store.dispatch(networkingInfoActions.loadNetworkLogs());
	}

	cancelNetworkingObservables(): void {
		this.store.dispatch(networkingInfoActions.cancelNetworkingHTTPObservables());
	}
}
