import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { AutomationService } from '../automation.service';
import { AutomationMapUiService } from '../automation-map-ui.service';
import { AutomationNgrxService } from '../ngrx/automation-ngrx.service';
import { AutomationGenericService } from '../automation-generic.service';
import { AutomationDeviceClassService } from './automation-device-class.service';
import { AutomationDeviceType } from '../../ts/enums/automation-device-type.enum';
import { AutomationLineDevice } from '../../ts/types/automation-line-device.type';
import { AutomationVideoNgrxService } from '../ngrx/automation-video-ngrx.service';
import { IAutomationMapDevice } from '../../ts/models/automation-map-device.model';
import { RioRvsImageService } from '../../../rio-rvs/services/rio-rvs-image.service';
import { AutomationAnalogInputs } from '../../ts/enums/automation-analog-inputs.enum';
import { AutomationAnalogOutputs } from '../../ts/enums/automation-analog-outputs.enum';
import { RioRvsDeviceValue } from '../../../rio-rvs/ts/enums/rio-rvs-device-value.enum';
import { AutomationMapDeviceValue } from '../../ts/enums/automation-map-device-value.enum';
import { AutomationLineDeviceType } from '../../ts/enums/automation-line-device-type.enum';
import { IAutomationMapVideoContainer } from '../../ts/models/automation-map-video-container.model';
import { AutomationInitialSelectedDevice } from '../../ts/types/automation-initial-selected-device.type';
import { RioRvsWioIohubDeviceClass } from '../../../rio-rvs/ts/enums/rio-rvs-wio-iohub-device-class.enum';
import { IAutomationTabDevicesLengthInfo } from '../../ts/models/automation-tab-devices-length-info.model';
import {
	AutomationProgramDevicesService
} from '../../../automation-program-edit/services/devices/automation-program-devices.service';

@Injectable({
	providedIn: 'root'
})
export class AutomationDeviceService {

	private readonly analogOutputs = [AutomationAnalogOutputs.ANALOG_OUTPUTS, AutomationAnalogOutputs.ANALOG_OUTPUT_MONITOR_CONTROLS];

	private readonly analogReadonlyTypes = [
		AutomationAnalogInputs.ANALOG_INPUT, AutomationAnalogInputs.ANALOG_INPUTS, AutomationAnalogOutputs.ANALOG_OUTPUT_MONITORS
	];

	constructor(
		private automationService: AutomationService,
		private rioRvsImageService: RioRvsImageService,
		private automationNgrxService: AutomationNgrxService,
		private automationMapUiService: AutomationMapUiService,
		private automationVideoNgrxService: AutomationVideoNgrxService,
		private automationDeviceClassService: AutomationDeviceClassService,
		private automationProgramDevicesService: AutomationProgramDevicesService
	) { }

	updateDeviceCoordinatesOnDragEnd(
		deviceElement: HTMLDivElement, videoContainer: boolean,
		device: IAutomationMapDevice | IAutomationMapVideoContainer
	): void {
		const { left, top } = this.automationMapUiService.getElementLeftTopPosition(deviceElement);
		// Update device/video container coordinates
		if (videoContainer) {
			const videoPayload = { ...device, left, top } as IAutomationMapVideoContainer;

			this.automationVideoNgrxService.updateVideoContainerInfo(videoPayload);
		} else {
			const devicePayload = { ...device, newLeftPosition: left, newTopPosition: top } as IAutomationMapDevice;

			this.automationNgrxService.updateSingleDeviceOnMap(devicePayload);
		}
	}

	showSquareDevice(device: IAutomationMapDevice): boolean {
		const { originalDeviceType } = device;

		const isMatchingAnalogOutputs = this.analogOutputs.some(analogOutputsItem => analogOutputsItem === originalDeviceType);
		const isMatchingReadonlyType = this.analogReadonlyTypes.some(analogReadonlyItem => analogReadonlyItem === originalDeviceType);

		return !isMatchingAnalogOutputs && !isMatchingReadonlyType;
	}

	showReadonlyDevice(device: IAutomationMapDevice): boolean {
		return this.analogReadonlyTypes.some(analogReadonlyType => analogReadonlyType === device.originalDeviceType);
	}

	showAnalogOutputsDevice(device: IAutomationMapDevice): boolean {
		const { originalDeviceType } = device;
		return originalDeviceType === AutomationAnalogOutputs.ANALOG_OUTPUTS ||
			originalDeviceType === AutomationAnalogOutputs.ANALOG_OUTPUT_MONITOR_CONTROLS;
	}

	getModalSelectedDevices(selectedDevices: IAutomationMapDevice[]): string[] {
		return selectedDevices.map(selectedDevice => {
			const { fullDeviceName, originalDeviceType } = selectedDevice;

			return `${fullDeviceName}${originalDeviceType}`;
		});
	}

	getDevicesLengthInfo(
		deviceLineDevices: IAutomationMapDevice[], selectedLineDevices: IAutomationMapDevice[]
	): IAutomationTabDevicesLengthInfo {
		const allSelectedDevicesNames = selectedLineDevices.map(selectedLineDevice => selectedLineDevice.fullDeviceName);

		return deviceLineDevices
			.filter(lineDevice => allSelectedDevicesNames.includes(lineDevice.fullDeviceName))
			.reduce((accumulator, deviceItem) => {
				const [port] = AutomationGenericService.splitAutomationItemByDash(deviceItem.port);
				const currentValue = accumulator[port as keyof IAutomationTabDevicesLengthInfo];

				return { ...accumulator, [port]: currentValue + 1 };
			}, this.automationService.getInitialTabDevicesLengthInfo());
	}

	getSelectedDeviceIndex(selectedDevices: FormArray, lineDevice: IAutomationMapDevice): number {
		return selectedDevices.controls.findIndex(control => {
			const { fullDeviceName, originalDeviceType } = control.value;

			return fullDeviceName === lineDevice.fullDeviceName && originalDeviceType === lineDevice.originalDeviceType;
		});
	}

	addMapParamsToLineDevice(lineDevice: IAutomationMapDevice): IAutomationMapDevice {
		const { num, value, } = lineDevice;
		const fullDeviceName = lineDevice.fullDeviceName as string;
		const originalDeviceType = lineDevice.originalDeviceType as AutomationLineDevice;
		const [device] = AutomationGenericService.splitAutomationItemByComma(fullDeviceName);
		const [deviceName, deviceNumber] = AutomationGenericService.splitAutomationItemByDash(device);

		// New map keys
		const deviceId = fullDeviceName + originalDeviceType;
		const resourceIdentifier = this.getResourceIdentifier(originalDeviceType, num, deviceName, +deviceNumber);
		const tooltipDescription = this.formatTooltipDescription(lineDevice, resourceIdentifier);
		const status = this.rioRvsImageService.convertValueToStatus(value.toString() as RioRvsDeviceValue);
		const imagePath = this.rioRvsImageService.getDeviceImagePath(originalDeviceType, status);

		return {
			...lineDevice, clickEnabled: false, newTopPosition: null, newLeftPosition: null,
			resourceIdentifier, tooltipDescription, status, imagePath, id: deviceId
		};
	}

	checkIfDeviceIsUsed(initialMapDevices: AutomationInitialSelectedDevice[], lineDevice: IAutomationMapDevice): boolean {
		return initialMapDevices.some(initialMapDevice => {
			const { originalDeviceType, fullDeviceName } = initialMapDevice;

			return originalDeviceType === lineDevice.originalDeviceType && fullDeviceName === lineDevice.fullDeviceName;
		});
	}

	getLineDevicesOfDevice(deviceClass: RioRvsWioIohubDeviceClass, devicesLineDevices: IAutomationMapDevice[]): IAutomationMapDevice[] {
		return devicesLineDevices.filter(deviceItem => deviceItem.port.startsWith(deviceClass));
	}

	isClickEnabled(deviceSubtype: string, deviceValue: AutomationMapDeviceValue): boolean {
		const clickableDevicesRegExp = /^(DigitalOutputs|DigitalOutputSwitches|DigitalOutputPulse|DigitalOutputToggles)$/;

		return clickableDevicesRegExp.test(deviceSubtype) && deviceValue !== AutomationMapDeviceValue.UNAVAILABLE;
	}

	formatTooltipDescription(updatedLineDevice: IAutomationMapDevice, resourceIdentifier: string): string {
		if (updatedLineDevice.hasOwnProperty('max') && updatedLineDevice.hasOwnProperty('min')) {
			const { min, max, unit, description } = updatedLineDevice;

			if (description) {
				return `{{AUTO.MAP.min}} ${min} ${unit}` + '\n' +
					`{{AUTO.MAP.max}} ${max} ${unit}` + '\n' +
					`{{AUTO.MAP.description}} ${description}`;
			}

			return `{{AUTO.MAP.min}} ${min} ${unit}` + '\n' +
				`{{AUTO.MAP.max}} ${max} ${unit}` + '\n';
		}

		return updatedLineDevice.description || resourceIdentifier;
	}

	getResourceIdentifier(lineDeviceType: AutomationLineDevice, lineDeviceNum: number, ioDeviceType: string, ioDeviceNum: number): string {
		const lineDeviceName = this.automationDeviceClassService.getLineDeviceName(lineDeviceType);

		return `${ioDeviceType}-${ioDeviceNum},${lineDeviceName}-${lineDeviceNum}`;
	}

	setImageBasedOnValue(deviceValue: AutomationMapDeviceValue, imagePath: string): string {
		if (imagePath.includes('feather-icons')) {
			return imagePath;
		}

		const deviceStatus = this.rioRvsImageService.convertValueToStatus(deviceValue?.toString() as RioRvsDeviceValue);

		return imagePath
			.replace('-on.svg', `-${deviceStatus}.svg`)
			.replace('-off.svg', `-${deviceStatus}.svg`)
			.replace('-setting.svg', `-${deviceStatus}.svg`)
			.replace('-unavailable.svg', `-${deviceStatus}.svg`);
	}

	getFullLineDeviceName(resourceIdentifier: string): string {
		const [device, port] = AutomationGenericService.splitAutomationItemByComma(resourceIdentifier);

		return device + this.formatPortToDeviceName(port);
	}

	formatPortToDeviceName(port: string): string {
		const [deviceName, deviceNumber] = AutomationGenericService.splitAutomationItemByDash(port);
		const deviceType = this.getDeviceType(deviceName as AutomationLineDeviceType);
		const isDigitalDevice = this.automationProgramDevicesService.isDigitalLineDevice(port);

		if (isDigitalDevice && deviceType === AutomationDeviceType.INPUT) {
			return `,${RioRvsWioIohubDeviceClass.DIN.toLowerCase()}-` + deviceNumber;
		}

		if (isDigitalDevice && deviceType === AutomationDeviceType.OUTPUT) {
			return `,${RioRvsWioIohubDeviceClass.DOUT.toLowerCase()}-` + deviceNumber;
		}

		if (!isDigitalDevice && deviceType === AutomationDeviceType.INPUT) {
			return `,${RioRvsWioIohubDeviceClass.AIN.toLowerCase()}-` + deviceNumber;
		}

		if (!isDigitalDevice && deviceType === AutomationDeviceType.OUTPUT) {
			return `,${RioRvsWioIohubDeviceClass.AOUT.toLowerCase()}-` + deviceNumber;
		}

		return '';
	}

	getDeviceType(lineDeviceType: AutomationLineDeviceType): AutomationDeviceType {
		switch (lineDeviceType) {
		case AutomationLineDeviceType.DIN:
		case AutomationLineDeviceType.AIN:
		case AutomationLineDeviceType.ALARM:
		case AutomationLineDeviceType.ALMIN:
			return AutomationDeviceType.INPUT;
		case AutomationLineDeviceType.DOUT:
		case AutomationLineDeviceType.AOUT:
		case AutomationLineDeviceType.ALMOUT:
			return AutomationDeviceType.OUTPUT;
		}
	}
}
