import { Injectable } from '@angular/core';
import { FormValueType } from '../ts/types/form-value-type.type';
import { MainUtilService } from '../../../services/main-util.service';
import { FormlyFunction } from '../ts/enums/formly/formly-function.enum';
import { FormlyMultipleModalValue } from '../ts/types/formly-multiple-modal.type';
import { FormlyHideOperator } from '../ts/enums/formly/formly-hide-operator.enum';
import { FormlyUtilOperators } from '../ts/enums/formly/formly-util-operators.enum';
import { IGeneratedFormContainer } from '../ts/models/generated-form-container.model';
import { GeneratedFormComponentType } from '../ts/enums/generated-form-component-type.enum';

@Injectable({
	providedIn: 'root'
})
export class NetworkingAutomationService {

	private readonly multipleHideOperatorsRegExp = /(&&|\^\^|\|\|)/;

	constructor() { }

	isMatchingHideOperator(hideCondition: string): boolean {
		return this.multipleHideOperatorsRegExp.test(hideCondition);
	}

	getAllContainerParamNames(configurationItems: IGeneratedFormContainer[]): string[] {
		return configurationItems
			.filter(configurationItem => configurationItem.type === GeneratedFormComponentType.CONTAINER)
			.map(containerItem => {
				const { containerParameters, containerElementHeaderName } = containerItem;

				return [...containerParameters.split(','), containerElementHeaderName];
			})
			.flat(1)
			.filter(containerItemName => containerItemName !== '');
	}

	getFormStateModalParameter(mainModal: Record<string, FormValueType>, hideCondition: string): FormlyMultipleModalValue | FormValueType {
		try {
			if (this.isMatchingHideOperator(hideCondition)) {

				return this.getFormStateMultipleModalParameters(mainModal, hideCondition);
			}

			return this.getFormStateSingleModalParameter(mainModal, hideCondition);
		} catch {
			return false;
		}
	}

	getFormStateMultipleModalParameters(mainModal: Record<string, FormValueType>, hideCondition: string): FormlyMultipleModalValue {
		const hideOperator = this.getMatchingHideOperator(hideCondition);

		return hideCondition
			.split(hideOperator)
			.map(condition => this.getFormStateSingleModalParameter(mainModal, condition))
			.flat(1) as FormlyMultipleModalValue;
	}

	getFormStateSingleModalParameter(mainModal: Record<string, any>, hideCondition: string): FormValueType {
		return hideCondition
			.slice(0, hideCondition.indexOf(FormlyUtilOperators.SEPARATOR))
			.split('.')
			.reduce((accumulator, path) => accumulator[path], mainModal) as FormValueType;
	}

	getFormStateModalCondition(parameterValue: FormValueType[] | FormValueType, hideCondition: string): boolean {
		try {
			if (this.isMatchingHideOperator(hideCondition)) {
				return this.getMultipleStateModalConditions(parameterValue as FormValueType[], hideCondition);
			}

			return this.getSingleStateModalCondition(parameterValue as FormValueType, hideCondition);
		} catch {
			return false;
		}
	}

	getSingleStateModalCondition(parameterValue: FormValueType, hideCondition: string): boolean {
		const [functionName, valueToCompare] = hideCondition
			.slice(hideCondition.indexOf(FormlyUtilOperators.SEPARATOR) + 3, hideCondition.length)
			.split(FormlyUtilOperators.SEPARATOR);

		const formlyFunction = functionName.toLowerCase() as FormlyFunction;
		const arrayValues = this.getHideConditionArrayValues(valueToCompare);

		return this.isFormlyConditionMatching(formlyFunction, valueToCompare, parameterValue, arrayValues);
	}

	getMultipleStateModalConditions(parameterValue: FormValueType[], hideCondition: string): boolean {
		const hideOperator = this.getMatchingHideOperator(hideCondition);

		const matchingConditions = hideCondition
			.split(hideOperator)
			.map(condition => condition
				.slice(condition.indexOf(FormlyUtilOperators.SEPARATOR) + 3, condition.length)
				.split(FormlyUtilOperators.SEPARATOR)
			)
			.map((conditionValue, index) => {
				const [functionName, valueToCompare] = conditionValue;
				const formlyFunction = functionName.toLowerCase() as FormlyFunction;
				const arrayValues = this.getHideConditionArrayValues(valueToCompare);

				return this.isFormlyConditionMatching(formlyFunction, valueToCompare, parameterValue[index], arrayValues);
			});

		return this.evauluateMultipleConditionStateResult(matchingConditions, hideOperator);
	}

	isFormlyConditionMatching(
		functionName: FormlyFunction, valueToCompare: string, parameterValue: FormValueType, arrayValues: string[]
	): boolean {
		const stringParameterValue = parameterValue.toString();

		switch (functionName) {
		case FormlyFunction.EQUAL:
			return stringParameterValue === (valueToCompare || '').toString();
		case FormlyFunction.NOTEQUAL:
			return stringParameterValue !== (valueToCompare || '').toString();
		case FormlyFunction.INCLUDES:
		case FormlyFunction.NOTINCLUDES:
			return this.handleIncludesHideCondition(functionName, valueToCompare, stringParameterValue, arrayValues);
		case FormlyFunction.LESS:
			return this.handleLessHideCondition(valueToCompare, stringParameterValue);
		case FormlyFunction.LESS_EQUAL:
			return this.handleLessEqualHideCondition(valueToCompare, stringParameterValue);
		case FormlyFunction.RANGE_MATCHES:
			return this.handleRangeMatchesCondition(valueToCompare, stringParameterValue);
		default:
			return false;
		}
	}

	handleRangeMatchesCondition(valueToCompare: string, stringParameterValue: string): boolean {
		const ipRegExp = /((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/;

		if (ipRegExp.test(stringParameterValue)) {
			const valueToCompareFormatted = this.pluckIpAddressFromRange(valueToCompare)[0];
			const ipAddressesRange = this.pluckIpAddressFromRange(stringParameterValue);

			return ipAddressesRange.every(ipAddress => MainUtilService.isIpMatchingRange(valueToCompareFormatted, ipAddress));
		}

		return true;
	}

	pluckIpAddressFromRange(ipAddressesValue: string): string[] {
		if (ipAddressesValue.includes('(')) {
			return [...ipAddressesValue.slice(ipAddressesValue.indexOf('(') + 1, ipAddressesValue.indexOf(')')).split(':')];
		}

		if (ipAddressesValue.includes(':')) {
			return ipAddressesValue.split(':');
		}

		return [ipAddressesValue];
	}

	handleIncludesHideCondition(
		functionName: string, valueToCompare: string,
		stringParameterValue: string, arrayValues: string[]
	): boolean {
		const isIncludesFunction = functionName === FormlyFunction.INCLUDES;

		if (!arrayValues.length) {
			const singleParamMatching = stringParameterValue.toLowerCase().includes((valueToCompare?.toLowerCase() || '').toString());

			return isIncludesFunction ? singleParamMatching : !singleParamMatching;
		}

		const partialMatches = arrayValues.some(arrayValue => stringParameterValue.toLowerCase().includes(arrayValue.toLowerCase()));

		return isIncludesFunction ? partialMatches : !partialMatches;
	}

	handleLessHideCondition(valueToCompare: string, stringParameterValue: string): boolean {
		const parsedValueToCompare = Number.parseFloat(valueToCompare || '0');
		const parsedParameterValue = Number.parseFloat(stringParameterValue);

		return parsedParameterValue < parsedValueToCompare;
	}

	handleLessEqualHideCondition(valueToCompare: string, stringParameterValue: string): boolean {
		const parsedValueToCompare = Number.parseFloat(valueToCompare || '0');
		const parsedParameterValue = Number.parseFloat(stringParameterValue);

		return parsedParameterValue <= parsedValueToCompare;
	}

	getHideConditionArrayValues(valueToCompare: string): string[] {
		if (/\[.{1,}\]/.test(valueToCompare)) {
			return valueToCompare
				.slice(1, valueToCompare.length - 1)
				.split(',');
		}

		return [];
	}

	getMatchingHideOperator(hideCondition: string): FormlyHideOperator {
		if (hideCondition.includes(FormlyHideOperator.SOME_MATCHING)) {
			return FormlyHideOperator.SOME_MATCHING;
		}

		if (hideCondition.includes(FormlyHideOperator.EVERY_MATCH)) {
			return FormlyHideOperator.EVERY_MATCH;
		}

		return FormlyHideOperator.SOME_NOT_MATCHING;
	}

	evauluateMultipleConditionStateResult(matchingConditions: boolean[], hideOperator: FormlyHideOperator): boolean {
		switch (hideOperator) {
		case FormlyHideOperator.SOME_MATCHING:
			return matchingConditions.some(condition => condition);
		case FormlyHideOperator.SOME_NOT_MATCHING:
			return matchingConditions.some(condition => !condition);
		case FormlyHideOperator.EVERY_MATCH:
			return matchingConditions.every(condition => condition);
		default:
			return false;
		}
	}
}
