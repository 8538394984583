import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable, zip, BehaviorSubject, timer, forkJoin, of } from 'rxjs';
import { map, take, switchMap, catchError, exhaustMap } from 'rxjs/operators';
import { IAutomationDevice } from '../../../automation/ts/models/automation-device.model';
import { IRioRvsIohubDevice } from '../../../rio-rvs/ts/models/rio-rvs-iohub-device.model';
import { IAutomationProgramEditLineDevice } from '../../ts/models/automation-program-edit-line-device.model';
import { AutomationProgramSpreadsheetNgrxService } from '../ngrx/automation-program-spreadsheet-ngrx.service';
import { IRioRvsIohubDevicesResponse } from '../../../rio-rvs/ts/models/rio-rvs-iohub-devices-response.model';
import { AutomatonProgramEditTransitionService } from '../transition/automaton-program-edit-transition.service';
import { AutomationProgramEditDeviceStatusData } from '../../ts/types/automation-program-edit-status-data.type';
import { AutomationProgramSessionDevicesHandlerService } from '../devices/automation-program-session-devices-handler.service';
import {
	AutomationDeviceSessionHandlerService
} from '../../../automation/services/device/automation-device-session-handler.service';
import * as customOperators from '../../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSpreadsheetHttpService {

	private sessionId: BehaviorSubject<string> = new BehaviorSubject('');

	private readonly getDevicesStatusUrl: string = environment.abilisUrl + '/sys/iohub/all/GetSesStatus.json';

	constructor(
		private http: HttpClient,
		private automationSessionHandlerService: AutomationDeviceSessionHandlerService,
		private automatonProgramEditTransService: AutomatonProgramEditTransitionService,
		private automationSpreadsheetNgrxService: AutomationProgramSpreadsheetNgrxService,
		private automationSessionDevicesHandlerService: AutomationProgramSessionDevicesHandlerService
	) { }

	getDevicesPayload$(): Observable<AutomationProgramEditDeviceStatusData> {
		return zip(
			this.fetchDeviceStatus$(),
			this.automationSpreadsheetNgrxService.selectActuators$(),
			this.automationSpreadsheetNgrxService.selectCustomInputValues$(),
			this.automationSpreadsheetNgrxService.selectAllSpreadsheetLineDevices$(),
			this.automationSpreadsheetNgrxService.selectVoutTemporaryDescriptions$()
		);
	}

	pollFetchDevicesStatus$(): Observable<IAutomationProgramEditLineDevice[]> {
		return timer(0, 1000)
			.pipe(
				exhaustMap(() => this.getDevicesPayload$()),
				switchMap(response => forkJoin({
					projectOptions: this.automationSpreadsheetNgrxService.selectProjectOptions$().pipe(take(1)),
					lineDevices: of(this.automationSessionDevicesHandlerService.formatProjectLineDevices(response))
				})),
				switchMap(response => {
					const { lineDevices, projectOptions } = response;
					const { ProjectTimeoutUnit } = projectOptions;

					return this.automatonProgramEditTransService.checkForHistoricalDeviceValues(lineDevices, ProjectTimeoutUnit);
				}),
				customOperators.retryFromError$(1000)
			);
	}

	fetchModalDevices$(): Observable<IAutomationDevice[]> {
		return this.fetchDeviceStatus$()
			.pipe(
				map(ioSessionData => this.automationSessionHandlerService.getAllDevices(ioSessionData))
			);
	}

	fetchDeviceStatus$(): Observable<IRioRvsIohubDevice[] | null> {
		return this.getSessionId$()
			.pipe(
				take(1),
				switchMap(sessionId => this.fetchSessionStatus$(sessionId ? `?SesId=${sessionId}` : '')
					.pipe(
						catchError(() => {
							this.setSessionId('');

							return this.fetchSessionStatus$('');
						})
					))
			);
	}

	fetchSessionStatus$(queryParams: string): Observable<IRioRvsIohubDevice[] | null> {
		return this.http.get<IRioRvsIohubDevicesResponse>(this.getDevicesStatusUrl + queryParams)
			.pipe(
				map(response => this.handleDevicesResponse(response))
			);
	}

	setSessionId(sessionId: string): void {
		this.sessionId.next(sessionId);
	}

	getSessionId$(): Observable<string> {
		return this.sessionId.asObservable();
	}

	handleDevicesResponse(response: IRioRvsIohubDevicesResponse): IRioRvsIohubDevice[] | null {
		const { iohub, SesId } = response.Response;

		if (iohub !== undefined) {
			this.setSessionId(SesId);

			return iohub;
		}

		return null;
	}
}
