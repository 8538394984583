import { createAction, props } from '@ngrx/store';
import { IOpcPanelClientSettingsState } from '../../ts/models/client-settings/opc-panel-client-settings-state.model';
import { IOpcPanelClientSettingsBoxForm } from '../../ts/models/client-settings/opc-panel-client-settings-box-form.model';

export const loadOpcPanelClientSettings = createAction(
	'[Opc Panel Client] Load Opc Panel Client Settings'
);

export const finishLoadingOpcPanelClientSettings = createAction(
	'[Opc Panel Client] Finish Loading Opc Panel Client Settings',
	props<{ payload: { settings: IOpcPanelClientSettingsState } }>()
);

export const saveUserSettings = createAction(
	'[Opc Panel Client] Save Users Settings',
	props<{ payload: { userId: string; rowFormData: boolean[] } }>()
);

export const saveBoxSettings = createAction(
	'[Opc Panel Client] Save Box Settings',
	props<{ payload: { boxFormValues: IOpcPanelClientSettingsBoxForm } }>()
);

export const addNewQueueNumber = createAction(
	'[Opc Panel Client] Add New Queue Number',
	props<{ payload: { newQueueNumber: string } }>()
);

export const deleteQueueNumber = createAction(
	'[Opc Panel Client] Delete Queue Number',
	props<{ payload: { queueNumber: string } }>()
);

export const setShowQueues = createAction(
	'[Opc Panel Client] Set Show Queues',
	props<{ payload: { showQueues: boolean } }>()
);

export const setMinimizedQueues = createAction(
	'[Opc Panel Client] Set Minimized Queues',
	props<{ payload: { minimized: boolean } }>()
);

export const setMinimizedActiveCalls = createAction(
	'[Opc Panel Client] Set Minimized Active Calls',
	props<{ payload: { minimizedActiveCalls: boolean } }>()
);
