import { Injectable } from '@angular/core';
import { PhoneUtilService } from '../phone-util.service';
import { PhoneCallsService } from '../calls/phone-calls.service';
import { IPhoneCallRecord } from '../../ts/models/phone-call-record.model';
import { OpcPanelCallDirection } from '../../../opc-panel/ts/enums/opc-panel-call-direction.enum';
import { PreferencesCtipAllType } from '../../../preferences/ts/enums/preferences-ctip-all-type.enum';
import { OpcPanelCallRecordState } from '../../../opc-panel/ts/enums/opc-panel-call-record-state.enum';
import { OpcPanelUserRecordState } from '../../../opc-panel/ts/enums/opc-panel-user-record-state.enum';
import { IOpcPanelUserRecord } from '../../../opc-panel/ts/models/session/opc-panel-user-record.model';
import { OpcPanelCtipPotsDevice } from '../../../opc-panel/ts/enums/opc-panel-ctip-if-subtype-pots-device.enum';

@Injectable({
	providedIn: 'root'
})
export class PhoneFooterUtilService {

	constructor() { }

	public static isCalltryActiveByCallRecords(callRecords: IPhoneCallRecord[], userRecord: IOpcPanelUserRecord | null): boolean {
		const noCalls = !callRecords.length;
		const isReady = userRecord?.State !== OpcPanelUserRecordState.NOTREADY;
		const isNotRinging = userRecord?.State !== OpcPanelUserRecordState.RINGING;

		return noCalls && isReady && isNotRinging;
	}

	public static canSetPriorityOnSingleCall(callState: OpcPanelCallRecordState, userRecord: IOpcPanelUserRecord | null): boolean {
		const notNullState = callState !== OpcPanelCallRecordState.NULL;
		const notAlertingState = callState !== OpcPanelCallRecordState.ALERTING;

		return notNullState && notAlertingState && userRecord?.State !== OpcPanelUserRecordState.RINGING;
	}

	public static isCallStateMatching(state: OpcPanelCallRecordState, stateToMatch: OpcPanelCallRecordState): boolean {
		return state === stateToMatch;
	}

	public static isCommandActive(active: boolean): false | null {
		return active ? false : null;
	}

	public static isHeadsetMode(userRecord: IOpcPanelUserRecord | null): boolean {
		if (!userRecord) { return false; }

		const { IfSubtype, IfSubtypePotsDevice } = userRecord;
		const validSubtypes = [PreferencesCtipAllType.POTS, PreferencesCtipAllType.APOTS];

		return validSubtypes.includes(IfSubtype as PreferencesCtipAllType) && IfSubtypePotsDevice === OpcPanelCtipPotsDevice.HEADSET;
	}

	public static isVpots(userRecord: IOpcPanelUserRecord | null, missingRecordValue = false): boolean {
		return !userRecord ? missingRecordValue : userRecord.IfSubtype === PreferencesCtipAllType.VPOTS;
	}

	public getCalltryNumberName(userRecord: IOpcPanelUserRecord | null): string {
		if (!userRecord) { return ''; }

		const { CdoName, CdoNum, CdoPrefix } = userRecord.CallTry;

		if (CdoName) { return `(${CdoName})`; }

		if (CdoNum && CdoPrefix) { return `(${CdoPrefix}${CdoNum})`; }

		if (CdoNum) { return `(${CdoNum})`; }

		return '';
	}

	public static canAcceptCall(callRecords: IPhoneCallRecord[], userRecord: IOpcPanelUserRecord | null): boolean {
		if (!userRecord) { return false; }

		const { State } = userRecord;
		const isVpots = PhoneFooterUtilService.isVpots(userRecord);
		const isHeadsetMode = PhoneFooterUtilService.isHeadsetMode(userRecord);

		if (isVpots || isHeadsetMode) {
			const alertingCall = PhoneCallsService.getCallRecordByState(callRecords, OpcPanelCallRecordState.ALERTING);
			const alertingOut = alertingCall && !PhoneUtilService.isInDirection(alertingCall.Direction);

			return (alertingOut || (!!callRecords.length && State === OpcPanelUserRecordState.RINGING));
		}

		if (callRecords.length > 1) {
			const [, secondCall] = callRecords;
			const { Direction: secondCallDirection, State: secondCallState } = secondCall;

			const isAlerting = secondCallState === OpcPanelCallRecordState.ALERTING;
			const isOutDirection = !PhoneUtilService.isInDirection(secondCallDirection);
			const isRingingOffhook = State === OpcPanelUserRecordState.RINGING || State === OpcPanelUserRecordState.OFFHOOK;

			return (isOutDirection && isAlerting && isRingingOffhook);
		}

		return false;
	};

	public static canCall(callRecords: IPhoneCallRecord[], userRecord: IOpcPanelUserRecord | null): boolean {
		if (!userRecord) { return false; }

		const { State } = userRecord;
		const isRinging = State === OpcPanelUserRecordState.RINGING;
		const isNotReady = State === OpcPanelUserRecordState.NOTREADY;
		const holdActiveStates = [OpcPanelCallRecordState.HOLD, OpcPanelCallRecordState.ACTIVE];

		const has2HoldAndActive = PhoneCallsService.getCallRecordCount(callRecords, holdActiveStates) === 2;
		const alertingCall = PhoneCallsService.someCallStateMatches(callRecords, [OpcPanelCallRecordState.ALERTING]);

		return !(alertingCall || has2HoldAndActive || isRinging || isNotReady);
	};

	public static areBothCallsAvailable(firstCall: IPhoneCallRecord, secondCall: IPhoneCallRecord): boolean {
		return !!firstCall && !!secondCall;
	}

	public canSetPriorityOnMultipleCalls(firstState: OpcPanelCallRecordState, secondState: OpcPanelCallRecordState): boolean {
		const isFirstCallHold = PhoneFooterUtilService.isCallStateMatching(firstState, OpcPanelCallRecordState.HOLD);
		const isFirstCallActive = PhoneFooterUtilService.isCallStateMatching(firstState, OpcPanelCallRecordState.ACTIVE);
		const isFirstCallDisconnecting = PhoneFooterUtilService.isCallStateMatching(firstState, OpcPanelCallRecordState.DISCONNECTING);

		const isSecondCallActive = PhoneFooterUtilService.isCallStateMatching(secondState, OpcPanelCallRecordState.ACTIVE);
		const isSecondCallCalling = PhoneFooterUtilService.isCallStateMatching(secondState, OpcPanelCallRecordState.CALLING);
		const isSecondCallDisconnecting = PhoneFooterUtilService.isCallStateMatching(secondState, OpcPanelCallRecordState.DISCONNECTING);

		const bothCallsDisconnecting = isFirstCallDisconnecting && isSecondCallDisconnecting;
		const firstDisconnectingSecondActive = isFirstCallDisconnecting && isSecondCallActive;
		const firstActiveSecondDisconnecting = isFirstCallActive && isSecondCallDisconnecting;

		return ((isFirstCallHold || isFirstCallDisconnecting) && isSecondCallCalling) ||
			firstActiveSecondDisconnecting || firstDisconnectingSecondActive || bothCallsDisconnecting;
	}

	public canTransferNormalCall(callState: OpcPanelCallRecordState, callDirection: OpcPanelCallDirection): boolean {
		const isInDirection = PhoneUtilService.isInDirection(callDirection);
		const isHoldCall = PhoneFooterUtilService.isCallStateMatching(callState, OpcPanelCallRecordState.HOLD);
		const isActiveCall = PhoneFooterUtilService.isCallStateMatching(callState, OpcPanelCallRecordState.ACTIVE);
		const isAlertingCall = PhoneFooterUtilService.isCallStateMatching(callState, OpcPanelCallRecordState.ALERTING);

		const isActiveHold = isActiveCall || isHoldCall;

		return isActiveHold || (isAlertingCall && isInDirection);
	}

	public static isUserRecordNotRinging(userRecord: IOpcPanelUserRecord | null): boolean {
		return userRecord?.State !== OpcPanelUserRecordState.RINGING;
	}
}
