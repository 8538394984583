import { Injectable } from '@angular/core';
import { AutomationDeviceService } from './automation-device.service';
import { AutomationNgrxService } from '../ngrx/automation-ngrx.service';
import { AutomationGenericService } from '../automation-generic.service';
import { IAutomationDevice } from '../../ts/models/automation-device.model';
import { AutomationDeviceClassService } from './automation-device-class.service';
import { AutomationLineDevice } from '../../ts/types/automation-line-device.type';
import { IAutomationMapDevice } from '../../ts/models/automation-map-device.model';
import { RioRvsImageService } from '../../../rio-rvs/services/rio-rvs-image.service';
import { RioRvsService } from '../../../../components/rio-rvs/services/rio-rvs.service';
import { RioRvsDeviceValue } from '../../../rio-rvs/ts/enums/rio-rvs-device-value.enum';
import { AutomationDeviceFormatterService } from './automation-device-formatter.service';
import { IRioRvsIohubDevice } from '../../../rio-rvs/ts/models/rio-rvs-iohub-device.model';
import { IAutomationFetchDevicesPayload } from '../../ts/models/automation-fetch-devices-payload.model';

@Injectable({
	providedIn: 'root'
})
export class AutomationDeviceSessionHandlerService {

	constructor(
		private rioRvsService: RioRvsService,
		private rioRvsImageService: RioRvsImageService,
		private automationNgrxService: AutomationNgrxService,
		private automationDeviceService: AutomationDeviceService,
		private automationDeviceClassService: AutomationDeviceClassService,
		private automationDeviceFormatterService: AutomationDeviceFormatterService
	) { }

	handleSessionDeviceChange(response: [IRioRvsIohubDevice[], IAutomationMapDevice[]]): void {
		const [sessionChanges, devicesUsedByMap] = response;

		if (sessionChanges?.length) {
			const updatedMapDevices = this.updateSessionChangesData(sessionChanges, devicesUsedByMap);

			this.automationNgrxService.updateDevicesUsedByMap(updatedMapDevices);
		}
	}

	updateSessionChangesData(sessionChanges: IRioRvsIohubDevice[], devicesUsedByMap: IAutomationMapDevice[]): IAutomationMapDevice[] {
		const sessionLineDevices = this.flatSessionChangesValues(this.formatSessionDevices(sessionChanges));

		return devicesUsedByMap.map(lineDevice => {
			const resourceIdentifier = lineDevice.resourceIdentifier as string;
			const [device] = AutomationGenericService.splitAutomationItemByComma(resourceIdentifier);
			const [deviceName, deviceNumber] = AutomationGenericService.splitAutomationItemByDash(device);
			const lineDeviceId = deviceName + '-' + deviceNumber;

			const matchingSessionLineDevice = this.getMatchingSessionLineDevice(sessionLineDevices, lineDevice, lineDeviceId);

			if (matchingSessionLineDevice) {
				const { value: sessionLineDeviceValue } = matchingSessionLineDevice;
				const { originalDeviceType, imagePath } = lineDevice as { originalDeviceType: AutomationLineDevice; imagePath: string };

				const status = this.rioRvsImageService.convertValueToStatus(sessionLineDeviceValue.toString() as RioRvsDeviceValue);
				const deviceImagePath = this.automationDeviceService.setImageBasedOnValue(sessionLineDeviceValue, imagePath);
				const newTopPosition = lineDevice.hasOwnProperty('newTopPosition') ? lineDevice.newTopPosition : lineDevice.top;
				const newLeftPosition = lineDevice.hasOwnProperty('newLeftPosition') ? lineDevice.newLeftPosition : lineDevice.left;

				const clickEnabled = this.automationDeviceService.isClickEnabled(originalDeviceType, sessionLineDeviceValue);
				const updatedDevice = { ...lineDevice, value: sessionLineDeviceValue, status, imagePath: deviceImagePath, clickEnabled };
				const tooltipDescription = this.automationDeviceService.formatTooltipDescription(updatedDevice, resourceIdentifier);

				return { ...updatedDevice, newTopPosition, newLeftPosition, tooltipDescription };
			}

			return lineDevice;
		});
	}

	flatSessionChangesValues(sessionDevices: IAutomationDevice[]): IAutomationMapDevice[] {
		return sessionDevices.reduce((accumulator, sessionDevice) => {
			const { type, num, devices } = sessionDevice;
			const lineDevices = devices.map(lineDevice => ({ ...lineDevice, sessionLineDeviceId: type + '-' + num }));

			return [...accumulator, ...lineDevices];
		}, [] as IAutomationMapDevice[]);
	}

	getMatchingSessionLineDevice(
		sessionChangesLineDevices: IAutomationMapDevice[], lineDevice: IAutomationMapDevice, lineDeviceId: string
	): IAutomationMapDevice | undefined {
		return sessionChangesLineDevices.find(sessionLineDevice => {
			const { deviceType, num, sessionLineDeviceId } = sessionLineDevice;

			return deviceType === lineDevice.deviceType && num === lineDevice.num && sessionLineDeviceId === lineDeviceId;
		});
	}

	formatSessionDevices(ioSessionData: IRioRvsIohubDevice[]): IAutomationDevice[] {
		return ioSessionData.reduce((accumulator, sessionItem) => {
			const { num, type, status, statusdesc, description } = sessionItem;

			if (status !== 2) {
				const isUp = status === 1;
				const id = `${type}-${num}`;
				const devicesMapFormat = this.automationDeviceFormatterService.formatDeviceToMapFormat(sessionItem);
				const devices = this.updateSessionDeviceProperties(devicesMapFormat, sessionItem);

				return [...accumulator, { num, type, description, isUp, statusdesc, devices, id }];
			}

			return accumulator;
		}, [] as IAutomationDevice[]);
	}

	updateSessionDeviceProperties(lineDevices: IAutomationMapDevice[], sessionItem: IRioRvsIohubDevice): IAutomationMapDevice[] {
		return lineDevices.reduce((accumulator, lineDevice) => {
			const originalDeviceType = lineDevice.originalDeviceType as AutomationLineDevice;
			const deviceName = this.rioRvsService.getDeviceMainClass(originalDeviceType, false);

			if (deviceName) {
				const checkboxState = false;
				const port = `${deviceName}-${lineDevice.num}`;
				const device = `${sessionItem.type}-${sessionItem.num}`.toUpperCase();
				const deviceType = this.automationDeviceClassService.translateDeviceClassToCustomType(originalDeviceType);
				const resourceIdentifier = this.automationDeviceService.getResourceIdentifier(
					originalDeviceType, lineDevice.num, sessionItem.type, sessionItem.num
				);
				const fullDeviceName = this.automationDeviceService.getFullLineDeviceName(resourceIdentifier);

				return [...accumulator, {
					...lineDevice, deviceName: device, originalDeviceType, deviceType, port, fullDeviceName, checkboxState
				}] as IAutomationMapDevice[];
			}

			return accumulator;
		}, [] as IAutomationMapDevice[]);
	}

	getAllDevices(ioSessionData: IRioRvsIohubDevice[] | null): IAutomationDevice[] {
		return ioSessionData && ioSessionData.length ? this.formatSessionDevices(ioSessionData) : [];
	}

	setInitialSessionDevices(payload: IAutomationFetchDevicesPayload): void {
		const { mapNumber, ioSessionData, designMapMode, iconsAndMapDevices } = payload;

		const allDevices = this.getAllDevices(ioSessionData);
		const allMapDevices = this.automationDeviceFormatterService.getAllMapDevices(allDevices, iconsAndMapDevices);

		this.automationNgrxService.setAllDevices(allDevices);
		this.automationNgrxService.setDevicesUsedByMap(allMapDevices);
		this.automationNgrxService.setSessionChanges(mapNumber, designMapMode);
	}

	updateAvailableDevices(ioSessionData: IRioRvsIohubDevice[], devicesUserByMap: IAutomationMapDevice[]): void {
		const allDevices = this.updateDeviceCheckboxes(this.getAllDevices(ioSessionData), devicesUserByMap);

		this.automationNgrxService.setAllDevices(allDevices);
	}

	updateDeviceCheckboxes(newDevices: IAutomationDevice[], devicesUserByMap: IAutomationMapDevice[]): IAutomationDevice[] {
		return newDevices.map(device => {
			const updatedDevices = device.devices.map(newLineDevice => {
				const newDeviceId = newLineDevice.fullDeviceName;
				const isDeviceMatching = devicesUserByMap.find(lineDevice => lineDevice.fullDeviceName === newDeviceId);

				return { ...newLineDevice, checkboxState: !!isDeviceMatching };
			});

			return { ...device, devices: updatedDevices };
		});
	}
}
