import { createAction, props } from '@ngrx/store';
import { IPreferencesStore } from '../ts/models/preferences-store.model';
import { IPreferencesSavePayload } from '../ts/models/preferences-save-payload.model';
import { VirtualOfficeSide } from '../../virtual-office/ts/enums/virtual-office-side.enum';

export const fetchPreferencesAction = createAction(
	'[Preferences] Fetch Action Started',
	props<{ payload: { username: string } }>()
);

export const fetchingPreferencesCompletedAction = createAction(
	'[Preferences] Fetch  Done Action',
	props<{ payload: { preferences: IPreferencesStore } }>()
);

export const saveNewNetUserNumber = createAction(
	'[Preferences] Save New Net/User Number',
	props<{ payload: { user: string; phoneNumber: string; side: VirtualOfficeSide } }>()
);

export const setUserPreferencesAction = createAction(
	'[Preferences] Set User Preferences Action',
	props<{ payload: { savePayload: IPreferencesSavePayload; fetchPreferences: boolean } }>()
);

export const setLoadingPreferencesStateAction = createAction(
	'[Preferences] Set Loading Preferences Action',
	props<{ payload: { isLoadingPreferences: boolean } }>()
);

export const clearPreferencesStoreAction = createAction(
	'[Preferences] Clear Preferences Store Action'
);

export const cancelPreferencesHTTPObservablesAction = createAction(
	'[Preferences] Cancel Preferences HTTP Observables Action'
);
