import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, filter, map, switchMap, tap } from 'rxjs';
import { VirtualOfficeService } from './virtual-office.service';
import { environment } from '../../../../environments/environment';
import { VirtualOfficeSide } from '../ts/enums/virtual-office-side.enum';
import { VirtualOfficeNgrxService } from './virtual-office-ngrx.service';
import { IVirtualOfficeTableItem } from '../ts/models/virtual-office-table-item.model';
import { IVirtualOfficeResponse } from '../ts/models/virtual-office-get-response.model';
import { IVirtualOfficeSystemForm } from '../ts/models/virtual-office-system-form.model';
import { IVirtualOfficeConfResponse } from '../ts/models/virtual-office-conf-response.model';
import { IVirtualOfficeActionResponse } from '../ts/models/virtual-office-action-response.model';
import * as customOperators from '../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class VirtualOfficeHttpService {

	private readonly setSystemFormUrl: string = environment.abilisUrl + '/sys/admin/vo/setconf.json';
	private readonly saveSystemFormUrl: string = environment.abilisUrl + '/sys/admin/vo/save_conf.json';
	private readonly getVirtualOfficeUsersUrl: string = environment.abilisUrl + '/sys/admin/vo/get.json';
	private readonly addVirtualOfficeNumberUrl: string = environment.abilisUrl + '/sys/admin/vo/add.json';
	private readonly getVirtualOfficeConfUrl: string = environment.abilisUrl + '/sys/admin/vo/getconf.json';

	private voUuid: string;
	private systemUuid: string;

	constructor(
		private readonly http: HttpClient,
		private readonly virtualOfficeService: VirtualOfficeService,
		private readonly virtualOfficeNgrxService: VirtualOfficeNgrxService
	) { }

	// HTTP

	public fetchVirtualOfficeUsers$(): Observable<IVirtualOfficeTableItem[]> {
		return this.http.get<IVirtualOfficeResponse>(this.getVirtualOfficeUsersUrl)
			.pipe(
				map(response => {
					const { CtivoNumbers, Uuid } = response.Response;
					const userNumbers = this.virtualOfficeService.formatVirtualOfficeUserNumbers(CtivoNumbers);
					this.virtualOfficeNgrxService.finishLoadingUserNumbers(userNumbers);

					this.setUuid(Uuid);

					return this.virtualOfficeService.formatVirtualOfficeUserData(CtivoNumbers);
				}),
				customOperators.retryFromError$(5000)
			);
	}

	public fetchVirtualOfficeConfiguration$(): Observable<IVirtualOfficeSystemForm> {
		return this.http.get<IVirtualOfficeConfResponse>(this.getVirtualOfficeConfUrl)
			.pipe(
				tap(response => this.setSystemUuid(response.Response.Res.Uuid)),
				map(response => {
					const { 'PS-NUM': psNum, 'CB-NUM': cbNum, 'CB-CGO-NUM': cbCgo } = response.Response.Res;

					return { 'PS-NUM': psNum, 'CB-NUM': cbNum, 'CB-CGO-NUM': cbCgo };
				}),
				customOperators.retryFromError$(5000)
			);
	}

	public addVirtualOfficeNumber$(user: string, num: string, side: VirtualOfficeSide): Observable<boolean> {
		const payload = { Uuid: this.voUuid, user, num, side, current: true };

		return this.http.post<IVirtualOfficeActionResponse>(this.addVirtualOfficeNumberUrl, payload)
			.pipe(
				map(response => this.virtualOfficeService.handleActionResponse(response, this.setUuid.bind(this)))
			);
	}

	public setSystemForm$(systemForm: IVirtualOfficeSystemForm): Observable<IVirtualOfficeActionResponse> {
		const payload = { ...systemForm, uuid: this.systemUuid, res: 'vo' };

		return this.http.post<IVirtualOfficeActionResponse>(this.setSystemFormUrl, payload)
			.pipe(
				map(response => this.virtualOfficeService.handleActionResponse(response, this.setUuid.bind(this))),
				filter(isActionOk => isActionOk),
				switchMap(() => {
					this.virtualOfficeService.emitSetFormInitialValuesEvent();

					return this.saveVoConfigPermanently$();
				})
			);
	}

	private saveVoConfigPermanently$(): Observable<IVirtualOfficeActionResponse> {
		return this.http.post<IVirtualOfficeActionResponse>(this.saveSystemFormUrl, {});
	}

	// ACTION

	public setUuid(passedUuid: string | undefined): void {
		if (!passedUuid) { return; }

		this.voUuid = passedUuid;
	}

	private setSystemUuid(systemUuid: string): void {
		this.systemUuid = systemUuid;
	}
}
