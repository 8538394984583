import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../../../root-reducers';
import { TranslateService } from '@ngx-translate/core';
import { IAutomationMap } from '../../ts/models/automation-map.model';
import { GlobalService } from '../../../../services/others/global.service';
import { IAutomationDevice } from '../../ts/models/automation-device.model';
import { AutomationStoreKey } from '../../ts/enums/automation-store-key.enum';
import { IAutomationMapDevice } from '../../ts/models/automation-map-device.model';
import { AutomationMapDeviceStoreKey } from '../../ts/enums/automation-map-device-store-key.enum';
import { IAutomationMapTemporaryDescription } from '../../ts/models/automation-map-temporary-description.model';
import { IRioRvsMultipleEntriesResponse } from '../../../rio-rvs/ts/models/rio-rvs-save-multiple-entries-response.model';
import * as automationSelectors from '../../ngrx/automation-selector';
import * as automationActions from '../../ngrx/automation.action';

@Injectable({
	providedIn: 'root'
})
export class AutomationNgrxService {

	constructor(
		private store: Store<IAppState>,
		private translate: TranslateService,
		private globalService: GlobalService
	) { }

	selectFromAutomationStore$<T>(selector: AutomationStoreKey): Observable<T> {
		return this.store.pipe(select(automationSelectors.selectFromAutomationStore(selector))) as unknown as Observable<T>;
	}

	selectFromAutomationMapDevicesStore$<T>(selector: AutomationMapDeviceStoreKey): Observable<T> {
		return this.store.pipe(select(automationSelectors.selectFromAutomationMapDevicesStore(selector))) as unknown as Observable<T>;
	}

	selectAutomationMaps$(): Observable<IAutomationMap[]> {
		return this.store.pipe(select(automationSelectors.selectAutomationMaps()));
	}

	selectCurrentlyUsedAutomationMap$(): Observable<IAutomationMap | null> {
		return this.store.pipe(select(automationSelectors.selectCurrentlyUsedMap));
	}

	selectAllDevicesUsedByMap$(): Observable<IAutomationMapDevice[]> {
		return this.store.pipe(select(automationSelectors.selectAllDevicesUsedByMap()));
	}

	selectAllAutomationDevices$(): Observable<IAutomationDevice[]> {
		return this.store.pipe(select(automationSelectors.selectAutomationDevices()));
	}

	selectTemporaryDescriptions$(): Observable<IAutomationMapTemporaryDescription[]> {
		return this.store.pipe(select(automationSelectors.selectTemporaryDescriptions()));
	}

	fillAutomationMaps(selectedMapNumber: number): void {
		const payload = { selectedMapNumber };

		this.store.dispatch(automationActions.fillAutomationMaps({ payload }));
	}

	loadMapTableOverview(): void {
		this.store.dispatch(automationActions.loadMapTableOverview({ payload: { selectedMapNumber: null } }));
	}

	setSelectedAutomationMapNumber(automationMapNumber: number): void {
		const payload = { automationMapNumber };

		this.store.dispatch(automationActions.setSelectedAutomationMapNumber({ payload }));
	}

	deleteSelectedAutomationMap(automationMapNumber: number): void {
		const okCallback = () => {
			const payload = { automationMapNumber };

			this.store.dispatch(automationActions.deleteSelectedMap({ payload }));
		};

		this.globalService.confirm(this.translate.instant('AUTO.MAP.deleteConfirm'), okCallback);
	}

	setAvailableMapNumbers(automationMaps: IAutomationMap[]): void {
		const availableMapNumbers = automationMaps.map(automationMap => automationMap.mapNumber) as number[];
		const payload = { availableMapNumbers };

		this.store.dispatch(automationActions.setAvailableMapNumbers({ payload }));
	}

	cancelAutomationObservables(): void {
		this.store.dispatch(automationActions.cancelAutomationObservablesAction());
	}

	cancelMapsHttpObservables(): void {
		this.store.dispatch(automationActions.cancelMapsHttpObservables());
	}

	fetchAvailableDevices(mapNumber: number, designMapMode: boolean): void {
		const payload = { mapNumber, designMapMode };

		this.store.dispatch(automationActions.fetchAvailableDevicesAction({ payload }));
	}

	setDevicesUsedByMap(devicesUsedByMap: IAutomationMapDevice[]): void {
		const payload = { devicesUsedByMap };

		this.store.dispatch(automationActions.finishLoadingDevicesUsedByMap({ payload }));
	}

	setAllDevices(allDevices: IAutomationDevice[]): void {
		const payload = { allDevices };

		this.store.dispatch(automationActions.setAllDevices({ payload }));
	}

	setSessionChanges(mapNumber: number, designMapMode: boolean): void {
		const payload = { mapNumber, designMapMode };

		this.store.dispatch(automationActions.setSessionChangesAction({ payload }));
	}

	updateDevicesUsedByMap(devicesUsedByMap: IAutomationMapDevice[]): void {
		const payload = { devicesUsedByMap };

		this.store.dispatch(automationActions.updateDevicesUsedByMap({ payload }));
	}

	fetchDeviceSessionStatus(mapNumber: number, designMapMode: boolean): void {
		const payload = { mapNumber, designMapMode };

		this.store.dispatch(automationActions.fetchDevicesSessionStatus({ payload }));
	}

	setSelectedAutomationMap(automationMap: IAutomationMap): void {
		const payload = { automationMap };

		this.store.dispatch(automationActions.setSelectedAutomationMap({ payload }));
	}

	setSwitchControl(device: IAutomationMapDevice, selectedMapNumber: number, analogValue?: number): void {
		const payload = { device, selectedMapNumber, analogValue };

		this.store.dispatch(automationActions.setSwitchControl({ payload }));
	}

	updateSingleDeviceOnMap(deviceUsedByMap: IAutomationMapDevice): void {
		const payload = { deviceUsedByMap };

		this.store.dispatch(automationActions.updateDeviceUsedByMap({ payload }));
	}

	setAllDevicesLoadingFlag(isLoading: boolean): void {
		const payload = { isLoading };

		this.store.dispatch(automationActions.setAllDevicesLoadingFlag({ payload }));
	}

	fetchLiveBackgroundOutputs(): void {
		this.store.dispatch(automationActions.fetchLiveBackgroundOutputs());
	}

	destroySessionId(): void {
		this.store.dispatch(automationActions.destroyAutomationSessionId());
	}

	removeAllAutomationMaps(): void {
		this.store.dispatch(automationActions.removeAllAutomationMaps());
	}

	addTemporaryDeviceDescription(mapDevice: IAutomationMapDevice, request$: Observable<IRioRvsMultipleEntriesResponse>): void {
		const payload = { descriptionPayload: { id: mapDevice.id as string, request: request$ } };

		this.store.dispatch(automationActions.addTemporaryLineDeviceDescription({ payload }));
	}

	removeAllTemporaryDevicesDescriptions(): void {
		this.store.dispatch(automationActions.removeAllTemporaryDescriptions());
	}

	updateBackgroundImageId(): void {
		this.store.dispatch(automationActions.updateNewBackgroundImageId());
	}

	loadDevicesFromModal(mapNumber: number, selectedDevices: IAutomationMapDevice[]): void {
		const payload = { mapNumber, selectedDevices };

		this.store.dispatch(automationActions.loadDevicesFromModal({ payload }));
	}

	saveMapForAbilis(mapNumber: number, newMapSelected: boolean): void {
		const payload = { mapNumber, newMapSelected };

		this.store.dispatch(automationActions.saveMapForAbilis({ payload }));
	}

	saveDeviceTemporaryDescriptions(): void {
		this.store.dispatch(automationActions.setDeviceDescriptions());
	}

	removeCurrentlyUsedMap(): void {
		this.store.dispatch(automationActions.removeCurrentlyUsedMap());
	}

	finishLoadingAutomationMaps(automationMaps: IAutomationMap[]): void {
		const payload = { automationMaps };

		this.store.dispatch(automationActions.finishMapLoadingAction({ payload }));
	}

	setDevicesCheckboxState(selectedLineDevices: string[]): void {
		const payload = { selectedLineDevices };

		this.store.dispatch(automationActions.setSelectedLineDevices({ payload }));
	}

	updateAvailableDevices(mapNumber: number, designMapMode: boolean): void {
		const payload = { mapNumber, designMapMode };

		this.store.dispatch(automationActions.updateAvailableDevices({ payload }));
	}
}
