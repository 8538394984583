export enum RecentCallResult {
	ANSWERED = 'Answered',
	VOICEMAIL = 'VoiceMail',
	SEIZED = 'Seized',
	BUSY = 'Busy',
	NOANSWER = 'NoAnswer',
	ABORTED = 'Aborted',
	UNEREACHABLE = 'Unreachable',
	FORWARDED = 'Forwarded',
	NOT_AVAILABLE = 'NotAvailable'
}
