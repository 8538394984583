import { createAction, props } from '@ngrx/store';
import { IActuator } from '../ts/models/automation-program-edit-actuator.model';
import { IAutomationProgramEdit } from '../ts/models/automation-program-edit.model';
import { IAutomationDevice } from '../../automation/ts/models/automation-device.model';
import { IProjectOptions } from '../ts/models/automation-program-edit-project-options.model';
import { AutomationProgramEditStoredData } from '../ts/types/automation-program-edit-stored-data.type';
import { IAutomationProgramEditLineDevice } from '../ts/models/automation-program-edit-line-device.model';
import { AutomationProgramProjectTimeout } from '../ts/enums/automation-program-edit-project-timeout.enum';
import { IAutomationProgramVoutDescription } from '../ts/models/automation-program-vout-description.model';
import { INewAutomationProgramEditInputValue } from '../ts/models/automation-program-edit-new-input-value.model';

export const startLoadingAutomationPrograms = createAction(
	'[Automation Program Edit] Load JSON Automation Programs Request'
);

export const automationProgramsLoaded = createAction(
	'[Automation Program Edit] Finish Automation Programs Loading',
	props<{ payload: { automationPrograms: IAutomationProgramEdit[] } }>()
);

export const automationProgramsFetchOnce = createAction(
	'[Automation Program Edit] Fetch Automation Programs Once',
	props<{ payload: { programName: string } }>()
);

export const cancelAutomationProgramObservables = createAction(
	'[Automation Program Edit] Cancel Automation Program HTTP Observables'
);

export const clearAutomationProgramTableObservables = createAction(
	'[Automation Program Edit] Clear Automation Program Edit Table Observables'
);

export const exportJsonTable = createAction(
	'[Automation Program Edit] Export JSON table',
	props<{ payload: { filePath: string } }>()
);

export const setProjectLineDevices = createAction(
	'[Automation Program Edit] Set Project Line Devices',
	props<{ payload: { lineDevices: IAutomationProgramEditLineDevice[]; scriptName?: string } }>()
);

export const updateProjectLineDevices = createAction(
	'[Automation Program Edit] Update Project Line Devices',
	props<{ payload: { lineDevices: IAutomationProgramEditLineDevice[] } }>()
);

export const deleteAutomationProgram = createAction(
	'[Automation Program Edit] Delete Automation Program',
	props<{ payload: { automationProgram: string; areSheets: boolean } }>()
);

export const setProgramDeleteState = createAction(
	'[Automation Program Edit] Set Automation Program Delete State',
	props<{ payload: { automationProgram: IAutomationProgramEdit } }>()
);

export const saveAutomationProgramProjectOptions = createAction(
	'[Automation Program Edit] Save Automation Program Project Options',
	props<{ payload: { automationProgramName: string; projectOptions: IProjectOptions } }>()
);

export const saveAutomationProgramContent = createAction(
	'[Automation Program Edit] Save Automation Program Content',
	props<{ payload: { automationProgram: string; programContent: string } }>()
);

export const setAutomationProgramAutostart = createAction(
	'[Automation Program Edit] Set Automation Program Autostart',
	props<{ payload: { programName: string; autostart: boolean } }>()
);

export const importAutomationProgram = createAction(
	'[Automation Program Edit] Import Automation Program',
	props<{ payload: { scriptName: string; storedData: AutomationProgramEditStoredData } }>()
);

export const loadAutomationProgramTable = createAction(
	'[Automation Program Edit] Load Automation Program Table',
	props<{ payload: { automationProgramName: string } }>()
);

export const startStopAutomationProgram = createAction(
	'[Automation Program Edit] Start/Stop Automation Program',
	props<{ payload: { programName: string; startProgram: boolean } }>()
);

export const loadLineDevices = createAction(
	'[Automation Program Edit] Load Line Devices'
);

export const fetchModalLineDevices = createAction(
	'[Automation Program Edit] Fetch Modal Line Devices',
	props<{ payload: { successCallback?: () => void } }>()
);

export const finishLoadingModalDevices = createAction(
	'[Automation Program Edit] Line Devices Loaded',
	props<{ payload: { modalDevices: IAutomationDevice[] } }>()
);

export const setAutomationProgramOptions = createAction(
	'[Automation Program Edit] Set Automation Program Options',
	props<{ payload: { ProjectTimeout: number; ProjectTimeoutUnit: AutomationProgramProjectTimeout } }>()
);

export const addActuator = createAction(
	'[Automation Program Edit] Add Actuator',
	props<{ payload: { actuator: IActuator } }>()
);

export const finishActuatorsLoading = createAction(
	'[Automation Program Edit] Finish Actuators Loading',
	props<{ payload: { actuators: IActuator[] } }>()
);

export const addNewInputValue = createAction(
	'[Automation Program Edit] Add New Input Value',
	props<{ payload: { newInput: INewAutomationProgramEditInputValue } }>()
);

export const updateNewInputValue = createAction(
	'[Automation Program Edit] Update New Input Value',
	props<{ payload: { newInput: INewAutomationProgramEditInputValue } }>()
);

export const setPreselectedInputValues = createAction(
	'[Automation Program Edit] Set Preselected Input Values',
	props<{ payload: { customInputs: INewAutomationProgramEditInputValue[] } }>()
);

export const addNewVout = createAction(
	'[Automation Program Edit] Add New Vout'
);

export const deleteVout = createAction(
	'[Automation Program Edit] Delete Vout',
	props<{ payload: { vout: IAutomationProgramEditLineDevice } }>()
);

export const removeActuator = createAction(
	'[Automation Program Edit] Delete Actuator',
	props<{ payload: { actuator: IAutomationProgramEditLineDevice } }>()
);

export const clearAutomationProgramStore = createAction(
	'[Automation Program Edit] Clear Automation Program Store'
);

export const saveProject = createAction(
	'[Automation Program Edit] Save Project',
	props<{ payload: { initialProgramName: string; newProgramName: string } }>()
);

export const updateDevicePositionOnDragDrop = createAction(
	'[Automation Program Edit] Update Device Position Drag Drop',
	props<{ payload: { currentIndex: number; previousIndex: number } }>()
);

export const setSelectedLineDevices = createAction(
	'[Automation Program Edit] Set Selected Checkbox Line Devices',
	props<{ payload: { selectedLineDevices: string[] } }>()
);

export const setDeviceData = createAction(
	'[Automation Program Edit] Set Device',
	props<{ payload: { device: IAutomationProgramEditLineDevice; description: string } }>()
);

export const updateDeviceDescription = createAction(
	'[Automation Program Edit] Update Device Description',
	props<{ payload: { description: IAutomationProgramVoutDescription } }>()
);

export const removeAllDescriptions = createAction(
	'[Automation Program Edit] Remove All Description'
);

export const fillAutomationProgram = createAction(
	'[Automation Program Edit] Fill Automation Program',
	props<{ payload: { programName: string } }>()
);

