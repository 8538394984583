import { Injectable, inject } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { AddressBookService } from '../address-book.service';
import { environment } from '../../../../../../environments/environment';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { AddressBookHttpUtilService } from './address-book-http-util.service';
import { IAddressBookContact } from '../../ts/models/address-book-contact.model';
import { AddressBookNgrxSelectorService } from '../ngrx/address-book-ngrx-selector.service';
import { IAddressBookFetchResponse } from '../../ts/models/address-book-fetch-response.model';
import { AddressBookCsvUploadService } from './../import-export/address-book-csv-upload.service';
import * as customOperators from '../../../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class AddressBookHttpService {

	private readonly http: HttpClient;

	public readonly contactsUrl: string = environment.abilisUrl + '/sys/user/addrbook_query.json';
	private readonly importCsvUrl: string = environment.abilisUrl + '/sys/user/addrbook_import.json';

	private readonly importAdminCsvUrl: string = environment.abilisUrl + '/sys/admin/users/addrbook_import.json';
	public readonly contactsAdminUrl: string = environment.abilisUrl + '/sys/admin/users/user_addrbook_query.json';

	constructor(
		private readonly addressBookService: AddressBookService,
		private readonly addressBookHttpUtilService: AddressBookHttpUtilService,
		private readonly addressBookCsvUploadService: AddressBookCsvUploadService,
		private readonly addressBookNgrxSelectorService: AddressBookNgrxSelectorService
	) {
		this.http = inject(HttpClient);
	}

	/**
	* Retrieves Address book contacts for passed username
	* @param {string} username Owner of Address Book
	*/
	public fetchContacts$(username: string): Observable<IAddressBookContact[]> {
		return this.http.get<IAddressBookFetchResponse>(this.getFetchContactUrl + `?user=${username}`)
			.pipe(
				switchMap(response => forkJoin({
					addressBookResponse: of(response),
					addressBookContacts: this.addressBookNgrxSelectorService.selectAddressBookContacts$().pipe(take(1))
				})),
				map(response => this.addressBookHttpUtilService.handleContactsResponse(response)),
				customOperators.retryFromError$(1000)
			);
	}

	/**
	* Handles import of CSV
	* @param {string} username Owner of Address Book
	* @param {Event} file CSV file
	*/
	public importCsv$(username: string, file: Event): HttpRequest<FormData> {
		const [name, fileData] = this.getFileData(file);
		const formData = new FormData();

		formData.append('user', username);
		formData.append(name, fileData, fileData.name);

		return new HttpRequest('POST', this.getImportCsvUrl, formData, { reportProgress: true });
	}

	/**
	* Sends HTTP tracking request with progress of upload CSV
	* @param {string} username Owner of Address Book
	*/
	public fetchUploadCsvState$(username: string): Observable<HttpEvent<any>> {
		const file = this.addressBookCsvUploadService.getFile();

		return !file ? EMPTY : this.http.request(this.importCsv$(username, file));
	}

	/**
	* Gets fetch Address Book URL
	*/
	public get getContactsUrl(): string {
		return this.contactsUrl;
	}

	/**
	* Gets admin fetch Address Book URL
	*/
	public get getContactsAdminUrl(): string {
		return this.contactsAdminUrl;
	}

	/**
	* Gets name of the imported file and file data
	* @param {Event} file Imported file data
	*/
	private getFileData(file: Event): [string, File] {
		const { name, files } = file.target as HTMLInputElement;
		const [fileData] = files as unknown as File[];

		return [name, fileData];
	}

	/**
	* Gets appropriate import CSV URL
	*/
	private get getImportCsvUrl(): string {
		const isAdminBook = this.addressBookService.checkIfAdminAddressBook();

		return !isAdminBook ? this.importCsvUrl : this.importAdminCsvUrl;
	}

	/**
	* Gets appropriate fetch contacts URL
	*/
	private get getFetchContactUrl(): string {
		const isAdminBook = this.addressBookService.checkIfAdminAddressBook();

		return !isAdminBook ? this.getContactsUrl : this.getContactsAdminUrl;
	}
}
