<div class="content" [ngStyle]="{width: !isSearchVisible ? '20px' : null}">
	<div class="content__bar">
		<img src="assets/feather-icons/search.svg" class="icon" alt="search">

		<app-custom-input [bottomSpace]="false" [passedFormControl]="searchControl" [whiteText]="true" #customInputElement
			*ngIf="isSearchVisible">
		</app-custom-input>
	</div>

	<img src="assets/feather-icons/x.svg" class="icon" alt="x" (click)="onResetSearch()">
</div>
