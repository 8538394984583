import { Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { AuthNgrxService } from './auth-ngrx.service';
import { TranslateService } from '@ngx-translate/core';
import { RoutesEnum } from '../../../ts/enums/routes.enum';
import { LoginStatus } from '../ts/enums/login-status.enum';
import { ToastrType } from '../../../ts/enums/toastr-type.enum';
import { ILoginResponse } from '../ts/models/login-response.model';
import { MainUtilService } from '../../../services/main-util.service';
import { ILoginVerification } from '../ts/models/login-verification-data.model';

@Injectable({
	providedIn: 'root'
})
export class AuthHttpUtilService {

	private readonly router: Router;
	private readonly translate: TranslateService;

	constructor(
		private readonly authNgrxService: AuthNgrxService,
		private readonly mainUtilService: MainUtilService
	) {
		this.router = inject(Router);
		this.translate = inject(TranslateService);
	}

	public handleLoginResponse(response: ILoginResponse, verificationCodeLogin: boolean): void {
		switch(response.Response.status) {
		case LoginStatus.OK:
			this.authNgrxService.dispatchLoginAction();
			break;
		case LoginStatus.UNABLE_TO_EXECUTE:
			const errorText = this.getLoginExecutionErrorTranslation(verificationCodeLogin);

			this.mainUtilService.showToastrMessage(this.translate.instant(errorText), ToastrType.ERROR);
			break;
		case LoginStatus.LOGIN_SESSION_EXPIRED:
			this.mainUtilService.showToastrMessage(this.translate.instant('LOGIN.sessionExpired'), ToastrType.ERROR);
			this.router.navigateByUrl(RoutesEnum.LOGIN);
			break;
		case LoginStatus.VERIFICATION_CODE_REQUESTED:
			this.authNgrxService.setVerificationData(this.getVerificationCodeData(response));
			this.router.navigateByUrl(`/${RoutesEnum.LOGIN}/${RoutesEnum.VERIFICATION}`);
			break;
		}
	}

	private getLoginExecutionErrorTranslation(verificationCodeLogin: boolean): string {
		return verificationCodeLogin ? 'LOGIN.verificationCodeWrong' : 'LOGIN.usernamePassErr';
	}

	private getVerificationCodeData(response: ILoginResponse): ILoginVerification {
		const { username, savedurl, password, login_sid, login_method, sent_by } = response.Response;

		return { username, savedurl, password, login_sid, login_method, sent_by };
	}
}
