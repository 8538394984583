import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { RoutesEnum } from '../../../../../ts/enums/routes.enum';
import { RecentCallRouter } from '../../ts/enums/recent-call-router.enum';
import { RecentCallModeClass } from '../../classes/recent-call-mode.class';

@Injectable({
	providedIn: 'root'
})
export class RecentCallAllModeService extends RecentCallModeClass {

	constructor(
		private readonly router: Router
	) {
		super();
	}

	/**
	 * Routes to add mode of logged user, in ALL mode
	 */
	public openAddForm(): void {
		this.router.navigateByUrl(this.constructAllModeUrl);
	}

	/**
	 * Constructs URL to route to all mode
	 */
	private get constructAllModeUrl(): string {
		const recentCalls = RoutesEnum.ALL_RECENT_CALLS;
		const administration = RoutesEnum.ADMINISTRATION;

		return `/${administration};replaceUrl=true/(${recentCalls}:${recentCalls}/${RecentCallRouter.ADD})`;
	}
}
