import { Injectable } from '@angular/core';
import { IAddressBookContact } from '../../ts/models/address-book-contact.model';
import { AddressBookContactArrayService } from '../address-book-contact-array.service';
import { AddressBookTableUtilService } from './../table/address-book-table-util.service';
import { IAddressBookContactsResponse } from '../../ts/models/address-book-contacts-response.model';

@Injectable({
	providedIn: 'root'
})
export class AddressBookHttpUtilService {

	constructor(
		private readonly addressBookContactArrayService: AddressBookContactArrayService
	) { }

	/**
	* Gets contact by provided number
	* @param {IAddressBookContact[]} contacts Address Book contacts
	* @param {string} contactNumber Number to match
	* @param {IAddressBookContact | null} selectedContact Selected Address Book contact (if edit mode), or null (if add new contact mode)
	*/
	public getContactByNumber(
		contacts: IAddressBookContact[], contactNumber: string, selectedContact: IAddressBookContact | null
	): IAddressBookContact | null {
		if (!selectedContact) {
			return this.addressBookContactArrayService.getContactByValue(contacts, contactNumber, 'num');
		}

		const { name } = selectedContact;

		return this.addressBookContactArrayService.getContactBySameNumberName(contacts, contactNumber, name);
	}

	/**
	* Retrieves Address book contacts, from HTTP GET response
	* @param {IAddressBookContactsResponse} response Response which contains:
		1. addressBookContacts - Already existing Address Book Contacts
		2. addressBookResponse - HTTP GET response
	*/
	public handleContactsResponse(response: IAddressBookContactsResponse): IAddressBookContact[] {
		const { addressBookContacts, addressBookResponse } = response;
		const { Addressbook, rsp } = addressBookResponse.Response;

		AddressBookTableUtilService.setResponseErrorMessage(rsp);

		if (!Addressbook) { return []; }

		return this.addressBookContactArrayService.formatAddressBookContacts(Addressbook, addressBookContacts);
	}
}
