import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ICamera } from '../../../../components/videorecorder/ts/models/camera.model';
import * as automationActions from '../automation.action';

export const automationCameraFeatureKey = 'automationCamera';

export type IAutomationAvailableCamerasState = EntityState<ICamera>;

export const adapter: EntityAdapter<ICamera> = createEntityAdapter<ICamera>();

export const initialAutomationCamera: IAutomationAvailableCamerasState = adapter.getInitialState();

const automationWorkingCamerasReducer = createReducer(
	initialAutomationCamera,
	on(automationActions.setAllWorkingCameras, (state, { payload }) => adapter.setAll(payload.workingCameras, { ...state })),
	on(automationActions.removeAllAvailableCameras, (state) => adapter.removeAll({ ...state, isLoading: true }))
);

export function AutomationWorkingCamerasReducer(state: IAutomationAvailableCamerasState | undefined, action: Action) {
	return automationWorkingCamerasReducer(state, action);
}

export const { selectAll: selectAllAvailableCameras } = adapter.getSelectors();
