import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ValidationErrors } from '@angular/forms';
import { Injectable, inject } from '@angular/core';
import { AddressBookService } from '../address-book.service';
import { AddressBookHttpService } from './address-book-http.service';
import { environment } from '../../../../../../environments/environment';
import { IAddressBookContact } from '../../ts/models/address-book-contact.model';
import { AddressBookContactData } from '../../ts/types/address-book-contact-data.type';
import { AddressBookContactArrayService } from './../address-book-contact-array.service';
import { IAddressBookCoreContact } from '../../ts/models/address-book-core-contact.model';
import { IAddressBookFetchResponse } from '../../ts/models/address-book-fetch-response.model';
import { IAddressBookPermissionInfo } from './../../ts/models/permissions/address-book-permission-info.model';
import { IAddressBookPermissionResponse } from '../../ts/models/permissions/address-book-permission-response.model';
import { IAddressBookUniqueNameNumberPayload } from './../../ts/models/address-book-unique-name-number-payload.model';

@Injectable({
	providedIn: 'root',
})
export class AddressBookContactService {

	private readonly http: HttpClient;

	private readonly contactPermissionsUrl: string = environment.abilisUrl + '/sys/user/getinfo.json';

	constructor(
		private readonly addressBookService: AddressBookService,
		private readonly addressBookHttpService: AddressBookHttpService,
		private readonly addressBookContactArrayService: AddressBookContactArrayService
	) {
		this.http = inject(HttpClient);
	}

	/**
	* Handles fetching of Address Book permissions
	*/
	public fetchContactPermissions$(): Observable<IAddressBookPermissionInfo> {
		return this.http.get<IAddressBookPermissionResponse>(this.contactPermissionsUrl)
			.pipe(
				map(response => {
					const { smsenabled, pubprotected, callenabled_ctip, callenabled_sip } = response.Response;

					return { smsenabled, pubprotected, callenabled_ctip, callenabled_sip };
				}),
				catchError(() => of(this.getPermissionsOnError()))
			);
	}

	/**
	* Gets matching contact or return null if none is matching
	* @param {IAddressBookContact} formContact Values from Form
	* @param {IAddressBookContact | null} contact Selected contact (if edit mode) or null (if add new mode)
	* @param {string} username Owner of Address Book
	*/
	public getMatchingContact$(
		formContact: IAddressBookCoreContact, contact: IAddressBookContact | null, username: string
	): Observable<AddressBookContactData> {
		return this.http.get<IAddressBookFetchResponse>(this.getFetchContactUrl(username))
			.pipe(
				map(response => {
					const { name, type, num } = formContact;
					const contacts = this.addressBookContactArrayService.formatContactsForMatch(response, contact);
					const matchingNumberContact = this.addressBookContactArrayService.getContactByValue(contacts, num, 'num');

					if (matchingNumberContact) {
						return { matchByName: false, matchingContact: matchingNumberContact };
					}

					const matchingNameTypeContact = this.addressBookContactArrayService.getContactByNameType(contacts, name, type);

					return matchingNameTypeContact ? { matchByName: true, matchingContact: matchingNameTypeContact } : null;
				}),
				catchError(() => of(null))
			);
	}

	/**
	* Checks if provided name/number is unique. It's used for async validator
	* @param {IAddressBookUniqueNameNumberPayload} payload Contains:
	* - value - value to compare
	* - username - Owner of Address Book
	* - isNumberMode - If either checking for unique number or name
	* @param {IAddressBookContact | null} contact Selected contact (if edit mode) or null (if add new mode)
	*/
	public checkUniqueRecord$(
		payload: IAddressBookUniqueNameNumberPayload, contact: IAddressBookContact | null
	): Observable<ValidationErrors | null> {
		const { value, username, isNumberMode } = payload;
		const validationKey = this.getContactValidationKey(isNumberMode);

		return this.http.get<IAddressBookFetchResponse>(this.getFetchContactUrl(username))
			.pipe(
				map(response => {
					const key = this.getContactKey(isNumberMode);
					const contacts = this.addressBookContactArrayService.formatContactsForMatch(response, contact);
					const matchingContact = this.addressBookContactArrayService.getContactByValue(contacts, value, key);

					return matchingContact ? { [validationKey]: true } : null;
				}),
				catchError(() => of({ [validationKey]: true }))
			);
	}

	/**
	* Gets appropriate key for Address Book contact object
	* @param {boolean} isNumberMode If either checking for unique number or name
	*/
	private getContactKey(isNumberMode: boolean): 'num' | 'name' {
		return isNumberMode ? 'num' : 'name';
	}

	/**
	* Gets appropriate key for async checking of unique record
	* @param {boolean} isNumberMode If either checking for unique number or name
	*/
	private getContactValidationKey(isNumberMode: boolean): 'contactNumberTaken' | 'contactNameTaken' {
		return isNumberMode ? 'contactNumberTaken' : 'contactNameTaken';
	}

	/**
	* Gets appropriate fetch URL
	* @param {string} username Owner of Address Book
	*/
	private getFetchContactUrl(username: string): string {
		const isAdminBook = this.addressBookService.checkIfAdminAddressBook();

		if (!isAdminBook) {
			return this.addressBookHttpService.getContactsUrl;
		}

		return this.addressBookHttpService.getContactsAdminUrl + `?user=${username}`;
	}

	/**
	* Gets Address Book permissions, when HTTP error is present
	*/
	private getPermissionsOnError(): IAddressBookPermissionInfo {
		return { smsenabled: false, pubprotected: true, callenabled_ctip: false, callenabled_sip: false };
	}
}
