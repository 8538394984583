import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrType } from '../../../ts/enums/toastr-type.enum';
import { ISelectData } from '../../../ts/models/select-data.model';
import { MainUtilService } from '../../../services/main-util.service';
import { ResponseStatus } from '../../../ts/enums/response-status.enum';
import { IActionResponse } from '../../../ts/models/action-response.model';
import { AutomationProgramState } from '../ts/enums/automation-program-state.enum';
import { IAutomationProgramEdit } from '../ts/models/automation-program-edit.model';
import { AutomationProgramEditNgrxService } from './ngrx/automation-program-edit-ngrx.service';
import { AutomationProgramEditListTableService } from './automation-program-edit-list-table.service';
import { AutomationProgramExtensionType } from '../ts/enums/automation-program-edit-extension-type.enum';
import { AutomationProgramProjectTimeout } from '../ts/enums/automation-program-edit-project-timeout.enum';
import { AutomationProgramSpreadsheetNgrxService } from './ngrx/automation-program-spreadsheet-ngrx.service';
import { IAutomationProgramEditListResponse } from '../ts/models/automation-program-edit-list.response.model';
import { IRioRvsMultipleEntriesResponse } from '../../rio-rvs/ts/models/rio-rvs-save-multiple-entries-response.model';
import { AutomationProgramEditSaveProjectResponse } from '../ts/types/automation-program-edit-save-project-response.type';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramEditService {

	constructor(
		private router: Router,
		private translate: TranslateService,
		private mainUtilService: MainUtilService,
		private automationListTableService: AutomationProgramEditListTableService,
		private automationProgramEditNgrxService: AutomationProgramEditNgrxService,
		private automationSpreadsheetNgrxService: AutomationProgramSpreadsheetNgrxService
	) { }

	formatScriptsResponse(response: IAutomationProgramEditListResponse): IAutomationProgramEdit[] {
		const { message, files } = response.Response;
		const hasErrorMessage = message !== undefined;

		this.automationListTableService.setResponseErrorMessage(message);

		if (!hasErrorMessage && files?.length) {
			return files.reduce((accumulator, file) => {
				const { name, exist } = file;
				const typelessScript = this.getScriptNameWithoutType(name);

				return exist ? [...accumulator, this.formatLuaFileItem(files, file, typelessScript)] : accumulator;
			}, [] as IAutomationProgramEdit[]);
		}

		return [];
	}

	formatLuaFileItem(files: IAutomationProgramEdit[], file: IAutomationProgramEdit, typelessScript: string): IAutomationProgramEdit {
		const id = typelessScript;
		const extension = this.getExtensionType(file.name);
		const matchingProgram = this.getMatchingFile(files, typelessScript, extension);

		const luaScriptExist = matchingProgram !== undefined;
		const state = matchingProgram?.state || file.state;

		const isRunning = state === AutomationProgramState.RUNNING;
		const isStopping = state === AutomationProgramState.STOPPING;

		const endtime = this.formatScriptTime(matchingProgram?.endtime);
		const starttime = this.formatScriptTime(matchingProgram?.starttime);
		const createtime = this.formatScriptTime(matchingProgram?.createtime);

		const isDeleting = false;
		const autostart = matchingProgram?.autostart || file.autostart;
		const showRunLuaIcon = luaScriptExist && !isRunning && !isStopping;
		const showStopLuaIcon = luaScriptExist && isRunning && !isStopping;
		const jsonTableExist = this.getMatchingFile(files, typelessScript, extension) !== undefined;

		return {
			...file, id, state, extension, name: typelessScript, isRunning,
			showStopLuaIcon, createtime, autostart, luaScriptExist, starttime,
			endtime, isStopping, showRunLuaIcon, jsonTableExist, isDeleting
		};
	}

	getExtensionType(fileName: string): string {
		if (fileName.endsWith(AutomationProgramExtensionType.JSON)) {
			return AutomationProgramExtensionType.JSON;
		}

		if (fileName.endsWith(AutomationProgramExtensionType.LUA)) {
			return AutomationProgramExtensionType.LUA;
		}

		return '';
	}

	getMatchingFile(files: IAutomationProgramEdit[], typelessScript: string, extension: string): IAutomationProgramEdit | undefined {
		return files.find(fileItem => fileItem.name.toLowerCase() === `${typelessScript}${extension}`.toLowerCase());
	}

	formatScriptTime(scriptTime: string | undefined): string {
		if (!scriptTime || scriptTime.startsWith('1980-')) {
			return '';
		}

		return MainUtilService.getLuxonDatetime(scriptTime, 'dd/MM/yyyy HH:mm');
	}

	handleSaveProjectResponse(response: AutomationProgramEditSaveProjectResponse): void {
		const commandsResponse = response.map(responseItem => this.isCommandExecuted(responseItem));
		const allValidResponses = commandsResponse.every(responseItem => responseItem);

		if (allValidResponses) {
			this.mainUtilService.showToastrMessage(this.translate.instant('AUTO.RULES.EDIT.savedChanges'), ToastrType.SUCCESS);

			return;
		}

		const invalidResponseIndex = commandsResponse.findIndex(responseItem => responseItem === false);
		const { message } = response[invalidResponseIndex].Response;

		this.mainUtilService.showToastrMessage(message, ToastrType.ERROR);
	}

	isCommandExecuted(responseItem: IActionResponse | IRioRvsMultipleEntriesResponse): boolean {
		const { message } = responseItem.Response;

		return message.toLowerCase() === ResponseStatus.OK;
	}

	getScriptNameWithoutType(name: string): string {
		return name.slice(0, name.lastIndexOf('.'));
	}

	getMatchingProgram(
		automationPrograms: IAutomationProgramEdit[], selectedAutomationProgram: IAutomationProgramEdit | null
	): IAutomationProgramEdit | undefined {
		const automationProgramName = selectedAutomationProgram?.name || null;

		return automationPrograms.find(item => item.name === automationProgramName);
	}

	isNewSpreadsheetUrl(): boolean {
		return this.router.url.endsWith('/new)') && !this.router.url.includes('/edit/');
	}

	getProjectExecutionInterval(value: number, projectTimeoutUnit: AutomationProgramProjectTimeout): number {
		switch (projectTimeoutUnit) {
		case AutomationProgramProjectTimeout.MILLISECONDS:
			return value;
		case AutomationProgramProjectTimeout.SECONDS:
			return value * 1000;
		case AutomationProgramProjectTimeout.MINUTES:
			return value * 60 * 1000;
		}
	}

	getProjectTimeoutValue(value: AutomationProgramProjectTimeout): ISelectData[] {
		switch (value) {
		case AutomationProgramProjectTimeout.MILLISECONDS:
			return [{ value: 100, uiValue: '100' }];
		case AutomationProgramProjectTimeout.MINUTES:
		case AutomationProgramProjectTimeout.SECONDS:
			return [{ value: 1, uiValue: '1' }, { value: 10, uiValue: '10' }, { value: 100, uiValue: '100' }];
		}
	}

	navigateToMainTable(): void {
		const path = 'table-programs-edit';

		this.router.navigate([{ replaceUrl: true }, { outlets: { [path]: path } }]);
	}

	handleSetAutomationProgram(automationPrograms: IAutomationProgramEdit[], scriptName: string): void {
		const matchingProgram = automationPrograms.find(item => {
			const { name, extension } = item;

			return name === scriptName && extension === AutomationProgramExtensionType.JSON;
		});


		if (matchingProgram) {
			this.automationProgramEditNgrxService.loadAutomationProgramTable(matchingProgram.name);
			return;
		}

		if (this.isNewSpreadsheetUrl()) {
			this.automationSpreadsheetNgrxService.setProjectLineDevices([], '');
			return;
		}

		this.navigateToMainTable();
	}
}
