import { Action, createReducer, on } from '@ngrx/store';
import { IIpbanWhitelistItem } from '../ts/models/ipsh-whitelist-item.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IIpshConfigurationValue } from '../ts/models/configuration/ipsh-configuration-value.model';
import { INetworkingInfoResourceItem } from '../../networking-info/ts/models/resource/networking-info-resource-item.model';
import * as ipshActions from './ipsh.actions';

export const ipshFeatureKey = 'ipsh';

export const initialIpshConfigurationFormState = {
	isLoading: true,
	saved: true,
	initialized: true,
	setFormValues: true,
	enabled: false,
	topHostsMonitored: 5,
	thresholdInLimiter: 8,
	thresholdOutLimiter: 8,
	variance: 50,
	varianceTime: 30,
	shiftStep: 30
};

export interface IIpshState extends EntityState<INetworkingInfoResourceItem> {
	isLoading: boolean;
	isLoadingWhitelistItems: boolean;
	whitelistItems: IIpbanWhitelistItem[];
	ipshConfigurationForm: IIpshConfigurationValue;
}

export const adapter: EntityAdapter<INetworkingInfoResourceItem> = createEntityAdapter<INetworkingInfoResourceItem>();

export const initialIpshState: IIpshState = adapter.getInitialState({
	isLoading: true,
	whitelistItems: [],
	isLoadingWhitelistItems: true,
	ipshConfigurationForm: initialIpshConfigurationFormState
});

const ipshReducer = createReducer(
	initialIpshState,
	on(ipshActions.finishLoadingLanResources, (state, { payload }) =>
		adapter.setAll(payload.lanResources, { ...state, isLoading: false })),
	on(ipshActions.finishLoadingIpshConfiguration, (state, { payload }) => {
		const { ipshConfigurationForm } = payload;

		return { ...state, ipshConfigurationForm };
	}),
	on(ipshActions.finishLoadingWhitelistItems, (state, { payload }) => {
		const { whitelistItems } = payload;

		return { ...state, whitelistItems, isLoadingWhitelistItems: false };
	}),
	on(ipshActions.updateSingleWhitelistItem, (state, { payload }) => {
		const { whitelistItem } = payload;
		const { whitelistItems: existingWhitelistItems } = state;

		const updatedExistingWhitelistItems = existingWhitelistItems.map(existingWhitelistItem => {
			const { item } = existingWhitelistItem;

			return whitelistItem.item === item ? whitelistItem : existingWhitelistItem;
		});

		return { ...state, whitelistItems: updatedExistingWhitelistItems };
	}),
	on(ipshActions.updateWhitelistItems, (state, { payload }) => {
		const { whitelistItems: payloadWhitelistItems } = payload;
		const { whitelistItems: existingWhitelistItems } = state;

		const updatedExistingWhitelistItems = existingWhitelistItems.map(existingWhitelistItem => {
			const { item } = existingWhitelistItem;
			const matchingWhitelistItem = payloadWhitelistItems.find(payloadWhitelistItem => payloadWhitelistItem.item === item);

			return matchingWhitelistItem ? matchingWhitelistItem : existingWhitelistItem;
		});

		return { ...state, whitelistItems: updatedExistingWhitelistItems };
	})
);

export function IpshReducer(state: IIpshState | undefined, action: Action) {
	return ipshReducer(state, action);
}

export const { selectAll: selectAllLanResources } = adapter.getSelectors();
