import { Input, NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormlyFormModule } from './formly-form.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularBootstrapModule } from './angular-bootstrap.module';
import { SharedPipeDirectiveModule } from './shared-pipe-directive.module';
import { CardComponent } from '../components/ui-components/card/card.component';
import { ChartComponent } from '../components/ui-components/chart/chart.component';
import { DropFileComponent } from '../components/ui-components/drop-file/drop-file.component';
import { AntekLogoComponent } from '../components/ui-components/antek-logo/antek-logo.component';
import { GoogleMapComponent } from '../components/ui-components/google-map/google-map.component';
import { InputRangeComponent } from '../components/ui-components/input-range/input-range.component';
import { ProgressbarComponent } from '../components/ui-components/progressbar/progressbar.component';
import { CustomRadioComponent } from '../components/ui-components/custom-radio/custom-radio.component';
import { ConfirmDialogComponent } from '../components/ui-components/confirm-dialog/confirm-dialog.component';
import { CustomCheckboxComponent } from '../components/ui-components/custom-checkbox/custom-checkbox.component';
import { ActionRowComponent } from '../components/networking/common/components/action-row/action-row.component';
import { CheckboxEditComponent } from '../components/ui-components/generic-table/components/checkbox-edit/checkbox-edit.component';
import { NetworkCardFormComponent } from '../components/networking/common/components/network-card-form/network-card-form.component';
import { NetworkActionRowComponent } from '../components/networking/common/components/network-action-row/network-action-row.component';
import { CheckboxFilterComponent } from '../components/ui-components/generic-table/components/checkbox-filter/checkbox-filter.component';
import { BaseSelectComponent } from '../components/ui-components/base-select/base-select.component';
import {
	MinimizeMaximizeContainerComponent
} from '../components/ui-components/minimize-maximize-container/minimize-maximize-container.component';
import {
	NetworkActionHeaderComponent
} from '../components/networking/common/components/network-action-header/network-action-header.component';
import {
	ImageSelectEditComponent
} from '../components/ui-components/generic-table/components/image-select-edit/image-select-edit.component';
import {
	NetworkCardFormTemplateComponent
} from '../components/networking/common/components/network-card-form-template/network-card-form-template.component';
import {
	NetworkingBasicAdvancedViewComponent
} from '../components/networking-info/components/networking-basic-advanced-view/networking-basic-advanced-view.component';
import { SwitchComponent } from '../components/ui-components/switch/switch.component';
import {
	BaseChartComponent
} from '../components/ui-components/base-chart/base-chart.component';
import {
	CustomTooltipComponent
} from '../components/ui-components/custom-tooltip/custom-tooltip.component';
import { SpinnerLoadingComponent } from '../components/ui-components/custom-ui-components/spinner-loading.component';
import { InfoboxHoverComponent } from '../components/ui-components/infobox-hover/infobox-hover.component';
import { SelectDropdownComponent } from '../components/ui-components/custom-ui-components/select-dropdown.component';
import { BestCheckComponent } from '../components/ui-components/custom-ui-components/best-check.component';
import { RegexInputDirective } from '../standalone/directives/regex-input.directive';
import { OffCanvasComponent } from '../components/ui-components/custom-ui-components/off-canvas.component';
import { ToggleThemeComponent } from '../components/ui-components/custom-ui-components/toggle-theme.component';

import { InfoBubbleComponent } from '../components/ui-components/custom-ui-components/info-bubble.component';
import { WanSwitchComponent } from '../components/ui-components/wan-switch/wan-switch.component';
import { BestTextComponent } from '../components/ui-components/custom-ui-components/best-text.component';
import { BestNumberComponent } from '../components/ui-components/custom-ui-components/best-number.component';

import { FormContainerComponent } from '../components/ui-components/form/form-container.component';
import { FormFieldComponent } from '../components/ui-components/form/form-field.component';

import { BestArrowComponent } from '../components/ui-components/custom-ui-components/best-arrow.component';
import { BestBitRateComponent } from '../components/ui-components/custom-ui-components/best-bit-rate.component';
import { CustomBestSelectAnyManualComponent } from '../components/ui-components/custom-ui-components/custom-best-select-any-auto.component';

import { CustomBestCheckNoNumberComponent } from '../components/ui-components/custom-ui-components/custom-best-check-no-number.component';
import { BestSubnetInputComponent } from '../components/ui-components/custom-ui-components/best-subnet-input.component';
import { CustomBestCheckUndefinedComponent } from '../components/ui-components/custom-ui-components/custom-best-check-undefined.component';
import { BestTimeInputComponent } from '../components/ui-components/custom-ui-components/best-time-input.component';
import { CustomBestCheckRedisComponent } from '../components/ui-components/custom-ui-components/custom-best-check-redis.component';
import { BestOffCanvasComponent } from '../components/ui-components/custom-ui-components/best-off-canvas.component';
import { DnsRequestorsComponent } from '../components/ADMIN_FEATURES/system-general-parameters/components/networking-admin-features/dns-requestors/dns-requestors.component';
import { BestSelectComponent } from '../components/ui-components/custom-ui-components/best-select.component';
import { SetRemIpComponent } from '../components/networking/components/ports-preview/components/ports-preview-resource-add/aipt2-form/components/wan-paths/set-rem-ip.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgChartComponent } from '../components/ui-components/ng-chart/ng-chart.component';
import { LegendComponent } from '../components/ui-components/legend/legend.component';
import { ProgressBarComponent } from '../components/ui-components/progress-bar/progress-bar.component';
import { SimpleChartComponent } from '../components/ui-components/simple-chart/simple-chart.component';
import { SliderComponent } from '../standalone/components/controls/slider/slider.component';



@NgModule({
	imports: [
		A11yModule,
		FormsModule,
		ChartsModule,
		CommonModule,
		DragDropModule,
		FormlyFormModule,
		GoogleMapsModule,
		ReactiveFormsModule,
		FormlyBootstrapModule,
		AngularBootstrapModule,
		FormlyModule.forChild(),
		TranslateModule.forChild(),
		SharedPipeDirectiveModule,
		PopoverModule,
		SliderComponent
	],
	declarations: [
		CardComponent,
		ChartComponent,
		SimpleChartComponent,
		DropFileComponent,
		AntekLogoComponent,
		ActionRowComponent,
		GoogleMapComponent,
		InputRangeComponent,
		ProgressbarComponent,
		CustomRadioComponent,
		CheckboxEditComponent,
		ConfirmDialogComponent,
		CheckboxFilterComponent,
		CustomCheckboxComponent,
		ImageSelectEditComponent,
		NetworkCardFormComponent,
		NetworkActionRowComponent,
		NetworkActionHeaderComponent,
		NetworkCardFormTemplateComponent,
		MinimizeMaximizeContainerComponent,
		NetworkingBasicAdvancedViewComponent,
		DnsRequestorsComponent,
		NgChartComponent,
		ProgressBarComponent,


		BaseChartComponent,
		CustomTooltipComponent,
		BaseSelectComponent,
		SwitchComponent,
		SpinnerLoadingComponent,
		InfoboxHoverComponent,
		SelectDropdownComponent,
		RegexInputDirective,
		OffCanvasComponent,
		ToggleThemeComponent,
		BestSelectComponent,
		WanSwitchComponent,
		BestOffCanvasComponent,
		LegendComponent,
		// BEST
		BestTextComponent,
		BestNumberComponent,
		BestCheckComponent,
		FormContainerComponent,
		FormFieldComponent,
		BestArrowComponent,
		BestBitRateComponent,
		BestSubnetInputComponent,
		BestTimeInputComponent,
		// CUSTOM
		CustomBestSelectAnyManualComponent,
		CustomBestCheckNoNumberComponent,
		CustomBestCheckUndefinedComponent,
		CustomBestCheckRedisComponent,
		SetRemIpComponent,


	],
	exports: [
		CardComponent,
		ChartComponent,
		SimpleChartComponent,
		DropFileComponent,
		ActionRowComponent,
		AntekLogoComponent,
		GoogleMapComponent,
		InputRangeComponent,
		CustomRadioComponent,
		ProgressbarComponent,
		CheckboxEditComponent,
		CustomCheckboxComponent,
		CheckboxFilterComponent,
		ImageSelectEditComponent,
		NetworkCardFormComponent,
		NetworkActionRowComponent,
		NetworkActionHeaderComponent,
		NetworkCardFormTemplateComponent,
		MinimizeMaximizeContainerComponent,
		NetworkingBasicAdvancedViewComponent,
		DnsRequestorsComponent,
		NgChartComponent,
		// NEW COMPONENTS
		SetRemIpComponent,
		SwitchComponent,
		BestTextComponent,
		BaseChartComponent,
		CustomTooltipComponent,
		BaseSelectComponent,
		InfoboxHoverComponent,
		RegexInputDirective,
		OffCanvasComponent,
		InfoBubbleComponent,
		BestSelectComponent,
		WanSwitchComponent,
		ToggleThemeComponent,
		SpinnerLoadingComponent,
		SelectDropdownComponent,
		BestNumberComponent,
		BestCheckComponent,
		FormContainerComponent,
		FormFieldComponent,
		BestArrowComponent,
		BestBitRateComponent,
		// CUSTOM NEW COMPONENTS
		CustomBestCheckNoNumberComponent,
		CustomBestSelectAnyManualComponent,
		CustomBestCheckUndefinedComponent,
		BestTimeInputComponent,
		CustomBestCheckRedisComponent,
		BestSubnetInputComponent,
		BestOffCanvasComponent,
		LegendComponent,
		ProgressBarComponent


	]
})

export class UiComponentsFeatureModule { }
