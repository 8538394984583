import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { maxValueValidator, minValueValidator } from '../../../../utils/sync-validator';
import { AutomationDeviceShortPipe } from '../../../../pipes/automation-device-short.pipe';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { createTrimWhitespaceValidator } from '../../../../utils/form-validators.validator';
import { GenericTableBuilder } from '../../../networking/common/classes/table-builder.class';
import { IGenericTableRow } from '../../../ui-components/generic-table/ts/models/generic-table-row';
import { IGenericTableHeader } from '../../../ui-components/generic-table/ts/models/generic-table-header';
import { IGenericUnsetSelectedRow } from '../../../ui-components/generic-table/ts/models/generic-unset-selected-row.model';
import { IRioRvsIohubAnalogInputLineDevice } from '../../ts/models/line-devices/rio-rvs-iohub-analog-input-line-device.model';

@Injectable({
	providedIn: 'root'
})
export class RioRvsAinTableService extends GenericTableBuilder {

	private unsetSelectedRowState = new BehaviorSubject({ unsetSelectedRow: false });

	private readonly ainIconStyle = 'margin-right: 6px; width: 16px; height: 16px; cursor: default';
	private readonly headerCellStyle = 'display: flex; justify-content: center; padding-left: 8px !important';

	constructor(
		private formBuilder: FormBuilder
	) {
		super();
	}

	buildTableHeaders(): IGenericTableHeader[] {
		return [
			{ header: { headerName: 'AUTO.PREVIEW.ADV.AIN.hdrPort' }, sort: { useSort: false } },
			{
				header: {
					headerName: 'AUTO.PREVIEW.ADV.AIN.hdrMapIcon',
					headerStyle: this.headerCellStyle,
				},
				sort: { useSort: false },
			},
			{ header: { headerName: 'AUTO.PREVIEW.ADV.AIN.hdrDesc' }, sort: { useSort: false } },
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AIN.hdrUnit' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AIN.hdrMinVal' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AIN.hdrMaxVal' },
				sort: { useSort: false }
			},
			{
				header: {
					headerName: 'AUTO.PREVIEW.ADV.AIN.hdrLow', headerIcon: true, headerIconPath: 'bell.svg',
					headerContainerStyle: 'flex-direction: row !important;', headerIconStyle: this.ainIconStyle
				},
				sort: { useSort: false }
			},
			{
				header: {
					headerName: 'AUTO.PREVIEW.ADV.AIN.hdrHigh', headerIcon: true, headerIconPath: 'bell.svg',
					headerContainerStyle: 'flex-direction: row !important;', headerIconStyle: this.ainIconStyle
				},
				sort: { useSort: false }
			},
			{
				header: {
					headerName: 'AUTO.PREVIEW.ADV.AIN.hdrHyst',
					headerIcon: true, headerIconPath: 'bell.svg',
					headerContainerStyle: 'flex-direction: row !important;', headerIconStyle: this.ainIconStyle
				},
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AIN.hdrSens' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AIN.hdrPoll' },
				sort: { useSort: false }
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.AOUT.hdrDefaultState' },
				sort: { useSort: false }
			},
			{
				header: { headerName: '', isAction: true },
				sort: { useSort: false }
			}
		];
	}

	buildTableRows(): IGenericTableRow[] {
		return [
			{
				cell: {
					cellName: 'lineDeviceType', visibleCellCondition: true, pipe: new AutomationDeviceShortPipe()
				},
				edit: { editMode: false }
			},
			{
				cell: {
					cellName: 'imagePath', visibleCellCondition: true, isImage: true,
					customImageHeight: '22px', hideImagePath: true
				},
				edit: { editMode: false },
			},
			{
				cell: { cellName: 'description', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'unit', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'minUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'maxUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'almLoUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'almHiUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'histUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'updUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'poll', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: 'dftUI', visibleCellCondition: true },
				edit: { editMode: false }
			},
			{
				cell: { cellName: '', visibleCellCondition: true, isAction: true },
				edit: { editMode: false }
			}
		];
	}

	buildForm(): FormArray {
		return this.formBuilder.array([]);
	}

	setUnselectedRowState(rowState: boolean): void {
		this.unsetSelectedRowState.next({ unsetSelectedRow: rowState });
	}

	getUnsetSelectedRowState$(): Observable<IGenericUnsetSelectedRow> {
		return this.unsetSelectedRowState;
	}

	buildAinForm(selectedDevice: IRioRvsIohubAnalogInputLineDevice): FormGroup {
		const {
			'unit-ronly': unitReadonly, 'min-ronly': minReadonly, 'max-ronly': maxReadonly, min, max,
			'hist-ronly': hystReadonly, 'upd-ronly': sensitivityReadonly, 'poll-ronly': poolingReadonly,
			almHi, almLo
		} = selectedDevice;

		const minMaxValidators = this.getMinMaxValidators();
		const whitespaceValidator = createTrimWhitespaceValidator();
		const defaultValidators = this.getAlmLoHiValidators(min, max, false, true);
		const channelRegExp = /^(No|([1-9]\d?|1\d{2}|2[0-4]\d|25[0-5])(,([1-9]\d?|1\d{2}|2[0-4]\d|25[0-5]))*)$/i;

		return this.formBuilder.group({
			imagePath: [''],
			alertAlmLo: ['', [whitespaceValidator, Validators.pattern(channelRegExp)]],
			alertAlmHi: ['', [whitespaceValidator, Validators.pattern(channelRegExp)]],
			description: ['', [Validators.maxLength(14)]],
			almLo: ['', [...this.getAlmLoHiValidators(min, max, true, this.isMatchingUnavailableValue(almLo))]],
			almHi: ['', [...this.getAlmLoHiValidators(min, max, true, this.isMatchingUnavailableValue(almHi))]],
			dft: ['', [whitespaceValidator, ...defaultValidators]],
			minValue: [{ value: '', disabled: minReadonly }, minMaxValidators],
			maxValue: [{ value: '', disabled: maxReadonly }, minMaxValidators],
			unit: [{ value: '', disabled: unitReadonly }, [Validators.maxLength(8)]],
			hysteresis: [{ value: '', disabled: hystReadonly }, this.getHystValidators()],
			poolingPeriod: [{ value: '', disabled: poolingReadonly }, this.getPoolingPeriod()],
			sensitivity: [{ value: '', disabled: sensitivityReadonly }, this.getHystValidators()]
		});
	}

	isMatchingUnavailableValue(value: string | undefined): boolean {
		return ['-9999', '9999', '+9999'].includes((value || '').toString());
	}

	getAlmLoHiValidators(min: string, max: string, acceptEmptyValue: boolean, includeEqual: boolean): ValidatorFn[] {
		const minParsed = Number.parseFloat(min);
		const maxParsed = Number.parseFloat(max);

		return [
			minValueValidator(minParsed, acceptEmptyValue, includeEqual),
			maxValueValidator(maxParsed, acceptEmptyValue, includeEqual),
			Validators.pattern(/^((-?\d*\.?\d+)|(^$))$/)
		];
	}

	getHystValidators(): ValidatorFn[] {
		const hystUpdRegExp = /^[+-]?((([1-9]|\d{2,3}|[1-8]\d{3}|9[0-8]\d{2}|99[0-8]\d|999[0-8])(\.[0-9]{1,5})?)|0(\.[0-9]{1,5})?|9999)$/;

		return [createTrimWhitespaceValidator(), Validators.pattern(hystUpdRegExp)];
	}

	getMinMaxValidators(): ValidatorFn[] {
		const minMaxRegExp = /^[+-]?((-?([1-9]|\d{2,3}|[1-8]\d{3}|9[0-8]\d{2}|99[0-8]\d|999[0-8])(\.[0-9]{1,5})?)|0(\.[0-9]{1,5})?|9999)$/;

		return [createTrimWhitespaceValidator(), Validators.pattern(minMaxRegExp)];
	}

	getPoolingPeriod(): ValidatorFn[] {
		return [createTrimWhitespaceValidator(), Validators.pattern(/^[+-]?(0\.[1-9]{1,5}|[1-9](\.[0-9])?|[1-9]\d(\.[0-9])?|[1-2]\d{2}(\.[0-9]{1,5})?|300)$/)];
	}
}
