import { CommonModule } from '@angular/common';
import { TabsService } from '../../services/tabs.service';
import { TabBodyComponent } from '../tab-body/tab-body.component';
import { TabLabelComponent } from '../tab-label/tab-label.component';
import { Component, ChangeDetectionStrategy, Input, ContentChild, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-tab',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './tab.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class TabComponent implements OnChanges {

	@ContentChild(TabBodyComponent) bodyComponent: TabBodyComponent;
	@ContentChild(TabLabelComponent) labelComponent: TabLabelComponent;

	@Input() tooltip = '';
	@Input() location = '';
	@Input() active: boolean;
	@Input() disabled: boolean | undefined;

	@Output() selectTabEvent = new EventEmitter();

	constructor(
		private readonly tabsService: TabsService
	) { }

	public ngOnChanges(changes: SimpleChanges): void {
		if (!changes?.active?.currentValue) { return; }

		this.tabsService.setActiveTabComponent(this);
	}
}
