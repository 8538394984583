import { Action, createReducer, on } from '@ngrx/store';
import { IAddressBookContact } from '../ts/models/address-book-contact.model';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { IAddressBookPermissionInfo } from './../ts/models/permissions/address-book-permission-info.model';
import * as addressBookActions from './address-book.action';

export const addressBookFeatureKey = 'addressBook';

export interface IAddressBookState extends EntityState<IAddressBookContact> {
	isLoading: boolean;
	permissions: IAddressBookPermissionInfo;
}

function sortByName(a: IAddressBookContact, b: IAddressBookContact): number {
	return a.name.localeCompare(b.name);
}

export const adapter: EntityAdapter<IAddressBookContact> = createEntityAdapter<IAddressBookContact>({
	sortComparer: sortByName
});

export const initialAddressbookState: IAddressBookState = adapter.getInitialState({
	isLoading: true,
	permissions: {
		smsenabled: false,
		pubprotected: true,
		callenabled_sip: false,
		callenabled_ctip: false
	}
});

const addressBookReducer = createReducer(
	initialAddressbookState,
	on(addressBookActions.setIsLoadingValue, (state, { payload }) => ({ ...state, isLoading: payload.isLoading })),
	on(addressBookActions.addressBoookFetchCompleted, (state, { payload }) =>
		adapter.setAll(payload.contacts, { ...state, isLoading: false })),
	on(addressBookActions.updateContacts, (state, { payload }) =>
		adapter.updateMany(payload.contacts.map(contact => Object.assign({ id: contact.id, changes: contact })), { ...state })),
	on(addressBookActions.updateSingleCheckedContact, (state, { payload }) =>
		adapter.updateOne({ id: `${payload.contact.id}`, changes: payload.contact }, { ...state })),
	on(addressBookActions.markContactAsUnselectable, (state, { payload }) =>
		adapter.updateOne({ id: `${payload.contact.id}`, changes: payload.contact }, { ...state })),
	on(addressBookActions.finishLoadingAddressbookInfo, (state, { payload }) => ({ ...state, permissions: payload.permissions })),
	on(addressBookActions.changedCurretlyVisibleContacts,
		(state, { payload }) => {
			const temp: Dictionary<IAddressBookContact> = { ...state.entities };

			for (const [key, value] of Object.entries(temp)) {
				if (value) {
					temp[key] = { ...value, isInView: false };
				}
			}

			payload.visibleContacts.forEach((contact) => {
				// I dont want to do this but for some reason es-lint is wrong here
				if (contact.id) {
					const id = contact.id;
					if (temp[id]) {
						const cont = temp[id];
						if (cont !== undefined) {
							cont.isInView = true;
						}
					}
				}
			});

			return adapter.updateMany(Object.entries(temp)
				.filter(([id, contact]) => state.entities[id]?.isInView !== contact?.isInView)
				.map(([id, contact]) => Object.assign({ id, changes: contact })), { ...state });
		})
);

export function AddressBookReducer(state: IAddressBookState | undefined, action: Action) {
	return addressBookReducer(state, action);
}

export const { selectAll: selectAllAddressBookContacts } = adapter.getSelectors();
