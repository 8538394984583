import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SelectFilterPipe } from '../../../pipes/select-filter.pipe';
import { TranslateFixPipe } from '../../../pipes/translate-fix.pipe';
import { ISelectData } from '../../../../ts/models/select-data.model';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { LangChangeEvent, TranslateModule } from '@ngx-translate/core';
import { GlobalService } from '../../../../services/others/global.service';
import { NestedTranslationPipe } from '../../../pipes/nested-translation.pipe';
import { PasswordTextDirective } from '../../../directives/password-text.directive';
import { AutofocusInputFieldDirective } from '../../../directives/autofocus-input-field.directive';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		SelectFilterPipe,
		TranslateFixPipe,
		ReactiveFormsModule,
		NestedTranslationPipe,
		PasswordTextDirective,
		AutofocusInputFieldDirective
	],
	selector: 'app-custom-input',
	templateUrl: './custom-input.component.html',
	styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent implements OnInit {

	@ViewChild('inputElement') inputElement: ElementRef;

	@Input() step = 0;
	@Input() min: number;
	@Input() max: number;

	@Input() noWrap: boolean;
	@Input() inputIcon: string;
	@Input() inputType: string;
	@Input() whiteText: boolean;
	@Input() inputLabel: string;
	@Input() rightIcon: boolean;
	@Input() autofocus: boolean;
	@Input() bottomSpace = true;
	@Input() inputPlaceholder = '';
	@Input() maxControlWidth: string;
	@Input() afterInputLabel: string;
	@Input() translateToPasswordType: boolean;
	@Input() passedFormControl: AbstractControl;
	@Input() passwordTextState: boolean | null;
	@Input() selectData: ISelectData[] | null = null;

	@Output() fieldFocusedEvent = new EventEmitter();
	@Output() inputIconClickedEvent = new EventEmitter();

	useFocus = true;
	isFocused: boolean;

	languageChanged$: Observable<LangChangeEvent>;

	constructor(
		private globalService: GlobalService
	) { }

	ngOnInit(): void {
		this.languageChanged$ = this.globalService.getLanguageChangeState$();
	}

	onFocus(): void {
		this.setFocus(true);
	}

	onBlur(): void {
		if (!this.useFocus) { return; }

		this.setFocus(false);
	}

	onSelectItem(onSelectItem: ISelectData): void {
		this.useFocus = false;
		this.passedFormControl.setValue(onSelectItem.value);
		this.passedFormControl.updateValueAndValidity();

		this.useFocus = true;
		this.setFocus(false);
	}

	onClickInputIcon(): void {
		this.inputIconClickedEvent.emit();
	}

	private setFocus(isFocused: boolean): void {
		this.isFocused = isFocused;

		this.fieldFocusedEvent.emit(this.isFocused);
	}
}
