import { Observable, timer } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { exhaustMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ICpuLoadResponse } from '../../ts/models/cpu-load-response.model';
import { ChangeDetectorRef, ElementRef, Injectable, Renderer2, Inject } from '@angular/core';
import * as customOperators from '../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class UiService {

	private readonly cpuLoadUrl: string = environment.abilisUrl + '/sys/user/cpuload.json';

	constructor(
		private http: HttpClient,
		@Inject(DOCUMENT) private document: Document
	) { }

	getCpuLoadIcon$(): Observable<string> {
		return timer(0, 15 * 1000)
			.pipe(
				exhaustMap(() => this.http.get<ICpuLoadResponse>(this.cpuLoadUrl)),
				map(response => this.getCpuLoadIconBasedOnPercentage(response.Response.CpuLoad.MainCpu.Last15sec)),
				customOperators.retryFromError$(1000)
			);
	}

	getCpuLoadIconBasedOnPercentage(cpuLoad: number): string {
		if (cpuLoad >= 0 && cpuLoad < 15) {
			return 'cpu-load-0.svg';
		}

		if (cpuLoad >= 15 && cpuLoad < 30) {
			return 'cpu-load-1.svg';
		}

		if (cpuLoad >= 30 && cpuLoad < 45) {
			return 'cpu-load-2.svg';
		}

		if (cpuLoad >= 45 && cpuLoad < 60) {
			return 'cpu-load-3.svg';
		}

		if (cpuLoad >= 60 && cpuLoad < 75) {
			return 'cpu-load-4.svg';
		}

		if (cpuLoad >= 75 && cpuLoad < 90) {
			return 'cpu-load-5.svg';
		}

		return 'cpu-load-6.svg';
	}

	setSelectboxWidth(
		changeDetectorRef: ChangeDetectorRef, renderer2: Renderer2,
		selectBox: ElementRef, fixedWidth: boolean, selectedValue: string
	): void {
		if (!selectBox) { return; }

		const hiddenDiv = this.document.getElementById('hidden__div');
		const { options } = selectBox.nativeElement;
		const text = (options[options?.selectedIndex]?.label || options[0]?.label) || selectedValue;

		const newOptionElement = renderer2.createElement('option');
		const newSelectElement = renderer2.createElement('select');

		renderer2.appendChild(newSelectElement, newOptionElement);
		renderer2.appendChild(newOptionElement, this.document.createTextNode(text));
		renderer2.appendChild(hiddenDiv, newSelectElement);

		changeDetectorRef.markForCheck();

		const elementWidth = newSelectElement.offsetWidth;
		const cssProperty = fixedWidth ? 'width' : 'max-width';
		renderer2.setStyle(selectBox.nativeElement, cssProperty, `${elementWidth + 20}px`);

		renderer2.removeChild(hiddenDiv, newSelectElement);
	}
}
