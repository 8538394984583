import { Pipe, PipeTransform } from '@angular/core';
import { AvailableLanguage } from '../../components/core/ts/enums/available-language.enum';

@Pipe({
	standalone: true,
	name: 'translateLanguageFullname'
})
export class TranslateLanguageFullnamePipe implements PipeTransform {

	public transform(language: AvailableLanguage | string): string {
		switch(language) {
		case AvailableLanguage.EN:
			return 'TOOLS.english';
		case AvailableLanguage.IT:
			return 'TOOLS.italian';
		default:
			return '';
		}
	}
}
