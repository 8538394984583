import { Action, createReducer, on } from '@ngrx/store';
import { IAuthUser } from '../ts/models/auth-user.model';
import { AuthUserInfo } from '../ts/enums/user-auth.enum';
import { ILoginVerification } from '../ts/models/login-verification-data.model';
import { IFeaturePermissionsItem } from '../../core/ts/models/feature-permissions-item.model';
import * as authActions from './auth.actions';

export const authFeatureKey = 'auth';

export interface IAuthState {
	isLoggedIn: boolean;
	AbilisBuild: string;
	firmware: string;
	loctime: string;
	'login-tout': string;
	name: string;
	'ctisys-ubr': boolean;
	serialNumber: string;
	'auto-max-maps': number;
	user: IAuthUser;
	permissions: IFeaturePermissionsItem[];
	verification: ILoginVerification | null;
}

export const initialAuthState: IAuthState = {
	isLoggedIn: false,
	AbilisBuild: '',
	firmware: '',
	loctime: '',
	'login-tout': '',
	name: '',
	'ctisys-ubr': false,
	serialNumber: '',
	'auto-max-maps': 0,
	user: {
		language: '',
		level: null,
		'io-map': 'ALL',
		'vs-cam': '#',
		'http-gui-group': '',
		'http-gui-phone': false,
		'http-gui-network': false,
		'http-gui-auto': false,
		'http-gui-video': false,
		'http-gui-tools': false,
		username: AuthUserInfo.ANONYMOUS
	},
	verification: null,
	permissions: []
};

const authReducer = createReducer(
	initialAuthState,
	on(authActions.loginAction, (state) => ({ ...state })),
	on(authActions.clearLoginStore, () => ({ ...initialAuthState })),
	on(authActions.updateUserLevel, (state, { payload }) => ({ ...state, user: { ...state.user, level: payload.userLevel } })),
	on(
		authActions.loginActionCompleted,
		authActions.refreshUserPermissionsFinished,
		authActions.checkForNonAnonymousSessionAction,
		(state, { payload }) => ({ ...state, ...JSON.parse(JSON.stringify(payload.sessionData)), isLoggedIn: true })
	),
	on(authActions.set2FaVerificationData, (state, { payload }) => ({ ...state, verification: payload.verification })),
	on(
		authActions.logoutActionCompleted,
		authActions.checkForAnonymousSessionAction,
		(state, { payload }) => ({ ...state, ...payload.sessionData, 'auto-max-maps': 0, isLoggedIn: false })
	)
);

export function AuthReducer(state: IAuthState | undefined, action: Action) {
	return authReducer(state, action);
}
