import { createAction, props } from '@ngrx/store';
import { AudioMessages } from '../../ts/enums/audio-message.enum';
import { IAudioDirectoryUser } from '../../ts/models/audio-directory-user.model';
import { IAudioSystemMessage } from '../../ts/models/audio-system-message.model';
import { IAudioDirectoryStore } from '../../ts/models/audio-directory-store.model';
import { GenericFormValue } from '../../../ui-components/generic-table/ts/types/generic-form-value.type';

export const fetchAudioData = createAction(
	'[Audio] Fetch User Audio Data',
	props<{ payload: { username: string } }>()
);

export const cancelAudioHttpObservables = createAction(
	'[Audio] Cancel Audio HTTP Observables'
);

export const finishFetchingAudioData = createAction(
	'[Audio] Finish Fetching Audio Data',
	props<{ payload: { audioStoreData: IAudioDirectoryStore[] } }>()
);

export const setDefaultAudioFile = createAction(
	'[Audio] Set Default Audio File',
	props<{ payload: { selectedAudioFileItem: IAudioDirectoryStore; audioId: string } }>()
);

export const setDefaultSystemAudioFile = createAction(
	'[Audio] Set Default System Audio File',
	props<{ payload: { type: AudioMessages; formValues: (GenericFormValue | null)[]; systemAudioData: IAudioSystemMessage } }>()
);

export const playAudioFile = createAction(
	'[Audio] Play Audio File',
	props<{ payload: { selectedAudioFileItem: IAudioDirectoryStore; audioId: string } }>()
);

export const fetchAudioUsers = createAction(
	'[Audio] Fetch Audio Users Data'
);

export const finishFetchingAudioUsers = createAction(
	'[Audio] Finish Fetching Audio Users Data',
	props<{ payload: { audioUsernames: IAudioDirectoryUser[] } }>()
);

export const deleteUsersAudioSet = createAction(
	'[Audio] Delete Users Audio Message set',
	props<{ payload: { username: string } }>()
);

export const clearAudioManagementStore = createAction(
	'[Audio] Clear Audio Management Store'
);

export const setVoicemailUserAction = createAction(
	'[Audio] Set User Voicemail Action',
	props<{
		payload: {
			user: string; msgSetPath: string; actionName: string; actionState: string;
			selectedAudioFileItem: IAudioDirectoryStore;
		};
	}>()
);

export const setSystemAction = createAction(
	'[Audio] Set System Action',
	props<{
		payload: {
			msgSetPath: string; actionName: string; actionState: string;
			systemAudioType: AudioMessages; formValues: GenericFormValue[]; systemAudioData: IAudioSystemMessage;
		};
	}>()
);

export const fetchAudioSystemDirectories = createAction(
	'[Audio] Fetch Audio System Directories'
);

export const finishFetchingAudioSystemDirectories = createAction(
	'[Audio] Finish Fetching Audio System Directories',
	props<{ payload: { audioSystemDirectories: IAudioSystemMessage[] } }>()
);

export const playSystemAudioFile = createAction(
	'[Audio] Play System Audio File',
	props<{ payload: { type: AudioMessages; formValues: GenericFormValue[]; systemAudioData: IAudioSystemMessage } }>()
);
