import { createAction, props } from '@ngrx/store';
import { INetworkingFtpForm } from '../ts/models/networking-ftp-form.model';
import { INetworkingFtp } from '../ts/models/networking-ftp.model';

export const startLoadingFtpConfiguration = createAction(
	'[FTP] Start Loading FTP Configuration'
);

export const finishLoadingFtpConfiguration = createAction(
	'[FTP] Finish Loading FTP Configuration',
	props<{ payload: { ftpConfiguration: INetworkingFtp } }>()
);

export const setFtpLoaderState = createAction(
	'[FTP] Set FTP Loader State',
	props<{ payload: { isLoading: boolean } }>()
);

export const setFtpSettings = createAction(
	'[FTP] Set FTP Settings',
	props<{ payload: { formData: INetworkingFtpForm } }>()
);

export const applyFtpSettings = createAction(
	'[FTP] Apply FTP Settings'
);

export const cancelFtpObservables = createAction(
	'[FTP] Cancel FTP Observables'
);
