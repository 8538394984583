import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { RoutesEnum } from '../../../../../ts/enums/routes.enum';
import { RecentCallRouter } from '../../ts/enums/recent-call-router.enum';
import { RecentCallModeClass } from '../../classes/recent-call-mode.class';

@Injectable({
	providedIn: 'root'
})
export class RecentCallSelfModeService extends RecentCallModeClass {

	constructor(
		private readonly router: Router
	) {
		super();
	}

	/**
	 * Routes to add mode of logged user, in SELF mode
	 * @param {string} username Owner of Recent Calls
	 */
	public openAddForm(username: string): void {
		this.router.navigate([{ replaceUrl: true }, { outlets: { [RoutesEnum.RECENT_CALLS]: this.getAddFormPrefix(username) } }]);
	}

	/**
	 * Returns add form URL prefix
	 * @param {string} username Owner of Recent Calls
	 */
	private getAddFormPrefix(username: string): string {
		return `${RoutesEnum.RECENT_CALLS}/${RecentCallRouter.ADD}/${username}`;
	}
}
