import { storeFreeze } from 'ngrx-store-freeze';
import { routerReducer } from '@ngrx/router-store';
import { environment } from '../../environments/environment';
import { ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';
import { IAuthState, AuthReducer } from '../components/auth/ngrx/auth.reducer';
import { OpcPanelClientReducer } from '../components/opc-panel/ngrx/opc-panel-client/opc-panel-client.reducer';

import * as fromRouter from '@ngrx/router-store';
import * as ipshReducer from '../components/ipsh/ngrx/ipsh.reducer';
import * as rioRvsReducer from '../components/rio-rvs/ngrx/rio-rvs.reducer';
import * as dnsReducer from '../components/networking/components/dns/ngrx/dns.reducer';
import * as ftpReducer from '../components/networking/components/ftp/ngrx/ftp.reducer';
import * as preferencesReducer from '../components/preferences/ngrx/preferences.reducer';
import * as httpReducer from '../components/networking/components/http/ngrx/http.reducer';
import * as automationMapsReducer from '../components/automation/ngrx/automation.reducer';
import * as mapReducer from '../components/automation/ngrx/map/automation-selected-map.reducer';
import * as audioFileSystemReducer from '../components/audio-editor/ngrx/system/audio-file-system.reducer';
import * as networkingInfoReducer from '../components/networking-info/ngrx/networking-info.reducer';
import * as searchPagesReducer from '../standalone/components/ui/side-bar/ngrx/side-bar-search.reducer';
import * as recentCallsReducer from '../components/PHONE_FEATURES/recent-calls/ngrx/recent-call.reducer';
import * as addressBookReducer from '../components/PHONE_FEATURES/address-book/ngrx/address-book.reducer';
import * as luaActuatorReducer from '../components/automation-program-edit/ngrx/actuator/automation-program-actuators.reducer';
import * as luaSpredsheetReducer from '../components/automation-program-edit/ngrx/spreadsheet/automation-program-spreadsheet.reducer';
import { IOpcPanelClientSettingsState } from '../components/opc-panel/ts/models/client-settings/opc-panel-client-settings-state.model';

const selectRouterState = (state: IAppState) => state.router;

export const getRouterUrlState = createSelector(
	selectRouterState,
	router => router.state.url
);

export interface IAppState {
	router: fromRouter.RouterReducerState<any>;
	auth: IAuthState;
	dns: dnsReducer.IDnsState;
	ftp: ftpReducer.IFtpState;
	http: httpReducer.IHttpState;
	ipsh: ipshReducer.IIpshState;
	rioRvs: rioRvsReducer.IRioRvsState;
	opcPanelClient: IOpcPanelClientSettingsState;
	pages: searchPagesReducer.ISideBarSearchState;
	recentCall: recentCallsReducer.IRecentCallState;
	addressBook: addressBookReducer.IAddressBookState;
	preferences: preferencesReducer.IPreferencesState;
	audioFileSystem: audioFileSystemReducer.IAudioFileSystem;
	automationMaps: automationMapsReducer.IAutomationMapState;
	networkingInfo: networkingInfoReducer.INetworkingInfoState;
	automationSelectedMap: mapReducer.IAutomationCurrentMapState;
	automationProgramActuator: luaActuatorReducer.IActuatorsState;
	automationProgramSpreadsheet: luaSpredsheetReducer.IAutomationProgramProjectState;
}

export const reducers: ActionReducerMap<IAppState> = {
	auth: AuthReducer,
	router: routerReducer,
	dns: dnsReducer.DnsReducer,
	ftp: ftpReducer.FtpReducer,
	ipsh: ipshReducer.IpshReducer,
	http: httpReducer.HttpReducer,
	rioRvs: rioRvsReducer.RioRvsReducer,
	opcPanelClient: OpcPanelClientReducer,
	pages: searchPagesReducer.SidebarSearchReducer,
	recentCall: recentCallsReducer.RecentCallReducer,
	addressBook: addressBookReducer.AddressBookReducer,
	preferences: preferencesReducer.PreferencesReducer,
	automationMaps: automationMapsReducer.AutomationReducer,
	networkingInfo: networkingInfoReducer.NetworkingInfoReducer,
	automationProgramActuator: luaActuatorReducer.ActuatorReducer,
	automationSelectedMap: mapReducer.AutomationCurrentMapReducer,
	audioFileSystem: audioFileSystemReducer.AudioFileSystemReducer,
	automationProgramSpreadsheet: luaSpredsheetReducer.AutomationProgramProjectReducer
};

export const metaReducers: MetaReducer<IAppState>[] = !environment.production ? [storeFreeze] : [];
