import { IAppState } from '../../../root-reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AutomationStoreKey } from '../ts/enums/automation-store-key.enum';
import { AutomationMapDeviceStoreKey } from '../ts/enums/automation-map-device-store-key.enum';

import * as automationReducer from './automation.reducer';
import * as availableCamerasReducer from './cameras/automation-camera.reducer';
import * as workingCamerasReducer from './used-cameras/automation-used-camera.reducer';
import * as automationDevicesReducer from './all-devices/automation-all-devices.reducer';
import * as automationMapDevicesReducer from './map-devices/automation-map-devices.reducer';
import * as automationVideoContainerReducer from './video-container/automation-video-container.reducer';
import * as automationTempDescriptionsReducer from './temporary-descriptions/automation-temporary-description.reducer';

export const selectAutomationMapsState = createFeatureSelector<automationReducer.IAutomationMapState>('automationMaps');
export const selectUsedCamerasState = createFeatureSelector<workingCamerasReducer.IAutomationUsedCameraState>('automationUsedCamera');
export const selectAutomationDevicesState = createFeatureSelector<automationDevicesReducer.IAutomationAllDevices>('automationAllDevices');
export const selectMapDevicesState = createFeatureSelector<automationMapDevicesReducer.IAutomationMapDevicesState>('automationMapDevices');

export const selectAvailableCamerasState = createFeatureSelector
<availableCamerasReducer.IAutomationAvailableCamerasState>('automationCamera');

export const selectVideoContainersState = createFeatureSelector
<automationVideoContainerReducer.IAutomationVideoContainerState>('automationVideoContainer');

export const selectTemporaryDescriptionState = createFeatureSelector
<automationTempDescriptionsReducer.IAutomationTemporaryDescriptionState>('automationTemporaryDescription');

export const selectCurrentlyUsedMapState = (select: IAppState) => select.automationSelectedMap;

export const selectCurrentlyUsedMap = createSelector(
	selectCurrentlyUsedMapState,
	mapState => mapState.currenltyUsedMap
);

export const selectAutomationMaps = () => createSelector(
	selectAutomationMapsState,
	automationReducer.selectAllAvailableMaps
);

export const selectAllDevicesUsedByMap = () => createSelector(
	selectMapDevicesState,
	automationMapDevicesReducer.selectAllDevicesUsedByMap
);

export const selectVideoContainers = () => createSelector(
	selectVideoContainersState,
	automationVideoContainerReducer.selectAllVideoContainers
);

export const selectAutomationDevices = () => createSelector(
	selectAutomationDevicesState,
	automationDevicesReducer.selectAllAutomationDevices
);

export const selectFromAutomationStore = (property: AutomationStoreKey) => createSelector(
	selectAutomationMapsState,
	automationState => automationState[property]
);

export const selectFromAutomationMapDevicesStore = (property: AutomationMapDeviceStoreKey) => createSelector(
	selectMapDevicesState,
	automationMapDevicesState => automationMapDevicesState[property]
);

export const selectAvailableCameras = () => createSelector(
	selectAvailableCamerasState,
	availableCamerasReducer.selectAllAvailableCameras
);

export const selectUsedCameras = () => createSelector(
	selectUsedCamerasState,
	workingCamerasReducer.selectAllUsedCameras
);

export const selectTemporaryDescriptions = () => createSelector(
	selectTemporaryDescriptionState,
	automationTempDescriptionsReducer.selectAllTemporaryDescriptions
);

// Select working cameras which are: choosen for map + working while fetching camera list
export const selectAllWorkingCameras = () => createSelector(
	selectUsedCameras(),
	selectAvailableCameras(),
	(usedCameras, availableCameras) => usedCameras.filter(usedCamera => {
		const { cameraId, deviceId } = usedCamera;

		return availableCameras.some(workingCamera => workingCamera.CamId === cameraId && workingCamera.DevId === deviceId);
	})
);

export const selectAllVideoContainersWithCameras = () => createSelector(
	selectVideoContainers(),
	videoContainers => videoContainers.filter(videoContainer => videoContainer.deviceId && videoContainer.cameraId)
);
