import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { INetworkingInfoResourceItem } from '../ts/models/resource/networking-info-resource-item.model';
import { INetworkingInfoCustomLogItem } from '../ts/models/networking-info-custom-log-item.model';
import * as networkingInfoActions from './networking-info.actions';

export const networkingInfoFeatureKey = 'networkingInfo';

export interface INetworkingInfoState extends EntityState<INetworkingInfoResourceItem> {
	isLoading: boolean;
	isLoadingNetworkLogs: boolean;
	networkLogs: INetworkingInfoCustomLogItem[];
}

export const adapter: EntityAdapter<INetworkingInfoResourceItem> = createEntityAdapter<INetworkingInfoResourceItem>();

export const initialNetworkingInfoState: INetworkingInfoState = adapter.getInitialState({
	isLoading: true,
	isLoadingNetworkLogs: true,
	networkLogs: []
});

const networkingInfoReducer = createReducer(
	initialNetworkingInfoState,
	on(networkingInfoActions.finishLoadingNetworkingResources, (state, { payload }) =>
		adapter.setAll(payload.networkingResources, { ...state, isLoading: false })),
	on(networkingInfoActions.finishLoadingSystemLogs, (state, { payload }) =>
		({ ...state, isLoadingNetworkLogs: false, networkLogs: payload.networkLogs }))
);

export function NetworkingInfoReducer(state: INetworkingInfoState | undefined, action: Action) {
	return networkingInfoReducer(state, action);
}

export const { selectAll: selectAllNeworkingResources } = adapter.getSelectors();
