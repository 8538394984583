import { Pipe, PipeTransform } from '@angular/core';
import { IGeneratedConfigurationParams } from '../components/networking/ts/models/generated-configuration-params.model';

@Pipe({
	name: 'formlyValidContainer'
})
export class FormlyValidContainerPipe implements PipeTransform {

	transform(fieldGroups: IGeneratedConfigurationParams[], formChangedCounter: number): boolean {
		return fieldGroups
			.filter(fieldGroup => !fieldGroup.hide)
			.some(fieldGroup => fieldGroup.formControl?.errors);
	}
}
