import { Action, createReducer, on } from '@ngrx/store';
import { IAudioSystemMessage } from '../../ts/models/audio-system-message.model';
import * as audioManagementActions from '../management/audio-management.actions';

export const audioFileSystemFeatureKey = 'audioFileSystem';

export interface IAudioFileSystem {
	isLoading: boolean;
	audioSystemDirectories: IAudioSystemMessage[];
}

export const initialFileSystemState: IAudioFileSystem = {
	isLoading: true,
	audioSystemDirectories: []
};

const audioFileSystemReducer = createReducer(
	initialFileSystemState,
	on(audioManagementActions.finishFetchingAudioSystemDirectories,
		(state, { payload }) => ({ ...state, audioSystemDirectories: payload.audioSystemDirectories, isLoading: false }))
);

export function AudioFileSystemReducer(state: IAudioFileSystem | undefined, action: Action) {
	return audioFileSystemReducer(state, action);
}
