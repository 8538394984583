import { Injectable } from '@angular/core';
import { OpcPanelUtilService } from './opc-panel-util.service';
import { ToastrType } from '../../../ts/enums/toastr-type.enum';
import { OpcPanelCardService } from './card/opc-panel-card.service';
import { MainUtilService } from '../../../services/main-util.service';
import { OpcPanelModalService } from './modal/opc-panel-modal.service';
import { OpcPanelCtiControl } from '../ts/enums/opc-panel-cti-control.enum';
import { OpcPanelCardCallService } from './card/opc-panel-card-call.service';
import { IOpcPanelCallRecord } from '../ts/models/session/opc-panel-call-record.model';
import { IOpcPanelUserRecord } from '../ts/models/session/opc-panel-user-record.model';
import { OpcPanelCallQueuePosition } from '../ts/enums/opc-panel-call-queue-position.enum';
import { IOpcPanelCtiControlPayload } from '../ts/models/opc-panel-cti-control-payload.model';
import { IOpcPanelCallQueueRecord } from '../ts/models/session/opc-panel-call-queue-record.model';
import { IAddressBookContact } from '../../PHONE_FEATURES/address-book/ts/models/address-book-contact.model';

@Injectable({
	providedIn: 'root'
})
export class OpcPanelPayloadService {

	constructor(
		private mainUtilService: MainUtilService,
		private opcPanelCardService: OpcPanelCardService,
		private opcPanelModalService: OpcPanelModalService,
		private opcPanelCardCallService: OpcPanelCardCallService
	) { }

	getTransferCallPayload(
		addressBookUser: IAddressBookContact, callRecords: IOpcPanelCallRecord[],
		callRecord: IOpcPanelCallRecord, activeUserRecord: IOpcPanelUserRecord
	): IOpcPanelCtiControlPayload {
		const { num, od } = addressBookUser;
		const { interface: userInterface, IfSubtype } = activeUserRecord;
		const { abilisName, user } = this.opcPanelCardService.getLoggedUserInfo();
		const callId = this.opcPanelModalService.getTransferCallId(callRecord, callRecords);

		const command = OpcPanelCtiControl['3PTRANSFER'];
		const isPriorityCall = this.opcPanelCardCallService.isPriorityCall(num);
		const canTransferAny = this.opcPanelCardCallService.canTransferAlertingWithPhaseAny(callRecords, userInterface, IfSubtype);

		const updatedNumber = OpcPanelUtilService.updatePhoneNumber(num);
		const phase = this.opcPanelCardCallService.getCorrectPhase(canTransferAny);
		const priorityPayload = this.opcPanelModalService.getTransferCallPriorityPayload(isPriorityCall);

		const parameters = { Par: { CallId: callId, NUM: updatedNumber, PHASE: phase, PREFIX: od, ...priorityPayload } };

		return { CtiControl: { A: abilisName, U: user, I: userInterface, Cmd: command, ...parameters } };
	}

	getTransferCustomNumberPayload(
		transferNumber: string, callRecords: IOpcPanelCallRecord[], activeUserRecord: IOpcPanelUserRecord
	): IOpcPanelCtiControlPayload | null {
		const lastAlertingCall = this.opcPanelCardCallService.getLastAlertingCall(callRecords);

		if (!lastAlertingCall) { return null; }

		const command = OpcPanelCtiControl['3PTRANSFER'];

		const { CallID } = lastAlertingCall;
		const { interface: userInterface, IfSubtype } = activeUserRecord;
		const { abilisName, user } = this.opcPanelCardService.getLoggedUserInfo();

		const isPriorityCall = this.opcPanelCardCallService.isPriorityCall(transferNumber);
		const canTransferAny = this.opcPanelCardCallService.canTransferAlertingWithPhaseAny(callRecords, userInterface, IfSubtype);

		const phase = this.opcPanelCardCallService.getCorrectPhase(canTransferAny);
		const updatedNumber = OpcPanelUtilService.updatePhoneNumber(transferNumber);
		const priorityPayload = this.opcPanelModalService.getTransferCallPriorityPayload(isPriorityCall);

		const parameters = { Par: { CallId: CallID, NUM: updatedNumber, PHASE: phase, ...priorityPayload } };

		return { CtiControl: { A: abilisName, U: user, I: userInterface, Cmd: command, ...parameters } };
	}

	getCallNumberFromModalPayload(
		addressBookUser: IAddressBookContact | null, activeUserRecord: IOpcPanelUserRecord, customInputValue?: string
	): IOpcPanelCtiControlPayload | null {
		const loggedUserInterface = this.opcPanelCardService.getLoggedUserActiveInterface();

		if (!loggedUserInterface) { return null; }

		const command = OpcPanelCtiControl.CALL;

		const { interface: userInterface } = activeUserRecord;
		const { abilisName, user } = this.opcPanelCardService.getLoggedUserInfo();
		const numberToUse = addressBookUser ? addressBookUser.num : customInputValue as string;
		const updatedNumber = OpcPanelUtilService.updatePhoneNumber(numberToUse);
		// check for precedence (TO-DO)
		const prefixPayload = addressBookUser ? { PREFIX: addressBookUser.od } : {};
		const parameters = { Par: { NUM: updatedNumber, ...prefixPayload } };

		return { CtiControl: { A: abilisName, U: user, I: userInterface, Cmd: command, ...parameters } };
	}

	getRecordCallPayload(callRecord: IOpcPanelCallRecord, activeUserRecord: IOpcPanelUserRecord): IOpcPanelCtiControlPayload | null {
		const { CallID } = callRecord;
		const { interface: userInterface } = activeUserRecord;
		const { abilisName, user } = this.opcPanelCardService.getLoggedUserInfo();

		const parameters = { Par: { CallId: CallID } };
		const recordCommand = this.opcPanelModalService.getRecordCommand(callRecord);

		if (!recordCommand) { return null; }

		return { CtiControl: { A: abilisName, U: user, I: userInterface, Cmd: recordCommand, ...parameters } };
	}

	getCloseCallPayload(callRecord: IOpcPanelCallRecord): IOpcPanelCtiControlPayload | null {
		const { CallID, abilis, user, interface: userInterface } = callRecord;
		const parameters = { Par: { CallId: CallID } };

		return { CtiControl: { A: abilis, U: user, I: userInterface, Cmd: OpcPanelCtiControl.CLOSE, ...parameters } };
	}

	getChangeQueuePositionPayload(
		callQueue: IOpcPanelCallQueueRecord, position: OpcPanelCallQueuePosition,
		isPriorityCall: boolean, phoneNumber: string, phonePrefix: string
	): IOpcPanelCtiControlPayload | null {
		const updatedNumber = OpcPanelUtilService.updatePhoneNumber(phoneNumber);
		const isPhoneNumberCorrect = OpcPanelUtilService.areNumberAndPrefixCorrect(updatedNumber, phonePrefix);

		if (isPhoneNumberCorrect) {
			const command = OpcPanelCtiControl.CHANGEQUEUE;
			const { abilis, user, interface: queueInterface, Uuid } = callQueue;

			const updatedUsername = user ? user : '';
			const updatedQueueInterface = queueInterface ? queueInterface : '';
			const priorityPayload = this.opcPanelModalService.getTransferCallPriorityPayload(isPriorityCall);
			const parameters = { Par: { UUID: Uuid, NUM: phonePrefix + updatedNumber, POSITION: position, ...priorityPayload } };

			return { CtiControl: { A: abilis, U: updatedUsername, I: updatedQueueInterface, Cmd: command, ...parameters } };
		}

		this.mainUtilService.showToastrMessage('Change queue not possible - number or prefix is not valid.', ToastrType.ERROR);

		return null;
	}
}
