export enum NetworkingInfoLogCode {
	BUILD = '00103',
	LAN = '00547',
	PPP = '00102',
	LOG_CLEARED = '00257',
	DISK_WARNING = '00642',
	DSL = '00514',
	MODEM_SPEED_CHANGES = '00639',
	MODEM_STATE_CHANGES = '00630',
	LAN_LAYER = '00094',
	INFO = '00270',
	INFO_WITH_PING = '00344',
	AIPT2_MAIN_PING_TUNNEL_PATH = '00538',
	AIPT2_MAIN_TUNNEL_PATH = '00537',
	AIPT2_MAIN_PING_TUNNEL = '00536',
	AIPT2_MAIN_TUNNEL = '00535',
	FAST_ETHERNET = '00095',
	AIPT2_OUTSP = '00681',
	AIPT2_MISSING = '00682',
	AIPT2_BACKUP = '00683',
	VPN_IPSEC = '00233'
}
