import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { MainUtilService } from '../../../../services/main-util.service';
import { specialAlertFnValue } from '../../utils/available-functions-operators';
import { AutomationProgramDevicesService } from '../devices/automation-program-devices.service';
import { IAutomationProgramEditFunction } from '../../ts/models/automation-program-edit-function.model';
import { AutomationProgramEditFunctonList } from '../../ts/enums/automation-program-edit-function-list.enum';
import { AutomatonProgramEditTransitionService } from '../transition/automaton-program-edit-transition.service';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSpreadsheetFunctionListService {

	constructor(
		private authService: AuthService,
		private automationProgramDevicesService: AutomationProgramDevicesService
	) { }

	getMatchingFunction(functionName: string): IAutomationProgramEditFunction | undefined {
		const availableFunctions = this.getAvailableFunctions();

		return availableFunctions.find(availableFunction => {
			const { shortFunctionDescription } = availableFunction;

			if (!shortFunctionDescription.startsWith('^')) {
				return shortFunctionDescription === functionName;
			}

			return new RegExp(shortFunctionDescription).test(functionName);
		});
	}

	getAvailableFunctions(): IAutomationProgramEditFunction[] {
		return [
			{
				maxArguments: 0, minArguments: 0, shortFunctionDescription: AutomationProgramEditFunctonList.WEEKDAY,
				functionExpression: () => {
					const abilisTimezone = this.authService.getAbilisTimezone();

					return MainUtilService.getLuxonWeekday(abilisTimezone);
				}
			},
			{
				maxArguments: 6, minArguments: 3, shortFunctionDescription: AutomationProgramEditFunctonList.DATETIME,
				functionExpression: (year, month, day, hours, minutes, seconds) => {
					const abilisTimezone = this.authService.getAbilisTimezone();

					const startDate = DateTime.local(2020, 1, 1, 0, 0, 0).setZone(abilisTimezone);
					const dateToCompare = DateTime.local(year, month, day, hours || 0, minutes || 0, seconds || 0).setZone(abilisTimezone);

					return dateToCompare.diff(startDate, ['days']).days;
				}
			},
			{
				maxArguments: 2, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.POW,
				functionExpression: (base, exp) => Math.pow(base, exp)
			},
			{
				maxArguments: 1, minArguments: 1, shortFunctionDescription: AutomationProgramEditFunctonList.SQRT,
				functionExpression: val1 => Math.sqrt(val1)
			},
			{
				maxArguments: 0, minArguments: 0, shortFunctionDescription: AutomationProgramEditFunctonList.HOUR,
				functionExpression: () => {
					const abilisTimezone = this.authService.getAbilisTimezone();
					const currentTime = DateTime.local().setZone(abilisTimezone);

					return currentTime.hour;
				}
			},
			{
				maxArguments: 4, minArguments: 4, shortFunctionDescription: AutomationProgramEditFunctonList.HMSM,
				functionExpression: (hours, minutes, seconds) => {
					const abilisTimezone = this.authService.getAbilisTimezone();
					const dateCompareFormat = { hour: hours, minute: minutes, second: seconds };

					const currentTime = DateTime.local().set({ millisecond: 0 }).setZone(abilisTimezone);
					const dateToCompare = DateTime.local().set(dateCompareFormat).setZone(abilisTimezone);

					const { hour: currentHour, minute: currentMinute, second: currentSecond } = currentTime;
					const { hour: compareHour, minute: compareMinute, second: compareSecond } = dateToCompare;

					return currentHour === compareHour && currentMinute === compareMinute && currentSecond === compareSecond;
				}
			},
			{
				maxArguments: 2, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.INV,
				functionExpression: () => false
			},
			{
				maxArguments: 1, minArguments: 1, shortFunctionDescription: AutomationProgramEditFunctonList.ALERT,
				functionExpression: () => specialAlertFnValue
			},
			{
				maxArguments: 0, minArguments: 0, shortFunctionDescription: AutomationProgramEditFunctonList.MINUTE,
				functionExpression: () => {
					const abilisTimezone = this.authService.getAbilisTimezone();
					const currentTime = DateTime.local().setZone(abilisTimezone);

					return currentTime.minute;
				}
			},
			{
				maxArguments: 0, minArguments: 0, shortFunctionDescription: AutomationProgramEditFunctonList.SECOND,
				functionExpression: () => {
					const abilisTimezone = this.authService.getAbilisTimezone();
					const currentTime = DateTime.local().setZone(abilisTimezone);

					return currentTime.second;
				}
			},
			{
				maxArguments: 0, minArguments: 0, shortFunctionDescription: AutomationProgramEditFunctonList.MILLISECOND,
				functionExpression: () => {
					const abilisTimezone = this.authService.getAbilisTimezone();
					const currentTime = DateTime.local().setZone(abilisTimezone);

					return currentTime.millisecond;
				}
			},
			{
				maxArguments: 0, minArguments: 0, shortFunctionDescription: AutomationProgramEditFunctonList.NOW,
				functionExpression: () => {
					const abilisTimezone = this.authService.getAbilisTimezone();

					const currentTime = DateTime.local().setZone(abilisTimezone);
					const dateToCompare = DateTime.local(2020, 1, 1, 0, 0, 0).setZone(abilisTimezone);

					return currentTime.diff(dateToCompare, ['days']).days;
				}
			},
			{
				maxArguments: 1, minArguments: 1, shortFunctionDescription: AutomationProgramEditFunctonList.EVEN,
				functionExpression: val1 => val1 % 2 === 0 ? 1 : 0
			},
			{
				maxArguments: 1, minArguments: 1, shortFunctionDescription: AutomationProgramEditFunctonList.ODD,
				functionExpression: val1 => Math.abs(val1 % 2) === 1 ? 1 : 0
			},
			{
				maxArguments: 1, minArguments: 1, shortFunctionDescription: AutomationProgramEditFunctonList.INT,
				functionExpression: val1 => Math.trunc(val1)
			},
			{
				maxArguments: 1, minArguments: 1, shortFunctionDescription: AutomationProgramEditFunctonList.LOG_10,
				functionExpression: val1 => Math.log10(val1)
			},
			{
				maxArguments: 1, minArguments: 1, shortFunctionDescription: AutomationProgramEditFunctonList.ABS,
				functionExpression: val1 => Math.abs(val1)
			},
			{
				maxArguments: 2, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.ROUND,
				functionExpression: (val1, val2) => Number.parseFloat(val1.toFixed(val2))
			},
			{
				maxArguments: 2, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.RANDBETWEEN,
				functionExpression: (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
			},
			{
				maxArguments: 10, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.AND,
				functionExpression: (val1, val2, ...rest) => {
					const functionArguments = [val1, val2, ...rest];

					return functionArguments.reduce((accumulator, value) => accumulator && value);
				}
			},
			{
				maxArguments: 10, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.OR,
				functionExpression: (val1, val2, ...rest) => {
					const functionArguments = [val1, val2, ...rest];

					return functionArguments.reduce((accumulator, value) => accumulator || value);
				}
			},
			{
				maxArguments: Infinity, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.AVERAGE,
				functionExpression: (val1, val2, ...rest) => {
					const functionArguments = [...rest, val1, val2];

					return functionArguments.reduce((accumulator, value) => accumulator + value, 0) / functionArguments.length;
				}
			},
			{
				maxArguments: 0, minArguments: 0, shortFunctionDescription: AutomationProgramEditFunctonList.TODAY,
				functionExpression: () => {
					const abilisTimezone = this.authService.getAbilisTimezone();

					const currentTime = DateTime.local().setZone(abilisTimezone);
					const dateToCompare = DateTime.local(2020, 1, 1, 0, 0, 0).setZone(abilisTimezone);

					return Math.floor(+currentTime.diff(dateToCompare, ['days']).days.toFixed(1));
				}
			},
			{
				maxArguments: Infinity, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.STDEV,
				functionExpression: (val1, val2, ...rest) => {
					const functionArguments = [...rest, val1, val2];
					const functionArgumentsLength = functionArguments.length;
					const mean = functionArguments.reduce((accumulator, fnArgument) => accumulator + fnArgument) / functionArgumentsLength;

					return Math.sqrt(
						functionArguments
							.map(value => Math.pow(value - mean, 2))
							.reduce((accumulator, fnArgument) => accumulator + fnArgument) / functionArgumentsLength
					);
				}
			},
			{
				maxArguments: Infinity, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.MAX,
				functionExpression: (val1, val2, ...rest) => [val1, val2, ...rest]
					.reduce((accumulator, element) => Math.max(accumulator, element), val1)
			},
			{
				maxArguments: Infinity, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.MIN,
				functionExpression: (val1, val2, ...rest) => [val1, val2, ...rest]
					.reduce((accumulator, element) => Math.min(accumulator, element), val1)
			},
			{
				maxArguments: 1, minArguments: 1, shortFunctionDescription: AutomationProgramEditFunctonList.MOD,
				functionExpression: val1 => Number((val1 - Math.trunc(val1)).toFixed(2))
			},
			{
				maxArguments: 0, minArguments: 0, shortFunctionDescription: AutomationProgramEditFunctonList.RAND,
				functionExpression: () => Math.random()
			},
			{
				maxArguments: 2, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.XOR,
				functionExpression: (val1, val2) => !(val1 === val2)
			},
			{
				maxArguments: 1, minArguments: 1, shortFunctionDescription: AutomationProgramEditFunctonList.NOT,
				functionExpression: val1 => !val1
			},
			{
				maxArguments: 3, minArguments: 3, shortFunctionDescription: AutomationProgramEditFunctonList.IF,
				functionExpression: (expression, ifTrue, ifFalse) => expression ? ifTrue : ifFalse
			},
			{
				maxArguments: 2, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.TIME,
				functionExpression: (hours, minutes) => {
					const abilisTimezone = this.authService.getAbilisTimezone();
					const dateCompareFormat = { hour: hours, minute: minutes, second: 0, millisecond: 0 };

					const currentTime = DateTime.local().set({ second: 0, millisecond: 0 }).setZone(abilisTimezone);
					const dateToCompare = DateTime.local().set(dateCompareFormat).setZone(abilisTimezone);

					const { hour: currentHour, minute: currentMinute, second: currentSecond } = currentTime;
					const { hour: compareHour, minute: compareMinute, second: compareSecond } = dateToCompare;

					return currentHour === compareHour && currentMinute === compareMinute && currentSecond === compareSecond;
				}
			},
			{
				maxArguments: 4, minArguments: 4, shortFunctionDescription: AutomationProgramEditFunctonList.BETWEEN,
				functionExpression: (startingHour, startingMinute, endingHour, endingMinute) => {
					const abilisTimezone = this.authService.getAbilisTimezone();
					const { hour, minute } = MainUtilService.getCurrentLuxonDatetimeObject(abilisTimezone);

					const currentTime = hour * 60 + minute;
					const endingTime = endingHour * 60 + endingMinute;
					const startingTime = startingHour * 60 + startingMinute;

					return currentTime >= startingTime && currentTime < endingTime;
				}
			},
			{
				maxArguments: 4, minArguments: 4, shortFunctionDescription: AutomationProgramEditFunctonList.IFNA,
				functionExpression: (expression, ifTrue, ifFalse, naValue, isSomeDeviceOffline) =>
					isSomeDeviceOffline ? naValue : expression ? ifTrue : ifFalse
			},
			{
				maxArguments: 2, minArguments: 2, shortFunctionDescription: AutomationProgramEditFunctonList.TRANS,
				functionExpression: (_, timespanInSeconds, tokenParenthesis) => {
					const cell = tokenParenthesis.substring(1).split(',')[0];
					const translateValuesBufffer = AutomatonProgramEditTransitionService.transitionValuesBuffer;
					const translateValue = translateValuesBufffer.get(`${cell}-${timespanInSeconds}`.toLowerCase());

					return translateValue || 0;
				}
			},
			{
				maxArguments: 1, minArguments: 1, shortFunctionDescription: AutomationProgramEditFunctonList.HISTORY,
				functionExpression: (timeInDays, referencedCell, selectedDevices) => {
					const lineDevice = AutomationProgramDevicesService.getReferencedLineDevice(referencedCell, selectedDevices);
					const isDigitalDevice = this.automationProgramDevicesService.isDigitalLineDevice(lineDevice?.port.toLowerCase() || '');

					const historicalValuesBuffer = AutomatonProgramEditTransitionService.historicalValuesBuffer;
					const historicalValue = historicalValuesBuffer.get(`${referencedCell}-${timeInDays}`.toLowerCase());

					return historicalValue || (isDigitalDevice ? false : 0);
				}
			}
		];
	}
}
