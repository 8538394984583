import { createReducer, on, Action } from '@ngrx/store';
import { PhoneMenuModes } from '../ts/enums/phone-menu-modes.enum';
import { IPhoneCallRecord } from './../ts/models/phone-call-record.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IPhoneCallRecordInfo } from '../ts/models/phone-call-record-info.model';
import { IPreferencesStore } from './../../preferences/ts/models/preferences-store.model';
import { IRecentCall } from './../../PHONE_FEATURES/recent-calls/ts/models/recent-call-table.model';
import { IOpcPanelUserRecord } from './../../opc-panel/ts/models/session/opc-panel-user-record.model';
import * as phoneComponentActions from './phone-component.actions';

export const phoneComponentFeatureKey = 'phoneComponent';

export interface IPhoneComponentState extends EntityState<IPhoneCallRecord> {
	input: string; // input field for user to enter phone number
	fetchError: boolean; // if opc session has issues while fetching
	status: PhoneMenuModes; // phone status - to present different footer buttons (when call is active, on hold etc.)
	clearNextInput: boolean; // if when user types new digit, previous input should be cleared completely
	priorityMode: boolean; // if true, priority (precedence) mode is active
	callRecordsInfo: IPhoneCallRecordInfo[]; // contains historical data of call records state changes
	recentCalls: IRecentCall[]; // holds recent calls log
	unconfiguredFooter: boolean; // if true, all footer buttons are unable to execute action
	userRecord: IOpcPanelUserRecord | null; // object from OPC session response, to keep info about SMS availability and calltry
	userPreferences: null | IPreferencesStore; // object from Preferences to have info about if user set DND, FORKING, FORWARDING etc...
}

export const adapter: EntityAdapter<IPhoneCallRecord> = createEntityAdapter<IPhoneCallRecord>();

export const initialPhoneComponentState: IPhoneComponentState = adapter.getInitialState({
	input: '',
	recentCalls: [],
	userRecord: null,
	fetchError: false,
	priorityMode: false,
	callRecordsInfo: [],
	clearNextInput: false,
	userPreferences: null,
	unconfiguredFooter: false,
	status: PhoneMenuModes.CORE
});

export const phoneComponentReducer = createReducer(
	initialPhoneComponentState,
	on(phoneComponentActions.setUnconfiguredFooterState, (state, { payload }) =>
		({ ...state, unconfiguredFooter: payload.unconfiguredFooter })),
	on(phoneComponentActions.clearPhoneStore, (state) => {
		const { userPreferences, userRecord, recentCalls } = state;

		return { ...initialPhoneComponentState, userPreferences, userRecord, recentCalls };
	}),
	on(phoneComponentActions.setStatus, (state, { payload }) => ({ ...state, status: payload.status })),
	on(phoneComponentActions.clearInputValue, (state) => ({ ...state, input: '', clearNextInput: false })),
	on(phoneComponentActions.setFetchError, (state, { payload }) => ({ ...state, fetchError: payload.active })),
	on(phoneComponentActions.addUserRecord, (state, { payload }) => ({ ...state, userRecord: payload.userRecord })),
	on(phoneComponentActions.setCallRecords, (state, { payload }) => adapter.setAll(payload.callRecords, { ...state })),
	on(phoneComponentActions.setPriorityMode, (state, { payload }) => ({ ...state, priorityMode: payload.priorityMode })),
	on(phoneComponentActions.setRecentCallsLog, (state, { payload }) => ({ ...state, recentCalls: payload.recentCalls })),
	on(phoneComponentActions.setInputValue, (state, { payload }) => ({ ...state, input: payload.value, clearNextInput: false })),
	on(phoneComponentActions.setCallRecordsInfo, (state, { payload }) => ({ ...state, callRecordsInfo: payload.callRecordsInfo })),
	on(phoneComponentActions.setUserPreferences, (state, { payload }) => ({ ...state, userPreferences: payload.userPreferences })),
	on(phoneComponentActions.addInputValue, (state, { payload }) => {
		const { clearNextInput } = state;
		const input = !clearNextInput ? state.input + payload.value : payload.value;

		return { ...state, input, clearNextInput: false };
	}),
	on(phoneComponentActions.setClearNextInputState, (state, { payload }) => ({ ...state, clearNextInput: payload.clearNextInput })),
	on(phoneComponentActions.removeLastInputValue, (state) => ({ ...state, input: state.input.slice(0, -1), clearNextInput: false })),
	on(phoneComponentActions.updateCallRecords, (state, { payload }) =>
		adapter.updateMany(payload.callRecords.map(callRecord => Object.assign({ id: callRecord.id, changes: callRecord })), { ...state }))
);

export function PhoneReducer(state: IPhoneComponentState | undefined, action: Action) {
	return phoneComponentReducer(state, action);
}

export const { selectAll: selectAllCallRecords } = adapter.getSelectors();
