import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UnitSeparatorPipe } from '../pipes/unit-separator.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiComponentsFeatureModule } from './ui-components.module';
import { AngularBootstrapModule } from './angular-bootstrap.module';
import { ValidationErrorPipe } from '../pipes/validation-error.pipe';
import { SharedPipeDirectiveModule } from './shared-pipe-directive.module';
import { TextOverflowDetectPipe } from '../pipes/text-overflow-detect.pipe';
import { AutomationDeviceShortPipe } from '../pipes/automation-device-short.pipe';
import { PhoneComponentFeatureModule } from '../components/phone/modules/phone-component-feature.module';
import { NetworkModalComponent } from '../components/networking/components/network-modal/network-modal.component';
import { GenericTableFeatureModule } from '../components/ui-components/generic-table/modules/generic-table-feature.module';
import { DrivePipe } from '../pipes/drive.pipe';




@NgModule({
	imports: [
		A11yModule,
		FormsModule,
		CommonModule,
		DragDropModule,
		ReactiveFormsModule,
		AngularBootstrapModule,
		SharedPipeDirectiveModule,
		GenericTableFeatureModule,
		TranslateModule.forChild(),
		UiComponentsFeatureModule,
		PhoneComponentFeatureModule,
	],
	declarations: [
		SafeUrlPipe,
		UnitSeparatorPipe,
		ValidationErrorPipe,
		NetworkModalComponent,
		TextOverflowDetectPipe,
		AutomationDeviceShortPipe,
		DrivePipe
	],
	exports: [
		A11yModule,
		SafeUrlPipe,
		FormsModule,
		CommonModule,
		DragDropModule,
		TranslateModule,
		UnitSeparatorPipe,
		ValidationErrorPipe,
		ReactiveFormsModule,
		NetworkModalComponent,
		AngularBootstrapModule,
		TextOverflowDetectPipe,
		SharedPipeDirectiveModule,
		UiComponentsFeatureModule,
		GenericTableFeatureModule,
		AutomationDeviceShortPipe,
		PhoneComponentFeatureModule,
		DrivePipe
	],
})
export class SharedModule { }
