import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RoutesEnum } from '../../../ts/enums/routes.enum';
import { ToastrType } from '../../../ts/enums/toastr-type.enum';
import { ISelectData } from '../../../ts/models/select-data.model';
import { MainUtilService } from '../../../services/main-util.service';
import { IProjectOptions } from '../ts/models/automation-program-edit-project-options.model';
import { AutomationProgramDevicesService } from './devices/automation-program-devices.service';
import { AutomationProgramEditNgrxService } from './ngrx/automation-program-edit-ngrx.service';
import { IAutomationProgramEditLineDevice } from '../ts/models/automation-program-edit-line-device.model';
import { AutomationProgramProjectTimeout } from '../ts/enums/automation-program-edit-project-timeout.enum';
import { IAutomationProgramEditSaveProject } from '../ts/models/automation-program-edit-saved-project.model';
import { AutomationProgramSpreadsheetNgrxService } from './ngrx/automation-program-spreadsheet-ngrx.service';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramEditModalService {

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private translate: TranslateService,
		private mainUtilService: MainUtilService,
		private automationNgrxService: AutomationProgramEditNgrxService,
		private automationDevicesService: AutomationProgramDevicesService,
		private automationSpreadsheetNgrxService: AutomationProgramSpreadsheetNgrxService
	) { }

	buildProjectOptionsForm(): FormGroup {
		return this.formBuilder.group({
			ProjectTimeoutUnit: [1],
			ProjectTimeout: [AutomationProgramProjectTimeout.SECONDS]
		});
	}

	buildSaveProjectForm(): FormGroup {
		return this.formBuilder.group({ scriptName: [''] });
	}

	checkSameScriptOptions(projectOptions: IProjectOptions, initialData: IProjectOptions): boolean {
		const { ProjectTimeout, ProjectTimeoutUnit } = projectOptions;
		const { ProjectTimeout: initialProjectTimeout, ProjectTimeoutUnit: initialProjectTimeoutUnit } = initialData;

		const currentOptions = this.formatScriptOptionFormCompare(ProjectTimeout, ProjectTimeoutUnit);
		const initialOptions = this.formatScriptOptionFormCompare(initialProjectTimeout, initialProjectTimeoutUnit);

		return currentOptions === initialOptions;
	}

	formatScriptOptionFormCompare(ProjectTimeout: number, ProjectTimeoutUnit: AutomationProgramProjectTimeout): string {
		return JSON.stringify({ ProjectTimeout: ProjectTimeout.toString(), ProjectTimeoutUnit });
	}

	handleSaveProject(newScriptName: string, currentScriptName: string, scripts: string[]): boolean {
		const isScriptExist = currentScriptName !== newScriptName && scripts.includes(newScriptName);
		const scriptInfo = this.translate.instant('AUTO.RULES.EDIT.scriptExists');

		if (!isScriptExist || (isScriptExist && confirm(scriptInfo))) {
			this.automationSpreadsheetNgrxService.saveProject(currentScriptName, newScriptName);

			return true;
		}

		return false;
	}

	addNewProject(): void {
		const automationProgramsEdit = RoutesEnum.AUTOMATION_PROGRAMS_EDIT;
		const outlets = { [automationProgramsEdit]: `${automationProgramsEdit}/new` };

		this.automationSpreadsheetNgrxService.setProjectLineDevices([], '');
		this.router.navigate([{ replaceUrl: true }, { outlets }]);
	}

	editProjectDevices(selectedDevices: IAutomationProgramEditLineDevice[]): void {
		const updatedLineDevices = this.automationDevicesService.formatProjectDevicesFromModal(selectedDevices);

		this.automationSpreadsheetNgrxService.setProjectLineDevices(
			this.automationDevicesService.sortNonExpressionPorts(updatedLineDevices)
		);
	}

	setSpreadsheetDevices(selectedDevices: IAutomationProgramEditLineDevice[]): void {
		const lineDevices = this.automationDevicesService.mapLineDevicesWithIndex(selectedDevices);

		this.automationSpreadsheetNgrxService.setProjectLineDevices(
			this.automationDevicesService.sortNonExpressionPorts(lineDevices)
		);
	}

	importJsonProject(file: Event): void {
		const reader: FileReader = new FileReader();
		const fileList = (file.target as HTMLInputElement).files as FileList;

		reader.onload = () => {
			const fileContent = reader.result as string;
			const isJsonStructure = MainUtilService.hasJsonStructure(fileContent);

			if (isJsonStructure) {
				const dataToBeSaved = JSON.parse(fileContent);
				const validJsonStructure = this.isValidAutomationTableJson(dataToBeSaved);

				if (validJsonStructure) {
					this.automationNgrxService.importScript(dataToBeSaved);

					return;
				}
			}

			this.mainUtilService.showToastrMessage(this.translate.instant('AUTO.RULES.EDIT.incorrectJson'), ToastrType.INFO);
		};

		reader.readAsText(fileList[0]);
	}

	isValidAutomationTableJson(savedData: IAutomationProgramEditSaveProject): boolean {
		return savedData.hasOwnProperty('scriptName') && savedData.hasOwnProperty('storedData') && savedData.storedData.length === 5;
	}

	getAvailableProjectOptions$(): Observable<ISelectData[]> {
		return of([
			{ uiValue: 'AUTO.EDITPROGS.comboMiliseconds', value: AutomationProgramProjectTimeout.MILLISECONDS },
			{ uiValue: 'AUTO.EDITPROGS.comboSecs', value: AutomationProgramProjectTimeout.SECONDS },
			{ uiValue: 'AUTO.EDITPROGS.comboMins', value: AutomationProgramProjectTimeout.MINUTES }
		]);
	}

	onEditAutomationProject(automationDevicesForm: FormGroup, bsModalRef: BsModalRef): void {
		const { valid, value } = automationDevicesForm;

		if (valid) {
			this.editProjectDevices(value.selectedDevices);

			bsModalRef.hide();
		}
	}
}
