import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { IAppState } from '../../../root-reducers';
import { RioRvsTab } from '../ts/enums/rio-rvs-tab.enum';
import { IWioNodeModel } from '../ts/models/wio-node.model';
import { IRioRvsWioDevice } from '../ts/models/rio-rvs-wio-device.model';
import { RioRvsWioIohubShortDeviceClass } from '../ts/enums/rio-rvs-wio-iohub-short-device-class.enum';
import * as rioRvsSelectors from '../ngrx/rio-rvs.selector';
import * as rioRvsActions from '../ngrx/rio-rvs.action';

@Injectable({
	providedIn: 'root'
})
export class WioNgrxService {

	constructor(
		private store: Store<IAppState>
	) { }

	fetchAllWioSlaveNodes(): void {
		this.store.dispatch(rioRvsActions.fetchAllWioSlaveNodes());
	}

	removeWioSlaveNode(selectedWioSlaveNode: IWioNodeModel, selectedWioMaster: IRioRvsWioDevice): void {
		const payload = { selectedWioSlaveNode, selectedWioMaster };

		this.store.dispatch(rioRvsActions.removeWioSlaveNode({ payload }));
	}

	updateWioSlaveInformation(wioSlaveNode: IWioNodeModel, newDescription: string): void {
		const payload = { wioSlaveNode, newDescription };

		this.store.dispatch(rioRvsActions.updateWioSlaveInformation({ payload }));
	}

	setMultipleWioValuesAtOnce(
		tab: RioRvsTab, device: IRioRvsWioDevice,
		parameter: string, value: string, selectedWioNodeItem: IWioNodeModel
	): void {
		const payload = { tab, parameter, value, device, selectedWioNodeItem };

		this.store.dispatch(rioRvsActions.setMultipleWioValuesAtOnce({ payload }));
	}

	setLoadingWioSlaveNodes(isLoading: boolean): void {
		const payload = { isLoading };

		this.store.dispatch(rioRvsActions.setLoadingWioSlaveNodes({ payload }));
	}

	refreshWioNodes(selectedWioDevice: IRioRvsWioDevice, selectedWioNode?: number): void {
		const payload = { selectedWioDevice, selectedWioNode };

		this.store.dispatch(rioRvsActions.refreshWioNodes({ payload }));
	}

	selectWioNodeDevices$(wioResourceName: string): Observable<IWioNodeModel[]> {
		return this.store.pipe(select(rioRvsSelectors.selectWioSlaveNodes(wioResourceName))) as Observable<IWioNodeModel[]>;
	}

	selectWioSlaveNode$(wioSlaveIndex: string, nodeInd: number): Observable<IWioNodeModel> {
		return this.selectWioNodeDevices$(`Wio-${wioSlaveIndex}`)
			.pipe(
				map(wioDevices => wioDevices.find(wioDevice => wioDevice.nodeInd === nodeInd)),
				filter((matchingWioDevice): matchingWioDevice is IWioNodeModel => !!matchingWioDevice)
			);
	}

	selectWioNodeLineDevices$<T>(deviceType: RioRvsWioIohubShortDeviceClass, wionode: number): Observable<T> {
		return this.store.pipe(select(rioRvsSelectors.selectSpecificWioLineDevices(deviceType, wionode))) as unknown as Observable<T>;
	}
}
