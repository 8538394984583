import { Injectable } from '@angular/core';
import { AuthNgrxService } from './auth-ngrx.service';
import { ISectionPermission } from '../ts/models/section-permission.model';
import { ActivatedRouteSnapshot, CanActivate, Data } from '@angular/router';
import { filter, forkJoin, map, Observable, of, switchMap, take } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MainAuthGuardService implements CanActivate {

	constructor(
		private authNgrxService: AuthNgrxService
	) { }

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return this.checkRoutePermission(route);
	}

	checkRoutePermission(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
		const urlToUse = (route.data as Data).url as string;

		return this.authNgrxService.isUserLoggedIn$()
			.pipe(
				filter(loggedIn => loggedIn),
				switchMap(() => this.authNgrxService.selectSectionPermissions$()),
				switchMap(sectionPermissions => forkJoin({
					sectionPermissions: of(sectionPermissions),
					haveAdminUserLevel: this.authNgrxService.haveAdminUserLevel$().pipe(take(1))
				})),
				map(response => {
					const { sectionPermissions, haveAdminUserLevel } = response;

					return !urlToUse.includes('admin') ? sectionPermissions[urlToUse as keyof ISectionPermission] : haveAdminUserLevel;
				})
			);
	}
}
