export enum RoutesEnum {
	SMS = 'sms',
	PHONE = 'phone',
	CP = 'cp',
	DISK = 'disk-analyzer',
	DISA = 'disa',
	OPC_PANEL = 'opc-panel',
	PREFERENCES = 'preferences',
	ADDRESSBOOK = 'address-book',
	BASIC_LIST = 'basic-list',
	USER_SETTINGS = 'user-settings',
	RECENT_CALLS = 'recent-calls',
	PHONE_TRAFFIC = 'phone-traffic',
	NETWORK = 'network',
	LAN_MONITOR = 'lan-monitor',
	NETWORK_SETTINGS = 'network-settings',
	NETWORK_INFO = 'network-info',
	AUTOMATION = 'automation',
	RIO_RVS = 'rio-rvs',
	AUTOMATION_MAPS = 'automation-maps',
	AUTOMATION_LOG = 'automation-log',
	AUTOMATION_PROGRAMS_EDIT = 'table-programs-edit',
	AUTOMATION_PROGRAM = 'automation-program',
	TOOLS = 'tools',
	AUDIO = 'audio',
	ALERTS = 'alerts',
	LOGIN = 'login',
	VERIFICATION = 'verification',
 	ROUTINGS = 'routings',
	USERS_MANAGEMENT = 'admin-preferences',
	EXTENSIONS = 'extensions',
	USERS_RIGHTS = 'users-rights',
	VIDEORECORDER = 'videorecorder',
	ADMINISTRATION = 'administration',
	NOT_FOUND = '404-not-found',
	MISMATCH = '**',
	VIRTUAL_OFFICE = 'virtual-office',
	PORTS_CONFIGURATION = 'ports-configuration',
	ALL_RECENT_CALLS = 'all-recent-calls',
	USERS_RECENT_CALLS = 'users-recent-calls',
	SYSTEM_PARAMETERS = 'sys-parameters',
	WHITELIST = 'dns-whitelist',
	BLACKLIST = 'dns-blacklist',
	SYSTEM_MANAGEMENT = 'system-mangement',

	NETWORK_ADMIN_FEATURES = 'network-admin-features'
}
