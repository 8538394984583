import { RouterStateSerializer } from '@ngrx/router-store';
import { Params, RouterStateSnapshot, Data } from '@angular/router';

export interface IRouterStateUrl {
	data: Data;
	url: string;
	params: Params;
	queryParams: Params;
}

export class CustomSerializer implements RouterStateSerializer<IRouterStateUrl> {

	serialize(routerState: RouterStateSnapshot): IRouterStateUrl {
		let route = routerState.root;

		while (route.firstChild) {
			route = route.firstChild;
		}

		const { url, root: { queryParams } } = routerState;
		const { params, data } = route;

		// Only return an object including the URL, params, query params and data
		// instead of the entire snapshot
		return { url, params, queryParams, data };
	}
}
