export enum UserPreferencesPhoneTab {
	CTIP = 'CTIP',
	CLUS = 'CLUS',
	VO = 'VO',
	SIP = 'SIP',
	ADDRESS_BOOK = 'ADDRESS_BOOK',
	OPC = 'OPC',
	LDAP = 'LDAP',
	UBR = 'UBR'
}
