import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, of, filter } from 'rxjs';
import { IStore } from '../ts/models/store.model';
import { HttpClient } from '@angular/common/http';
import { AuthNgrxService } from './auth-ngrx.service';
import { AuthUserInfo } from '../ts/enums/user-auth.enum';
import { LoginMethod } from '../ts/enums/login-method.enum';
import { AuthHttpUtilService } from './auth-http-util.service';
import { IUserSessionData } from '../ts/models/permissions.model';
import { environment } from '../../../../environments/environment';
import { ISelectData } from '../../../ts/models/select-data.model';
import { ILoginResponse } from '../ts/models/login-response.model';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { IActionResponse } from '../../../ts/models/action-response.model';
import { FeaturePermissionsName } from '../../core/ts/enums/feature-permission-name.enum';
import { FeaturePermissionsService } from '../../core/services/feature-permissions.service';

@Injectable({
	providedIn: 'root'
})
export class AuthHttpService {

	private readonly loginUrl: string = environment.abilisUrl + '/login.json';
	private readonly logoutUrl: string = environment.abilisUrl + '/logout.json';
	private readonly userPermissionsUrl: string = environment.abilisUrl + '/sys/pub/home.json';

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private authNgrxService: AuthNgrxService,
		private readonly authHttpUtilService: AuthHttpUtilService,
		private featurePermissionService: FeaturePermissionsService
	) {}

	// HTTP REQUESTS

	public login$(payload: Record<string, any>, verificationCodeLogin = false): Observable<ILoginResponse> {
		return this.http.post<ILoginResponse>(this.loginUrl, payload)
			.pipe(
				tap(response => this.authHttpUtilService.handleLoginResponse(response, verificationCodeLogin))
			);
	}

	logout$(): Observable<IActionResponse> {
		return this.http.post<IActionResponse>(this.logoutUrl, {});
	}

	isRemoteIpAvailable$(remoteIp: string): Observable<boolean> {
		return this.http.get<IUserSessionData>(`https://${remoteIp}/sys/pub/home.json`)
			.pipe(
				map(response => response.Response.user.username !== AuthUserInfo.ANONYMOUS),
				catchError(() => of(false))
			);
	}

	fetchUserSessionData$(): Observable<IStore> {
		return this.http.get<IUserSessionData>(this.userPermissionsUrl)
			.pipe(
				map(response => {
					const { user, serv } = response.Response;
					const permissions = this.featurePermissionService.formatPermissions(serv, user.level);

					return { ...response.Response, permissions };
				}),
				tap(sessionData => this.authService.setSessionData(sessionData))
			);
	}

	checkUserAuth$(permissionName: FeaturePermissionsName): Observable<boolean> {
		return this.authNgrxService.selectUserPermission$(permissionName)
			.pipe(
				take(1),
				map(selectedPermission => selectedPermission !== null ? !selectedPermission.isFeatureDisabled : false)
			);
	}

	logoutNonAnonymousUser$(): Observable<IActionResponse> {
		return this.authNgrxService.selectUsername$()
			.pipe(
				take(1),
				switchMap(username => username !== AuthUserInfo.ANONYMOUS ? this.logout$() : of(null)),
				filter((logoutData): logoutData is IActionResponse => !!logoutData),
				tap(() => this.authNgrxService.logoutNonAnonymousAction())
			);
	}

	getLoginTimeout$(): Observable<string> {
		return this.http.get<IUserSessionData>(this.userPermissionsUrl)
			.pipe(
				map(response => response.Response['login-tout']),
				catchError(() => of('1 days 0 hours 0 min'))
			);
	}

	getLoginInfoData$(): Observable<ISelectData[]> {
		return this.getLoginTimeout$()
			.pipe(
				switchMap(defaultTimeout => {
					const [days, hours, minutes] = defaultTimeout
						.split(' days')
						.join('')
						.split(' hours')
						.join('')
						.split(' min')
						.join('')
						.split(' ');

					const translateAdditionParams = { days, hours, minutes };

					return of([
						{ uiValue: 'LOGIN.browserClose', value: LoginMethod.BROWSER_CLOSE },
						{
							uiValue: 'LOGIN.default', value: LoginMethod.CONFIGURED_TIMEOUT,
							translateAddition: 'LOGIN.timeout', translateAdditionParams
						},
						{ uiValue: 'LOGIN.permanent', value: LoginMethod.PERMANENT }
					]);
				})
			);
	}
}
