import { createAction, props } from '@ngrx/store';
import { ISearchItem } from './../../../../../components/core/ts/models/search-item.model';
import { ISearchNavigationResponse } from './../../../../../components/core/ts/models/search-navigation-response.model';

export const loadSearchBarPages = createAction(
	'[SideBar Search Action] Load Search Bar Pages'
);

export const finishLoadingPages = createAction(
	'[SideBar Search Action] Finish Loading Pages',
	props<{ payload: { pages: ISearchNavigationResponse } }>()
);

export const filterPagesBySearchTerm = createAction(
	'[SideBar Search Action] Filter Pages By Search Tearm',
	props<{ payload: { value: string } }>()
);

export const setPagesFilteredBySearchTerm = createAction(
	'[SideBar Search Action] Set Pages Filtered By Search Tearm',
	props<{ payload: { pages: ISearchItem[] } }>()
);

