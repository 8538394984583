import { createAction, props } from '@ngrx/store';
import { NetworkingInfoMobilePinState } from '../ts/enums/networking-info-mobile-pin-state.enum';
import { INetworkingInfoCustomLogItem } from '../ts/models/networking-info-custom-log-item.model';
import { INetworkingInfoResourceItem } from '../ts/models/resource/networking-info-resource-item.model';
import { INetworkingInfoMobileTrfaSavePayload } from '../ts/models/mobile/networking-info-mobile-trfa-save-payload.model';

export const loadNetworkingResources = createAction(
	'[Networking Info] Load Networking Resources'
);

export const loadNetworkLogs = createAction(
	'[Networking Info] Load Networking Logs'
);

export const setTrfaWanConfiguration = createAction(
	'[Networking Info] Set WAN TRFA Configuration',
	props<{ payload: { resource: INetworkingInfoResourceItem; trfaForm: INetworkingInfoMobileTrfaSavePayload } }>()
);

export const setSdwanControl = createAction(
	'[Networking Info] Set SDWAN Control',
	props<{ payload: { resource: INetworkingInfoResourceItem; vpnValue: string } }>()
);

export const setPinCardState = createAction(
	'[Networking Info] Set PIN Card State',
	props<{ payload: { id: number; pin: string; state: NetworkingInfoMobilePinState } }>()
);

export const finishLoadingNetworkingResources = createAction(
	'[Networking Info] Finish Loading Networking Resources',
	props<{ payload: { networkingResources: INetworkingInfoResourceItem[] } }>()
);

export const finishLoadingSystemLogs = createAction(
	'[Networking Info] Finish Loading Network Logs',
	props<{ payload: { networkLogs: INetworkingInfoCustomLogItem[] } }>()
);

export const cancelNetworkingHTTPObservables = createAction(
	'[Networking Info] Cancel Networking HTTP Observables'
);
