import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IUserListResponse } from '../../../../ts/models/user-list-response.model';
import * as customOperators from '../../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class BasicUserPreferencesTableHttpService {

	private readonly getConfiguredUsernamesUrl: string = environment.abilisUrl + '/sys/admin/getusernames.json';

	constructor(
		private http: HttpClient
	) { }

	fetchUniqueUsernameRealnameValidator$(): Observable<string[]> {
		return this.http.get<IUserListResponse>(this.getConfiguredUsernamesUrl)
			.pipe(
				map(response => {
					const { Users, rsp } = response.Response;

					if (rsp) { return []; }

					const users = Users.reduce((accumulator, userItem) => [...accumulator, userItem.user], [] as string[]);

					return Array.from(new Set(users));
				}),
				customOperators.retryFromError$(1000)
			);
	}
}
