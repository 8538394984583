import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './services/others/global.service';
import { Component, HostListener, Injector, ChangeDetectionStrategy } from '@angular/core';
import { LanguageService } from './components/OTHER_FEATURES/user-settings/services/language/language.service';

export let appInjector: Injector;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

	@HostListener('window:resize', ['$event'])
	public onResize(): void {
		this.globalService.emitWindowSize(window.innerWidth);
	}

	constructor(
		private readonly injector: Injector,
		private readonly translate: TranslateService,
		private readonly globalService: GlobalService
	) {
		this.translate.addLangs(LanguageService.getAvailableLanguages);

		appInjector = this.injector;
	}
}
