import { Injectable } from '@angular/core';
import { PhoneUtilService } from './../phone-util.service';
import { PhoneCallRecord } from '../../ts/types/phone-call-record.type';
import { PhoneCallActive } from './../../ts/enums/phone-call-active.enum';
import { IPhoneCallRecord } from './../../ts/models/phone-call-record.model';
import { IPhoneCallRecordInfo } from '../../ts/models/phone-call-record-info.model';
import { PhoneCallAlertingCalling } from './../../ts/enums/phone-call-alerting-calling.enum';
import { OpcPanelCallDirection } from './../../../opc-panel/ts/enums/opc-panel-call-direction.enum';
import { OpcPanelCallRecordState } from './../../../opc-panel/ts/enums/opc-panel-call-record-state.enum';

@Injectable({
	providedIn: 'root'
})
export class PhoneCallsLogService {

	constructor() { }

	private generateCallRecordId(callRecord: PhoneCallRecord): string {
		const { State, CallID } = callRecord;
		const callingNameNumber = this.handleCalledNumberName(callRecord);

		return `${State}-${callingNameNumber}-${CallID}`;
	}

	private getCalledNumberName(callRecord: PhoneCallRecord): string {
		return callRecord.State === OpcPanelCallRecordState.NULL ? '' : this.handleCalledNumberName(callRecord);
	}

	private handleCalledNumberName(callRecord: PhoneCallRecord): string {
		const { Direction, CallingName, Calling, Called, CalledName } = callRecord;

		return PhoneUtilService.isInDirection(Direction) ? CalledName || Called : CallingName || Calling;
	}

	private getMainCallIcon(callRecord: PhoneCallRecord): string {
		const { State, Direction } = callRecord;

		if (!callRecord.hasOwnProperty('callIcon')) { return ''; }

		const previousCallIcon = (callRecord as IPhoneCallRecord).callIcon;

		switch (State) {
		case OpcPanelCallRecordState.NULL:
			return '';
		case OpcPanelCallRecordState.HOLD:
			return 'phone-hold.svg';
		case OpcPanelCallRecordState.DISCONNECTING:
			return 'phone-missed.svg';
		case OpcPanelCallRecordState.ACTIVE:
			return this.getActiveCallIcon(previousCallIcon);
		case OpcPanelCallRecordState.CALLING:
			return this.getAlertingCallingIcon(previousCallIcon, Direction, 'phone-forwarded.svg');
		case OpcPanelCallRecordState.ALERTING:
			return this.getAlertingCallingIcon(previousCallIcon, Direction, 'phone-idle2.svg');
		}
	}

	private getAlertingCallingIcon(previousCallIcon: string, direction: OpcPanelCallDirection, inIcon: string): string {
		const isInDirection = PhoneUtilService.isInDirection(direction);

		return !isInDirection ? this.getAlertingCallingOutgoing(previousCallIcon) : inIcon;
	}

	private getAlertingCallingOutgoing(previousCallIcon: string): string {
		if (previousCallIcon === PhoneCallAlertingCalling.INITIAL_ACTIVE) {
			return PhoneCallAlertingCalling.SECOND_ACTIVE;
		}

		if (previousCallIcon === PhoneCallAlertingCalling.SECOND_ACTIVE) {
			return PhoneCallAlertingCalling.THIRD_ACTIVE;
		}

		return PhoneCallAlertingCalling.INITIAL_ACTIVE;
	}

	private getActiveCallIcon(previousCallIcon: string): string {
		if (previousCallIcon === PhoneCallActive.INITIAL_ACTIVE) {
			return PhoneCallActive.SECOND_ACTIVE;
		}

		if (previousCallIcon === PhoneCallActive.SECOND_ACTIVE) {
			return PhoneCallActive.THIRD_ACTIVE;
		}

		return PhoneCallActive.INITIAL_ACTIVE;
	}

	private isCallStateMatching(state: OpcPanelCallRecordState, stateToMatch: OpcPanelCallRecordState): boolean {
		return state === stateToMatch;
	}

	private formatSeconds(value: number): number {
		return Math.floor(value % 60);
	}

	private formatMinutes(value: number): number {
		return Math.floor(value % 3600 / 60);
	}

	private formatHours(value: number): number {
		return Math.floor(value % 3600 / 60 / 60);
	}

	private formatTimeUnit(value: number): string {
		return value < 10 ? `0${value}` : value.toString();
	}

	private getShortOutputFormat(hours: number, minutes: number, seconds: number): string {
		return `${this.getShortHours(hours)}${minutes}m ${seconds}s`;
	}

	private getShortHours(hours: number): string {
		return hours === 0 ? '' : `${hours}h `;
	}

	private isNewCallRecordInfo(callRecordInfo: IPhoneCallRecordInfo[], callId: number): boolean {
		return !callRecordInfo.some(item => item.callId === callId);
	}

	private getNewRecordsInfo(newData: IPhoneCallRecordInfo[], existingData: IPhoneCallRecordInfo[]): IPhoneCallRecordInfo[] {
		return newData.filter(newItem => !existingData.some(existingItem => existingItem.callId === newItem.callId));
	}

	private getCallStatesToAdd(existingStates: OpcPanelCallRecordState[], newStates: OpcPanelCallRecordState[]): OpcPanelCallRecordState[] {
		return Array.from(new Set([...existingStates, ...newStates]));
	}

	public calculateCallDuration(callStartTime: number, callRecord: PhoneCallRecord): number {
		const { State, Duration } = callRecord;
		const time = Number.parseInt((new Date().getTime() / 1000).toString(), 10);

		const isHoldState = this.isCallStateMatching(State, OpcPanelCallRecordState.HOLD);
		const isActiveState = this.isCallStateMatching(State, OpcPanelCallRecordState.ACTIVE);

		if (isActiveState || isHoldState) {
			return time - callStartTime + Duration;
		}

		const isDisconnectingState = this.isCallStateMatching(State, OpcPanelCallRecordState.DISCONNECTING);

		return isDisconnectingState ? Duration : 0;
	}

	public getCallStartTime(callRecord: PhoneCallRecord): number {
		return callRecord?.callStartTime ? callRecord.callStartTime : Math.floor(new Date().getTime() / 1000);
	}

	public formatSecondsToHMS(value: number, shortOutput = false): string {
		const hours = this.formatHours(value);
		const seconds = this.formatSeconds(value);
		const minutes = this.formatMinutes(value);

		const hoursToDisplay = this.formatTimeUnit(hours);
		const secondsToDisplay = this.formatTimeUnit(seconds);
		const minutesToDisplay = this.formatTimeUnit(minutes);

		if (shortOutput) {
			return this.getShortOutputFormat(hours, minutes, seconds);
		}

		return `${hoursToDisplay}:${minutesToDisplay}:${secondsToDisplay}`;
	}

	public addCallRecordProperties(callRecord: PhoneCallRecord): IPhoneCallRecord {
		const id = this.generateCallRecordId(callRecord);
		const callIcon = this.getMainCallIcon(callRecord);
		const callStartTime = this.getCallStartTime(callRecord);
		const calledNumberName = this.getCalledNumberName(callRecord);
		const callDurationSeconds = this.calculateCallDuration(callStartTime, callRecord);
		const callDuration = this.formatSecondsToHMS(callDurationSeconds);

		return { ...callRecord, id, callDuration, callStartTime, calledNumberName, callIcon };
	}

	public formatCallRecordsToRecordsInfo(callRecords: IPhoneCallRecord[]): IPhoneCallRecordInfo[] {
		return callRecords.reduce((accumulator, callRecord) => {
			const { CallID: callId, State } = callRecord;
			const isNewRecord = this.isNewCallRecordInfo(accumulator, callId);

			if (isNewRecord) {
				return [...accumulator, { callId, states: [State] }];
			}

			return accumulator.map(item => item.callId !== callId ? item : { ...item, states: [...item.states, State] });
		}, [] as IPhoneCallRecordInfo[]);
	}

	public mergeNewAndExistingCallRecordsInfo(
		newData: IPhoneCallRecordInfo[], existingData: IPhoneCallRecordInfo[]
	): IPhoneCallRecordInfo[] {
		const newCallRecordsInfo = this.getNewRecordsInfo(newData, existingData);

		const existingCallRecordsInfo = existingData.map(existingItem => {
			const { callId, states } = existingItem;
			const matchingNewRecord = newData.find(item => item.callId === callId);

			if (!matchingNewRecord) { return existingItem; }

			return { ...existingItem, states: this.getCallStatesToAdd(states, matchingNewRecord.states) };
		});

		return [...existingCallRecordsInfo, ...newCallRecordsInfo];
	}
}
