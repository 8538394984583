import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { IAppState } from '../../../root-reducers';
import { ISelectData } from '../../../ts/models/select-data.model';
import { PreferencesUserService } from './preferences-user.service';
import { IPreferencesStore } from '../ts/models/preferences-store.model';
import { PreferencesStoreKey } from '../ts/enums/preferences-store-key.enum';
import { PreferencesTabsComponent } from '../components/preferences-tabs.component';
import { PreferencesFormPayloadService } from './form/preferences-form-payload.service';
import { IPreferencesInterfaceType } from '../ts/enums/preferences-interface-type.enum';
import { VirtualOfficeSide } from '../../virtual-office/ts/enums/virtual-office-side.enum';
import * as preferencesSelectors from '../ngrx/preferences.selectors';
import * as preferencesActions from '../ngrx/preferences.actions';
import {
	UserPrivilegeVoComponent
} from '../../user-preferences/components/user-privileges-detail/user-privilege-detail-phone/user-privilege-vo/user-privilege-vo.component';

@Injectable({
	providedIn: 'root'
})
export class PreferencesNgrxService {

	constructor(
		private store: Store<IAppState>,
		private preferencesUserService: PreferencesUserService,
		private preferencesPayloadService: PreferencesFormPayloadService
	) { }

	fetchUserPreferences(username: string): void {
		this.store.dispatch(preferencesActions.fetchPreferencesAction({ payload: { username } }));
	}

	clearPreferencesStore(): void {
		this.store.dispatch(preferencesActions.clearPreferencesStoreAction());
	}

	setLoadingPreferencesState(isLoading: boolean): void {
		const payload = { isLoadingPreferences: isLoading };

		this.store.dispatch(preferencesActions.setLoadingPreferencesStateAction({ payload }));
	}

	saveUserPreferences(
		tabsComponent: PreferencesTabsComponent, selectedInterface: IPreferencesInterfaceType, username: string, isUbrMode: boolean
	): void {
		this.handleSaveUserNumber(tabsComponent, username);

		const savePayload = this.preferencesPayloadService.getPreferenceSavePayload(username, tabsComponent, selectedInterface, isUbrMode);

		this.store.dispatch(preferencesActions.setUserPreferencesAction({ payload: { savePayload, fetchPreferences: true } }));
		this.setLoadingPreferencesState(true);
	}

	public handleSaveNetNumber(userPrivilegeVoComponent: UserPrivilegeVoComponent, user: string, isUbrMode: boolean): void {
		if (isUbrMode) { return; }

		const { voForm, initialVoFormValues, netNumbers } = userPrivilegeVoComponent;
		const { extensionNumber } = voForm.value;
		const { extensionNumber: initialExtensionNumber } = initialVoFormValues;

		if (extensionNumber === initialExtensionNumber) { return; }

		if (this.isAlreadyExistingNumber(netNumbers, extensionNumber)) {
			this.saveExistingVoNumber(user, extensionNumber, 'vo-cur-net-num');
			return;
		}

		this.saveNewNetUserNumber(user, extensionNumber, VirtualOfficeSide.NET);
	}

	private handleSaveUserNumber(tabsComponent: PreferencesTabsComponent, user: string): void {
		if (!tabsComponent.advancedSettingsComponent.showAbilisphoneSettings) { return; }

		const { advancedSettingsForm, initialAdvancedSettingsForm, userNumbers } = tabsComponent.advancedSettingsComponent;

		const { cellularNumber } = advancedSettingsForm.value;
		const { cellularNumber: initialCellularNumber } = initialAdvancedSettingsForm.value;

		if (cellularNumber === initialCellularNumber) { return; }

		if (this.isAlreadyExistingNumber(userNumbers,cellularNumber)) {
			this.saveExistingVoNumber(user, cellularNumber, 'vo-cur-user-num');
			return;
		}

		this.saveNewNetUserNumber(user, cellularNumber, VirtualOfficeSide.USER);
	}

	private isAlreadyExistingNumber(numbersDropdown: ISelectData[], phoneNumber: string): boolean {
		return numbersDropdown.some(item => item.value === phoneNumber);
	}

	private saveNewNetUserNumber(user: string, phoneNumber: string, side: VirtualOfficeSide): void {
		const payload = { user, phoneNumber, side };

		this.store.dispatch(preferencesActions.saveNewNetUserNumber({ payload }));
	}

	public saveExistingVoNumber(user: string, numberValue: string, key: 'vo-cur-user-num' | 'vo-cur-net-num'): void {
		const savePayload = { CtiPreferences: [{ user, [key]: numberValue }] };

		this.store.dispatch(preferencesActions.setUserPreferencesAction({ payload: { savePayload, fetchPreferences: false } }));
	}

	getConfiguredInterfaces$(): Observable<ISelectData[]> {
		return this.selectPreferences$()
			.pipe(
				map(preferences => this.preferencesUserService.getConfiguredInterfaces(preferences))
			);
	}

	selectPreferences$(): Observable<IPreferencesStore> {
		return this.store.pipe(select(preferencesSelectors.selectPreferences()))
			.pipe(
				filter(preferences => preferences.hasOwnProperty(PreferencesStoreKey.HAS_ERROR))
			) as Observable<IPreferencesStore>;
	}

	selectFromPreferencesStore$<T>(selector: PreferencesStoreKey): Observable<T> {
		return this.store.pipe(select(preferencesSelectors.selectFromPreferencesStore(selector))) as unknown as Observable<T>;
	}
}
