import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IAutomationDevice } from '../../../automation/ts/models/automation-device.model';
import * as automationProgramModalActions from '../automation-program-edit.actions';

export const automationProgramDeviceFeatureKey = 'automationProgramModalDevices';

export interface IAutomationProgramModalDeviceState extends EntityState<IAutomationDevice> {
	isLoading: boolean;
}

export const adapter: EntityAdapter<IAutomationDevice> = createEntityAdapter<IAutomationDevice>();

export const initialAutomationProgramDeviceState: IAutomationProgramModalDeviceState = adapter.getInitialState({
	isLoading: true
});

const automationProgramDeviceReducer = createReducer(
	initialAutomationProgramDeviceState,
	on(automationProgramModalActions.finishLoadingModalDevices, (state, { payload }) =>
		adapter.setAll(payload.modalDevices, { ...state, isLoading: false }))
);

export function AutomationProgramDeviceReducer(state: IAutomationProgramModalDeviceState | undefined, action: Action) {
	return automationProgramDeviceReducer(state, action);
}

export const { selectAll: selectAllModalAutomationProgramDevices } = adapter.getSelectors();
