import { Injectable } from '@angular/core';
import { NetworkingInfoService } from '../networking-info.service';
import { NETWORK_LOG_DOWN, NETWORK_LOG_UP } from '../../util/networking-info-util';
import { NetworkingInfoLogType } from '../../ts/enums/networking-info-log-type.enum';
import { INetworkingInfoLogItem } from '../../ts/models/networking-info-log-item.model';
import { NetworkingInfoNewsCodeUtilService } from './networking-info-news-code-util.service';
import { INetworkingInfoCustomLogItem } from '../../ts/models/networking-info-custom-log-item.model';
import { INetworkingInfoResourceItem } from '../../ts/models/resource/networking-info-resource-item.model';

@Injectable({
	providedIn: 'root'
})
export class NetworkingInfoNewsCodeResourceService {

	constructor(
		private networkingCodeUtilService: NetworkingInfoNewsCodeUtilService
	) { }

	formatLanMainTunnelLog(
		systemLogItem: INetworkingInfoLogItem, datetime: string,
		availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const resourceData = this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /i: ip-\d+/);
		const type = NetworkingInfoLogType.CRITICAL__ERROR;
		const { id, resource, resourceDescription, resourceStatus } = resourceData;
		const resourceUI = NetworkingInfoService.generateNetworkLogResource(resource, resourceDescription);

		return { id, type, datetime, resource: resourceUI, eventDescription: resourceStatus };
	}

	formatPPPLog(
		systemLogItem: INetworkingInfoLogItem, datetime: string, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const resourceData = this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /i: ip-\d+/);

		const { id, resource, resourceDescription } = resourceData;
		const type = NetworkingInfoLogType.CRITICAL__ERROR;
		const pppStatusInfo = this.getPPPStatusInfo(systemLogItem.text);
		const resourceUI = NetworkingInfoService.generateNetworkLogResource(resource, resourceDescription);

		return { id, type, datetime, resource: resourceUI, eventDescription: pppStatusInfo };
	}

	formatVpnIpsecCode(systemLogItem: INetworkingInfoLogItem, datetime: string): INetworkingInfoCustomLogItem {
		const { text } = systemLogItem;
		const id = this.networkingCodeUtilService.createSystemLogId(systemLogItem);
		const [_, interfaceName, hardware, status, ip, ...restDescription] = text.split(' ');
		const statusImage = status.toLowerCase() === 'dn' ? NETWORK_LOG_DOWN : NETWORK_LOG_UP;

		return {
			id, type: NetworkingInfoLogType.INFO, datetime,
			resource: `(${interfaceName}) ${hardware} ${restDescription.join(' ')}`,
			eventDescription: ` {{NETWORK.NEWS.is}} ${statusImage}, ${ip}`
		};
	}

	getPPPStatusInfo(logText: string): string {
		const pppStatusInformationResult = logText.toLowerCase().match(/ppp-st:.+/);

		if (!pppStatusInformationResult) { return ''; }

		const pppStatus = this.networkingCodeUtilService.getValueFromParameter(pppStatusInformationResult);

		switch (pppStatus) {
		case 's1':
		case 's2':
		case 's3':
			return '{{NETWORK.NEWS.pppStopped}}';
		case 'dl':
			return '{{NETWORK.NEWS.pppNotWorking}}';
		case 'dn':
			return `{{NETWORK.NEWS.pppIs}} ${NETWORK_LOG_DOWN}`;
		case 'up':
			return `{{NETWORK.NEWS.pppIs}} ${NETWORK_LOG_UP}`;
		default:
			return '';
		}
	}

	formatDslLog(
		systemLogItem: INetworkingInfoLogItem, datetime: string, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const { text } = systemLogItem;
		const resourceData = this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /i: dsl-\d+/);
		const { id, isUp, resource, resourceStatus } = resourceData;

		const type = NetworkingInfoLogType.CRITICAL__ERROR;
		const speedInfo = NetworkingInfoNewsCodeUtilService.getDownloadUploadInfo(text);
		const downloadSpeedUI = isUp ? `, {{NETWORK.NEWS.withDownloadUploadSpeed}}: ${speedInfo}` : '';
		const eventDescription = `${resourceStatus}${downloadSpeedUI}`;

		return { id, type, datetime, resource, eventDescription };
	}

	formatLanLayerLog(
		systemLogItem: INetworkingInfoLogItem, datetime: string, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const { text } = systemLogItem;
		const resourceData = this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /i: ip-\d+/);

		const { id, resource, resourceDescription } = resourceData;
		const type = NetworkingInfoLogType.CRITICAL__ERROR;
		const lanLayerStatus = this.getLanLayerStatus(text);
		const resourceUI = NetworkingInfoService.generateNetworkLogResource(resource, resourceDescription);

		return { id, type, datetime, resource: resourceUI, eventDescription: lanLayerStatus };
	}

	getLanLayerStatus(text: string): string {
		const statusInformationResult = text.toLowerCase().match(/lan-st:.+/);

		if (!statusInformationResult) { return ''; }

		const lanStatus = this.networkingCodeUtilService.getValueFromParameter(statusInformationResult);

		switch (lanStatus) {
		case 'ld':
			return `{{NETWORK.NEWS.lanIs}} ${NETWORK_LOG_DOWN}`;
		case 'lr':
			return '{{NETWORK.NEWS.lanIs}} {{NETWORK.NEWS.ready}}';
		case 'ln':
			return '{{NETWORK.NEWS.lanIs}} {{NETWORK.NEWS.disconnected}}';
		case 'hd':
			return '{{NETWORK.NEWS.lanIs}} {{NETWORK.NEWS.stopped}}';
		case 'fd':
			return '{{NETWORK.NEWS.lanIs}} {{NETWORK.NEWS.outOfOrder}}';
		default:
			return '';
		}
	}

	formatFastEthernetLog(
		systemLogItem: INetworkingInfoLogItem, datetime: string, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem {
		const { text } = systemLogItem;
		const resourceData = this.networkingCodeUtilService.getCommonResourceInfo(systemLogItem, availableResources, /i:.+\s/);
		const { id, resource, resourceDescription } = resourceData;

		const type = NetworkingInfoLogType.CRITICAL__ERROR;
		const resourceStatus = this.getFastEthernetStatus(text);
		const resourceUI = NetworkingInfoService.generateNetworkLogResource(resource, resourceDescription);

		return { id, type, datetime, resource: resourceUI, eventDescription: resourceStatus };
	}

	getFastEthernetStatus(text: string): string {
		const statusInformationResult = text.toLowerCase().match(/st:.+/);

		if (!statusInformationResult) { return ''; }

		const lanStatus = this.networkingCodeUtilService.getValueFromParameter(statusInformationResult);

		switch (lanStatus) {
		case 'dn':
			return `{{NETWORK.NEWS.is}} ${NETWORK_LOG_DOWN}`;
		case 'rd':
			return '{{NETWORK.NEWS.is}} {{NETWORK.NEWS.ready}}';
		case 'td':
			return '{{NETWORK.NEWS.uploadDisabled}}';
		case 'hd':
			return '{{NETWORK.NEWS.is}} {{NETWORK.NEWS.stopped}}';
		case 'fd':
			return '{{NETWORK.NEWS.isNotWorking}}';
		case 'ng':
			return '{{NETWORK.NEWS.isInitializing}}...';
		default:
			return '';
		}
	}
}
