import { createAction, props } from '@ngrx/store';
import { ICamera } from '../../videorecorder/ts/models/camera.model';
import { IUserPreferencesVoPayload } from '../ts/models/user-preferences-vo-payload.model';
import { IUserPreferenceItem } from '../ts/models/user-preferences-item.model';
import { IUserPreferencesFtpForm } from '../ts/models/user-preferences-ftp-form.model';
import { IUserPreferencesConflict } from '../ts/models/user-preferences-conflict.model';
import { IUserPreferencesBasicForm } from '../ts/models/user-preferences-basic-form.model';
import { ISaveConfigurationResponse } from '../../../ts/models/save-configuration-response.model';
import { IUserPreferencesSettingsSave } from '../ts/models/user-preferences-setttings-save.model';
import { IUserPreferencesAutomationForm } from '../ts/models/user-preferences-automation-form.model';
import { UserPreferencesPhoneSavePayload } from '../ts/types/user-preferences-phone-save-payload.type';
import { IUserPreferencesVideorecorderForm } from '../ts/models/user-preferences-videorecorder-form.model';
import { IUserPreferencesPrivilegeParameters } from '../ts/models/user-preferences-privilege-parameters.model';

export const loadBasicUserPreferences = createAction(
	'[Basic User Preferences] Load Basic User Preferences'
);

export const loadCameraList = createAction(
	'[Basic User Preferences] Load Camera List'
);

export const saveAbilisphoneTracking = createAction(
	'[Basic User Preferences] Save Abilisphone Values',
	props<{ payload: IUserPreferencesVoPayload }>()
);

export const finishLoadingUserPreferences = createAction(
	'[Basic User Preferences] Finish Loading Basic User Preferences',
	props<{ payload: { userPreferences: IUserPreferenceItem[] } }>()
);

export const finishLoadingCameraList = createAction(
	'[Basic User Preferences] Finish Loading Camera List',
	props<{ payload: { cameraList: ICamera[] } }>()
);

export const cancelUserPreferences = createAction(
	'[Basic User Preferences] Cancel Basic User Preferences'
);

export const setLoadingUsersTable = createAction(
	'[Basic User Preferences] Set Loading Users Table',
	props<{ payload: { isLoading: boolean } }>()
);

export const deleteSelectedUser = createAction(
	'[Basic User Preferences] Delete Selected User',
	props<{ payload: { username: string } }>()
);

export const saveBasicUserPreferences = createAction(
	'[Basic User Preferences] Save Basic User Preferences',
	props<{ payload: IUserPreferencesSettingsSave }>()
);

export const saveBasicUserPrivileges = createAction(
	'[Basic User Preferences] Save Basic User Privileges',
	props<{ payload: { formValues: IUserPreferencesBasicForm; showAddNewUser: boolean; selectedPrivilegeItem: IUserPreferenceItem } }>()
);

export const copyBasicUserToNewUser = createAction(
	'[Basic User Preferences] Copy Basic User Preferences',
	props<{ payload: { username: string; newUser: string; newUserPassword: string; newUserRealname: string } }>()
);

export const addNewUser = createAction(
	'[Basic User Preferences] Add new user',
	props<{ payload: { newUser: string; newUserPassword: string; newUserRealname: string } }>()
);

export const savePermanentlyUserSettings = createAction(
	'[Basic User Preferences] Save permanently user settings'
);

export const setSaveConfigurationResponse = createAction(
	'[Basic User Preferences] Set Save Configuration Response',
	props<{ payload: { response: ISaveConfigurationResponse } }>()
);

export const saveAutomationUserPrivileges = createAction(
	'[Basic User Preferences] Save Automation User Privileges Form',
	props<{ payload: { savePayload: IUserPreferencesAutomationForm; user: string } }>()
);

export const saveVideorecorderUserPrivileges = createAction(
	'[Basic User Preferences] Save Videorecorder User Privileges Form',
	props<{ payload: { savePayload: IUserPreferencesVideorecorderForm; user: string } }>()
);

export const savePhoneUserPrivileges = createAction(
	'[Basic User Preferences] Save Phone User Privileges Form',
	props<{ payload: { savePayload: UserPreferencesPhoneSavePayload; user: string } }>()
);

export const saveNetworkingUserPrivileges = createAction(
	'[Basic User Preferences] Save Networking User Privileges Form',
	props<{ payload: { savePayload: IUserPreferencesFtpForm; user: string } }>()
);

export const saveMultipleForcePayload = createAction(
	'[Basic User Preferences] Save Multiple Force Reload',
	props<{ payload: { conflicts: IUserPreferencesConflict[]; user: string } }>()
);

export const savePrivilegeCheckbox = createAction(
	'[Basic User Preferences] Save Privilege Checkbox',
	props<{ payload: { username: string; parameterKey: string; parameterValue: boolean } }>()
);

export const setUserRowDisabled = createAction(
	'[Basic User Preferences] Set User Row Disabled',
	props<{ payload: { userId: string } }>()
);

export const fetchUserPrivilegeData = createAction(
	'[Fetch User Privilege Data] Fetch User Privilege Data',
	props<{ payload: { selectedUser: string } }>()
);

export const setUserPrivilegeData = createAction(
	'[Set User Privilege Data] Set User Privilege Data',
	props<{ payload: { data: IUserPreferencesPrivilegeParameters | null } }>()
);
