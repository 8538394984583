<div class="content" *ngIf="results.length else noResultsTemplate">
	<p class="content__item" *ngFor="let result of results; trackBy: trackByIndex" (click)="onGoToPage(result)">
		{{ result.display }}
	</p>
</div>

<ng-template #noResultsTemplate>
	<div class="content">
		<p class="content__item">{{'COMMON.noResults' | translate}}</p>
	</div>
</ng-template>
