import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { PhoneComponent } from '../phone.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneComponentEffects } from '../ngrx/phone-component.effects';
import { PhoneLogComponent } from '../components/phone-log/phone-log.component';
import { PhoneModalComponent } from '../components/phone-modal/phone-modal.component';
import { PhoneKeypadComponent } from '../components/phone-keypad/phone-keypad.component';
import { PhoneHeaderComponent } from '../components/phone-header/phone-header.component';
import { PhoneFooterComponent } from '../components/phone-footer/phone-footer.component';
import { SharedPipeDirectiveModule } from './../../../shared/shared-pipe-directive.module';
import { PhoneLogItemComponent } from '../components/phone-log/phone-log-item/phone-log-item.component';
import { AutofocusInputFieldDirective } from '../../../standalone/directives/autofocus-input-field.directive';
import { PhoneLogDiallerComponent } from '../components/phone-log/phone-log-dialler/phone-log-dialler.component';
import { PhoneFooterMenuComponent } from '../components/phone-footer/phone-footer-menu/phone-footer-menu.component';
import { CustomInputComponent } from './../../../standalone/components/controls/custom-input/custom-input.component';
import { PhoneHeaderInputComponent } from './../components/phone-header/phone-header-input/phone-header-input.component';
import { PhoneFooterSettingsComponent } from '../components/phone-footer/phone-footer-settings/phone-footer-settings.component';
import { PhoneRecentCallLogItemComponent } from '../components/phone-log/phone-recent-call-log-item/phone-recent-call-log-item.component';
import * as phoneReducer from '../ngrx/phone-component.reducer';

@NgModule({
	declarations: [
		PhoneComponent,
		PhoneLogComponent,
		PhoneModalComponent,
		PhoneKeypadComponent,
		PhoneHeaderComponent,
		PhoneFooterComponent,
		PhoneLogItemComponent,
		PhoneLogDiallerComponent,
		PhoneFooterMenuComponent,
		PhoneHeaderInputComponent,
		PhoneFooterSettingsComponent,
		PhoneRecentCallLogItemComponent
	],
	imports: [
		FormsModule,
		CommonModule,
		ReactiveFormsModule,
		CustomInputComponent,
		SharedPipeDirectiveModule,
		TranslateModule.forChild(),
		AutofocusInputFieldDirective,
		StoreModule.forFeature(phoneReducer.phoneComponentFeatureKey, phoneReducer.phoneComponentReducer),
		EffectsModule.forFeature([PhoneComponentEffects])
	],
	exports: [PhoneComponent]
})
export class PhoneComponentFeatureModule { }
