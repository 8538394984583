import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IAutomationMapTemporaryDescription } from '../../ts/models/automation-map-temporary-description.model';
import * as automationActions from '../automation.action';

export const automationTemporaryDescriptionFeatureKey = 'automationTemporaryDescription';

export type IAutomationTemporaryDescriptionState = EntityState<IAutomationMapTemporaryDescription>;

export const adapter: EntityAdapter<IAutomationMapTemporaryDescription> = createEntityAdapter<IAutomationMapTemporaryDescription>();

export const initialAutomationDescriptionState: IAutomationTemporaryDescriptionState = adapter.getInitialState({});

const automationTemporaryDescriptionsReducer = createReducer(
	initialAutomationDescriptionState,
	on(automationActions.addTemporaryLineDeviceDescription, (state, { payload }) =>
		adapter.upsertOne(payload.descriptionPayload, { ...state })),
	on(automationActions.removeAllTemporaryDescriptions, (state) => adapter.removeAll(({ ...state })))
);

export function AutomationTemporaryDescriptionsReducer(
	state: IAutomationTemporaryDescriptionState | undefined, action: Action) {
	return automationTemporaryDescriptionsReducer(state, action);
}

export const { selectAll: selectAllTemporaryDescriptions } = adapter.getSelectors();
