import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { switchMap, map } from 'rxjs/operators';
import { RoutesEnum } from '../ts/enums/routes.enum';
import { IStore } from '../components/auth/ts/models/store.model';
import { AuthUserInfo } from '../components/auth/ts/enums/user-auth.enum';
import { AuthHttpService } from '../components/auth/services/auth-http.service';
import { createEffect, ofType, ROOT_EFFECTS_INIT, Actions } from '@ngrx/effects';
import * as AuthActions from '../components/auth/ngrx/auth.actions';

@Injectable()
export class RootEffects {

	constructor(
		private router: Router,
		private actions$: Actions,
		private authHttpService: AuthHttpService
	) { }

	init$ = createEffect(() => this.actions$.pipe(
		ofType(ROOT_EFFECTS_INIT),
		switchMap(() => this.authHttpService.fetchUserSessionData$()),
		map((sessionData: IStore) => {
			const { username } = sessionData.user;
			const payload = { sessionData };

			if (username !== AuthUserInfo.ANONYMOUS) {
				return AuthActions.checkForNonAnonymousSessionAction({ payload });
			}

			this.router.navigateByUrl(`/${RoutesEnum.LOGIN}`);

			return AuthActions.checkForAnonymousSessionAction({ payload });
		})
	));
}
