import { Injectable } from '@angular/core';
import { RoutesEnum } from '../../../ts/enums/routes.enum';
import { UserLevel } from '../../auth/ts/enums/user-level.enum';
import { FeaturePermissionsName } from '../ts/enums/feature-permission-name.enum';
import { IFeaturePermissionsItem } from '../ts/models/feature-permissions-item.model';
import { IFeaturePermissionDetail } from '../ts/models/feature-permission-detail.model';
import { FeaturePermissionNaStatus } from '../ts/enums/feature-permission-na-status.enum';
import { IFeaturePermissionsService } from '../ts/models/feature-permissions-service.model';

@Injectable({
	providedIn: 'root'
})
export class FeaturePermissionsService {

	private readonly audioPermissionNames = [
		FeaturePermissionsName.VM, FeaturePermissionsName.MIX, FeaturePermissionsName.MOH, FeaturePermissionsName.DISA
	];

	private readonly networkPermissionNames = [
		FeaturePermissionsName.PORTS, FeaturePermissionsName.NETWORK_ROUTINGS, FeaturePermissionsName.NAT,
		FeaturePermissionsName.ACL, FeaturePermissionsName.DHCP, FeaturePermissionsName.DNS,
		FeaturePermissionsName.FTP, FeaturePermissionsName.HTTP, FeaturePermissionsName.IP_SHAPING,
		FeaturePermissionsName.WAN_PREFERENCES
	];

	private readonly userRightPermissionNames = [
		FeaturePermissionsName.ADMIN_HTTP, FeaturePermissionsName.ADMIN_FTP, FeaturePermissionsName.LDAP
	];

	constructor() { }

	formatPermissions(services: IFeaturePermissionsService[], userLevel: UserLevel | null): IFeaturePermissionsItem[] {
		const availableServices = services.map(item => {
			const { status, name } = item;
			const isFeatureDisabled = status !== FeaturePermissionNaStatus.OK;
			const tooltip = this.formatPermissionTooltip(isFeatureDisabled, item);

			return { isFeatureDisabled, tooltip, name };
		});

		const audioPermission = this.compileCustomPermission(
			availableServices, this.audioPermissionNames, FeaturePermissionsName.AUDIO
		);

		const networkSettingPermission = this.compileCustomPermission(
			availableServices, this.networkPermissionNames, FeaturePermissionsName.NETWORK_SETTINGS, userLevel
		);

		const userRightPermission = this.compileCustomPermission(
			availableServices, this.userRightPermissionNames, FeaturePermissionsName.ADMIN_USER_RIGHTS
		);

		const portsConfigurationPermission = this.compileCustomPermission(
			availableServices, [FeaturePermissionsName.DISCOVERY], FeaturePermissionsName.PORTS_CONFIGURATION
		);

		return [...availableServices, audioPermission, networkSettingPermission, userRightPermission, portsConfigurationPermission];
	}

	private formatPermissionTooltip(isFeatureDisabled: boolean, item: IFeaturePermissionsService): string {
		if (!isFeatureDisabled) { return ''; }

		const { details, status } = item;
		const mainInactiveText = this.getMainInactiveText(status);

		if (!details.length) { return `{{${mainInactiveText}}}`; }

		const detailedText = this.getDetailedPermissionProblems(details);

		return `{{${mainInactiveText}}}, {{SERVICE.because}} ${detailedText}`;
	}

	private getDetailedPermissionProblems(details: IFeaturePermissionDetail[]): string {
		return details
			.map(detail => `"${detail.n}: {{${this.getDetailedInactiveText(detail.s)}}}"`)
			.join(', ');
	}

	private getDetailedInactiveText(status: FeaturePermissionNaStatus): string {
		switch (status) {
		case FeaturePermissionNaStatus.SERVICE_NA:
			return 'SERVICE.naDetail';
		case FeaturePermissionNaStatus.SERVICE_INACTIVE:
			return 'SERVICE.inactiveDetail';
		case FeaturePermissionNaStatus.SERVICE_LICENSE_PROBLEM:
			return 'SERVICE.licenseDetail';
		case FeaturePermissionNaStatus.SERVICE_NOT_RUNNING:
			return 'SERVICE.notRunningDetail';
		case FeaturePermissionNaStatus.SERVICE_DISABLED_UBR:
			return 'SERVICE.ubrDetail';
		case FeaturePermissionNaStatus.SERVICE_MISSING:
			return 'SERVICE.missingDetail';
		case FeaturePermissionNaStatus.SERVICE_VALUE_NO:
			return 'SERVICE.serviceParameterNoDetail';
		default:
			return 'SERVICE.inactiveDetail';
		}
	}

	private getMainInactiveText(status: FeaturePermissionNaStatus): string {
		switch (status) {
		case FeaturePermissionNaStatus.SERVICE_NA:
			return 'SERVICE.na';
		case FeaturePermissionNaStatus.SERVICE_INACTIVE:
			return 'SERVICE.inactive';
		case FeaturePermissionNaStatus.SERVICE_LICENSE_PROBLEM:
			return 'SERVICE.license';
		case FeaturePermissionNaStatus.SERVICE_NOT_RUNNING:
			return 'SERVICE.notRunning';
		case FeaturePermissionNaStatus.SERVICE_DISABLED_UBR:
			return 'SERVICE.ubr';
		case FeaturePermissionNaStatus.SERVICE_MISSING:
			return 'SERVICE.missing';
		case FeaturePermissionNaStatus.SERVICE_VALUE_NO:
			return 'SERVICE.serviceParameterNo';
		default:
			return 'SERVICE.inactive';
		}
	}

	compileCustomPermission(
		permissions: IFeaturePermissionsItem[], permissionNames: FeaturePermissionsName[],
		name: FeaturePermissionsName, userLevel?: UserLevel | null
	): IFeaturePermissionsItem {
		if (!permissions.length) { return { tooltip: '', isFeatureDisabled: true, name }; }

		const customPermissions = permissionNames.map(permissionName => this.getPermissionByName(permissions, permissionName));

		const isFeatureDisabled = this.checkIfFeatureIsDisabled(customPermissions, name, userLevel);
		const tooltip = this.findCompiledPermissionTooltip(customPermissions, isFeatureDisabled);

		return { tooltip, isFeatureDisabled, name };
	}

	findCompiledPermissionTooltip(permissions: IFeaturePermissionsItem[], isFeatureDisabled: boolean): string {
		if (!isFeatureDisabled) { return ''; }

		return permissions.find(item => item.isFeatureDisabled)?.tooltip || '';
	}

	formatPhonePermissionToRoutes(name: FeaturePermissionsName): RoutesEnum | string {
		switch (name) {
		case FeaturePermissionsName.PREFERENCES:
			return RoutesEnum.PREFERENCES;
		case FeaturePermissionsName.OPC_PANEL:
			return RoutesEnum.OPC_PANEL;
		case FeaturePermissionsName.ADDRESSBOOK:
			return RoutesEnum.ADDRESSBOOK;
		case FeaturePermissionsName.RECENT_CALLS:
			return RoutesEnum.RECENT_CALLS;
		case FeaturePermissionsName.STATISTICS:
			return RoutesEnum.PHONE_TRAFFIC;
		case FeaturePermissionsName.ROUTINGS:
			return RoutesEnum.ROUTINGS;
		default:
			return '';
		}
	}

	formatAutoPermissionToRoutes(name: FeaturePermissionsName): RoutesEnum | string {
		switch (name) {
		case FeaturePermissionsName.MAPS:
			return RoutesEnum.AUTOMATION_MAPS;
		case FeaturePermissionsName.SHEETS:
			return RoutesEnum.AUTOMATION_PROGRAMS_EDIT;
		case FeaturePermissionsName.PROGRAMS:
			return RoutesEnum.AUTOMATION_PROGRAM;
		case FeaturePermissionsName.LOGS:
			return RoutesEnum.AUTOMATION_LOG;
		case FeaturePermissionsName.DISCOVERY:
			return RoutesEnum.RIO_RVS;
		case FeaturePermissionsName.PORTS_CONFIGURATION:
			return RoutesEnum.PORTS_CONFIGURATION;
		default:
			return '';
		}
	}

	formatToolsPermissionToRoutes(name: FeaturePermissionsName): RoutesEnum | string {
		switch (name) {
		case FeaturePermissionsName.LISTS:
			return RoutesEnum.BASIC_LIST;
		case FeaturePermissionsName.ALERT:
			return RoutesEnum.ALERTS;
		case FeaturePermissionsName.SMS:
			return RoutesEnum.SMS;
		case FeaturePermissionsName.CONTROL_PORT:
			return RoutesEnum.CP;
		case FeaturePermissionsName.DISK:
			return RoutesEnum.DISK;
		case FeaturePermissionsName.DISA:
			return RoutesEnum.DISA;
		default:
			return '';
		}
	}

	getPermissionByName(permissions: IFeaturePermissionsItem[], name: FeaturePermissionsName): IFeaturePermissionsItem {
		return permissions.find(item => item.name === name) as IFeaturePermissionsItem;
	}

	checkIfFeatureIsDisabled(
		customPermissions: IFeaturePermissionsItem[], name: FeaturePermissionsName, userLevel?: UserLevel | null
	): boolean {
		if (!!userLevel && !this.haveUserAdminlevel(userLevel) && name === FeaturePermissionsName.NETWORK_SETTINGS) {
			return true;
		}

		return customPermissions.every(item => item.isFeatureDisabled);
	}

	haveUserAdminlevel(userLevel: UserLevel | null): boolean {
		if (!userLevel) { return false; }

		return [UserLevel.MANAGER, UserLevel.ADMINISTRATOR, UserLevel.SUPER].includes(userLevel);
	}
}
