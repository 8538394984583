import { Injectable } from '@angular/core';
import { FormlyUtilOperators } from '../../ts/enums/formly/formly-util-operators.enum';
import { IGeneratedFormContainer } from '../../ts/models/generated-form-container.model';
import { GeneratedFormComponentType } from '../../ts/enums/generated-form-component-type.enum';
import { INetworkingAutomationSaveConfiguration } from '../../ts/models/networking-automation-save-config.model';

@Injectable({
	providedIn: 'root'
})
export class NetworkingAutomationHttpLogicService {

	constructor() { }

	formatAsyncConfigurationItems(response: string, asyncValidatorPath: string): string[] {
		if (!asyncValidatorPath) { return []; }

		const parsedResponse = JSON.parse(response);
		const [pathTillValue, valuePath] = asyncValidatorPath.split(FormlyUtilOperators.SEPARATOR);

		const parametersArray = pathTillValue
			.split('.')
			.reduce((accumulator, singlePath) => accumulator[singlePath], parsedResponse) as Array<any>;

		// if there are no parameter value paths, then values which are in the array are ones which are needed
		if (valuePath === '') { return parametersArray.slice(); }
		// try to extract data from parameters array (useful for non-primitive types)
		try {
			return parametersArray.map(parameterItem => valuePath
				.split('.')
				.reduce((accumulator, parameterPath) => accumulator[parameterPath], parameterItem));
		} catch {
			return [];
		}
	}

	getReferencedConfigurationNames(response: INetworkingAutomationSaveConfiguration): string[] {
		const referenceNames = response.configuration
			.filter(configurationItem => configurationItem.type === GeneratedFormComponentType.REFERENCED)
			.map(referencedConfigurationItem => referencedConfigurationItem.referencedName);

		return Array.from(new Set([...referenceNames]));
	}

	translateReferencedConfigurations(
		response: [INetworkingAutomationSaveConfiguration, string[]]
	): INetworkingAutomationSaveConfiguration {
		const [originalConfiguration, referencedConfigurationNames, ...resolvedConfigurations] = response;
		// 0 index - original configuration, // 1 index - referenced configuration names, // 2 and so on, resolved configurations
		const referencedConfiguration = referencedConfigurationNames.map((name, configurationIndex) => {
			const data = (resolvedConfigurations[configurationIndex] as INetworkingAutomationSaveConfiguration).configuration;

			return { name, data };
		});

		const configuration = originalConfiguration.configuration.flatMap(configurationItem => {
			const { type, referencedName, disabled } = configurationItem;

			if (type === GeneratedFormComponentType.REFERENCED) {
				// eslint-disable-next-line max-len
				const { data } = referencedConfiguration.find(refConfigItem => refConfigItem.name === referencedName) as { name: string; data: IGeneratedFormContainer[] };
				const referencedConfigurations = data.filter(refConfigItem => refConfigItem.name !== 'ADVANCED');

				return this.checkToSetDisabledReferencedConfiguration(referencedConfigurations, disabled as boolean);
			}

			return configurationItem;
		});

		return { configuration };
	}

	checkToSetDisabledReferencedConfiguration(
		referencedConfigurations: IGeneratedFormContainer[], disabled: boolean
	): IGeneratedFormContainer[] {
		if (!disabled) { return referencedConfigurations; }

		return referencedConfigurations.map(refConfigItem => ({ ...refConfigItem, disabled: true }));
	}
}
