import { Action, createReducer, on } from '@ngrx/store';
import { IOpcPanelGroup } from '../../ts/models/opc-panel-group.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as opcPanelGroupActions from './opc-panel-group.actions';

export const opcPanelGroupFeatureKey = 'opcPanelGroup';

export interface IOpcPanelGroupState extends EntityState<IOpcPanelGroup> {
	isLoading: boolean;
	showPhone: boolean;
	gpsRefreshValidation: string;
	showQueueDragNotification: boolean;
}

export const adapter: EntityAdapter<IOpcPanelGroup> = createEntityAdapter<IOpcPanelGroup>();

export const initialOpcPanelGroupState: IOpcPanelGroupState = adapter.getInitialState({
	isLoading: true,
	showPhone: false,
	gpsRefreshValidation: '',
	showQueueDragNotification: false
});

const opcPanelGroupReducer = createReducer(
	initialOpcPanelGroupState,
	on(opcPanelGroupActions.finishLoadingOpcPanelGroups, (state, { payload }) =>
		adapter.setAll(payload.opcPanelGroups, { ...state, isLoading: false })),
	on(opcPanelGroupActions.updateOpcPanelGroup, (state, { payload }) =>
		adapter.updateOne({ id: payload.id, changes: payload.groupData }, state)),
	on(opcPanelGroupActions.setGpsRefreshErrorState, (state, { payload }) =>
		({ ...state, gpsRefreshValidation: payload.gpsRefreshValidation })),
	on(opcPanelGroupActions.setDragCallQueueLayout, (state, { payload }) =>
		({ ...state, showQueueDragNotification: payload.showQueueDragNotification })),
	on(opcPanelGroupActions.setShowPhone, (state, { payload }) => ({ ...state, showPhone: payload.showPhone }))
);

export function OpcPanelGroupReducer(state: IOpcPanelGroupState | undefined, action: Action) {
	return opcPanelGroupReducer(state, action);
}

export const { selectAll: selectAllOpcGroups } = adapter.getSelectors();
