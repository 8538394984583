import { forkJoin, of, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserLevel } from '../../../auth/ts/enums/user-level.enum';
import { AuthNgrxService } from '../../../auth/services/auth-ngrx.service';
import { tap, takeUntil, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { RecentCallsCsvService } from '../services/csv/recent-calls-csv.service';
import { RecentCallHttpService } from '../services/http/recent-call-http.service';
import { RecentCallNgrxActionService } from '../services/ngrx/recent-call-ngrx-action.service';
import { RecentCallNgrxSelectorService } from '../services/ngrx/recent-call-ngrx-selector.service';
import * as recentCallActions from './recent-call.action';

@Injectable()
export class RecentCallEffects {

	constructor(
		private readonly actions$: Actions,
		private readonly authNgrxService: AuthNgrxService,
		private readonly recentCallsCsvService: RecentCallsCsvService,
		private readonly recentCallHttpService: RecentCallHttpService,
		private readonly recentCallNgrxActionService: RecentCallNgrxActionService,
		private readonly recentCallNgrxSelectorService: RecentCallNgrxSelectorService
	) { }

	loadAllUsersRecentCalls$ = createEffect(() =>
		this.actions$.pipe(
			ofType(recentCallActions.loadAllUsersRecentCalls),
			tap(() => this.recentCallNgrxActionService.setIsLoading(true)),
			withLatestFrom(
				this.authNgrxService.selectLoggedUserGroup$(),
				this.authNgrxService.haveSpecificUserLevels$([UserLevel.MANAGER])
			),
			switchMap(response => {
				const [, loggedUserGroup, haveManagerRole] = response;

				return this.recentCallHttpService.fetchAllUsersCalls$(loggedUserGroup, haveManagerRole)
					.pipe(
						takeUntil(this.actions$.pipe(ofType(recentCallActions.cancelRecentCallHTTPObservables)))
					);
			}),
			map(recentCalls => recentCallActions.finishLoadingRecentCalls({ payload: { recentCalls } }))
		)
	);

	fetchRecentCallDebugInfo$ = createEffect(() => this.actions$.pipe(
		ofType(recentCallActions.fetchRecentCallDebugInfo),
		switchMap(action => this.recentCallHttpService.fetchDebugInfoAboutNACall$(action.payload.recentCallItem)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(recentCallActions.cancelRecentCallHTTPObservables)))
			)),
		map(recentCallDebugInfo => recentCallActions.setRecentCallDebugInfo({ payload: { recentCallDebugInfo } }))
	));

	loadSingleUsersRecentCalls$ = createEffect(() => this.actions$.pipe(
		ofType(recentCallActions.loadSingleUsersRecentCalls),
		switchMap(action => this.recentCallHttpService.fetchRecentCalls$(action.payload.username)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(recentCallActions.cancelRecentCallHTTPObservables)))
			)),
		map(recentCalls => recentCallActions.finishLoadingRecentCalls({ payload: { recentCalls } }))
	));

	pollFetchRecentCalls$ = createEffect(() => this.actions$.pipe(
		ofType(recentCallActions.pollFetchRecentCalls),
		switchMap(action => this.recentCallHttpService.pollFetchRecentCalls$(action.payload.username)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(recentCallActions.cancelRecentCallHTTPObservables)))
			)),
		map(recentCalls => recentCallActions.finishLoadingRecentCalls({ payload: { recentCalls } }))
	));

	clearRecentCallList$ = createEffect(() => this.actions$.pipe(
		ofType(recentCallActions.clearRecentCalls),
		switchMap(action => {
			const { username } = action.payload;

			return forkJoin({
				username: of(username),
				clearCalls: this.recentCallHttpService.clearRecentCallsLog$(username)
					.pipe(
						takeUntil(this.actions$.pipe(ofType(recentCallActions.cancelRecentCallHTTPObservables)))
					)
			});
		}),
		map(response => {
			const { username } = response;
			const payload = { username };

			return recentCallActions.loadSingleUsersRecentCalls({ payload });
		})
	));

	exportRecentCallsAction$ = createEffect(() => this.actions$.pipe(
		ofType(recentCallActions.exportRecentCalls),
		withLatestFrom(this.recentCallNgrxSelectorService.selectAllRecentCalls$()),
		tap(response => {
			const [action, recentCalls] = response;
			const { username } = action.payload;

			this.recentCallsCsvService.exportCSV(recentCalls, username);
		})
	), { dispatch: false });

	uncheckAllRecentCalls$ = createEffect(() => this.actions$.pipe(
		ofType(recentCallActions.uncheckAllRecentCalls),
		switchMap(() => this.recentCallNgrxSelectorService.selectAllRecentCalls$().pipe(take(1))),
		map(recentCalls => recentCallActions.setUncheckAllRecentCalls({ payload: { recentCalls } }))
	));
}
