import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

	noCredentialsUrl: string[] = ['texttospeech.googleapis.com'];

	constructor() { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// set withCredentials to true, in order to correctly handle authorization
		return next.handle(req.clone({ withCredentials: this.shouldSendCredentials(req.url) }));
	}

	shouldSendCredentials(requestUrl: string): boolean {
		return !this.noCredentialsUrl.some(noCredentialUrl => requestUrl.includes(noCredentialUrl));
	}
}
