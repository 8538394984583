import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GlobalService } from '../../../../../../services/others/global.service';
import { DomUtilService } from './../../../../../../services/utils/dom-util.service';
import { ISearchItem } from '../../../../../../components/core/ts/models/search-item.model';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomInputComponent } from '../../../../controls/custom-input/custom-input.component';
import { SideBarSearchInputComponent } from './side-bar-search-input/side-bar-search-input.component';
import { SideBarSearchNgrxActionService } from './../../services/ngrx/side-bar-search-ngrx-action.service';
import { SideBarSearchContentComponent } from './side-bar-search-content/side-bar-search-content.component';
import { SideBarSearchNgrxSelectorService } from './../../services/ngrx/side-bar-search-ngrx-selector.service';

@UntilDestroy()
@Component({
	standalone: true,
	imports: [
		CommonModule,
		CustomInputComponent,
		SideBarSearchInputComponent,
		SideBarSearchContentComponent
	],
	selector: 'app-side-bar-search',
	templateUrl: './side-bar-search.component.html',
	styleUrls: ['./side-bar-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarSearchComponent implements OnInit {

	@ViewChild(SideBarSearchInputComponent, { static: true }) sidebarSearchInputComponent: SideBarSearchInputComponent;

	@Input() haveAdminUserLevel: boolean | null;

	public isInputFilled: boolean;
	public isSearchVisible: boolean;

	public filteredResults$: Observable<ISearchItem[]>;

	constructor(
		private readonly globalService: GlobalService,
		private readonly sideBarSearchNgrxActionService: SideBarSearchNgrxActionService,
		private readonly sideBarSearchNgrxSelectorService: SideBarSearchNgrxSelectorService
	) { }

	public ngOnInit(): void {
		this.watchLanguageChange();

		this.filteredResults$ = this.sideBarSearchNgrxSelectorService.selectFilteredPages$();
	}

	private watchLanguageChange(): void {
		this.globalService.getLanguageChangeState$()
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				const searchValue = this.sidebarSearchInputComponent.searchControl.value;

				this.sideBarSearchNgrxActionService.filterPagesBySearchTerm(searchValue);
			});
	}

	public onResetSearch(): void {
		this.sidebarSearchInputComponent.onResetSearch();
	}

	public onToggleSearchVisibility(event: Event): void {
		const isInput = DomUtilService.isDomElement(event.target as HTMLElement, 'input');

		if (isInput) { return; }

		this.isSearchVisible = !this.isSearchVisible;

		if (!this.isSearchVisible) { return; }

		setTimeout(() => this.sidebarSearchInputComponent.customInputElement.inputElement.nativeElement.focus(), 0);
	}

	public onSetInputFilled(isInputFilled: boolean): void {
		this.isInputFilled = isInputFilled;
	}
}
