<div class="content" [ngClass]="{'content--disabled': disabled}">
	<img [src]="'assets/feather-icons/' + leftLabelIcon + '.svg'" alt="left-icon"
		[ngClass]="{'inactive': checkboxFormControl.value}" *ngIf="leftLabelIcon">

	<span (click)="onSetValue(false)" [ngClass]="{'inactive': checkboxFormControl.value}">
		{{leftLabel | translate}}
	</span>

	<input type="checkbox" [formControl]="checkboxFormControl" />

	<span (click)="onSetValue(true)" [ngClass]="{'inactive': !checkboxFormControl.value}">
		{{rightLabel | translate}}
	</span>

	<img [src]="'assets/feather-icons/' + rightLabelIcon + '.svg'" alt="right-icon"
		[ngClass]="{'inactive': !checkboxFormControl.value}" *ngIf="rightLabelIcon">
</div>
