import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAutomationProgramEditState } from './automation-program-edit.reducer';
import { IActuatorsState } from './actuator/automation-program-actuators.reducer';
import { AutomationDeviceType } from '../../automation/ts/enums/automation-device-type.enum';
import { RioRvsIohubDeviceType } from '../../rio-rvs/ts/enums/rio-rvs-iohub-device-type.enum';
import { IAutomationProgramProjectState } from './spreadsheet/automation-program-spreadsheet.reducer';
import { IAutomationProgramInputValueState } from './custom-input/automation-program-input-value.reducer';
import { AutomationProgramProjectTimeout } from '../ts/enums/automation-program-edit-project-timeout.enum';
import { IAutomationProgramModalDeviceState } from './modal-devices/automation-program-modal-devices.reducer';
import { IAutomationProgramProjectOptions } from './project-option/automation-program-project-option.reducer';
import { IAutomationProgramDescriptionState } from './description/automation-program-edit-description.reducer';

import * as automationProgramInputValueReducer from './custom-input/automation-program-input-value.reducer';
import * as automationProgramDeviceReducer from './modal-devices/automation-program-modal-devices.reducer';
import * as automationProgramLineDeviceReducer from './spreadsheet/automation-program-spreadsheet.reducer';
import * as descriptionReducer from '../ngrx/description/automation-program-edit-description.reducer';
import * as automationProgramActuatorsReducer from './actuator/automation-program-actuators.reducer';
import * as automationProgramReducer from './automation-program-edit.reducer';


export const selectActuatorState = createFeatureSelector<IActuatorsState>('automationProgramActuator');
export const selectAutomationProgramState = createFeatureSelector<IAutomationProgramEditState>('automationProgramEdit');
export const selectProjectOptionState = createFeatureSelector<IAutomationProgramProjectOptions>('automationProgramProjectOptions');
export const selectDescriptionState = createFeatureSelector<IAutomationProgramDescriptionState>('automationProgramEditDescription');
export const selectAutomationProgramSpreadsheetState = createFeatureSelector
<IAutomationProgramProjectState>('automationProgramSpreadsheet');
export const selectAutomationProgramInputValueState = createFeatureSelector
<IAutomationProgramInputValueState>('automationProgramInputValue');
export const selectAutomationProgramModalDevicesState = createFeatureSelector
<IAutomationProgramModalDeviceState>('automationProgramModalDevices');

export const selectAllJsonPrograms = () => createSelector(
	selectAutomationProgramState,
	automationProgramReducer.selectAllJsonAutomationPrograms
);

export const selectAutomationProgramLoadingState = () => createSelector(
	selectAutomationProgramState,
	automationProgramState => automationProgramState.isLoading
);

export const areModalAutomationProgramDevicesLoading = () => createSelector(
	selectAutomationProgramModalDevicesState,
	automationProgramState => automationProgramState.isLoading
);

export const selectModalAutomationProgramDevices = () => createSelector(
	selectAutomationProgramModalDevicesState,
	automationProgramDeviceReducer.selectAllModalAutomationProgramDevices
);

export const selectSpreadsheetLoadingState = () => createSelector(
	selectAutomationProgramSpreadsheetState,
	lineDevicesState => lineDevicesState.isLoading
);

export const selectAllSpreadsheetLineDevices = () => createSelector(
	selectAutomationProgramSpreadsheetState,
	automationProgramLineDeviceReducer.selectAllSpreadsheetLineDevices
);

export const selectLineDevicesByType = (lineDeviceType: AutomationDeviceType) => createSelector(
	selectAllSpreadsheetLineDevices(),
	automationProgramLineDevices => automationProgramLineDevices.filter(lineDevice => lineDevice.type === lineDeviceType)
);

export const selectAutomationProgramName = () => createSelector(
	selectAutomationProgramSpreadsheetState,
	lineDevicesState => lineDevicesState.programName
);

export const selectProjectOptions = () => createSelector(
	selectProjectOptionState,
	projectOptions => {
		const data = projectOptions || { ProjectTimeout: 1, ProjectTimeoutUnit: AutomationProgramProjectTimeout.SECONDS };
		const { ProjectTimeout, ProjectTimeoutUnit } = data;

		return { ProjectTimeout, ProjectTimeoutUnit };
	}
);

export const selectActuators = () => createSelector(
	selectActuatorState,
	automationProgramActuatorsReducer.selectAllActuators
);

export const selectAllAutomationProgramNames = () => createSelector(
	selectAllJsonPrograms(),
	automationPrograms => automationPrograms.map(automationProgram => automationProgram.name)
);

export const selectCustomInputValues = () => createSelector(
	selectAutomationProgramInputValueState,
	automationProgramInputValueReducer.selectAllAutomationProgramInputValues
);

export const selectVoutTemporaryDescriptions = () => createSelector(
	selectDescriptionState,
	descriptionReducer.selectAllDescriptions
);

export const selectAllVouts = () => createSelector(
	selectLineDevicesByType(AutomationDeviceType.OUTPUT),
	automationProgramLineDevices => automationProgramLineDevices.filter(lineDevice => lineDevice.device === RioRvsIohubDeviceType.VOUT)
);
