import { filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Injectable, inject } from '@angular/core';
import { IAppState } from './../../../../../root-reducers/index';
import { Observable, switchMap, of, forkJoin, map, take } from 'rxjs';
import { UserLevel } from './../../../../auth/ts/enums/user-level.enum';
import { AuthNgrxService } from './../../../../auth/services/auth-ngrx.service';
import { AddressBookStoreKey } from './../../ts/enums/address-book-store-key.enum';
import { IAddressBookContact } from './../../ts/models/address-book-contact.model';
import { AddressBookContactArrayService } from './../address-book-contact-array.service';
import { IAddressBookPermissionInfo } from './../../ts/models/permissions/address-book-permission-info.model';
import * as addressBookSelectors from '../../ngrx/address-book.selector';

@Injectable({
	providedIn: 'root'
})
export class AddressBookNgrxSelectorService {

	private readonly store: Store<IAppState>;

	constructor(
		private readonly authNgrxService: AuthNgrxService,
		private readonly addressBookContactArrayService: AddressBookContactArrayService
	) {
		this.store = inject(Store<IAppState>);
	}

	/**
	 * Returns all Address Book contacts
	 */
	public selectAddressBookContacts$(): Observable<IAddressBookContact[]> {
		return this.store.pipe(select(addressBookSelectors.selectAllContacts()))
			.pipe(
				switchMap(contacts => forkJoin({
					contacts: of(contacts),
					pubprotected: this.selectPermission$('pubprotected').pipe(take(1))
				})),
				map(response => {
					const { contacts, pubprotected } = response;

					return this.addressBookContactArrayService.addCanModifyToContacts(contacts, pubprotected);
				})
			);
	}

	/**
	 * Returns permission value from ngrx store, related to Address Book actions
	 * @param {keyof IAddressBookPermissionInfo} key Key of permission which should this function return
	 */
	public selectPermission$(key: keyof IAddressBookPermissionInfo): Observable<boolean> {
		return this.selectFromAddressBookStore$<IAddressBookPermissionInfo>(AddressBookStoreKey.PERMISSIONS)
			.pipe(
				map(permissions => permissions[key])
			);
	}

	/**
	 * Takes value from ngrx store, related to Address Book
	 * @param {AddressBookStoreKey} selector Key of value in ngrx store
	 */
	public selectFromAddressBookStore$<T>(selector: AddressBookStoreKey): Observable<T> {
		return this.store.pipe(select(addressBookSelectors.selectFromAddressBookStore(selector))) as unknown as Observable<T>;
	}

	/**
	 * Determines if button for export Address Book contacts, should be enabled/disabled
	 */
	public isExportEnabled$(): Observable<boolean> {
		return this.selectAddressBookContacts$()
			.pipe(
				map(contacts => AddressBookContactArrayService.isExportEnabled(contacts))
			);
	}

	/**
	 * Gets contact by username
	 * @param {string} username Value of passed username
	 */
	public selectContactById$(username: string): Observable<IAddressBookContact | null> {
		return this.selectAddressBookContacts$()
			.pipe(
				map(contacts => this.addressBookContactArrayService.getContactByValue(contacts, username, 'name'))
			);
	}

	/**
	 * Determines if button to import/export Address Book contacts is visible
	 */
	public showImportExport$(): Observable<boolean> {
		return this.authNgrxService.haveSpecificUserLevels$([UserLevel.BASIC])
			.pipe(
				map(isBasicUserLevel => !isBasicUserLevel)
			);
	}

	/**
	 * Determines if button to delete Address Book contact is enabled/disabled
	 */
	public shouldDisableDeleteButton$(): Observable<boolean> {
		return this.getSelectedContacts$()
			.pipe(
				map(selectedContacts => !selectedContacts.length)
			);
	}

	/**
	 * Determines if button to edit Address Book contact is enabled/disabled
	 */
	public shouldDisableEditButton$(): Observable<boolean> {
		return this.getSelectedContacts$()
			.pipe(
				switchMap(selectedContacts => this.getSelectedContact$()
					.pipe(
						map(selectedContact => {
							if (!selectedContact) { return true; }

							const selectedContactsLength = selectedContacts.length;

							return !selectedContact.canModifyContact || !selectedContactsLength || selectedContactsLength > 1;
						})
					))
			);
	}

	/**
	 * Selects last selected Address Book contact from the table, if not selected, won't emit the value
	 */
	public getLastSelectedContact$(): Observable<IAddressBookContact> {
		return this.getSelectedContact$()
			.pipe(
				filter((selectedContact): selectedContact is IAddressBookContact => !!selectedContact)
			);
	}

	/**
	 * Determines if there are more than one selected contact
	 */
	public shouldClearPhoneInput$(): Observable<boolean> {
		return this.getSelectedContacts$()
			.pipe(
				map(selectedContacts => selectedContacts.length > 1)
			);
	}

	/**
	 * Selects last selected Address Book contact from the table, if not selected, it will return null
	 */
	public getSelectedContact$(): Observable<IAddressBookContact | null> {
		return this.selectAddressBookContacts$()
			.pipe(
				map(contacts => this.addressBookContactArrayService.getLastSelectedContact(contacts))
			);
	}

	/**
	 * Returns only selected contacts from Address Book table
	 */
	public getSelectedContacts$(): Observable<IAddressBookContact[]> {
		return this.selectAddressBookContacts$()
			.pipe(
				map(contacts => AddressBookContactArrayService.getSelectedContacts(contacts))
			);
	}

	/**
	 * Returns true if PC Phone component should be renderer in Address Book root component
	 * @param {boolean} isOtherMode Value if true, means that Address Book is managed for other user (than currently logged user)
	 */
	public showPhone$(isOtherMode: boolean): Observable<boolean> {
		return this.selectPermission$('callenabled_ctip')
			.pipe(
				map(callEnabledCtip => callEnabledCtip && !isOtherMode)
			);
	}
}
