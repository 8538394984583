import { Injectable } from '@angular/core';
import { switchMap, map, combineLatest, take } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SideBarSearchService } from './../services/side-bar-search.service';
import { SideBarSearchHttpService } from './../services/side-bar-search-http.service';
import { AuthNgrxService } from './../../../../../components/auth/services/auth-ngrx.service';
import { SideBarSearchNgrxSelectorService } from './../services/ngrx/side-bar-search-ngrx-selector.service';
import * as sidebarSearchActions from './side-bar-search.actions';

@Injectable()
export class SideBarSearchActionEffects {

	constructor(
		private readonly actions$: Actions,
		private readonly authNgrxService: AuthNgrxService,
		private readonly sideBarSearchService: SideBarSearchService,
		private readonly sideBarSearchHttpService: SideBarSearchHttpService,
		private readonly sideBarSearchNgrxSelectorService: SideBarSearchNgrxSelectorService
	) { }

	sidebarSearchAction$ = createEffect(() => this.actions$.pipe(
		ofType(sidebarSearchActions.loadSearchBarPages),
		switchMap(() => this.sideBarSearchHttpService.fetchSearchNavigationPages$().pipe(take(1))),
		switchMap(pages => combineLatest([
			this.authNgrxService.haveAdminUserLevel$(),
			this.authNgrxService.selectAllPermissions$(),
			this.authNgrxService.selectSectionPermissions$()
		])
			.pipe(
				map(response => this.sideBarSearchService.formatPagesResponse([pages, ...response]))
			)),
		map(pages => sidebarSearchActions.finishLoadingPages({ payload: { pages } }))
	));

	filterPagesBySearchTerm$ = createEffect(() => this.actions$.pipe(
		ofType(sidebarSearchActions.filterPagesBySearchTerm),
		switchMap(action => this.sideBarSearchNgrxSelectorService.selectAllPagesState$()
			.pipe(
				take(1),
				map(pages => this.sideBarSearchService.getMatchingPages(action.payload.value, pages))
			)),
		map(pages => sidebarSearchActions.setPagesFilteredBySearchTerm({ payload: { pages } }))
	));
}
