import { forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { RioRvsService } from '../services/rio-rvs.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WioNgrxService } from '../services/wio-ngrx.service';
import { WioHttpService } from '../services/http/wio-http.service';
import { RioRvsStoreKey } from '../ts/enums/rio-rvs-store-key.enum';
import { RioRvsNgrxService } from '../services/rio-rvs-ngrx.service';
import { MainUtilService } from '../../../services/main-util.service';
import { RioRvsHttpService } from '../services/http/rio-rvs-http.service';
import { RioRvsWioDeviceType } from '../ts/enums/rio-rvs-device-type.enum';
import { RioRvsDiscoveryType } from '../ts/enums/rio-rvs-discovery-type.enum';
import { RioRvsHttpLogicService } from '../services/http/rio-rvs-http-logic.service';
import { map, tap, takeUntil, switchMap, take, withLatestFrom, filter } from 'rxjs/operators';
import { RioRvsWioIohubShortDeviceClass } from '../ts/enums/rio-rvs-wio-iohub-short-device-class.enum';
import { IRioRvsModalAnalogLineDevice } from '../ts/models/modal-line-devices/rio-rvs-modal-analog-line-device.model';
import { IRioRvsModalDigitalLineDevice } from '../ts/models/modal-line-devices/rio-rvs-modal-digital-line-device.model';
import * as rioRvsActions from './rio-rvs.action';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/root-reducers';
import { selectAllRioRvsDevices } from './rio-rvs.selector';

@Injectable()
export class RioRvsEffects {

	constructor(
		private actions$: Actions,
		private rioRvsService: RioRvsService,
		private wioHttpService: WioHttpService,
		private wioNgrxService: WioNgrxService,
		private rioRvsHttpService: RioRvsHttpService,
		private rioRvsNgrxService: RioRvsNgrxService,
		private rioRvsHttpLogicService: RioRvsHttpLogicService,
		private store: Store<IAppState>
	) { }

	loadRioRvsDevicesTableOverview$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.loadRioRvsDevicesTableOverview),
		switchMap(action => forkJoin({
			macIp: of(action.payload.macIp),
			devices: this.rioRvsHttpService.fetchAvailableDevices$(RioRvsDiscoveryType.NO)
		})
			.pipe(
				takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
			)),
		map(response => {
			const { devices, macIp } = response;
			this.rioRvsService.handleSetSelectedDevice(devices, macIp);

			return rioRvsActions.finishLoadingRioRvsDevices({ payload: { devices } });
		})
	));

	fillRioRvsWioLineDevices$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.fillRioRvsWioLineDevices),
		withLatestFrom(this.rioRvsNgrxService.selectFromRioRvsStore$<boolean>(RioRvsStoreKey.IS_LOADING_RIO_RVS_LINE_DEVICES)),
		filter(response => {
			const [_, isLoading] = response;

			return isLoading;
		}),
		map(response => {
			const [action] = response;
			const { macIp } = action.payload;
			const payload = { macIp };

			return rioRvsActions.loadRioRvsDevicesTableOverview({ payload });
		})
	));

	rioRvsWioLoadDevices$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.rioRvsWioLoadDevices),
		switchMap(action =>
			this.store.select(selectAllRioRvsDevices()).pipe(
				take(1),
				switchMap((devices) => {
					if (devices.length === 0) {
						return this.rioRvsHttpService.fetchAvailableDevices$(RioRvsDiscoveryType.YES)
							.pipe(
								takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
							);
					}
					return this.rioRvsHttpService.fetchAvailableDevices$(action.payload.discovery)
						.pipe(
							takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
						);
				})
			)),
		map(devices => {
			const payload = { devices };

			return rioRvsActions.finishLoadingRioRvsDevices({ payload });
		})
	));

	fetchAllWioSlaveNodes$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.fetchAllWioSlaveNodes, rioRvsActions.autoLoadWioSlaveNodes),
		switchMap(action => {
			const pollFetch = action.type === '[Rio Rvs] Auto Load All Wio Slave Nodes';
			const source$ = pollFetch ? this.wioHttpService.autoLoadWioSlaveNodes$() : this.wioHttpService.fetchAvailableWioNodes$();

			return source$
				.pipe(
					takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
				);
		}),
		map(response => {
			const wioSlaveNodes = this.rioRvsService.updateWioSlaveNodePortsInfo(response);
			const payload = { wioSlaveNodes };

			return rioRvsActions.finishLoadingWioSlaveNodes({ payload });
		})
	));

	refreshWioNodes$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.refreshWioNodes),
		switchMap(action => this.wioHttpService.refreshWioNodes$(action.payload.selectedWioDevice, action.payload?.selectedWioNode)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
			)),
		tap(() => this.wioNgrxService.fetchAllWioSlaveNodes())
	), { dispatch: false });

	removeWioSlaveNode$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.removeWioSlaveNode),
		switchMap(action => this.wioHttpService.removeWioSlave$(action.payload.selectedWioSlaveNode, action.payload.selectedWioMaster)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
			)),
		tap(() => this.wioNgrxService.fetchAllWioSlaveNodes())
	), { dispatch: false });

	releaseUnbindDevice$ = createEffect(() => this.actions$.pipe(
		ofType(
			rioRvsActions.rioRvsBindDeviceAction,
			rioRvsActions.rioRvsUnbindDeviceAction,
			rioRvsActions.rioRvsReleaseDeviceAction,
			rioRvsActions.updateDeviceInformationAction
		),
		switchMap(action => {
			switch (action.type) {
			case '[Rio Rvs] Release Device':
				return this.rioRvsHttpService.removeResetDevice$(action.payload.selectedDevice)
					.pipe(
						takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
					);
			case '[Rio Rvs] Unbind Device':
				return this.rioRvsHttpService.unbindDevice$(action.payload.selectedDevice)
					.pipe(
						takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
					);
			case '[Rio Rvs] Bind Device':
				const { selectedDevice, bindDeviceId, newDeviceDescription, newDeviceIpAddress, callback: bindCallback } = action.payload;

				return this.rioRvsHttpService.bindDevice$(selectedDevice, bindDeviceId, newDeviceDescription, newDeviceIpAddress)
					.pipe(
						takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables))),
						tap(() => bindCallback())
					);
			case '[Rio Rvs] Update Device Information':
				const { updateDevice, newIpAddress, newDescription, callback: updateCallback } = action.payload;

				return this.rioRvsHttpService.updateDeviceInformation$(updateDevice, newIpAddress, newDescription)
					.pipe(
						takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables))),
						tap(() => updateCallback())
					);
			}
		}),
		tap(response => this.rioRvsHttpLogicService.showResponseNotifications(response))
	), { dispatch: false });

	updateWioSlaveInformation$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.updateWioSlaveInformation),
		switchMap(action => {
			const { wioSlaveNode, newDescription } = action.payload;

			return this.wioHttpService.updateWioSlaveInformation$(wioSlaveNode, newDescription)
				.pipe(
					takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
				);
		}),
		map(() => this.wioNgrxService.fetchAllWioSlaveNodes())
	), { dispatch: false });

	startLoadingRioRvsWioLineDevices$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.startLoadingRioRvsWioLineDevices),
		withLatestFrom(this.rioRvsNgrxService.selectFromRioRvsStore$<boolean>(RioRvsStoreKey.IS_LOADING_RIO_RVS_LINE_DEVICES)),
		filter(response => {
			const [action, isLoading] = response;
			const { checkIfDevicesAreLoading } = action.payload;

			return checkIfDevicesAreLoading ? isLoading : true;
		}),
		switchMap(response => {
			const [action] = response;
			const { type, resourceId } = action.payload;
			const isPortsConfiguration = this.rioRvsService.getIsPortsConfiguration();
			const capitalCaseType = MainUtilService.capitalCaseString(type) as RioRvsWioDeviceType;

			if (isPortsConfiguration) {
				return this.rioRvsHttpService.fetchDeviceConfiguration$(capitalCaseType, resourceId)
					.pipe(
						takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
					);
			}

			return this.rioRvsHttpService.fetchLineDevices$(capitalCaseType, resourceId)
				.pipe(
					takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
				);
		}),
		map(rioRvsWioLineDevices => rioRvsActions.finishLoadingRioRvsWioLineDevices({ payload: { rioRvsWioLineDevices } }))
	));

	eraseIconConfiguration$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.eraseIconConfiguration),
		switchMap(action => this.rioRvsHttpService.eraseIconConfiguration$(action.payload.resource)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
			))
	), { dispatch: false });

	saveLineDeviceChange$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.saveLineDeviceChange),
		switchMap(action => {
			const { newIcon, formValues, resource, selectedRioRvsDevice, iohubClass } = action.payload;

			const isGainValueOnly = formValues.length === 1;
			const iconsRequest$ = !isGainValueOnly ? this.rioRvsHttpService.saveDeviceIconsList$(newIcon, resource) : of(null);
			const lineDevices$ = this.rioRvsHttpService.saveMultipleDeviceChanges$(
				iohubClass, resource, isGainValueOnly, selectedRioRvsDevice, formValues
			);

			return forkJoin({
				resource: of(resource), isGainValueOnly: of(isGainValueOnly),
				iconsResponse: iconsRequest$, lineDevicesResponse: lineDevices$
			})
				.pipe(
					takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
				);
		}),
		tap(response => this.rioRvsNgrxService.loadModalRioRvsWioLineDevices(response.resource))
	), { dispatch: false });

	setMultipleWioValuesAtOnce$ = createEffect(() => this.actions$.pipe(
		ofType(rioRvsActions.setMultipleWioValuesAtOnce),
		switchMap(action => {
			const { device, selectedWioNodeItem } = action.payload;
			const { nodeInd, nodeType } = selectedWioNodeItem;
			const wioNodeType = this.rioRvsService.getWioLineType(nodeType) as RioRvsWioIohubShortDeviceClass;

			return forkJoin({
				resource: of(device.resource as string), payload: of(action.payload),
				selectedDevices: this.wioNgrxService.selectWioNodeLineDevices$
				<Array<IRioRvsModalDigitalLineDevice | IRioRvsModalAnalogLineDevice>>(wioNodeType, nodeInd).pipe(take(1))
			});
		}),
		switchMap(response => {
			const { payload, selectedDevices } = response;
			const { device, parameter, tab, value } = payload;
			const lineDeviceNumbers = selectedDevices.map(item => `${item.num}`);

			return forkJoin({
				response: of(response),
				shouldRefresh: this.wioHttpService.saveMultipleWioValuesAtOnce$(tab, device, parameter, value, lineDeviceNumbers)
			})
				.pipe(
					takeUntil(this.actions$.pipe(ofType(rioRvsActions.cancelRioRvsHTTPObservables)))
				);
		}),
		tap(totalResponse => {
			const { response, shouldRefresh } = totalResponse;
			const { resource } = response;

			if (!shouldRefresh) { return; }

			this.rioRvsNgrxService.loadModalRioRvsWioLineDevices(resource);
		})
	), { dispatch: false });
}
