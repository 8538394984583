import { DateTime } from 'luxon';
import { ElementRef, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from '../../../ts/enums/routes.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, filter } from 'rxjs';
import { MainUtilService } from '../../../services/main-util.service';
import { NetworkingInfoSdwanService } from './networking-info-sdwan.service';
import { NetworkingInfoLayout } from '../ts/enums/networking-info-layout.enum';
import { ITrfaResource } from '../../trfa/ts/models/utils/trfa-resource.model';
import { NetworkingInfoModalType } from '../ts/enums/networking-info-modal-type.enum';
import { NetworkingInfoNewsCodeService } from './news/networking-info-news-code.service';
import { createTrimWhitespaceValidator } from '../../../utils/form-validators.validator';
import { NetworkingInfoNewsTableService } from './news/networking-info-news-table.service';
import { NetworkingInfoCategory } from '../ts/enums/networking-info-resource-category.enum';
import { INetworkingInfoLogResponse } from '../ts/models/networking-info-log-response.model';
import { NetworkingInfoResourceState } from '../ts/enums/networking-info-resource-state.enum';
import { INetworkingInfoModal } from '../ts/models/resource/networking-info-modal-resource.model';
import { INetworkingInfoCustomLogItem } from '../ts/models/networking-info-custom-log-item.model';
import { INetworkingInfoQualityData } from '../ts/models/quality/networking-info-quality-data.model';
import { INetworkingInfoResourceItem } from '../ts/models/resource/networking-info-resource-item.model';
import { NetworkingInfoResourceTunnelState } from '../ts/enums/networking-info-resource-tunnel-state.enum';
import { INetworkingInfoMobileTrfaForm } from '../ts/models/mobile/networking-info-mobile-trfa-form.model';
import { INetworkingInfoSdwanVpnModalState } from '../ts/models/networking-info-sdwan-vpn-modal-state.model';
import { badNetworkQualityScores, NetworkingInfoQuality, SDWAN_UNIT_WIDTH } from '../util/networking-info-util';
import { INetworkingInfoResourceLocation } from '../ts/models/resource/networking-info-resource-location.model';
import { INetworkingInfoResourcesResponse } from '../ts/models/resource/networking-info-resources-response.model';
import { INetworkingInfoTrfaModalInitialState } from '../ts/models/networking-info-trfa-modal-initial-state.model';
import { INetworkingInfoSdwanModalInitialState } from '../ts/models/networking-info-sdwan-modal-initial-state.model';
import { INetworkingInfoQualityAdvancedData } from '../ts/models/quality/networking-info-quality-advanced-data.model';
import { INetworkingInfoMobileTrfaSavePayload } from '../ts/models/mobile/networking-info-mobile-trfa-save-payload.model';
import { IPortPreviewPPPModel } from '../../networking/components/ports-preview/ts/models/resource-definitions/port-preview-ppp-model';

@Injectable({
	providedIn: 'root'
})
export class NetworkingInfoService {

	badNetworkQualityScores: number[] = badNetworkQualityScores.slice();
	vpnAdvancedSdwanResource: INetworkingInfoResourceItem | null = null;

	private closeMainVPNModal: Subject<void> = new Subject<void>();
	private scrollToRight: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private selectedTunnelResource: BehaviorSubject<string> = new BehaviorSubject<string>('');
	private showBackMainVpnModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private networkingCodeService: NetworkingInfoNewsCodeService,
		private networkingInfoSdwanService: NetworkingInfoSdwanService,
		private networkingInfoNewsTableService: NetworkingInfoNewsTableService
	) { }

	buildMobileTrfaForm(): FormGroup {
		const whitespaceValidator = createTrimWhitespaceValidator();

		return this.formBuilder.group({
			VALUE: [false],
			'TRFA-THR': ['', [whitespaceValidator, Validators.pattern(/^[1-9][0-9]?$|^100$/)]],
			'TRFA-DAY': ['', [whitespaceValidator, Validators.pattern(/^((1|[2-9]|[1-2]\d|3[0-1])|#)$/)]],
			'TRFA-TIME': ['', [whitespaceValidator, Validators.pattern(/^(((0?\d|1\d|2[0-3]):(0?\d|[1-5]\d))|#)$/)]],
			'TRFA-ALERT': ['', [whitespaceValidator, Validators.pattern(/^(No|([1-9]\d?|1\d{2}|2[0-4]\d|25[0-5])(,([1-9]\d?|1\d{2}|2[0-4]\d|25[0-5]))*)$/i)]],
			'TRFA-LIMIT': ['', [whitespaceValidator, Validators.pattern(/^(([1-9]|[1-9]\d{1,8}|[1-3]\d{9}|41\d{8}|428\d{7}|4293\d{6}|42948\d{5}|429495\d{4}|4294966\d{3}|42949671\d{2}|429496728\d{1}|429496729[0-5])|#)$/)]]
		});
	}

	getMobileTrfaFormValues(resourceConfiguration: IPortPreviewPPPModel): INetworkingInfoMobileTrfaForm {
		const {
			VALUE, 'TRFA-ALERT': trfaAlert, 'TRFA-LIMIT': trfaLimit, 'TRFA-START': trfaStart, 'TRFA-THR': trfaThr
		} = resourceConfiguration.TRFA;

		return {
			VALUE,
			'TRFA-THR': trfaThr,
			'TRFA-LIMIT': trfaLimit,
			'TRFA-DAY': this.getTrfaDayValue(trfaStart),
			'TRFA-TIME': this.getTrfaTimeValue(trfaStart),
			'TRFA-ALERT': trfaAlert.toString().toLowerCase()
		};
	}

	getTrfaDayValue(value: string | undefined): string {
		if (!value) { return '#'; }

		return this.getTrfaDatetimeValue(value, this.getDayValue.bind(this, value));
	}

	getTrfaTimeValue(value: string | undefined): string {
		if (!value) { return '#'; }

		return this.getTrfaDatetimeValue(value, this.getTimeValue.bind(this, value));
	}

	getTrfaSavePayload(formValues: INetworkingInfoMobileTrfaForm): INetworkingInfoMobileTrfaSavePayload {
		const { 'TRFA-DAY': trfaDay, 'TRFA-TIME': trfaTime, ...restOfFormValues } = formValues;

		return { ...restOfFormValues, 'TRFA-START': this.getTrfaStartDatetime(trfaDay, trfaTime) };
	}

	getTrfaStartDatetime(day: string, time: string): string {
		const isDayDisabled = this.isTrfaDatetimeDisabled(day);
		const isTimeDisabled = this.isTrfaDatetimeDisabled(time);

		if (isDayDisabled || (isDayDisabled && isTimeDisabled)) {
			return '#';
		}

		if (isTimeDisabled) {
			return day;
		}

		return `${day},${time}`;
	}

	isTrfaDatetimeDisabled(value: string): boolean {
		return value.toLowerCase() === '#';
	}

	getTrfaDatetimeValue(datetime: string, callback: (datetime: string) => string): string {
		const isDisabled = this.isTrfaDatetimeDisabled(datetime);

		if (isDisabled) { return ''; }

		return callback(datetime);
	}

	getDayValue(datetime: string): string {
		const [day] = datetime.split(',');

		return day;
	}

	getTimeValue(datetime: string): string {
		if (!datetime.includes(',')) { return ''; }

		const [_, time] = datetime.split(',');

		return time;
	}

	formatAvailableResources(response: INetworkingInfoResourcesResponse): INetworkingInfoResourceItem[] {
		const { 'VPN status': vpnStatus, message } = response.Response;

		if (message) { return []; }

		return vpnStatus.map(item => {
			const { id, tst, st } = item;
			const stateIcon = this.getIconState(tst || st);
			const des = NetworkingInfoService.getResourceDescription(item, id);

			return { ...item, des, stateIcon };
		});
	}

	getIconState(resourceState: NetworkingInfoResourceTunnelState | NetworkingInfoResourceState): string {
		const mapIconPaths = 'assets/map-markers/';
		const featherIconPath = 'assets/feather-icons/';

		switch (resourceState) {
		case NetworkingInfoResourceState.UP:
			return featherIconPath + 'smiley-good.svg';
		case NetworkingInfoResourceState.DOWN:
			return featherIconPath + 'smiley-dead.svg';
		case NetworkingInfoResourceTunnelState.ALL_UP:
			return mapIconPaths + 'ALL-UP-CIRCLE.svg';
		case NetworkingInfoResourceTunnelState.ALL_DOWN:
			return mapIconPaths + 'ALL-DOWN-CIRCLE.svg';
		case NetworkingInfoResourceTunnelState.BACKUP_UP:
			return mapIconPaths + 'BACKUP-UP-CIRCLE.svg';
		case NetworkingInfoResourceTunnelState.SOME_UP:
			return mapIconPaths + 'SOME-UP-CIRCLE.svg';
		default:
			return '';
		}
	}

	formatNetworkEvents(
		response: INetworkingInfoLogResponse, availableResources: INetworkingInfoResourceItem[]
	): INetworkingInfoCustomLogItem[] {
		const { message, SystemLog } = response.Response;

		this.networkingInfoNewsTableService.setResponseErrorMessage(message);

		if (message) { return []; }

		return SystemLog
			.map(systemLogItem => this.networkingCodeService.formatLogItemBasedOnCode(systemLogItem, availableResources))
			.slice()
			.reverse();
	}

	public formatCurrentDatetimeForApi(dateObject: DateTime): string {
		const dateFormat = dateObject.toFormat('yyyy-MM-dd');
		const timeFormat = dateObject.toFormat('HH:mm:ss');

		return dateFormat + 'T' + timeFormat;
	}

	public getStartDatetime(startTimeLuxonObject: DateTime, minutes: number): string {
		const endLuxonObject = startTimeLuxonObject.minus({ minutes });

		return this.formatCurrentDatetimeForApi(endLuxonObject);
	}
	public getStartDatetimeSeconds(startTimeLuxonObject: DateTime, seconds: number): string {
		const endLuxonObject = startTimeLuxonObject.minus({ seconds });

		return this.formatCurrentDatetimeForApi(endLuxonObject);
	}

	formatVpnResourcesForMap(vpnResources: INetworkingInfoResourceItem[]): INetworkingInfoResourceLocation[] {
		return vpnResources.reduce((accumulator, vpnResource) => {
			if (!vpnResource.hasOwnProperty('loc')) { return accumulator; }

			const { des, tst, id, loc } = vpnResource;
			const [latitude, longitude] = loc.split(',').slice(0, 2);
			const resourceToAdd = { latitude: +latitude, longitude: +longitude, name: des, status: tst, resourceId: id };

			return [...accumulator, resourceToAdd];
		}, [] as INetworkingInfoResourceLocation[]);
	}

	getShowUpTunnels(): boolean {
		return MainUtilService.getLocalStorageValue<boolean>('vpnShowUpTunnels', false);
	}

	buildLayoutFormView(checkboxForm: boolean): FormGroup {
		return this.formBuilder.group({
			layout: [this.getDefaultBasicAdvancedFormValue(checkboxForm)]
		});
	}

	getDefaultBasicAdvancedFormValue(checkboxForm: boolean): NetworkingInfoLayout | boolean {
		return checkboxForm ? NetworkingInfoLayout.BASIC : false;
	}

	buildVpnLayoutFormView(): FormGroup {
		return this.formBuilder.group({
			view: [NetworkingInfoLayout.VPN_LIST]
		});
	}

	getTrfaModalInitialState(
		selectedResource: INetworkingInfoResourceItem | INetworkingInfoModal, showTop10Tab: boolean
	): INetworkingInfoTrfaModalInitialState {
		const modalType = NetworkingInfoModalType.STATISTICS;

		return { selectedResource, modalType, showTop10Tab };
	}

	getSdwanModalInitialState(resource: INetworkingInfoResourceItem): INetworkingInfoSdwanModalInitialState {
		const modalType = NetworkingInfoModalType.SDWAN_STATISTICS;
		const resources = [NetworkingInfoService.getSdwanSelectedResource(resource)];

		return { modalType, resources, vpnResources: [resource] };
	}

	public static getSdwanSelectedResource(resource: INetworkingInfoResourceItem): ITrfaResource {
		const tunnelResource = NetworkingInfoService.formatTrfaModalResource(resource);
		const paths = resource.paths.map(path => {
			const id = `Path ${path.id}`;
			const cat = NetworkingInfoCategory.SDWAN;

			return { id, cat, isUp: true, description: '', maxSpeedInput: '-', maxSpeedOutput: '-' };
		}) as ITrfaResource[];

		return { ...tunnelResource, paths };
	}

	getSdwanVpnInitialState(selectedResource: INetworkingInfoResourceItem): INetworkingInfoSdwanVpnModalState {
		const { cat } = selectedResource;
		const showTop10Tab = cat === NetworkingInfoCategory.LAN;
		const showSdwanTab = cat === NetworkingInfoCategory.VPN;
		const initialState = this.getTrfaModalInitialState(selectedResource, showTop10Tab);

		return { ...initialState, showSdwanTab, vpnResources: [selectedResource] };
	}

	getTrfaModalResource(resources: INetworkingInfoResourceItem[], selectedResource: INetworkingInfoResourceItem): ITrfaResource[] {
		const { id } = selectedResource;
		const matchingResource = resources.find(resource => resource.id === id);

		return [NetworkingInfoService.formatTrfaModalResource(matchingResource || selectedResource)];
	}

	public static formatTrfaModalResource(resource: INetworkingInfoResourceItem): ITrfaResource {
		const { id, des: description, cat, maxbwi: maxSpeedInput, maxbwo: maxSpeedOutput, st, tst, sbt } = resource;
		const isUp = st !== NetworkingInfoResourceState.DOWN && tst !== NetworkingInfoResourceTunnelState.ALL_DOWN;

		return { id, cat, description, sbt, maxSpeedInput, maxSpeedOutput, isUp, paths: [] };
	}

	emitScrollOnRight(): void {
		this.scrollToRight.next(true);

		setTimeout(() => this.scrollToRight.next(false), 100);
	}

	getScrollOnRightEvent$(): Observable<boolean> {
		return this.scrollToRight.asObservable();
	}

	emitCloseMainVpnModal(resource: INetworkingInfoResourceItem): void {
		this.closeMainVPNModal.next();
		this.showBackMainVpnModal.next(true);
		this.vpnAdvancedSdwanResource = { ...resource };
	}

	watchCloseMainVpnModal$(): Observable<void> {
		return this.closeMainVPNModal.asObservable();
	}

	clearVpnAdvancedSdwanResource(): void {
		this.vpnAdvancedSdwanResource = null;
		this.showBackMainVpnModal.next(false);
	}

	getVpnAdvancedSdwanResource(): INetworkingInfoResourceItem | null {
		return this.vpnAdvancedSdwanResource;
	}

	watchShowBackModalButton$(): Observable<boolean> {
		return this.showBackMainVpnModal.asObservable();
	}

	openNetworkingSettings(ipResource: string | null): void {
		if (!ipResource) { return; }

		const featureName = RoutesEnum.NETWORK_SETTINGS;
		const outlets = { [featureName]: featureName };

		this.router.navigate([{ replaceUrl: true }, { outlets }], { relativeTo: this.activatedRoute });

		this.selectedTunnelResource.next(ipResource);
	}

	watchSelectedTunnelResourceChange$(): Observable<string> {
		return this.selectedTunnelResource.asObservable()
			.pipe(
				filter(value => !!value)
			);
	}

	renderSDWANBasicStripe(
		overallData: INetworkingInfoQualityData[], canvasElement: ElementRef, unitsVisiblity: boolean[], barHeight: number
	): void {
		if (!canvasElement?.nativeElement) { return; }

		const context = canvasElement.nativeElement.getContext('2d') as CanvasRenderingContext2D;
		MainUtilService.clearCanvas(canvasElement);

		overallData.forEach((item, index) => {
			const color = this.getBasicViewQualityColor(item.quality, unitsVisiblity);

			this.fillWholeHeightRectangle(context, color, index, barHeight);
		});
	}

	fillWholeHeightRectangle(context: CanvasRenderingContext2D, color: string, index: number, barHeight: number): void {
		context.fillStyle = color;
		context.fillRect(index * SDWAN_UNIT_WIDTH, 0, SDWAN_UNIT_WIDTH, barHeight);
	}

	renderSDWANAdvancedStripe(
		overallData: INetworkingInfoQualityAdvancedData[], barHeight: number, downloadStripe: boolean,
		multiplyPixelUnit: number, canvasElement: ElementRef, unitsVisiblity: boolean[]
	): void {
		if (!canvasElement?.nativeElement) { return; }

		const context = canvasElement.nativeElement.getContext('2d') as CanvasRenderingContext2D;
		const stripesData = this.networkingInfoSdwanService.getAdvancedZoomDataFormat(overallData, downloadStripe);

		MainUtilService.clearCanvas(canvasElement);

		const [missUnitsVisible, congestionUnitsVisible, jitterUnitsVisible, timeTripUnitsVisible] = unitsVisiblity;

		stripesData.forEach((item, index) => {
			const { quality, tt, jit, fbw, miss } = item;

			if (this.badNetworkQualityScores.includes(quality)) {
				const color = this.getBadAdvancedQualityColor(quality, unitsVisiblity);

				this.fillWholeHeightRectangle(context, color, index, barHeight);
				return;
			}

			const showTt = !!tt && timeTripUnitsVisible;
			const showJit = !!jit && jitterUnitsVisible;
			const showFbw = !!fbw && congestionUnitsVisible;
			const showMiss = !!miss && missUnitsVisible;

			const ttHeight = this.getAdvancedViewUnitBarHeight(showTt, tt, multiplyPixelUnit);
			const jitHeight = this.getAdvancedViewUnitBarHeight(showJit, jit, multiplyPixelUnit);
			const fbwHeight = this.getAdvancedViewUnitBarHeight(showFbw, fbw, multiplyPixelUnit);
			const missHeight = this.getAdvancedViewUnitBarHeight(showMiss, miss, multiplyPixelUnit);

			const [
				ttPosition, jitPosition, fbwPosition, missPosition
			] = this.getAdvancedUnitesPositions([ttHeight, jitHeight, fbwHeight, missHeight]);

			this.renderAdvancedViewUnitRect(context, !!tt, '#212D86', index, ttHeight, ttPosition);
			this.renderAdvancedViewUnitRect(context, !!jit, '#1FC4EF', index, jitHeight, jitPosition);
			this.renderAdvancedViewUnitRect(context, !!fbw, '#EDBA0B', index, fbwHeight, fbwPosition);
			this.renderAdvancedViewUnitRect(context, !!miss, '#DF1B0B', index, missHeight, missPosition);
		});
	}

	getAdvancedUnitesPositions(heights: number[]): number[] {
		return heights.reduce((accumulator, _, index) => {
			if (index === 0) { return [...accumulator, 0]; }

			const sumOfItems = heights.reduce((sumAcc, value, sumIndex) => sumIndex < index ? sumAcc + value : sumAcc, 0);

			return [...accumulator, sumOfItems];
		}, [] as number[]);
	}

	getAdvancedViewUnitBarHeight(showValue: boolean, value: number, multiplyPixelUnit: number): number {
		return !showValue ? 0 : (value + 1) * multiplyPixelUnit;
	}

	renderAdvancedViewUnitRect(
		context: CanvasRenderingContext2D, showUnit: boolean,
		color: string, index: number, height: number, position: number
	): void {
		if (!showUnit) { return; }

		context.fillStyle = color;
		context.fillRect(index * SDWAN_UNIT_WIDTH, position, SDWAN_UNIT_WIDTH, height);
	}

	getBadAdvancedQualityColor(quality: number, unitsVisiblity: boolean[]): string {
		const [, , , , inactiveUnitsVisible, outOfServiceVisible, errorVisible] = unitsVisiblity;

		if (quality === NetworkingInfoQuality.DOWN && outOfServiceVisible) {
			return 'rgba(44, 44, 44, 1)';
		}

		if (quality === NetworkingInfoQuality.INACTIVE && inactiveUnitsVisible) {
			return '#979797';
		}

		if ((quality === NetworkingInfoQuality.CELLKEY_NETWORK_ERROR1 || quality === NetworkingInfoQuality.CELLKEY_NETWORK_ERROR2)
			&& errorVisible) {
			return '#ffffff';
		}

		return 'transparent';
	}

	getBasicViewQualityColor(quality: number, unitsVisiblity: boolean[]): string {
		const [
			goodUnitsVisible, fairUnitsVisible, poorUnitsVisible, inactiveUnitsVisible, outOfServiceVisible, errorVisible
		] = unitsVisiblity;

		if (quality === NetworkingInfoQuality.GOOD && goodUnitsVisible) {
			return '#49d687';
		}

		if (quality === NetworkingInfoQuality.FAIR && fairUnitsVisible) {
			return '#FFD644';
		}

		if ((quality === NetworkingInfoQuality.POOR || quality === NetworkingInfoQuality.BAD) && poorUnitsVisible) {
			return '#FF8372';
		}

		if (quality === NetworkingInfoQuality.DOWN && outOfServiceVisible) {
			return 'rgba(44, 44, 44, 1)';
		}

		if (quality === NetworkingInfoQuality.INACTIVE && inactiveUnitsVisible) {
			return '#979797';
		}

		if ((quality === NetworkingInfoQuality.CELLKEY_NETWORK_ERROR1 || quality === NetworkingInfoQuality.CELLKEY_NETWORK_ERROR2)
			&& errorVisible) {
			return '#ffffff';
		}

		return 'transparent';
	}

	static getResourceDescription(resource: INetworkingInfoResourceItem | undefined, resourceId: string): string {
		return resource ? resource.des : `(${resourceId})`;
	}

	static generateNetworkLogResource(resource: string, resourceDescription: string): string {
		return `${resource} ${resourceDescription}`;
	}
}
