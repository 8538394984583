import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { RoutesEnum } from '../../../ts/enums/routes.enum';
import { ToastrType } from '../../../ts/enums/toastr-type.enum';
import { environment } from '../../../../environments/environment';
import { ISelectData } from '../../../ts/models/select-data.model';
import { MainUtilService } from '../../../services/main-util.service';
import { IPreferencesStore } from '../ts/models/preferences-store.model';
import { IPreferencesSavePayload } from '../ts/models/preferences-save-payload.model';
import { IPreferencesUserResponse } from '../ts/models/preferences-user-response.model';
import { PreferencesAbilisphoneSide } from '../ts/enums/preferences-abilisphone-side.enum';
import { PreferencesFormValidatorService } from './form/preferences-form-validator.service';
import { VirtualOfficeHttpService } from '../../virtual-office/services/virtual-office-http.service';
import { IPreferencesAbilisphoneNumbersResponse } from '../ts/models/preferences-abilisphone-numbers-response.model';
import { IPreferencesAbilisphoneNumberRecordItem } from '../ts/models/preferences-abilisphone-number-record-item.model';
import { IUserPreferencesActionResponse } from '../../user-preferences/ts/models/user-preferences-action-response.model';
import * as customOperators from '../../../custom-operators/custom-operators';
import {
	BasicUserPreferencesHttpService
} from '../../user-preferences/services/basic-user-preferences/basic-user-preferences-http.service';

@Injectable({
	providedIn: 'root'
})
export class PreferencesHttpService {

	private readonly userPreferencesUrl: string = environment.abilisUrl + '/sys/user/cti_pref_get.json';
	private readonly setUserPreferencesUrl: string = environment.abilisUrl + '/sys/user/cti_pref_set.json';
	private readonly abilisphoneUserNumbersUrl: string = environment.abilisUrl + '/sys/user/ctivo_numb_get.json';

	private readonly adminPreferencesUrl: string = environment.abilisUrl + '/sys/admin/users/cti_pref_get.json';
	private readonly setAdminPreferencesUrl: string = environment.abilisUrl + '/sys/admin/users/cti_pref_set.json';
	private readonly abilisphoneAdminNumbersUrl: string = environment.abilisUrl + '/sys/admin/users/ctivo_numb_get.json';

	constructor(
		private router: Router,
		private http: HttpClient,
		private mainUtilService: MainUtilService,
		private virtualOfficeHttpService: VirtualOfficeHttpService,
		private preferencesFormValidatorService: PreferencesFormValidatorService
	) { }

	// HTTP

	fetchUserPreferences$(username: string, hasAdminPrivilege = false): Observable<IPreferencesStore> {
		return this.http.get<{ Response: IPreferencesUserResponse }>(this.getFetchUserPreferenceURL(username, hasAdminPrivilege))
			.pipe(
				map(response => {
					const { rsp, Uuid } = response.Response;
					const haveError = rsp !== undefined;

					if (this.router.url.startsWith(`/${RoutesEnum.ADMINISTRATION}`)) {
						BasicUserPreferencesHttpService.setUuid(Uuid);
					}

					return { ...response.Response, haveError };
				}),
				customOperators.retryFromError$(1000)
			);
	}

	setUserPreferences$(
		hasAdminPrivilege: boolean, preferencesPayload: IPreferencesSavePayload
	): Observable<IUserPreferencesActionResponse | null> {
		const ctiPreferencesUrl = hasAdminPrivilege ? this.setAdminPreferencesUrl : this.setUserPreferencesUrl;

		return this.http.post<IUserPreferencesActionResponse>(ctiPreferencesUrl, preferencesPayload)
			.pipe(
				tap(response => this.handleSaveOperation(response, true, 'Successfully saved user preferences')),
				catchError(() => {
					this.mainUtilService.showToastrMessage('Error during saving preferences. Please try again', ToastrType.ERROR);

					return of(null);
				})
			);
	}

	fetchAbilisphoneNumbers$(username: string): Observable<IPreferencesAbilisphoneNumberRecordItem[]> {
		return this.http.get<IPreferencesAbilisphoneNumbersResponse>(this.getUserAbilisphoneNumberUrl(username))
			.pipe(
				map(response => {
					const { CtivoNumbers, rsp, Uuid, message } = response.Response;

					if (!!rsp || !!message) { return []; }

					this.virtualOfficeHttpService.setUuid(Uuid);
					const matchingUserNumberSet = CtivoNumbers.find(ctivoNumberItem => ctivoNumberItem.user === username);

					return matchingUserNumberSet?.numbers || [];
				}),
				customOperators.retryFromError$(1000)
			);
	}

	formatUserNumbers$(type: PreferencesAbilisphoneSide, numbers: IPreferencesAbilisphoneNumberRecordItem[]): Observable<ISelectData[]> {
		const usersNumber = numbers.reduce((accumulator, item) => {
			const { num, side, current } = item;

			if (side !== type) { return accumulator; }

			const uiValue = this.translateAbilisphoneNumberValue(num.toLowerCase());

			return [...accumulator, { uiValue, value: num, current }];
		}, [] as ISelectData[]);

		return of(usersNumber.length ? usersNumber : []);
	}

	// LOGIC

	translateAbilisphoneNumberValue(numberToPresent: string): string {
		return numberToPresent === 'abilisphone' || numberToPresent === 'abilisphone *' ? 'PHONE.PREF.ADV.noSimUsed' : numberToPresent;
	}

	getUserAbilisphoneNumberUrl(username: string): string {
		const isAdminPage = this.router.url.endsWith(RoutesEnum.USERS_MANAGEMENT + ')');

		return isAdminPage ? this.abilisphoneAdminNumbersUrl + `?user=${username}` : this.abilisphoneUserNumbersUrl;
	}

	getFetchUserPreferenceURL(username: string, hasAdminPrivilege: boolean): string {
		return this.getCtiPreferencesUrl(hasAdminPrivilege) + `?user=${username}`;
	}

	handleSaveOperation(response: IUserPreferencesActionResponse, resetFormValidity: boolean, successfullText?: string): void {
		const { rsp, errors } = response.Response;
		const responseText = rsp || '';
		const isCommandExecuted = responseText.toLowerCase().includes('command executed');

		if (isCommandExecuted && resetFormValidity) {
			this.preferencesFormValidatorService.resetFormValidityState();
		}

		if (isCommandExecuted && successfullText) {
			this.mainUtilService.showToastrMessage(successfullText, ToastrType.SUCCESS);
		}

		if (!isCommandExecuted) {
			this.mainUtilService.showToastrMessage(responseText, ToastrType.ERROR, errors || '');
		}
	}

	private getCtiPreferencesUrl(hasAdminPrivilege: boolean): string {
		return hasAdminPrivilege ? this.adminPreferencesUrl : this.userPreferencesUrl;
	}
}
