import { Injectable } from '@angular/core';
import { IAddressBookContact } from './../ts/models/address-book-contact.model';
import { AddressBookFieldKey } from './../ts/enums/address-book-field-key.enum';
import { AddressBookContactUtilService } from './address-book-contact-util.service';
import { AddressBookContactType } from './../ts/enums/address-book-contact-type.enum';
import { IAddressBookContactCsv } from './../ts/models/address-book-contact-csv.model';
import { IAddressBookFetchResponse } from './../ts/models/address-book-fetch-response.model';

@Injectable({
	providedIn: 'root'
})
export class AddressBookContactArrayService {

	constructor(
		private readonly addressBookContactUtilService: AddressBookContactUtilService
	) { }

	/**
	 * Returns modified contacts by "checkboxState" property
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 * @param {boolean} isSelected If contact selection is enabled/disabled
	 */
	public static setAllContactsCheckedState(contacts: IAddressBookContact[], isSelected: boolean): IAddressBookContact[] {
		return contacts.map(contact => ({ ...contact, checkboxState: isSelected }));
	}

	/**
	 * Returns only selected contacts
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 */
	public static getSelectedContacts(contacts: IAddressBookContact[]): IAddressBookContact[] {
		return contacts.filter(contact => contact.checkboxState && !contact?.rowDisabled);
	}

	/**
	 * Returns true if all contacts are selected
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 */
	public static areAllContactsSelected(contacts: IAddressBookContact[]): boolean {
		let atLeastOne = false;
		return !!contacts.length && contacts.every(contact => {
			const tempRes = (contact.checkboxState && !contact?.rowDisabled) || !contact.isInView;
			atLeastOne = atLeastOne || contact.isInView;
			return tempRes;
		}) && atLeastOne;
	}

	/**
	 * Returns true/false if export button should be enabled
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 */
	public static isExportEnabled(contacts: IAddressBookContact[]): boolean {
		return contacts.some(contact => {
			const { checkboxState, rowDisabled, type } = contact;

			return checkboxState && !rowDisabled && !AddressBookContactUtilService.isPublicContact(type);
		});
	}

	/**
	 * Modifies Address Book contacts with defined properties
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 * @param {IAddressBookContact[]} existingContacts Already existing Address Book contacts
	 */
	public formatAddressBookContacts(contacts: IAddressBookContact[], existingContacts: IAddressBookContact[]): IAddressBookContact[] {
		return contacts.map(contact => {
			const { type } = contact;

			const canModifyContact = false;
			const id = this.generateContactId(contact);
			const rowDisabled = this.isRowDisabled(existingContacts, contact);
			const checkboxState = this.isContactChecked(existingContacts, contact);
			const contactTypeIcon = AddressBookContactUtilService.getContactTypeIcon(type);
			const updatedType = this.addressBookContactUtilService.translateContactTypeToCustom(type);

			return { ...contact, id, updatedType, canModifyContact, checkboxState, contactTypeIcon, rowDisabled };
		});
	}

	/**
	 * Returns contacts which will be used for checking unique name/number
	 * @param {IAddressBookFetchResponse} response Address Book Fetch response
	 * @param {IAddressBookContact | null} selectedContact Address Book selected contact
	 */
	public formatContactsForMatch(response: IAddressBookFetchResponse, selectedContact: IAddressBookContact | null): IAddressBookContact[] {
		const contacts = this.getContactsToUse(response);

		if (!selectedContact) { return contacts.slice(); }

		const { name: passedName, type: passedType } = selectedContact;

		return contacts.filter(contact => {
			const { name, type } = contact;
			const nameMatching = this.isValueMatching(name, passedName);

			return !nameMatching || (nameMatching && !this.isValueMatching(type, passedType));
		});
	}

	/**
	 * Selects only private contacts and prepare them for CSV
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 */
	public formatContactsForCsv(contacts: IAddressBookContact[]): IAddressBookContactCsv[] {
		return contacts.reduce((accumulator, contact) => {
			const { type } = contact;

			if (AddressBookContactUtilService.isPublicContact(type)) { return accumulator; }

			const { name, od, num, email, tag } = contact;

			return [...accumulator, { name, od, num, email, tag }];
		}, [] as IAddressBookContactCsv[]);
	}

	/**
	 * Adds "canModifyContact" property to each contact object
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 * @param {boolean} pubprotected Are public contacts protected
	 */
	public addCanModifyToContacts(contacts: IAddressBookContact[], pubprotected: boolean): IAddressBookContact[] {
		return contacts.map(contact => {
			const { type } = contact;
			const isPublicContact = AddressBookContactUtilService.isPublicContact(type);
			const canModifyContact = AddressBookContactUtilService.canModifyContact(pubprotected, !isPublicContact);

			return { ...contact, canModifyContact };
		});
	}

	/**
	 * Returns contact by name and number
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 * @param {string} contactNumber Contact Number to match
	 * @param {string} contactName Contact Name to match
	 */
	public getContactBySameNumberName(
		contacts: IAddressBookContact[], contactNumber: string, contactName: string
	): IAddressBookContact | null {
		return contacts.find(contact => {
			const { num, name } = contact;

			return this.isValueMatching(num, contactNumber) && !this.isValueMatching(name, contactName);
		}) || null;
	}

	/**
	 * Returns last selected contact out of all contacts
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 */
	public getLastSelectedContact(contacts: IAddressBookContact[]): IAddressBookContact | null {
		return contacts
			.slice()
			.reverse()
			.find(contact => contact.checkboxState && !contact?.rowDisabled) || null;
	}

	/**
	 * Returns contact by value and key
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 * @param {string | undefined} value Value which should match provided key
	 * @param {'num' | 'name'} key Address Book contact key to match
	 */
	public getContactByValue(contacts: IAddressBookContact[], value: string | undefined, key: 'num' | 'name'): IAddressBookContact | null {
		if (value === undefined) { return null; }

		return contacts.find(contact => {
			const lowercaseValue = value.toLowerCase();
			const lowercaseContactValue = contact[key].toString().toLowerCase();

			return this.isValueMatching(lowercaseContactValue, lowercaseValue);
		}) || null;
	}

	/**
	 * Returns contact by provided name and type
	 * @param {IAddressBookContact[]} contacts Address Book contacts
	 * @param {string} name Address Book name to match
	 * @param {AddressBookContactType} type Address Book contact type to match
	 */
	public getContactByNameType(contacts: IAddressBookContact[], name: string, type: AddressBookContactType): IAddressBookContact | null {
		return contacts.find(contact => this.isValueMatching(contact.name, name) && this.isValueMatching(contact.type, type)) || null;
	}

	/**
	 * Formats header keys into provided ones
	 * @param {AddressBookFieldKey[]} headers File keys which will be written in the file header
	 */
	public formatCsvHeader(headers: AddressBookFieldKey[]): string[] {
		return headers.map(item => {
			switch (item) {
			case AddressBookFieldKey.OD:
				return 'PREFIX';
			case AddressBookFieldKey.NUM:
				return 'NUMBER';
			default:
				return item.toUpperCase();
			}
		});
	}

	/**
	 * Converts provided contacts (alongside with file header keys) into CSV
	 * @param {IAddressBookContactCsv[]} contacts Address Book contacts to convert
	 * @param {AddressBookFieldKey[]} headers File keys which will be written in the file header
	 */
	public formatCsvData(contacts: IAddressBookContactCsv[], headers: AddressBookFieldKey[]) {
		return contacts.map(contact => headers
			.map(fieldName => JSON.stringify(contact[fieldName]))
			.join(',')
		);
	}

	/**
	 * Generic function which checks if value is equal to provided value
	 * @param {string} value Provided value
	 * @param {string} valueToCompare Value to compare with first provided value
	 */
	private isValueMatching(value: string, valueToCompare: string): boolean {
		return value === valueToCompare;
	}

	/**
	 * Returns what contacts to use, based on the response
	 * @param {IAddressBookFetchResponse} response Address Book Fetch response
	 */
	private getContactsToUse(response: IAddressBookFetchResponse): IAddressBookContact[] {
		return response.Response.Addressbook || [];
	}

	/**
	 * Returns true, if same contact was already checked before, when new fetch event occurs
	 * @param {IAddressBookContact[]} existingContacts Already existing Address Book contacts
	 * @param {IAddressBookContact} contact New Address Book contact
	 */
	private isContactChecked(existingContacts: IAddressBookContact[], contact: IAddressBookContact): boolean {
		const matchingContact = existingContacts.find(existingContact => existingContact.name === contact.name);

		if (!matchingContact) { return false; }

		const { checkboxState, rowDisabled } = matchingContact;

		return (checkboxState && rowDisabled) || false;
	}

	/**
	 * Returns true, if selected row should be disabled
	 * @param {IAddressBookContact[]} existingContacts Already existing Address Book contacts
	 * @param {IAddressBookContact} contact New Address Book contact
	 */
	private isRowDisabled(existingContacts: IAddressBookContact[], contact: IAddressBookContact): boolean {
		const matchingContact = existingContacts.find(existingContact => existingContact.name === contact.name);

		return matchingContact?.hasOwnProperty('rowDisabled') ? !!matchingContact?.rowDisabled : false;
	}

	/**
	 * Generates Address Book contact id
	 * @param {IAddressBookContact} contact Address Book contact
	 */
	private generateContactId(contact: IAddressBookContact): string {
		const { name, type } = contact;

		return `${name}-${type}`;
	}
}
