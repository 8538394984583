import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import { Observable, of, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { AutomationCell } from '../../ts/enums/automation-cell.enum';
import { MainUtilService } from '../../../../services/main-util.service';
import { AutomationProgramEditService } from '../automation-program-edit.service';
import { AutomationProgramDevicesService } from '../devices/automation-program-devices.service';
import { AutomationDeviceType } from '../../../automation/ts/enums/automation-device-type.enum';
import { IAutomationProgramEditLineDevice } from '../../ts/models/automation-program-edit-line-device.model';
import { AutomationProgramEditFunctonList } from '../../ts/enums/automation-program-edit-function-list.enum';
import { AutomationProgramProjectTimeout } from '../../ts/enums/automation-program-edit-project-timeout.enum';
import { AutomationProgramEditHttpTransitionService } from './automation-program-edit-http-transition.service';
import {
	AutomationProgramSpreadsheetFunctionService
} from '../spreadsheet/input-validation/automation-program-spreadsheet-function.service';

@Injectable({
	providedIn: 'root'
})
export class AutomatonProgramEditTransitionService {

	private readonly inputCell = AutomationCell.INPUT;
	private readonly outputCell = AutomationCell.OUTPUT;

	public static transitionValuesBuffer = new Map();
	public static historicalValuesBuffer = new Map();

	private readonly dateFormat = 'dd/MM/yyyy HH:mm:ss';

	constructor(
		private authService: AuthService,
		private automationProgramEditService: AutomationProgramEditService,
		private automationTransitionService: AutomationProgramEditHttpTransitionService,
		private automationSpreadsheetFnService: AutomationProgramSpreadsheetFunctionService
	) { }

	checkForHistoricalDeviceValues(
		lineDevices: IAutomationProgramEditLineDevice[], projectTimeoutUnit: AutomationProgramProjectTimeout
	): Observable<IAutomationProgramEditLineDevice[]> {
		const outputDevices = AutomationProgramDevicesService.filterDevicesByType(lineDevices, AutomationDeviceType.OUTPUT);
		const expressions = this.filterExpressions(outputDevices);

		if (expressions.length) {
			const historyFunctionArguments = this.pluckHistoryFunctionParenthesisData(expressions, lineDevices);
			const transitionFunctionArguments = this.pluckTransitionFunctionParenthesisData(expressions, lineDevices);

			const transitionParams = this.formatTransitionHistoricalParenthesisDataToHttp(
				lineDevices, transitionFunctionArguments, projectTimeoutUnit, true
			);
			const historyParams = this.formatTransitionHistoricalParenthesisDataToHttp(
				lineDevices, historyFunctionArguments, projectTimeoutUnit, false
			);

			const transitionHttpRequests$ = this.automationTransitionService.formatHistoricalHttpParamsToHttpRequest$(
				transitionParams, lineDevices, true
			);
			const historyHttpRequests$ = this.automationTransitionService.formatHistoricalHttpParamsToHttpRequest$(
				historyParams, lineDevices, false
			);

			return zip(...transitionHttpRequests$, ...historyHttpRequests$)
				.pipe(
					map(responses => {
						const transitionsLength = transitionHttpRequests$.length;
						const transitionsResponse = responses.slice(0, transitionsLength);
						const historyResponse = responses.slice(transitionsLength, transitionsLength + historyHttpRequests$.length);

						this.setTransitionHistoricalValuesBuffer(transitionParams, transitionsResponse, false);
						this.setTransitionHistoricalValuesBuffer(historyParams, historyResponse, true);

						return lineDevices;
					})
				);
		}

		return of(lineDevices);
	}

	setTransitionHistoricalValuesBuffer(httpParams: string[], responses: Array<boolean | number>, historicalValue: boolean): void {
		httpParams.forEach((httpParam, index) => {
			const [cell, timespanInSeconds] = httpParam.split('-');
			const key = `${cell}-${timespanInSeconds}`.toLowerCase();

			if (historicalValue) {
				AutomatonProgramEditTransitionService.historicalValuesBuffer.set(key, responses[index]);

				return;
			}

			AutomatonProgramEditTransitionService.transitionValuesBuffer.set(key, responses[index]);
		});
	}

	pluckTransitionFunctionParenthesisData(expressions: string[], lineDevices: IAutomationProgramEditLineDevice[]): string[] {
		return expressions.reduce((accumulator, expression) => {
			const matchingData = expression.match(/trans\(([^()]+|[^(]+\([^)]*\)[^()]*)\)/g);

			if (matchingData) {
				const parenthesisData = matchingData.map(item => {
					const [cell, time] = MainUtilService.splitAtFirstOccurence(this.getDataBetweenParenthesis(item), ',');
					const expressionValue = this.automationSpreadsheetFnService.parseInputToValue(time, lineDevices, null, undefined);

					return `${cell},${expressionValue.value}`;
				});

				return [...accumulator, ...parenthesisData];
			}

			return accumulator;
		}, [] as string[]);
	}

	pluckHistoryFunctionParenthesisData(expressions: string[], lineDevices: IAutomationProgramEditLineDevice[]): string[] {
		// eslint-disable-next-line max-len
		const regExp = new RegExp(`[${this.inputCell}-${this.outputCell}${this.inputCell.toUpperCase()}-${this.outputCell.toUpperCase()}]\\d+\\(([^()]+|[^(]+\\([^)]*\\)[^()]*)\\)`, 'g');

		return expressions.reduce((accumulator, expression) => {
			const matchingData = expression.match(regExp);

			if (matchingData) {
				const parenthesisData = matchingData.map(item => {
					const cell = item.slice(0, item.indexOf('('));
					const dataBetweenParenthesis = this.getDataBetweenParenthesis(item);
					const argumentsValue = this.automationSpreadsheetFnService.parseInputToValue(
						dataBetweenParenthesis, lineDevices, null, undefined
					);

					return `${cell},${argumentsValue.value}`;
				});

				return [...accumulator, ...parenthesisData];
			}

			return accumulator;
		}, [] as string[]);
	}

	formatTransitionHistoricalParenthesisDataToHttp(
		lineDevices: IAutomationProgramEditLineDevice[], transitionFunctionArguments: string[],
		projectTimeoutUnit: AutomationProgramProjectTimeout, isTransition: boolean
	): string[] {
		return transitionFunctionArguments.reduce((accumulator, transitionFunctionArgument) => {
			const [cell, time] = transitionFunctionArgument.split(',');

			const trimmedCell = cell.trim();
			const trimmedTime = +time.trim();
			const lineDevice = AutomationProgramDevicesService.getReferencedLineDevice(trimmedCell, lineDevices);

			if (lineDevice) {
				const { device, deviceNumber, lineDeviceName, lineDeviceNumber } = lineDevice;
				const lineName = device + deviceNumber + lineDeviceName + lineDeviceNumber;
				const datetime = this.getDatetime(trimmedTime, projectTimeoutUnit, isTransition);
				const id = `${trimmedCell}-${trimmedTime}-${lineName}-`;
				const finalInfo = `${id}${datetime}`;

				if (!accumulator.some(item => item.startsWith(id))) {
					return [...accumulator, finalInfo];
				}
			}

			return accumulator;
		}, [] as string[]);
	}

	getDatetime(trimmedTime: number, projectTimeoutUnit: AutomationProgramProjectTimeout, isTransition: boolean): string {
		const milliseconds = this.automationProgramEditService.getProjectExecutionInterval(trimmedTime, projectTimeoutUnit);
		const currentDateObject = this.getCurrentDateObject();
		const fromObject = currentDateObject.minus({ milliseconds });
		const fromObjectDatetime = fromObject.toFormat(this.dateFormat);

		if (isTransition) {
			return this.getTransitionDatetime(fromObjectDatetime, currentDateObject);
		}

		const formHistoricalDatetime = fromObject.minus({ seconds: 1 }).toFormat(this.dateFormat);

		return this.getHistoricalDatetime(formHistoricalDatetime, fromObject);
	}

	getHistoricalDatetime(formHistoricalDatetime: string, fromObject: DateTime): string {
		const fromFormatted = this.formatDateObjectToString(formHistoricalDatetime);
		const toFormatted = this.formatDateObjectToString(fromObject.toFormat(this.dateFormat));

		return `${fromFormatted}_${toFormatted}`;
	}

	getTransitionDatetime(from: string, currentDateObject: DateTime): string {
		const fromDatetime = this.formatDateObjectToString(from);
		const toDatetime = this.formatDateObjectToString(currentDateObject.plus({ minute: 5 }).toFormat(this.dateFormat));

		return `${fromDatetime}_${toDatetime}`;
	}

	getCurrentDateObject() {
		return MainUtilService.getCurrentLuxonDatetimeObject(this.authService.getAbilisTimezone());
	}

	formatDateObjectToString(datetime: string): string {
		const [dateInfo, timeInfo] = datetime.split(' ');

		const [day, month, year] = dateInfo.split('/');
		const [hours, minutes, seconds] = timeInfo.split(':');

		return `${year}${month}${day}T${hours}${minutes}${seconds}`;
	}

	filterExpressions(outputDevices: IAutomationProgramEditLineDevice[]): string[] {
		return outputDevices.reduce((accumulator, outputDevice) => {
			const expression = outputDevice.expression;

			if (expression !== '') {
				const clearedExpression = this.clearExpressionFromSpaces(`${expression}`).toLowerCase();
				// eslint-disable-next-line max-len
				const regExp = `[${this.inputCell}-${this.outputCell}${this.inputCell.toUpperCase()}-${this.outputCell.toUpperCase()}]\\d+\\(`;
				const matchingHistoricalCell = new RegExp(regExp).test(`${clearedExpression}`);
				const matchingTransitionFunction = clearedExpression.includes(AutomationProgramEditFunctonList.TRANS);

				if (matchingHistoricalCell || matchingTransitionFunction) {
					return [...accumulator, clearedExpression as string];
				}
			}

			return accumulator;
		}, [] as string[]);
	}

	clearExpressionFromSpaces(expression: string): string {
		if (!expression) {
			return '';
		}

		return expression
			.split('')
			.filter(item => item !== ' ')
			.join('');
	}

	getDataBetweenParenthesis(item: string): string {
		return item.slice(item.indexOf('(') + 1, item.lastIndexOf(')'));
	}

	clearHistoricalValuesBuffer(): void {
		AutomatonProgramEditTransitionService.transitionValuesBuffer.clear();
		AutomatonProgramEditTransitionService.historicalValuesBuffer.clear();
	}
}
