import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../../root-reducers';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import { UserLevel } from '../../auth/ts/enums/user-level.enum';
import { LOGIN_SENT_BY } from '../ts/models/login-sent-by.model';
import { ISectionPermission } from '../ts/models/section-permission.model';
import { ILoginVerification } from '../ts/models/login-verification-data.model';
import { PermissionsSectionKey } from './../ts/enums/permissions-section-key.enum';
import { FeaturePermissionsName } from '../../core/ts/enums/feature-permission-name.enum';
import { IFeaturePermissionsItem } from '../../core/ts/models/feature-permissions-item.model';
import * as authSelectors from '../ngrx/auth.selectors';
import * as authActions from '../ngrx/auth.actions';

@Injectable({
	providedIn: 'root'
})
export class AuthNgrxService {

	constructor(
		private store: Store<IAppState>
	) { }

	clearLoginStore(): void {
		this.store.dispatch(authActions.clearLoginStore());
	}

	public resetVerificationCode(): void {
		this.store.dispatch(authActions.reset2FaVerificationCode());
	}

	public loginWithVerificationCode(verificationCode: string): void {
		const payload = { verificationCode };

		this.store.dispatch(authActions.loginWith2FaVerificationCode({ payload }));
	}

	public setVerificationData(verification: ILoginVerification | null): void {
		const payload = { verification };

		this.store.dispatch(authActions.set2FaVerificationData({ payload }));
	}

	dispatchLoginAction(): void {
		this.store.dispatch(authActions.loginAction());
	}

	refreshUserPermissions(): void {
		this.store.dispatch(authActions.refreshUserPermissions());
	}

	updateUserLevel(userLevel: UserLevel): void {
		const payload = { userLevel };

		this.store.dispatch(authActions.updateUserLevel({ payload }));
	}

	logoutNonAnonymousAction(): void {
		this.store.dispatch(authActions.logoutNonAnonymousAction());
	}

	dispatchLogoutAction(): void {
		this.store.dispatch(authActions.logoutAction());
	}

	getUserLevel$(): Observable<UserLevel> {
		return this.store.pipe(select(authSelectors.selectUserLevel));
	}

	haveSpecificUserLevels$(userLevelsToMatch: UserLevel[]): Observable<boolean> {
		return this.getUserLevel$()
			.pipe(
				map(userLevel => userLevelsToMatch.includes(userLevel))
			);
	}

	haveAdminUserLevel$(): Observable<boolean> {
		return this.getUserLevel$()
			.pipe(
				map(userLevel => [UserLevel.MANAGER, UserLevel.ADMINISTRATOR, UserLevel.SUPER].includes(userLevel))
			);
	}

	haveAdvancedUserLevel$(): Observable<boolean> {
		return this.getUserLevel$()
			.pipe(
				map(userLevel => UserLevel.ADVANCED === userLevel)
			);
	}

	selectLoggedUserGroup$(): Observable<string> {
		return this.store.pipe(select(authSelectors.selectLoggedUserGroup));
	}

	selectMaxAutomationMapsNumber$(): Observable<number> {
		return this.store.pipe(select(authSelectors.selectMaxAutomationMapsNumber));
	}

	selectUbrMode$(): Observable<boolean> {
		return this.store.pipe(select(authSelectors.selectUbrMode));
	}

	selectSectionPermissions$(): Observable<ISectionPermission> {
		return this.store.pipe(select(authSelectors.selectSectionPermissions)) as Observable<ISectionPermission>;
	}

	selecteEnhancedPermissions$(): Observable<ISectionPermission> {
		return this.selectSectionPermissions$()
			.pipe(
				switchMap(permissions => this.haveAdminUserLevel$()
					.pipe(
						map(haveAdminUserLevel => ({ ...permissions, [PermissionsSectionKey.ADMIN]: haveAdminUserLevel }))
					))
			);
	}

	selectUsername$(): Observable<string> {
		return this.store.pipe(select(authSelectors.selectUsername));
	}

	public getVerificationCodeText$(): Observable<string> {
		return this.selectAllVerificationData$()
			.pipe(
				filter((data): data is ILoginVerification => !!data),
				map(data => data.sent_by.toLowerCase() === LOGIN_SENT_BY.MAIL ? 'LOGIN.verificationCodeMail' : 'LOGIN.verificationCodeSms')
			);
	}

	selectUserPermission$(permissionName: FeaturePermissionsName): Observable<IFeaturePermissionsItem | null> {
		return this.store.pipe(select(authSelectors.selectFromPermissions(permissionName)))
			.pipe(
				delay(0)
			);
	}

	selectAllVerificationData$(): Observable<ILoginVerification | null> {
		return this.store.pipe(select(authSelectors.selectVerificationData));
	}

	selectAllPermissions$(): Observable<IFeaturePermissionsItem[]> {
		return this.store.pipe(select(authSelectors.selectPermissions));
	}

	selectAllowedVsCameras$(): Observable<string[]> {
		return this.store.pipe(select(authSelectors.selectAllowedVsCamera))
			.pipe(
				map(availableCameras => {
					if (availableCameras === '#') { return []; }

					return availableCameras
						.split(',')
						.map(camera => {
							const [device, port] = camera.split('.');

							return `${device.split('-')[1]}-${port.split('-')[1]}`;
						});
				})
			);
	}

	selectAllowedAutomationMap$(): Observable<string> {
		return this.store.pipe(select(authSelectors.selectAllowedAutomationMap));
	}

	selectFromAuthStore<T>(selectorName: string): Observable<T> {
		return this.store.pipe(select((authSelectors as any)[selectorName]));
	}

	isUserLoggedIn$(): Observable<boolean> {
		return this.store.pipe(select((authSelectors).selectLoggedInState));
	}
}
