<ng-container *ngIf="translateObject$ | async as translateObject">
	<div class="modal-header">
		<h5 class="modal-title pull-left">{{translateObject.newVersion}}</h5>
	</div>
	<div class="modal-body">
		<p class="title">
			{{translateObject.switching}} <b>{{currentVersion}}</b> {{translateObject.to}}
			<b>{{newVersion}}</b>
		</p>

		<p class="title">{{translateObject.acceptState}}</p>

		<div class="modal__buttons">
			<button type="submit" class="btn btn-sm btn-primary" (click)="updateApplication(true)">
				{{translateObject.accept}}
			</button>

			<button type="button" class="btn btn-sm btn-primary" (click)="updateApplication(false)">
				{{translateObject.reject}}
			</button>
		</div>
	</div>
</ng-container>
