import { NgIf } from '@angular/common';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { IPopOver } from 'src/app/ts/models/popover.model';

@Component({
	selector: 'app-info-bubble',
	styleUrls: ['./styles/info-bubble.scss'],
	template: `

<!-- Popover info bubble -->
<ng-container *ngIf="isPopover">
	<div class="info-bubble-container">
		<button
		type="button"
		class="btn  icon-container"
		[popover]="popTemplate"
		[popoverTitle]="popoverConfiguration.title"
		[placement]="popoverConfiguration.placement"
		[adaptivePosition]="false"
		triggers="click"
		[outsideClick]="true"
		></button>
	</div>
</ng-container>

	<ng-template #popTemplate let-message="message">
	<ng-content></ng-content>
	<ng-container *ngIf="popoverConfiguration.content">
		<p>
			{{popoverConfiguration.content}}
		</p>
	</ng-container>
</ng-template>



<!-- MODAL info bubble (default) -->
	<ng-container *ngIf="!isPopover">
		<div class="info-bubble-container">
			<button (click)="showInfoBubble()" class="icon-container"></button>
		</div>
		<ng-template #infoBubbleModal>
			<ng-container *ngIf="ModalHeader">
				<div class="modal-header">
					<h5 class="modal-title pull-left">
				{{ModalHeader}}
					</h5>
				</div>
		</ng-container>
			<div class="modal-body">
				<ng-container *ngTemplateOutlet="customTemplate"></ng-container>
			</div> <!-- Chiusura corretta del tag <div class="modal-body"> -->
		</ng-template>
	</ng-container>
  `,
})

export class InfoBubbleComponent {

	constructor(
		private modalService: BsModalService,
	) {
	}
	@Input() isPopover = false;
	@Input() ModalHeader: string;
	@Input() marginLeft: any;
	@Input() customTemplate: TemplateRef<any>;
	@Input() position: 'left' | 'right';
	@Input() cardWidth = '400px';
	@Input() overlayHeight = '100%';
	@Input() popoverConfiguration: IPopOver;

	@ViewChild('infoBubbleModal') infoBubbleModal: TemplateRef<any>;

	showInfo = false;

	onChange: any = () => { };
	onTouched: any = () => { };

	protected showInfoBubble() {
		this.modalService.show(this.infoBubbleModal);
	}

}
