import { FormBuilder } from '@angular/forms';

// toastr variables
export const infoToastrDuration = 6000;
export const errorToastrDuration = 6000;
export const successToastrDuration = 3000;
// version update check time - default 30 minutes
export const versionFrequency = 1000 * 60 * 30;

// default <app-action-row> object
export const DEFAULT_ACTION_ROW_ICON_CONFIG = {
	showAddIcon: true,
	showEditIcon: false,
	showDeleteIcon: true,
	showAdvancedIcon: true,
	showMoveAllIcon: false,
	showDuplicateIcon: false,
	showAssignmentIcon: false
};

// default <app-generic-table> object
export const DEFAULT_GENERIC_TABLE_CONFIG = {
	tableHeight: null,
	showEveryRow: false,
	tableHeaderColor: '',
	specificRowStyles: null,
	enableRowClickEvent: null,
	useDynamicSelectbox: false,
	showRowOnSelectedIndex: true
};

const formBuilder = new FormBuilder();
// default <app-generic-table>
export const DEFAULT_GENERIC_TABLE_ROW_CONFIG = {
	tableRows: [],
	tableHeaders: [],
	headerFormArray: formBuilder.array([]),
	genericFormArray: formBuilder.array([])
};
