import { Injectable } from '@angular/core';
import { AutomationCell } from '../../../ts/enums/automation-cell.enum';
import { specialAlertFnValue } from '../../../utils/available-functions-operators';
import { AutomationProgramDevicesService } from '../../devices/automation-program-devices.service';
import { AutomationDeviceType } from '../../../../automation/ts/enums/automation-device-type.enum';
import { AutomationProgramSpreadsheetUtilService } from '../automation-program-spreadsheet-util.service';
import { IAutomationProgramEditFunction } from '../../../ts/models/automation-program-edit-function.model';
import { AutomationProgramSpreadsheetFunctionListService } from '../automation-program-function-list.service';
import { AutomationProgramEditFunctonList } from '../../../ts/enums/automation-program-edit-function-list.enum';
import { IAutomationProgramEditLineDevice } from '../../../ts/models/automation-program-edit-line-device.model';
import { IAutomationProgramEditResultResponse } from '../../../ts/models/automation-program-edit-result-response.modal';
import { AutomationProgramEditLineDeviceValue } from '../../../ts/types/automation-program-edit-line-device-value.type';
import { AutomationProgramSpreadsheetFunctionArgsService } from './automation-program-spreadsheet-function-args.service';
import { AutomationProgramSpreadsheetFunctionValidatorService } from './automation-program-spreadsheet-function-validator.service';

@Injectable({
	providedIn: 'root'
})
export class AutomationProgramSpreadsheetFunctionService extends AutomationProgramSpreadsheetUtilService {

	private readonly inputCell = AutomationCell.INPUT;
	private readonly outputCell = AutomationCell.OUTPUT;

	constructor(
		private automationProgramDevicesService: AutomationProgramDevicesService,
		private functionListService: AutomationProgramSpreadsheetFunctionListService,
		private automationTableFnArgsService: AutomationProgramSpreadsheetFunctionArgsService,
		private automationFnValidatorService: AutomationProgramSpreadsheetFunctionValidatorService
	) {
		super();
	}

	parseInputToValue(
		inputValue: string, selectedDevices: IAutomationProgramEditLineDevice[],
		fieldIndex: number | null, selectedOutputDevice: IAutomationProgramEditLineDevice | undefined
	): IAutomationProgramEditResultResponse {
		const inputValueEqualSign = `=${inputValue}`;

		// Empty string check
		if (inputValueEqualSign.trim() === '=') {
			return { haveError: false, value: '' };
		}

		// Expression with equal sign start check
		if (inputValue.startsWith('=')) {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.expressionStart}}' };
		}

		const tokenResult = this.automationFnValidatorService.getInputTokenCollections(inputValueEqualSign, selectedDevices, fieldIndex);
		const { haveError, value } = tokenResult;

		if (haveError) {
			return tokenResult;
		}

		const expressionValue = this.getExpressionValue(inputValue, value as string[], selectedDevices);

		if (expressionValue.haveError) {
			return expressionValue;
		}

		return this.checkForValidExpressionResult(expressionValue, selectedOutputDevice);
	}

	checkForValidExpressionResult(
		expressionValue: IAutomationProgramEditResultResponse, selectedOutputDevice?: IAutomationProgramEditLineDevice
	): IAutomationProgramEditResultResponse {
		if (!selectedOutputDevice) { return expressionValue; }

		const { port } = selectedOutputDevice;
		const isVout = port.toLowerCase().startsWith('v');

		if (isVout) { return expressionValue; }

		if (expressionValue.value === specialAlertFnValue) { return { ...expressionValue, value: '' }; }

		const isDigitalDevice = this.automationProgramDevicesService.isDigitalLineDevice(port);

		if (isDigitalDevice && typeof expressionValue.value !== 'boolean') {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.digitalOutput}}' };
		}

		if (!isDigitalDevice && typeof expressionValue.value !== 'number') {
			return { haveError: true, errorMessage: '{{AUTO.RULES.EDIT.ERRORS.analogOutput}}' };
		}

		return expressionValue;
	}

	getExpressionValue(
		inputValue: string, expression: string[], selectedDevices: IAutomationProgramEditLineDevice[]
	): IAutomationProgramEditResultResponse {
		let expressionCopy = expression.slice();
		const tokenExpressions = super.setToTokenExpressions(inputValue).filter(token => token !== '');

		while (expressionCopy.includes('(') && expressionCopy.includes(')')) {
			const lastOpenParenthesisIndex = super.getLastOpenParenthesisIndex(expressionCopy);
			const firstCloseParenthesisIndex = super.getFirstCloseParenthesisIndex(expressionCopy, lastOpenParenthesisIndex);
			const preParenthesisChar = expressionCopy[lastOpenParenthesisIndex - 1];

			const isFunction = super.isCharacterFunction(preParenthesisChar);
			const parenthesisData = super.getDataBetweenParentheis(expressionCopy, lastOpenParenthesisIndex, firstCloseParenthesisIndex);
			const tokenParenthesis = super.getDataBetweenParentheis(tokenExpressions, lastOpenParenthesisIndex, firstCloseParenthesisIndex);
			const expressionResult = this.getExpressionResult(
				isFunction, preParenthesisChar, parenthesisData, tokenParenthesis, selectedDevices
			);
			const { haveError, errorMessage, value } = expressionResult;

			if (!haveError) {
				const expressionValue = (value as AutomationProgramEditLineDeviceValue | string[]).toString();
				// replace function result value for string between parenthesis (with/without function name)
				// example sum(sum(1,5),4) =>> sum(6,4) =>> 10 - until it has no parenthesis and functions
				expressionCopy = this.replaceExpressionPart(
					expressionCopy, isFunction, expressionValue, lastOpenParenthesisIndex, firstCloseParenthesisIndex
				);

				continue;
			}

			return { haveError: true, errorMessage };
		}

		return this.automationTableFnArgsService.evaluateExpressionResult(expressionCopy.join(''));
	}

	replaceExpressionPart(
		expression: string[], isFunction: boolean, value: string,
		lastOpenParenthesis: number, firstCloseParenthesis: number
	): string[] {
		const firstIndex = this.getFirstReplaceIndex(isFunction, lastOpenParenthesis);
		const charsToReplace = this.getNumberOfCharactersToReplace(isFunction, firstCloseParenthesis, lastOpenParenthesis);
		const lastIndex = charsToReplace + firstIndex;

		return [...expression.slice(0, firstIndex), value, ...expression.slice(lastIndex, expression.length)];
	}

	getNumberOfCharactersToReplace(isFunction: boolean, firstCloseParenthesis: number, lastOpenParenthesis: number): number {
		if (isFunction) {
			return firstCloseParenthesis - lastOpenParenthesis + 1;
		}

		return firstCloseParenthesis - lastOpenParenthesis;
	}

	getFirstReplaceIndex(isFunction: boolean, lastOpenParenthesis: number): number {
		return isFunction ? lastOpenParenthesis - 1 : lastOpenParenthesis;
	}

	getExpressionResult(
		isFunction: boolean, functionNameOrOperator: string,
		parenthesisData: string, tokenParenthesis: string, selectedDevices: IAutomationProgramEditLineDevice[]
	): IAutomationProgramEditResultResponse {
		if (isFunction) {
			return this.getSingleFunctionResult(functionNameOrOperator, parenthesisData, tokenParenthesis, selectedDevices);
		}

		return this.automationTableFnArgsService.evaluateExpressionResult(parenthesisData);
	}

	getSingleFunctionResult(
		functionName: string, parenthesisData: string,
		tokenParenthesis: string, selectedDevices: IAutomationProgramEditLineDevice[]
	): IAutomationProgramEditResultResponse {
		const functionMatch = this.functionListService.getMatchingFunction(functionName) as IAutomationProgramEditFunction;
		const functionArgsResult = this.automationFnValidatorService.validateFunctionResult(functionName, parenthesisData, functionMatch);

		if (functionArgsResult?.haveError) {
			return functionArgsResult;
		}

		const functionArgumentsValidation = this.automationTableFnArgsService.getFunctionArgumentsValidation(parenthesisData);
		const functionArgumentsValues = this.automationTableFnArgsService.getFunctionArgumentsValue(functionArgumentsValidation);
		const value = this.getFunctionResult(functionName, tokenParenthesis, functionMatch, functionArgumentsValues, selectedDevices);
		const validatedFunctionValue = this.automationTableFnArgsService.validateFunctionResult(value, null);

		if (validatedFunctionValue?.haveError) {
			return validatedFunctionValue;
		}

		// if function returns string, add quotes
		// so that when comes to expression evaluation it doesn't make parsing
		if (typeof value === 'string') {
			return { haveError: false, value: `'${value}'` };
		}

		return { haveError: false, value };
	}

	getFunctionResult(
		functionName: string, tokenParenthesis: string, functionMatch: IAutomationProgramEditFunction,
		functionArgumentsValues: AutomationProgramEditLineDeviceValue[], selectedDevices: IAutomationProgramEditLineDevice[]
	): AutomationProgramEditLineDeviceValue {
		const { shortFunctionDescription } = functionMatch;

		if (shortFunctionDescription.startsWith('^')) {
			return functionMatch.functionExpression(...functionArgumentsValues, functionName, selectedDevices);
		}

		if (shortFunctionDescription === AutomationProgramEditFunctonList.IFNA) {
			const isSomeDeviceOffline = this.isSomeDeviceOfflineIfFn(tokenParenthesis, selectedDevices);

			return functionMatch.functionExpression(...functionArgumentsValues, isSomeDeviceOffline);
		}

		if (shortFunctionDescription !== AutomationProgramEditFunctonList.TRANS) {
			return functionMatch.functionExpression(...functionArgumentsValues);
		}

		return functionMatch.functionExpression(...functionArgumentsValues, tokenParenthesis);
	}

	getIfCondition(tokenParenthesis: string, commaIndex: number): string {
		let commaCount = 0;
		let thirdCommaIndex = tokenParenthesis.length - 1;

		for (let i = tokenParenthesis.length - 1; i >= 0; i--) {
			if (tokenParenthesis[i] === ',') {
				commaCount += 1;
			}

			if (commaCount === commaIndex) {
				thirdCommaIndex = i;
				break;
			}
		}

		return tokenParenthesis.slice(tokenParenthesis.indexOf('(') + 1, thirdCommaIndex);
	}

	isSomeDeviceOfflineIfFn(tokenParenthesis: string, selectedDevices: IAutomationProgramEditLineDevice[]): boolean {
		const ifCondition = this.getIfCondition(tokenParenthesis, 2);
		// eslint-disable-next-line max-len
		const regExp = new RegExp(`[${this.inputCell}-${this.outputCell}${this.inputCell.toUpperCase()}-${this.outputCell.toUpperCase()}]\\d+`, 'g');
		const matchingData = ifCondition.match(regExp);

		if (!matchingData) {
			return false;
		}

		const matchingCells = matchingData as string[];
		const inputDevices = AutomationProgramDevicesService.filterDevicesByType(selectedDevices, AutomationDeviceType.INPUT);
		const outputDevices = AutomationProgramDevicesService.filterDevicesByType(selectedDevices, AutomationDeviceType.OUTPUT);

		const inputDevicesOffline = this.isSomeDeviceOffline(inputDevices, this.filterCellsByType(matchingCells, this.inputCell));
		const outputDevicesOffline = this.isSomeDeviceOffline(outputDevices, this.filterCellsByType(matchingCells, this.outputCell));

		return inputDevicesOffline || outputDevicesOffline;
	}

	filterCellsByType(matchingCells: string[], cellLetter: string): string[] {
		return matchingCells.filter(cell => cell.charAt(0).toLowerCase() === cellLetter);
	}

	isSomeDeviceOffline(devices: IAutomationProgramEditLineDevice[], matchingCells: string[]): boolean {
		return matchingCells
			.map(cell => devices[+cell.substring(1) - 1].isOffline)
			.some(isOffline => isOffline);
	}
}
