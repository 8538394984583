import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { RioRvsNgrxService } from '../rio-rvs-ngrx.service';
import { RioRvsModalService } from '../rio-rvs-modal.service';
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { ToastrType } from '../../../../ts/enums/toastr-type.enum';
import { environment } from '../../../../../environments/environment';
import { RioRvsHttpLogicService } from './rio-rvs-http-logic.service';
import { map, catchError, tap, switchMap, take } from 'rxjs/operators';
import { MainUtilService } from '../../../../services/main-util.service';
import { RioRvsCookieKey } from '../../ts/enums/rio-rvs-cookie-key.enum';
import { IRioRvsResponse } from '../../ts/models/rio-rvs-response.model';
import { IRioRvsIconList } from '../../ts/models/rio-rvs-icon-list.model';
import { ResponseStatus } from '../../../../ts/enums/response-status.enum';
import { IRioRvsSavedIcon } from '../../ts/models/rio-rvs-saved-icon.model';
import { IRioRvsWioDevice } from '../../ts/models/rio-rvs-wio-device.model';
import { RioRvsWioDeviceType } from '../../ts/enums/rio-rvs-device-type.enum';
import { IActionResponse } from '../../../../ts/models/action-response.model';
import { RioRvsDiscoveryType } from '../../ts/enums/rio-rvs-discovery-type.enum';
import { RioRvsIohubDeviceType } from '../../ts/enums/rio-rvs-iohub-device-type.enum';
import { RioRvsModalLineDeviceType } from '../../ts/types/rio-rvs-modal-line-device.type';
import { IRioRvsSaveIconsResponse } from '../../ts/models/rio-rvs-save-icons-response.model';
import { RioRvsWioIohubDeviceClass } from '../../ts/enums/rio-rvs-wio-iohub-device-class.enum';
import { IRioRvsIohubDevicesResponse } from '../../ts/models/rio-rvs-iohub-devices-response.model';
import { AutomationGenericService } from '../../../automation/services/automation-generic.service';
import { GenericFormValue } from '../../../ui-components/generic-table/ts/types/generic-form-value.type';
import { IRioRvsMultipleEntriesResponse } from '../../ts/models/rio-rvs-save-multiple-entries-response.model';
import { IPortsConfigurationDeviceResponse } from '../../ts/models/ports-configuration-device-response.model';
import * as customOperators from '../../../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root'
})
export class RioRvsHttpService {

	private uuid: string;
	private sessionId: BehaviorSubject<string> = new BehaviorSubject('');

	private readonly setDeviceUrl: string = environment.abilisUrl + '/sys/admin/io/set.json';
	private readonly bindDeviceUrl: string = environment.abilisUrl + '/sys/admin/io/bind.json';
	private readonly resetDeviceUrl: string = environment.abilisUrl + '/sys/admin/io/reset.json';
	private readonly removeDeviceUrl: string = environment.abilisUrl + '/sys/admin/io/remove.json';
	private readonly unbindDeviceUrl: string = environment.abilisUrl + '/sys/admin/io/unbind.json';
	private readonly discoverDevicesUrl: string = environment.abilisUrl + '/sys/admin/io/get.json';
	private readonly setMultipleValuesUrl: string = environment.abilisUrl + '/sys/io/design/line/set.json';
	private readonly getDevicesStatusUrl: string = environment.abilisUrl + '/sys/iohub/all/GetSesStatus.json';
	private readonly getDeviceConfigurationUrl: string = environment.abilisUrl + '/sys/admin/io/getconf.json?res=';

	constructor(
		private http: HttpClient,
		private translate: TranslateService,
		private mainUtilService: MainUtilService,
		private rioRvsNgrxService: RioRvsNgrxService,
		private rioRvsModalService: RioRvsModalService,
		private rioRvsHttpLogicService: RioRvsHttpLogicService
	) { }

	fetchDeviceIconsList$(type: RioRvsWioDeviceType | RioRvsIohubDeviceType, resourceId: number | string): Observable<IRioRvsIconList> {
		const fetchUrl = environment.abilisUrl + `/sys/io/design/${type.toLowerCase()}${resourceId}.json?t=${new Date().getTime()}`;

		return this.http.get<IRioRvsIconList>(fetchUrl)
			.pipe(
				catchError(() => of({ response: { iconList: [] } }))
			);
	}

	eraseIconConfiguration$(resource: string): Observable<IRioRvsIconList> {
		const [deviceName, deviceNumber] = AutomationGenericService.splitAutomationItemByDash(resource);
		const saveIconsUrl = environment.abilisUrl + `/sys/io/design/${deviceName.toLowerCase()}${deviceNumber}/setjson.json`;

		return this.http.post<IRioRvsIconList>(saveIconsUrl, { response: { iconList: [] } })
			.pipe(
				customOperators.retryFromError$(1000)
			);
	}

	saveDeviceIconsList$(newIcon: IRioRvsSavedIcon, resource: string): Observable<IRioRvsSaveIconsResponse> {
		const [deviceName, deviceNumber] = AutomationGenericService.splitAutomationItemByDash(resource);
		const saveIconsUrl = environment.abilisUrl + `/sys/io/design/${deviceName.toLowerCase()}${deviceNumber}/setjson.json`;

		return this.fetchDeviceIconsList$(deviceName as RioRvsWioDeviceType, deviceNumber)
			.pipe(
				switchMap(iconsListResponse => {
					const { iconList } = iconsListResponse.response;

					if (!iconList.length) {
						return this.http.post<IRioRvsSaveIconsResponse>(saveIconsUrl, { response: { iconList: [newIcon] } });
					}

					const { id } = newIcon;
					const presentIconIds = iconList.map(iconListItem => iconListItem.id);

					const newIconsToAdd = !presentIconIds.includes(id) ? [newIcon] : [];
					const newIconList = iconList.map(iconListItem => iconListItem.id === id ? newIcon : iconListItem);
					const payload = { response: { iconList: [...newIconList, ...newIconsToAdd] } };

					return this.http.post<IRioRvsSaveIconsResponse>(saveIconsUrl, payload);
				}),
				customOperators.retryFromError$(1000)
			);
	}

	saveMultipleDeviceChanges$(
		iohubClass: RioRvsWioIohubDeviceClass, resource: string, isGainValueOnly: boolean,
		selectedRioRvsDevice: RioRvsModalLineDeviceType, formValues: GenericFormValue[]
	): Observable<IRioRvsMultipleEntriesResponse> {
		const changedDevice = this.rioRvsModalService.formatChangedDevice(
			iohubClass, resource, selectedRioRvsDevice, formValues, isGainValueOnly
		);

		return this.http.post<IRioRvsMultipleEntriesResponse>(this.setMultipleValuesUrl, [changedDevice])
			.pipe(
				tap(response => {
					const { message } = response.Response;

					if (message.toLowerCase() !== ResponseStatus.OK) {
						const { parameters, errors } = response.Response;
						const parametersMessage = (parameters || []).join(',');
						this.mainUtilService.showToastrMessage(errors as string, ToastrType.ERROR, message || parametersMessage || '');
						return;
					}

					this.mainUtilService.showToastrMessage(this.translate.instant('COMMON.commandExecuted'), ToastrType.SUCCESS);
				}),
				customOperators.retryFromError$(1000)
			);
	}

	fetchAvailableDevices$(discovery: RioRvsDiscoveryType): Observable<IRioRvsWioDevice[]> {
		const uuid = MainUtilService.getLocalStorageValue(RioRvsCookieKey.RIO_RVS_UUID, '');
		const queryParams = uuid ? `?discover=${discovery}&UUID=${uuid}` : `?discover=${discovery}`;

		return this.http.get<IRioRvsResponse>(this.discoverDevicesUrl + queryParams)
			.pipe(
				map(response => {
					const { message } = response.Response;
					const hasErrorMessage = message !== undefined;
					this.uuid = !hasErrorMessage ? response.Response.uuid : '';

					return this.rioRvsHttpLogicService.formatAvailableDevices(response, hasErrorMessage);
				}),
				customOperators.retryFromError$(1000)
			);
	}

	fetchDeviceConfiguration$(type: RioRvsWioDeviceType, resourceId: string): Observable<RioRvsModalLineDeviceType[]> {
		const fetchDeviceUrl = this.getDeviceConfigurationUrl + type + '-' + resourceId;

		return forkJoin([
			this.fetchDeviceIconsList$(type, resourceId),
			this.http.get<IPortsConfigurationDeviceResponse>(fetchDeviceUrl)
		])
			.pipe(
				map(response => {
					const [iconsResponse, configurationResponse] = response;

					if (configurationResponse && configurationResponse.Response.hasOwnProperty('Res')) {
						const { Res } = configurationResponse.Response;
						const deviceConfiguration = this.rioRvsHttpLogicService.formatDeviceConfiguration(Res);

						return this.rioRvsHttpLogicService.formatAllLineDevices(type, +resourceId, [deviceConfiguration], iconsResponse);
					}

					return [];
				}),
				customOperators.retryFromError$(1000)
			);
	}

	fetchLineDevices$(type: RioRvsWioDeviceType, resourceId: string): Observable<RioRvsModalLineDeviceType[]> {
		return forkJoin({
			devicesResponse: this.fetchDeviceStatus$(),
			deviceIconListResponse: this.fetchDeviceIconsList$(type, resourceId)
		})
			.pipe(
				map(response => {
					const { devicesResponse, deviceIconListResponse } = response;

					if (devicesResponse && devicesResponse.Response.hasOwnProperty('iohub')) {
						const { iohub, SesId } = devicesResponse.Response;

						this.sessionId.next(SesId);

						return this.rioRvsHttpLogicService.formatAllLineDevices(type, +resourceId, iohub, deviceIconListResponse);
					}

					return [];
				}),
				customOperators.retryFromError$(1000)
			);
	}

	fetchDeviceStatus$(): Observable<IRioRvsIohubDevicesResponse> {
		return this.sessionId.asObservable()
			.pipe(
				take(1),
				switchMap(sessionId => {
					const queryParams = sessionId ? `?SesId=${sessionId}` : '';

					return this.fetchSessionStatus$(queryParams)
						.pipe(
							catchError(() => {
								this.sessionId.next('');

								return this.fetchSessionStatus$('');
							})
						);
				})
			);
	}

	fetchSessionStatus$(queryParams: string): Observable<IRioRvsIohubDevicesResponse> {
		return this.http.get<IRioRvsIohubDevicesResponse>(this.getDevicesStatusUrl + queryParams);
	}

	removeResetDevice$(selectedDevice: IRioRvsWioDevice): Observable<IActionResponse | null> {
		const { mac, type, resource } = selectedDevice;

		const id = AutomationGenericService.splitAutomationItemByDash(resource as string)[1];
		const urlToUse = id !== '0' ? this.removeDeviceUrl : this.resetDeviceUrl;
		const removePayload = { uuid: this.rioRvsHttpLogicService.getRioRvsUUID, mac, type, id };

		return this.http.post<IActionResponse>(urlToUse, removePayload)
			.pipe(
				catchError(() => {
					this.rioRvsNgrxService.setIsLoading(false);
					const translated = this.translate.instant('AUTO.PREVIEW.deviceRemoval');

					return this.handleOperationErrors$(translated);
				})
			);
	}

	unbindDevice$(selectedDevice: IRioRvsWioDevice): Observable<IActionResponse | null> {
		const { mac, type, resource } = selectedDevice;
		const id = AutomationGenericService.splitAutomationItemByDash(resource as string)[1];

		const unbindPayload = { uuid: this.rioRvsHttpLogicService.getRioRvsUUID, mac, type, id };

		return this.http.post<IActionResponse>(this.unbindDeviceUrl, unbindPayload)
			.pipe(
				catchError(() => {
					this.rioRvsNgrxService.setIsLoading(false);
					const translated = this.translate.instant('AUTO.PREVIEW.unbindRemoval');

					return this.handleOperationErrors$(translated);
				})
			);
	}

	bindDevice$(
		selectedDevice: IRioRvsWioDevice, bindDeviceId: string, newDescription: string, newIpAddress: string
	): Observable<IActionResponse | null> {
		const { mac, type, ipres, 'ipres-mask': ipresMask, 'default-gw': defaultGateway } = selectedDevice;

		const bindPayload = {
			uuid: this.rioRvsHttpLogicService.getRioRvsUUID, mac, type, id: bindDeviceId, descr: newDescription,
			ipres, ipaddr: newIpAddress, mask: ipresMask, gw: defaultGateway
		};

		return this.http.post<IActionResponse>(this.bindDeviceUrl, bindPayload)
			.pipe(
				catchError(() => {
					this.rioRvsNgrxService.setIsLoading(false);
					const translated = this.translate.instant('AUTO.PREVIEW.bindError');

					return this.handleOperationErrors$(translated);
				})
			);
	}

	updateDeviceInformation$(
		selectedDevice: IRioRvsWioDevice, newIpAddress: string, newDescription: string
	): Observable<IActionResponse | null> {
		const { mac, type, resource, ipres, 'ipres-mask': ipresMask, 'default-gw': defaultGateway } = selectedDevice;
		const id = AutomationGenericService.splitAutomationItemByDash(resource as string)[1];

		const updatePayload = {
			uuid: this.rioRvsHttpLogicService.getRioRvsUUID, mac, type, id, descr: newDescription,
			ipres, ipaddr: newIpAddress, mask: ipresMask, gw: defaultGateway
		};

		return this.http.post<IActionResponse>(this.setDeviceUrl, updatePayload)
			.pipe(
				catchError(() => {
					this.rioRvsNgrxService.setIsLoading(false);
					const translated = this.translate.instant('AUTO.PREVIEW.setChangesError');

					return this.handleOperationErrors$(translated);
				})
			);
	}

	saveCanDeactiveLineDevice$(
		iohubClass: RioRvsWioIohubDeviceClass, formValues: string[],
		resource: string, selectedRioRvsLineDevice: RioRvsModalLineDeviceType
	): Observable<IRioRvsMultipleEntriesResponse> {
		return this.saveMultipleDeviceChanges$(iohubClass, resource, false, selectedRioRvsLineDevice, formValues);
	}

	handleOperationErrors$(errorText: string): Observable<null> {
		this.mainUtilService.showToastrMessage(errorText, ToastrType.ERROR);

		return of(null);
	}

	getUuid(): string {
		return this.uuid;
	}
}
