<button class="content__item" type="button" (click.zoneless)="onOpenManual()">
	<img src="assets/feather-icons/book-open.svg" alt="manual" class="content__item--white">
</button>

<ng-container *ngIf="isUserLoggedIn else notLoggedInTemplate">
	<button class="content__item" type="button" (click)="onOpenHomepage()"
		[ngClass]="{'content__item--active': sidebarFeatureName === getSidebarFeatureName.HOME}">
		<img src="assets/feather-icons/home.svg" alt="home" class="content__item--white">
	</button>

	<button class="content__item" type="button" (click)="onOpenUserSettings()"
		[ngClass]="{'content__item--active': sidebarFeatureName === getSidebarFeatureName.USER_SETTINGS}">
		<img src="assets/feather-icons/user.svg" alt="user" class="content__item--white">
	</button>

	<button class="content__item" type="button" (click.zoneless)="onLogout()">
		<img src="assets/feather-icons/log-out.svg" alt="logout" class="content__item--white">
	</button>
</ng-container>

<ng-template #notLoggedInTemplate>
	<app-language-picker-small></app-language-picker-small>
</ng-template>
