import { Action, createReducer, on } from '@ngrx/store';
import { IAutomationMap } from '../ts/models/automation-map.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as automationActions from './automation.action';

export const automationFeatureKey = 'automationMaps';

export interface IAutomationMapState extends EntityState<IAutomationMap> {
	isLoading: boolean;
	availableMapNumbers: number[];
	selectedMapNumber: number | null;
}

export const adapter: EntityAdapter<IAutomationMap> = createEntityAdapter<IAutomationMap>();

export const initialAutomationState: IAutomationMapState = adapter.getInitialState({
	isLoading: true,
	selectedMapNumber: null,
	availableMapNumbers: []
});

const automationReducer = createReducer(
	initialAutomationState,
	on(automationActions.finishMapLoadingAction, (state, { payload }) =>
		adapter.setAll(payload.automationMaps, { ...state, isLoading: false })),
	on(automationActions.setSelectedAutomationMapNumber, (state, { payload }) =>
		({ ...state, selectedMapNumber: payload.automationMapNumber })),
	on(automationActions.setAvailableMapNumbers, (state, { payload }) =>
		({ ...state, availableMapNumbers: payload.availableMapNumbers })),
	on(automationActions.removeAllAutomationMaps, (state) => adapter.removeAll(({ ...state, isLoading: true })))
);

export function AutomationReducer(state: IAutomationMapState | undefined, action: Action) {
	return automationReducer(state, action);
}

export const { selectAll: selectAllAvailableMaps } = adapter.getSelectors();
