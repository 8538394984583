import { Observable, map } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Injectable, inject } from '@angular/core';
import { IAppState } from '../../../../../root-reducers';
import { RecentCallUtilService } from '../recent-call-util.service';
import { IRecentCall } from '../../ts/models/recent-call-table.model';
import { RecentCallArrayService } from '../recent-call-array.service';
import { RecentCallStoreKey } from '../../ts/enums/recent-call-store-key.enum';
import * as recentCallSelectors from '../../ngrx/recent-call.selector';

@Injectable({
	providedIn: 'root'
})
export class RecentCallNgrxSelectorService {

	private readonly store: Store<IAppState>;

	constructor(
		private readonly recentCallArrayService: RecentCallArrayService
	) {
		this.store = inject(Store<IAppState>);
	}

	/**
	 * Gets all Recent Call items
	 */
	public selectAllRecentCalls$(): Observable<IRecentCall[]> {
		return this.store.pipe(select(recentCallSelectors.selectAllRecentCalls()));
	}

	/**
	 * Takes value from ngrx store, related to Recent Calls
	 * @param {RecentCallStoreKey} selector Key of value in ngrx store
	 */
	public selectFromRecentCallsStore$<T>(selector: RecentCallStoreKey): Observable<T> {
		return this.store.select(recentCallSelectors.selectFromRecentCallsStore(selector)) as unknown as Observable<T>;
	}

	/**
	 * Gets first checked Recent Call item
	 */
	public getFirstSelectedRecentCallItem$(): Observable<IRecentCall | null> {
		return this.selectAllRecentCalls$()
			.pipe(
				map(recentCalls => this.recentCallArrayService.getFirstSelectedRecentCallItem(recentCalls))
			);
	}

	/**
	 * Determines if button to add to Address Book should be disabled/enabled.
	 * This condition is based on single selected Recent Call Item
	 */
	public addToAddressBookValid$(): Observable<boolean> {
		return this.getFirstSelectedRecentCallItem$()
			.pipe(
				map(selectedContact => RecentCallUtilService.addToAddressBookValid(selectedContact?.correspondent))
			);
	}

	/**
	 * Determines if button to add to Address Book should be disabled/enabled.
	 * This condition is based on all available Recent Calls
	 */
	public shouldDisableAddToAddressBookButton$(): Observable<boolean> {
		return this.selectAllRecentCalls$()
			.pipe(
				map(recentCalls => {
					const emptyRecentCalls = RecentCallArrayService.areRecentCallsEmpty(recentCalls);
					const someRecentCallSelected = this.recentCallArrayService.isSomeRecentCallSelected(recentCalls);

					return emptyRecentCalls || !someRecentCallSelected;
				})
			);
	}
}
