import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { INewVersion } from '../../ts/models/new-version.model';
import { CoreService } from '../core/services/core.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-version-modal',
	templateUrl: './version-modal.component.html',
	styleUrls: ['./version-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionModalComponent implements OnInit {

	newVersion: string;
	currentVersion: string;

	translateObject$: Observable<INewVersion>;

	constructor(
		public bsModalRef: BsModalRef,
		private coreService: CoreService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.newVersion = this.coreService.getNewVersion();
		this.currentVersion = this.coreService.getCurrentVersion().version;
		// update translate values asynchronous
		this.translateObject$ = this.translate.get('VERSION-MODAL');
	}

	updateApplication(reloadState: boolean): void {
		// if user choose to update app, reload page, else set new version to variable and prevent function execution again
		if (reloadState) {
			window.location.reload();

			return;
		}

		this.currentVersion = this.newVersion;
		this.bsModalRef.hide();
	}

}
