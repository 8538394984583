import { Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { filter, Observable, map, zip, take } from 'rxjs';
import { RoutesEnum } from './../../../ts/enums/routes.enum';
import { RouterUtilService } from './../../../services/utils/router-util.service';
import { FeaturePermissionsName } from './../ts/enums/feature-permission-name.enum';
import { IFeaturePermissionsItem } from './../ts/models/feature-permissions-item.model';

@Injectable({
	providedIn: 'root'
})
export abstract class PermissionTabService {

	router = inject(Router);

	constructor() { }

	public getFirstAvailableTab$(
		url: RoutesEnum,
		permissions$: Observable<IFeaturePermissionsItem | null>[],
		formatPermissions?: (route: FeaturePermissionsName) => RoutesEnum | string
	) {
		return zip(...permissions$)
			.pipe(
				map(permissions => {
					const isMatchingUrl = this.isMatchingRouterUrl(url);
					const matchingPermission = this.getFirstMatchingPermissionSet(permissions);

					return isMatchingUrl && matchingPermission;
				}),
				filter((permission): permission is IFeaturePermissionsItem => !!permission),
				map(permissionItem => formatPermissions ? formatPermissions(permissionItem.name) : permissionItem.name),
				filter((correctUrl): correctUrl is RoutesEnum => !!correctUrl),
				take(1)
			);
	}

	public watchMainRouteMatch$(url: RoutesEnum) {
		return this.router.events
			.pipe(
				filter(event => {
					const isNavigationState = RouterUtilService.isNavigationStart(event);
					const isBackButtonPressed = isNavigationState && RouterUtilService.isPopStateEvent(event);

					return isBackButtonPressed && RouterUtilService.matchesRouterUrl(event.url, url);
				})
			);
	}

	private isMatchingRouterUrl(url: RoutesEnum): boolean {
		return RouterUtilService.matchesRouterUrl(this.router.url, url);
	}

	private getFirstMatchingPermissionSet(permissions: (IFeaturePermissionsItem | null)[]): IFeaturePermissionsItem | null {
		return permissions.find(item => item !== null && !item.isFeatureDisabled) || null;
	}
}
