import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { DragDropDirective } from '../components/ui-components/generic-table/directives/drag-drop.directive';
import { NetworkingInfoLocationPipe } from '../components/networking-info/pipes/networking-info-location.pipe';
import { KbitConverterPipe } from '../pipes/kbit-converter.pipe';
import { IpConverterPipe } from '../pipes/ip-converter.pipe';
import { TimestampConverterPipe } from '../pipes/timestamp-conventer.pipe';
import { MinimizeStringPipe } from '../pipes/minimize-string.pipe';

@NgModule({
	declarations: [
		SafeHtmlPipe,
		KbitConverterPipe,
		DragDropDirective,
		NetworkingInfoLocationPipe,
		IpConverterPipe,
		TimestampConverterPipe,
		MinimizeStringPipe
	],
	imports: [],
	exports: [
		SafeHtmlPipe,
		KbitConverterPipe,
		DragDropDirective,
		NetworkingInfoLocationPipe,
		IpConverterPipe,
		TimestampConverterPipe,
		MinimizeStringPipe
	]
})

export class SharedPipeDirectiveModule { }
