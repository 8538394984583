import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
	// data passed to modal
	message: string;

	okCallback: () => void;
	cancelCallback: () => void;

	constructor(
		public bsModalRef: BsModalRef
	) { }

	onConfirm(): void {
		this.okCallback();
		this.bsModalRef.hide();
	}

	onCancel(): void {
		if (this.cancelCallback) {
			this.cancelCallback();
		}

		this.bsModalRef.hide();
	}
}
