import { Injectable } from '@angular/core';
import { OpcPanelCardService } from './opc-panel-card.service';
import { OpcPanelCtiRole } from '../../ts/enums/opc-panel-cti-role.enum';
import { OpcPanelUserRole } from '../../ts/enums/opc-panel-user-role.enum';
import { OpcPanelInterface } from '../../ts/enums/opc-panel-interface.enum';
import { OpcPanelSipIfSubtype } from '../../ts/enums/opc-panel-sip-if-subtype.enum';
import { OpcPanelCallDirection } from '../../ts/enums/opc-panel-call-direction.enum';
import { IOpcPanelCallRecord } from '../../ts/models/session/opc-panel-call-record.model';
import { OpcPanelCallRecordState } from '../../ts/enums/opc-panel-call-record-state.enum';
import { IOpcPanelUserRecord } from '../../ts/models/session/opc-panel-user-record.model';
import { OpcPanelUserRecordState } from '../../ts/enums/opc-panel-user-record-state.enum';
import { OpcPanelCtipPotsDevice } from '../../ts/enums/opc-panel-ctip-if-subtype-pots-device.enum';
import { OpcPanelUserRecordCallState } from '../../ts/enums/opc-panel-user-record-call-state.enum';
import { PreferencesCtipAllType } from '../../../preferences/ts/enums/preferences-ctip-all-type.enum';

@Injectable({
	providedIn: 'root'
})
export class OpcPanelCardCallService {

	constructor(
		private opcPanelCardService: OpcPanelCardService
	) { }

	getCallDirectionFromCtiRole(ctiRole: OpcPanelCtiRole, callRecord: IOpcPanelCallRecord) {
		const { Direction } = callRecord;

		if (ctiRole.toLowerCase() !== OpcPanelCtiRole.NETWORK.toLowerCase()) {
			return Direction;
		}

		return Direction === OpcPanelCallDirection.OUT ? OpcPanelCallDirection.IN : OpcPanelCallDirection.OUT;
	}

	hasNotNullCall(callRecords: IOpcPanelCallRecord[]): boolean {
		return callRecords.some(callRecord => callRecord.State !== OpcPanelCallRecordState.NULL);
	}

	hasCallWithState(callRecords: IOpcPanelCallRecord[], state: OpcPanelCallRecordState): boolean {
		return callRecords.some(callRecord => callRecord.State === state);
	}

	getCallsWithState(callRecords: IOpcPanelCallRecord[], state: OpcPanelCallRecordState): IOpcPanelCallRecord[] {
		return callRecords.filter(callRecord => callRecord.State === state);
	}

	hasCallWithStateAndId(callRecords: IOpcPanelCallRecord[], callId: number, state: OpcPanelCallRecordState): boolean {
		return callRecords.some(callRecord => callRecord.State === state && callRecord.CallID === callId);
	}

	hasCallWithId(callRecords: IOpcPanelCallRecord[], callId: number): boolean {
		return callRecords.some(callRecord => callRecord.CallID === callId);
	}

	isMatchingPotsDevice(userRecord: IOpcPanelUserRecord, potsDevice: OpcPanelCtipPotsDevice): boolean {
		return userRecord.IfSubtypePotsDevice === potsDevice;
	}

	getFirstNotNullCall(callRecords: IOpcPanelCallRecord[]): IOpcPanelCallRecord | undefined {
		return callRecords.find(callRecord => callRecord.State !== OpcPanelCallRecordState.NULL);
	}

	hasCallRecordByStateAndDirection(
		callRecords: IOpcPanelCallRecord[], state: OpcPanelCallRecordState, direction: OpcPanelCallDirection
	): boolean {
		return callRecords.some(callRecord => callRecord.State === state && callRecord.Direction === direction);
	}

	isPots(IfSubtype: PreferencesCtipAllType | undefined): boolean {
		return IfSubtype === PreferencesCtipAllType.POTS;
	}

	getLastNotNullCall(callRecords: IOpcPanelCallRecord[]): IOpcPanelCallRecord | undefined {
		return callRecords
			.slice()
			.reverse()
			.find(callRecord => callRecord.State !== OpcPanelCallRecordState.NULL);
	}

	getLastActiveCall(callRecords: IOpcPanelCallRecord[]): IOpcPanelCallRecord | undefined {
		return callRecords
			.slice()
			.reverse()
			.find(callRecord => callRecord.State === OpcPanelCallRecordState.ACTIVE);
	}

	getLastAlertingCall(callRecords: IOpcPanelCallRecord[]): IOpcPanelCallRecord | undefined {
		return callRecords
			.slice()
			.reverse()
			.find(callRecord => {
				const { State, Direction } = callRecord;

				return State === OpcPanelCallRecordState.ALERTING && Direction === OpcPanelCallDirection.OUT;
			});
	}

	isPriorityCall(customNumber: string): boolean {
		const loggedUserRecords = this.opcPanelCardService.getLoggedUserRecords();
		const ctipInterface = loggedUserRecords.find(userRecord => userRecord.interface === OpcPanelInterface.CTIP);

		return ctipInterface ? ctipInterface.Num.toString() === customNumber : false;
	}

	isCallMatchingByStateDirection(
		secondCall: IOpcPanelCallRecord, state: OpcPanelCallRecordState, direction: OpcPanelCallDirection
	): boolean {
		const { State, Direction } = secondCall;

		return State === state && Direction === direction;
	}

	isSecondCallAlertingOut(secondCall: IOpcPanelCallRecord | null): boolean {
		if (secondCall) {
			return this.isCallMatchingByStateDirection(secondCall, OpcPanelCallRecordState.ALERTING, OpcPanelCallDirection.OUT);
		}

		return false;
	}

	canTransferAlertingWithPhaseAny(
		callRecords: IOpcPanelCallRecord[], userInterface: OpcPanelInterface, ifSubtype: PreferencesCtipAllType | undefined
	): boolean {
		if (userInterface === OpcPanelInterface.VO) { return true; }

		const hasTwoCalls = callRecords.length === 2;
		const hasPots = ifSubtype === PreferencesCtipAllType.POTS;

		if (hasPots && hasTwoCalls) {
			const secondCall = hasTwoCalls ? callRecords[1] : null;
			const isSecondCallAlertingOut = this.isSecondCallAlertingOut(secondCall);

			const hasHoldCall = this.hasCallWithState(callRecords, OpcPanelCallRecordState.HOLD);
			const hasActiveCall = this.hasCallWithState(callRecords, OpcPanelCallRecordState.ACTIVE);
			const hasAlertingOutgoingCall = this.hasCallRecordByStateAndDirection(
				callRecords, OpcPanelCallRecordState.ALERTING, OpcPanelCallDirection.OUT
			);

			if (isSecondCallAlertingOut) {
				return true;
			}

			if (hasActiveCall || hasHoldCall) {
				return hasAlertingOutgoingCall;
			}
		}

		return false;
	}

	getCorrectPhase(canTranfserAny: boolean): string {
		return canTranfserAny ? 'ANY' : 'ALERTING';
	}

	canTransferAlerting(
		matchingUserRecord: IOpcPanelUserRecord, loggedUserRole: OpcPanelUserRole, callRecords: IOpcPanelCallRecord[]
	): boolean {
		const outDirection = OpcPanelCallDirection.OUT;
		const alertingState = OpcPanelCallRecordState.ALERTING;
		const isOperator = this.opcPanelCardService.isUserOperator(loggedUserRole);
		const isMatchingByLoggedUser = this.isMatchingLoggedUserData(matchingUserRecord);
		const hasAlertingOutgoingCall = this.hasCallRecordByStateAndDirection(callRecords, alertingState, outDirection);

		return (isOperator || isMatchingByLoggedUser) && hasAlertingOutgoingCall;
	}

	canTransferActive(matchingUserRecord: IOpcPanelUserRecord, callRecords: IOpcPanelCallRecord[]): boolean {
		const { interface: activeUserInterface } = matchingUserRecord;
		const loggedUserMainInterface = this.opcPanelCardService.getLoggedUserActiveInterface();

		if (loggedUserMainInterface && activeUserInterface === loggedUserMainInterface.interface) {
			return this.isMatchingLoggedUserData(matchingUserRecord) && this.isActiveHeldCanPerformCall(matchingUserRecord, callRecords);
		}

		return false;
	}

	isActiveHeldCanPerformCall(matchingUserRecord: IOpcPanelUserRecord, callRecords: IOpcPanelCallRecord[]): boolean {
		const { State } = matchingUserRecord;
		const canCall = this.canCall(matchingUserRecord, callRecords);
		const hasHoldCalls = this.hasCallWithState(callRecords, OpcPanelCallRecordState.HOLD);
		const hasActiveCalls = this.hasCallWithState(callRecords, OpcPanelCallRecordState.ACTIVE);

		return canCall && (hasActiveCalls || hasHoldCalls) && State !== OpcPanelUserRecordState.RINGING;
	}

	isCallToCardAvailable(callRecords: IOpcPanelCallRecord[]): boolean {
		const loggedUserInterface = this.opcPanelCardService.getLoggedUserActiveInterface();

		return loggedUserInterface ? this.canCall(loggedUserInterface, callRecords) : false;
	}

	canCall(matchingUserRecord: IOpcPanelUserRecord, callRecords: IOpcPanelCallRecord[]): boolean {
		const { interface: userInterface, State, Call, IfSubtype, IfSubtypeSip } = matchingUserRecord;

		const isPotsInterface = this.isPots(IfSubtype);
		const alertingState = OpcPanelCallRecordState.ALERTING;

		const hasAlertingIncomingCall = this.hasCallRecordByStateAndDirection(callRecords, alertingState, OpcPanelCallDirection.IN);
		const hasAlertingOutgoingCall = this.hasCallRecordByStateAndDirection(callRecords, alertingState, OpcPanelCallDirection.OUT);

		if (isPotsInterface && State !== OpcPanelUserRecordState.NOTREADY) {
			const isRinging = State === OpcPanelUserRecordState.RINGING;

			return !(hasAlertingIncomingCall || isRinging || hasAlertingOutgoingCall || this.hasTwoButOnlyHoldAndActiveCalls(callRecords));
		}

		if (userInterface === OpcPanelInterface.SIP && IfSubtypeSip === OpcPanelSipIfSubtype.PHONE) {
			const isNotReadyState = State === OpcPanelUserRecordState.NOTREADY;
			const ringCallActive = Call.State === OpcPanelUserRecordCallState.ACTIVE;

			return !(isNotReadyState || ringCallActive || hasAlertingIncomingCall || hasAlertingOutgoingCall);
		}

		const holdState = OpcPanelCallRecordState.HOLD;
		const activeState = OpcPanelCallRecordState.ACTIVE;

		return this.hasCallWithState(callRecords, activeState) || this.hasCallWithState(callRecords, holdState);
	}

	hasTwoButOnlyHoldAndActiveCalls(callRecords: IOpcPanelCallRecord[]): boolean {
		return callRecords
			.filter(callRecord => {
				const { State } = callRecord;

				return State === OpcPanelCallRecordState.ACTIVE || State === OpcPanelCallRecordState.HOLD;
			})
			.length === 2 && callRecords.length === 2;
	}

	hasMoreCalls(callRecords: IOpcPanelCallRecord[]): boolean {
		return callRecords
			.filter(callRecord => callRecord.State !== OpcPanelCallRecordState.NULL)
			.length >= 2;
	}

	isMatchingLoggedUserData(matchingUserRecord: IOpcPanelUserRecord): boolean {
		const { abilis, user } = matchingUserRecord;
		const { abilisName: loggedUserAbilis, user: loggedUserUsername } = this.opcPanelCardService.getLoggedUserInfo();

		return abilis === loggedUserAbilis && user === loggedUserUsername;
	}
}
