import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IAutomationProgramEdit } from '../ts/models/automation-program-edit.model';
import * as automationProgramActions from './automation-program-edit.actions';

export const automationProgramEditFeatureKey = 'automationProgramEdit';

export interface IAutomationProgramEditState extends EntityState<IAutomationProgramEdit> {
	isLoading: boolean;
}

export const adapter: EntityAdapter<IAutomationProgramEdit> = createEntityAdapter<IAutomationProgramEdit>();

export const initialAutomationProgramState: IAutomationProgramEditState = adapter.getInitialState({
	isLoading: true,
});

const automationProgramEditReducer = createReducer(
	initialAutomationProgramState,
	on(automationProgramActions.setProgramDeleteState, (state, { payload }) =>
		adapter.updateOne({ id: payload.automationProgram.id as string, changes: payload.automationProgram }, state)),
	on(automationProgramActions.automationProgramsLoaded, (state, { payload }) =>
		adapter.setAll(payload.automationPrograms, { ...state, isLoading: false })),
	on(automationProgramActions.clearAutomationProgramTableObservables, (state) => ({ ...state, isLoading: true }))
);

export function AutomationProgramReducer(state: IAutomationProgramEditState | undefined, action: Action) {
	return automationProgramEditReducer(state, action);
}

export const { selectAll: selectAllJsonAutomationPrograms } = adapter.getSelectors();
