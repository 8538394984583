import { Injectable } from '@angular/core';
import { IRecentCall } from '../ts/models/recent-call-table.model';
import { IRecentCallMode } from '../ts/enums/recent-call-mode.enum';
import { RecentCallActionFactoryService } from './recent-call-action-factory.service';

@Injectable({
	providedIn: 'root'
})
export class RecentCallUtilService {

	constructor(
		private readonly recentCallActionFactoryService: RecentCallActionFactoryService
	) {}

	/**
 * Gets number to call from Recent Call Item
 * @param {IRecentCall} recentCallItem Selected Recent Call item
 */
	public static getNumberToCall(recentCallItem: IRecentCall): string {
		const { outdial, isInDirection, called, calling, name, number: phoneNumber } = recentCallItem;

		if (name && phoneNumber) { return outdial + phoneNumber; }

		return `${outdial}${isInDirection ? calling : called}`;
	}

	/**
 * Determines if selected Recent Call item's number should be added to <app-phone/> component
 * @param {string | undefined} correspondent Selected Recent Call item correspondent key's value
 */
	public static canPerformCall(correspondent: string | undefined): boolean {
		return !!correspondent && correspondent !== '';
	}

	/**
	 * Determines if button to add to Address Book contact should be enabled/disabled
	 * @param {string | undefined} correspondent Selected Recent Call item correspondent key's value
	 */
	public static addToAddressBookValid(correspondent: string | undefined): boolean {
		if (!correspondent) { return false; }

		return /^\d+$/.test(correspondent);
	}

	/**
	 * Routes to add mode of logged user
	 * @param {string} username Owner of Recent Calls
	 */
	public openAddForm(username: string): void {
		this.recentCallActionFactoryService.getActiveService.openAddForm(username);
	}

	/**
	 * Value to check if Recent Calls is in the mode to manage logged user's Recent Calls.
	 * @param {IRecentCallMode} recentCallsMode Recent Calls mode
	 */
	public static isSelfMode(recentCallsMode: IRecentCallMode): boolean {
		return recentCallsMode === IRecentCallMode.SELF;
	}

	/**
		 * Value to check if Recent Calls is in the mode to manage all user's Recent Calls.
		 * @param {IRecentCallMode} recentCallsMode Recent Calls mode
		 */
	public static isAllMode(recentCallsMode: IRecentCallMode): boolean {
		return recentCallsMode === IRecentCallMode.ALL;
	}

	/**
		 * Value to check if Recent Calls is in the mode to manage other users.
		 * @param {IRecentCallMode} recentCallsMode Recent Calls mode
		 */
	public static isOtherMode(recentCallsMode: IRecentCallMode): boolean {
		return recentCallsMode === IRecentCallMode.OTHER;
	}
}
