import { Injectable } from '@angular/core';
import { AuthHttpService } from './auth-http.service';
import { AuthNgrxService } from '../services/auth-ngrx.service';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {

	constructor(
		private authNgrxService: AuthNgrxService,
		private authHttpService: AuthHttpService
	) { }

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		return this.checkRoutePermission$(route);
	}

	canLoad(route: Route): Observable<boolean> {
		return this.checkRoutePermission$(route);
	}

	checkRoutePermission$(route: Route | ActivatedRouteSnapshot): Observable<boolean> {
		const urlToUse = route?.data?.url ? route.data.url : null;

		if (!urlToUse) { return of(true); }

		return this.authNgrxService.selectAllPermissions$()
			.pipe(
				switchMap(allPermissions => forkJoin({
					allPermissions: of(allPermissions),
					featureDisabled: this.authHttpService.checkUserAuth$(urlToUse)
				})),
				map(response => {
					const { allPermissions, featureDisabled } = response;

					return allPermissions.length ? featureDisabled : true;
				})
			);
	}
}
