import { Injectable, inject } from '@angular/core';
import { Observable, of, forkJoin, zip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrType } from './../../../../../ts/enums/toastr-type.enum';
import { MainUtilService } from './../../../../../services/main-util.service';
import { AddressBookContactService } from '../http/address-book-contact.service';
import { AddressBookNgrxActionService } from './address-book-ngrx-action.service';
import { IAddressBookContact } from './../../ts/models/address-book-contact.model';
import { AddressBookOperation } from './../../ts/types/address-book-operation.type';
import { AddressBookContactUtilService } from './../address-book-contact-util.service';
import { AddressBookContactArrayService } from './../address-book-contact-array.service';
import { AddressBookContactHttpService } from './../http/address-book-contact-http.service';
import { IAddressBookAddContactPayload } from '../../ts/models/address-book-add-contact-payload.model';
import { IAddressBookEditContactPayload } from '../../ts/models/address-book-edit-contact-payload.model';
import { IAddressBookMatchingContactData } from './../../ts/models/address-book-matching-contact-data.model';
import { IAddressBookAddContactPayloadData } from '../../ts/models/address-book-add-contact-payload-data.model';
import { IAddressBookAddEditActionResponse } from '../../ts/models/address-book-add-edit-action-response.model';
import { IAddressBookAddEditContactEffect } from './../../ts/models/address-book-add-edit-contact-effect.model';
import { IAddressBookEditContactPayloadData } from '../../ts/models/address-book-edit-contact-payload-data.model';

@Injectable({
	providedIn: 'root'
})
export class AddressBookEffectsHandlerService {

	private readonly translateService: TranslateService;

	constructor(
		private readonly mainUtilService: MainUtilService,
		private readonly addressBookContactService: AddressBookContactService,
		private readonly addressBookNgrxActionService: AddressBookNgrxActionService,
		private readonly addressBookContactHttpService: AddressBookContactHttpService,
		private readonly addressBookContactUtilService: AddressBookContactUtilService
	) {
		this.translateService = inject(TranslateService);
	}

	/**
	 * Handles adding new Address Book contact
	 * @param {IAddressBookAddEditActionResponse} response Contains:
	 * 1. username - to be used if action was successful, in order to fetch Address Book table
	 * 2. editAddContactResponse - HTTP response of add new contact action
	 */
	public handleAddContact(response: IAddressBookAddEditActionResponse): void {
		const { username, editAddContactResponse } = response;
		const { code } = editAddContactResponse.Response;

		if (!AddressBookContactUtilService.isActionOk(code)) { return; }

		this.addressBookContactUtilService.handleAddEditContactResponse(editAddContactResponse, username);
	}

	/**
	 * Provides Observable with payload (explained below)
	 * matchingContactData which will provide matching contact either by name or number
	 * @param {IAddressBookAddContactPayload} payload Contains:
	 * 1. username - to be used in fetch URL, to fetch specific user's Address Book contacts
	 * 2. formContact - Address Book form value
	 */
	public formatAddContactPayloadData$(payload: IAddressBookAddContactPayload): Observable<IAddressBookAddContactPayloadData> {
		const { formContact, username } = payload;
		const matchingContactData = this.addressBookContactService.getMatchingContact$(formContact, null, username);

		return forkJoin({ matchingContactData, payload: of(payload) });
	}

	/**
	 * Provides Observable with payload (explained below)
	 * matchingContactData which will provide matching contact either by name or number
	 * @param {IAddressBookEditContactPayload} payload Contains:
	 * 1. username - to be used in fetch URL, to fetch specific user's Address Book contacts
	 * 2. formContact - Address Book form value
	 * 3. selectedContact - already selected Address Book contact
	 */
	public formatEditContactPayloadData$(payload: IAddressBookEditContactPayload): Observable<IAddressBookEditContactPayloadData> {
		const { username, selectedContact, formContact } = payload;
		const matchingContactData = this.addressBookContactService.getMatchingContact$(formContact, selectedContact, username);

		return forkJoin({ matchingContactData, payload: of(payload) });
	}

	/**
	 * Handles adding/editing contact
	 * 1. If there is no duplicated contact, add/edit operation will peform
	 * 2. If there is duplicated contact, but it's matching by number, appropriate toastr will appear
	 * 3. Last scenario is to offer contact overwrite
	 * @param {IAddressBookAddContactPayloadData} response Contains response from "formatAddContactPayloadData$" function
	 * @param {boolean} editContact If either should add or edit Address Book contact
	 */
	public handleAddEditContact$(
		response: IAddressBookAddContactPayloadData, editContact = false
	): Observable<null | IAddressBookAddEditContactEffect> {
		const { matchingContactData, payload } = response;
		const { username } = payload;

		if (!matchingContactData) {
			const editAddContactResponse$ = this.getSaveFunctionToExecute$(response, !editContact);

			return forkJoin({ username: of(username), editAddContactResponse: editAddContactResponse$ });
		}

		const { matchByName, matchingContact } = matchingContactData;

		if (!matchByName) {
			return this.handleSameContactNumber$(matchingContact, username);
		}

		const confirmedOverwrite = this.confirmOverwriteContact(matchingContact);

		if (!confirmedOverwrite) {
			this.addressBookNgrxActionService.fetchAddressBook(username);
		}

		return of(null);
	}

	/**
	 * Makes Observable of delete responses
	 * @param {IAddressBookContact[]} contacts All Address Book contacts
	 * @param {string} username Username to know for which user to delete the selected contacts
	 */
	public handleDeleteContacts$(contacts: IAddressBookContact[], username: string): Observable<AddressBookOperation[]> {
		if (!contacts.length) { return of([]); }

		const selectedContacts = AddressBookContactArrayService.getSelectedContacts(contacts);
		const deleteResponses$ = selectedContacts.map(contact => this.addressBookContactHttpService.deleteContact$(contact, username));

		return zip(...deleteResponses$);
	}

	/**
	 * Decides if modal to offer contact overwrite should be visible
	 * @param {IAddressBookMatchingContactData | null} matchingContactData Will provide matching contact either by name or number
	 */
	private overwriteContact(matchingContactData: IAddressBookMatchingContactData | null): boolean {
		return matchingContactData !== null;
	}

	/**
	 * Provides either add or edit contact async function
	 * @param {IAddressBookAddContactPayloadData} response Contains response from "formatAddContactPayloadData$" function
	 * @param {boolean} addMode If it's add Address Book mode
	 */
	private getSaveFunctionToExecute$(response: IAddressBookAddContactPayloadData, addMode: boolean): Observable<AddressBookOperation> {
		const { matchingContactData, payload } = response;
		const overwriteContact = this.overwriteContact(matchingContactData);
		const { username, formContact } = payload;

		if (addMode) {
			return this.addressBookContactHttpService.addContact$(username, formContact, overwriteContact);
		}

		return this.addressBookContactHttpService.editContact$(payload, formContact, overwriteContact);
	}

	/**
	 * Handles the case when editing/adding contact, same number is used
	 * @param {IAddressBookContact} matchingContact Same contact which matches with one provided
	 * @param {string} username Username to load user's Address Book
	 */
	private handleSameContactNumber$(matchingContact: IAddressBookContact, username: string): Observable<null> {
		const translatedError = this.getTranslationForSameContact(matchingContact);

		this.mainUtilService.showToastrMessage(translatedError, ToastrType.ERROR);
		this.addressBookNgrxActionService.fetchAddressBook(username);

		return of(null);
	}

	/**
	 * Provides the text, with meaning that another contact with name and type matches by number
	 * @param {IAddressBookContact} matchingContact Same contact which matches with one provided
	 */
	private getTranslationForSameContact(matchingContact: IAddressBookContact): string {
		const { name, type } = matchingContact;
		const sameNumber = this.translateService.instant('ADDRESS-BOOK.MAIN.sameNumber');
		const anotherContact = this.translateService.instant('ADDRESS-BOOK.MAIN.anotherContact');

		return `${anotherContact} "${name}" (${type}) ${sameNumber}`;
	}

	/**
	 * Provides confirm dialog, if user should overwrite contact
	 * @param {IAddressBookContact} matchingContact Same contact which matches with one provided
	 */
	private confirmOverwriteContact(matchingContact: IAddressBookContact): boolean {
		const { name, type } = matchingContact;

		const contact = this.translateService.instant('ADDRESS-BOOK.MAIN.contact');
		const reallyWant = this.translateService.instant('ADDRESS-BOOK.MAIN.doYouReallyWant');
		const alreadyExists = this.translateService.instant('ADDRESS-BOOK.MAIN.alreadyExists');

		const capitalCaseType = MainUtilService.capitalCaseString(type);

		return confirm(`${capitalCaseType} ${contact} "${name}" ${alreadyExists}. ` + `${reallyWant} ${type} ${contact} "${name}"?`);
	}
}
